import React, { useLayoutEffect, useState } from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const SnackBarAlert = ({
  message = null,
  error = true,
  duration = 2000,
  closeSnackBar = () => {}
}) => {
  const [ifError, setIfError] = useState(error)

  useLayoutEffect(() => {
    if (message) {
      setIfError(error)
    }
  }, [message, error])

  const handleSnackbarClose = (event, reason, fileWrapper) => {
    if (reason === 'clickaway') {
      return
    }
    closeSnackBar()
  }

  return (
    <Snackbar
      onExited={setIfError.bind(this, true)}
      open={!!message}
      autoHideDuration={duration}
      onClose={(e, r) => handleSnackbarClose(e, r)}>
      <Alert
        onClose={(e, r) => handleSnackbarClose(e, r)}
        severity={ifError ? 'error' : 'success'}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SnackBarAlert
