import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import StaffListDoctors from './StaffListDoctors'
import StaffListMedicalAssitants from './StaffListMedicalAssitants'
import StaffListAccountant from './StaffListAccountant'
import StaffListOthers from './StaffListOthers'
import MediaBgImg from '../../images/right-top-img.jpg'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}
export default function Staffs() {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <AppBar position="static" color="default" style={{ boxShadow: 'none' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="white"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example">
          <Tab
            style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
            label="Doctors"
            {...a11yProps(0)}
          />
          <Tab
            style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
            label="Medical Assistant"
            {...a11yProps(1)}
          />
          <Tab
            style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
            label="Accountants"
            {...a11yProps(2)}
          />
          <Tab
            style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
            label="Others"
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <div
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        // onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Grid container>
            {/* <Grid item md={9}> */}
            <StaffListDoctors />
            {/* </Grid> */}
            {/* <Grid item md={3}>
                <div className={classes.addNewContainer}>
                  <Box
                    boxShadow={4}
                    className={classes.addNew}
                  >
                    <b><i className={classes.plus}>+</i>Add New Doctor</b>
                  </Box>
                  <div className={classes.media}>
                    <h1>02</h1>
                    <p>Total Active Doctors</p>
                  </div>
                </div>
              </Grid> */}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Grid container>
            <StaffListMedicalAssitants />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Grid container>
            <StaffListAccountant />
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <StaffListOthers />
        </TabPanel>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    minHeight: '100vh',
    '& .MuiTab-root': {
      fontSize: '12px',
      padding: '6px 0px'
    },
    '@media (max-width:767px)': {
      marginTop: '35px'
    }
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '15px'
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%'
  },
  plus: {
    color: '#fff',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    fontSize: '25px',
    background: '#3f4b79',
    fontWeight: 'bold',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0',
    marginRight: '7px'
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box style={{ marginTop: '15px' }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}
