import React from 'react'
import ScheduleContent from './Schedule'
import { DashboardLayout } from '../../../layouts/ClinicDashboardLayout'
import { ClinicStore } from '../../../../stores'

const Schedule = () => {
  return (
    <ClinicStore.Provider>
      <DashboardLayout>
        <ScheduleContent />
      </DashboardLayout>
    </ClinicStore.Provider>
  )
}

export default Schedule
