import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import localforage from 'localforage'
import {
  Button,
  Avatar,
  Box,
  Grid,
  TextField,
  InputAdornment,
  makeStyles,
  useTheme
} from '@material-ui/core'
import {
  DOCTOR_USER_ID,
  VALIDATE_PHONE,
  LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME,
  NETWORK_STATUS,
  VALIDATE_WHITESPACES,
  SINGLE_WHITESPACE,
  NO_WHITESPACE,
  SHORT_CODE,
  VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE
} from '../../Constants'
import { DoctorStore, DoctorPublicStore } from '../../stores'
import ProfilePic from '../../components/CommonComponents/ProfilePic'
import SnackbarAlert from '../../components/CommonComponents/SnackbarAlert'
import Loader from '../../components/CommonComponents/Loader'
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber
} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import indianFlag from '../../images/flag.png'

function rand() {
  return Math.round(Math.random() * 20) - 10
}

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

export default function UpdateProfile() {
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [Image, previewImage] = useState('')
  const [file, setFile] = useState('')
  const [inputValue, setInputValue] = useState('')
  const classes = useStyles()
  const theme = useTheme()
  const [modalStyle] = React.useState(getModalStyle)
  const [inputPhone, setInputPhone] = useState('')

  const { profileUpdateStatus, usershortcode } = DoctorStore.useStoreState(
    state => ({
      ...state.profile
    })
  )

  const { networkStatus, profileUpdateLoader } = DoctorStore.useStoreState(
    state => ({
      ...state.profile
    })
  )
  const { updatedDoctorProfile, updateDoctorConsultationFees, snackClear } =
    DoctorStore.useStoreActions(actions => ({
      ...actions.profile
    }))

  // console.log( profileUpdateStatus?.errorMessage)
  useEffect(() => {
    snackClear()
    fetchDetails({
      shortCode: localStorage.getItem('user_short_code'),
      bypassFetch: true
    })
  }, [])

  useEffect(() => {
    if (profileUpdateStatus === undefined) {
      //
    } else if (profileUpdateStatus?.statusCode !== 400) {
      setOpen(true)
      setSnackBarMessage('Doctor profile updated successfully')
      setmultiButton(false)
      setAlertType('success')
      fetchDetails({
        shortCode: localStorage.getItem('user_short_code'),
        bypassFetch: true,
        reloadPage: true
      })
    } else {
      setOpen(true)
      setSnackBarMessage('Doctor profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [profileUpdateStatus])
  useEffect(() => {
    if (usershortcode) {
      localforage.getItem(LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME).then(data => {
        data.hospital_details.user_short_code = usershortcode
        localforage.setItem(LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME, data)
      })
    }
  }, [usershortcode])
  const onClose = () => {
    setOpen(false)
  }

  const submit = async values => {
    const file = values.file
    if (file) {
      if (
        file.name.split('.').pop().search('pdf') === 0 ||
        file.name.split('.').pop().search('docx') === 0
      ) {
        setOpen(true)
        setSnackBarMessage(
          'Pdf and Docx file are not supported for profile picture!'
        )
        setmultiButton(false)
        setAlertType('info')
      } else if (
        file.name.split('.').pop().search('pdf') !== 0 ||
        file.name.split('.').pop().search('docx') !== 0
      ) {
        let base64 = await convertBase64(file)
        let format = base64.split(',')
        updatedDoctorProfile({
          ...values,
          doctor_id: localStorage.getItem(DOCTOR_USER_ID),
          mobile_no: `+91${values.mobile_no}`,
          format
        })
        updateDoctorConsultationFees({
          doctor_id: localStorage.getItem(DOCTOR_USER_ID),
          in_person_fees: values?.in_person_fees,
          mobile_no: `+91${values.mobile_no}`,
          tele_fees: values?.tele_fees
        })
      }
    } else {
      updatedDoctorProfile({
        ...values,
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        mobile_no: `+91${values.mobile_no}`
      })
      updateDoctorConsultationFees({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        in_person_fees: values?.in_person_fees,
        tele_fees: values?.tele_fees
      })
    }
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }
  const {
    image,
    name,
    email,
    mobile_no,
    specialization,
    user_short_code,
    degree,
    experience,
    username,
    bio,
    prefix,
    picture,
    availability,
    description,
    associated_with,
    consultationFees,
    clinic_address,
    clinic_name
  } = DoctorPublicStore.useStoreState(state => ({
    ...state.doctorProfile
  }))
  const { fetchDetails } = DoctorPublicStore.useStoreActions(actions => ({
    ...actions.doctorProfile
  }))

  useEffect(() => {
    if (mobile_no !== '') {
      setInputValue(mobile_no)
    }
  }, [mobile_no])

  const formik = useFormik({
    initialValues: {
      firstname: name ? name : '',
      username: username ? username : '',
      mobile_no: mobile_no ? mobile_no.replace('+91', '') : '',
      email: email ? email : '',
      picture: picture ? picture : '',
      picture_name: '',
      prefix: prefix ? prefix : '',
      // middleName: '',
      user_short_code: user_short_code ? user_short_code : '',
      experience: experience ? experience : '',
      degree: degree ? degree : '',
      specialization: specialization ? specialization : '',
      bio: bio ? bio : '',
      password: '',
      confirmpassword: '',
      in_person_fees:
        consultationFees.length === 0
          ? 0
          : Number(
              consultationFees.find(
                i =>
                  i.appointment_type === 'in-person appointment' &&
                  i.visit_type == 1
              ).fees
            ),
      tele_fees:
        consultationFees.length === 0
          ? 0
          : Number(
              consultationFees.find(
                i =>
                  i.appointment_type === 'tele appointment' && i.visit_type == 1
              ).fees
            ),
      clinic_address: clinic_address ? clinic_address : '',
      clinic_name: clinic_name ? clinic_name : '',
      associated_with: associated_with ? associated_with : ''
    },
    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const hiddenFileInput = useRef()

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const handleChange = event => {
    formik.setFieldValue('file', event.currentTarget.files[0])
    event.preventDefault()

    let reader = new FileReader()
    let fileimage = event.target.files[0]

    reader.onloadend = () => {
      setFile(fileimage)
      previewImage(reader.result)
    }
    reader.readAsDataURL(fileimage)
  }

  const phoneChange = value => {
    setInputValue(value)
    formik.setFieldValue('mobile_no', value ? value : '')
  }

  const customStyles = {
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '99',
    cursor: 'pointer',
    display: 'none',
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    fontSize: '50px',
    /*Opacity settings for all browsers*/
    opacity: '0',
    mozOpacity: '0',
    msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
  }

  return (
    <div className={classes.Editprofilecol}>
      <h2
        style={{
          marginTop: '0px',
          fontSize: 20,
          fontWeight: 500,
          color: '#000',
          marginBottom: 20
        }}>
        Update Profile
      </h2>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}

      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={onClose}
      />
      <form
        onSubmit={formik.handleSubmit}
        noValidate={true}
        className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} className={classes.Editprofileright}>
            <h4 style={{ fontSize: 18, color: '#000', fontWeight: 400 }}>
              Personal Information
            </h4>
            <Box
              style={{
                border: '1px #DBE1F6 solid',
                borderRadius: 10,
                padding: '10px 15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}>
              <Box
                style={{
                  width: '85px',
                  height: '85px',
                  background: 'white',
                  borderRadius: '50%',
                  border: '1px #ccc solid',
                  margin: '10px 0',
                  textAlign: 'left',
                  padding: '2px',
                  display: 'flex'
                }}>
                {Image ? (
                  <Avatar className={classes.AvatarCol} src={Image} />
                ) : (
                  <ProfilePic />
                )}
              </Box>
              <div style={{ marginLeft: 15 }}>
                <h5
                  style={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: '#000',
                    margin: 0
                  }}>
                  Profile Image
                </h5>
                <p
                  onClick={handleClick}
                  style={{
                    color: '#111d4e',
                    fontSize: 12,
                    border: 'none',
                    textAlign: 'left',
                    // lineHeight: '40px',
                    cursor: 'pointer',
                    margin: 0,
                    fontWeight: 500,
                    fontStyle: 'italic'
                  }}>
                  Edit Picture
                </p>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept=" image/jpeg, image/png"
                  ref={hiddenFileInput}
                  style={customStyles}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div style={{ marginLeft: 20 }}>
                <p style={{ margin: '0px auto', fontSize: 14, marginLeft: 10 }}>
                  Consultation Fees
                </p>
                <div style={{ display: 'flex' }}>
                  <TextField
                    className={classes.Inputtext}
                    id="in_person_fees"
                    placeholder=""
                    type="number"
                    name="in_person_fees"
                    label="In-Person Consultation Fees *"
                    value={
                      formik && formik.values && formik.values.in_person_fees
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.in_person_fees &&
                        formik.errors.in_person_fees
                          ? formik.errors.in_person_fees
                          : null}
                      </div>
                    }
                  />
                  <TextField
                    className={classes.Inputtext}
                    id="tele_fees"
                    type="number"
                    placeholder=""
                    name="tele_fees"
                    label="Tele Consultation Fees *"
                    value={formik && formik.values && formik.values.tele_fees}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.tele_fees && formik.errors.tele_fees
                          ? formik.errors.tele_fees
                          : null}
                      </div>
                    }
                  />
                </div>
              </div>
            </Box>

            {/* <Box
              className={classes.Userinfo}
              style={{
                width: '100%',
                padding: '20px',
                background: '#ac128d',
                fontSize: '16px',
                color: '#fff',
                borderRadius: '20px',
                marginTop: '20px'
              }}>
              <Link to="/doctor/subscription">
                <h4 style={{ color: '#fff', paddingTop: '5px' }}>
                  View Subscription Plan
                </h4>
              </Link>
            </Box> */}
          </Grid>
          <Grid item xs={12} md={12} className={classes.Editprofileleft}>
            <h4
              style={{
                fontSize: 18,
                color: '#000',
                fontWeight: 400,
                marginBottom: 20
              }}>
              Doctor Information
            </h4>
            <TextField
              className={classes.Inputfield2}
              id="prefix"
              style={{ width: 100 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Prefix"
              type="text"
              variant="outlined"
              name="prefix"
              label="Prefix *"
              value={formik && formik.values && formik.values.prefix}
              helperText={
                <div className={classes.errMsg}>
                  {formik.touched.prefix && formik.errors.prefix
                    ? formik.errors.prefix
                    : null}
                </div>
              }
            />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Box className="update-profile">
                  <Box className="update-profile-col">
                    <TextField
                      id="firstname"
                      style={{ width: '100%' }}
                      type="text"
                      placeholder="Name"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik && formik.values && formik.values.firstname}
                      onBlur={formik.handleBlur}
                      name="firstname"
                      label="Name *"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.firstname && formik.errors.firstname
                            ? formik.errors.firstname
                            : null}
                        </div>
                      }
                    />
                  </Box>
                  <Box className="update-profile-col">
                    <TextField
                      id="username"
                      style={{ width: '100%' }}
                      type="text"
                      variant="outlined"
                      placeholder="User name"
                      value={formik && formik.values && formik.values.username}
                      name="username"
                      label="User name *"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // disabled={true}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.username && formik.errors.username
                            ? formik.errors.username
                            : null}
                        </div>
                      }
                      // disabled="true"
                    />
                  </Box>
                  <Box className="update-profile-col">
                    <TextField
                      id="user_short_code"
                      style={{ width: '100%' }}
                      placeholder="user short code"
                      name="user_short_code"
                      variant="outlined"
                      label="User short code *"
                      value={
                        formik && formik.values && formik.values.user_short_code
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.user_short_code &&
                          formik.errors.user_short_code
                            ? formik.errors.user_short_code
                            : null}
                        </div>
                      }
                      // disabled="true"
                    />
                  </Box>
                  <Box className="update-profile-col">
                    <TextField
                      id="email"
                      variant="outlined"
                      style={{ width: '100%' }}
                      placeholder="jane@gmail.com"
                      name="email"
                      label="Email *"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik && formik.values && formik.values.email}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : null}
                        </div>
                      }
                      // disabled="true"
                    />
                  </Box>
                  <Box className="update-profile-col">
                    {/* <PhoneInput
                      placeholder="Enter phone number *"
                      international
                      id="mobile_no"
                      // label="Mobile No*"
                      value={inputValue}
                      // style={{
                      //   width: '100%',
                      //   paddingLeft: '8px',
                      //   paddingTop: '30px',
                      //   // border:"none",
                      //   // borderBottom:"1px solid #9E9E9E"
                      // }}
                      className={classes.inputChange}
                      name="mobile_no"
                      onChange={phoneChange}
                      error={
                        formik.values.mobile_no
                          ? isValidPhoneNumber(formik.values.mobile_no)
                            ? undefined
                            : 'Invalid phone number'
                          : 'Phone number required'
                      }
                      // disabled="true"
                    />
                    {
                      <div className={classes.errMsg2}>
                        {formik.touched.mobile_no && formik.errors.mobile_no
                          ? formik.errors.mobile_no
                          : null}
                      </div>
                    } */}
                    <TextField
                      id="contact"
                      name="mobile_no"
                      variant="outlined"
                      label="Enter phone number *"
                      placeholder="9870568432"
                      className={classes.Input}
                      value={formik.values.mobile_no}
                      // inputProps={{ maxlength: 10 }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={indianFlag}
                              alt=""
                              width="25"
                              height="25"
                              style={{ marginRight: '0.4rem' }}
                            />
                            +91
                          </InputAdornment>
                        )
                      }}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.mobile_no && formik.errors.mobile_no
                            ? formik.errors.mobile_no
                            : null}
                        </div>
                      }
                    />
                  </Box>
                  <Box className="update-profile-col">
                    <TextField
                      id="degree"
                      style={{ width: '100%' }}
                      type="text"
                      variant="outlined"
                      name="degree"
                      label="Degree *"
                      placeholder="Degree"
                      value={formik && formik.values && formik.values.degree}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.degree && formik.errors.degree
                            ? formik.errors.degree
                            : null}
                        </div>
                      }
                      // style={{ paddingTop: '18px' }}
                    />
                  </Box>
                  <Box className="update-profile-col">
                    <TextField
                      id="experience"
                      style={{ width: '100%' }}
                      type="text"
                      variant="outlined"
                      placeholder="5"
                      label="Experience *"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik && formik.values && formik.values.experience
                      }
                      name="experience"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.experience && formik.errors.experience
                            ? formik.errors.experience
                            : null}
                        </div>
                      }
                    />
                  </Box>
                  <Box className="update-profile-col">
                    <TextField
                      id="specialization"
                      style={{ width: '100%' }}
                      type="text"
                      variant="outlined"
                      name="specialization"
                      label="Specialization *"
                      value={
                        formik && formik.values && formik.values.specialization
                      }
                      placeholder="Specalization"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.specialization &&
                          formik.errors.specialization
                            ? formik.errors.specialization
                            : null}
                        </div>
                      }
                    />
                  </Box>
                  <Box className="update-profile-col">
                    <TextField
                      id="password"
                      variant="outlined"
                      style={{ width: '100%' }}
                      autoComplete="new-password"
                      type="password"
                      name="password"
                      label="Password"
                      value={formik && formik.values && formik.values.password}
                      placeholder="Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.password && formik.errors.password
                            ? formik.errors.password
                            : null}
                        </div>
                      }
                    />
                  </Box>
                  <Box className="update-profile-col">
                    <TextField
                      id="confirmpassword"
                      variant="outlined"
                      autoComplete="off"
                      style={{ width: '100%' }}
                      type="password"
                      name="confirmpassword"
                      label="Confirm password"
                      value={
                        formik && formik.values && formik.values.confirmpassword
                      }
                      placeholder="Confirm Password"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.errors.confirmpassword
                            ? formik.errors.confirmpassword
                            : null}
                        </div>
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.Inputfield2}
                id="associated_with"
                style={{ width: '98%' }}
                type="text"
                variant="outlined"
                label="Associated With *"
                value={formik && formik.values && formik.values.associated_with}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="associated_with"
                placeholder="Associated With"
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.associated_with &&
                    formik.errors.associated_with
                      ? formik.errors.associated_with
                      : null}
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.Inputfield2}
                id="bio"
                style={{ width: '98%' }}
                type="text"
                variant="outlined"
                label="Bio *"
                value={formik && formik.values && formik.values.bio}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="bio"
                placeholder="Bio"
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.bio && formik.errors.bio
                      ? formik.errors.bio
                      : null}
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.Inputfield2}
                disabled="true"
                id="clinic_name"
                variant="outlined"
                style={{ width: '98%' }}
                type="text"
                label="Clinic Name"
                value={formik && formik.values && formik.values.clinic_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="clinic_name"
                placeholder="Clinic name"
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.clinic_name && formik.errors.clinic_name
                      ? formik.errors.clinic_name
                      : null}
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.Inputfield2}
                id="clinic_address"
                disabled="true"
                variant="outlined"
                style={{ width: '98%' }}
                type="text"
                label="Clinic address"
                value={formik && formik.values && formik.values.clinic_address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="clinic_address"
                placeholder="Clinic address"
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.clinic_address &&
                    formik.errors.clinic_address
                      ? formik.errors.clinic_address
                      : null}
                  </div>
                }
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <button
              type={'submit'}
              style={{
                width: '140px',
                borderRadius: '8px',
                background:
                  'linear-gradient(to right bottom, #3267F1, #3E3BE8)',
                border: 'none',
                height: '40px',
                textAlign: 'center',
                color: '#fff',
                lineHeight: '40px',
                cursor: 'pointer'
              }}>
              Update
            </button>
          </Grid>
          {/* </Grid> */}
        </Grid>
      </form>
      {/* </Grid> */}
    </div>
  )
}

const validationSchema = yup.object().shape({
  firstname: yup
    .string()
    // .trim()
    .required('Name is required')
    // .matches(SINGLE_WHITESPACE, 'Remove extra spaces')
    .max(30, 'Max 30 characters allowed')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  username: yup
    .string()
    // .trim()

    .max(50, 'Max 50 characters allowed')
    .required('Username is required')
    .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),

  email: yup
    .string()
    // .trim()
    .max(50, 'Max 50 characters allowed')
    .email('Invalid email address')
    .required('Required')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  mobile_no: yup
    .string()
    // .trim()
    .max(14, 'You may enter up to 14 digits')
    .matches(
      VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
      'Please enter a valid mobile number'
    )
    // .matches(VALIDATE_PHONE, 'Please enter a valid mobile number with +91')
    .required('Phone number is Required')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  password: yup
    .string()
    // .trim()

    .min(6, 'Password cannot be less than 6 characters')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  confirmpassword: yup
    .string()
    // .trim()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    })
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  bio: yup
    .string()
    // .trim()
    .required('Bio is required')
    .max(200, 'Max 200 characters allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  user_short_code: yup
    .string()
    // .trim()

    .required('User short code is required')
    // .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),
    .max(30, 'Max 30 characters allowed')
    .matches(SHORT_CODE, 'Invalid short code')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  degree: yup
    .string()
    // .trim()
    .required('Degree is required')
    .max(150, 'Max 150 characters allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    )
    .matches(/^([^0-9]*)$/, 'Digits are not allowed'),

  prefix: yup
    .string()
    // .trim()
    .max(15, 'You may enter up to 15 characters')
    .required('Prefix is required')
    .matches(/^([^0-9]*)$/, 'Special characters are not allowed'),
  // .matches(/[!@#$%^&*(),.?":{}|<>`]/g, 'Special characters are not allowed'),

  specialization: yup
    .string()
    // .trim()

    .required('Specalization is required')
    .max(150, 'Max 150 characters allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  experience: yup
    .string()
    .required('Experience is required')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .max(2, 'Experience should be within 0-99 years')
    .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),

  clinic_name: '',
  // yup
  //   .string()
  //   // .trim()
  //   // .required('Clinic Name is required')
  //   .max(100, 'Max 100 characters allowed')
  //   .matches(
  //     VALIDATE_WHITESPACES,
  //     'Space should not come before and after text!'
  //   ),

  clinic_address: '',
  // yup
  // .string()
  // // .trim()
  // // .required('Clinic Address is required')
  // .max(100, 'Max 100 characters allowed')
  // .matches(
  //   VALIDATE_WHITESPACES,
  //   'Space should not come before and after text!'
  // ),

  in_person_fees: yup
    .number()
    .moreThan(-1, 'Fees cannot be a negative value')
    .lessThan(100000, 'Fees cannot exceed 1 Lakh')
    .required('In-person fees is required'),
  tele_fees: yup
    .number()
    .moreThan(-1, 'Fees cannot be a negative value')
    .lessThan(100000, 'Fees cannot exceed 1 Lakh')
    .required('Tele fees is required'),
  associated_with: yup
    .string()
    // .trim()
    .required('Association is required')
    .max(100, 'Max 100 characters allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    )
})

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  Editprofileleft: {
    backgroundColor: '#fff',
    borderRadius: 15,
    marginBottom: 20,
    padding: '25px !important'
  },
  Editprofileright: {
    backgroundColor: '#fff',
    borderRadius: 15,
    marginBottom: 20,
    padding: '25px !important'
  },
  root: {
    backgroundColor: 'transparent',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },

  Inputfield: {
    color: '#000 !important',
    border: '1px #ccc solid',
    borderRadius: 8,
    height: 47,
    padding: '0 10px',
    '& .MuiInput-underline:before': {
      border: 0
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000 !important'
    },
    '@media (max-width:767px)': {
      width: '100% !important'
    },
    '& label': {
      color: '#666',
      padding: '10px 0 0 10px'
    }
  },
  aside: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent'
    }
    // padding: '25px 0 !important'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  errMsg: {
    color: 'red'
  },
  errMsg2: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingTop: '4px',
    fontWeight: 'bolder'
  },
  Inputtext: {
    color: '#000 !important',
    border: '1px #ccc solid',
    borderRadius: 8,
    height: 47,
    padding: '0 10px',
    '& .MuiInput-underline:before': {
      border: 0
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000 !important'
    },
    '& label': {
      color: '#666',
      padding: '10px 0 0 10px'
    },
    '& .MuiInput-input': {
      color: '#000 !important'
    }
  },
  Editprofilecol: {
    padding: '20px',
    '@media (max-width:767px)': {
      padding: '0 15px 15px 0'
    }
  },

  paper: {
    position: 'absolute',
    width: 400,
    // backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 300,
    backgroundColor: 'transparent'
  },
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
        0 2px 2px rgba(0,0,0,0.12), 
        0 4px 4px rgba(0,0,0,0.12), 
        0 8px 8px rgba(0,0,0,0.12),
        0 16px 16px rgba(0,0,0,0.12)`,
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex'
  },
  image: {
    width: '100%',
    height: '100%'
  },
  inputChange: {
    width: '100%',
    paddingLeft: '8px',
    paddingTop: '22px',
    '& .PhoneInputInput': {
      fontSize: '16px',
      border: 'none',
      borderBottom: '1px solid #9E9E9E',
      margin: '10px'
    }
  },
  Userinfo: {
    padding: '20px',
    '@media (max-width:1024px)': {
      padding: '15px'
    }
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  AppBar: {
    '& .MuiBox-root': {
      padding: '0'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    checkboxes: {
      marginTop: '15px'
    }
  },
  phoneField: {
    height: '100px'
  }
}))
