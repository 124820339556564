import React from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { DashboardLayout } from '../../patientLayouts'
import PatientDetails from '../../components/PatientDetails/PatientDetails'
import { useParams } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'

const PatientLanding = () => {
  const params = useParams()
  const classes = useStyles()
  return <DashboardLayout></DashboardLayout>
}

export default PatientLanding

const useStyles = makeStyles(() => ({
  Backbtn: {
    marginRight: '20px',
    background: 'none',
    border: '0px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    cursor: 'pointer',
    marginBottom: '15px',
    '@media (max-width:550px)': {
      marginBottom: '0px'
    }
  },
  Backicon: {
    marginRight: '5px'
  }
}))
