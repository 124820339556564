import React from 'react'

export const DoctorSteps = [
  {
    title: 'Welcome to CareNowDoc',
    target: 'body',
    placement: 'center',
    content: (
      <div>
        <h4>The simple teleconsultation platform</h4>
        <p>Hope you enjoy this platform to the fullest designed by Carenow</p>
      </div>
    ),
    disableBeacon: true
  },
  {
    title: 'This is the menu',
    target: '.menu-view',
    content: 'Look, this is your menu',
    placement: 'right-start'
  },
  {
    title: 'This is the dashboard',
    target: '.dashboard-menu',
    content: 'It displays all the dashboard options',
    placement: 'right-start'
  },
  {
    title: 'This is the appointment menu',
    target: '.appointments-menu',
    content:
      'It displays all your past, upcoming, reschduled and followup appointments',
    placement: 'right-start'
  },
  {
    title: 'This is the patients menu',
    target: '.patients-menu',
    content: 'It displays all your patients',
    placement: 'right-start'
  },
  {
    title: 'This is the accounts menu',
    target: '.accounts-menu',
    content: 'It displays all your account details',
    placement: 'right-start'
  },
  {
    title: 'This is the scheduler menu',
    target: '.scheduler-menu',
    content: 'It displays all your schedules and appointments',
    placement: 'right-start'
  },
  {
    title: 'This is the clinical insights menu',
    target: '.clinical-insights-menu',
    content: 'It displays all your clinical insights',
    placement: 'right-start'
  },
  {
    title: 'This is the communication center menu',
    target: '.communication-menu',
    content: 'It displays all your sms sending options',
    placement: 'right-start'
  },
  {
    title: 'This is the Profile card menu',
    target: '.profile-menu',
    content: 'It displays your Profile card',
    placement: 'right-start'
  },
  {
    title: 'This is the update profile-menu menu',
    target: '.update-profile-menu',
    content: 'It displays options to update your profile details',
    placement: 'right-start'
  },
  {
    title: 'This is the Sketch menu',
    target: '.sketch-menu',
    content: 'It displays your sketch portal',
    placement: 'right-start'
  }
]
