import React, { useState } from 'react'
import { colors } from '@material-ui/core'

import TextField from '../../components/TextField'

const TextArea = ({ label, id, rows = 2, maxLength = null, ...rest }) => {
  id = id || label

  const [focused, setFocused] = useState(false)

  return (
    <div>
      <label
        htmlFor={id}
        style={{
          color: rest.error
            ? colors.red[500]
            : focused
            ? 'rgb(17, 29, 78)'
            : '#9b9b9b',
          display: 'block',
          marginBottom: '5px',
          fontWeight: '500'
        }}>
        {label}
      </label>
      <TextField
        className="textarea"
        id={id}
        label=""
        multiline
        variant="outlined"
        rows={rows}
        InputProps={{
          onFocus: () => {
            setFocused(true)
          },
          onBlur: e => {
            if (rest.onBlur) {
              rest.onBlur(e)
            }
            setFocused(false)
          }
        }}
        style={{
          width: '100%',
          borderColor: 'rgba(0, 0, 0, 0.92)'
        }}
        {...rest}
      />
      {maxLength && (
        <div
          style={{
            color:
              rest?.value.trim().length > maxLength
                ? colors.red[500]
                : focused
                ? 'rgb(17, 29, 78)'
                : '#9b9b9b',
            fontSize: '70%',
            fontWeight: 'bold',
            textAlign: 'right',
            paddingTop: '0.3rem',
            marginTop: rest.error ? '-1.4rem' : 0
          }}>{`${rest?.value.trim().length} / ${maxLength}`}</div>
      )}
    </div>
  )
}

export default TextArea
