import React, { useEffect } from 'react'
import { Snackbar, Grid, makeStyles, colors } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Button from '../../../components/Button'
import Page from '../../../components/Page'
import { NETWORK_STATUS } from '../../../../Constants'
import Loader from '../../../components/ClinicLoader'
import { SuperClinicStore } from '../../../../stores'
import { SUPER_CLINIC_USER_ID } from '../../../../Constants'
import EditField from './EditField'

const useStyles = makeStyles(() => ({
  root: {},
  errMsg: {
    color: 'red'
  },
  input: {
    display: 'none'
  },
  MyaccountTab: {
    boxShadow: 'none',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
      '& button': {
        minWidth: '33%'
      }
    }
  }
}))

const PocDetails = () => {
  const classes = useStyles()
  const history = useHistory()

  //Store state
  const {
    pocinfo,
    networkStatus,
    reload,
    message,
    messageToggle
  } = SuperClinicStore.useStoreState(state => ({
    ...state.pocDetails
  }))

  //Store action
  const {
    getPocDetails,
    editPocDetails,
    setReload,
    setMessage
  } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.pocDetails
  }))

  useEffect(() => {
    console.log('use effect executed')
    if (reload) {
      getPocDetails({
        super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID)
      })
    }
    setReload(false)
  }, [reload])

  const handleClose = (event, reason) => {
    setMessage({
      message: '',
      messageToggle: false
    })
  }

  //Initial value for forms
  const initialValues = {
    pocname: pocinfo.poc_name,
    pocPhone: pocinfo.poc_phone_no?.replace('+91', ''),
    pocEmail: pocinfo.poc_email_id
  }

  //Form validation
  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/gm

  const formValidation = Yup.object({
    pocname: Yup.string().required('POC Name required'),
    pocPhone: Yup.string()
      .matches(phoneRegExp, 'Phone  is not valid')
      .required('Phone No. required'),
    pocEmail: Yup.string().email().required('Email required')
  })

  //handel form submit
  const submitHandler = val => {
    editPocDetails({
      poc_email_id: val.pocEmail,
      poc_phone_no: val.pocPhone,
      point_of_contact_name: val.pocname,
      poc_phone_country_code: '+91'
    })
  }

  return (
    <Page className={classes.root}>
      <Loader open={networkStatus === NETWORK_STATUS.FETCHING} />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={messageToggle}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
      />
      <Box className="main-content-coll">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: 'flex',
              paddingTop: '30px',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <h3 style={{ marginBottom: '0px' }}>Super Clinic POC Details</h3>
            <p
              style={{
                fontStyle: 'italic',
                fontSize: '85%',
                fontWeight: 'normal',
                color: colors.grey[500]
              }}>
              Click on any field to edit!
            </p>
          </Grid>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={val => {
              submitHandler(val)
            }}
            validationSchema={formValidation}>
            {props => {
              console.log(props.dirty)
              return (
                <Grid item lg={12} xs={12} sm={12}>
                  <Form>
                    <Grid item xs={12} sm={12}>
                      <Box
                        className="account-details"
                        style={{ marginBottom: '0px', width: '100%' }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <div className="form-group">
                              <EditField
                                name={'pocname'}
                                info={pocinfo.poc_name}
                                lable={'Poc Name'}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <div className="form-group">
                              <EditField
                                phone={true}
                                name={'pocPhone'}
                                info={pocinfo.poc_phone_no}
                                lable={'Poc Phone No.'}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <div className="form-group">
                              <EditField
                                name={'pocEmail'}
                                info={pocinfo.poc_email_id}
                                lable={'Poc Email ID'}
                              />
                            </div>
                          </Grid>

                          {props.dirty ? (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                              }}>
                              <Button
                                variant="contained"
                                style={{
                                  padding: '0 1.5rem',
                                  height: '40px',
                                  fontSize: '12px',
                                  color: '#cd4b4b',
                                  background: '#ccc',
                                  marginRight: '15px'
                                }}>
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                type="submit"
                                style={{
                                  padding: '0 1.5rem',
                                  height: '40px',
                                  fontSize: '12px'
                                }}>
                                Save
                              </Button>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Form>
                </Grid>
              )
            }}
          </Formik>
        </Grid>
      </Box>
    </Page>
  )
}

export default PocDetails
