import Appointments from './Appointments'
import Patients from './Patients'
import Profile from './Profile'
import PatientsList from './PatientsList'
import NewScheduler from './SchedulerData'

export default {
  appointments: Appointments,
  profile: Profile,
  patients: Patients,
  my_scheduler: NewScheduler,
  patientsList: PatientsList
}
