import React, { useState } from 'react'
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Error } from '@material-ui/icons'

import { NETWORK_STATUS } from '../../../../../Constants'

const RecommendedServices = ({
  servicesList,
  disabled,
  loadStatus,
  handleClick
}) => {
  const classes = useStyles()

  const [
    selectedRecommendedService,
    setSelectedRecommenededService
  ] = useState({ service_name: null, id: '' })

  const [
    selectedRecommendedServiceInputValue,
    setSelectedRecommendedServiceInputValue
  ] = useState('')

  const handleServiceChange = (e, newValue) => {
    if (!newValue) {
      setSelectedRecommenededService({ service_name: null, id: '' })
      return
    }
    setSelectedRecommenededService(newValue)
    setSelectedRecommendedServiceInputValue(newValue.service_name)
  }

  const handleServiceInputChange = (event, newInputValue, reason) => {
    if (reason === 'reset') {
      return
    } else {
      if (selectedRecommendedService.service_name !== newInputValue) {
        setSelectedRecommenededService({ service_name: null, id: '' })
      }
      setSelectedRecommendedServiceInputValue(newInputValue)
    }
  }

  const handleAddButtonClick = () => {
    handleClick(selectedRecommendedService.id)
    setSelectedRecommenededService({ service_name: null, id: '' })
    setSelectedRecommendedServiceInputValue('')
  }

  const options = servicesList
    .filter(service => service.status !== '1')
    .map(option => {
      const firstLetter = option.service_name[0].toUpperCase()
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        key: option.id,
        ...option
      }
    })

  return (
    <div className={classes.recommendedServicesSection}>
      <h3>Recommended Services</h3>
      <div className={classes.recommendedServicesGrid}>
        <Autocomplete
          id="grouped-demo"
          options={options.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          disableClearable
          value={selectedRecommendedService.service_name}
          onChange={handleServiceChange}
          inputValue={selectedRecommendedServiceInputValue}
          onInputChange={handleServiceInputChange}
          getOptionLabel={option => option.service_name}
          style={{ display: 'block', width: '100%' }}
          size="small"
          renderInput={params => (
            <TextField
              {...params}
              disabled={disabled}
              label="Select from services"
              variant="outlined"
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={
            disabled ||
            !selectedRecommendedService ||
            !selectedRecommendedService.id ||
            selectedRecommendedService.id === ''
          }
          style={{ height: '2.5rem' }}
          onClick={handleAddButtonClick}>
          Add
        </Button>
      </div>

      {loadStatus === NETWORK_STATUS.FETCHING ? (
        <Typography
          variant="caption"
          color="primary"
          className={classes.hintText}>
          <CircularProgress size={20} />
          Loading...
        </Typography>
      ) : (
        loadStatus === NETWORK_STATUS.FAILED && (
          <Typography
            variant="caption"
            color="error"
            className={classes.hintText}>
            <Error />
            Oops! Something went wrong. Try again later!
          </Typography>
        )
      )}
    </div>
  )
}

export default RecommendedServices

const useStyles = makeStyles(theme => ({
  recommendedServicesSection: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  recommendedServicesGrid: {
    display: 'grid',
    gridTemplateColumns: 'minmax(10rem, 1fr) max-content',
    alignItems: 'center',
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: '0.4rem'
  },
  hintText: {
    display: 'grid',
    gridColumnGap: '0.5rem',
    columnGap: '0.5rem',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    lineHeight: '1',
    alignItems: 'center'
  }
}))
