import React from 'react'
import PropTypes from 'prop-types'
import { Box, AppBar, Tabs, Tab, makeStyles, useTheme } from '@material-ui/core'
import UpcomingAppointments from './UpcomingAppointments'
import PastAppointments from './PastAppointments'
import FollowupAppointments from './FollowupAppointments'
import RescheduledAppointments from './RescheduledAppointments'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

export default function Appointment() {
  const classess = useStyless()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const [upcomingType, setType] = React.useState('')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  React.useEffect(() => {
    setType(localStorage.getItem('upcomingType'))
  }, [])

  React.useEffect(() => {
    // localStorage.removeItem("upcomingType")
    if (upcomingType === 'tele' || upcomingType === 'inPerson') {
    }
  }, [upcomingType])

  // const handleChangeIndex = index => {
  //   setValue(index)
  // }

  return (
    <div>
      <h2
        style={{
          fontSize: 20,
          fontWeight: 500,
          color: '#000',
          marginTop: '0px',
          marginBottom: 30,
          letterSpacing: 1
        }}>
        Appointment List
      </h2>
      <AppBar
        className={classess.Appointmenttab}
        position="static"
        color="default"
        style={{
          boxShadow: 'none',
          background: 'none',
          borderBottom: '1px rgba(25,40,82,0.1) solid'
        }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="white"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className="appointment-tab">
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Upcoming Appointments"
            {...a11yProps(0)}
          />
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Past Appointments"
            {...a11yProps(1)}
          />
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Followup Appointments"
            {...a11yProps(2)}
          />
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Rescheduled Appointments"
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <div
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        // onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <UpcomingAppointments upcomingType={upcomingType} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <PastAppointments />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <FollowupAppointments />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <RescheduledAppointments />
        </TabPanel>
      </div>
    </div>
  )
}

const useStyless = makeStyles(() => ({
  checkboxes: {
    marginTop: '15px',
    '& label > span': {
      fontSize: '14px'
    },
    '& span > svg': {
      width: '0.8em',
      height: '0.8em'
    }
  },
  paper: {
    padding: '20px 30px',
    width: '100%',
    position: 'relative',
    borderRadius: '10px',
    '@media (max-width:767px)': {
      padding: '20px'
    }
  }
}))
