import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Typography,
  InputAdornment,
  Button
} from '@material-ui/core'
import Footer from '../../components/CommonComponents/Footer'
import PublicNavbar from '../../components/CommonComponents/PublicNavbar'
import DashboardLayoutImg from '../../images/newprofilebg.png'
import BookHead from '../../images/bookhead.png'
import DoctorImage from '../../images/doctor-image.png'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useFormik } from 'formik'
import { useForm, Controller } from 'react-hook-form'
import OTPComponent from '../../components/CommonComponents/OtpComponent'
import PrimaryInputField from '../../components/CommonComponents/PrimaryInputField'
import { PublicPatientStore, PatientStore } from '../../stores'
import {
  VALIDATE_PHONE,
  PATIENT_ID,
  PATIENT_USER_ID,
  LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME,
  VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE
} from '../../Constants'
import Loader from '../../components/CommonComponents/Loader'
import localforage from 'localforage'
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber
} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isEmpty } from 'lodash'
import PrimaryButton from '../../components/CommonComponents/PrimaryButton'
import ResendOTPDialogBox from '../../components/CommonComponents/ResendOTPDialog'

import indianFlag from '../../images/flag.png'

const useStyles = makeStyles(theme => ({
  TopNav: {
    width: '100%'
    // position:"absolute"
  },
  footercol: {
    width: '100%',
    paddingRight: '5%',
    display: 'flex'
  },
  ProfilePicture: {
    width: '100px',
    height: '100px',
    borderRadius: '50%'
  },
  LoginLeftcol: {
    padding: '45px',
    '@media (max-width:767px)': {
      padding: '20px'
    },
    '& .MuiTextField-root': {
      width: '100%'
    }
  },
  bottomMargin: {
    marginBottom: '0px !important'
  },
  DoctorProfile: {
    width: '100%',
    background: `url(${BookHead}) center top`,
    // padding: "15px",
    display: 'flex',
    height: '150px',
    backgroundPosition: 'right',
    paddingBottom: '20px',
    marginTop: '90px',
    position: 'relative',
    marginBottom: '50px',
    flexWrap: 'wrap',
    '@media (max-width:767px)': {
      height: '585px',
      backgroundRepeat: 'no-repeat',
      marginTop: '80px',
      background: 'none'
    }
  },
  DetailInfo: {
    color: '#fff',
    fontSize: '15px',
    borderLeft: '1px solid',
    margin: '30px auto 0',
    '@media (max-width:767px)': {
      borderLeft: 'none',
      backgroundColor: '#DDE8FF',
      paddingRight: '20px',
      color: '#000',
      borderRadius: '20px'
    },
    '&>div': {
      display: 'flex'
    },
    '& h5': {
      marginTop: '20px',
      marginLeft: '20px',
      fontSize: '15px',
      marginBottom: '10px',
      '@media (max-width:767px)': {
        color: '#000'
      }
    },
    '& h4': {
      marginLeft: '20px',
      marginTop: '5px',
      marginBottom: '0px',
      '@media (max-width:767px)': {
        color: '#000'
      }
    }
  },
  Datecol: {
    '& h4': {
      color: '#fff',
      marginLeft: '20px',
      marginTop: '5px',
      marginBottom: '0px',
      '@media (max-width:767px)': {
        color: '#000',
        marginBottom: '20px'
      }
    }
  },
  CameraIcon: {
    color: '#a0d65e',
    fontSize: '24px',
    marginRight: '10px'
  },
  ProfileInfo: {
    paddingLeft: '50px',
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h4': {
      color: '#a0d65e',
      fontSize: '16px',
      marginBottom: '0px'
    },
    '& p': {
      fontSize: '16px',
      color: '#fff',
      marginBottom: '0px',
      '@media (max-width:767px)': {
        color: '#000'
      }
    },
    '& h3': {
      fontSize: '20px',
      color: '#fff',
      marginBottom: '0px',
      marginTop: '0px',
      '@media (max-width:767px)': {
        color: '#000'
      }
    }
  },
  InfoText: {
    width: '100%',
    padding: '30px 0',
    '& h3': {
      fontSize: '22px',
      color: '#3f4c79',
      fontWeight: 'bold',
      '& span': {
        width: '200px',
        fontSize: '16px',
        fontWeight: 'normal',
        float: 'left'
      }
    }
  },
  DoctorActive: {
    color: '#fff',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '30px'
  },
  DoctorSearch: {
    width: '96%',
    marginTop: '-50px',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    background: '#FAFAFA',
    // background: `url(${DashboardLayoutImg})`,
    backgroundAttachment: 'fixed',
    overflowX: 'hidden',
    paddingLeft: '40px',

    '@media (max-width:767px)': {
      padding: '100px 20px;',
      marginTop: '-180px',
      width: '90%'
    }
  },
  BtnCol: {
    width: '100%',
    padding: '0 0 15px',
    '& p': {
      color: '#3f4c79',
      marginBottom: '0px'
    },
    '& h4': {
      fontSize: '20px',
      color: '#3f4c79',
      marginBottom: '20px'
    }
  },
  ProceedBtn: {
    width: '135px',
    height: '40px',
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
    borderRadius: '8px',
    textAlign: 'center',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '40px',
    border: 'none',
    cursor: 'pointer'
  },
  DisabledAddBtn: {
    width: '135px',
    height: '40px',
    background: '#adadad',
    borderRadius: '40px',
    textAlign: 'center',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '40px',
    border: 'none',
    cursor: 'pointer'
  },
  ContentText: {
    fontSize: '16px',
    color: '#979797'
  },
  ContentCol: {
    width: '100%',
    minHeight: '500px',
    padding: '40px 15px',
    '@media (max-width:767px)': {
      width: '100%',
      padding: '40px 0px'
    }
  },
  FormElement: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '15px',
    '& p': {
      marginBottom: '0px',
      fontSize: '16px',
      marginRight: '15px'
    }
  },
  InputRow: {
    width: '100%',
    marginBottom: 0
  },
  InputCol: {
    width: '100%'
  },
  formControl: {
    width: '120px',
    marginRight: '15px'
  },
  LoginLeft: {
    width: '80%',
    backgroundColor: '#fff',
    borderRadius: '20px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    padding: '4%',
    '@media (max-width:767px)': {
      width: '90%',
      padding: '5%'
    }
  },
  errMsg: {
    color: 'red'
  },
  BottomTent: {
    width: '100%',
    marginTop: '60px',
    textAlign: 'center',
    '& p': {
      color: '#000',
      fontWeight: '600'
    }
  },
  TopCol: {
    display: 'flex',
    '& h3': {
      width: '60%',
      fontSize: '20px',
      fontWeight: '500'
    }
  },
  errMsg2: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingTop: '4px'
  },
  inputChange: {
    width: '100%',
    paddingLeft: '8px',
    paddingTop: '22px',
    '& .PhoneInputInput': {
      border: 'none',
      borderBottom: '1px solid #9E9E9E',
      margin: '10px'
    }
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '80%'
  }
}))

const LoginStep = props => {
  const onSuccess = () => {}
  const classes = useStyles()

  const [formData, setData] = useState({})
  const [otpRegister, setOtpRegister] = useState('')
  const docDetails = JSON.parse(localStorage.getItem('selected_doctor'))
  const [loginLoader, setLoginLoader] = useState(false)
  const [loginDetail, setLoginDetail] = useState(null)
  const [account, setAccount] = useState(true)
  const { control, handleSubmit, errors } = useForm()
  const [phoneInput, setPhoneInput] = useState('')
  const [otpTime, setOtpTime] = React.useState(null)

  const [resendOTPDialog, setResendOTPDialog] = React.useState(false)
  const [resendOTPvia, setResendOTPvia] = React.useState('text')

  const [otpSent, setOtpSent] = useState(false)

  const { login, registerOtp, postResendOtp, updateResponse } =
    PublicPatientStore.useStoreActions(actions => ({
      ...actions.auth
    }))
  const setPatientProfileData = PatientStore.useStoreActions(
    actions => actions.profile.setData
  )
  const { doPatientRegistration } = PatientStore.useStoreActions(actions => ({
    ...actions.profile
  }))
  const { networkStatus, otpMsg, resendOtp } = PublicPatientStore.useStoreState(
    state => ({
      ...state.auth
    })
  )

  const { message } = PatientStore.useStoreState(state => ({
    ...state.profile
  }))

  //on enter click bug fix
  const otpRef = React.useRef()

  useEffect(() => {
    if (message) {
      if (message.statusCode === 201) {
        Swal.fire('Success', 'Your account was successfully registered!').then(
          () => {
            login(loginDetail).then(data => {
              if (data?.user) {
                localStorage.setItem(PATIENT_USER_ID, message?.user_id)
                localStorage.setItem(PATIENT_ID, message?.patient_id)
                localStorage.setItem('new_patient', true)
                localStorage.setItem('patient_auth_key', data.user.id_token)
                const { hospital_details, user, access_specificatin } = data
                const authn = {
                  user,
                  hospital_details
                }
                localforage
                  .setItem(LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME, authn)
                  .then(() => {
                    window.location.href = '/booking/patientdetailinfo'
                  })
              }
            })
          }
        )
      }
      //receiving status code 512 and 401 for wrong otp entry attempts
      else if (message['status code'] == 512 || message['status code'] == 401) {
        Swal.fire({
          title: 'OTP Expired',
          text: 'Your OTP has expired. Please try again',
          icon: 'info'
        })
        setAccount(true)
        setOtpRegister('')
      } else {
        Swal.fire({
          title: 'Failure',
          text: message.message.replace(/['"]+/g, ''),
          icon: 'info'
        })
        setOtpRegister('')
      }
    }
  }, [message])

  const onInput = formData => {
    // console.log(formData)
    login(formData).then(data => {
      if (data?.user) {
        setPatientProfileData(data.user)
        window.location.href = '/patient/booking/selectPatient'
      }
      if (onSuccess) {
        onSuccess({ request: formData, response: data })
      }
    })
  }

  const setLoader = React.useCallback(
    value => {
      setLoginLoader(value)
    },
    [loginLoader, setLoginLoader]
  )

  const timer = React.useCallback(time => {
    let min = Math.floor(time / 60)
    let sec = time % 60

    min = min < 10 ? '0' + min : min
    sec = sec < 10 ? '0' + sec : sec

    setOtpTime(min + ':' + sec)
    time--

    if (time >= 0) {
      setTimeout(() => {
        timer(time)
      }, 1000)
      return
    }

    if (!timer) {
      return
    }

    // Swal.fire({
    //   title: 'Timeout for OTP',
    //   text: 'Please, Enter your credentials again !',
    //   icon: 'info',
    //   timer: 2000
    // })
    setOtpTime('')
  }, [])

  useEffect(() => {
    if (otpMsg) {
      if (otpMsg.statusCode === 201 || otpMsg.statusCode === 200) {
        setAccount(false)
        if (!resendOtp) {
          timer(120)
          Swal.fire(
            '',
            'OTP successfully sent on your mobile number',
            'success'
          )
        } else {
          Swal.fire({
            title: '',
            text: 'OTP resent successfully',
            icon: 'success',
            timer: 3000
          })
        }
      } else if (otpMsg.message === 'Maximum limit to RESEND OTP exceeded') {
        Swal.fire({
          title: '',
          text: otpMsg.message,
          icon: 'error',
          timer: 3000
        })
      } else {
        Swal.fire('', 'This user already exists. Please log in', 'info')
      }
      updateResponse(null)
    }
  }, [otpMsg, timer, resendOtp, updateResponse])

  const sendOtp = async values => {
    // let formData = await values
    let formData = {
      ...values,
      mobile_no: !!values.mobile_no ? `+91${values.mobile_no}` : ''
    }
    setData(formData)
    // e.preventDefault();
    localStorage.setItem('account-data', JSON.stringify(formData))
    if (formData.mobile_no == undefined || formData.mobile_no.length !== 13) {
      return Swal.fire('', 'Please enter a valid mobile number', 'error')
    }
    registerOtp({ mobile_no: formData.mobile_no })
    setOtpSent(true)
  }

  const onResendNowClicked = () => {
    postResendOtp({
      retry_type: resendOTPvia,
      channel_id: 'patient_registration'
    })
  }

  // useEffect(() => {
  //   console.log(formData)
  // }, [formData])

  const phoneChange = value => {
    setPhoneInput(value)
    formik.setFieldValue('mobile_no', value ? value : '')
  }

  const registerPatient = e => {
    e.preventDefault()
    localStorage.setItem('email', data?.email)
    let data = { ...formData }
    let loginCreds = {
      registration: true,
      email: data.email,
      username: data.email,
      password: data.password
    }
    setLoginDetail(loginCreds)
    localStorage.setItem('patient_register_details', JSON.stringify(loginCreds))
    data.username = data.email
    data.patient_otp = otpRegister
    data.mobile_no = data.mobile_no
    delete data.confirm_password
    doPatientRegistration({ ...data })
  }
  let doc_details = [JSON.parse(localStorage.getItem('schedule'))]

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      mobile_no: '',
      email: '',
      password: '',
      confirmpassword: ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: sendOtp
  })

  return (
    <div className={classes.root}>
      <ResendOTPDialogBox
        isOpen={resendOTPDialog}
        handleClose={() => setResendOTPDialog(false)}
        resendOTPViaValue={resendOTPvia}
        updateResendOTPViaValue={setResendOTPvia}
        onClickResendNow={onResendNowClicked}
      />
      <Box className={classes.TopNav}>
        <PublicNavbar />
        {/* <Navbar /> */}
      </Box>
      {networkStatus === 'FETCHING' ? (
        <div>
          <Loader open={true} />
        </div>
      ) : (
        <div>
          <Loader open={false} />
        </div>
      )}
      <Box className={classes.DoctorProfile}>
        <img src={DoctorImage} className="profile-responsiveimg" alt="" />
        <Box className={classes.ProfileInfo}>
          <h3>{docDetails?.prefix + ' ' + docDetails?.name}</h3>
          <h4>{docDetails?.specialization}</h4>
          <p>{docDetails?.degree}</p>
        </Box>
        <Box className={classes.DetailInfo}>
          {doc_details?.map(curr => {
            return (
              <React.Fragment>
                <h5>
                  Appointment Type:{' '}
                  {curr?.scheduleType === '1'
                    ? 'In-Person Consultation'
                    : 'Tele Consultation'}
                </h5>
                <h5>
                  Appointment Date:{' '}
                  {moment(
                    curr?.appointment_start_time.split(' ')[0],
                    'YYYY-MM-DD'
                  ).format('DD-MM-YYYY')}
                </h5>
                <Box className={classes.Datecol}>
                  <h4>
                    Start Time:{' '}
                    {moment(
                      curr?.appointment_start_time.split(' ')[1],
                      'hh:mm:ss'
                    ).format('hh:mm A')}
                  </h4>
                  <h4>
                    End Time:{' '}
                    {moment(
                      curr?.appointment_end_time.split(' ')[1],
                      'hh:mm:ss'
                    ).format('hh:mm A')}
                  </h4>
                </Box>
              </React.Fragment>
            )
          })}
        </Box>
      </Box>
      <div className={classes.DoctorSearch}>
        <Box className={classes.ContentCol}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className={classes.LoginLeftcol}>
              <Box className={classes.LoginLeft}>
                <h2 style={{ fontSize: 20, fontWeight: 600 }}>Login</h2>
                <form onSubmit={handleSubmit(onInput)}>
                  <Box mb={4}>
                    <Controller
                      as={PrimaryInputField}
                      name="email"
                      control={control}
                      defaultValue=""
                      placeholder="Email *"
                      rules={{ required: 'Email is required' }}
                    />
                    {errors.email ? (
                      <Typography color={'error'}>
                        {errors.email?.message}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box mb={4}>
                    <Controller
                      as={PrimaryInputField}
                      name="password"
                      type="password"
                      control={control}
                      defaultValue=""
                      placeholder="Password *"
                      rules={{ required: 'Password is required' }}
                    />
                    {errors.password ? (
                      <Typography color={'error'}>
                        {errors.password?.message}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box className={classes.BtnCol}>
                    <button type="submit" className={classes.ProceedBtn}>
                      Login
                    </button>
                  </Box>
                </form>

                <Box className={classes.BtnCol}>
                  <p style={{ marginBottom: '1.5rem' }}>Or, Request OTP</p>
                  <OTPComponent
                    loginStep={true}
                    setLoader={setLoader}
                    bookingFlow={true}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.LoginLeftcol}>
              <Box className={classes.LoginLeft} style={{ paddingBottom: 35 }}>
                <h2 style={{ fontSize: 20, fontWeight: 600 }}>
                  Don't have an account?
                </h2>

                <form onSubmit={formik.handleSubmit} noValidate={true}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Box className={classes.InputRow}>
                        <TextField
                          id="first_name"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="First Name"
                          onChange={formik.handleChange}
                          value={
                            formik && formik.values && formik.values.first_name
                          }
                          onBlur={formik.handleBlur}
                          name="first_name"
                          label="First Name *"
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.first_name &&
                              formik.errors.first_name
                                ? formik.errors.first_name
                                : null}
                            </div>
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className={classes.InputRow}>
                        <TextField
                          id="last_name"
                          style={{ width: '100%' }}
                          placeholder="Last name"
                          name="last_name"
                          label="Last Name *"
                          value={
                            formik && formik.values && formik.values.last_name
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.last_name &&
                              formik.errors.last_name
                                ? formik.errors.last_name
                                : null}
                            </div>
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.InputRow}>
                        <TextField
                          id="email"
                          style={{ width: '100%' }}
                          placeholder="jane@gmail.com"
                          name="email"
                          label="Email *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik && formik.values && formik.values.email}
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.email && formik.errors.email
                                ? formik.errors.email
                                : null}
                            </div>
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.InputRow}>
                        <TextField
                          id="contact"
                          name="mobile_no"
                          label="Enter mobile number *"
                          placeholder="9870568432"
                          // className={classes.Input}
                          value={formik.values.mobile_no}
                          style={{ width: '100%' }}
                          // inputProps={{ maxlength: 10 }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={indianFlag}
                                  alt=""
                                  width="25"
                                  height="25"
                                  style={{ marginRight: '0.4rem' }}
                                />
                                +91
                              </InputAdornment>
                            )
                          }}
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.mobile_no &&
                              formik.errors.mobile_no
                                ? formik.errors.mobile_no
                                : null}
                            </div>
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box className={classes.InputRow}>
                        <TextField
                          id="password"
                          // style={{ width: "100%" }}
                          type="password"
                          placeholder="Password"
                          onChange={formik.handleChange}
                          value={
                            formik && formik.values && formik.values.password
                          }
                          onBlur={formik.handleBlur}
                          name="password"
                          label="Password *"
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.password && formik.errors.password
                                ? formik.errors.password
                                : null}
                            </div>
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box className={classes.InputRow}>
                        <TextField
                          id="confirmpassword"
                          // style={{ width: "100%" }}
                          type="password"
                          placeholder="Confirm Password"
                          onChange={formik.handleChange}
                          value={
                            formik &&
                            formik.values &&
                            formik.values.confirmpassword
                          }
                          onBlur={formik.handleBlur}
                          name="confirmpassword"
                          label="Confirm Password *"
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.confirmpassword &&
                              formik.errors.confirmpassword
                                ? formik.errors.confirmpassword
                                : null}
                            </div>
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {/* <button className={classes.ProceedBtn} type={'submit'}>
                        Send OTP
                      </button> */}
                      <PrimaryButton
                        rounded
                        type="submit"
                        disabled={
                          formik.values.mobile_no.length < 10 ||
                          !(otpTime === null || otpTime === '') ||
                          !isEmpty(formik.errors)
                        }>
                        Send OTP
                      </PrimaryButton>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={classes.InputRow}>
                        <TextField
                          type="tel"
                          id="otp"
                          placeholder="Enter OTP"
                          name="otp"
                          label="Enter OTP"
                          value={otpRegister}
                          className={classes.InputCol}
                          onChange={e => setOtpRegister(e.target.value)}
                          inputRef={otpRef}
                          disabled={otpTime === null || otpTime === ''}
                        />
                      </Box>
                      {!(otpTime === null || otpTime === '') ? (
                        otpTime !== '00:00' ? (
                          <div className={classes.errorBox}>
                            {otpTime ? (
                              <div className={classes.errorText}>
                                Time-Left: {otpTime}
                              </div>
                            ) : null}
                          </div>
                        ) : null
                      ) : null}
                      {!(otpTime === null || otpTime === '') && (
                        <div
                          style={{
                            fontSize: '80%',
                            display: 'grid',
                            gridAutoFlow: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            lineHeight: 1,
                            margin: '1rem 0 1rem 0'
                          }}>
                          <div>Didn't receive OTP?</div>
                          <div>
                            <Button
                              onClick={() => setResendOTPDialog(true)}
                              style={{
                                height: '1rem',
                                fontSize: 'inherit',
                                color: '#091c2e',
                                lineHeight: 'inherit',
                                fontFamily: 'inherit'
                              }}>
                              Resend OTP
                            </Button>
                          </div>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {/* <button
                        className={
                          account ? classes.DisabledAddBtn : classes.ProceedBtn
                        }
                        disabled={account}
                        onClick={registerPatient}>
                        Create Account
                      </button> */}
                      <PrimaryButton
                        rounded
                        onClick={registerPatient}
                        disabled={account || otpRegister.length <= 0}>
                        Create Account
                      </PrimaryButton>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.BottomTent}>
            <p>
              Get virtual assistance at 033-418-16533 for booking appointment
            </p>
          </Box>
        </Box>
      </div>
      <div className={classes.footercol}>
        <Footer />
      </div>
    </div>
  )
}

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .max(35, 'You may enter up to 35 characters')
    .required('First Name is required'),
  last_name: yup
    .string()
    .trim()
    .max(70, 'You may enter up to 70 characters')
    .required('Last Name is required'),
  mobile_no: yup
    .string()
    .matches(
      VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
      'Please enter a valid mobile number'
    )
    // .matches(VALIDATE_PHONE, 'Please enter a valid mobile number with +91')
    .max(14, 'You may enter up to 14 digits')
    .required('Mobile number is required'),
  email: yup
    .string()
    .email('Invalid email')
    .max(254, 'You have exceeded the character limit')
    .required('Email-Id is required'),
  password: yup.string().trim().min(6).required('Password is required'),
  confirmpassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
})
export default LoginStep
