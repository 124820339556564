import React, { useEffect } from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import Page from '../../../components/Page'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Slots from './Slots'
import PatientSelection from './PatientSelection'
import { ClinicStore } from '../../../../stores'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  containerCol:{
    width:'95%',
    background:'#fff',
    borderRadius:'10px'
  }
}))

const BookingContent = () => {
  const location = useLocation()
  const classes = useStyles()
  const [docAssociationId, setDoctorAssociationId] = React.useState(null)
  const [doctor, setDoctor] = React.useState('null')
  const { getDoctorList } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))
  const { doctorList } = ClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))

  useEffect(() => {
    // localStorage.removeItem('clinic_booking_details')
    // localStorage.removeItem('selectedSchedule')
    getDoctorList({ filterInactive: true })
  }, [])

  useEffect(() => {
    if (doctorList.length !== 0) {
      setDoctorAssociationId(doctorList[0].clinic_doctor_association)
      setDoctor(doctorList[0].id)
    }
  }, [doctorList])

  const handleChange = event => {
    let docId = event.target.value
    doctorList.map(item => {
      if (item.id === docId) {
        setDoctorAssociationId(item.clinic_doctor_association)
      }
    })
    setDoctor(docId)
  }

  return (
    <Page className={classes.root} title="book Doctors">
      <Container maxWidth={false} className={classes.containerCol}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h5"
              style={{ color: '#111d4e' }}>
              Book An Appointment
            </Typography>
          </Grid>
          {location.pathname == '/clinic/booking/patient_selection' ? (
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <PatientSelection />
            </Grid>
          ) : (
            <>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Doctor
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={doctor}
                    MenuProps={{
                      style: { top: '50px' }
                    }}
                    disabled={doctorList.length == 0}
                    onChange={handleChange}
                    label="Select Doctor">
                    {doctorList.map(item => {
                      return (
                        <MenuItem value={item.id}>{item.doctor_name}</MenuItem>
                      )
                    })}
                    {doctorList.length == 0 ? (
                      <MenuItem value={'null'}>No Doctor Found</MenuItem>
                    ) : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Slots doctorId={doctor} docAssociationId={docAssociationId} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  )
}

export default BookingContent
