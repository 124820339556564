import React, { useEffect, useState, useCallback } from 'react'
import { NETWORK_STATUS } from '../../Constants'
import {
  makeStyles,
  Box,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Modal,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  Button,
  Select,
  FormControl,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import TableAction from './TableAction'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { PatientStore } from '../../stores'
import Loading from 'react-loading'
import * as moment from 'moment'
import { PATIENT_ID } from '../../Constants'
import NoRecordImg from '../../images/norecord.png'
import Loader from '../CommonComponents/Loader'
import ViewImageModal from './viewImageModal'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import AddLabOrders from '../MyMedications/AddLabOrders'
import EditLabOrders from '../MyMedications/EditLabOrders'
import SelfUpload from './SelfUpload'

const PatientDocument = props => {
  const [type, setValue] = React.useState('')
  const classes = useStyles()
  const [age, setAge] = React.useState('')
  const [openModal, setOpenModal] = useState(false)
  const [Image, previewImage] = useState('')
  const [doc_name, setFile] = useState('')
  const [editModal, setEditModal] = useState(false)
  const [previousDocument, setPreviousDocument] = useState('')
  const [labRecords, setLabRecords] = useState('')
  const [instruct, setInstruct] = useState('')
  const [addLabRecord, setAddLabRecord] = useState(false)
  const [imageModal, setImageModalOpen] = React.useState(false)
  const [imageUrl, setImageUrl] = React.useState(null)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [noLabRecord, setNoLabRecord] = React.useState(false)
  const [addLab, setAddLab] = useState(false)
  const [openAddLabModal, setAddLabModal] = useState(false)
  const [openEditLabModal, setEditLabModal] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [upload, setUpload] = useState(false)

  const handleChange = event => {
    setValue(event.target.value)
  }

  const recordChange = event => {
    setLabRecords(event.target.value)
  }

  const handleImageModalOpen = imageUri => {
    setImageUrl(imageUri)
    setImageModalOpen(true)
  }

  const handleImageModalClose = () => {
    setImageModalOpen(false)
  }

  const {
    documentList,
    labs,
    networkStatus,
    documentPageNumber,
    addPatientDocumentResponse,
    editDocumentResponse,
    deleteDocumentResponse,
    editLabDataResponse,
    addLabDataResponse
  } = PatientStore.useStoreState(state => ({
    ...state.patients
  }))

  const { getDocuments, editPatientDocument } = PatientStore.useStoreActions(
    actions => ({
      ...actions.patients
    })
  )

  useEffect(() => {
    setValue('other')
    getDocuments({ pageNumber: pageNumber })
  }, [])

  useEffect(() => {
    if (labs.length >= 0) {
      setLabRecords(labs[0]?.testname)
    }
  }, [labs])

  useEffect(() => {
    if (addPatientDocumentResponse === undefined) {
      //
    } else if (addPatientDocumentResponse.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Document added successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Document not added successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [addPatientDocumentResponse])

  useEffect(() => {
    if (editDocumentResponse === undefined) {
      //
    } else if (editDocumentResponse.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Document updated successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else if (editDocumentResponse.statusCode === 404) {
      setOpenSnackBar(true)
      setSnackBarMessage('You are not authorized to delete this document')
      setmultiButton(false)
      setAlertType('info')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Document not updated successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [editDocumentResponse])

  useEffect(() => {
    if (deleteDocumentResponse === undefined) {
      //
    } else if (deleteDocumentResponse.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Document deleted successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else if (deleteDocumentResponse.statusCode === 422) {
      setOpenSnackBar(true)
      setSnackBarMessage('You are not authorized to delete this document')
      setmultiButton(false)
      setAlertType('info')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Error while deleting the document')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [deleteDocumentResponse])

  useEffect(() => {
    if (editLabDataResponse == undefined) {
    } else if (editLabDataResponse === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Lab Record updated successfully...')
      setmultiButton(false)
      setAlertType('success')
      window.location.reload()
    } else if (editLabDataResponse === 404) {
      setOpenSnackBar(true)
      setSnackBarMessage('You are not authorized to edit this lab record')
      setmultiButton(false)
      setAlertType('info')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Error while updating lab record...')
      setAlertType('error')
    }
  }, [editLabDataResponse])

  useEffect(() => {
    if (addLabDataResponse === undefined) {
      //
    } else if (addLabDataResponse.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Lab Data added successfully...')
      setmultiButton(false)
      setAlertType('success')
      getDocuments({ pageNumber: pageNumber })
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Lab Data not added successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [addLabDataResponse])

  // useEffect(() => {
  //   console.log('Lab edit response', editLabDataResponse)
  // }, [editLabDataResponse])

  const convertBase64 = doc_name => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(doc_name)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const callbackModal = useCallback(
    (openValue, document) => {
      if (document) {
        setPreviousDocument(document)
        setInstruct(document.instructions)
        if (document.type_of_document === '1') {
          setEditLabModal(true)
        } else {
          setOpenModal(openValue)
          setUpload(openValue)
          // setEditModal(true)
        }
      }
    },
    [setOpenModal]
  )

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  const onPaginationClick = (event, value) => {
    setPageNumber(value)
    getDocuments({ pageNumber: value })
  }

  let count = 1 //To increase the count of document added

  return (
    <div className={classes.root}>
      <Link to="/patient/selectPatient" className={classes.navLink}>
        <button
          className={classes.Backbtn}
          style={{ marginTop: 30, marginLeft: 15 }}>
          <IoIosArrowBack className={classes.Backicon} />
          Select Patient Account
        </button>
      </Link>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />
      <div className={classes.documentcol}>
        <Box className={classes.Contentcol}>
          <h2 style={{ fontSize: 20, fontWeight: 500, color: '#000' }}>
            My Documents
          </h2>
          <Box className={classes.Contentop}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box className={classes.Selecttype}>
                  <h4>Select Documents Type</h4>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={type}
                      onChange={handleChange}
                      label="Age">
                      <MenuItem value={'other'}>Self Upload</MenuItem>
                      <MenuItem value={'lab'}>Lab Records</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} className={classes.btnCol}>
                <label htmlFor="contained-button-file">
                  <Button
                    onClick={() => {
                      if (type !== 'lab') {
                        setOpenModal(true)
                      } else {
                        setAddLabModal(true)
                      }
                      setEditModal(false)
                      setUpload(false)
                      setInstruct('')
                      setPreviousDocument('')
                    }}
                    variant="contained"
                    className={classes.Uploadbtn}
                    color="primary"
                    component="span">
                    Add
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Box>

          {networkStatus === 'FETCHING' ? (
            <div>
              <Loader open={true} />
            </div>
          ) : (
            <div>
              <Loader open={false} />
            </div>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Box
                style={{
                  backgroundColor: '#fff',
                  borderRadius: 20,
                  padding: 20,
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                }}>
                <h3
                  style={{
                    fontSize: 18,
                    fontWeight: 500,
                    color: '#000',
                    marginBottom: 20
                  }}>
                  Uploaded Documents
                </h3>
                <TableContainer
                  component={Paper}
                  className={classes.Tablecontent}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead style={{ backgroundColor: '#F5F5F5' }}>
                      <TableRow>
                        <TableCell
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: 'rgba(0,0,0,0.7)'
                          }}>
                          Document / Lab-Record Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: 'rgba(0,0,0,0.7)'
                          }}>
                          Document Type
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: 'rgba(0,0,0,0.7)'
                          }}>
                          Instructions
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: 'rgba(0,0,0,0.7)'
                          }}>
                          Uploaded on
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: 'rgba(0,0,0,0.7)'
                          }}>
                          Document Modified on
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: 'rgba(0,0,0,0.7)'
                          }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {networkStatus === 'FETCHING' ? (
                      <div className={classes.loaderText}>
                        Loading your documents...
                      </div>
                    ) : !documentList && networkStatus !== 'FETCHING' ? (
                      <img
                        src={NoRecordImg}
                        className={classes.notFoundImage}
                        alt={'Not found!'}
                      />
                    ) : (
                      <TableBody>
                        {documentList.map(row => (
                          <TableRow key={row.name}>
                            <TableCell
                              component="th"
                              scope="row"
                              onClick={() =>
                                handleImageModalOpen(row.document_uri)
                              }
                              className={classes.viewModalCell}>
                              <a>
                                {row.type_of_document === '1'
                                  ? row?.testname
                                    ? row?.testname
                                    : 'N/A'
                                  : row.testname}
                              </a>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left">
                              {row.type_of_document === '1'
                                ? 'Lab Records'
                                : 'Self Upload'}
                            </TableCell>
                            <TableCell align="left">
                              {row.instructions}
                            </TableCell>
                            <TableCell align="center">
                              {moment(row.created_at).format(
                                'DD/MM/YYYY,  hh:mm A'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {moment(row.last_modified).format(
                                'DD/MM/YYYY,  hh:mm A'
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <TableAction
                                document={row}
                                callbackModal={callbackModal}></TableAction>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <Pagination
                  count={documentPageNumber}
                  shape="rounded"
                  onChange={onPaginationClick}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      {openAddLabModal ? (
        <AddLabOrders
          open={openAddLabModal}
          handleClose={() => {
            setAddLabModal(false)
          }}
        />
      ) : (
        ''
      )}
      {openModal ? (
        <SelfUpload
          open={openModal}
          handleClose={() => {
            setOpenModal(false)
          }}
          openModal={openModal}
          document={previousDocument.document_uri}
          instruct={instruct}
          upload={upload}
          previousDocument={previousDocument}
        />
      ) : (
        ''
      )}
      {openEditLabModal ? (
        <EditLabOrders
          open={openEditLabModal}
          document={previousDocument.document_uri}
          handleClose={() => setEditLabModal(false)}
          defaultValues={previousDocument}
        />
      ) : null}

      <ViewImageModal
        open={imageModal}
        onClose={() => setImageModalOpen(false)}
        image={imageUrl}
      />
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  modalImg: {
    maxWidth: '100%',
    maxHeight: '100%'
  },

  dashboardRoot: {
    margin: '12px 0',
    width: '100%',
    justifyContent: 'flex-start',
    '@media (max-width:991px)': {
      margin: '0px 0 10px',
      paddingRight: '15px'
    },
    '@media (max-width:550px)': {
      margin: '10px 0 10px',
      paddingRight: '15px'
    }
  },
  documentcol: {
    padding: '10px 20px 20px 10px',
    '@media (max-width:767px)': {
      padding: '5px 10px 20px 10px'
    }
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px'
  },
  Selecttype: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '& h4': {
      fontSize: '16px',
      margin: '0px'
    }
  },
  Instruction: {
    display: 'flex',
    alignItems: 'center'
  },
  Uploadbtn: {
    width: 'auto',
    borderRadius: '8px',
    height: '40px',
    padding: '0 25px',
    textAlign: 'center',
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)'
  },
  formControl: {
    width: '50%',
    marginLeft: '15px',
    '@media (max-width:767px)': {
      marginLeft: '0px',
      width: '100%'
    }
  },
  Tablecontent: {
    width: '100%',
    boxShadow: '0px 0px 0px #000',
    marginBottom: 15
  },
  table: {
    minWidth: '100%',
    border: 'none',
    '@media (max-width:767px)': {
      width: '700px'
    },
    '& td': {
      fontSize: '15px'
    },
    '& th': {
      fontSize: '15px',
      color: '#3f4b79',
      fontWeight: '400'
    },
    '& p': {
      fontSize: '14px',
      fontWeight: '400'
    },
    '& .MuiTableCell-root': {
      border: 'none'
    }
  },
  input: {
    display: 'none'
  },
  Contentop: {
    width: '100%',
    display: 'flex',
    marginBottom: '30px',
    '& h5': {
      fontSize: '18px',
      margin: '0px'
    }
  },
  Contentcol: {
    width: '100%',
    background: 'transparent',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 0px 0px #000',
    '& h2': {
      fontSize: '20px',
      marginTop: '0px'
    }
  },
  btnCol: {
    display: 'flex',
    alignItems: 'center'
  },
  Backbtn: {
    marginRight: '20px',
    background: 'none',
    border: '0px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    cursor: 'pointer',
    marginBottom: '0',
    '@media (max-width:550px)': {
      marginBottom: '0px'
    }
  },
  Backicon: {
    marginRight: '5px'
  },
  heading: {
    fontWeight: 'bold',
    top: '5%',
    textAlign: 'center'
  },
  labRecordsButton: {
    width: '140px',
    borderRadius: '40px',
    height: '40px',
    textAlign: 'center',
    backgroundColor: '#3f4b79',
    color: 'white',
    '&:hover': {
      backgroundColor: '#fafafa'
    }
  },
  labRecordsDropdown: {
    width: 'calc(100% - 15px)',
    height: '30px'
  },
  fileInputSection: {
    margin: '15px auto',
    display: 'block',
    fontWeight: 'bold'
  },
  loaderText: {
    fontSize: '18px',
    textAlign: 'center',
    paddingTop: '100px',
    fontWeight: 'bold'
  },
  instructPrompt: {
    color: 'red',
    margin: 0,
    padding: 0
  },
  viewModalCell: {
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 'bold'
    }
  }
}))
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // borderStyle: "13px double rgb(204, 204, 204)",
    border: '6px solid #3f4b79'
    // height: '50%'
  }
}
export default PatientDocument
