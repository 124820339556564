import React, { useState, useLayoutEffect, useCallback, useRef } from 'react'
import { Box, Fab, makeStyles, colors, useTheme } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import axios from 'axios'

const ImageComponent = ({
  cachedImages = [],
  setCachedImages = () => {},
  field,
  form,
  meta: { touched } = {},
  submitting
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const editLabelRef = useRef(null)
  const imageUrlRef = useRef(null)

  const [errorMsg, setErrorMsg] = useState(null)
  const [hydratedImage, setHydratedImage] = useState(null)

  const fetchImage = useCallback(async () => {
    if (form.values.logo_image) {
      try {
        const { data = null } = await axios.get(form.values.logo_image, {
          responseType: 'blob'
        })

        if (data) {
          const url = URL.createObjectURL(data)
          setHydratedImage(url)
          imageUrlRef.current = url
          setCachedImages(curState => [
            ...curState,
            {
              imageUrl: url,
              urlFromBackEnd: form.values.logo_image
            }
          ])
        } else {
          throw new Error()
        }
      } catch (e) {
        setHydratedImage(null)
        imageUrlRef.current = null
      }
    }
  }, [form.values.logo_image, setCachedImages])

  useLayoutEffect(() => {
    const indexOfImage = cachedImages.findIndex(
      cii => cii.urlFromBackEnd === form.values.logo_image
    )
    if (indexOfImage === -1) {
      setHydratedImage(null)
      imageUrlRef.current = null
      fetchImage()
    } else if (indexOfImage !== -1) {
      setHydratedImage(cachedImages[indexOfImage].imageUrl)
      imageUrlRef.current = cachedImages[indexOfImage].imageUrl
    }
  }, [form.values.logo_image, fetchImage, cachedImages])

  const onImageChange = (e, form) => {
    const value = e.currentTarget.files[0]

    if (!value) {
      return
    }

    if (
      value.type !== 'image/jpeg' &&
      value.type !== 'image/png' &&
      value.type !== 'image/jpg'
    ) {
      setHydratedImage(imageUrlRef.current)
      form.setFieldValue('newLogoImage', '')
      form.setFieldValue('newLogoImageName', '')
      form.setFieldTouched('newLogoImage')
      setErrorMsg('Only jpg-s, jpeg-s & png-s are allowed')
    } else if (Math.round(value.size / Math.pow(1024, 2), 2) > 4.5) {
      setHydratedImage(imageUrlRef.current)
      form.setFieldValue('newLogoImage', '')
      form.setFieldValue('newLogoImageName', '')
      form.setFieldTouched('newLogoImage')
      setErrorMsg('File size should be less than 4.5 MB')
    } else {
      var reader = new FileReader()

      reader.onload = e => {
        setHydratedImage(e.target.result)
        form.setFieldValue(
          'newLogoImage',
          e.target.result.substring(e.target.result.indexOf(',') + 1)
        )
        form.setFieldValue(
          'newLogoImageName',
          `${form.values.clinic_name.replace(' ', '')}-logo.${
            value.type.split('/')[1]
          }`
        )
        setErrorMsg(null)
        form.setFieldTouched('newLogoImage')
      }
      reader.readAsDataURL(value)
    }
  }

  return (
    <React.Fragment>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '1rem'
        }}>
        <Box className="clinic-avatar">
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '2rem',
              fontWeight: 'lighter',
              letterSpacing: '0.1rem',
              backgroundColor: hydratedImage
                ? theme.palette.primary.main
                : '#9138aa',
              color: '#eee',
              textAlign: 'center',
              position: 'relative'
            }}>
            {form.values.clinic_name
              .split(' ')
              .map(word => word.charAt(0))
              .join('')}
            <img
              src={hydratedImage}
              alt={`${form.values.clinic_name}-logo`}
              className={[
                classes.hydratingImages,
                hydratedImage ? classes.imageLoaded : null
              ].join(' ')}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            />
          </div>
        </Box>
        {submitting || (
          <input
            accept="image/x-png,image/jpg,image/jpeg"
            className={classes.input}
            id="icon-button-file"
            type="file"
            name={field.name}
            onBlur={field.onBlur}
            onChange={e => onImageChange(e, form)}
          />
        )}
        <Fab
          aria-label="upload picture"
          size="small"
          onClick={() => editLabelRef.current.click()}
          style={{ marginTop: '-1.2rem' }}>
          <EditIcon />
        </Fab>
        <label
          htmlFor="icon-button-file"
          style={{ display: 'none' }}
          ref={editLabelRef}></label>
      </Box>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          color: colors.red[500],
          fontSize: '80%',
          marginTop: errorMsg && touched ? '-0.5rem' : 0,
          marginBottom: errorMsg && touched ? '1rem' : 0
        }}>
        {errorMsg}
      </div>
    </React.Fragment>
  )
}

export default ImageComponent

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  hydratingImages: {
    opacity: 0,
    transition: 'opacity 2000ms'
  },
  imageLoaded: {
    opacity: 1
  }
}))
