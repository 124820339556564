import { action, thunk } from 'easy-peasy'

import BaseModel from '../Base'
import { NETWORK_STATUS, CLINIC_USER_ID, BOOKING_STATUS } from '../../Constants'
import api from '../../api'
import moment from 'moment'

export default {
  ...BaseModel(),
  upcomingdata: [],
  totalPagesCount: 0,
  servicesList: [],
  manualPrescriptionList: [],
  recommendedServiceList: [],
  takenServiceList: [],

  getList: thunk(async (actions, payload, { getStoreState }) => {
    actions.setAppointments([])
    actions.setTotalPages(0)
    // actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    if (payload.isForFollowup) {
      api.setAuthHeaders({
        authorization: localStorage.getItem('clinic_auth_key')
      })
    }

    const {
      list_type,
      status,
      visit_type,
      appointment_type,
      sorting,
      currentPage,
      searchString,
      startDate,
      endDate,
      doctor_id,
      clinic_doc_assoc_id
    } = payload

    const response = await api.doctor.getAppointmentList({
      user_id: doctor_id === 'all-doctors' ? '' : doctor_id,
      //   doctor_id: doctor_id,
      status_list:
        status !== BOOKING_STATUS.ALL.value
          ? [status]
          : list_type === 'past' || list_type === 'all'
          ? ['2', '3', '4', '7']
          : ['2', '3', '4'],
      visit_type: visit_type ? visit_type : 0,
      table_config_rows_per_page: '10',
      table_config_page_no: currentPage ? currentPage : '1',
      list_type: list_type,
      appointment_type: appointment_type,
      pattern: searchString ? searchString : '',
      sorting: sorting ? sorting : '',
      start_date: startDate ? startDate : '',
      end_date: endDate ? endDate : '',
      clinic_doctor_association_id: clinic_doc_assoc_id
        ? clinic_doc_assoc_id
        : ''
    })
    // sorting:{
    //   created_at: 'ASC',
    //   start_time: 'ASC',
    //   appointment_date: 'ASC'
    // }
    if (response.ok) {
      // actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      if (response?.data?.appointments) {
        actions.setAppointments(response?.data?.appointments)
        actions.setTotalPages(response?.data?.total_pages_count)
      }
    } else {
      // actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
    return response?.data
  }),

  setAppointments: action((state, payload) => {
    state.upcomingdata = payload
  }),
  setTotalPages: action((state, payload) => {
    state.totalPagesCount = payload
  }),

  cancelClinicAppointment: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.clinic.cancelAppointment({
      appointment_id: payload?.appointment_id,
      appointment_type: payload?.appointment_type
    })
    if (response.status == 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      return response.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return false
    }
  }),

  generatePrescriptionPdf: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const { appointment_id } = payload

      const response = await api.patient.generatePrescription({
        appointment_id: appointment_id,
        view_type: 'pdf'
      })
      if (response?.data?.statusCode == 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return response?.data
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        return response?.data
      }
    }
  ),

  patientProfileSearch: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.clinic.patientSearch({
      mobile_no: payload?.mobile_no
    })
    if (response.status == 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      return response.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return false
    }
  }),

  clinicAppointmentBooking: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.clinic.patientBookAppointment({
      ...payload,
      appointment_date: payload.start_time_slot.split(' ')[0],
      appointment_start_time: payload.start_time_slot.split(' ')[1],
      appointment_end_time: payload.end_time_slot.split(' ')[1],
      status: '1',
      appointment_type: payload.appointment_type,
      booked_by_id: localStorage.getItem(CLINIC_USER_ID),
      booked_by_first_name: '',
      booked_by_last_name: '',
      primary_doctor_id: ''
    })
    if (response) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      return response.data
    }
  }),

  generateInvoice: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.clinic.clinicGenerateInvoice({
      appointment_id: payload?.appointment_id,
      service_id_list: payload?.service_id_list ? payload?.service_id_list : [],
      doctor_fee: payload?.doctor_fee ? 'true' : 'false'
    })
    if (response.status == 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      return response.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return false
    }
  }),

  appointmentServicesMove: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.clinic.clinicPatientServiceMove({
      patient_service_id: payload?.patient_service_id
    })
    if (response.data.statusCode == 201) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      return response.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return false
    }
  }),

  appointmentServicesPayment: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.clinic.clinicPatientServicePayment({
      patient_service_id: payload?.patient_service_id
    })
    if (response.data.statusCode == 201) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      return response.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return false
    }
  }),

  appointmentServicesDelete: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.clinic.clinicPatientServiceDelete({
      patient_service_id: payload?.patient_service_id
    })
    if (response.data.statusCode == 201) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      return response.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return false
    }
  }),

  appointmentServicesList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    actions.setServicesList([])
    const response = await api.clinic.clinicPatientServiceList({
      appointment_id: payload?.appointment_id,
      action: 'list',
      list_type: payload?.list_type ? payload.list_type : 'all',
      table_config_rows_per_page: 10,
      table_config_page_no: 1
    })
    if (response.status == 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setServicesList(response.data.response)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setServicesList: action((state, payload) => {
    state.servicesList = payload
  }),

  appointmentPaymentConfirm: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.clinic.clinicPatientConfirmPayment({
      appointment_id: payload?.appointment_id,
      offline_payment_mode: payload?.offline_payment_mode
    })
    if (response.data.statusCode == 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      return response.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return response.false
    }
  }),

  setManualPrescriptionList: action((state, payload) => {
    state.manualPrescriptionList = payload
  }),

  uploadPrescription: thunk(async (actions, payload) => {
    const response = await api.clinic.clinicUploadPrescription(payload)
    const responseData = response?.data
    return responseData
  }),

  fetchManualPrescriptionList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    try {
      const response = await api.clinic.clinicListManualPrescription(payload)
      const responseData = response?.data

      if (!responseData) {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      } else if (responseData?.statusCode === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

        const baseURL = responseData?.base_url
        const presList = responseData?.prescriptions
          ? responseData?.prescriptions
          : []

        const modifiedList = presList.map(pres => ({
          ...pres,
          prescription_url: `${baseURL}${pres.prescription_url}`
        }))

        actions.setManualPrescriptionList(modifiedList)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    } catch (error) {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
    return
  }),

  billDeskAppointmentServicesList: thunk(async (actions, payload) => {
    const response = await api.clinic.fetchServicesList({
      description: '',
      pattern: '',
      table_config_rows_per_page: 1000,
      table_config_page_no: 1
    })
    return response?.data
  }),

  recommendServiceToPatient: thunk(async (actions, payload) => {
    const response = await api.patient.serviceAdd({
      service_status: '2',
      appointment_id: payload.appointment_id,
      service_master_id: payload.service_master_id,
      patient_id: payload.patient_id
    })
    return response?.data
  }),

  setRecommendedServiceList: action((state, payload) => {
    state.recommendedServiceList = payload
  }),

  setTakenServiceList: action((state, payload) => {
    state.takenServiceList = payload
  }),

  getServiceLists: thunk(async (actions, payload) => {
    const response = await api.clinic.clinicPatientServiceList({
      appointment_id: payload.appointment_id,
      list_type: payload.list_type, // rec, taken
      table_config_rows_per_page: 1000,
      table_config_page_no: 1
    })

    const responseData = response?.data
    if (
      !!responseData &&
      responseData.status === 200 &&
      !!responseData.response &&
      Array.isArray(responseData.response)
    ) {
      if (payload.list_type === 'rec') {
        actions.setRecommendedServiceList(responseData?.response)
      } else if (payload.list_type === 'taken') {
        actions.setTakenServiceList(responseData?.response)
      }
    }
    return responseData
  }),

  movePatientServiceToTaken: thunk(async (actions, payload) => {
    const response = await api.clinic.clinicPatientServiceMove({
      patient_service_id: payload.patient_service_id
    })

    const responseData = response?.data
    return responseData
  }),

  getReportDownloadLink: thunk(async (actions, payload) => {
    const response = await api.clinic.clinicDownloadReport({
      start_date: payload.start_date,
      end_date: payload.end_date
    })
    return response?.data
  }),

  appointmentServicesTakenPayment: thunk(async (actions, payload) => {
    const response = await api.clinic.clinicPatientServicePayment({
      patient_service_id: payload?.patient_service_id
    })
    return response?.data
  }),

  getInvoiceDownloadLink: thunk(async (actions, payload) => {
    const response = await api.clinic.clinicGenerateInvoice({
      appointment_id: payload.appointment_id,
      // doctor_fee: 'true',
      doctor_fee: payload.doctor_fee,
      service_id_list: payload.service_id_list
    })
    return response?.data
  }),

  deleteServiceFromServicesTaken: thunk(async (actions, payload) => {
    const response = await api.clinic.clinicPatientServiceDelete({
      patient_service_id: [payload.patient_service_id]
    })
    return response?.data
  }),

  deleteServiceFromRecommendedServices: thunk(async (actions, payload) => {
    const response = await api.clinic.clinicRemoveRecommendedService({
      patient_service_id: [payload.patient_service_id]
    })
    return response?.data
  }),

  editAppointmentStatusInClinic: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.clinic.clinicEditAppointmentStatus({
      appointment_id: payload.appointment_id,
      doctor_id: payload.doctor_id,
      status: payload.status, // -- 1 = booked, 2 = confirmed (unused), 3 = checked-in, 4 = in-progress, 5 = cancelled, 6 = no-show, 7 = completed , 8 = rescheduled
      patient_id: payload.patient_id,
      followup_recommendation: '2', // -- 1 = yes, 2 = no "value": "6", // -- count of days or weeks or months in 'between 1 to 6'
      value: '',
      timeline_type: '', // -- doctor recommends the patient to visit again in certain number (value) of 'days or weeks or months' (timeline_type),
      offline_payment_mode: payload.offline_payment_mode
        ? payload.offline_payment_mode
        : '' // --- credit-card, debit-card, upi, cash, net-banking
    })
    const responseData = response?.data

    if (
      !!responseData &&
      !!responseData.statusCode &&
      responseData.statusCode === 200
    ) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }

    return response?.data
  }),

  doctorPaymentUsingClinic: thunk(async (actions, payload) => {
    const response = await api.clinic.clinicPatientConfirmPayment({
      appointment_id: payload.appointment_id,
      offline_payment_mode: payload.offline_payment_mode
    })
    return response?.data
  })
}
