import React from 'react'
import { makeStyles, Box, Grid, TextField, Button } from '@material-ui/core'
import { BeatLoader } from 'react-spinners'
import MuiAlert from '@material-ui/lab/Alert'
import RuppeeIcon from '../../images/rupee.png'
import { DoctorStore } from '../../stores'
import { NETWORK_STATUS } from '../../Constants'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'

const useStyles = makeStyles(() => ({
  userForm: {
    width: '100%',
    padding: '0px'
  },
  requestbtn: {
    width: '200px',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#5a5f95',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  confirmbtn: {
    width: '40%',
    cursor: 'pointer',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#121d48',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '14px',
    justifyContent: 'center',
    border: 'none',
    '@media (max-width:767px)': {
      width: '90%',
      marginBottom: '15px'
    }
  },
  confirmbtnDisabled: {
    width: '40%',
    cursor: 'pointer',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#121d48',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '14px',
    justifyContent: 'center',
    border: 'none',
    '@media (max-width:767px)': {
      width: '90%',
      marginBottom: '15px'
    }
  },

  blancebox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    width: '100%',
    '@media (max-width:768px)': {
      width: '100%'
    }
  },
  btncol: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
    '@media (max-width:767px)': {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  accountbox: {
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    '& p': {
      fontSize: '20px',
      marginTop: '10px',
      fontWeight: '400',
      color: '#737272',
      margin: '0px',
      '@media (max-width:767px)': {
        fontSize: '16px'
      }
    },
    '& img': {
      '@media (max-width:767px)': {
        height: '15px'
      }
    },
    '& h3': {
      fontSize: '40px',
      color: '#374370',
      margin: '0px',
      lineHeight: '40px',
      '@media (max-width:767px)': {
        fontSize: '20px'
      }
    }
  },
  list: {
    fontSize: '15px',
    color: '#737272',
    fontWeight: '400',
    display: 'grid'
  }
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function IncomeBlance() {
  const classes = useStyles()
  const [wallet, setWallet] = React.useState([])
  const [amount, setAmount] = React.useState('')
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [confirmAlert, setConfirmAlert] = React.useState(false)
  const [amountExceeded, setAmountExceeded] = React.useState(false)

  const { doctorWalletView, doctorWalletRequestAdd, snackClear } =
    DoctorStore.useStoreActions(actions => ({
      ...actions.profile
    }))

  const { walletDetails, networkStatus, walletAdd } = DoctorStore.useStoreState(
    state => ({
      ...state.profile
    })
  )

  React.useEffect(() => {
    doctorWalletView()
    return () => {
      snackClear()
    }
  }, [])

  React.useEffect(() => {
    if (walletDetails == undefined) {
      //
    } else {
      walletDetails.map(list => {
        setWallet(list)
      })
    }
  }, [walletDetails])

  React.useEffect(() => {
    if (walletAdd === undefined) {
      //
    } else if (walletAdd && walletAdd.statusCode !== 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Please provide a valid Amount')
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Wallet request added successfully!!!')
      setmultiButton(false)
      setAlertType('success')
      doctorWalletView()
    }
  }, [walletAdd])

  const Amount = e => {
    if (Number(e.target.value) > Number(wallet.wallet_balance) - 100) {
      setOpenSnackBar(true)
      setSnackBarMessage('Amount should be less than Wallet Balance')
      setmultiButton(false)
      setAlertType('info')
      setAmountExceeded(true)
    } else {
      setAmountExceeded(false)
      setAmount(e.target.value)
    }
  }

  const Payout = event => {
    event.preventDefault()
    setConfirmAlert(true)
  }

  // const confirmRequest = () => {
  //   doctorWalletRequestAdd(amount)
  //   setAmount('')
  //   setConfirmAlert(false)
  // }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  // const handleClose = () => {
  //   setConfirmAlert(false)
  // }

  return (
    <Grid container spacing={3}>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />
      {/* <Dialog
        open={confirmAlert}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">Confirm Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you like to open a payout request for Rs.{amount}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRequest} color="primary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog> */}

      {/*
      <Grid item xs={6} sm={6} md={3}>
        <Box className={classes.accountbox}>
          {networkStatus === NETWORK_STATUS.FETCHING ? (
            <BeatLoader size={15} color="#3f4b79" loading />
          ) : (
            <h3>
              <img src={RuppeeIcon} alt="rupee" />{' '}
              {wallet.wallet_balance ? wallet.wallet_balance : '0.0'}
            </h3>
          )}
          <p>Wallet Balance</p>
          <small style={{ fontWeight: '200' }}>
            (NOTE: Only online payments will reflect in wallet.)
          </small>
        </Box>
      </Grid>
      */}

      <Grid item xs={6} sm={6} md={4}>
        <Box className={classes.accountbox} style={{ background: '#C3F5F6' }}>
          <p
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: '#1C6070',
              marginBottom: 10,
              marginTop: 0
            }}>
            Total Earnings
          </p>
          {networkStatus === NETWORK_STATUS.FETCHING ? (
            <BeatLoader size={15} color="#3f4b79" loading />
          ) : (
            <h3 style={{ color: '#000' }}>
              <img src={RuppeeIcon} alt="rupee" />{' '}
              {wallet.total_fees ? wallet.total_fees : '0.0'}
            </h3>
          )}

          <p
            style={{
              fontWeight: '200',
              fontSize: 12,
              color: '#255762',
              lineHeight: '16px',
              height: 30
            }}>
            (Total earning includes both online and offline payments.)
          </p>
        </Box>
      </Grid>

      <Grid item xs={6} sm={6} md={4}>
        <Box className={classes.accountbox} style={{ background: '#F8D1E1' }}>
          <p
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: '#BA5A81',
              marginBottom: 10,
              marginTop: 0
            }}>
            Digital Pay Earnings
          </p>
          {networkStatus === NETWORK_STATUS.FETCHING ? (
            <BeatLoader size={15} color="#3f4b79" loading />
          ) : (
            <h3 style={{ color: '#000' }}>
              <img src={RuppeeIcon} alt="rupee" />{' '}
              {wallet.digital_fees ? wallet.digital_fees : '0.0'}
            </h3>
          )}

          <p
            style={{
              fontWeight: '200',
              fontSize: 12,
              color: '#844760',
              height: 30
            }}>
            (Total earnings in CareNowDoc till date.)
          </p>
        </Box>
      </Grid>

      <Grid item xs={6} sm={6} md={4}>
        <Box className={classes.accountbox} style={{ background: '#DABCFF' }}>
          <p
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: '#833CDA',
              marginBottom: 10,
              marginTop: 0
            }}>
            Offline Earnings
          </p>
          {networkStatus === NETWORK_STATUS.FETCHING ? (
            <BeatLoader size={15} color="#3f4b79" loading />
          ) : (
            <h3 style={{ color: '#000' }}>
              <img src={RuppeeIcon} alt="rupee" />{' '}
              {wallet.offline_fees ? wallet.offline_fees : '0.0'}
            </h3>
          )}

          <p
            style={{
              fontWeight: '200',
              fontSize: 12,
              color: '#56367C',
              height: 30
            }}>
            (Offline payments made at the clinic.)
          </p>
        </Box>
      </Grid>

      {/* <Grid item xs={6} sm={6} md={3}>
        <Box className={classes.accountbox}>
          {networkStatus === NETWORK_STATUS.FETCHING ? (
            <BeatLoader size={15} color="#3f4b79" loading />
          ) : (
            <h3>
              <img src={RuppeeIcon} alt="rupee" />{' '}
              {wallet.gst_charge ? wallet.gst_charge : '0.0'}
            </h3>
          )}
          <p>Gst Charge</p>
          <small style={{ fontWeight: '200' }}>
            (Offline payments made at the clinic.)
          </small>
        </Box>
      </Grid> */}

      {/*
      <Grid item xs={12} sm={12} md={5}>
        <Box className={classes.blancebox}>
          <h4>
            <strong>
              Balance available to withdraw: ₹{' '}
              {wallet.wallet_balance
                ? wallet.wallet_balance - 100 > 0
                  ? wallet.wallet_balance - 100
                  : '0.0'
                : '0.0'}
            </strong>
          </h4>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="standard-basic"
              onChange={Amount}
              value={amount}
              label="Enter Amount"
              type="number"
            />
            <Box className={classes.btncol}>
              <button
                className={
                  amountExceeded
                    ? classes.confirmbtnDisabled
                    : classes.confirmbtn
                }
                disabled={amountExceeded}
                onClick={Payout}>
                Request Payout
              </button>
            </Box>
          </form>
        </Box>
      </Grid>
      */}
      {/*
      <Grid item xs={12} sm={12} md={7}>
        <h4>Important:</h4>
        <ul className={classes.list}>
          <li>Check your bank details before requesting a payout.</li>
          <li>
            Amount will reflect in wallet only after an appointment is
            completed.
          </li>
          <li>Minimum payout request is Rs 100.</li>
          <li>
            To deduct subscription cost from wallet, please call support team.
          </li>
        </ul>
      </Grid>
      */}
    </Grid>
  )
}
