import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  CircularProgress,
  makeStyles,
  Avatar,
  CardActions,
  InputAdornment,
  Typography
} from '@material-ui/core'
import {
  VALIDATE_PHONE,
  VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
  EMAIL_CHECK,
  SPECIAL_CHAR,
  NETWORK_STATUS
} from '../../../../Constants'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ClinicStore } from '../../../../stores'
import Autocomplete from '@material-ui/lab/Autocomplete'
import moment from 'moment'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import Loader from '../../../components/ClinicLoader'
import VisibilityIcon from '@material-ui/icons/Visibility'

import indianFlag from '../../../../images/flag.png'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  timezone: 'GTM-7'
}
const useStyles = makeStyles(() => ({
  root: {},
  Savebtn: {
    '&:hover': {
      background: 'rgb(48 55 84) !important'
    }
  },
  contentCol: {
    minHeight: '100%',
    boxShadow: '0 1px 7px 0 rgba(0,0,0,0.1)',
    width: '96%',
    overflow: 'hidden !important',
    borderRadius: '10px',
    padding: '30px 0',
    margin: '0px auto'
  },
  errMsg: {
    color: 'red'
  },
  inputRoot: {
    height: '3.5rem',
    '& .MuiOutlinedInput-input': {
      border: 'none'
    }
  },
  viewIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles()
  const [countrySelect, setCountrySelect] = useState(false)
  const [stateSelect, setStateSelect] = useState(false)
  const [citySelect, setCitySelect] = useState(false)
  const [countryNameValue, setCountryNameValue] = useState({})
  const [stateNameValue, setStateNameValue] = useState({})
  const [cityNameValue, setCityNameValue] = useState({})
  const hiddenFileInput = useRef()
  const [file, setFile] = useState('')
  const [Image, previewImage] = useState('')
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [accVisibility, setAccVisibility] = useState(false)

  let countryName
  let stateName
  let cityName

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const submit = async values => {
    const file = values.file
    if (file) {
      if (
        file.name.split('.').pop().search('pdf') === 0 ||
        file.name.split('.').pop().search('docx') === 0
      ) {
        setOpen(true)
        setSnackBarMessage(
          'Pdf and Docx file are not supported for profile picture!'
        )
        setmultiButton(false)
        setAlertType('info')
      } else if (
        file.name.split('.').pop().search('pdf') !== 0 ||
        file.name.split('.').pop().search('docx') !== 0
      ) {
        let base64 = await convertBase64(file)
        let format = base64.split(',')
        updatedClinicProfile({
          ...values,
          phone: `+91${values.phone}`,
          format
        })
      }
    } else {
      updatedClinicProfile({
        ...values,
        phone: `+91${values.phone}`
      })
    }
  }

  const customStyles = {
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '99',
    cursor: 'pointer',
    display: 'none',
    fontSize: '50px',
    opacity: '0',
    mozOpacity: '0',
    msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
  }

  const {
    getClinicProfileDetails,
    fetchCountryList,
    fetchStateList,
    fetchCityList,
    clearState,
    clearCity,
    updatedClinicProfile
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const {
    profileDetails,
    countryList,
    stateList,
    cityList,
    fetchingCountry,
    fetchingState,
    fetchingCity,
    updatedClinicSuccessMessage,
    networkStatus
  } = ClinicStore.useStoreState(state => ({
    ...state.profile
  }))

  useEffect(() => {
    if (updatedClinicSuccessMessage === undefined) {
      //
    } else if (updatedClinicSuccessMessage?.statusCode !== 400) {
      setOpen(true)
      setSnackBarMessage('Clinic profile updated successfully')
      setmultiButton(false)
      setAlertType('success')
      getClinicProfileDetails()
    } else if (updatedClinicSuccessMessage?.statusCode == 400) {
      setOpen(true)
      {
        updatedClinicSuccessMessage.message == '"Mobile Number Already exist."'
          ? setSnackBarMessage('Phone number already exist')
          : setSnackBarMessage(
              updatedClinicSuccessMessage.message.replace(/['"]+/g, '')
            )
      }
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpen(true)
      setSnackBarMessage('Clinic profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [updatedClinicSuccessMessage])

  const countryChange = (e, value) => {
    fetchStateList(value?.id)
    setCountrySelect(true)
    setCountryNameValue(value)
    setStateNameValue({})
    setCityNameValue({})
    clearState()
    formik.setFieldValue('country_id', value?.id)
  }

  const stateChange = (e, value) => {
    setStateSelect(true)
    fetchCityList(value?.id)
    setStateNameValue(value)
    setCityNameValue({})
    clearCity()
    formik.setFieldValue('state_id', value?.id)
  }

  const cityChange = (e, value) => {
    setCitySelect(true)
    setCityNameValue(value)
    formik.setFieldValue('city_id', value?.id)
  }

  useEffect(() => {
    getClinicProfileDetails({})
    fetchCountryList({})
  }, [])

  useEffect(() => {
    if (profileDetails?.length !== 0) {
      previewImage(profileDetails?.logo)
      fetchStateList(profileDetails?.country)
      fetchCityList(profileDetails?.state)
    }
  }, [profileDetails])

  useEffect(() => {
    if (countrySelect) {
      // when country select event triggered means it will change state value to true then it will not affected anything,
      // IMP : without countrySelect(true) state value it will always go to whatever code written in else statement it will run,
      // so we can't see updated country there
    } else {
      countryName =
        countryList &&
        countryList.filter(country => {
          return country.id === profileDetails?.country
        })
      if (countryName.length > 0) {
        setCountryNameValue(countryName[0])
        formik.setFieldValue('country_id', countryName[0].id)
      }
    }
    if (stateSelect) {
    } else {
      stateName =
        stateList &&
        stateList.filter(state => {
          return state.id === profileDetails?.state
        })
      if (stateName.length > 0) {
        setStateNameValue(stateName[0])
        formik.setFieldValue('state_id', stateName[0].id)
      }
    }
    if (citySelect) {
    } else {
      cityName =
        cityList &&
        cityList.filter(city => {
          return city.id === profileDetails?.city
        })
      if (cityName.length > 0) {
        setCityNameValue(cityName[0])
        formik.setFieldValue('city_id', cityName[0].id)
      }
    }
  }, [countryList, stateList, cityList])

  const validationSchema = yup.object().shape({
    clinicName: yup
      .string()
      .trim()
      .required('Clinic Name is required')
      .max(100, 'Max 100 characters allowed'),
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required')
      .max(255, 'Max 255 characters allowed'),
    phone: yup
      .string()
      .max(14, 'You may enter up to 14 digits')
      .matches(
        VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
        'Please enter a valid mobile number'
      )
      // .matches(VALIDATE_PHONE, 'Please enter a valid mobile number with +91')
      .required('Phone number is Required'),
    address: yup
      .string()
      .trim()
      .required('Address is required')
      .max(200, 'Max 200 characters allowed'),
    city_id: yup.string().required('City is required'),
    state_id: yup.string().required('State is required'),
    country_id: yup.string().required('Country is required'),
    about: yup
      .string()
      .trim()
      .required('Bio is required')
      .max(500, 'Max 500 characters allowed'),
    gst: '',
    regno: '',
    pincode: yup
      .string()
      .trim()
      .required('Pincode is required')
      .matches(/^\d{6}$/, 'Incorrect pincode format'),
    account_no: yup
      .string()
      .required('Account No is required')
      .matches(/^[0-9]*$/, 'Only numbers are allowed')
      .min(9, 'Account number should be between 9 to 18 digits')
      .max(18, 'Account number should be between 9 to 18 digits'),
    cnfrmaccount_no: yup
      .string()
      .required('Confirm account No is required')
      .oneOf([yup.ref('account_no'), null], 'Confirm Account No must match')
      .matches(/^[0-9]*$/, 'Only numbers are allowed')
      .min(9, 'Account number should be between 9 to 18 digits')
      .max(18, 'Account number should be between 9 to 18 digits'),
    bank_name: yup
      .string()
      .max(50, 'Character exceeded the limit!')
      .required('Bank Name is required')
      .matches(/^([^0-9]*)$/, 'Digits are not allowed')
      .matches(SPECIAL_CHAR, 'Special Characters are not allowed'),
    branch_name: yup
      .string()
      .max(50, 'Character exceeded the limit!')
      .required('Branch Name is required')
      .matches(/^([^0-9]*)$/, 'Digits are not allowed'),
    ifsc_code: yup
      .string()
      .max(11, 'IFSC_Code must be 11 digit code!')
      .required('IFSC Code is required'),
    beneficiary_name: yup
      .string()
      .max(50, 'Character exceeded the limit!')
      .required('Beneficiary Name is required')
      .matches(/^([^0-9]*)$/, 'Digits are not allowed')
  })
  const onClose = () => {
    setOpen(false)
  }
  const formik = useFormik({
    initialValues: {
      clinicName: profileDetails?.display_name
        ? profileDetails?.display_name
        : '',
      email: profileDetails?.email ? profileDetails?.email : '',
      phone: profileDetails?.phone_number
        ? profileDetails?.phone_number.replace('+91', '')
        : '',
      address: profileDetails?.address ? profileDetails?.address : '',
      city_id: profileDetails?.city ? profileDetails?.city : '',
      state_id: profileDetails?.state ? profileDetails?.state : '',
      country_id: profileDetails?.country ? profileDetails?.country : '',
      about: profileDetails?.about ? profileDetails?.about : '',
      pincode: profileDetails?.pincode ? profileDetails?.pincode : '',
      gst: profileDetails?.gst_no ? profileDetails?.gst_no : '',
      regno: profileDetails?.registration_no
        ? profileDetails?.registration_no
        : '',
      account_no: profileDetails?.primary_account_no
        ? profileDetails?.primary_account_no
        : '',
      cnfrmaccount_no: profileDetails?.primary_account_no
        ? profileDetails?.primary_account_no
        : '',
      bank_name: profileDetails?.primary_bank_name
        ? profileDetails?.primary_bank_name
        : '',
      branch_name: profileDetails?.primary_branch_name
        ? profileDetails?.primary_branch_name
        : '',
      ifsc_code: profileDetails?.primary_ifsc_code
        ? profileDetails?.primary_ifsc_code
        : '',
      beneficiary_name: profileDetails?.primary_beneficiary_name
        ? profileDetails?.primary_beneficiary_name
        : ''
    },
    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const handleChange = event => {
    formik.setFieldValue('file', event.currentTarget.files[0])
    event.preventDefault()
    let reader = new FileReader()
    let fileimage = event.target.files[0]
    reader.onloadend = () => {
      setFile(fileimage)
      previewImage(reader.result)
    }
    reader.readAsDataURL(fileimage)
  }

  const onPaste = e => {
    e.preventDefault()
    setOpen(true)
    setSnackBarMessage('Paste option disabled...')
    setmultiButton(false)
    setAlertType('info')
  }

  return (
    <>
      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={onClose}
      />
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Card className={classes.contentCol}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <Box
              style={{
                width: '300px',
                margin: '0px auto',
                position: 'relative'
              }}
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center">
              <Avatar
                style={{ width: '75px', height: '75px' }}
                className={classes.avatar}
                src={Image ? Image : user.avatar}
              />
              <Button
                className={classes.Savebtn}
                onClick={handleClick}
                style={{
                  backgroundColor: '#111d4e',
                  color: '#fff',
                  padding: '0 30px',
                  marginTop: '15px',
                  height: '40px',
                  borderRadius: '50px'
                }}>
                Edit Profile Picture
              </Button>
              <h3
                style={{
                  fontWeight: '600',
                  marginTop: '10px',
                  marginBottom: '0px',
                  wordBreak: 'break-all'
                }}>
                {formik.values.clinicName}
              </h3>
              {/* <Typography color="textSecondary" variant="body1">
                {cityNameValue?.name &&
                stateNameValue?.name &&
                countryNameValue?.name
                  ? `${cityNameValue.name}, ${stateNameValue.name}, ${countryNameValue.name}`
                  : ''}
              </Typography>
              <Typography
                className={classes.dateText}
                color="textSecondary"
                variant="body1">
                {`${moment().format('hh:mm A')} ${user.timezone}`}
              </Typography> */}
              <CardActions style={{ position: 'absolute' }}>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept=" image/jpeg, image/png"
                  ref={hiddenFileInput}
                  style={customStyles}
                  onChange={handleChange}
                  className="form-control"
                />
              </CardActions>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <form
              autoComplete="off"
              noValidate
              className={clsx(classes.root, className)}
              {...rest}>
              <h4
                style={{
                  width: '200px',
                  background: '#eaf1f7',
                  color: '#21313f',
                  fontWeight: '500',
                  marginBottom: '10px',
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                  padding: '5px 30px'
                }}>
                Clinic Details
              </h4>
              <p
                style={{
                  fontSize: '14px',
                  color: 'rgba(0,0,0,0.6)',
                  padding: '0 30px',
                  marginTop: '0px',
                  marginBottom: '20px'
                }}>
                The information can be edited
              </p>
              <Box style={{ padding: '0 30px', marginBottom: '30px' }}>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Clinic name"
                      name="clinicName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                      value={formik.values.clinicName}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.clinicName && formik.errors.clinicName
                            ? formik.errors.clinicName
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      required
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : null}
                        </div>
                      }
                      value={formik.values.email}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phone"
                      required
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={indianFlag}
                              alt=""
                              width="25"
                              height="25"
                              style={{ marginRight: '0.4rem' }}
                            />
                            +91
                          </InputAdornment>
                        )
                      }}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.phone && formik.errors.phone
                            ? formik.errors.phone
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Address"
                      name="address"
                      required
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      required
                      value={formik.values.address}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.address && formik.errors.address
                            ? formik.errors.address
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Autocomplete
                      id="size-small-outlined"
                      name="country_id"
                      size="small"
                      style={{ width: '100%' }}
                      classes={classes}
                      onBlur={() => formik.setTouched({ country_id: true })}
                      options={countryList}
                      getOptionLabel={option => option.name}
                      onChange={countryChange}
                      value={countryNameValue}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Country*"
                          loading={fetchingCountry}
                          style={{ border: 'none' }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingCountry ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    <div className={classes.errMsg}>
                      {formik.touched.country_id && formik.errors.country_id
                        ? formik.errors.country_id
                        : null}
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      name="state_id"
                      classes={classes}
                      onBlur={() => formik.setTouched({ state_id: true })}
                      style={{ width: '100%' }}
                      options={countryNameValue ? stateList : []}
                      getOptionLabel={option => option.name}
                      onChange={stateChange}
                      value={stateNameValue}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="State*"
                          loading={fetchingState}
                          style={{ width: '100%' }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingState ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    <div className={classes.errMsg}>
                      {formik.touched.state_id && formik.errors.state_id
                        ? formik.errors.state_id
                        : null}
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      name="city_id"
                      onBlur={() => formik.setTouched({ city_id: true })}
                      classes={classes}
                      style={{ width: '100%' }}
                      options={
                        countryNameValue && stateNameValue ? cityList : []
                      }
                      getOptionLabel={option => option.name}
                      onChange={cityChange}
                      value={cityNameValue}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="City*"
                          loading={fetchingCity}
                          style={{ width: '100%' }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingCity ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    <div className={classes.errMsg}>
                      {formik.touched.city_id && formik.errors.city_id
                        ? formik.errors.city_id
                        : null}
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Pincode"
                      name="pincode"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      required
                      value={formik.values.pincode}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.pincode && formik.errors.pincode
                            ? formik.errors.pincode
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <TextField
                      fullWidth
                      label="About"
                      name="about"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      required
                      value={formik.values.about}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.about && formik.errors.about
                            ? formik.errors.about
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="GST no (if applicable)"
                      name="gst"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.gst}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.gst && formik.errors.gst
                            ? formik.errors.gst
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Reg no"
                      name="regno"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.regno}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.regno && formik.errors.regno
                            ? formik.errors.regno
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <h4
                style={{
                  width: '200px',
                  background: '#eaf1f7',
                  color: '#21313f',
                  fontWeight: '500',
                  borderTopRightRadius: '5px',
                  marginBottom: '10px',
                  borderBottomRightRadius: '5px',
                  padding: '5px 30px'
                }}>
                Bank Details
              </h4>
              <p
                style={{
                  fontSize: '14px',
                  color: 'rgba(0,0,0,0.6)',
                  padding: '0 30px',
                  marginTop: '0px',
                  marginBottom: '20px'
                }}>
                The information can be edited
              </p>
              <Box style={{ padding: '0 30px' }}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Account number"
                      name="account_no"
                      required
                      type={accVisibility ? 'text' : 'password'}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.account_no}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.account_no && formik.errors.account_no
                            ? formik.errors.account_no
                            : null}
                        </div>
                      }
                      InputProps={{
                        endAdornment: (
                          <VisibilityIcon
                            onClick={() => setAccVisibility(!accVisibility)}
                            className={classes.viewIcon}
                          />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Confirm Account number"
                      required
                      onBlur={formik.handleBlur}
                      name="cnfrmaccount_no"
                      type={'password'}
                      onChange={formik.handleChange}
                      onPaste={onPaste}
                      value={formik.values.cnfrmaccount_no}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.cnfrmaccount_no &&
                          formik.errors.cnfrmaccount_no
                            ? formik.errors.cnfrmaccount_no
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="IFSC Code"
                      onBlur={formik.handleBlur}
                      name="ifsc_code"
                      onChange={formik.handleChange}
                      value={formik.values.ifsc_code}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.ifsc_code && formik.errors.ifsc_code
                            ? formik.errors.ifsc_code
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Benificiary name"
                      onBlur={formik.handleBlur}
                      required
                      name="beneficiary_name"
                      onChange={formik.handleChange}
                      value={formik.values.beneficiary_name}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.beneficiary_name &&
                          formik.errors.beneficiary_name
                            ? formik.errors.beneficiary_name
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Bank Name"
                      name="bank_name"
                      required
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.bank_name}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.bank_name && formik.errors.bank_name
                            ? formik.errors.bank_name
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Branch Name"
                      name="branch_name"
                      required
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.branch_name}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.branch_name &&
                          formik.errors.branch_name
                            ? formik.errors.branch_name
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" justifyContent="center" p={2}>
                <Button
                  className={classes.Savebtn}
                  onClick={formik.handleSubmit}
                  style={{
                    backgroundColor: '#111d4e',
                    color: '#fff',
                    padding: '0 30px',
                    marginTop: '15px',
                    height: '40px',
                    borderRadius: '50px'
                  }}>
                  Save details
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

ProfileDetails.propTypes = {
  className: PropTypes.string
}

export default ProfileDetails
