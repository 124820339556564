import React from 'react'
import Chart from 'react-apexcharts'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  lineChart: {
    minWidth: '-webkit-fill-available'
  }
}))
const LineChart = ({ date, earnings }) => {
  const classes = useStyles()
  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Doctor Earnings',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep'
      ]
    }
  }

  const series = [
    {
      name: 'Desktops',
      data: earnings
    }
  ]

  return (
    <Chart
      className={classes.lineChart}
      options={options}
      series={series}
      type="line"
      height={150}
    />
  )
}

export default LineChart
