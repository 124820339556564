import React from 'react'
import ServicesContent from './Services'
import { DashboardLayout } from '../../../layouts/ClinicDashboardLayout'
import { ClinicStore } from '../../../../stores'

const Booking = () => {
  return (
    <ClinicStore.Provider>
      <DashboardLayout>
        <ServicesContent />
      </DashboardLayout>
    </ClinicStore.Provider>
  )
}

export default Booking
