import React from 'react'
import {
  Box,
  Container,
  Typography,
  makeStyles,
  useTheme,
  Button
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { withRouter } from 'react-router-dom'

import Page from '../../components/Page'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}))

const NotFoundView = ({ history: { replace } }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Page className={classes.root} title="Page Not Found">
      <Box
        display="flex"
        flexDirection="column"
        height="60vh"
        alignItems="center"
        justifyContent="center">
        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            variant="h3"
            style={{ marginBottom: '2rem' }}>
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle2"
            style={{ marginBottom: '2.5rem' }}>
            You either tried some shady route or you came here by mistake.
          </Typography>
          <Box textAlign="center">
            <Button
              variant="outlined"
              onClick={() => replace('/superclinic/dashboard/revenue')}
              style={{
                borderRadius: '20rem',
                padding: '0 2rem',
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main
              }}>
              <span style={{ paddingRight: '1rem' }}>
                Redirect to Dashboard
              </span>{' '}
              <ArrowForwardIosIcon />
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  )
}

export default withRouter(NotFoundView)
