import React, { useEffect } from 'react'
import {
  Table,
  Button,
  TableBody,
  TableRow,
  TableHead,
  withStyles,
  TableCell
} from '@material-ui/core'
import { useParams } from 'react-router'
import NoRecordImg from '../../images/norecord.png'
import PrimaryButton from '../CommonComponents/PrimaryButton'
import ViewDocumetModal from '../PatientMedication/ViewLabRecordModal'
import { DoctorStore } from '../../stores'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: 'rgba(0,0,0,0.7)',
    padding: '7px 5px !important'
  },
  tbody: {
    color: '#000',
    '& td': {
      color: '#000',
      border: 0
    },
    '& th': {
      fontSize: '0.8rem',
      fontWeight: '500',
      border: 0
    }
  }
}))(TableCell)

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F5F5F5',
      color: '#000'
    },
    '& th': {
      fontSize: '0.8rem',
      border: 0
    },
    '& td': {
      fontSize: '0.8rem',
      border: 0
    }
  }
}))(TableRow)

const PatientDocument = ({ classes }) => {
  const params = useParams()
  const [showDocumentModal, setShowDocumentModal] = React.useState(false)
  const [labReportUri, setLabReportUri] = React.useState('')
  // const [document , setDocument] = React.useState([])

  const { documentList } = DoctorStore.useStoreState(state => ({
    ...state.patients
  }))

  const { getDocuments } = DoctorStore.useStoreActions(actions => ({
    ...actions.patients
  }))

  useEffect(() => {
    getDocuments({ patientId: params.patient_id })
  }, [])

  let sortedDocumentList = documentList.sort((a, b) => {
    return new Date(b.last_modified) - new Date(a.last_modified)
  })

  const viewClicked = doc => {
    setLabReportUri(doc)
    setShowDocumentModal(true)
  }

  let count = 1 //To increate the count of document added

  const downloadFile = fileRow => {
    let doc_name = fileRow.document_uri.split('.')
    let fileName = fileRow.testname ? fileRow.testname : 'PatientDocument'
    fetch(fileRow.document_uri).then(function (blobber) {
      return blobber.blob().then(b => {
        let a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        // a.setAttribute('download', `${fileRow.name}.jpg`)
        a.setAttribute(
          'download',
          `${fileName}.${doc_name[doc_name.length - 1]}`
        )
        a.click()
      })
    })
  }

  return (
    <>
      {sortedDocumentList.length === 0 ? (
        <img
          alt="No data"
          style={{ display: 'block', width: '200px', margin: '0px auto' }}
          src={NoRecordImg}
        />
      ) : (
        <Table className={classes.table} aria-label="customized table Table2">
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{ width: '25%', fontSize: 13, color: 'rgba(0,0,0)' }}>
                Document Name
              </StyledTableCell>
              <StyledTableCell style={{ width: '25%' }}>
                Document Type
              </StyledTableCell>
              <StyledTableCell align="left" style={{ width: '25%' }}>
                Instructions
              </StyledTableCell>
              <StyledTableCell align="left" style={{ width: '25%' }}>
                Status
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDocumentList.map(row => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.type_of_document === '1'
                    ? row?.testname
                    : `Document ${count++}`}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.type_of_document === '1' ? 'Lab Records' : 'Self Upload'}{' '}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.instructions}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.document_uri ? (
                    <React.Fragment>
                      <PrimaryButton
                        onClick={() => viewClicked(row.document_uri)}
                        style={{
                          backgroundColor: '#0D46C1',
                          color: '#fff',
                          borderRadius: '8px',
                          textTransform: 'capitalize',
                          height: '35px',
                          fontSize: 13
                        }}>
                        View
                      </PrimaryButton>{' '}
                      &nbsp;&nbsp;&nbsp;
                      <PrimaryButton
                        style={{
                          backgroundColor: '#0D46C1',
                          color: '#fff',
                          borderRadius: '8px',
                          textTransform: 'capitalize',
                          height: '35px',
                          fontSize: 13
                        }}
                        onClick={() => downloadFile(row)}>
                        Download
                      </PrimaryButton>
                      {/* </a> */}
                    </React.Fragment>
                  ) : (
                    <p>Not Available</p>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {showDocumentModal ? (
        <ViewDocumetModal
          open={showDocumentModal}
          handleClose={() => setShowDocumentModal(false)}
          record={labReportUri}
        />
      ) : null}
    </>
  )
}

export default PatientDocument
