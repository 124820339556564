import React, { useEffect } from 'react'
import {
  makeStyles,
  Box,
  Typography,
  Avatar,
  Grid,
  Button
} from '@material-ui/core'
import { useLocation, useParams } from 'react-router-dom'
import { PublicPatientStore } from '../../stores'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import InfoIcon from '@material-ui/icons/Info'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import BusinessIcon from '@material-ui/icons/Business'
import ProfileImg from '../../images/profile-img.png'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const DoctorProfileNew = () => {
  const classes = useStyles()
  const location = useLocation()
  const [clinic, setClinic] = React.useState(null)
  const { shortCode } = useParams()
  const { fetchDetails } = PublicPatientStore.useStoreActions(actions => ({
    ...actions.doctorProfile
  }))
  const history = useHistory()

  const {
    prefix,
    image,
    name,
    specialization,
    degree,
    experience,
    availability,
    description,
    associated_with,
    consultationFees,
    userId,
    networkStatus,
    noDoctorFound,
    clinic_name,
    clinic_logo,
    clinic_address,
    sub_clinics,
    clinic_association_id
  } = PublicPatientStore.useStoreState(state => ({
    ...state.doctorProfile
  }))

  const doctorDetails = PublicPatientStore.useStoreState(state => ({
    ...state.doctorProfile
  }))

  useEffect(() => {
    fetchDetails({ shortCode })
  }, [])

  useEffect(() => {
    if (noDoctorFound) {
      Swal.fire(
        'No Doctor Found!',
        'Please enter correct Doctor Name',
        'info'
      ).then(() => {
        history.push('/')
      })
    }
  }, [noDoctorFound])

  useEffect(() => {
    localStorage.setItem('selected_doctor', JSON.stringify(doctorDetails))
    if (userId) {
      localStorage.setItem('doctor_id', userId)
    }
  }, [doctorDetails])

  const handleChange = e => {
    let selected_id = e.target.value
    localStorage.setItem('clinic_doctor_association_id', selected_id)
    setClinic(e.target.value)
  }
  return (
    <Grid className="slot-booking" container spacing={3}>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        className={classes.ProfileInfo}
        style={{ display: 'flex', borderRight: '1px dashed #ccc' }}>
        <Avatar
          className={classes.AvatarCol}
          alt={name}
          src={image || ProfileImg}
        />

        <Box
          className={classes.DoctorInfoText}
          style={{ marginLeft: '30px', marginBottom: '0px', padding: '0px' }}>
          <h4>Hi I'm</h4>
          <h2>
            {name ? prefix + ' ' + name : 'Loading...'}{' '}
            <VerifiedUserIcon style={{ color: '#3E3BE8', fontSize: '1rem' }} />
          </h2>
          <h5>
            {degree || 'Degree Unvailable'},{' '}
            {specialization ? specialization : ''}
          </h5>
          <a href="#book">
            <Button
              className={classes.BookBtn}
              style={{
                padding: '0 10px',
                borderRadius: '8px',
                textAlign: 'center',
                width: '230px',
                height: '40px'
              }}>
              Book Appointment{' '}
              <ArrowForwardIcon className={classes.NextArrow} />
            </Button>
          </a>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        className={classes.DoctorInfo}
        style={{ paddingLeft: '30px', wordBreak: 'break-all' }}>
        <p style={{ display: 'flex', fontSize: '14px' }}>
          <Box className={classes.Iconcol}>
            {/* <HomeWorkIcon style={{ marginRight: '8px' }} /> */}
            Associated with:
          </Box>

          <span style={{ fontWeight: '500', color: '#2c292f' }}>
            {experience || '--'} years
          </span>
        </p>

        <Box>
          {consultationFees && consultationFees.length === 0 ? (
            ''
          ) : (
            <>
              <p style={{ display: 'flex', fontSize: '14px' }}>
                <Box className={classes.Iconcol}>
                  {/* <AccountBalanceWalletIcon style={{ marginRight: '8px' }} /> */}{' '}
                  Fees:
                </Box>
                <Box
                  style={{ fontWeight: '500', color: '#2c292f', width: '50%' }}>
                  In-Person Consultation Fees{' '}
                  <span style={{ color: '#2c292f' }}>
                    <b>
                      <i class="fa fa-inr"></i>{' '}
                      {Number(
                        consultationFees.find(
                          i =>
                            i.appointment_type === 'in-person appointment' &&
                            i.visit_type == 1
                        ).fees
                      ) || '0'}
                    </b>
                  </span>
                  <br />
                  Tele-Consultation Fees{' '}
                  <span style={{ color: '#2c292f' }}>
                    <b>
                      <i class="fa fa-inr"></i>{' '}
                      {Number(
                        consultationFees.find(
                          i =>
                            i.appointment_type === 'tele appointment' &&
                            i.visit_type == 1
                        ).fees
                      ) || '0'}
                    </b>
                  </span>
                </Box>
              </p>
            </>
          )}
        </Box>
        <p style={{ display: 'flex', fontSize: '14px' }}>
          <Box className={classes.Iconcol}>
            {/* <LocalHospitalIcon style={{ marginRight: '8px' }} /> */}
            Clinic Name:{' '}
          </Box>
          {!sub_clinics ? (
            <span style={{ fontWeight: '500', color: '#000' }}>
              {clinic_name || '--'}
            </span>
          ) : (
            sub_clinics.map(data => {
              return (
                <React.Fragment>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={clinic}
                      onChange={handleChange}>
                      <MenuItem value={data.id}>{data.clinic_name}</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="caption" display="block" gutterBottom>
                    (Select from the list of clinic from dropdown)
                  </Typography>
                </React.Fragment>
              )
            })
          )}
        </p>

        <p style={{ display: 'flex', fontSize: '14px' }}>
          <Box className={classes.Iconcol}>
            {/* <BusinessIcon style={{ marginRight: '8px' }} /> */}
            Clinic Address:
          </Box>

          <span style={{ fontWeight: '500', color: '#000' }}>
            {clinic_address || '--'}
          </span>
        </p>
        <Box className={classes.Iconcol}>Bio</Box>
        <p
          style={{
            marginTop: '5px',
            fontSize: 13,
            fontWeight: '400',
            color: '#000',
            wordBreak: 'break-all'
          }}>
          {description || '--'}
        </p>
        {/* <Box className={classes.DoctorInfo}>
          <Typography
            variant="h2"
            component="h2"
            style={{ fontWeight: '800', color: '#111d4e', textAlign: 'left' }}>
            
          </Typography>

          <Typography variant="caption" style={{ fontSize: '1.3rem',wordBreak:'break-all' }}>
            
          </Typography>

          <Typography
            variant="caption"
            style={{
              textAlign: 'left',
              color: '#9637a5',
              marginTop: '10px',
              fontSize: '20px',
              wordBreak:'break-all'
            }}>
            
          </Typography>
        </Box> */}

        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} style={{ padding: '0 4px' }}>
           
          </Grid>
          <Grid item xs={12} sm={6} md={6} style={{ padding: '0 4px' }}>
            
          </Grid>

          <Grid item xs={12} sm={12} md={6} style={{ padding: '0 4px' }}>
            
          </Grid>

          <Grid item xs={12} sm={6} md={6} style={{ padding: '0 4px' }}>
           
          </Grid>
        </Grid> */}

        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} style={{ padding: '0 4px' }}>
            
          </Grid>

          <Grid item xs={12} md={4} style={{ padding: '0 4px' }}>
            <p>
              <Box className={classes.Iconcol}>Clinic Logo</Box>
            </p>
            <Box className={classes.ClinicImage}><img src={clinic_logo} crossorigin="annonymous" /></Box>
            
          </Grid>
        </Grid> */}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Box className={classes.Iconcol}>
          {/* <InfoIcon style={{ marginRight: '8px' }} />  */}
        </Box>
      </Grid>
    </Grid>

    /*
    <Box>
      <Box className={classes.ProfileCol}>
        <Box className={classes.ProfileImgLeft}>
          <div className={classes.root}>
            <Avatar
              className={classes.AvatarCol}
              alt={name}
              src={image || ProfileImg}
            />
          </div>
        </Box>
        <Box className={classes.DoctorInfo}>
          <Typography
            variant="h3"
            component="h3"
            style={{ fontWeight: '800', color: '#3f4b79', marginTop: '20px', textAlign:'left' }}>
            {name ? prefix + ' ' + name : 'Loading...'}{' '}
            <VerifiedUserIcon
              style={{ color: '#a042af', fontSize: '1.2rem' }}
            />
          </Typography>

          <Typography variant="caption" style={{ fontSize: '1rem' }}>
            {degree || 'Degree Unvailable'}
          </Typography>

          <Typography
            variant="caption"
            style={{
              textAlign: 'left',
              color: '#9637a5',
              marginTop: '10px',
              fontSize: '16px'
            }}>
            {specialization ? specialization : ''}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.DoctorInfo} style={{ marginTop: '10px' }}>
        <p>
          <Box className={classes.Iconcol}>
            <HomeWorkIcon style={{ marginRight: '8px' }} /> Associated with
          </Box>

          <span>{associated_with || '--'}</span>
        </p>
        <br />

        <p>
          <Box className={classes.Iconcol}>
            <BusinessCenterIcon style={{ marginRight: '8px' }} /> Experience{' '}
          </Box>

          <span>{experience || '--'} years</span>
        </p>
        {consultationFees && consultationFees.length === 0 ? (
          ''
        ) : (
          <Box className={classes.DoctorInfo}>
            <p>
              <Box className={classes.Iconcol}>
                <AccountBalanceWalletIcon style={{ marginRight: '8px' }} /> Fees
              </Box>
            </p>
            <p>
              In-Person Consultation Fees{' '}
              <span>
                
                {Number(
                  consultationFees.find(
                    i => i.appointment_type === 'in-person appointment' && i.visit_type == 1
                  ).fees
                ) || 'Rs 0'}
              </span>
            </p>
            <p>
              Tele-Consultation Fees{' '}
              <span>
                
                {Number(
                  consultationFees.find(
                    i => i.appointment_type === 'tele appointment' && i.visit_type == 1
                  ).fees
                ) || 'Rs 0'}
              </span>
            </p>
          </Box>
        )}
        <p>
          <Box className={classes.Iconcol}>
            <LocalHospitalIcon style={{ marginRight: '8px' }} /> Clinic Name{' '}
          </Box>

          <span>{clinic_name || '--'}</span>
        </p>
        <br />
        <p>
          <Box className={classes.Iconcol}>
            <BusinessIcon style={{ marginRight: '8px' }} /> Clinic Address
          </Box>

          <span>{clinic_address || '--'}</span>
        </p>
      </Box>
      <Box className={classes.DoctorInfo}>
        <p>
          <Box className={classes.Iconcol}>
            <InfoIcon style={{ marginRight: '8px' }} /> Bio
          </Box>
        </p>
        <p>
          <span>{description || '--'}</span>
        </p>
      </Box>
    </Box>
    */
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  ProfileInfo: {
    '@media (max-width:767px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '0px !important'
    }
  },
  DoctorInfoText: {
    width: '100%',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify',
    background: '#ffffff00',
    marginBottom: '10px',
    '@media (max-width:767px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '0px !important',
      marginTop: '15px',
      marginLeft: '0px !important'
    },
    '& h2': {
      fontSize: '24px',
      margin: '0 0 10px',
      fontWeight: '600'
    },
    '& h4': {
      fontSize: '18px',
      color: '#3E3BE8',
      margin: '0',
      fontWeight: '600'
    },
    '& h5': {
      fontSize: '14px',
      color: '#6c676d',
      margin: '0 0 25px',
      fontWeight: '500'
    }
  },
  DoctorInfo: {
    width: '100%',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify',
    background: '#fff',
    marginBottom: '10px',
    '@media (max-width:767px)': {
      paddingLeft: '10px !important'
    },
    '& h2': {
      fontSize: '24px',
      margin: '0 0 10px',
      fontWeight: '600'
    },
    '& h4': {
      fontSize: '18px',
      color: '#3E3BE8',
      margin: '0',
      fontWeight: '600'
    },
    '& h5': {
      fontSize: '14px',
      color: '#6c676d',
      margin: '0 0 25px',
      fontWeight: '500'
    },
    '& p': {
      fontSize: '16px',
      color: '#111d4e',
      marginTop: '0px',
      marginBottom: '10px',
      '@media (max-width:767px)': {
        textAlign: 'left',
        flexDirection: 'column'
      },
      '& span': {
        color: '#000',
        fontWeight: '400'
      }
    },
    '& h3': {
      fontSize: '18px',
      margin: '0'
    }
  },
  BookBtn: {
    border: '2px #3E3BE8 solid',
    color: '#3E3BE8',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '14px',
    '&:hover': {
      background: '#3E3BE8',
      color: '#fff'
    }
  },
  NextArrow: {
    '&:hover': {
      color: '#fff'
    }
  },
  Iconcol: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#817e83',
    marginRight: '10px',
    width: '120px'
  },
  ProfileImgLeft: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    border: '0px #3f4b79 solid',
    marginRight: '20px',
    minWidth: '120px',
    marginTop: '20px'
  },
  ClinicImage: {
    width: '70%',
    '@media (max-width:767px)': {
      width: '100%'
    },
    '& img': {
      width: '70%',
      '@media (max-width:767px)': {
        width: '100%'
      }
    }
  },
  ViewText: {
    color: '#3f4b79',
    cursor: 'pointer',
    '&:hover': {
      color: '#333',
      textDecoration: 'none'
    }
  },
  ProfileCol: {
    width: '100%',
    display: 'flex',
    '& p': {
      fontSize: '18px',
      color: '#979797',
      marginTop: '0px'
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: '400',
      marginBottom: '0px',
      marginTop: '20px'
    }
  },
  ContentText: {
    fontSize: '16px',
    color: '#979797'
  },
  AvatarCol: {
    width: '155px',
    height: '155px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 125
  }
}))

export default DoctorProfileNew
