let home_url = {
  dev: 'https://dev.carenowdoc.com/',
  demo: 'https://demo.carenowdoc.com/',
  prod: 'https://carenowdoc.com/',
  qa: 'https://qa.carenowdoc.com/'
}

let backend_url = {
  //   dev: 'https://api-development.carenowdoc.com/', //to be replaced
  dev: 'https://api-development.carenowdoc.com/',
  demo: 'https://api-demo.carenowdoc.com/',
  prod: 'https://api.carenowdoc.com/',
  qa: 'https://api-qa.carenowdoc.com/'
}

let admin_url = {
  dev: 'https://www.dev-admin.carenowdoc.com/',
  demo: 'https://www.demo-admin.carenowdoc.com/',
  prod: 'https://www.admin.carenowdoc.com/',
  qa: 'https://www.qa-admin.carenowdoc.com/'
}

let google_analytics = {
  dev: 'UA-184651243-1',
  demo: 'UA-184651243-1',
  prod: 'UA-186307611-1',
  qa: 'UA-184651243-1'
}

let razorpay_creds = {
  dev: 'rzp_test_6gwhNOZlsbqwq7',
  // dev: 'rzp_test_xQamqLeSHP6TPj',
  demo: 'rzp_test_6gwhNOZlsbqwq7',
  prod: 'rzp_live_rtq3PaNcEKrmm6',
  // qa: 'rzp_test_xQamqLeSHP6TPj',
  qa: 'rzp_test_6gwhNOZlsbqwq7'
}

let environment = process?.env?.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : 'dev'

const api_type = window.location.hostname === 'localhost' ? 'dev' : environment
console.log(environment)

export const BASE_URL = backend_url[api_type]
export const ADMIN_URL = admin_url[api_type]
export const GA_KEY = google_analytics[api_type]
export const HOME_URL = home_url[api_type]
export const RAZOR_KEY = razorpay_creds[api_type]
