import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import ComingSoon from '../../images/comingsoon.jpg'

const PastAppointmentSummary = () => {
  const classes = useStyles()
  return (
    <>
      <h3 style={{ marginTop: '50px', color:'#777e9c', fontSize:'18px', fontWeight: '600' }}>Upcoming Webinars</h3>
      <Grid container spacing={4}>
        <Grid item md={12} className={classes.reviews}>
          <img
            className={classes.image}
            src={ComingSoon}
            alt="coming soon"
            width="40%"
          />
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: 8,
    '@media (max-width:767px)': {
      maxWidth: '94%',
      flexBasis: '100%'
    }
  },
  chartBarGraph: {
    '@media (max-width:767px)': {
      maxWidth: '98%',
      flexBasis: '100%'
    }
  },
  reviews: {
    '@media (max-width:767px)': {
      maxWidth: '98%',
      flexBasis: '100%'
    },
    '&>p': {
      fontSize: '14px',
      margin: '12px 0',
      color: theme.palette.primary.dark
    }
  },
  chart: {
    maxWidth: '100%',
    margin: '0 auto',
    marginTop: '1rem',
    '@media (max-width:767px)': {
      maxWidth: '98%',
      flexBasis: '100%'
    }
  }
}))

export default PastAppointmentSummary
