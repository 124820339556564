import React, { useState, useEffect } from 'react'
import {
  Paper,
  Fade,
  makeStyles,
  Box,
  Button,
  Backdrop,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableContainer
} from '@material-ui/core'
import moment from 'moment'
import { API_REQUEST_DATETIME_FORMAT, NETWORK_STATUS } from '../../Constants'
import Loading from 'react-loading'
import Pagination from '@material-ui/lab/Pagination'
import { DoctorStore } from '../../stores'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const ViewVitalsHistoryModal = ({ showHideModal, isShow, pateintId }) => {
  const classes = useStyles()

  const startDate = moment().format(API_REQUEST_DATETIME_FORMAT)
  const endDate = moment()
    .subtract(60, 'days')
    .format(API_REQUEST_DATETIME_FORMAT)

  const { getPatientVitals } = DoctorStore.useStoreActions(actions => ({
    ...actions.patients
  }))

  const { patientVitalsHistory, networkStatus } = DoctorStore.useStoreState(
    state => ({
      ...state.patients
    })
  )

  const [initialPage, setInitialPage] = React.useState(0)
  const [pageCount, setPageCount] = React.useState(8)
  const [contentPerPage, setContentPerPage] = React.useState(8)
  const [finalPage, setFinalPage] = React.useState(contentPerPage)

  useEffect(() => {
    getPatientVitals({
      patient_id: pateintId,
      start_time: startDate,
      end_time: endDate,
      list_type: 'history',
      table_config_rows_per_page: 20,
      table_config_page_no: 1
    })
  }, [])

  useEffect(() => {
    let x = Math.floor(patientVitalsHistory.length / contentPerPage)
    let y = patientVitalsHistory.length - x * contentPerPage
    if (y > 0) {
      setPageCount(x + 1)
    } else {
      setPageCount(x)
    }
  }, [patientVitalsHistory])

  const onPaginationClick = (event, value) => {
    setInitialPage((value - 1) * contentPerPage)
    setFinalPage((value - 1) * contentPerPage + contentPerPage)
  }

  return (
    <Dialog
      classes={{ paper: classes.modalView }}
      open={isShow}
      onClose={showHideModal}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.Toptittle}>
        Vitals History
      </DialogTitle>
      <DialogContent>
        <Box className={classes.ModalForm} style={{ padding: '0px' }}>
          <TableContainer
            component={Paper}
            style={{ padding: '0px', fontSize: '14px', fontFamily: 'Poppins' }}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Date Recorded
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Temparature (in F)
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Height (in cm)
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Weight (in Kg)
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    BMI index
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Blood Pressure
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Heart Rate
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Respiratory Rate
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Oxygen Saturation (in %)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {networkStatus === NETWORK_STATUS.FETCHING ? (
                  <Loading
                    type="cylon"
                    textAlign="center"
                    color="grey"
                    height={50}
                  />
                ) : patientVitalsHistory.length ? (
                  patientVitalsHistory
                    .slice(initialPage, finalPage)
                    .map(row => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {moment(row.datetime_recorded).format(
                            'DD/MM/YYYY, h:mm:ss a'
                          )}
                        </TableCell>
                        <TableCell align="center">{row.temperature}</TableCell>
                        <TableCell align="center">
                          {Math.floor(row.height)}
                        </TableCell>
                        <TableCell align="center">{row.weight}</TableCell>
                        <TableCell align="center">{row.bmi_index}</TableCell>
                        <TableCell align="center">
                          {Math.trunc(row.sbp) + ' / ' + Math.trunc(row.dbp)}
                        </TableCell>
                        <TableCell align="center">
                          {Math.trunc(row.heart_rate)}
                        </TableCell>
                        <TableCell align="center">
                          {Math.trunc(row.respiratory_rate)}
                        </TableCell>
                        <TableCell align="center">
                          {Math.trunc(row.oxygen_saturation_rate)}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  'No Data found'
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Pagination
          count={pageCount}
          shape="rounded"
          onChange={onPaginationClick}
        />
      </DialogContent>
    </Dialog>
  )
}
const useStyles = makeStyles(() => ({
  modalView: {
    maxWidth: '80rem !important',
    backgroundColor: '#FAFAFA',
    '@media (max-width:767px)': {
      margin: '15px'
    },
    '& .MuiDialogTitle-root': {
      padding: '10px 24px'
    }
  },
  table: {
    '& th': {
      padding: '10px 0',
      fontFamily: 'Poppins',
      fontSize: 14
    },
    '& td': {
      padding: '10px 0',
      fontFamily: 'Poppins',
      fontSize: 14
    },
    '@media (max-width:767px)': {
      width: '850px'
    }
  },
  Toptittle: {
    color: '#000',
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    marginTop: 20
  },
  ModalBox: {
    width: '90%',
    background: '#FAFAFA',
    padding: '0px',
    '@media (max-width:767px)': {
      padding: '0 0 20px',
      height: '450px',
      overflowY: 'auto',
      width: '100%'
    },
    // height:'400px',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  ModalForm: {
    width: '100%',
    padding: '20px 0',
    '@media (max-width:767px)': {
      padding: '10px',
      height: 'auto',
      overflowY: 'auto'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default ViewVitalsHistoryModal
