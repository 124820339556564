import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { makeStyles, Grid, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import LoginBg from '../images/medical_pattern.svg'
import DoctorImg from '../images/doctor_login_img.svg'
import { Layout } from '../doctorLayouts'
import LoginForm from '../components/Login/LoginForm'
import Swal from 'sweetalert2'
import UserBrowser from '../BrowserSupport'
import PublicNavbar from '../components/CommonComponents/PublicNavbar'
import { HOME_URL } from '../Environment'

const DoctorLogin = props => {
  const history = useHistory()
  const classes = useStyles()

  const [showAlert, setAlert] = useState(false)
  const [expire, setExpire] = useState(false)

  const onLoginSuccess = ({ request, response }) => {
    // let { from } = location.state
    if (response?.statusCode === 200) {
      history.replace({ pathname: '/doctor/dashboard' })
    } else {
      setExpire(!expire)
      return Swal.fire(
        'Oops! Login failed',
        `${response.message.replace(/^\w/, c => c.toUpperCase())}!`,
        ''
      )
    }
  }

  useEffect(() => {
    //commented to avoid unnecessary alerts
    // if (props.history?.location?.state?.unauthorized === true) {
    //   setAlert(true)
    // }
    return () => {}
  }, [])

  const fireAlert = () => {
    if (showAlert) {
      Swal.fire('', 'Please log in to continue', 'info').then(() => {
        setAlert(false)
        const state = { ...history.location.state }
        state.unauthorized = false
        history.replace({ ...history.location, state })
      })
    }
  }

  return (
    <Layout hideNavbar>
      <PublicNavbar />
      <Grid container spacing={0} className={classes.Heademain}>
        <div
          style={{
            background: `url(${LoginBg}) #3265F0`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '50%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 85
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              marginTop: '-65px'
            }}>
            <img src={DoctorImg} alt="" />
            <h4
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: 'white',
                marginBottom: 10
              }}>
              Modernizing the doctor consultation
            </h4>
            <p
              style={{
                fontSize: 12,
                color: '#fff',
                width: '50%',
                margin: '0px auto',
                textAlign: 'center'
              }}>
              Mobile application that makes it easier for patients to
              communicate online-based consultations
            </p>
          </div>
          <div
            style={{
              width: '100%',
              backgroundColor: '#285CEB',
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0
            }}>
            <p
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: '#fff',
                textAlign: 'center'
              }}>
              Call Virtual Medical Assistance at <br />
              (033) 418-16522
            </p>
          </div>
        </div>
        <div
          style={{
            width: '50%',
            backgroundColor: '#F8F8FB',
            padding: '145px 0 60px'
          }}>
          <div style={{ width: 390, margin: '0px auto' }}>
            <h2
              style={{
                fontSize: 24,
                fontWeight: 600,
                color: '#FF7F40',
                margin: 0
              }}>
              Welcome!
            </h2>
            <p
              style={{
                fontSize: 14,
                color: '#000',
                letterSpacing: 2,
                margin: '0 0 30px'
              }}>
              Sign in to continue
            </p>
          </div>

          <Box className={classes.loginForm}>
            <div className={classes.form}>
              <LoginForm expire={expire} onSuccess={onLoginSuccess} />
            </div>
          </Box>
        </div>
        {/* <Grid item xs={12} className={classes.LoginImg}>
          <img src={LoginBg} alt="Hero" className={classes.heroImage} />
        </Grid> */}
        {/* <Box className={classes.Logintext}>
          <h2>
            Call Virtual Medical Assistance at <br />
            (033) 418-16522
          </h2>
        </Box> */}
      </Grid>
      <Box className={classes.LoginContent}>
        <h3>CareNowDoc for Clinics</h3>
        <p>
          Now CareNowDoc is available for clinics and hospitals. List all your
          doctors in your profile, share doctor or clinic cards with your target
          audience or patients. Experience an advanced appointment booking
          process with our intelligent scheduler. Reduces cancellations, easy
          rescheduling and increases appointments in your clinic by 3x. Manage
          your doctor accounts, get account insights and make a strong doctor
          patient relationship.
        </p>
        <a
          className={classes.LoginButton}
          href={`${HOME_URL}#contact`}
          target="_blank">
          Learn More
        </a>
        <h5>Or</h5>
        <h4>
          <span>Call</span> 033-418-16533
        </h4>
        <h6>to schedule a demo with our sales team.</h6>
      </Box>
      {fireAlert()}
    </Layout>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '1300px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '0px',
    padding: '0px',
    background: '#fff',

    '@media (max-width:1250px)': {
      maxWidth: '100%'
    }
  },
  LoginButton: {
    width: '150px',
    height: '45px',
    backgroundImage: 'linear-gradient(to right, #45548c , #1d2649)',
    color: '#fff',
    fontSize: '16px',
    borderRadius: '40px',
    textAlign: 'center',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px auto 15px'
  },
  LoginContent: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '50px',
    '@media (max-width:767px)': {
      paddingTop: '5px'
    },
    '& h6': {
      fontSize: '20px',
      color: '#263159',
      padding: '0 31%',
      margin: '0 0 30px',
      fontWeight: 'normal',
      '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape)':
        {
          padding: '0 10%'
        },
      '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait)':
        {
          padding: '0 20%'
        },
      '@media (max-width:767px)': {
        padding: '0 5%',
        fontSize: '16px'
      }
    },
    '& h4': {
      fontSize: '20px',
      color: '#263159',
      fontWeight: 'bold',
      marginBottom: '0px',
      '@media (max-width:767px)': {
        fontSize: '16px'
      },
      '& span': {
        fontWeight: 'normal'
      }
    },
    '& p': {
      fontSize: '16px',
      color: '#787878',
      padding: '0 10%',
      fontWeight: 'normal',
      '@media (max-width:767px)': {
        fontSize: '14px',
        padding: '0 5%'
      }
    },
    '& h5': {
      fontSize: '20px',
      color: '#263159',
      marginRight: '15px',
      marginTop: '0px',
      marginBottom: '0px',
      '@media (max-width:767px)': {
        fontSize: '16px'
      }
    }
  },
  Heademain: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: 0,
    '@media (max-width:767px)': {
      marginTop: 0
    }
  },
  logo: {
    marginBottom: '4.5rem',
    maxWidth: 'fit-content'
  },

  heroImage: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
    '@media (max-width:767px)': {
      marginTop: '0px'
    }
    // TODO: Add media Query in Image
  },
  loginFormRoot: {
    display: 'flex',
    backgroundColor: 'white',
    justifyContent: 'center',
    '@media (max-width:767px)': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  loginForm: {
    padding: '45px 35px',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    background: '#fff',
    width: 390,
    margin: '0px auto',
    borderRadius: 30,
    zIndex: '99',
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape)':
      {
        bottom: '2%'
      },
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait)':
      {
        width: '100%',
        position: 'relative',
        right: '0px'
      },
    '@media (max-width:767px)': {
      width: '100%',
      position: 'relative',
      right: '0px',
      marginTop: '20px'
    }
  },
  form: {
    maxWidth: '380px',
    width: '100%',
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  Logintext: {
    background: '#b72079',
    width: '100%',
    padding: '30px 7%',
    '& h2': {
      fontSize: '30px',
      color: '#fff',
      margin: '0px',
      '@media (max-width:1024px)': {
        fontSize: '24px'
      },
      '@media (max-width:767px)': {
        fontSize: '20px'
      }
    }
  },
  topLogo: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '70px'
  }
}))

export default withRouter(UserBrowser(DoctorLogin))
