import React from 'react'
import { Container } from '@material-ui/core'
import { Redirect, Switch, Route } from 'react-router-dom'

import Page from '../../../components/Page'
import ProfileInformation from './ProfileInformation'
import BankDetails from './BankDetails'
import PocDetails from './PocDetails'

const Myaccount = props => {
  if (props.match.isExact) {
    return <Redirect to="/superclinic/myaccount/profileinfo" />
  }

  let content = (
    <Switch>
      <Route
        exact
        path="/superclinic/myaccount/profileinfo"
        render={props => (
          <Page title={'Profile Info | My Account'}>
            <ProfileInformation {...props} />
          </Page>
        )}
      />
      <Route
        exact
        path="/superclinic/myaccount/bankdetails"
        render={props => (
          <Page title={'Bank Details | My Account'}>
            <BankDetails {...props} />
          </Page>
        )}
      />
      <Route
        exact
        path="/superclinic/myaccount/pocdetails"
        render={props => (
          <Page title={'POC Details | My Account'}>
            <PocDetails {...props} />
          </Page>
        )}
      />
    </Switch>
  )

  return <Container maxWidth={false}>{content}</Container>
}

export default Myaccount
