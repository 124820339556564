import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Paper, Grid, Typography, Box } from '@material-ui/core'
import { DashboardLayout } from '../../doctorLayouts'
import Product1 from '../../images/product1.jpg'
import Product2 from '../../images/product2.jpg'
import Product3 from '../../images/product3.jpg'
import blog from '../../images/blog.jpg'
import blog2 from '../../images/blog2.jpg'
import blog3 from '../../images/blog3.jpg'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Link from '@material-ui/core/Link'

export default function Virtual() {
  const classes = useStyles()

  return (
    <DashboardLayout>
      <Box className={classes.aside}>
        <VirtualMR />
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles(theme => ({
  Profilecard: {
    width: '100%',
    padding: '10px 30px',
    '& h2': {
      marginBottom: '0',
      fontWeight: '600',
      fontSize: '24px',
      color: '#66667b',
      marginTop: '15px'
    }
  },
  VirtualBox: {
    background: '#535f8f',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '25px',
    overflow: 'hidden',
    '& img': {
      width: '100%'
    }
  },
  VirtualGrey: {
    background: '#b7d419',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '25px',
    overflow: 'hidden',
    '& img': {
      width: '100%'
    }
  },
  VirtualRight: {
    padding: '0 5px',

    '& h3': {
      fontSize: '25px',
      color: '#fff',
      marginTop: '0',
      fontWeight: 'bold',

      '@media (max-width:767px)': {
        fontSize: '18px'
      }
    },
    '& p': {
      fontSize: '16px',
      color: '#fff',
      fontWeight: 'normal',
      '@media (max-width:767px)': {
        fontSize: '14px'
      }
    },
    '@media (max-width:767px)': {
      padding: '0 0'
    }
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box style={{ marginTop: '15px' }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const VirtualMR = () => {
  const classes = useStyles()
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  return (
    <div>
      <Box className={classes.Profilecard}>
        <h2
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: '#000',
            marginTop: 30
          }}>
          Clinical Insights
        </h2>

        <Grid container spacing={3}>
          <Grid item xs={12}></Grid>
          <Grid item md={4}>
            <div className="featured-imagebox featured-imagebox-blog">
              <div className="featured-thumbnail">
                <img
                  className={classes.image}
                  src="https://images.financialexpress.com/2020/09/Coronavirus-vaccine-reuters.jpg"
                  alt="blog"
                />
              </div>
              <div className="featured-content">
                <div className="ttm-box-post-date">
                  <span className="ttm-entry-date">
                    <time
                      className="entry-date"
                      datetime="2021-01-06T07:07:55+00:00">
                      06
                      <span className="entry-month entry-year">Jan</span>
                    </time>
                  </span>
                </div>
                <div className="featured-title">
                  <h5>
                    Covid vaccine rollout set to begin next week across India,
                    says government
                  </h5>
                </div>
                <div className="post-meta">
                  <span className="ttm-meta-line">Source: Times of India</span>
                </div>
                <div className="featured-desc">
                  <p>
                    The government will roll out the Covid-19 vaccine next week,
                    ..
                  </p>
                </div>
                <Link
                  target="_blank"
                  href="http://timesofindia.indiatimes.com/articleshow/80123824.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst">
                  Read More
                </Link>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="featured-imagebox featured-imagebox-blog">
              <div className="featured-thumbnail">
                <img
                  className={classes.image}
                  src="https://www.isglobal.org/documents/10179/7119080/faqs_vacunas_eng.jpg/7f489cb0-2b89-4ecf-9b1f-56990d9ad7d7?t=1607591236033"
                  alt="blog"
                />
              </div>
              <div className="featured-content">
                <div className="ttm-box-post-date">
                  <span className="ttm-entry-date">
                    <time
                      className="entry-date"
                      datetime="2020-01-06T07:07:55+00:00">
                      06
                      <span className="entry-month entry-year">Jan</span>
                    </time>
                  </span>
                </div>
                <div className="featured-title">
                  <h5>Explained: India Covid-19 vaccination FAQs</h5>
                </div>
                <div className="post-meta">
                  <span className="ttm-meta-line">
                    Source: The Indian Express
                  </span>
                </div>
                <div className="featured-desc">
                  <p>
                    The national drug regulator on Sunday gave “emergency
                    restricted” approval...
                  </p>
                </div>
                <Link
                  target="_blank"
                  href="https://indianexpress.com/article/explained/india-coronavirus-vaccination-faq-7133092/">
                  Read More
                </Link>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="featured-imagebox featured-imagebox-blog">
              <div className="featured-thumbnail">
                <img
                  className={classes.image}
                  src="https://www.biospectrumasia.com/uploads/articles/shutterstock_1714108390_1595055139-16781.jpg"
                  alt="blog"
                />
              </div>
              <div className="featured-content">
                <div className="ttm-box-post-date">
                  <span className="ttm-entry-date">
                    <time
                      className="entry-date"
                      datetime="2020-04-01T07:07:55+00:00">
                      01
                      <span className="entry-month entry-year">Apr</span>
                    </time>
                  </span>
                </div>
                <div className="featured-title">
                  <h5>Study shows middle-age COVID-19 mortality risk</h5>
                </div>
                <div className="post-meta">
                  <span className="ttm-meta-line">Source: HealthWorld</span>
                </div>
                <div className="featured-desc">
                  <p>
                    Middle-aged people, and not just the elderly, have a
                    dramatically...
                  </p>
                </div>
                <Link
                  target="_blank"
                  href="https://health.economictimes.indiatimes.com/news/diagnostics/study-shows-middle-age-covid-19-mortality-risk/74921314">
                  Read More
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
