import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import Page from '../../components/Page'
import Footer from '../../../components/CommonComponents/Footer'
import TopBarHome from '../../layouts/MainLayout/TopBar'
import bannerimg from '../../../images/clinicbanner.png'
import { ClinicStore } from '../../../stores'
import { NETWORK_STATUS, CLINIC_USER_ID } from '../../../Constants'
import Loader from '../../components/ClinicLoader'
import { useHistory, useParams } from 'react-router-dom'
import success from '../../../images/checked.png'
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(3)
  },
  footercol: {
    width: '100%',
    paddingRight: '5%',
    display: 'flex',
    paddingTop: '50px'
  }
}))

const PaymentErrorMessage = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const { status } = useParams()
  return (
    <Page className={classes.root} title="Login">
      {/* {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )} */}
      <TopBarHome />

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        style={{ paddingTop: '20px' }}>
        <Container maxWidth="sm">
          <Box className="payment-error-col">
            {/* success */}
            {status == 'success' ? (
              <Box className="payment-success">
                <img src={success} alt="success icon" />
                <h3>Thank you!</h3>
                <p>Payment Successful</p>
              </Box>
            ) : status == 'failed' ? (
              <Box className="payment-erroe">
                <i class="fa fa-exclamation-triangle"></i>
                <Box className="message">
                  <h2>Oops!</h2>
                  <p>Your payment was declined</p>
                  <p>
                    Please <Link>Go back</Link> and try again
                  </p>
                </Box>
              </Box>
            ) : (
              <Box className="payment-request">
                <p className="alert-warning">
                  Warning! This alert box could indicate a warning that might
                  need attention.
                </p>
              </Box>
            )}

            {/* request */}
          </Box>
        </Container>
      </Box>
      <div className={classes.footercol}>
        <Footer />
      </div>
    </Page>
  )
}

export default PaymentErrorMessage
