import React, { useEffect, useState } from 'react'
import {
  Typography,
  Divider,
  CircularProgress,
  Snackbar,
  makeStyles
} from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import MuiAlert from '@material-ui/lab/Alert'
import moment from 'moment'

import { ClinicStore } from '../../../../../stores'
import { NETWORK_STATUS } from '../../../../../Constants'
import PrintPrescriptionItem from './PrintPrescriptionItem'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const DefaultPrescriptionList = ({ open, appointmentData }) => {
  const classes = useStyles()

  const { generatePrescriptionPdf } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  const [pdfBlob, setPdfBlob] = useState(null)
  const [networkStatus, setNetworkStatus] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    const generateDefaultList = async () => {
      setNetworkStatus(NETWORK_STATUS.FETCHING)
      try {
        const responseData = await generatePrescriptionPdf({
          appointment_id: appointmentData.id
        })

        if (
          !responseData ||
          responseData.statusCode !== 200 ||
          !responseData.pdf_blob
        ) {
          setNetworkStatus(NETWORK_STATUS.FAILED)
          setError(true)
          return
        }

        setNetworkStatus(NETWORK_STATUS.SUCCESS)
        setPdfBlob(responseData.pdf_blob)
      } catch (error) {
        setNetworkStatus(NETWORK_STATUS.FAILED)
        setError(true)
      }
    }

    if (open) {
      generateDefaultList()
    }
  }, [open, generatePrescriptionPdf, appointmentData.id])

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setNetworkStatus(null)
  }

  let contentInsideCard = (
    <div className={classes.containerDiv}>
      <CircularProgress />
    </div>
  )

  if (networkStatus === NETWORK_STATUS.FETCHING) {
    contentInsideCard = (
      <div className={classes.containerDiv}>
        <CircularProgress />
      </div>
    )
  } else if (error) {
    contentInsideCard = (
      <ErrorOutline className={classes.errorImage} color="error" />
    )
  } else {
    contentInsideCard = (
      <div className={classes.containerDiv}>
        <ul className={classes.listContainer}>
          <PrintPrescriptionItem
            updateStatus={setNetworkStatus}
            pdfBlob={pdfBlob}
            fileExtention={'pdf'}
            appointmentDate={moment(appointmentData.appointment_date).format(
              'DD-MM-YYYY'
            )}
          />
        </ul>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom>
        Default Prescription
      </Typography>
      <Divider variant="middle" />
      {contentInsideCard}
      <Snackbar
        open={networkStatus === NETWORK_STATUS.FAILED}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error">
          Oops! Something went wrong. Try again later!
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default DefaultPrescriptionList

const useStyles = makeStyles(theme => ({
  containerDiv: {
    height: theme.spacing(25),
    padding: `0 -${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
    margin: `0 -${theme.spacing(2)}px`,
    '& *': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0
    }
  },
  errorImage: {
    height: theme.spacing(22),
    width: theme.spacing(22),
    display: 'block',
    padding: theme.spacing(2),
    margin: 'auto'
  },
  listContainer: {
    listStyleType: 'none',
    display: 'grid',
    gridAutoRows: 'min-content',
    width: '100%',
    gridRowGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    alignSelf: 'stretch',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  }
}))
