import React from 'react'
import {
  Container,
  Grid,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core'
import Page from '../../../components/Page'
import Budget from './Budget'
import LatestOrders from './LatestOrders'

import TasksProgress from './TasksProgress'
import TotalCustomers from './TotalCustomers'
import TotalProfit from './TotalProfit'
import Request from './Request'
import RequestList from './RequestList'
import { ClinicStore } from '../../../../stores'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    
  }
}))

const DashboardContent = () => {
  const classes = useStyles()

  const { getWalletDetails } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))

  const { wallet } = ClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))

  React.useEffect(() => {
    getWalletDetails()
  }, [])

  return (
    <Page className={classes.root} title="Dashboard" style={{padding:'0px'}}>
      <Container maxWidth={false} style={{padding:'0px'}}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              wallet_balance={wallet?.clinic_wallet_Data?.wallet_balance}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers
              earnings={{
                service_earnings: wallet?.clinic_wallet_Data?.service_earnings,
                fee_earnings: wallet?.clinic_wallet_Data?.fees_earning,
                total_earnings: wallet?.clinic_wallet_Data?.net_earnings
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress
              online_earning={wallet?.clinic_wallet_Data?.digital_earnings}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit
              offline_earning={wallet?.clinic_wallet_Data?.offline_earnings}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Request
              wallet_balance={wallet?.clinic_wallet_Data?.wallet_balance}
              getWalletDetails={getWalletDetails}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            {/* <Typography
              color="textSecondary"
              gutterBottom
              style={{
                fontSize: '0.8rem',
                padding: '10px 15px',
                backgroundColor: '#111d4e',
                color: '#fff'
              }}> */}
              {/* You are on trail version */}
              {/* Total Rs 1498 (subscription cost) will be held from your account.
              If wallet balance is below Rs 1498, <a>Click here</a> to pay your
              subscription. */}
              {/* <br />
              <br /> Please note, your account will be discontinued if due is
              not cleared by 03-02-2021. */}
            {/* </Typography> */}
          </Grid>
          {/* <Grid item lg={12} md={12} xl={12} xs={12}>
            <LatestOrders />
          </Grid>

          <Grid item lg={12} md={12} xl={12} xs={12}>
            <RequestList />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  )
}

export default DashboardContent
