import { action, thunk } from 'easy-peasy'
import BaseModel from '../Base'
import { BOOKING_STATUS, NETWORK_STATUS, DOCTOR_USER_ID } from '../../Constants'
import api from '../../api'
import moment from 'moment'

export default {
  ...BaseModel(),
  upcomingdata: [],
  totalPagesCount: 0,
  upcomingType: '',
  manualPrescriptionList: [],

  getList: thunk(async (actions, payload, { getStoreState }) => {
    actions.setAppointments([])
    actions.setTotalPages(0)
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      Authorization: localStorage.getItem('doctor_auth_key')
    })

    const {
      list_type,
      status,
      visit_type,
      appointment_type,
      sorting,
      currentPage,
      searchString
    } = payload

    const response = await api.doctor.getAppointmentList({
      user_id: localStorage.getItem(DOCTOR_USER_ID),
      status_list:
        status !== BOOKING_STATUS.ALL.value
          ? [status]
          : list_type === 'past' || list_type === 'all'
          ? ['2', '3', '4', '7']
          : ['2', '3', '4'],
      visit_type: visit_type ? visit_type : 0,
      table_config_rows_per_page: '10',
      table_config_page_no: currentPage ? currentPage : '1',
      list_type: list_type,
      appointment_type: appointment_type,
      pattern: searchString ? searchString : '',
      sorting: sorting ? sorting : ''
    })
    // sorting:{
    //   created_at: 'ASC',
    //   start_time: 'ASC',
    //   appointment_date: 'ASC'
    // }
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      if (response?.data?.appointments) {
        actions.setAppointments(response.data.appointments)
        actions.setTotalPages(response.data.total_pages_count)
      }
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setAppointments: action((state, payload) => {
    state.upcomingdata = payload
  }),
  setTotalPages: action((state, payload) => {
    state.totalPagesCount = payload
  }),

  setTele: action((state, payload) => {
    state.upcomingType = 'tele'
    localStorage.setItem("upcomingType", "tele")
  }),

  setInPerson: action((state, payload) => {
    state.upcomingType = 'inPerson'
    localStorage.setItem("upcomingType", "inPerson")
  }),

  setManualPrescriptionList: action((state, payload) => {
    state.manualPrescriptionList = payload
  }),

  fetchManualPrescriptionList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    try {
      const response = await api.clinic.clinicListManualPrescription(payload)
      const responseData = response?.data

      if (!responseData) {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      } else if (responseData?.statusCode === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

        const baseURL = responseData?.base_url
        const presList = responseData?.prescriptions
          ? responseData?.prescriptions
          : []

        const modifiedList = presList.map(pres => ({
          ...pres,
          prescription_url: `${baseURL}${pres.prescription_url}`
        }))

        actions.setManualPrescriptionList(modifiedList)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    } catch (error) {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
    return
  })
}
