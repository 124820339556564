import React from 'react'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import NotificationIcon from '../../images/notification-icon.png'

const Notification = ({ total }) => {
  const classes = useStyles()
  return (
    <Button variant="outlined" className={classes.notification}>
      <img src={NotificationIcon} alt="" />
      Notifications
      <span className={classes.text}>{total}</span>
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  notification: {
    borderRadius: '40px',
    color: '#333',
    textTransform: 'capitalize',
    '@media (max-width:1024px)': {
      fontSize: '0px',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      minWidth: '30px',
      textAlign: 'center',
      padding: '0px',
      position: 'absolute',
      right: '15px',
      top: '10px'
    },
    '& img': {
      marginRight: '5px',
      '@media (max-width:1024px)': {
        marginRight: '0px',
        marginLeft: '3px',
        height: '18px'
      }
    }
  },
  text: {
    color: theme.palette.primary.main,
    marginLeft: '5px'
  }
}))

export default Notification
