import React, { useEffect, useState } from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Page from '../../../components/Page'
import TotalDoctors from './TotalDoctors'
import ActiveDoctors from './ActiveDoctors'
import DoctorList from './DoctorList'
import { ClinicStore } from '../../../../stores'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const DoctorsContent = () => {
  const classes = useStyles()
  const [totalDoctors, setTotalDoctors] = useState(0)
  const [activeDoctors, setActiveDoctors] = useState(0)
  const { getDoctorList } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))
  const { doctorList, activeCount, inactiveCount } = ClinicStore.useStoreState(
    state => ({
      ...state.doctorDetails
    })
  )

  useEffect(() => {
    if (doctorList == undefined) {
      //
    } else {
      setTotalDoctors(activeCount + inactiveCount)
      setActiveDoctors(activeCount)
    }
  }, [activeCount, inactiveCount])

  return (
    <Page className={classes.root} title="Clinic Doctors">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalDoctors totalDoctors={totalDoctors} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <ActiveDoctors activeDoctors={activeDoctors} />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <DoctorList doctors={doctorList} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default DoctorsContent
