import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core'
import { NETWORK_STATUS } from '../../../../Constants'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ClinicStore } from '../../../../stores'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import Loader from '../../../components/ClinicLoader'

const useStyles = makeStyles({
  root: {},
  errMsg: {
    color: 'red'
  },
  button: {
    padding: '0 30px',
    fontWeight: '500',
    borderRadius: '50px',
    letterSpacing: '0',
    textTransform: 'none',
    backgroundColor: '#111d4e',
    color: '#fff',
    height: '40px',
    display: 'block',
    '&:hover': {
      backgroundColor: '#252f58'
    }
  },
  ContentCol: {
    boxShadow: '0 1px 8px 0 rgba(0,0,0,0.1)',
    width: '40%',
    margin: '0px auto',
    padding: '30px 15px',
    borderRadius: '10px',
    '@media (max-width:767px)': {
      width: '90%'
    }
  }
})

const Password = ({ className, ...rest }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')

  const { changePassword } = ClinicStore.useStoreActions(actions => ({
    ...actions.auth
  }))

  const { networkStatus } = ClinicStore.useStoreState(state => ({
    ...state.auth
  }))

  const validationSchema = yup.object().shape({
    password: yup.string().required('Password is required'),
    // .min(9, 'Account number should be between 9 to 18 digits')
    // .max(18, 'Account number should be between 9 to 18 digits'),
    confirm: yup
      .string()
      .required('Confirm Password is required')
      .oneOf(
        [yup.ref('password'), null],
        'Confirm Password and Password must be same'
      )
    // .min(9, 'Account number should be between 9 to 18 digits')
    // .max(18, 'Account number should be between 9 to 18 digits')
  })

  const submit = values => {
    changePassword({ ...values }).then(data => {
      if (data.statusCode === 200) {
        setOpen(true)
        setSnackBarMessage('Password changed successfully')
        setmultiButton(false)
        setAlertType('success')
      } else {
        setOpen(true)
        setSnackBarMessage(data?.message)
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm: ''
    },
    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const onClose = () => {
    setOpen(false)
  }

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={onClose}
      />
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Card className={classes.ContentCol}>
        <h3
          style={{
            fontSize: '18px',
            fontWeight: '500',
            textAlign: 'center',
            margin: '0px'
          }}>
          Update password
        </h3>

        <CardContent>
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            required
            name="password"
            onChange={formik.handleChange}
            type="password"
            value={formik.values.password}
            onBlur={formik.handleBlur}
            helperText={
              <div className={classes.errMsg}>
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null}
              </div>
            }
          />
          <TextField
            fullWidth
            label="Confirm password"
            margin="normal"
            name="confirm"
            required
            onChange={formik.handleChange}
            type="password"
            value={formik.values.confirm}
            onBlur={formik.handleBlur}
            helperText={
              <div className={classes.errMsg}>
                {formik.touched.confirm && formik.errors.confirm
                  ? formik.errors.confirm
                  : null}
              </div>
            }
          />
        </CardContent>

        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={formik.handleSubmit}>
            Update
          </Button>
        </Box>
      </Card>
    </form>
  )
}

Password.propTypes = {
  className: PropTypes.string
}

export default Password
