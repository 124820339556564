import React, { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core'
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Calendar as calendarIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  DollarSign as bankIcon,
  List as listIcon,
  CheckCircle as AppointmentIcon,
  UserCheck as BookIcon
} from 'react-feather'
import NavItem from './NavItem'
import { ClinicStore } from '../../../../stores'
import CardBgImg from '../../../../images/right-top-img3.png'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Clinic Admin',
  name: 'Care Medical Clinic'
}

const items = [
  {
    href: '/clinic/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/clinic/doctors',
    icon: UsersIcon,
    title: 'Doctors'
  },
  {
    href: '/clinic/appointments',
    icon: AppointmentIcon,
    title: 'Appointments'
  },
  {
    href: '/clinic/schedule',
    icon: calendarIcon,
    title: 'Schedule'
  },
  {
    href: '/clinic/booking',
    icon: BookIcon,
    title: 'Book Appointment'
  },
  {
    href: '/clinic/services',
    icon: listIcon,
    title: 'Services'
  },
  {
    href: '/clinic/wallet',
    icon: bankIcon,
    title: 'Wallet & Earnings'
  },
  {
    href: '/clinic/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/clinic/settings',
    icon: SettingsIcon,
    title: 'Settings'
  },
  {
    href: '/clinic/login',
    icon: LockIcon,
    title: 'Logout'
  }
]

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
    background: `linear-gradient(45deg, #680fad, #063bc4)`,
    backgroundSize: 'cover'
  },
  desktopDrawer: {
    width: 256,
    top: 110,
    height: 'calc(100% - 80px)',
    background: `linear-gradient(45deg, #680fad, #063bc4)`,
    backgroundSize: 'cover'
  },
  avatar: {
    cursor: 'pointer',
    width: 84,
    height: 84,
    border: '3px solid #ececec'
  }
}))

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const location = useLocation()
  const { getClinicProfileDetails } = ClinicStore.useStoreActions(actions => ({
    ...actions.profile
  }))
  const { profileDetails, networkStatus } = ClinicStore.useStoreState(
    state => ({
      ...state.profile
    })
  )

  useEffect(() => {
    getClinicProfileDetails({ cachedValues: true })
  }, [])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={profileDetails?.logo ? profileDetails?.logo : user.avatar}
          to="/clinic/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h6"
          style={{ color: '#dadbe0', wordBreak: 'break-all' }}>
          {profileDetails?.display_name
            ? profileDetails?.display_name
            : 'Loading...'}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          style={{ color: '#dadbe0' }}>
          {user.jobTitle}
        </Typography>
      </Box>
      {/* <Divider /> */}
      <Box>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
}

export default NavBar
