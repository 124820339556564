import React from 'react'
import { makeStyles, Paper, Box, Typography } from '@material-ui/core'
import { DashboardLayout } from '../../doctorLayouts'
import { DoctorStore } from '../../stores'

export default function Pricing() {
  const classes = useStyles()

  return (
    <DashboardLayout>
      <Box className={classes.aside} style={{ padding: '40px' }}>
        <Paper elevation={3} className={classes.paper}>
          <PricingDetails />
        </Paper>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  h4: {
    fontSize: '24px',
    marginBottom: '40px'
  },
  p: {
    fontSize: '15px',
    marginBottom: '10px'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    minHeight: '100vh',
    '& .MuiTab-root': {
      fontSize: '12px',
      padding: '6px 0px'
    },
    '@media (max-width:767px)': {
      marginTop: '35px'
    }
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '15px'
  }
}))

const PricingDetails = () => {
  const classes = useStyles()

  const { getSubscriptionList } = DoctorStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const { subscriptionList } = DoctorStore.useStoreState(state => ({
    ...state.profile
  }))

  React.useEffect(() => {
    getSubscriptionList()
  }, [])

  React.useEffect(() => {
    if (subscriptionList.length !== 0) {
    }
  }, [subscriptionList])

  return (
    <div className="pricing-col">
      <Typography variant="h4" className={classes.annotation}>
        Show our pricing plans
      </Typography>
      <Box className="owl-stage">
        <Box className="ui-card ui-curve shadow-lg">
          <Box className="card-header bg-dark-gray left-box1">
            <h4>Free Trial</h4>
            <Box className="price text-red">
              {/* <span className="curency">£</span> */}
              <span className="price">0</span>
              <span className="period">3 Days free</span>
            </Box>
            <h6 className="sub-heading">24/7 Support</h6>
          </Box>
          <div className="card-body">
            <div className="st-pricing-feature feature2">
              <ul className="st-pricing-feature-list st-mp0">
                <li>Full access for 3 days with a limit of 10 consultations</li>
              </ul>
              <div className="st-pricing-btn">
                <a
                  href="#"
                  className="st-btn st-style2 st-color1 st-size-medium">
                  Contact Now
                </a>
              </div>
              <div className="st-height-b30 st-height-lg-b30"></div>
            </div>
          </div>
        </Box>
        <Box className="ui-card ui-curve shadow-lg color-card">
          <Box className="card-header ui-gradient-peach">
            <h4>Monthly</h4>
            <Box className="price text-red">
              {/* <span className="curency">£</span> */}
              <span className="price">599</span>
              <span className="period">Month</span>
            </Box>
            <h6 className="sub-heading">24/7 Support</h6>
          </Box>
          <div className="card-body">
            <div className="st-pricing-feature">
              <ul className="st-pricing-feature-list st-mp0">
                <li>Upto 20 consulations</li>
                <li>Topup ammount (min 500)</li>
                <li>Per consult 10% of fees</li>
                <li>24/7 virtual assistant</li>
                <li>Email and SMS notification</li>
              </ul>
              <div className="st-pricing-btn">
                <a
                  href="#"
                  className="st-btn st-style2 st-color1 st-size-medium">
                  Contact Now
                </a>
              </div>
              <div className="st-height-b30 st-height-lg-b30"></div>
            </div>
          </div>
        </Box>
        <Box className="ui-card ui-curve shadow-lg">
          <Box className="card-header bg-dark-gray right-box3">
            <h4>Annual Plan</h4>
            <Box className="price text-red">
              {/* <span className="curency">£</span> */}
              <span className="price">1599</span>
              <span className="period">/Month</span>
              <span className="price" style={{ marginLeft: '25px' }}>
                1299
              </span>
              <span className="period">/Year</span>
            </Box>
            <h6 className="sub-heading">24/7 Support</h6>
          </Box>
          <div className="card-body">
            <div className="st-pricing-feature feature3">
              <ul className="st-pricing-feature-list st-mp0">
                <li>Unlimited consulations</li>
                <li>No convenience fee</li>
                <li>24/7 virtual assistant</li>
                <li>Email and SMS notification</li>
                <li>Communication center (200 SMS)</li>
              </ul>
              <div className="st-pricing-btn">
                <a
                  href="#"
                  className="st-btn st-style2 st-color1 st-size-medium">
                  Contact Now
                </a>
              </div>
              <div className="st-height-b30 st-height-lg-b30"></div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  )
}
