import React, { useEffect } from 'react'
import { makeStyles, Box, Grid } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { FiPrinter } from 'react-icons/fi'
import { TiClipboard } from 'react-icons/ti'
import { useParams } from 'react-router-dom'
import { DOCTOR_USER_ID } from '../../Constants'
import { DoctorStore } from '../../stores'
import moment from 'moment'
import ListBgHoverImg from '../../images/listbghover.png'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import ManualPrescriptionDialog from '../CommonComponents/ManualPrescriptionDialog'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Details = props => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [snackOpen, setSnackOpen] = React.useState(false)
  const params = useParams()
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const snackClose = () => {
    setSnackOpen(false)
  }

  useEffect(() => {
    if (open) {
      viewVisitSummary({
        user_id: localStorage.getItem(DOCTOR_USER_ID), //'2a717c90-44d2-4fb4-b17e-a3ca0b21931f',
        patient_id: params.patient_id,
        view_type: 'view',
        appointment_id: props.item.id
      })
    }
  }, [open])

  const { viewVisitSummary, generatePrescriptionPdf } =
    DoctorStore.useStoreActions(actions => ({
      ...actions.patients
    }))

  const { viewSummary, prescription, prescriptionResult } =
    DoctorStore.useStoreState(state => ({
      ...state.patients
    }))

  const { fetchManualPrescriptionList, updateNetworkStatus } =
    DoctorStore.useStoreActions(actions => ({
      ...actions.appointments
    }))

  const { networkStatus, manualPrescriptionList } = DoctorStore.useStoreState(
    state => ({
      ...state.appointments
    })
  )

  const [showManualPrescriptionDialog, setShowManualPrescriptionDialog] =
    React.useState(false)

  const downloadPdf = pdf_blob => {
    if (pdf_blob) {
      let presDate = moment(viewSummary.appointment_date).format('DD-MM-YYYY')
      const linkSource = `data:application/pdf;base64,${pdf_blob}`
      const downloadLink = document.createElement('a')
      const fileName = `Prescription_${presDate}.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
  }

  React.useEffect(() => {
    if (prescriptionResult.statusCode === 400) {
      setOpen(false)
      setSnackOpen(true)
    }
  }, [prescriptionResult])

  const generatePrescription = () => {
    generatePrescriptionPdf({
      appointment_id: props.item.id
    }).then(data => {
      downloadPdf(data)
    })
  }

  return (
    <div className={classes.DoctorName}>
      <ManualPrescriptionDialog
        open={showManualPrescriptionDialog}
        onClose={() => setShowManualPrescriptionDialog(false)}
        appointmentId={props.item.id}
        manualPrescriptionList={manualPrescriptionList}
        networkStatus={networkStatus}
        fetchManualPrescriptionList={fetchManualPrescriptionList}
        updateNetworkStatus={updateNetworkStatus}
      />
      <button
        className={classes.DownloadBtn}
        onClick={handleOpen}
        style={{ width: '70px' }}>
        View
      </button>

      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snackClose}
        // anchorOrigin={{ vertical: "top", horizontal: "top" }}
      >
        <Alert onClose={snackClose} severity="error">
          {prescriptionResult.message}
        </Alert>
      </Snackbar>

      <Dialog
        classes={{ paper: classes.modalView }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          Visit Summary
        </DialogTitle>
        <DialogContent>
          <Box className={classes.ModalForm}>
            <Box className={classes.Btncol}>
              <button
                className={classes.Reportbtn}
                onClick={() => setShowManualPrescriptionDialog(true)}>
                <TiClipboard className={classes.ReportIcon} />
                <span>Print Manual Prescriptions</span>
              </button>
              <button
                className={classes.Reportbtn}
                onClick={generatePrescription}>
                <TiClipboard className={classes.ReportIcon} />
                <span>Generate Report</span>
              </button>
            </Box>
            <Box className={classes.TopContent}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <h3>
                    <span>Visit Date:</span>{' '}
                    {viewSummary && viewSummary.appointment_date
                      ? moment(viewSummary.appointment_date).format(
                          'DD/MM/YYYY'
                        ) + ' '
                      : null}
                    {viewSummary && viewSummary.start_time
                      ? moment(viewSummary.start_time, 'hh:mm:ss').format(
                          'hh:mm A'
                        )
                      : null}
                  </h3>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <h3>
                    <span>Visit Status:</span>{' '}
                    <div>
                      {viewSummary && viewSummary.status === '1'
                        ? 'Booked'
                        : viewSummary.status === '2'
                        ? 'Confirmed'
                        : viewSummary.status === '3'
                        ? 'Checked-In'
                        : viewSummary.status === '4'
                        ? 'In-Progress'
                        : viewSummary.status === '5'
                        ? 'Cancelled'
                        : viewSummary.status === '6'
                        ? 'No-show'
                        : viewSummary.status === '7'
                        ? 'Completed'
                        : viewSummary.status === '8'
                        ? 'rescheduled'
                        : 'NA'}
                    </div>
                  </h3>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                   
                  </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <h3>
                    <span>Visit Type:</span>{' '}
                    {viewSummary && viewSummary.visit_type === '1'
                      ? 'Initial consult'
                      : 'Followup'}{' '}
                  </h3>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <h3>
                    <span>Mode of Consult:</span>{' '}
                    {viewSummary && viewSummary.appointment_type === '1'
                      ? 'Offline'
                      : 'Online'}
                  </h3>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4}>
                   
                  </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <h3>
                    <span>Treated by:</span>{' '}
                    {viewSummary && viewSummary.doctor?.name}
                  </h3>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <h3>
                    <span>Referred to:</span>
                    {viewSummary?.doctorName ? viewSummary.doctor?.name : 'N/A'}
                  </h3>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.BodyVisit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h3>
                    <span>Body Vitals:</span>
                    {viewSummary &&
                    viewSummary.vitals &&
                    viewSummary.vitals.length ? (
                      viewSummary.vitals[0].sbp ? (
                        <div>
                          {Math.trunc(viewSummary.vitals[0].sbp)} SP/
                          {Math.trunc(viewSummary.vitals[0].dbp)} BP,{' '}
                          {Math.trunc(viewSummary.vitals[0].heart_rate)} HR,{' '}
                          {Math.trunc(viewSummary.vitals[0].respiratory_rate)}{' '}
                          Resp Rate, {viewSummary.vitals[0].bmi_index} BMI ,{' '}
                          {viewSummary.vitals[0].temperature} Temp
                        </div>
                      ) : (
                        'N/A'
                      )
                    ) : null}
                  </h3>
                </Grid>
                <Grid item xs={12}>
                  <h3>
                    <span>Purpose of Consultation:</span>{' '}
                    {viewSummary.purpose ? (
                      <div>
                        {viewSummary.purpose ? viewSummary.purpose : 'N/A'}
                      </div>
                    ) : null}
                  </h3>
                </Grid>
                <Grid item xs={12}>
                  <h3>
                    <span>Diagnosis:</span>
                    {viewSummary &&
                    viewSummary.diagnosis &&
                    viewSummary.diagnosis.diagnosis_comment
                      ? viewSummary.diagnosis.diagnosis_comment
                      : 'N/A'}
                  </h3>
                </Grid>
                <Grid item xs={12}>
                  <h3>
                    <span>Clinical Notes:</span>
                    {viewSummary &&
                    viewSummary.diagnosis &&
                    viewSummary.diagnosis.clinical_notes
                      ? viewSummary.diagnosis.clinical_notes
                      : 'N/A'}
                  </h3>
                </Grid>
                <Grid item xs={12}>
                  <h3>
                    <span>Observations:</span>
                    {viewSummary &&
                    viewSummary.diagnosis &&
                    viewSummary.diagnosis.observations
                      ? viewSummary.diagnosis.observations
                      : 'N/A'}
                  </h3>
                </Grid>
                <Grid item xs={12}>
                  <h3>
                    <span>Suggestions/Instructions:</span>
                    {viewSummary &&
                    viewSummary.diagnosis &&
                    viewSummary.diagnosis.suggestions
                      ? viewSummary.diagnosis.suggestions
                      : 'N/A'}
                  </h3>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.BodyVisit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className={classes.Medication}>
                    <h4>Medication:</h4>
                    {viewSummary &&
                    viewSummary.medications &&
                    viewSummary.medications.length !== 0
                      ? viewSummary.medications.map((item, index) => (
                          <div style={{ padding: '5px 5px' }}>
                            <p>
                              {viewSummary.medications.length > 1
                                ? index + 1 + '. '
                                : ''}
                              Drugname :{' '}
                              {item.drug_name ? item.drug_name : 'N/A'}
                            </p>
                            <p>
                              Frequency:{' '}
                              {item.frequency ? item.frequency : 'N/A'}
                            </p>
                            <p>
                              Duration:{' '}
                              {item.duration ? item.duration + ' Days' : 'N/A'}{' '}
                            </p>
                            <p>
                              Instructions:{' '}
                              {item.instructions ? item.instructions : 'N/A'}
                            </p>
                          </div>
                        ))
                      : 'N/A'}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className={classes.Medication}>
                    <h4>Lab Orders:</h4>
                    {viewSummary &&
                    viewSummary.labs &&
                    viewSummary.labs.length !== 0
                      ? viewSummary.labs.map((item, index) => (
                          <div>
                            <p>
                              {viewSummary.labs.length > 1
                                ? index + 1 + '. '
                                : ''}
                              Test name: {item.testname ? item.testname : 'N/A'}
                            </p>
                            <p>
                              Instructions:{' '}
                              {item.lab_instructions
                                ? item.lab_instructions
                                : 'N/A'}
                            </p>
                          </div>
                        ))
                      : 'N/A'}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  modalView: {
    maxWidth: '60rem !important'
  },
  ReatingCol: {
    display: 'flex',
    '& fa': {
      color: '#3f4b79',
      marginRight: '5px'
    }
  },
  Medication: {
    marginBottom: '30px',
    '& p': {
      fontSize: '14px',
      padding: '0 20px',
      marginBottom: '0'
    }
  },
  Btncol: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '15px',
    '@media (max-width:850px)': {
      flexDirection: 'column'
    }
  },
  Reportbtn: {
    background: '#0D46C1',
    cursor: 'pointer',
    borderRadius: '8px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 30px',
    marginLeft: '15px',
    border: 'none',
    '&:hover': {
      cursor: 'pointer'
    },
    '@media (max-width:850px)': {
      marginTop: '15px',
      marginLeft: '0px'
    }
  },
  BodyVisit: {
    marginTop: '30px',
    '& h5': {
      color: '#333',
      fontSize: '14px',
      fontWeight: '600',
      padding: '0 20px'
    },
    '& h4': {
      color: '#3f4b79',
      fontSize: '14px',
      fontWeight: '600',
      marginBottom: '0px',
      padding: '0 20px'
    }
  },
  ReportIcon: {
    fontSize: '20px',
    cursor: 'pointer',
    marginRight: '10px'
  },
  PrinterIcon: {
    fontSize: '20px',
    marginRight: '10px'
  },
  TopContent: {
    width: '100%',
    padding: '20px',
    backgroundColor: '#0D46C1',
    borderRadius: '10px',
    backgroundSize: 'cover',
    '& h3': {
      color: '#fff !important',
      '& span': {
        color: '#d0d2da !important'
      }
    }
  },
  Input: {
    width: '100%'
  },
  InputCol: {
    width: '100%',
    display: 'flex',
    marginBottom: '0',
    justifyContent: 'center',
    '& p': {
      fontSize: '18px',
      lineHeight: '55px',
      marginRight: '10px',
      width: '90px'
    }
  },
  ModalForm: {
    width: '100%',
    padding: '5px 20px 20px',
    height: '550px',
    '@media (max-width:991px)': {
      height: '300px'
    },
    '& h3': {
      fontSize: '13px',
      color: '#121212',
      display: 'flex',
      padding: '0 20px',
      marginBottom: '0',
      '& span': {
        color: '#242e54',
        marginRight: '10px'
      }
    }
  },
  ModalBox: {
    width: '80%',
    background: '#fff',
    padding: '0px',
    '@media (max-width:991px)': {
      height: '300px',
      overflowY: 'auto'
    },
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      color: '#fff',
      background: '#111d4e',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '999999999'
  },
  DownloadBtn: {
    width: '120px',
    background: '#0D46C1',
    height: '35px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '35px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer'
  },
  DoctorName: {
    '& p': {
      marginBottom: '0px',
      color: '#666565'
    }
  },
  Star: {
    color: '#3f4b79',
    marginRight: '5px',
    fontSize: '20px'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  }
}))

export default Details
