import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import {
  Box,
  Button,
  Card,
  TextField,
  Grid,
  Divider,
  Table,
  TableBody,
  Typography,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Pagination from '@material-ui/lab/Pagination'
import EditIcon from '@material-ui/icons/Edit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { ClinicStore } from '../../../../stores'
import { NETWORK_STATUS } from '../../../../Constants'
import Loader from '../../../components/ClinicLoader'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import { Formik, useFormik } from 'formik'
import * as yup from 'yup'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: '1px 0 7px 0 rgba(0,0,0,0.1)',
    fontFamily: 'Poppins',
    borderRadius: '10px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  modalView: {
    width: '350px !important',
    padding: '10px'
  },
  textArea: {
    width: '100%'
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '0 20px',
    borderRadius: '50px',
    height: '40px',
    fontSize: '13px'
  },
  modalSecondaryButton: {
    backgroundColor: '#aeaeae',
    padding: '0 20px',
    borderRadius: '50px',
    height: '40px',
    fontSize: '13px'
  },
  InputCol: {
    marginBottom: '15px'
  },
  ModalHead: {
    borderBottom: '1px rgba(0,0,0,0.1) solid'
  }
}))

const ServiceList = ({ className, ...rest }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [addDialog, setAddDialog] = useState(false)
  const [selectedService, setSelectedService] = useState(null)
  const [edit, setEditMode] = useState(false)
  const [deleteMode, setDeleteMode] = useState(false)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageCount, setPageCount] = React.useState(1)

  const {
    getClinicServices,
    addClinicServices,
    editClinicServices,
    deleteClinicServices,
    clinicServicesUpdateStatus
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.services
  }))
  const { servicesList, networkStatus, totalPagesCount } =
    ClinicStore.useStoreState(state => ({
      ...state.services
    }))

  useEffect(() => {
    getClinicServices({ current_page: currentPage })
  }, [])

  const onClose = () => {
    setOpen(false)
  }

  const handleStatusChange = value => {
    clinicServicesUpdateStatus(value).then(data => {
      if (data.statusCode === 200) {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('success')
        getClinicServices({})
      } else {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const handleEdit = value => {
    setEditMode(true)
    setDeleteMode(false)
    setSelectedService(value)
    setAddDialog(true)
  }

  const handleDelete = value => {
    setEditMode(false)
    setDeleteMode(true)
    setSelectedService(value)
    setAddDialog(true)
  }

  const addNewService = () => {
    setEditMode(false)
    setDeleteMode(false)
    setAddDialog(true)
    setSelectedService(null)
  }

  const addService = (values, { resetForm }) => {
    setAddDialog(false)
    addClinicServices(values).then(data => {
      if (data?.statusCode === 200) {
        setOpen(true)
        setSnackBarMessage(data?.message)
        setmultiButton(false)
        setAlertType('success')
        getClinicServices({})
        resetForm({ values: '' })
      } else {
        setOpen(true)
        setSnackBarMessage(data?.message)
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }
  const editService = values => {
    setAddDialog(false)
    values.service_id = selectedService.id
    editClinicServices(values).then(data => {
      if (data?.statusCode === 200) {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('success')
        getClinicServices({})
      } else {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const deleteService = values => {
    setAddDialog(false)
    deleteClinicServices(selectedService).then(data => {
      if (data?.statusCode === 200) {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('success')
        getClinicServices({})
      } else {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const validationSchema = yup.object().shape({
    service_name: yup.string().trim().required('Service name is required'),
    cost: yup
      .number()
      .moreThan(-1, 'Cost cannot be a negative value')
      .lessThan(100000, 'Cost cannot exceed 1 Lakh')
      .required('Cost is required'),
    service_description: yup.string().trim()
  })

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: {
      service_name: edit ? selectedService?.service_name : '',
      cost: edit ? selectedService?.cost : '',
      service_description: edit ? selectedService?.service_description : ''
    },
    enableReinitialize: true,
    onSubmit: deleteMode ? deleteService : edit ? editService : addService,
    validationSchema: deleteMode ? null : validationSchema
  })
  const isError = (error, touched) => error && touched

  const onPaginationClick = (event, value) => {
    setCurrentPage(value)
  }

  useEffect(() => {
    getClinicServices({ current_page: currentPage })
  }, [currentPage])

  useEffect(() => {
    setPageCount(totalPagesCount)
  }, [totalPagesCount])

  return (
    <>
      <Dialog
        style={{ margin: '0px auto' }}
        open={addDialog}
        classes={{ paper: classes.modalView }}
        onClose={() => setAddDialog(false)}
        aria-labelledby="form-dialog-title">
        <DialogTitle
          id="form-dialog-title"
          className={classes.ModalHead}
          style={{ padding: '10px 24px', marginBottom: '20px' }}>
          {deleteMode ? (
            <span>Delete Service</span>
          ) : edit ? (
            'Edit Service'
          ) : (
            'Add Service'
          )}
        </DialogTitle>
        <DialogContent>
          {deleteMode ? (
            'Do you want to delete the service : ' +
            selectedService?.service_name
          ) : (
            <Box className={classes.ModalForm}>
              <form noValidate={true}>
                <Box className={classes.ModalForm}>
                  <Box className={classes.InputCol}>
                    <TextField
                      multiline={true}
                      required
                      className={classes.textArea}
                      label="Service Name"
                      name={'service_name'}
                      error={isError(errors.service_name, touched.service_name)}
                      helperText={
                        isError(errors.service_name, touched.service_name)
                          ? errors.service_name
                          : null
                      }
                      onBlur={handleBlur}
                      value={values.service_name}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box className={classes.InputCol}>
                    <TextField
                      required
                      className={classes.textArea}
                      label="Cost"
                      type="number"
                      name={'cost'}
                      error={isError(errors.cost, touched.cost)}
                      helperText={
                        isError(errors.cost, touched.cost) ? errors.cost : null
                      }
                      onBlur={handleBlur}
                      value={values.cost}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box className={classes.InputCol}>
                    <TextField
                      multiline={true}
                      className={classes.textArea}
                      label="Description"
                      name={'service_description'}
                      error={isError(
                        errors.service_description,
                        touched.service_description
                      )}
                      helperText={
                        isError(
                          errors.service_description,
                          touched.service_description
                        )
                          ? errors.service_description
                          : null
                      }
                      onBlur={handleBlur}
                      value={values.service_description}
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
              </form>
            </Box>
          )}
        </DialogContent>
        <DialogActions style={{ padding: '20px 20px' }}>
          <button
            onClick={() => setAddDialog(false)}
            className={classes.modalSecondaryButton}>
            Cancel
          </button>
          <button onClick={handleSubmit} className={classes.modalPrimaryButton}>
            {deleteMode ? 'Delete' : edit ? 'Save' : 'Add'}
          </button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={onClose}
      />
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={800} style={{ padding: '30px' }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px',
                fontFamily: 'Poppins'
              }}>
              <h3
                style={{
                  color: '#063bc4',
                  marginBottom: '0px',
                  fontWeight: '700',
                  fontFamily: 'Poppins'
                }}>
                Services
              </h3>
              <Button
                variant="contained"
                size="small"
                onClick={addNewService}
                color="primary"
                style={{
                  backgroundColor: '#063bc4',
                  height: '40px',
                  borderRadius: '40px',
                  padding: '0 20px',
                  fontFamily: 'Poppins'
                }}>
                <span style={{ fontSize: '20px', marginRight: '8px' }}>+</span>{' '}
                Add Service
              </Button>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: '#680fad',
                      fontWeight: '600',
                      fontFamily: 'Poppins'
                    }}>
                    Service
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#680fad',
                      fontWeight: '600',
                      fontFamily: 'Poppins'
                    }}>
                    Price (<i class="fa fa-inr"></i>)
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#680fad',
                      fontWeight: '600',
                      fontFamily: 'Poppins'
                    }}>
                    Created On
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#680fad',
                      fontWeight: '600',
                      fontFamily: 'Poppins'
                    }}>
                    Action
                  </TableCell>
                  <TableCell
                    style={{
                      color: '#680fad',
                      fontWeight: '600',
                      fontFamily: 'Poppins'
                    }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servicesList.map(services => (
                  <TableRow hover>
                    <TableCell>{services.service_name}</TableCell>
                    <TableCell>{services.cost}</TableCell>
                    <TableCell>
                      {moment(services.created_on).format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell>
                      <EditIcon onClick={() => handleEdit(services)} />
                      <HighlightOffIcon
                        onClick={() => handleDelete(services)}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={services.status == '2' ? true : false}
                        onChange={() => handleStatusChange(services)}
                        color="#111d4e"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginBottom: '30px' }}>
          <Pagination
            count={pageCount}
            shape="rounded"
            onChange={onPaginationClick}
          />
        </Box>
      </Card>
    </>
  )
}

ServiceList.propTypes = {
  className: PropTypes.string
}

export default ServiceList
