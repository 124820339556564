import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import PublicNavbar from '../components/CommonComponents/PublicNavbar'
import { SUPPORTED_COUNTRIES } from '../Constants'
import { Layout } from '../patientLayouts'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import HomeBanner from '../images/home_banner.png'
import HomeBanner2 from '../images/home_banner2.png'
import HomeBanner3 from '../images/home_banner3.png'
import blog from '../images/blog.jpg'
import blog2 from '../images/blog2.jpg'
import blog3 from '../images/blog3.jpg'
import homebg1 from '../images/homebg1.png'
import homebg2 from '../images/homebg2.png'
import homebg3 from '../images/homebg3.png'
import contactBg from '../images/contactBg.png'
import why1 from '../images/why1.png'
import why2 from '../images/why2.png'
import why3 from '../images/why3.png'
import why4 from '../images/why4.png'
import Dashboard from '../images/dashboard.png'
import Tele from '../images/c24.png'
import Scheduler from '../images/scheduler.png'
import Profile from '../images/profile.png'
import drpic1 from '../images/drpic1.png'
import drpic2 from '../images/drpic2.png'
import drpic3 from '../images/drpic3.png'
import { withRouter } from 'react-router'
import UserBrowser from '../BrowserSupport'
import Slider from 'infinite-react-carousel'
import Link from '@material-ui/core/Link'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Portal from '@material-ui/core/Portal'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  }
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

const Onboarding = () => {
  const classes = useStyles()
  const { push } = useHistory()

  const [searchValue, setSearchValue] = useState('')
  const [country, setCountry] = useState(SUPPORTED_COUNTRIES[0])

  const searchChangeHandler = event => {
    setSearchValue(event.target.value)
  }

  const countryChangeHandler = event => {
    const country = SUPPORTED_COUNTRIES.find(
      ({ value }) => value === event.target.value
    )
    setCountry(country)
  }

  const settings = {
    arrows: false,
    arrowsBlock: false,
    autoplay: true,
    dots: true,
    duration: 100
  }

  const onSearch = () => {
    push(`/${searchValue}`)
  }

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      push(`/${searchValue}`)
    }
  }
  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  const responsive3 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const [show, setShow] = React.useState('')
  const [show2, setShow2] = React.useState('')
  const [show3, setShow3] = React.useState('')
  const container = React.useRef(null)

  const handleClick = React.useCallback(
    (e, id) => {
      if (id == 1) {
        if (e.target.textContent == 'View Less') {
          setShow('')
        } else {
          setShow(id)
          setShow2('')
          setShow3('')
        }
      } else if (id == 2) {
        if (e.target.textContent == 'View Less') {
          setShow2('')
        } else {
          setShow('')
          setShow2(id)
          setShow3('')
        }
      } else if (id == 3) {
        if (e.target.textContent == 'View Less') {
          setShow3('')
        } else {
          setShow('')
          setShow2('')
          setShow3(id)
        }
      }
    },
    [show]
  )

  return (
    <Layout hideNavbar>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box>
            <PublicNavbar />
          </Box>
        </Grid>

        <Grid item md={12} className={classes.contact}>
          <h2
            style={{ textAlign: 'center', color: '#fff', paddingTop: '40px' }}
            id="contactus">
            Start Your FREE Trial Today !
          </h2>
          <p style={{ textAlign: 'center', color: '#fff' }}>
            Our onboarding team will verify your details and you will receive
            your account details within next 48hrs.
          </p>
          <iframe
            frameborder="0"
            style={{
              height: '700px',
              width: '100%',
              border: 'none',
              padding: '20px'
            }}
            src="https://forms.zohopublic.in/carenowhealthcare/form/DocOnboarding/formperma/dG-MZ237-fNCPk_92_04EnI8zZXgM741JO5H9j4oj9I"></iframe>
        </Grid>

        <Grid item md={4}>
          <Box
            m={{ xs: 2, sm: 3, md: 8 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark"
            style={{ marginTop: '30px' }}>
            <img
              src={why1}
              alt="dashboard img"
              style={{
                width: '100%',
                borderRadius: '20px',
                marginBottom: '10px'
              }}
            />
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px' }}>
              No Apps To Download
            </Typography>
            <p>
              No additional download, open link and do consultations through
              your mobile or web browser.
            </p>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box
            m={{ xs: 2, sm: 3, md: 8 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark"
            style={{ marginTop: '30px' }}>
            <img
              src={why2}
              alt="dashboard img"
              style={{
                width: '100%',
                borderRadius: '20px',
                marginBottom: '10px'
              }}
            />
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px' }}>
              Easy To Use
            </Typography>
            <p>
              No need to be tech savvy. Reduce time spent documenting care for
              telehealth & in-office visits.
            </p>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box
            m={{ xs: 2, sm: 3, md: 8 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark"
            style={{ marginTop: '30px' }}>
            <img
              src={why3}
              alt="dashboard img"
              style={{
                width: '100%',
                borderRadius: '20px',
                marginBottom: '10px'
              }}
            />
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px' }}>
              24/7 Virtual Assistance
            </Typography>
            <p>
              Get 24x7 virtual medical assistance to guide you with your
              consultantions and appointment bookings.
            </p>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%'
  },
  count: {
    color: '#fff',
    '@media (max-width:780px)': {
      fontSize: '1.5rem'
    },
    '& p': {
      fontSize: '24px',
      marginTop: '0',
      '@media (max-width:780px)': {
        fontSize: '18px'
      }
    }
  },
  Subheading: {
    fontSize: '24px',
    color: '#11224b',
    textAlign: 'center',
    marginBottom: '35px',
    '@media (max-width:780px)': {
      fontSize: '18px',
      marginBottom: '15px'
    },
    '& span': {
      fontSize: '34px',
      fontWeight: 'bold',
      '@media (max-width:780px)': {
        fontSize: '24px'
      }
    }
  },
  featureheading: {
    '@media (max-width:780px)': {
      textAlign: 'center',
      marginTop: '40px'
    }
  },
  annotation: {
    color: '#111d4e !important',
    '@media (max-width:780px)': {
      marginBottom: '15px !important'
    }
  },
  countbox: {
    padding: '40px 80px',
    '@media (max-width:780px)': {
      padding: '10px 80px'
    }
  },
  Serviceimg: {
    marginTop: '80px',
    '@media (max-width:780px)': {
      marginTop: '20px'
    }
  },
  contactButton: {
    borderRadius: '40px',
    backgroundImage: 'linear-gradient(to right, #46558d , #212b50)',
    color: '#fff',
    padding: '10px 20px',
    textTransform: 'initial'
  },
  mobileImage: {
    width: '100%',
    objectFit: 'cover',
    height: '30vh'
  },
  annotation: {
    marginBottom: theme.spacing(3),
    '@media (max-width:780px)': {
      fontSize: '20px'
    }
  },
  colorWhiteBg: {
    backgroundColor: '#fff'
  },
  colorDarkBg: {
    backgroundColor: '#111d4e',
    '@media (max-width:780px)': {
      paddingTop: '10px'
    }
  },
  homebg1: {
    background: `url(${homebg1})`,
    backgroundRepeat: 'no-repeat'
  },
  homebg2: {
    background: `url(${homebg2})`,
    backgroundRepeat: 'no-repeat'
  },
  homebg3: {
    background: `url(${homebg3})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'inherit'
  },
  contact: {
    background: `url(${contactBg})`
  }
}))

export default UserBrowser(withRouter(Onboarding))
