import React from 'react'
import Chart from 'react-apexcharts'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  donut: {
    minHeight: '330px',
    minWidth: '450px'
  }
}))

const BarChart = () => {
  const classes = useStyles()
  const series = [44, 55, 41, 17, 15]
  const options = {
    chart: {
      type: 'donut'
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10
      }
    },
    grid: {
      padding: {
        bottom: -80
      }
    },
    donut: {
      size: '65%'
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }

  return (
    <Chart
      className={classes.donut}
      options={options}
      series={series}
      type="donut"
      height={550}
    />
  )
}

export default BarChart
