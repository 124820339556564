import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Button,
  colors,
  Box
} from '@material-ui/core'
import RuppeeIcon from '../../../../images/rupee.png'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ClinicStore } from '../../../../stores'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { NETWORK_STATUS } from '../../../../Constants'
import Loader from '../../../components/ClinicLoader'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    boxShadow: '0 1px 8px 0 rgba(0,0,0,0.1)',
    borderRadius: '10px',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '0px !important'
    }
  },
  avatar: {
    backgroundColor: '#9138aa',
    height: 56,
    width: 56
  },
  errMsg: {
    color: 'red'
  },
  disabledButton: {
    background: '#adadad !important'
  },
  normalButton: {
    background: '#111d4e !important'
  }
}))

const Request = ({ className, wallet_balance, getWalletDetails, ...rest }) => {
  const classes = useStyles()
  const [amount, setAmount] = React.useState(0)
  const [confirmAlert, setConfirmAlert] = React.useState(false)
  const [requestAmount, setReqestAmount] = React.useState(null)
  const {
    clinicWalletRequestAdd,
    resetRequestAdddResponse
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))
  const { walletAdd, networkStatus } = ClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))
  const [snackBar, setSnackbar] = React.useState(null)

  const requestPayout = () => {
    setReqestAmount(String(formik.values.amount))
    setConfirmAlert(true)
  }

  const confirmRequest = () => {
    clinicWalletRequestAdd(requestAmount)
    setConfirmAlert(false)
  }

  const handleClose = () => {
    setConfirmAlert(false)
  }

  React.useEffect(() => {
    if (walletAdd) {
      if (walletAdd.statusCode == 200) {
        setSnackbar({
          open: true,
          message: walletAdd.message.substring(1, walletAdd.message.length - 1),
          alertType: 'success'
        })
        formik.setFieldValue('amount', 100)

        resetRequestAdddResponse()
        getWalletDetails()
      } else {
        setSnackbar({
          open: true,
          message: walletAdd.message,
          alertType: 'error'
        })
      }
    }
  }, walletAdd)

  var validationSchema = yup.object().shape({
    amount: yup
      .number()
      .positive('Cannot be a negative amount')
      .typeError('Only digits are allowed')
      .min(Number(wallet_balance), 'Your request exceeds your wallet balance')
      .min(100, 'Minimum payout amount is 100')
  })

  const formik = useFormik({
    initialValues: {
      amount: ''
    },
    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: false,
    validationSchema,
    onSubmit: requestPayout
  })

  // React.useEffect(() => {
  //   console.log(formik)
  //   return () => {}
  // }, [formik])

  const onClose = () => {
    setSnackbar(null)
  }

  const requestPayoutChangeHandler = event => {
    const value = event.currentTarget.value
    if (value.startsWith(0)) {
      event.currentTarget.value = value.substring(1)
      return
    }
    formik.handleChange(event)
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      {snackBar && (
        <SnackbarAlert
          isOpen={snackBar?.open}
          message={snackBar?.message}
          alertType={snackBar?.alertType}
          multibutton={false}
          primaryClick={onClose}
        />
      )}
      <Dialog
        open={confirmAlert}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">Confirm Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you like to open a payout request for ₹.{requestAmount}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRequest} color="primary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <CardContent style={{ overflow: 'hidden' }}>
        <Grid container justify="center" spacing={3}>
          <Grid item>
            <h4 style={{ fontWeight: '500', marginTop: '20px' }}>
              Total amount to be withdrawn{' '}
              <span
                style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  marginLeft: '15px'
                }}>
                <i class="fa fa-inr"></i> {wallet_balance}
              </span>
            </h4>
            <Box style={{ display: 'flex' }}>
              <Box>
                <TextField
                  id="outlined-basic"
                  name="amount"
                  label="Request Payout"
                  style={{ width: '100%' }}
                  value={formik?.values?.amount}
                  onChange={requestPayoutChangeHandler}
                  placeholder="0"
                  onBlur={formik.handleBlur}
                />
                <div className={classes.errMsg}>
                  {formik.touched.amount && formik.errors.amount
                    ? formik.errors.amount
                    : null}
                </div>
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: '40px',
                    fontStyle: 'italic',
                    marginTop: '5px',
                    fontWeight: '400'
                  }}>
                  {'Minimum request amount should be ₹ 100 '}
                </p>
              </Box>
              <Button
                style={{
                  backgroundColor: '#111d4e',
                  padding: '0 20px',
                  borderRadius: '50px',
                  color: '#fff',
                  marginTop: '15px',
                  fontSize: '12px',
                  height: '40px',
                  marginLeft: '15px'
                }}
                className={
                  formik.values.amount > Number(wallet_balance) ||
                  Number(formik.values.amount) <= 0
                    ? classes.disabledButton
                    : classes.normalButton
                }
                disabled={
                  formik.values.amount > Number(wallet_balance) ||
                  Number(formik.values.amount) <= 0
                }
                onClick={formik.handleSubmit}>
                Submit Request
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

Request.propTypes = {
  className: PropTypes.string
}

export default Request
