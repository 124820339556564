import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Box, Link, Button } from '@material-ui/core'
import { BsPencil } from 'react-icons/bs'
import { AiOutlineDelete } from 'react-icons/ai'
import { PatientStore } from '../../stores'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'

const TableAction = props => {
  const classes = useStyles()
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [selectedDoc, setSelectedDoc] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [documentName, setDocumentName] = useState('Document')

  const {
    editPatientDocument,
    deletePatientDocument
  } = PatientStore.useStoreActions(actions => ({
    ...actions.patients
  }))

  const openConfirmation = document => {
    let docName = document?.testname ? document?.testname : 'Document'
    // console.log(document)
    setDocumentName(docName)
    setSelectedDoc(document.document_id)
    setOpen(true)
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  const validationCheck = e => {
    deletePatientDocument({ selectedDoc })
  }

  const handleAlertClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />

      <Dialog
        open={open}
        onClose={handleAlertClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">Delete Document</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Do you want to delete : ${documentName} ?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAlertClose} color="primary">
            Cancel
          </Button>
          <Button onClick={validationCheck} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.Contentcol}>
        <Link>
          <BsPencil
            onClick={() => props.callbackModal(true, props.document)}
            className={classes.Editicon}
          />
        </Link>{' '}
        <Link>
          <AiOutlineDelete
            onClick={() => openConfirmation(props.document)}
            className={classes.Deleteicon}
          />
        </Link>
      </Box>
    </div>
  )
}
const useStyles = makeStyles(() => ({
  Contentcol: {
    display: 'flex'
  },
  dashboardRoot: {
    margin: '12px 0',
    width: '100%',
    justifyContent: 'flex-start',
    '@media (max-width:991px)': {
      margin: '0px 0 10px',
      paddingRight: '15px'
    },
    '@media (max-width:550px)': {
      margin: '10px 0 10px',
      paddingRight: '15px'
    }
  },
  Editicon: {
    fontSize: '20px',
    color: '#3f4b79',
    marginRight: '15px',
    cursor: 'pointer'
  },
  Deleteicon: {
    fontSize: '20px',
    color: '#ff0000',
    cursor: 'pointer'
  }
}))
export default TableAction;