import React, { useEffect, useRef } from 'react'
import {
  Container,
  Grid,
  Box,
  Divider,
  TextField,
  Button,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import moment from 'moment'
import Swal from 'sweetalert2'
import axios from 'axios'
import fileDownload from 'js-file-download'
import Page from '../../../components/Page'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { ClinicStore } from '../../../../stores'
import AppointmentList from './AppointmentList'
import { NETWORK_STATUS } from '../../../../Constants'
import Loader from '../../../components/ClinicLoader'
import DatePicker from './DatePicker'

const AppointmentsContent = () => {
  const classes = useStyles()

  // const [doctor, setDoctor] = React.useState('null')
  const [doctor, setDoctor] = React.useState('all-doctors')
  const [docAssociationId, setDoctorAssociationId] = React.useState(null)
  const [apptType, setAppointment] = React.useState('upcoming')
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date())
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date())
  const [searchString, setSearchString] = React.useState('')
  const [pattern, setPattern] = React.useState('')

  const [
    serviceReportButtonLoadState,
    setServiceReportButtonLoadState
  ] = React.useState(false)

  const searchRef = useRef(null)

  const { getDoctorList } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))
  const { doctorList, networkStatus } = ClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))
  const { getReportDownloadLink } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  const handleChange = event => {
    let type = event.target.value
    // doctorList.map(item => {
    //   if (item.id === docId) {
    //     setDoctorAssociationId(item.clinic_doctor_association)
    //   }
    // })
    setAppointment(type)
  }
  const handleDoctorChange = event => {
    setDoctor(event.target.value)
  }
  const handleStartDateChange = date => {
    setSelectedStartDate(date)
  }
  const handleEndDateChange = date => {
    setSelectedEndDate(date)
  }

  useEffect(() => {
    if (apptType === 'past') {
      setSelectedStartDate(moment().startOf('month'))
    } else {
      setSelectedStartDate(new Date())
    }
  }, [apptType])

  useEffect(() => {
    getDoctorList({ filterInactive: true })
  }, [])

  useEffect(() => {
    if (doctorList.length !== 0) {
      // setDoctor(doctorList[0].id)
      setDoctorAssociationId(null)
      setDoctor('all-doctors')
    }
  }, [doctorList])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchString === searchRef.current.value) {
        setPattern(searchString)
      }
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [searchString, searchRef, setPattern])

  const downloadReportClicked = async () => {
    try {
      setServiceReportButtonLoadState(true)
      const responseData = await getReportDownloadLink({
        start_date: moment(selectedStartDate).format('YYYY-MM-DD'),
        end_date: moment(selectedEndDate).format('YYYY-MM-DD')
      })

      if (responseData.statusCode !== 200) {
        Swal.fire(
          responseData.message,
          'Change the dates and try again!',
          'info'
        )
        setServiceReportButtonLoadState(false)
        return
      } else {
        const { report_url: fileSource } = responseData
        const { data } = await axios.get(fileSource, { responseType: 'blob' })
        fileDownload(
          data,
          `Report_${moment().format('DD_MM_YYYY')}.${fileSource
            .split('.')
            .pop()}`
        )
      }
      setServiceReportButtonLoadState(false)
    } catch (error) {
      setServiceReportButtonLoadState(false)
      Swal.fire(
        'Sorry!',
        'Oops! Something went wrong. Try again later!',
        'error'
      )
    }
  }

  return (
    <>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Page className={classes.root} title="Appointments">
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Box className="card-box" style={{ padding: '0px' }}>
                <h3
                  style={{
                    color: '#111d4e',
                    fontWeight: '600',
                    padding: '15px 20px',
                    borderBottom: '1px rgba(0,0,0,0.1) solid',
                    paddingBottom: '10px'
                  }}>
                  Appointments
                </h3>
                <Box style={{ padding: '0 20px 0' }}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} sm={4} xs={12}>
                      <TextField
                        style={{ width: '100%' }}
                        id="outlined-search"
                        label="Search"
                        type="search"
                        value={searchString}
                        onChange={e => setSearchString(e.target.value)}
                        inputRef={searchRef}
                      />
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">
                          Appointment Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select"
                          value={apptType}
                          onChange={handleChange}
                          MenuProps={{
                            style: { top: '50px' }
                          }}
                          label="Appointment Type">
                          <MenuItem value={'upcoming'}>
                            Upcoming Appointments
                          </MenuItem>
                          <MenuItem value={'past'}>Past Appointments</MenuItem>
                          <MenuItem value={'rescheduled'}>
                            Rescheduled Appointments
                          </MenuItem>
                          <MenuItem value={'followup'}>
                            Followup Appointments
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Select Doctor
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={doctor}
                          disabled={doctorList.length == 0}
                          onChange={handleDoctorChange}
                          MenuProps={{
                            style: { top: '50px' }
                          }}
                          label="Select Doctor">
                          {doctorList.length === 0 && (
                            <MenuItem value="all-doctors">
                              No Doctors Found
                            </MenuItem>
                          )}
                          {doctorList.length !== 0 && (
                            <MenuItem value="all-doctors">All Doctors</MenuItem>
                          )}
                          {doctorList.map(item => {
                            return (
                              <MenuItem value={item.id}>
                                {item.doctor_name}
                              </MenuItem>
                            )
                          })}
                          {/* {doctorList.length == 0 ? (
                    <MenuItem value={'null'}>No Doctor Found</MenuItem>
                  ) : null} */}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={9}>
                      <DatePicker
                        style={{ width: '50%' }}
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        onStartDateChange={handleStartDateChange}
                        onEndDateChange={handleEndDateChange}
                        showPastAppointmentsDate={apptType === 'past'}
                        showUpcomingAppointmentsDate={apptType === 'upcoming'}
                      />
                    </Grid>
                    <Grid item lg={2} sm={2} xs={3}>
                      <Button
                        style={{
                          color: '#fff',
                          background: '#9138aa',
                          fontSize: '14px',
                          textTransform: 'capitalize',
                          padding: '0 25px',
                          marginTop: '22px',
                          borderRadius: '50px',
                          height: '40px'
                        }}
                        variant="contained"
                        disabled={serviceReportButtonLoadState}
                        onClick={downloadReportClicked}>
                        {serviceReportButtonLoadState ? (
                          <CircularProgress />
                        ) : (
                          'Service Report'
                        )}
                      </Button>
                    </Grid>
                    <div className={classes.seachContainer}></div>
                    <Divider variant="middle" />
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <AppointmentList
                docAssociationId={docAssociationId}
                doctorId={doctor}
                apptType={apptType}
                startDate={moment(selectedStartDate).format('YYYY-MM-DD')}
                endDate={moment(selectedEndDate).format('YYYY-MM-DD')}
                searchString={pattern}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  )
}

export default AppointmentsContent

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
    boxShadow: '0 1px 7px 0 rgba(0,0,0,0.1)',
    borderRadius: '10px'
  },
  seachContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'minmax(20rem, 1fr) repeat(2, max-content)',
    alignItems: 'center',
    gridColumnGap: '0.7rem',
    columnGap: '0.7rem'
  }
}))
