import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { ClinicStore } from '../../../../stores'
import { NETWORK_STATUS } from '../../../../Constants'
import Loader from '../../../components/ClinicLoader'
import NoRecordImg from '../../../../images/noRecordFound.png'

const useStyles = makeStyles(() => ({
  root: {
    width:'100%',
    boxShadow:'0 1px 10px 0 rgba(0,0,0,0.1)',
    borderRadius:'10px',
    '& .MuiOutlinedInput-input': {
      width: '20rem !important'
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px',
    marginTop: '70px'
  },
  Datatable: {
    '& .MuiTable-root': {
      '@media (max-width:767px)': {
        width: '700px !important',
        overflow: 'scroll !important',
        minWidth: '700px !important'
      }
    }
  },
}))

const LatestOrders = ({ className, ...rest }) => {
  const classes = useStyles()
  const [doctor, setDoctor] = React.useState('')

  const handleDoctorChange = event => {
    setDoctor(event.target.value)
  }

  useEffect(() => {
    getDoctorList({ filterInactive: true })
  }, [])

  const { getDoctorList } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))
  const {
    doctorList,
    networkStatus: doctorNetworkStatus
  } = ClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))

  const { upcomingdata, networkStatus } = ClinicStore.useStoreState(state => ({
    ...state.appointment
  }))
  const { getList } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  const viewAllAppointments = () => {
    window.location.href = '/clinic/appointments'
  }

  useEffect(() => {
    if (doctorList.length !== 0) {
      setDoctor(doctorList[0].id)
    }
  }, [doctorList])

  useEffect(() => {
    if (doctor) {
      getList({
        doctor_id: doctor,
        list_type: 'upcoming',
        status: '0',
        visit_type: 0,
        appointment_type: '0',
        currentPage: 1,
        searchString: '',
        sorting: {
          appointment_date: 'ASC',
          start_time: 'ASC'
        }
      })
    }
  }, [doctor])

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {networkStatus === NETWORK_STATUS.FETCHING ||
      doctorNetworkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Box className="Topcol" style={{overflowX:'hidden'}}>
        <h3 style={{ fontSize:'1.2rem', fontWeight:'500', marginBottom:'0px'}}>Upcoming Appointments</h3>
        <CardHeader
          style={{ paddingLeft: '0px' }}
          title=""
          action={
            <FormControl
              
              className={classes.formControl}
              style={{ width: '200px'}}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Doctor
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={doctor}
                onChange={handleDoctorChange}
                MenuProps={{
                  style: { top: '50px' , height:"250px"}
                }}
                label="Select Doctor">
                {doctorList.map(item => {
                  return <MenuItem 
                value={item.id}>{item.doctor_name}</MenuItem>
                })}
              </Select>
            </FormControl>
          }
        />
      </Box>
      
      <PerfectScrollbar>
        <Box minWidth={800} className="Datatable" style={{ padding:'0 20px'}}>
        <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{color:'rgba(17,19,78,0.6)',fontFamily: 'Poppins', fontWeight:'600'}}>Appointment ID</TableCell>
                <TableCell style={{color:'rgba(17,19,78,0.6)',fontFamily: 'Poppins', fontWeight:'600'}}>Doctor</TableCell>
                <TableCell style={{color:'rgba(17,19,78,0.6)',fontFamily: 'Poppins', fontWeight:'600'}}>Patient</TableCell>
                <TableCell style={{color:'rgba(17,19,78,0.6)',fontFamily: 'Poppins', fontWeight:'600'}}>Date</TableCell>
                <TableCell style={{color:'rgba(17,19,78,0.6)',fontFamily: 'Poppins', fontWeight:'600'}}>Time</TableCell>
                <TableCell style={{color:'rgba(17,19,78,0.6)',fontFamily: 'Poppins', fontWeight:'600'}}>Appointment type</TableCell>
                <TableCell style={{color:'rgba(17,19,78,0.6)',fontFamily: 'Poppins', fontWeight:'600'}}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {upcomingdata.map(item => (
                <TableRow hover key={item.id}>
                  <TableCell>{item.appointment_number}</TableCell>
                  <TableCell>{item.doctor_name}</TableCell>
                  <TableCell>{item.patient_name}</TableCell>
                  <TableCell>
                    {moment(item.appointment_date).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(item.start_time, 'hh:mm:ss').format('hh:mm A')}
                  </TableCell>
                  <TableCell>
                    {item.appointment_type === '1'
                      ? 'In-Person'
                      : 'Tele-Consult'}
                  </TableCell>
                  <TableCell>
                    <Chip
                      color="primary"
                      label={
                        item.status === '1'
                          ? 'Booked'
                          : item.status === '2'
                          ? 'Confirmed'
                          : item.status === '3'
                          ? 'Checked-In'
                          : item.status === '4'
                          ? 'In-Progress'
                          : item.status === '5'
                          ? 'Cancelled'
                          : item.status === '6'
                          ? 'No-show'
                          : item.status === '7'
                          ? 'Completed'
                          : item.status === '8'
                          ? 'Rescheduled'
                          : 'NA'
                      }
                      size="small"
                      style={{ backgroundColor: '#111d4e' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {upcomingdata.length == 0 ? (
            <img
              src={NoRecordImg}
              className={classes.notFoundImage}
              alt={'Not found!'}
            />
          ) : (
            ''
          )}
        </Box>
      </PerfectScrollbar>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          onClick={viewAllAppointments}
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text">
          View all
        </Button>
      </Box>
    </Card>
  )
}

LatestOrders.propTypes = {
  className: PropTypes.string
}

export default LatestOrders
