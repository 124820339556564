import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Loader from '../CommonComponents/Loader'
import { Box, Paper, Grid, Typography, makeStyles } from '@material-ui/core'
import { PatientStore } from '../../stores'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import PatientMedication from './PatientMedication'
import SnackbarAlert from '../../components/CommonComponents/SnackbarAlert'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const PatientDetailsVitals = props => {
  const params = useParams()
  const disabled = props.completed
  const classes = useStyles()
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  const {
    addMedicationsResponse,
    editMedicationsResponse,
    deleteMedicationsResponse,
    addLabDataResponse,
    editLabDataResponse,
    deleteLabDataResponse
  } = PatientStore.useStoreState(state => ({
    ...state.patients
  }))

  useEffect(() => {
    if (addMedicationsResponse === undefined) {
      //
    } else if (addMedicationsResponse.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Medication Data added successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Medication Data not added successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [addMedicationsResponse])

  useEffect(() => {
    if (editMedicationsResponse === undefined) {
      //
    } else if (editMedicationsResponse.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Medication Data updated successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Medication Data not updated successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [editMedicationsResponse])

  useEffect(() => {
    if (deleteMedicationsResponse === undefined) {
      //
    } else if (deleteMedicationsResponse.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Drug Name deleted successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Error while deleting the drug from record!')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [deleteMedicationsResponse])

  useEffect(() => {
    if (addLabDataResponse === undefined) {
      //
    } else if (addLabDataResponse.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Lab Data added successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Lab Data not added successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [addLabDataResponse])

  useEffect(() => {
    if (editLabDataResponse === undefined) {
      //
    } else if (editLabDataResponse.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Lab Data updated successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Lab Data not updated successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [editLabDataResponse])

  useEffect(() => {
    if (deleteLabDataResponse === undefined) {
      //
    } else if (deleteLabDataResponse.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Lab Data deleted successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Error while deleting the lab record')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [deleteLabDataResponse])

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <div>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        // secondaryClick={validationCheck}
      />
      <Link to="/patient/selectPatient" className={classes.navLink}>
        <button
          className={classes.Backbtn}
          style={{ marginTop: 30, marginLeft: 15 }}>
          <IoIosArrowBack className={classes.Backicon} />
          Select Patient Account
        </button>
      </Link>
      <Loader />
      <Paper elevation={3} className={classes.paper}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ paddingTop: '15px', overflowY: 'auto' }}>
          <h2
            style={{
              fontSize: '20px',
              color: '#000',
              marginTop: '0px',
              fontWeight: '500',
              marginBottom: 20
            }}>
            My Medications
          </h2>
          <Box
            style={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: 20,
              padding: '20px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
              overflowX: 'auto'
            }}>
            <PatientMedication
              TabPanel={TabPanel}
              classes={classes}
              disabled={disabled}
            />
          </Box>
        </Grid>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '15px',
    float: 'left',
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: '0px 0px 0px #000',
    padding: '15px 30px 20px',
    '& h2': {
      margin: '0px',
      fontSize: '24px'
    }
  },
  Backbtn: {
    marginRight: '20px',
    background: 'none',
    border: '0px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    cursor: 'pointer',
    marginBottom: '0',
    '@media (max-width:550px)': {
      marginBottom: '0px'
    }
  },
  Backicon: {
    marginRight: '5px'
  },
  patientVital: {
    fontWeight: '500',
    fontSize: 26
  },
  vitalName: {
    fontWeight: '400'
  },
  MedicationCol: {
    '& h3': {
      color: '#3f4b79',
      marginBottom: '0px'
    }
  },
  InfoRow: {
    marginBottom: '20px'
  },
  TableRow: {
    background: '#fff !important',
    borderBottom: '0px !important'
  },
  // TableRow2: {
  //   borderBottom: '0px !important',
  //   padding: '2px 0 !important'
  // },
  BtnRow: {
    display: 'flex'
  },
  InfoCol: {
    '& p': {
      fontSize: '14px',
      color: '#787878',
      marginBottom: '7px',
      '& span': {
        width: '200px',
        float: 'left'
      }
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: '600',
      color: '#414141',
      marginBottom: '20px'
    }
  },
  InfoColTop: {
    width: '100%',
    marginTop: '50px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    '@media (max-width:767px)': {
      marginTop: '20px'
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: '500',
      color: '#3f4b79',
      flexGrow: '10',
      marginBottom: '0px'
    }
  },
  EditBtn: {
    float: 'left',
    color: '#3f4b79',
    marginRight: '20px',
    '&:hover': {
      textDecoration: 'none',
      color: '#3f4b79'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  DeleteBtn: {
    float: 'left',
    color: '#c10505',
    marginRight: '20px',
    '&:hover': {
      textDecoration: 'none',
      color: '#c10505'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  AddBtn: {
    background: '#3f4b79',
    borderRadius: '50px',
    border: 'none',
    width: '115px',
    height: '30px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  DisabledAddBtn: {
    background: '#ADADAD',
    borderRadius: '50px',
    border: 'none',
    width: '115px',
    height: '30px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  customized: {
    padding: '0px'
  },
  TabContent: {
    '& .MuiTable-root': {
      width: '100%',
      overflowX: 'auto'
    },
    '@media (max-width:767px)': {
      width: '100%',
      overflowX: 'auto'
    }
  },
  table: {
    minWidth: '100%'
  },
  vitals: {
    float: 'left',
    width: '100%',
    fontWeight: 'normal'
  },
  dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },
  dashboard: {
    maxWidth: '75%',
    flexBasis: '75%',
    '@media (max-width:1024px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '35px !important'
    }
  },
  AreaChart: {
    '& svg rect': {
      fill: '#fff'
    }
  },
  ContentCol: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '15px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  PatientTop: {
    backgroundColor: '#f7f6f6',
    margin: '15px 0'
  },
  PatientInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '15px'
  },
  PatientData: {
    paddingRight: '20px',
    '& h2': {
      color: '#3f4b79',
      marginBottom: '15px',
      fontSize: '14px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  VisitedBtn: {
    textAlign: 'center',
    backgroundColor: '#3f4b79',
    borderRadius: '40px',
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#3f4b79',
      boxShadow: 'none'
    }
  },
  PatientInfoRight: {
    display: 'flex',
    flexDirection: 'flex-end',
    alignItems: 'center'
  },
  PatientStatus: {
    marginRight: '80px',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      marginBottom: '20px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  PatientProfile: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    padding: '5px',
    '& img': {
      width: '100%'
    }
  },
  TabBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '& button': {
      backgroundColor: '#f7f6f6',
      marginRight: '15px',
      textTransform: 'capitalize',
      color: '#3f4b79'
    }
  },
  PatientChart: {
    display: 'flex',
    marginBottom: '20px',
    width: '100%',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      width: '100%',
      marginRight: '30px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  GrapTop: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexWrap: 'wrap'
    },
    '& h4': {
      fontSize: '14px',
      color: '#414141',
      '& span': {
        fontSize: '12px',
        color: '#999'
      }
    }
  },
  BtnCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '75%'
  },
  ToggleBtn: {
    textTransform: 'capitalize',
    '& button': {
      textTransform: 'capitalize',
      padding: '3px 15px'
    }
  },
  ActiveBtn: {
    backgroundColor: '#eff7ff !important',
    borderColor: '#3d8ff7 !important',
    color: '#3d8ff7'
  },
  ChartBox: {
    backgroundColor: '#fff',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    borderRadius: '5px',
    marginBottom: '15px'
  },
  submitButton: {
    color: '#fff',
    borderRadius: '25px'
  },
  Input: {
    width: '100%'
  },
  Input1: {
    margin: theme.spacing(1),
    width: '70%'
  },

  InputCol: {
    width: '100%',
    display: 'flex',
    marginBottom: '0',
    justifyContent: 'center',
    '& p': {
      fontSize: '18px',
      lineHeight: '55px',
      // lineHeight: '40px',
      marginRight: '10px',
      width: '90px'
    }
  },
  ModalForm: {
    width: '100%',
    padding: '40px;'
  },
  ModalBox: {
    width: '500px',
    background: '#fff',
    padding: '0px',
    height: '600px',
    '& h2': {
      textAlign: 'center',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  DownloadBtn: {
    width: '120px',
    background: '#53caf1',
    height: '35px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '35px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '50px',
    border: 'none',
    cursor: 'pointer'
  },
  button: {
    height: 40,
    width: 40,
    padding: '8px',
    background: '#3f4b79',
    marginLeft: 'auto',
    alignSelf: 'right',
    float: 'right',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: '#3f4b79'
    }
  },
  vitalsCard: {
    marginTop: '10px',
    height: '60px',
    padding: '10px 15px',
    '& h5': {
      float: 'left'
    }
  },
  disabledIcon: {
    height: 40,
    width: 40,
    padding: '8px',
    background: '#ADADAD',
    marginLeft: 'auto',
    alignSelf: 'right',
    float: 'right',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: '#ADADAD'
    }
  }
}))

export default PatientDetailsVitals
