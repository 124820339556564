import React from 'react'
import { makeStyles } from '@material-ui/core'
import { IoIosMailUnread, IoIosCash, IoMdToday } from 'react-icons/io'
import { Link } from 'react-router-dom'
import CardBgImg from '../../images/right-top-img4.png'

const QuickLinks = ({ linkType }) => {
  const classes = useStyles()

  return (
    <>
      {linkType === 1 ? (
        <Link to="/doctor/account">
          <div className={classes.checkedInPatients}>
          <span className={classes.TopcardIcon}>
              <IoIosCash />
            </span>
            <p>My Accounts</p>
          </div>
        </Link>
      ) : linkType === 2 ? (
        <Link to="/doctor/communication">
          <div
            // style={{ backgroundImage: `url(${CardBgImg})` }}
            className={classes.checkedInPatients}>
            <span className={classes.TopcardIcon}>
              <IoIosMailUnread />
            </span>
            <p>Communication Center</p>
          </div>
        </Link>
      ) : (
        <Link to="/doctor/profileCard">
          <div
            // style={{ backgroundImage: `url(${CardBgImg})` }}
            className={classes.checkedInPatients}>
            <span className={classes.TopcardIcon}>
              <IoMdToday />
              </span>
            <p>Profile Card</p>
          </div>
        </Link>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  todayAppointments: {
    background: 'none center top no-repeat',
    width: '100%',
    padding: '20px',
    backgroundSize: 'cover',
    height: '160px',
    marginTop: '0px',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    '& h4': {
      fontSize: '18px',
      fontWeight: '200',
      textAlign: 'center',
      color: '#fff',
      marginTop: '15px',
      marginBottom: '10px'
    },
    '& p': {
      fontSize: '20px',
      color: '#fff',
      marginTop: '0px',
      textAlign: 'center'
    },
    '& h2': {
      fontSize: '20px',
      color: '#fff',
      marginTop: '10px',
      fontWeight: '200',
      marginBottom: '15px',
      textAlign: 'center'
    }
  },
  Online: {
    width: '50%',
    textAlign: 'center',
    '& h3': {
      fontSize: '30px',
      color: '#fff',
      marginTop: '0px',
      fontWeight: '600'
    },
    '& p': {
      fontSize: '18px',
      marginBottom: '0px'
    }
  },
  PatientSummary: {
    width: '100%',
    display: 'flex'
  },
  checkedInPatients: {
    background: '#fff center top no-repeat',
    display:'flex',
    width: '100%',
    padding: '0 20px 0 0',
    borderRadius: '10px',
    backgroundSize: 'cover',
    marginBottom: '15px',
    alignItems:'center',
    overflow:'hidden',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    '@media (max-width:1024px)': {
      marginTop: '0px !important',
      marginBottom: '0px'
    },
    '& p': {
      fontSize: '16px',
      fontWeight:'600',
      color: '#667096',
      margin: '0px auto',
      '@media (max-width:767px)': {
        margin:'0 0 0 20px'
      }
    },
    '& span': {
      fontSize: '30px',
      background:'#101d4e',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      color:'#b35acc',
      marginTop: '0px',
      fontWeight: '600',
      width:'70px',
      height:'70px',
      marginBottom: '0'
    }
  }
}))

export default QuickLinks
