import React from 'react'
import { Box, makeStyles, Button } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import PrimaryButton from '../../components/CommonComponents/PrimaryButton'
import PrimaryInputField from '../../components/CommonComponents/PrimaryInputField'
import { DoctorPublicStore, DoctorStore } from '../../stores'
import {
  NETWORK_STATUS,
  VALIDATE_PHONE,
  USER_TYPE,
  VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE
} from '../../Constants'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import ResendOTPDialogBox from '../CommonComponents/ResendOTPDialog'

import indianFlag from '../../images/flag.png'

const OtpLoginForm = ({
  onSuccess = () => {},
  expire,
  forgotPasswordClicked = () => {},
  setLoader
}) => {
  const history = useHistory()
  const [loadingOn, setLoadingOn] = React.useState(true)
  const [otpField, setOtp] = React.useState(false)
  const [resendRequested, setResend] = React.useState(false)
  const [phone, setPhone] = React.useState('')
  const [otpTime, setOtpTime] = React.useState(null)
  // const [phone, setphone] = useState('')
  const classes = useStyles()

  const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[56789]\d{9}$/

  const { control, handleSubmit, errors, register, setValue } = useForm()

  const { otpLogin, sendOtp, otpSent, postResendOtp } =
    DoctorPublicStore.useStoreActions(actions => ({
      ...actions.auth
    }))
  const setDoctorProfileData = DoctorStore.useStoreActions(
    actions => actions.profile.setData
  )

  const { networkStatus, otpResponse, resendOtp } =
    DoctorPublicStore.useStoreState(state => ({
      ...state.auth
    }))

  const [resendOTPDialog, setResendOTPDialog] = React.useState(false)
  const [resendOTPvia, setResendOTPvia] = React.useState('text')

  React.useEffect(() => {
    if (expire == true) {
      setValue('otp', '')
    }
  }, [expire])

  const timer = React.useCallback(time => {
    let min = Math.floor(time / 60)
    let sec = time % 60

    min = min < 10 ? '0' + min : min
    sec = sec < 10 ? '0' + sec : sec

    setOtpTime(min + ':' + sec)
    time--

    if (time >= 0) {
      setTimeout(() => {
        timer(time)
      }, 1000)
      return
    }

    if (!timer) {
      return
    }

    // Swal.fire({
    //   title: 'Timeout for OTP',
    //   text: 'Please, Enter your credentials again !',
    //   icon: 'info',
    //   timer: 2000
    // })
    setOtpTime('')
  }, [])

  React.useEffect(() => {
    if (otpResponse) {
      if (otpResponse.statusCode === 201 || otpResponse.statusCode === 200) {
        if (!resendOtp) {
          timer(120)
        }
        Swal.fire({
          title: '',
          text: !resendOtp
            ? 'OTP successfully sent'
            : 'OTP resent successfully',
          icon: 'success',
          timer: 3000
        })
        setOtp(true)
      } else {
        Swal.fire({
          title: '',
          text: otpResponse.message,
          icon: 'error',
          timer: 3000
        })
      }
      otpSent(null)
    }
  }, [otpResponse, resendOtp, timer, otpSent])

  const otpSubmit = formData => {
    setLoadingOn(false)
    setLoader(true)
    if (formData.otp) {
      otpLogin(formData).then(data => {
        if (data) {
          if (formData.userType === USER_TYPE.DOCTOR.value) {
            setDoctorProfileData(data[0])
          }
          if (onSuccess) {
            onSuccess({ request: formData, response: data })
          }
        } else if (data.statusCode === 421) {
          setValue('otp', '')
          return Swal.fire({
            title: 'Oops! Login failed',
            text: 'User doesnt exist. Please Register!',
            timer: 3000
          })
        } else {
          return Swal.fire(
            'Oops! Login failed',
            'Please enter your credentials correctly',
            ''
          )
        }
      })
    } else {
      sendOtp(formData).then(data => {
        // setOtp(true)
      })
    }
  }

  const onResendNowClicked = () => {
    // // e.preventDefault()
    // setOtp(false)
    // sendOtp({ mobile_no: phone }).then(data => {
    //   // setOtp(true)
    // })
    postResendOtp({
      retry_type: resendOTPvia
    })
  }

  return (
    <div>
      <ResendOTPDialogBox
        isOpen={resendOTPDialog}
        handleClose={() => setResendOTPDialog(false)}
        resendOTPViaValue={resendOTPvia}
        updateResendOTPViaValue={setResendOTPvia}
        onClickResendNow={onResendNowClicked}
      />
      <form onSubmit={handleSubmit(otpSubmit)}>
        <Box mb={4}>
          <div style={{ display: 'grid' }}>
            <div>
              <Controller
                render={props => (
                  // <PrimaryInputField
                  //   placeholder="9876543210"
                  //   onChange={event => {
                  //     // console.log(value)
                  //     props.onChange(event.target.value)
                  //     setPhone(event.target.value)
                  //   }}
                  //   value={props.value}
                  // />
                  <div
                    style={{
                      display: 'grid',
                      alignItems: 'center',
                      gridTemplateRows: 'min-content',
                      gridTemplateColumns: 'repeat(2, min-content) 1fr',
                      gridColumnGap: '0.1rem',
                      columnGap: '0.1rem'
                    }}>
                    <img
                      src={indianFlag}
                      alt=""
                      width="25"
                      height="25"
                      style={{ marginRight: '0.4rem' }}
                    />
                    <p>+91</p>
                    <PrimaryInputField
                      placeholder="9876543210"
                      onChange={({ target: { value } }) => {
                        // console.log(value)
                        props.onChange(value)
                        setPhone(value)
                      }}
                      value={props.value}
                    />
                  </div>
                )}
                // as={PrimaryInputField}
                name="mobile_no"
                defaultValue=""
                control={control}
                rules={{
                  required: true,
                  // pattern: VALIDATE_PHONE,
                  pattern: VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
                  maxLength: 13
                }}
              />
            </div>
            <div className={classes.errorBox}>
              {errors.mobile_no && errors.mobile_no.type === 'required' && (
                <div className={classes.errorText}>
                  Mobile number is required
                </div>
              )}
              {/* {errors.mobile_no &&
                errors.mobile_no.type === 'pattern' &&
                phone.includes('+91') === false && (
                  <Typography color={'error'}>Please check with +91</Typography>
                )} */}
              {errors.mobile_no && errors.mobile_no.type === 'maxLength' && (
                <div className={classes.errorText}>
                  Invalid input. Maximum length exceeded
                </div>
              )}
              {errors.mobile_no && errors.mobile_no.type === 'pattern' && (
                // phone.includes('+91') === true &&
                <div className={classes.errorText}>Invalid mobile number</div>
              )}
            </div>
          </div>
        </Box>

        {!(otpTime === null || otpTime === '') ? (
          <Box mb={4}>
            <div>
              <div>
                <Controller
                  as={PrimaryInputField}
                  name="otp"
                  defaultValue=""
                  control={control}
                  placeholder="OTP"
                  rules={{ required: true }}
                />
              </div>
              {otpTime !== '00:00' ? (
                <div className={classes.errorBox}>
                  {otpTime ? (
                    <div className={classes.errorText}>
                      Time-Left: {otpTime}
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className={classes.errorBox}>
                {errors.otp && errors.otp.type === 'required' && (
                  <div className={classes.errorText}>OTP is required</div>
                )}
              </div>
            </div>
          </Box>
        ) : null}
        {!(otpTime === null || otpTime === '') && (
          <div
            style={{
              fontSize: '80%',
              display: 'grid',
              gridAutoFlow: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              lineHeight: 1,
              margin: '0 0 1rem 0'
            }}>
            <div>Didn't receive OTP?</div>
            <div>
              <Button
                onClick={() => setResendOTPDialog(true)}
                style={{
                  height: '1rem',
                  fontSize: 'inherit',
                  color: '#091c2e',
                  lineHeight: 'inherit',
                  fontFamily: 'inherit'
                }}>
                Resend OTP
              </Button>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PrimaryButton
            rounded
            type="submit"
            stopLoading={loadingOn}
            isLoading={
              otpField ? networkStatus === NETWORK_STATUS.FETCHING : null
            }
            disabled={phone.length < 10}
            style={{ width: '100%', height: 40 }}>
            {otpTime === null || otpTime === '' ? 'Send' : 'Verify'} OTP
          </PrimaryButton>
        </div>
      </form>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  errorBox: {
    paddingTop: '5px',
    '& span': {
      color: 'red',
      fontSize: '14px'
    }
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '80%'
  }
}))

export default OtpLoginForm
