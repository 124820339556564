import React from 'react'
import PatientCard from './PatientCard'

const PatientCards = ({ appointments }) => {
  return (
    <>
      {appointments.map(appointment => {
        const {
          id,
          patient_name,
          photo_uri,
          appointment_date,
          appointment_type,
          first_name,
          last_name,
          status,
          patient_id,
          middle_name,
          start_time
        } = appointment
        return (
          <PatientCard
            key={id}
            id={id}
            patient_id={patient_id}
            name={patient_name}
            first_name={first_name}
            middle_name={middle_name}
            image={photo_uri}
            appointmentDate={appointment_date}
            start_time={start_time}
            appointmentMode={appointment_type}
            checked={status}
            last_name={last_name}
          />
        )
      })}
    </>
  )
}

export default PatientCards
