import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import '../../../../components/SlotBooking/style.css'
import moment from 'moment'
import { ClinicStore } from '../../../../stores'
import {
  makeStyles,
  Box,
  TextField,
  Grid,
  Paper,
  Avatar,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import {
  NETWORK_STATUS,
  VALIDATE_PHONE,
  VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE
} from '../../../../Constants'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import Loader from '../../../components/ClinicLoader'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as yup from 'yup'
import { IoIosArrowForward } from 'react-icons/io'
import { useFormik } from 'formik'
import ClinicBookingPage from './ClinicBookingPage'

import indianFlag from '../../../../images/flag.png'

var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
var days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export default function ScheduleSlots({ doctorId }) {
  let max = moment().add(3, 'month')
  const classes = useStyles()
  const [isShow, setShow] = React.useState(false)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [alertType, setAlertType] = React.useState('error')
  const [multiButton, setmultiButton] = React.useState(false)
  const [patientData, setPatientData] = React.useState(null)
  const [bookingDetailShow, setBookingDetailShow] = React.useState(false)
  const [appointmentDetailShow, setAppointmentDetailShow] =
    React.useState(false)

  const { patientProfileSearch } = ClinicStore.useStoreActions(state => ({
    ...state.appointment
  }))

  const { data, networkStatus } = ClinicStore.useStoreState(state => ({
    ...state.appointment
  }))
  const calendarRef = React.createRef()

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  const validationSchema = yup.object().shape({
    mobile_no: yup
      .string()
      .matches(
        VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
        'Please enter a valid mobile number'
      )
      // .matches(VALIDATE_PHONE, 'Please enter a valid mobile number with +91')
      .max(14, 'You may enter up to 14 digits')
      .required('Mobile number is required')
    // email: yup
    //   .string()
    //   .email('Invalid email')
    //   .max(254, 'You have exceeded the character limit')
    //   .required('Email-Id is required')
  })

  const submitPatientDetails = values => {
    values = {
      ...values,
      mobile_no: !!values.mobile_no ? `+91${values.mobile_no}` : ''
    }
    localStorage.setItem('account-data', JSON.stringify(values))
    patientProfileSearch(values).then(data => {
      setBookingDetailShow(true)
      if (data?.patients_data) {
        if (data.patients_data.length !== 0) {
          setPatientData(data.patients_data[0])
          localStorage.setItem(
            'clinic_booking_details',
            JSON.stringify(data.patients_data[0])
          )
        } else {
          setPatientData(null)
        }
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      mobile_no: ''
      // email: ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: submitPatientDetails
  })

  const bookAppointment = () => {
    setAppointmentDetailShow(true)
  }

  const addPatient = () => {
    setShow(true)
  }

  const handleClose = ({ resetForm }) => {
    formik.resetForm()
    setShow(false)
  }

  return (
    <div className={classes.root}>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Dialog
        open={isShow}
        classes={{ paper: classes.modalView }}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          Add Patient
          {/* <span
            style={styles}
            onMouseEnter={() => setColor('red')}
            onMouseLeave={() => setColor('')}
            onClick={handleClose}>
            X
          </span> */}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions style={{ padding: '20px 40px' }}>
          <Button
            onClick={handleClose}
            className={classes.modalSecondaryButton}>
            Cancel
          </Button>
          <Button
            // onClick={formik.handleSubmit}
            className={classes.modalPrimaryButton}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box>
        <SnackbarAlert
          isOpen={isSnackBarOpen}
          message={snackBarMessage}
          alertType={alertType}
          multibutton={multiButton}
          primaryClick={hideSnackBar}
        />
      </Box>
      <section id="patient-booking">
        <Grid item xs={12} sm={12}>
          <Box className={classes.LoginLeft}>
            {appointmentDetailShow ? (
              <ClinicBookingPage />
            ) : bookingDetailShow ? (
              <>
                <h2>Select Patient Details</h2>
                {patientData?.id ? (
                  <Paper
                    className={classes.patientCard}
                    square
                    style={{ margin: '15px 0' }}>
                    <Grid container className={classes.appointmentPage}>
                      <Grid item xs={10} md={10}>
                        <Grid
                          container
                          spacing={3}
                          style={{ alignItems: 'center' }}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.profilebox + ' ' + classes.item}>
                            <Avatar
                              className={classes.image}
                              alt="Image"
                              src={
                                'https://www.searchpng.com/wp-content/uploads/2019/02/Deafult-Profile-Pitcher.png'
                              }
                            />
                            <h5
                              style={{
                                marginTop: '0',
                                marginBottom: '0',
                                color: '#000',
                                width: '25rem'
                              }}>
                              {patientData.first_name +
                                ' ' +
                                patientData.last_name}
                            </h5>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              // className={classes.profilebox + ' ' + classes.item}
                            >
                              {patientData.email}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              // className={classes.profilebox + ' ' + classes.item}
                            >
                              {patientData.mobile_no}
                            </Grid>
                            {/* <IconButton>
                              <IoIosArrowForward
                                className={classes.icon}
                                size={32}
                                color="#000"
                              />
                            </IconButton> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  'No patient found'
                )}
                {patientData ? (
                  <Box className={classes.ProceedBtn}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={bookAppointment}
                      style={{ backgroundColor: '#111d4e' }}>
                      Book Appointment
                    </Button>
                  </Box>
                ) : (
                  <Box className={classes.ProceedBtn}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={bookAppointment}
                      style={{ backgroundColor: '#111d4e' }}>
                      Proceed with new account
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <>
                <h2>Enter Patient Details</h2>
                <form
                  noValidate={true}
                  onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                  }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Box className={classes.InputRow}>
                        <TextField
                          id="mobile_no"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder=""
                          onChange={formik.handleChange}
                          value={
                            formik && formik.values && formik.values.mobile_no
                          }
                          onBlur={formik.handleBlur}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={indianFlag}
                                  alt=""
                                  width="25"
                                  height="25"
                                  style={{ marginRight: '0.4rem' }}
                                />
                                +91
                              </InputAdornment>
                            )
                          }}
                          name="mobile_no"
                          label="Mobile number"
                          variant="outlined"
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.mobile_no &&
                              formik.errors.mobile_no
                                ? formik.errors.mobile_no
                                : null}
                            </div>
                          }
                        />
                      </Box>
                      {/* <Box className={classes.InputRow}>
                        <TextField
                          id="email"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder=""
                          required
                          onChange={formik.handleChange}
                          value={formik && formik.values && formik.values.email}
                          onBlur={formik.handleBlur}
                          name="email"
                          label="Email"
                          variant="outlined"
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.email && formik.errors.email
                                ? formik.errors.email
                                : null}
                            </div>
                          }
                        />
                      </Box> */}
                      <Box className={classes.ProceedBtn}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          style={{ backgroundColor: '#111d4e' }}>
                          Proceed
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </>
            )}
          </Box>
        </Grid>
      </section>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  TabCol: {
    backgroundColor: '#efefef',
    borderRadius: '5px',
    color: '#414141',
    boxShadow: 'none',
    overflow: 'hidden'
  },
  TabList: {
    '& span': {
      top: '0px',
      height: '0px',
      zIndex: '0'
    },
    '& button': {
      maxWidth: '108px',
      minWidth: '108px',
      padding: '5px 0',
      zIndex: '99',
      position: 'relative'
    }
  },
  TimeSelected: {
    backgroundColor: '#3f4b79',
    color: '#fff',
    borderRadius: '10px',
    boxShadow: 'none',
    marginRight: '10px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#3f4b79'
    }
  },
  TimeButton: {
    backgroundColor: '#efefef',
    color: '#3f4b79',
    borderRadius: '10px',
    boxShadow: 'none',
    marginRight: '10px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#3f4b79',
      color: '#fff'
    }
  },
  TimeSchdule: {
    marginBottom: '30px'
  },
  BookBtn: {
    width: '140px',
    textAlign: 'center',
    backgroundColor: '#3f4b79',
    borderRadius: '40px',
    boxShadow: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#414141',
      boxShadow: 'none'
    }
  },
  TopHeading: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '20px',
    alignItems: 'center',
    fontSize: '18px',
    color: '#979797',
    '& img': {
      marginRight: '10px'
    }
  },
  ToggleBtn: {
    backgroundColor: '#efefef',
    color: '#3f4b79',
    borderRadius: '0px',
    border: 'none',
    '& button': {
      borderRadius: '0px',
      border: 'none',
      textTransform: 'capitalize',
      fontSize: '16px',
      paddingLeft: '20px',
      paddingRight: '20px',
      '@media (max-width:767px)': {
        paddingLeft: '10px',
        paddingRight: '10px',
        fontSize: '14px'
      }
    },
    '& .Mui-selected': {
      color: '#fff',
      background: '#3f4b79',
      '&:hover': {
        backgroundColor: '#3f4b79',
        boxShadow: 'none'
      }
    },
    '& .Mui-selected:nth-child(2)': {
      background: '#9abd0f',
      '&:hover': {
        backgroundColor: '#9abd0f',
        boxShadow: 'none'
      }
    }
  },
  ActiveToggle: {
    backgroundColor: '#3f4b79',
    color: '#fff'
  },
  errMsg: {
    color: 'red'
  },
  modalView: {
    width: '20rem !important'
  },
  InputRow: {
    width: '475px',
    marginBottom: '15px',
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  ProceedBtn: {
    marginTop: '20px'
  },
  patientCard: {
    padding: '7px',
    borderRadius: '5px',
    position: 'relative',
    width: '90%',
    // background: `url(${ListBgHoverImg}) center top no-repeat`,
    backgroundSize: 'cover',
    color: '#202020',
    display: 'flex',
    marginBottom: '15px',
    flexWrap: 'wrap',
    boxShadow: 'none',
    '&:hover p': {
      color: '#202020'
    },
    '&:hover b': {
      color: '#202020 !important'
    },
    '&:hover h5 a': {
      color: '#202020 !important'
    },
    '&:hover p span': {
      color: '#202020'
    },
    '@media (max-width:767px)': {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '15px',
      boxShadow: '0px 0px 9px 0px #00000030'
    },
    '&:hover': {
      //   background: `url(${ListBgHoverImg}) center top no-repeat`,
      backgroundSize: 'cover'
    }
  },
  item: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media (max-width:767px)': {
      display: 'flex'
    }
  },
  profilebox: {
    display: 'flex',
    gridTemplateColumns: '24% 65%',
    '& a': { display: 'block' },
    '@media (max-width:767px)': {
      display: 'flex',
      marginBottom: '20px'
    }
  },
  iconContainer: {
    display: 'grid',
    alignItems: 'center',
    '& b': {
      wordBreak: 'break-all',
      lineHeight: '14px',
      fontSize: '13px'
    },
    gridTemplateColumns: '35px 80%',
    '@media (max-width:767px)': {
      gridTemplateColumns: '35px 90%',
      marginRight: '20px'
    }
  }
}))
