import React, { useState } from 'react'
import { makeStyles, Tooltip, withStyles } from '@material-ui/core'
import { ErrorMessage, Field } from 'formik'

import TextField from '../../../components/TextField'

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

const FieldComponent = ({
  id = '',
  label = '',
  nameUsedByFormik = '',
  render = null,
  renderText = () => null
}) => {
  const classes = useStyles()

  const [editable, setEditable] = useState(false)

  return (
    <React.Fragment>
      <label
        className={[
          classes.fieldLabel,
          editable ? classes.fieldLabelEditable : ''
        ].join(' ')}
        onClick={setEditable.bind(this, true)}
        htmlFor={id}>
        {label}
      </label>
      <Field name={nameUsedByFormik}>
        {({ field, meta: { touched, error } }) => {
          const updatedField = {
            ...field,
            focusNow: true,
            onBlur: e => {
              field.onBlur(e)
              if (!(touched && error)) {
                setEditable(false)
              }
            }
          }

          return editable ? (
            render ? (
              render({
                id,
                nameUsedByFormik,
                field: updatedField,
                touched,
                error
              })
            ) : (
              <TextField
                id={id}
                {...updatedField}
                style={{ width: '100%' }}
                error={touched && error}
                helperText={<ErrorMessage name={nameUsedByFormik} />}
              />
            )
          ) : (
            <LightTooltip
              title={
                renderText(field.value) ? renderText(field.value) : field.value
              }
              placement="top-start">
              <p
                className={classes.staticfieldValue}
                onClick={setEditable.bind(this, true)}>
                {renderText(field.value)
                  ? renderText(field.value)
                  : field.value}
              </p>
            </LightTooltip>
          )
        }}
      </Field>
    </React.Fragment>
  )
}

export default FieldComponent

const useStyles = makeStyles(theme => ({
  fieldLabel: {
    color: '#9fa0a4',
    fontSize: '0.875rem',
    display: 'block',
    fontWeight: 400,
    lineHeight: 1.3,
    verticalAlign: 'top'
  },
  fieldLabelEditable: {
    paddingTop: '0.5rem',
    lineHeight: 1,
    verticalAlign: 'middle'
  },
  staticfieldValue: {
    margin: '0',
    width: '100%',
    lineHeight: 1.3,
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    'overflow-wrap': 'break-word',
    'word-wrap': 'break-word',
    '-webkit-hyphens': 'auto',
    '-ms-hyphens': 'auto',
    hyphens: 'auto'
  }
}))
