import { action, thunk } from 'easy-peasy'
import localforage from 'localforage'

import BaseModel from '../Base'
import {
  NETWORK_STATUS,
  LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME,
  PATIENT_USER_ID
} from '../../Constants'
import api from '../../api'
import Swal from 'sweetalert2'

const updateLocalStorage = data => {
  const { hospital_details, user } = data

  const authn = {
    user,
    hospital_details
  }

  Promise.all([
    localforage.setItem(LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME, authn),
    localforage.setItem('selected_patient', authn.user.self_patient_data)
  ]).then(() => {
    // console.log('details stored')
  })
}

export default {
  ...BaseModel(),
  otpMsg: null,
  otpResponse: false,
  resendOtp: false,
  phoneNo: null,

  login: thunk(async (actions, payload, { dispatch }) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const { email, password } = payload
    // Setting up the password for the payload for update profile details.
    // localStorage.setItem("pass", password);

    const responseAuth = await api.authenticateUser({
      user_name: email,
      password,
      type: 'password',
      user_type: '3',
      url_code: 'craftveda-demo-hospital'
    })

    if (responseAuth.data.statusCode === 200) {
      var data = responseAuth.data
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      localStorage.setItem(PATIENT_USER_ID, data?.user && data?.user?.user_id)
      localStorage.setItem('patient_auth_key', data?.user?.id_token)
      updateLocalStorage(responseAuth.data)
      return responseAuth.data
    } else {
      Swal.fire('', "User doesn't exists", 'error')
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return { statusCode: responseAuth.data.statusCode }
    }
  }),

  otpSent: action((state, payload) => {
    state.otpResponse = payload
  }),

  sendOtp: thunk(async (actions, payload, { dispatch }) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { mobile_no } = payload

    const responseAuth = await api.sendOtp({
      mobile_no: `+91${mobile_no}`,
      channel_id: 'patient_login'
    })

    if (responseAuth?.data) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setResendOTP(false)
      actions.setPhoneNo(`+91${mobile_no}`)
      actions.otpSent(responseAuth?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  postResendOtp: thunk(async (actions, payload, { getState }) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { retry_type = 'text' } = payload

    const { phoneNo } = getState()

    try {
      const { data = {} } = await api.resendOtp({
        mobile_no: phoneNo,
        retry_type
      })

      if (!!data && (data.statusCode === 200 || data.statusCode === 201)) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setResendOTP(true)
        if (payload.channel_id === 'patient_registration') {
          actions.updateResponse(data)
        } else {
          actions.otpSent(data)
        }
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)

        const falseCaseErrorMsg = !data.message
          ? {
              message: 'Oops! Something went wrong. Please try again later...'
            }
          : data.message === 'OTP retry count maxed out'
          ? {
              ...data,
              message: 'Maximum limit to RESEND OTP exceeded'
            }
          : data

        if (payload.channel_id === 'patient_registration') {
          actions.updateResponse(falseCaseErrorMsg)
        } else {
          actions.otpSent(falseCaseErrorMsg)
        }
      }
    } catch (err) {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)

      if (payload.channel_id === 'patient_registration') {
        actions.updateResponse({
          message: 'Oops! Something went wrong. Please try again later...'
        })
      } else {
        actions.otpSent({
          message: 'Oops! Something went wrong. Please try again later...'
        })
      }
    }
  }),

  setResendOTP: action((state, payload) => {
    state.resendOtp = payload
  }),

  otpLogin: thunk(async (actions, payload, { dispatch }) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { mobile_no, otp } = payload
    // let phone_no = "+91" + mobile_no;
    const responseAuth = await api.authenticateUser({
      mobile_no: `+91${mobile_no}`,
      otp: otp,
      user_type: '3',
      type: 'otp'
    })
    if (responseAuth.data.statusCode === 200) {
      let data = responseAuth.data
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      localStorage.setItem(PATIENT_USER_ID, data?.user && data?.user?.user_id)
      localStorage.setItem('patient_auth_key', data?.user?.id_token)
      updateLocalStorage(data)
      return data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return { statusCode: responseAuth.data.statusCode }
    }
  }),

  registerOtp: thunk(async (actions, payload, { dispatch }) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { mobile_no } = payload

    const responseAuth = await api.sendOtp({
      mobile_no: mobile_no,
      channel_id: 'patient_registration'
    })
    if (responseAuth.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setResendOTP(false)
      actions.setPhoneNo(mobile_no)
      actions.updateResponse(responseAuth.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  updateResponse: action((state, payload) => {
    state.otpMsg = payload
  }),

  setPhoneNo: action((state, payload) => {
    state.phoneNo = payload
  })
}
