import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import {
  Box,
  Button,
  Card,
  CardHeader,
  TextField,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  makeStyles,
  InputAdornment
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import SearchIcon from '@material-ui/icons/Search'
import { ClinicStore } from '../../../../stores'
import { NETWORK_STATUS } from '../../../../Constants'
import Loader from '../../../components/ClinicLoader'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import AddDoctor from './AddDoctor'
import { debounce, stubTrue } from 'lodash'
import Loading from 'react-loading'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { green, red } from '@material-ui/core/colors'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText
} from '@material-ui/core'
import Swal from 'sweetalert2'

const useStyles = makeStyles(() => ({
  root: {
    width:'100%',
    boxShadow:'0 1px 10px 0 rgba(0,0,0,0.1)',
    borderRadius:'10px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    // borderRadius: '40px',
    color: '#fff',
    float: 'right',
    position: 'relative',
    marginTop: '-54px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#111d4e',
      color: '#fff'
    }
  },
  formcol: {
    '@media (max-width:767px)': {
      margin: '0px !important'
    }
  },
  loadingSpinner: {
    position: 'absolute',
    top: '42px',
    right: '150px',
    left: '430px',
    '@media (max-width:976px)': {
      right: '5px'
    }
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  content: {
    padding: '65px 14px'
  },
  modalView: {
    width: '25%',
    height: '50%'
  }
}))

const GreenCheckbox = withStyles({
  root: {
    boxShadow:'0 1px 10px 0 rgba(0,0,0,0.1)',
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />)

const RedCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />)

const DoctorList = ({ className, doctors, ...rest }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageCount, setPageCount] = React.useState(1)
  const [page, setPage] = React.useState(0)
  const [searchString, setSearchString] = React.useState('')
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [doctorList, setDoctorList] = React.useState([])
  const [prevState, setPrevState] = React.useState(false)
  const [doctor, setDoctor] = React.useState('null')
  const [isShow, setShow] = React.useState(false)
  const [alertDialog, setAlertDialog] = React.useState(false)

  const {
    networkStatus,
    totalPagesCount,
    billingUpdateStatus
  } = ClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))
  const {
    updateDoctorStatus,
    getDoctorList,
    billingPermission
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))

  const profilePopup = React.useRef(null)

  React.useEffect(() => {
    if (doctors == undefined) {
      //
    } else {
      setDoctorList(doctors ? doctors : [])
    }
  }, [doctors])

  React.useEffect(() => {
    if (billingUpdateStatus == undefined) {
      //
    } else {
      setShow(false)
      setOpen(true)
      setSnackBarMessage(
        billingUpdateStatus.message
          ? billingUpdateStatus.message
          : 'Billing authority updated to another doctor'
      )
      setmultiButton(false)
      setAlertType('success')
      getDoctorList({})
    }
  }, [billingUpdateStatus])

  const handleChange = value => {
    if (value?.status === '2' && value?.billing_permission == 1) {
      Swal.fire(
        'Doctors with enabled billing permission cannot be deactivated!',
        'Please give billing permission to another doctor and try again...',
        'info'
      )
      return
    }

    let status = value?.status === '2' ? '1' : '2'
    updateDoctorStatus({
      doctor_id: value?.id,
      status: status
    }).then(data => {
      if (data.statusCode === 200) {
        setOpen(true)
        setSnackBarMessage('Doctor status updated successfully')
        setmultiButton(false)
        setAlertType('success')
        getDoctorList({})
      } else {
        setOpen(true)
        setSnackBarMessage(
          data?.message
            ? data.message
            : 'There was some problem in updating the doctor status'
        )
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const checkChange = (event, doc) => {
    let checked = event.target.checked
    setPrevState(checked)
    setDoctor(doc.id)
    setAlertDialog(true)
  }

  const handleAlertClose = () => {
    setAlertDialog(false)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onPaginationClick = (event, value) => {
    setCurrentPage(value)
  }

  useEffect(() => {
    getDoctorList({ current_page: currentPage })
  }, [currentPage])

  useEffect(() => {
    setPageCount(totalPagesCount)
  }, [totalPagesCount])

  // Defunct as of now
  // const openStepper = () =>{
  //   profilePopup.current.click()
  // }
  //initial undebounced call
  React.useEffect(() => {
    getDoctorList({
      pattern: searchString,
      table_config_page_no: page + 1,
      table_config_rows_per_page: rowsPerPage
    })
  }, [rowsPerPage, page])

  const debouncedGetDoctorList = React.useCallback(
    debounce(
      searchString =>
        getDoctorList({
          pattern: searchString,
          table_config_page_no: 1,
          table_config_rows_per_page: rowsPerPage
        }),
      1000
    ),
    []
  )

  const onSearchUsed = e => {
    setSearchString(e.target.value)
    debouncedGetDoctorList(e.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const validationCheck = () => {
    setShow(open)
  }

  const handleClose = () => {
    setShow(false)
  }

  const docChange = event => {
    setDoctor(event.target.value)
    billingPermission({ doctor_id: event.target.value })
  }

  const changeBilling = () => {
    if (!prevState) {
      setShow(true)
      handleAlertClose(false)
    } else {
      billingPermission({ doctor_id: doctor })
      handleAlertClose(false)
    }
  }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        {networkStatus === NETWORK_STATUS.FETCHING ? (
          <Loader open={true} />
        ) : (
          <Loader />
        )}
        <SnackbarAlert
          isOpen={open}
          message={snackBarMessage}
          alertType={alertType}
          multibutton={multiButton}
          primaryClick={onClose}
          secondaryClick={validationCheck}
        />
        <Dialog
          open={alertDialog}
          // className={classes.modalView }
          onClose={handleAlertClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
            Change Billing Permissions
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {prevState
                ? 'The previous doctor with the billing permission will now loose the permission'
                : 'Atleast one Doctor should be given the billing permission. Are you sure to de-activate the permsission status'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => handleAlertClose(false)}
              color="primary">
              Cancel
            </Button>
            <Button onClick={changeBilling} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isShow}
          // className={classes.modalView }
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
            Choose a Doctor to update billing permission
          </DialogTitle>
          <DialogContent className={classes.content}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Doctor
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={doctor}
                disabled={doctorList.length == 0}
                onChange={docChange}
                label="Select Doctor">
                {doctorList.map(item => {
                  return <MenuItem value={item.id}>{item.doctor_name}</MenuItem>
                })}
                {doctorList.length == 0 ? (
                  <MenuItem value={'null'}>No Doctor Found</MenuItem>
                ) : null}
              </Select>
            </FormControl>
          </DialogContent>
        </Dialog>
        <h3 style={{marginBottom:'0px', padding:'20px 20px 0', fontWeight:'500'}}>Doctors</h3>
        {/* <CardHeader title="" /> */}
        <Grid item xs={12} md={4}>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <FormGroup
              aria-label="position"
              row
              style={{ width: '100%', position: 'relative' }}>
              <FormControlLabel
                className={classes.formcol}
                style={{ width: '100%' }}
                control={
                  <TextField
                    className={classes.Searchinput}
                    id="standard-basic"
                    label="Search"
                    style={{ margin: '0 0 20px 30px', width: '100%' }}
                    value={searchString}
                    onChange={onSearchUsed}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                }
              />
              {/* <FormControlLabel
              className={classes.searchicon}
              control={<FaSearch />}
            /> */}
              {networkStatus == 'FETCHING' ? (
                <Loading
                  type="spin"
                  textalign="center"
                  color="grey"
                  height={20}
                  width={20}
                  className={classes.loadingSpinner}
                />
              ) : null}
            </FormGroup>
          </FormControl>
        </Grid>
        {/* <Button className={classes.modalPrimaryButton} onClick={openStepper} >Request to add new doctor</Button> */}
        
        <PerfectScrollbar style={{ overflow: 'auto' }}>
          <Box minWidth={800} style={{padding:'0 20px'}}>
          <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 150, color:'rgba(17,29,78,0.7)',fontFamily:'Poppins',fontWeight:'600' }}>Doctor Name</TableCell>
                  <TableCell style={{ minWidth: 100, color:'rgba(17,29,78,0.7)',fontFamily:'Poppins',fontWeight:'600' }}>
                    Specialization
                  </TableCell>
                  <TableCell style={{ minWidth: 80, color:'rgba(17,29,78,0.7)',fontFamily:'Poppins',fontWeight:'600' }}>Degree</TableCell>
                  <TableCell style={{ minWidth: 150, color:'rgba(17,29,78,0.7)',fontFamily:'Poppins',fontWeight:'600' }}>Mobile number</TableCell>
                  <TableCell style={{ minWidth: 180, color:'rgba(17,29,78,0.7)',fontFamily:'Poppins',fontWeight:'600' }}>Email</TableCell>
                  <TableCell style={{ minWidth: 100, color:'rgba(17,29,78,0.7)',fontFamily:'Poppins',fontWeight:'600',lineHeight:'18px' }}>
                    Date of Joining
                  </TableCell>
                  {/* <TableCell style={{ minWidth: 100 }}>
                    Subscription Till
                  </TableCell>
                  <TableCell style={{ minWidth: 100 }}>Plan</TableCell> */}
                  <TableCell style={{color:'rgba(17,29,78,0.7)',fontFamily:'Poppins',fontWeight:'600'}}>Status</TableCell>
                  <TableCell style={{color:'rgba(17,29,78,0.7)', lineHeight:'18px',fontFamily:'Poppins',fontWeight:'600'}}>Billing Permission</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doctorList.map(item => (
                  <TableRow hover>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.doctor_name}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.specialization}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.qualification}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.mobile_no}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.email}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {moment(item.created_at).format('DD-MM-YYYY')}
                    </TableCell>
                    {/* <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.last_validity_date
                        ? moment(item.last_validity_date).format(
                            'DD-MM-YYYY hh:mm A'
                          )
                        : 'No Active Plans'}
                    </TableCell>
                    <TableCell
                      style={{
                        wordBreak: 'break-word',
                        textTransform: 'uppercase'
                      }}>
                      {item.subscription_name ? item.subscription_name : '-'}
                    </TableCell> */}
                    <TableCell>
                      <Switch
                        checked={item.status === '1' ? false : true}
                        onChange={() => handleChange(item)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TableCell>
                    <TableCell>
                      {item.billing_permission == 1 ? (
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={true}
                              onChange={e => checkChange(e, item)}
                              name="billing"
                            />
                          }
                          label="Enabled"
                        />
                      ) : (
                        <FormControlLabel
                          control={
                            <RedCheckbox
                              checked={false}
                              onChange={e => checkChange(e, item)}
                              name="billing"
                            />
                          }
                          label="Disabled"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Box display="flex" justifyContent="flex-end" p={2}>
          {doctorList ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              className={classes.overflow}
              count={doctors !== undefined ? totalPagesCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </Box>
      </Card>
      <AddDoctor ref={profilePopup} />
    </>
  )
}

DoctorList.propTypes = {
  className: PropTypes.string
}

export default DoctorList
