import React from 'react'
import { makeStyles, CssBaseline, Box } from '@material-ui/core'
import Footer from '../components/CommonComponents/Footer'
import PublicNavbar from '../components/CommonComponents/PublicNavbar'
import {
  DoctorPublicStore,
  DoctorStore,
  PublicPatientStore,
  PatientStore
} from '../stores'
import { StylesProvider, ThemeProvider } from '../providers/ThemeProvider'

const Layout = ({
  children,
  hideFooter,
  hideNavbar,
  navBar,
  showLogout = true
}) => {
  const classes = useStyles()
  const [navBars, setnavBar] = React.useState('')

  React.useEffect(() => {
    if (navBar === 'hideNavbar') {
      setnavBar(navBar)
    }
  }, [navBar])

  return (
    <PublicPatientStore.Provider>
      <PatientStore.Provider>
        <DoctorStore.Provider>
          <DoctorPublicStore.Provider>
            <StylesProvider>
              <ThemeProvider>
                <CssBaseline />
                {!hideNavbar && (
                  <Box py={1} px={5}>
                    <PublicNavbar hidebutton={navBars} />
                  </Box>
                )}
                <div className={classes.body}>
                  <div className={classes.content}>{children}</div>
                  {!hideFooter && <Footer />}
                </div>
              </ThemeProvider>
            </StylesProvider>
          </DoctorPublicStore.Provider>
        </DoctorStore.Provider>
      </PatientStore.Provider>
    </PublicPatientStore.Provider>
  )
}

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minHeight: '100vh'
  },
  content: {
    flex: 1
  }
}))

export default Layout
