import React, { useState, useEffect } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  makeStyles,
  withStyles,
  TableRow
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { DOCTOR_USER_ID, NETWORK_STATUS } from '../../Constants'
import Details from '../../components/CommonComponents/Details'
import { DoctorStore } from '../../stores'
import WarningIcon from '@material-ui/icons/Warning'
import NoRecordImg from '../../images/norecord.png'
import Skeleton from '@material-ui/lab/Skeleton'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#fff',
    color: '#3f4b79'
  },
  tbody: {
    color: '#3f4b79',
    '& td': {
      color: '#3f4b79'
    }
  }
}))(TableCell)

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f7f7',
      color: '#777777'
    }
  }
}))(TableRow)
export default function VisitSummaries() {
  const classes = useStyles()
  const params = useParams()
  const [summaries, setSummaries] = useState([])

  const { getVisitSummaries, getSummary, clearSnack } =
    DoctorStore.useStoreActions(actions => ({
      ...actions.patients
    }))

  const { getVisitSummariesData, networkStatus } = DoctorStore.useStoreState(
    state => ({
      ...state.patients
    })
  )

  useEffect(() => {
    getVisitSummaries({
      user_id: localStorage.getItem(DOCTOR_USER_ID), //'2a717c90-44d2-4fb4-b17e-a3ca0b21931f',
      patient_id: params.patient_id, //'eef21d7d-c0e8-4ab0-ad6d-dd9f528af102',
      view_type: 'list', // 'list' or 'view'
      table_config_rows_per_page: '25',
      table_config_page_no: '1'
    })
    return () => {
      clearSnack()
    }
  }, [])

  useEffect(() => {
    if (getVisitSummariesData === undefined) {
      //
    } else {
      setSummaries(getVisitSummariesData)
    }
  }, [getVisitSummariesData])
  return (
    <div>
      <Box className={classes.LabReport}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                className={classes.TableRow2}
                style={{ background: '#F5F5F5' }}>
                <b>Visit Date</b>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.TableRow2}
                style={{ background: '#F5F5F5' }}>
                <b>Appointment with</b>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.TableRow2}
                style={{ background: '#F5F5F5' }}>
                <b>Consult Type</b>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.TableRow2}
                style={{ background: '#F5F5F5' }}>
                <b>Consult Status</b>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.TableRow2}
                style={{ backgroundColor: '#F5F5F5' }}>
                <b>Details</b>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {getVisitSummariesData && getVisitSummariesData.length ? ( */}
            {networkStatus !== NETWORK_STATUS.FETCHING
              ? summaries.map(row => (
                  <StyledTableRow key={row.name} className={classes.TableRow}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className={classes.TableRow2}>
                      <h3>
                        {row.appointment_date
                          ? moment(row.appointment_date).format('DD/MM/YYYY')
                          : null}
                      </h3>
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.TableRow2}>
                      <h3>Dr. {row.name}</h3>
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.TableRow2}>
                      <h3>
                        {' '}
                        {row.appointment_type === '1'
                          ? 'In-Person'
                          : 'Virtual Consultation'}{' '}
                      </h3>
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.TableRow2}>
                      <h3>
                        {row.status === '1'
                          ? 'Booked'
                          : row.status === '2'
                          ? 'Confirmed'
                          : row.status === '3'
                          ? 'Checked-In'
                          : row.status === '4'
                          ? 'In-Progress'
                          : row.status === '5'
                          ? 'Cancelled'
                          : row.status === '6'
                          ? 'No-show'
                          : row.status === '7'
                          ? 'Completed'
                          : row.status === '8'
                          ? 'Rescheduled'
                          : 'NA'}
                      </h3>
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.TableRow2}>
                      {row.status === '7' ? (
                        <h3>
                          <Details item={row} />
                        </h3>
                      ) : (
                        <small
                          style={{ display: 'flex', alignItems: 'center' }}>
                          <WarningIcon
                            style={{
                              color: '#ffa500',
                              fontSize: '12px',
                              marginRight: '10px'
                            }}
                          />{' '}
                          Appointment not completed
                        </small>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {networkStatus === NETWORK_STATUS.FETCHING ? (
          <Skeleton variant={'rect'} height={40} width={'100%'} />
        ) : null}
        {networkStatus !== NETWORK_STATUS.FETCHING && summaries.length === 0 ? (
          <img
            src={NoRecordImg}
            className={classes.notFoundImage}
            alt={'Not found!'}
          />
        ) : null}
      </Box>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  LabReport: {
    marginTop: '0',
    '@media (max-width:767px)': {
      overflowX: 'auto'
    }
  },
  table: {
    minWidth: '100%',
    '@media (max-width:767px)': {
      width: '700px'
    }
  },
  TableRow2: {
    borderBottom: '0px !important',
    padding: '7px 5px !important',
    fontFamily: 'Poppins',
    width: '20%',

    '& b': {
      fontSize: '14px',
      fontWeight: '600',
      color: 'rgba(55,55,55,0.8)'
    },
    '& h3': {
      fontSize: '14px',
      color: '#373737',
      margin: '0px',
      fontWeight: '400 !important'
    }
  },
  TableRow: {
    fontFamily: 'Poppins',
    borderBottom: '0px !important',
    padding: 50,
    '&:nth-child(odd)': {
      background: '#fff'
    },
    '&:nth-child(even)': {
      background: '#F5F5F5'
    }
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px'
  }
}))
