import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { DoctorStore, PatientStore, PublicPatientStore } from '../../stores'
import { NETWORK_STATUS } from '../../Constants'

const Loader = props => {
  const classes = useStyles()
  let networkStatusLoader
  if (localStorage.getItem('routing_mode') === 'doctor') {
    const { networkStatus: doctorNetworkStatus } = DoctorStore.useStoreState(
      state => ({
        ...state.patients
      })
    )
    networkStatusLoader = doctorNetworkStatus
  } else {
    const { networkStatus } = PatientStore.useStoreState(state => ({
      ...state.patients
    }))
    networkStatusLoader = networkStatus
  }
  const {
    networkStatus: schedulerNetworkStatus
  } = PublicPatientStore.useStoreState(state => ({
    ...state.doctorSchedule
  }))

  const open =
    networkStatusLoader === NETWORK_STATUS.FETCHING ||
    schedulerNetworkStatus === NETWORK_STATUS.FETCHING

  return (
    <>
      <Backdrop className={classes.backdrop} open={props.open || open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 99999999,
    color: '#fff'
  }
}))

export default Loader
