import React from 'react'
import swal from 'sweetalert'
import { detect } from 'detect-browser'
import { HOME_URL } from './Environment'
const { isSupported } = require('twilio-video')
const browser = detect()
let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

const UserBrowser = Comp => {
  const user_browser = props => {
    const base_url = HOME_URL;
    let child_url = props.match.url
    return <Comp {...props} />

    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }
    }

    let userPlatform = getMobileOperatingSystem()
    if (userPlatform === 'Android') {
      if (
        (browser.name === 'chrome' && !!window.chrome) ||
        browser.name === 'firefox' ||
        browser.name === 'chromium-webview'
      ) {
        if (isSupported || browser.name === 'chromium-webview') {
          return <Comp {...props} />
        } else {
          swal({
            title: 'Unsupported Browser Version !',
            text: 'Please upgrade your browser',
            buttons: 'Confirm',
            closeOnClickOutside: false,
            className: 'unsupported'
          }).then(isConfirm => {
            if (isConfirm) {
              window.location.href = `https://play.google.com/store/apps/details?id=com.android.chrome&hl=en_IN`
              swal({
                title: 'Refresh',
                text: 'Please refresh your browser',
                closeOnClickOutside: false,
                buttons: 'OK',
                icon: 'info'
              }).then(() => {
                window.location.reload()
              })
            }
          })
        }
      } else {
        swal({
          title: 'Unsupported Browser !',
          closeOnClickOutside: false,
          text:
            'Our app works best for Chrome on Android. Please confirm to launch Chrome',
          buttons: 'Confirm',
          className: 'unsupported'
        }).then(isConfirm => {
          if (isConfirm) {
            window.location.href = `intent://${base_url}${child_url}#Intent;scheme=https;package=com.android.chrome;end`
            swal({
              text: 'Unsupported Browser !',
              button: false,
              closeOnClickOutside: false,
              icon: 'info'
            }).then(() => {
              window.location.reload()
            })
          }
        })
      }
    }
    if (userPlatform === 'iOS') {
      if (browser.name === 'ios') {
        return <Comp {...props} />
      } else {
        swal({
          title: 'Unsupported Browser !',
          className: 'unsupported',
          closeOnClickOutside: false,
          text:
            'Our app works best for Safari on iOS. Please confirm to launch Safari',
          buttons: 'Confirm'
        }).then(isConfirm => {
          if (isConfirm) {
            window.location.href = 'x-web-search://'
            swal({
              text: 'Unsupported Browser !',
              button: false,
              closeOnClickOutside: false,
              icon: 'info'
            }).then(() => {
              window.location.reload()
            })
          }
        })
      }
    }
  }

  const newComp = props => {
    return <div>{isMobile ? user_browser(props) : <Comp {...props} />}</div>
  }
  return newComp
}

export default UserBrowser
