import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import Page from '../../components/Page'
import Footer from '../../../components/CommonComponents/Footer'
import TopBarHome from '../../layouts/MainLayout/TopBar'
import logo from '../../../images/logoHQ.png'
import { ClinicStore } from '../../../stores'
import { NETWORK_STATUS, CLINIC_USER_ID } from '../../../Constants'
import Loader from '../../components/ClinicLoader'
import localforage from 'localforage'
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0)
  },
  footercol: {
    width: '100%',
    paddingRight: '5%',
    display: 'flex',
    paddingTop: '50px'
  }
}))

const LoginView = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const submit = (values, { resetForm }) => {
    login(values).then(data => {
      if (data.statusCode === 200) {
        localStorage.setItem(CLINIC_USER_ID, data.hospital_details.id)
        localforage.setItem('subscription_details', data.hospital_details)
        push('/clinic/dashboard')
      } else {
        resetForm()
      }
    })
  }

  const { login } = ClinicStore.useStoreActions(actions => ({
    ...actions.auth
  }))
  const { networkStatus } = ClinicStore.useStoreState(state => ({
    ...state.auth
  }))

  return (
    <Page className={classes.root} title="Login">
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      {/* <TopBarHome /> */}
      {/* <Grid container spacing={0}>
        <Grid item xs={12}>
          <img
            src={bannerimg}
            style={{ width: '100%', marginTop: '4rem' }}
            alt="bannerImg"
          />
        </Grid>
      </Grid> */}
      <Box className="">
        <Box className="col-left"></Box>
        <Box className="col-bg"></Box>
      </Box>
      <Box className="top-header">
        <img src={logo} alt="logo" style={{ width: '70px' }} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="flex-start"
        className="login-box">
        <Box className="login-coll">
          <Formik
            onSubmit={submit}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                submit()
              }
            }}
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3} className="Logincol">
                  <h3 alignItems="center"> Sign in </h3>
                  <p>Sign in on the clinic platform</p>
                </Box>

                <TextField
                  style={{ fontWeight: '400' }}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Enter email address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                />
                <Box>
                  <Button
                    style={{
                      backgroundColor: '#063bc4',
                      color: '#fff',
                      fontSize: '14px',
                      height: '42px',
                      fontSize: '14px',
                      borderRadius: '50px',
                      margin: '30px 0 10px'
                    }}
                    fullWidth
                    size="large"
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained">
                    Sign in now
                  </Button>
                </Box>
                {/* <Typography
                  className="Addtext"
                  color="textSecondary"
                  variant="body1">
                  Don&apos;t have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/clinic/register"
                    variant="h6">
                    Add your Clinic Today !
                  </Link>
                </Typography> */}
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      {/* <div className={classes.footercol}>
        <Footer />
      </div> */}
    </Page>
  )
}

export default LoginView
