import React, { useEffect } from 'react'
import { SwipeableDrawer } from '@material-ui/core'
import Profile from './Profile'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useHistory, useParams } from 'react-router-dom'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
// import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import CardBgImg from '../../images/nav-top-bg.svg'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import TextsmsIcon from '@material-ui/icons/Textsms'
import ContactsIcon from '@material-ui/icons/Contacts'

const DoctorMenuDrawer = ({ listItems = [], open }) => {
  const classes = useStyles()
  const { push, location } = useHistory()
  // const {} = useParams()

  const listItemClicked = (path, action) => {
    action()
    push(path)
  }

  return (
    <SwipeableDrawer
      className={classes.root}
      open={false}
      // onOpen={console.log}
      variant="permanent"
      // onClose={console.log}
    >
      <Profile />
      <div
        className={classes.Navcol + ' menu-view'}
        style={{ marginBottom: '1rem', paddingBottom: '70px' }}>
        {listItems.map(item => (
          <ListItem
            button
            key={item.id}
            className={`${classes.listItem + ' ' + item.class} ${
              location.pathname === item.path ? classes.activeItem : ''
            }`}
            onClick={() => listItemClicked(item.path, item.action)}>
            {item.id === 0 ? (
              <DashboardOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 1 ? (
              <AssignmentOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 2 ? (
              <SupervisorAccountIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 3 ? (
              <AssignmentIndIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 4 ? (
              <AccountBalanceIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 5 ? (
              <TodayOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 6 ? (
              <TodayOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 7 ? (
              <AddToQueueIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 8 ? (
              <AccountCircleOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 11 ? (
              <AccessibilityNewIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 9 ? (
              <ExitToAppOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 12 ? (
              <TextsmsIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 13 ? (
              <ContactsIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </div>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    '&>div': {
      width: '22vw',
      maxWidth: '300px',
      background: `linear-gradient(45deg, #680fad, #063bc4)`,
      // background: `url(${CardBgImg})#0D46C1 center top no-repeat`,
      // backgroundSize: 'cover',
      color: '#fff',
      top: 0,
      border: 0,
      '@media (max-width:991px)': {
        width: '40vw',
        maxWidth: '300px'
      },
      '@media (max-width:767px)': {
        width: '75vw',
        maxWidth: '300px',
        top: '105px'
      }
    }
  },
  activeItem: {
    background: '#9138aa !important'
  },
  listItem: {
    padding: '8px 32px',
    '& span': {
      fontSize: '14px',
      margin: '6px 0',
      padding: ''
    }
  }
}))

export default DoctorMenuDrawer
