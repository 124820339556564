import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import localforage from 'localforage'
import {
  Grid,
  Avatar,
  Box,
  Backdrop,
  Fade,
  makeStyles
} from '@material-ui/core'
import { LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME } from '../../Constants'


export default function Subscription() {
  const classes = useStyles()

  return (
    <div>
      <Box>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ marginBottom: '10px', minHeight: '550px' }}
            id="divToPrint">
            <h2>My Subscription</h2>
            <Box>
              <p>Your TRIAL account is now active.</p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  
}))
