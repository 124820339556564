import Auth from './Auth'
import DoctorDetails from './DoctorDetails'
import ClinicListDetails from './ClinicListDetails'
import Dashboard from './Dashboard'
import ClinicsAndDoctors from './ClinicsAndDoctors'
import BankDetails from "./BankDetails"
import PocDetails from "./PocDetails"

export default {
  auth: Auth,
  dashboard: Dashboard,
  doctorDetails: DoctorDetails,
  clinicListDetails: ClinicListDetails,
  clinicAndDoctors: ClinicsAndDoctors,
  bankDetails: BankDetails,
  pocDetails: PocDetails
}
