import React, { useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { StylesProvider, ThemeProvider } from '../providers/ThemeProvider'
import {
  DoctorPublicStore,
  DoctorStore,
  PatientStore,
  PublicPatientStore
} from '../stores'
import { DOCTOR_DRAWER_ITEMS } from '../Constants'
// import DashboardLayoutImg from '../images/bg2.png'
import Navbar from '../components/CommonComponents/Navbar'
import DoctorMenuDrawer from '../components/CommonComponents/DoctorMenuDrawer'

const DashboardLayout = ({ children }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  return (
    <PublicPatientStore.Provider>
      <DoctorPublicStore.Provider>
        <DoctorStore.Provider>
          <PatientStore.Provider>
            <StylesProvider>
              <ThemeProvider>
                <CssBaseline />

                <div onClick={() => setOpen(!open)}></div>
                <Grid
                  container
                  className={classes.dashboardPage}
                  spacing={0}
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Grid item xs={12} md={3} className={classes.drawer}>
                    <DoctorMenuDrawer
                      listItems={DOCTOR_DRAWER_ITEMS}
                      open={open}
                    />
                  </Grid>
                  <Grid item className={classes.dashboard}>
                    <Navbar />
                    {children}
                  </Grid>
                </Grid>
              </ThemeProvider>
            </StylesProvider>
          </PatientStore.Provider>
        </DoctorStore.Provider>
      </DoctorPublicStore.Provider>
    </PublicPatientStore.Provider>
  )
}

const useStyles = makeStyles(() => ({
  dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center',
    background: `#FAFAFA`,
    backgroundAttachment: 'fixed'
  },
  drawer: {
    width: '30%',
    flexBasis: '30%',
    maxWidth: '290px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },

  dashboard: {
    // maxWidth: '78%',
    // flexBasis: '78%',
    width: 'calc(100% - 300px)',
    '& .makeStyles-root-102': {
      '@media (max-width:767px)': {
        width: '95% !important'
      }
    },
    '& .scheduler-view': {
      width: '100%',
      '@media (max-width:1366px)': {
        width: '57% !important'
      },
      '@media (max-width:1024px)': {
        width: '900px !important'
      },
      '@media (max-width:767px)': {
        width: '700px !important'
      }
    },
    '@media (max-width:1024px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '15px'
    },
    '@media (max-width:767px)': {
      marginTop: '0px'
    }
  }
}))

export default DashboardLayout
