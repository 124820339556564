import React from 'react'
import BookingContent from './Booking'
import { DashboardLayout } from '../../../layouts/ClinicDashboardLayout'
import { ClinicStore, PublicPatientStore } from '../../../../stores'

const Booking = () => {
  return (
    <PublicPatientStore.Provider>
      <ClinicStore.Provider>
        <DashboardLayout>
          <BookingContent />
        </DashboardLayout>
      </ClinicStore.Provider>
    </PublicPatientStore.Provider>
  )
}

export default Booking
