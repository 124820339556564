import React, { useEffect, useState } from 'react'
import { Grid, TextField, InputAdornment, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CardList from './cardList'
import Card from '../../components/CommonComponents/Card'
import { PatientStore } from '../../stores'
import { IoIosArrowBack } from 'react-icons/io'
import {
  BOOKING_STATUS,
  VISIT_TYPE,
  LIST_TYPE,
  NETWORK_STATUS
} from '../../Constants'
import Loading from 'react-loading'
import PatientCards from './PatientCard'
import { Link } from 'react-router-dom'

const PatientInsights = () => {
  const classes = useStyles()
  const { upcomingdata, networkStatus } = PatientStore.useStoreState(state => ({
    ...state.appointments
  }))
  const { getList } = PatientStore.useStoreActions(actions => ({
    ...actions.appointments
  }))

  const [careID, setCareID] = useState('')

  useEffect(() => {
    getList({
      list_type: LIST_TYPE.UPCOMING.label,
      status: BOOKING_STATUS.ALL.value,
      visit_type: VISIT_TYPE.ALL.id,
      appointment_type: '0',
      sorting: {
        appointment_date: 'ASC',
        start_time: 'ASC'
      }
    })
    const getcareID = async (pid = '') => {
      try {
        if (!(pid?.length > 0)) throw new Error('Patient ID is Invalid')
        const resp = await fetch(
          'https://luzvk76flk.execute-api.ap-south-1.amazonaws.com/uat/user-controller',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              profile_id: pid,
              action: 'GET_CARE_ID',
              resource: 'CARENOW_DOC'
            })
          }
        )
        const respJSON = await resp.json()
        if (respJSON?.statusCode?.toString().startsWith('2'))
          setCareID(respJSON.identity)
        else throw new Error(respJSON?.message)
      } catch (err) {
        console.error('Error in Fetching CareID', err)
      }
    }
    getcareID(localStorage.getItem('patient_id'))
  }, [])

  return (
    <Grid container spacing={3} className={classes.dashboardRoot}>
      <Grid item sm={12} className={classes.insights}>
        {/* Dashboard */}
        <Grid container spacing={3}>
          <Grid item sm={12} className={classes.upcomingAppointmentsRoot}>
            {/* Day Board */}
            <Link to="/patient/selectPatient" className={classes.navLink}>
              <button className={classes.Backbtn}>
                <IoIosArrowBack className={classes.Backicon} />
                Select Patient Account
              </button>
            </Link>
            <TextField
              id="care-id"
              name="care-id"
              label="Care ID"
              variant="outlined"
              value={careID}
              disabled
              fullWidth
              style={{ marginBottom: '2rem', marginTop: '1rem' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ paddingRight: 0 }}
                      onClick={async () => {
                        try {
                          await navigator.clipboard.writeText(careID)
                          alert('Care ID Copied to Clipboard.')
                        } catch (error) {
                          console.error(error.message)
                        }
                      }}>
                      <svg width="1.5rem" viewBox="0 0 115.77 122.88">
                        <path
                          d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
                          stroke="currentColor"
                        />
                      </svg>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <div className={classes.upcomingAppointments}>
              <Card
                style={{ minHeight: '340px', borderTop: '4px solid #535f8f' }}>
                <h4> Upcoming Appointments</h4>
                {/* <DashboardAppointments /> */}
                {networkStatus === NETWORK_STATUS.FETCHING ? (
                  <Loading
                    type="cylon"
                    textAlign="center"
                    color="grey"
                    height={40}
                  />
                ) : upcomingdata.length ? (
                  upcomingdata.length > 5 ? (
                    <React.Fragment>
                      <PatientCards appointments={upcomingdata.slice(0, 5)} />

                      <Link
                        to="/patient/appointments"
                        className={classes.navLink}>
                        View More...
                      </Link>
                    </React.Fragment>
                  ) : (
                    <PatientCards appointments={upcomingdata} />
                  )
                ) : (
                  <p>Nothing Found</p>
                )}
              </Card>
            </div>
          </Grid>
          <Grid item sm={3} className={classes.AppointmentsCard}>
            <CardList linkType={1} />
          </Grid>
          <Grid item sm={3} className={classes.AppointmentsCard}>
            {/* Day Status */}
            <CardList linkType={2} />
          </Grid>
          <Grid item sm={3} className={classes.AppointmentsCard}>
            {/* Day Status */}
            <CardList linkType={3} />
          </Grid>
          <Grid item sm={3} className={classes.AppointmentsCard}>
            {/* Day Status */}
            <CardList linkType={4} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  dashboardRoot: {
    margin: '40px 0 12px',
    width: '100%',
    justifyContent: 'flex-start',
    '@media (max-width:991px)': {
      margin: '20px 0 10px',
      paddingRight: '15px'
    },
    '@media (max-width:550px)': {
      margin: '20px 0 10px',
      paddingRight: '15px'
    }
  },
  Backbtn: {
    marginRight: '20px',
    background: 'none',
    border: '0px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    cursor: 'pointer',
    marginTop: '-25px',
    marginBottom: '0',
    '@media (max-width:550px)': {
      marginBottom: '0px'
    }
  },
  Backicon: {
    marginRight: '5px'
  },
  insights: {
    '@media (max-width:991px)': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  HospitalList: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:991px)': {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    '& img': {
      width: '100%',
      marginBottom: '15px'
    }
  },
  HospitalImg: {
    '& img': {
      width: '100%'
    },
    '@media (max-width:991px)': {
      width: '25%'
    },
    '@media (max-width:550px)': {
      width: '50%'
    }
  },
  // insights: {
  //   maxWidth: 950
  // },
  // upcomingAppointmentsRoot: {
  //   maxWidth: 750
  // },
  upcomingAppointmentsRoot: {
    '@media (max-width:767px)': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  AppointmentsCard: {
    backgroundColor: '#fff',
    '@media (max-width:767px)': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  upcomingAppointments: {
    maxWidth: '100%',
    minHeight: 375,
    '& h4': {
      marginTop: '0px',
      fontWeight: '500',
      color: '#111d4e',
      display: 'flex'
    }
  },
  myHospital: {
    width: '100%',
    marginBottom: 16,
    '& h2': {
      fontSize: '24px',
      fontWeight: '500'
    },
    '& img': {
      width: '100%'
    },
    '&>p': {
      fontSize: '14px',
      fontWeight: '400'
    },
    '&>img': {
      width: '100%'
    }
  },
  news: {
    // maxWidth: 'unset'
    '@media (max-width:991px)': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  NoticeList: {
    width: '100%',
    borderBottom: '1px rgba(0,0,0,0.1) solid',
    '& h3': {
      fontSize: '18px',
      marginBottom: '0px',
      color: 'rgba(0,0,0,0.8)'
    },
    '& p': {
      fontSize: '14px',
      color: 'rgba(0,0,0,0.6)',
      marginTop: '0px'
    },
    '& h6': {
      fontSize: '11px',
      color: 'rgba(0,0,0,0.5)',
      marginTop: '0px'
    }
  },
  embeddedTwitter: {
    display: 'none'
  },
  show: {
    display: 'block'
  }
}))

export default PatientInsights
