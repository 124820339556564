import React from 'react'
import { ClinicStore } from '../../../stores'
import PaymentView from './PaymentView'

const PaymentLayout = () => {
  return (
    <ClinicStore.Provider>
      <PaymentView />
    </ClinicStore.Provider>
  )
}


export default PaymentLayout
