import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PatientProtectedRoute from '../components/CommonComponents/PatientProtectedRoute'
import {
  NotFoundPage,
  PatientsAppointmentsPage,
  PatientLogin,
  PatientDetailsInfo,
  PatientTeleConsultPage,
  PatientUpdateProfile,
  PatientDashboard,
  PatientSelect,
  PatientMedications,
  PatientVitals,
  PatientDocuments,
  SelectBookingPatientLayout
} from '../pages'
import { PATIENT_USER_ID } from '../Constants'

export default function PatientRoutes() {
  localStorage.setItem('routing_mode', 'patient')
  console.log(localStorage.getItem('routing_mode'))

  return (
    <Switch>
      <Route
        exact
        path="/patient/login"
        render={() => {
          return localStorage.getItem(PATIENT_USER_ID) ? (
            <Redirect to="/patient/dashboard" />
          ) : (
            <PatientLogin />
          )
        }}
      />
      <PatientProtectedRoute>
        <Route exact path="/patient/selectPatient" component={PatientSelect} />
        <Route
          exact
          path="/patient/booking/selectPatient"
          component={SelectBookingPatientLayout}
        />
        <Route exact path="/patient/dashboard" component={PatientDashboard} />
        <Route exact path="/patient/vitals" component={PatientVitals} />
        <Route
          exact
          path="/patient/medications"
          component={PatientMedications}
        />
        <Route
          exact
          path="/patient/details/:appointment_id"
          component={PatientDetailsInfo}
        />
        <Route exact path="/patient/documents" component={PatientDocuments} />

        <Route
          exact
          path="/patient/appointments"
          component={PatientsAppointmentsPage}
        />
        <Route
          exact
          path="/patient/updateprofile"
          component={PatientUpdateProfile}
        />
        <Route
          exact
          path="/patient/doctor/teleconsult/:appointment_id"
          component={PatientTeleConsultPage}
        />
        <Route exact path="/404" component={NotFoundPage} />
      </PatientProtectedRoute>
    </Switch>
  )
}
