import { action, thunk } from 'easy-peasy'

import BaseModel from '../Base'
import api from '../../api'
import { NETWORK_STATUS } from '../../Constants'

const NO_CONNECTION_MESSAGE =
  'Oops! Something went wrong. Please try again later...'

export default {
  ...BaseModel(),

  pocinfo: '',
  reload: true,
  messageToggle: false,
  message: '',

  //set reload to ture/false
  setReload: action((state, payload) => {
    state.reload = payload
  }),

  //set message
  setMessage: action((state, payload) => {
    state.message = payload.message
    state.messageToggle = payload.messageToggle
  }),

  //get poc detais
  getPocDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    try {
      const resp = await api.super_clinic.getPocDetails({
        super_clinic_id: payload.super_clinic_id
      })

      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      console.log(resp.data)
      if (resp.data.statusCode && resp.data.statusCode === 200) {
        actions.setPocInfo(resp.data.POC_data)
      } else {
        actions.setMessage({
          message: resp.data.message,
          messageToggle: true
        })
      }
    } catch {}
  }),

  //edit poc details
  editPocDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    console.log(payload)

    try {
      const resp = await api.super_clinic.editPocDetails({
        poc_email_id: payload.poc_email_id,
        poc_phone_no: `+91${payload.poc_phone_no}`,
        point_of_contact_name: payload.point_of_contact_name,
        poc_phone_country_code: payload.poc_phone_country_code
      })

      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

      console.log(resp.data)
      if (resp.data.statusCode && resp.data.statusCode === 201) {
        actions.setReload(true)
      } else {
        actions.setMessage({
          message: resp.data.message,
          messageToggle: true
        })
      }
    } catch {}
  }),

  setPocInfo: action((state, payload) => {
    console.log('paylaod of action')
    console.log(payload)
    state.pocinfo = payload
  })
}
