import Appointment from './Appointment'
import Auth from './Auth'
import DoctorProfile from './DoctorProfile'


export default {
  appointment: Appointment,
  auth: Auth,
  doctorProfile: DoctorProfile,
}
