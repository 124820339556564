import React, { useState, useEffect, useCallback, useRef } from 'react'
import Video, { Room } from 'twilio-video'
import Participant from './Participant'
import './player.css'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import Toolbar from './Toolbar'
import loader from '../../images/loader.gif'

const Player = ({
  roomName,
  token,
  endSession,
  isAudioCall,
  appointment,
  history,
  displayNetworkText
}) => {
  const [room, setRoom] = useState(null)
  const [roomId, setRoomId] = useState(null)
  const [participants, setParticipants] = useState([])
  const [isAudioOpen, setAudio] = useState(true)
  const [isVideoOpen, setVideo] = useState(true)
  const [isScreenSharing, setIsScreenSharing] = useState(false)
  const [screenShareTrack, setScreenShareTrack] = useState(null)
  const [localVideoTrack, setLocalVideoTrack] = useState(null)
  const [loading, setLoading] = useState(null)
  const [participantStatus, setParticipantStatus] = useState(
    'Waiting for your participant to connect...'
  )
  const [
    participantVideoTrackStatus,
    setParticipantVideoTrackStatus
  ] = useState(true)
  const [
    participantAudioTrackStatus,
    setParticipantAudioTrackStatus
  ] = useState(true)
  const [roomStatus, setRoomStatus] = useState(true)
  const [selfStatus, setSelfStatus] = useState('Connected')
  const [selfStatusText, setNetworkStatusText] = useState('')
  const [networkStatus, setNetworkStatus] = useState(5)
  const [endMeetingTimer, setEndMeetingTimer] = useState(null)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  //muting the audio track
  const muteAudio = useCallback(() => {
    let audioStatus = isAudioOpen
    if (room) {
      room.localParticipant.audioTracks.forEach(publication => {
        if (audioStatus) publication.track.disable()
        else publication.track.enable()
      })
      let status = audioStatus ? 'Muted' : 'UnMuted'
      // console.log('audio is ' + status)
      setAudio(!audioStatus)
    }
  }, [room, isAudioOpen, setAudio])

  const roomClosed = useCallback(() => {
    setRoomStatus(false)
  }, [setRoomStatus])

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }


  // Loader Image
  let image = (
    <img
      style={{ display: 'block', width: '360px' }}
      className={'loader'}
      src={loader}
      alt="Loader"
    />
  )

  useEffect(() => {
    if (image) {
      setLoading(image)
    }
    setOpenSnackBar(true)
    setSnackBarMessage("Please allow microphone and webcam access")
    setmultiButton(false)
    setAlertType('info')
  }, [])

  //muting the video track
  const muteVideo = useCallback(() => {
    if (isScreenSharing) {
      setOpenSnackBar(true)
      setSnackBarMessage('Switch off screen sharing before turning your video on')
      setmultiButton(false)
      setAlertType('error')
    } else {
      let videoStatus = isVideoOpen
      if (room) {
        room.localParticipant.videoTracks.forEach(publication => {
          if (videoStatus) publication.track.disable()
          else publication.track.enable()
        })
        let status = videoStatus ? 'Muted' : 'UnMuted'
        // console.log('video is ' + status)
        setVideo(!videoStatus)
      }
    }
  }, [room, isVideoOpen, isScreenSharing, setVideo])

  //screen share
  const screenShare = useCallback(() => {
    if (!isScreenSharing) {
      navigator.mediaDevices
        .getDisplayMedia({
          audio: false
        })
        .then(stream => {
          const track = stream.getTracks()[0]
          setScreenShareTrack(track)
          room.localParticipant
            .publishTrack(track, {
              name: 'screen'
            })
            .then(() => {
              room.localParticipant.videoTracks.forEach(publication => {
                if (publication.trackName !== 'screen') {
                  setLocalVideoTrack(publication.track)
                  publication.track.disable()
                  room.localParticipant.unpublishTrack(publication.track)
                  setVideo(false)
                  setIsScreenSharing(true)
                }
              })
            })
          track.onended = () => {
            closeScreenShare()
          }
        })
    } else {
      if (screenShareTrack && localVideoTrack) {
        screenShareTrack.stop()
        room.localParticipant.publishTrack(localVideoTrack)
        room.localParticipant.unpublishTrack(screenShareTrack)
        setIsScreenSharing(false)
        setScreenShareTrack(null)
      }
    }
  }, [
    room,
    isScreenSharing,
    screenShareTrack,
    localVideoTrack,
    setIsScreenSharing,
    setScreenShareTrack,
    setRoom,
    setLocalVideoTrack
  ])

  //close screen share by clicking the stop button explicitly
  const closeScreenShare = useCallback(() => {
    if (localVideoTrack) {
      room.localParticipant.publishTrack(localVideoTrack)
      setScreenShareTrack(null)
      setIsScreenSharing(false)
    }
  }, [room, localVideoTrack, setIsScreenSharing, setScreenShareTrack])

  //sweetalert closing on any alert is opened
  // const closeSwal = () => {
  //   var swalObject = swal.getState()
  //   if (swalObject.isOpen) {
  //     swal.close()
  //   }
  // }

  //disconnect from room instance
  const disconnectCurrentClient = room => {
    if (room) room.disconnect()
  }

  //disconnects the chat on page refresh
  useEffect(() => {
    if (isAudioCall && isVideoOpen) {
      muteVideo()
    }
    var endDateTime = moment(appointment.appointment_end_date_time).format()
    var currentDateTime = moment()
    var timeDifference = Math.abs(
      currentDateTime.diff(endDateTime, 'milliseconds')
    )
    setEndMeetingTimer(
      setTimeout(() => {
        endSession(roomClosed, true)
      }, timeDifference)
    )
    return () => {
      // closeSwal()
      clearTimeout(endMeetingTimer)
      setEndMeetingTimer(null)
      disconnectCurrentClient(room) // this needs to be fired only once when component onmounts but clearing effect is done every time videoProgress changes
    }
  }, [room])

  //Room Creation and participant connection and disconnection handled
  useEffect(() => {
    const participantConnected = participant => {
      // closeSwal()
      setParticipantStatus('Connected')
      setParticipants(prevParticipants => [...prevParticipants, participant])
    }

    const networkQualityChanged = level => {
      let statusText = ''
      if (level <= 2) {
        statusText =
          'Your Network Quality is low, so you can mute your video for an uninterupted appointment'
      } else {
        if (!isVideoOpen) {
          statusText =
            'Your Network Quality is high enough to switch back to the video call by unmuting your video'
        }
      }
      setNetworkStatusText(statusText)
      setNetworkStatus(level)
      displayNetworkText(statusText)
    }
    const participantDisconnected = participant => {
      setParticipantStatus('Waiting for your participant to connect...')
      // if (roomStatus) {
      //   swal('Alert', participantStatus, {
      //     button: true,
      //     closeOnClickOutside: false
      //   })
      // }
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      )
    }

    const participantReconnected = participant => {
      setParticipantStatus('Connected')
      // closeSwal()
    }

    const participantReconnecting = participant => {
      // swal('Alert', participantStatus, {
      //   button: true,
      //   closeOnClickOutside: false
      // })
      setOpenSnackBar(true)
      setSnackBarMessage(participantStatus)
      setmultiButton(false)
      setAlertType('info')

      setParticipantStatus('Reconnecting...')
    }

    const selfReconnecting = () => {
      setSelfStatus('Reconnecting')
      // swal('Alert', 'Reconnecting...', {
      //   button: false,
      //   closeOnClickOutside: false
      // })
      setOpenSnackBar(true)
      setSnackBarMessage('Reconnecting')
      setmultiButton(false)
      setAlertType('info')
    }

    const selfReconnected = error => {
      // closeSwal()
      setSelfStatus('Connected')
    }

    const participantTrackDisabled = (track, participant) => {
      if (track.kind == 'audio') {
        setParticipantAudioTrackStatus(false)
      } else if (track.kind == 'video') {
        setParticipantVideoTrackStatus(false)
      }
    }

    const participantTrackEnabled = (track, participant) => {
      if (track.kind == 'audio') {
        setParticipantAudioTrackStatus(true)
      } else if (track.kind == 'video') {
        setParticipantVideoTrackStatus(true)
      }
    }

    const disconnected = (room, error) => {
      if (error) {
        if (error.message == 'Room completed') {
          setRoomStatus(false)
          room.disconnect()
          endSession(roomClosed, true, true)
        } else {
          setSelfStatus('Disconnected')
          // swal({
          //   title: 'Oops!',
          //   text: 'Something went wrong. Please click Ok or refresh to rejoin.',
          //   type: 'failed'
          // }).then(function () {
          //   window.location.reload()
          // })

          setOpenSnackBar(true)
          setSnackBarMessage('Something went wrong. Please click Ok or refresh to rejoin!')
          setmultiButton(true)
          setAlertType('error')
        }
      }
    }



    Video.connect(token, {
      name: roomName,
      networkQuality: {
        local: 1,
        remote: 1
      },
      bandwidthProfile: {
        video: {
          mode: 'grid',
          trackSwitchOffMode: 'disabled',
          maxSubscriptionBitrate: 0,
          renderDimensions: {
            high: { width: 1280, height: 780 },
            standard: { width: 640, height: 480 },
            low: { width: 176, height: 144 }
          }
        }
      }
    })
      .then(room => {
        setRoom(room)
        setRoomId(room.sid)
        if (room.participants.size === 0) {
          // swal('Alert', participantStatus, {
          //   button: true,
          //   closeOnClickOutside: false
          // })
          setOpenSnackBar(true)
          setSnackBarMessage(participantStatus)
          setmultiButton(false)
          setAlertType('info')
        }
        room.on('participantConnected', participantConnected)
        room.on('participantDisconnected', participantDisconnected)
        room.on('participantReconnected', participantReconnected)
        room.on('participantReconnecting', participantReconnecting)
        room.on('reconnecting', selfReconnecting)
        room.on('reconnected', selfReconnected)
        room.on('disconnected', disconnected)
        room.on('trackDisabled', participantTrackDisabled)
        room.on('trackEnabled', participantTrackEnabled)
        room.participants.forEach(participantConnected)
        room.localParticipant.on(
          'networkQualityLevelChanged',
          networkQualityChanged
        )
      })
      .catch(error => {
        if (error.name == 'NotAllowedError') {
          setParticipantStatus('Permission denied by the user')
          // swal({
          //   title: 'Permissions Denied',
          //   button: true,
          //   closeOnClickOutside: false,
          //   text:
          //     'CareNow Doc requires access to your camera and microphone. Click the camera blocked icon in your browser.',
          //   icon: 'error'
          // })
          setOpenSnackBar(true)
          setSnackBarMessage('CareNow Doc requires access to your camera and microphone. Click the camera blocked icon in your browser')
          setmultiButton(false)
          setAlertType('error')
        } else {
          setParticipantStatus('Browser Error')
          // swal({
          //   title: error.name,
          //   button: true,
          //   closeOnClickOutside: false,
          //   text: error.message,
          //   icon: 'error'
          // })
          setOpenSnackBar(true)
          setSnackBarMessage(error.message)
          setmultiButton(false)
          setAlertType('error')
        }
      })

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop()
          })
          currentRoom.disconnect()
          return null
        } else {
          return currentRoom
        }
      })
    }
  }, [roomName, token])

  const validationCheck = () => {

    window.location.reload()
  }

  // const styles= () => {
  //   return{
  //     loadingGif:{
  //       padding:0,
  //       margin:0,
  //       position: 'absolute',
  //       top:"50%",
  //       left:"50%",
  //       transform: "translate(-50% , -50%)",
  //       width:"65%",
  //       maxHeight:"100%",
  //       maxWidth:"100%",
  //       borderRadius: '10px',
  //       backgroundSize:"400px auto",
  //       backgroundRepeat: 'no-repeat',
  //       backgroundPosition: 'center center',
  //     }

  //   }
  // }

  const remoteParticipants = participants.map(participant => (
    <Participant
      key={participant.sid}
      participant={participant}
      muteAudio={muteAudio}
      muteVideo={muteVideo}
      screenShare={screenShare}
      roomClosed={roomClosed}
      isScreenSharing={isScreenSharing}
      endSession={endSession}
      audioStatus={isAudioOpen}
      videoStatus={isVideoOpen}
      roomId={roomId}
      room={room}
      participantStatus={participantStatus}
      appointment={appointment}
      isAudioCall={isAudioCall}
      participantVideoTrackStatus={participantVideoTrackStatus}
      participantAudioTrackStatus={participantAudioTrackStatus}
    />
  ))
  var participantConnectedClass =
    remoteParticipants.length == 0 ? 'not-connected' : ''
  var participantStatusVisible =
    participantStatus === 'Waiting for your participant to connect...' ||
      participantStatus === 'Permission denied by the user' ||
      participantStatus === 'Browser Error'
      ? ''
      : 'hidden'
  var toolBarInvisible =
    participantStatus === 'Permission denied by the user' ||
      participantStatus === 'Browser Error'
      ? 'hidden'
      : participantStatusVisible

  return (
    <div>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        secondaryClick={validationCheck}
      />
      <div className="player-top">
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 connection-string">
          <span className="connection-sts">Status: </span>
          <span className={'connection ' + selfStatus}>{selfStatus}</span>
        </div>
        <div className="col-6 col-xs-6 col-sm-6 col-md-6 network-indicator">
          <span className="network-sts">Network: </span>
          {[0, 1, 2, 3, 4].map(level => (
            <div
              className="network-bar"
              key={level}
              style={{
                height: `${4 * (level + 1)}px`,
                background:
                  networkStatus > level
                    ? networkStatus < 3
                      ? '#f00'
                      : networkStatus < 4
                        ? '#ff0'
                        : '#0c0'
                    : '#040'
              }}
            />
          ))}
        </div>
      </div>
      <div
        className={`col-sm-6 col-md-12 patient-status ${participantStatusVisible}`}>
        <span className={'patient-connection'}>
          {participantStatus === 'Waiting for your participant to connect...'
            ? loading
            : null}
        </span>
      </div>
      <div className={'room ' + participantConnectedClass}>
        <div className="client-video">{remoteParticipants}</div>
        <div className={`doctor-toolbar ${toolBarInvisible}`}>
          <Toolbar
            muteVideo={muteVideo}
            muteAudio={muteAudio}
            endSession={endSession}
            screenShare={screenShare}
            audioStatus={isAudioOpen}
            videoStatus={isVideoOpen}
            isAudioCall={isAudioCall}
            isScreenSharing={isScreenSharing}
          />
        </div>
        <div className={'self-video ' + participantConnectedClass}>
          {room ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              remote={true}
            />
          ) : (
              ''
            )}
        </div>
      </div>
    </div>
  )
}

export default Player
