import React from 'react'
import DashboardContent from './Dashboard'
import { DashboardLayout } from '../../../layouts/ClinicDashboardLayout'
import { ClinicStore } from '../../../../stores'

const Dashboard = () => {
  return (
    <ClinicStore.Provider>
      <DashboardLayout>
        <DashboardContent />
      </DashboardLayout>
    </ClinicStore.Provider>
  )
}

export default Dashboard
