import React from 'react'
import { Box } from '@material-ui/core'

import { Layout } from '../doctorLayouts'

const NotFound = () => {
  return (
    <Layout hideNavbar>
      <Box m={10}>Not Found</Box>
    </Layout>
  )
}

export default NotFound
