import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { SUBSCRIPTION_PRICES } from '../../../Constants'
import { ClinicStore } from '../../../stores'
import Plan from './Plan'

const PaymentPlans = ({ setPlan, planName, setPlanDuration }) => {
  const [plansState, setPlansState] = useState({
    'opt-1': false,
    'opt-2': false,
    'opt-3': false
  })
  const [microAvailable, setMicroAvailable] = useState(false)
  const [miniAvailable, setMiniAvailable] = useState(false)
  const [payAsYouGoAvailable, setPayAsYouGoAvailable] = useState(false)

  const { subscriptionPlanList } = ClinicStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const { getSubscriptionList } = ClinicStore.useStoreState(state => ({
    ...state.profile
  }))

  React.useEffect(() => {
    subscriptionPlanList()
  }, [])

  React.useEffect(() => {
    if (getSubscriptionList) {
      let planList = []
      if (getSubscriptionList.length == 1) {
        setPlan(getSubscriptionList[0].display_name)
        setPlanDuration(
          getSubscriptionList[0].slug,
          getSubscriptionList[0].display_name
        )
      }
      getSubscriptionList.map(plan => {
        if (plan.display_name == 'Micro Tier') {
          if (plan.slug == 'yearly') {
            SUBSCRIPTION_PRICES.MICRO_YEARLY = plan.price
          } else {
            SUBSCRIPTION_PRICES.MICRO_MONTHLY = plan.price
          }
        } else if (plan.display_name == 'Mini Tier') {
          if (plan.slug == 'yearly') {
            SUBSCRIPTION_PRICES.MINI_YEARLY = plan.price
          } else {
            SUBSCRIPTION_PRICES.MINI_MONTHLY = plan.price
          }
        } else if (plan.display_name == 'Pay as you go') {
          SUBSCRIPTION_PRICES.PAY_AS_YOU_GO = plan.price
        }
        planList.push(plan.display_name)
      })
      setMicroAvailable(planList.includes('Micro Tier'))
      setMiniAvailable(planList.includes('Mini Tier'))
      setPayAsYouGoAvailable(planList.includes('Pay as you go'))
    }
  }, [getSubscriptionList])

  const PlanNameMapping = {
    'Micro Tier': 'Microplan',
    'Mini Tier': 'Miniplan',
    'Pay as you go': 'Pay as you go'
  }

  let checkedValue = PlanNameMapping[planName]

  const viewPressedHandler = id => {
    const newPlansState = { ...plansState }
    for (const [key, value] of Object.entries(newPlansState)) {
      if (key === id) {
        newPlansState[key] = !value
      } else {
        newPlansState[key] = false
      }
    }
    setPlansState(newPlansState)
  }

  return (
    <Box className="payment-plan">
      <ul>
        {microAvailable && (
          <li>
            <Plan
              inputId="opt-1"
              inputValue="Microplan"
              inputName="plan"
              cardHeading="Micro Tier"
              annualStrikedPrice="1399"
              annualActualPrice={SUBSCRIPTION_PRICES.MICRO_YEARLY}
              annualPercentageOff="50"
              monthlyActualPrice={SUBSCRIPTION_PRICES.MICRO_MONTHLY}
              monthlyPercentageOff="50"
              noOfDoctors="5"
              viewClicked={viewPressedHandler}
              show={plansState}
              setPlan={setPlan}
              checkedValue={checkedValue}>
              <ul className="st-pricing-feature-list st-mp0">
                <li>10 Days FREE Trial</li>
                <li>Intelligent Scheduler</li>
                <li>Unlimited In-Person and Tele Consult, @3.25% trans fee</li>
                <li>Unlimited Emails</li>
                <li>1000 SMS notifications</li>
                <li>Patient Charting with Ai based Vitals Capture</li>
                <li>Easy Reschedule and Cancellation</li>
                <li>Doctor / Clinic smart profile card with QR code</li>
                <li>For Clinic (FREE - Landing page with doctor listing)</li>
                <li>24/7 Virtual Medical Assistance</li>
                <li>e-Prescription</li>
                <li>Clinical Insights</li>
                <li>Easy Wallet (No transactional cost)</li>
                <li>Suggestive Medications</li>
                <li>No App Required</li>
              </ul>
            </Plan>
          </li>
        )}
        {miniAvailable && (
          <li>
            <Plan
              inputId="opt-2"
              inputValue="Miniplan"
              inputName="plan"
              cardHeading="Mini Tier"
              annualStrikedPrice="1199"
              annualActualPrice={SUBSCRIPTION_PRICES.MINI_YEARLY}
              annualPercentageOff="50"
              monthlyActualPrice={SUBSCRIPTION_PRICES.MINI_MONTHLY}
              monthlyPercentageOff="699"
              noOfDoctors="15"
              viewClicked={viewPressedHandler}
              show={plansState}
              setPlan={setPlan}
              checkedValue={checkedValue}>
              <ul className="st-pricing-feature-list st-mp0">
                <li>10 Days FREE Trial</li>
                <li>Intelligent Scheduler</li>
                <li>Unlimited In-Person and Tele Consult, @3.25% trans fee</li>
                <li>Unlimited Emails</li>
                <li>1000 SMS notifications</li>
                <li>Patient Charting with Ai based Vitals Capture</li>
                <li>Easy Reschedule and Cancellation</li>
                <li>Doctor / Clinic smart profile card with QR code</li>
                <li>For Clinic (FREE - Landing page with doctor listing)</li>
                <li>24/7 Virtual Medical Assistance</li>
                <li>e-Prescription</li>
                <li>Clinical Insights</li>
                <li>Easy Wallet (No transactional cost)</li>
                <li>Suggestive Medications</li>
                <li>No App Required</li>
              </ul>
            </Plan>
          </li>
        )}
        {payAsYouGoAvailable && (
          <li>
            <Plan
              inputId="opt-3"
              inputValue="Pay as you go"
              inputName="plan"
              cardHeading="Pay As You Go"
              component={
                <div>
                  <h5>
                    It's <i class="fa fa-inr"></i> 0* initially. Pay only when
                    you earn.
                  </h5>
                  <h6 className="doctor-text">Unlimited Doctors</h6>
                </div>
              }
              noOfDoctors="15"
              viewClicked={viewPressedHandler}
              show={plansState}
              setPlan={setPlan}
              checkedValue={checkedValue}>
              <Box className="pay-box2">
                <Box className="pay-top">
                  <h4>Total Earnings (Online + Offline)</h4>
                  <h4>Charge</h4>
                </Box>
                <Box className="pay-row">
                  <h6>0 - 25,000</h6>
                  <h6>2.5%</h6>
                </Box>
                <Box className="pay-row">
                  <h6>25,000 - 75,000</h6>
                  <h6>2%</h6>
                </Box>
                <Box className="pay-row">
                  <h6>75,000 - 250,000</h6>
                  <h6>1.75%</h6>
                </Box>
                <Box className="pay-row">
                  <h6>2,50,000 - 5,00,000</h6>
                  <h6>1%</h6>
                </Box>
                <Box className="pay-row">
                  <h6>5,00,000 +</h6>
                  <h6>0.5%</h6>
                </Box>
              </Box>
            </Plan>
          </li>
        )}
      </ul>
    </Box>
  )
}

export default PaymentPlans
