import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Page from '../../components/Page'
import Revenue from './revenue/Revenue'
import ClinicsAndDoctors from './clinicsanddoctors/ClinicsAndDoctors'

const Dashboard = props => {
  if (props.match.isExact) {
    return <Redirect to="/superclinic/dashboard/revenue" />
  }

  return (
    <Switch>
      <Route
        exact
        path="/superclinic/dashboard/revenue"
        render={props => (
          <Page title={'Revenue | Dashboard'}>
            <Revenue {...props} />
          </Page>
        )}
      />
      <Route
        exact
        path="/superclinic/dashboard/clinicsanddoctors"
        render={props => (
          <Page title={'Clinics & Doctors | Dashboard'}>
            <ClinicsAndDoctors {...props} />
          </Page>
        )}
      />
    </Switch>
  )
}

export default Dashboard
