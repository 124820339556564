import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Doughnut } from 'react-chartjs-2'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core'
import LaptopMacIcon from '@material-ui/icons/LaptopMac'
import PhoneIcon from '@material-ui/icons/Phone'
import TabletIcon from '@material-ui/icons/Tablet'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width:'100%',
    boxShadow:'0 1px 10px 0 rgba(0,0,0,0.1)'
  }
}))

const TrafficByDevice = ({ className, data, ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [graphData, setGraphData] = React.useState({})
  const [graphKeys, setGraphKeys] = React.useState([])

  function color(color, amount) {
    return (
      '#' +
      color
        .replace(/^#/, '')
        .replace(/../g, color =>
          (
            '0' +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    )
  }

  React.useEffect(() => {
    if (data.length !== 0) {
      let graphKey = []
      let colorList = []
      let labels = []
      let valuesList = []
      let keys = data.map((item, i) => {
        graphKey.push({
          title: item.name,
          value: Number(item.clinic_revenue),
          color: '#' + (((1 << 24) * Math.random()) | 0).toString(16)
        })
      })
      keys = data.map((item, i) => {
        colorList.push('#' + (((1 << 24) * Math.random()) | 0).toString(16))
      })
      keys = data.map(item => {
        labels.push(item.name)
      })
      keys = data.map(item => {
        valuesList.push(Number(item.clinic_revenue))
      })
      setGraphKeys(graphKey)
      setGraphData({
        datasets: [
          {
            data: valuesList,
            backgroundColor: colorList,
            borderWidth: 5,
            borderColor: colors.common.white,
            hoverBorderColor: colors.common.white
          }
        ],
        labels: labels
      })
    }
  }, [data])

  const options = {
    animation: true,
    cutoutPercentage: 50,
    layout: { padding: 0 },
    legend: {
      display: true
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 2,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {/* <CardHeader title="" style={{fontSize:'1.2rem'}} /> */}
      <h3 style={{ fontSize:'1.2rem', fontWeight:'500', padding:'15px 15px 0'}}>Top Earnings</h3>
      <Divider />
      <CardContent>
        {data.length !== 0 ? (
          <>
            <Box height={300} position="relative">
              <Doughnut data={graphData} options={options} />
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              {graphKeys.map(({ color, title, value }) => (
                <Box key={title} p={1} textAlign="center">
                  <Typography color="textPrimary" variant="body1">
                    {title}
                  </Typography>
                  <Typography style={{ color }} variant="h6">
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>{' '}
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>No data found</div>
        )}
      </CardContent>
    </Card>
  )
}

TrafficByDevice.propTypes = {
  className: PropTypes.string
}

export default TrafficByDevice
