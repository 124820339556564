import React, { useState } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Grid,
  makeStyles
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import NoRecordImg from '../../../../images/noRecordFound.png'
import { ClinicStore } from '../../../../stores'
// import moment from 'moment'

//THIS IS FOR PAYOUT HISTORY

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    boxShadow:'0 1px 8px 0 rgba(0,0,0,0.1)',
    borderBottomLeftRadius:'10px',
    borderBottomRightRadius:'10px',
    marginTop:'-15px',
    padding:'25px',
    '& .MuiCardContent-root:last-child':{
      paddingBottom:'0px !important'
    }
  },
  actions: {
    justifyContent: 'flex-end'
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px',
    marginTop: '70px'
  }
}))

const RequestList = ({ className, ...rest }) => {
  const classes = useStyles()
  const [orders] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [pageno, setPage] = useState(1)

  const { getPayoutList } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))

  const { payoutList } = ClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))

  React.useEffect(() => {
    fetchList()
  }, [])

  React.useEffect(()=>{
    if(startDate !== undefined){
      fetchList()
    }
  },[startDate , endDate])

  const fetchList = () => {
    getPayoutList({
      start_date: startDate,
      end_date: endDate,
      page_no: String(pageno),
      rows_per_page: '100'
    })
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <h3 style={{fontSize:'16px', color:'#64666d', fontWeight:'600'}}>Payout History</h3>
      <Grid
        container
        direction="row">
        <Box style={{marginRight:'20px', marginBottom:'20px'}}>
          <KeyboardDatePicker
            variant="inline"
            format="DD-MM-YYYY"
            margin="normal"
            maxDate={new Date()}
            name="from"
            label="From"
            value={startDate}
            onChange={(e,value) => setStartDate(e)}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            autoOk={true}
          />
        </Box>
    
        <Box style={{marginBottom:'20px'}}>
          <KeyboardDatePicker
            variant="inline"
            format="DD-MM-YYYY"
            margin="normal"
            maxDate={new Date()}
            name="to"
            label="To"
            value={endDate}
            onChange={(e,value) => setEndDate(e)}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            autoOk={true}
          />
        </Box>
      </Grid>

      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>Req ID</TableCell> */}
                <TableCell style={{fontFamily:'Poppins',color:'rgba(55,55,55,0.6)',fontWeight:'600'}}>Req Date</TableCell>
                <TableCell style={{fontFamily:'Poppins',color:'rgba(55,55,55,0.6)',fontWeight:'600'}}>Balance</TableCell>
                <TableCell style={{fontFamily:'Poppins',color:'rgba(55,55,55,0.6)',fontWeight:'600'}}>Request Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(payoutList || []).map(order => (
                <TableRow hover key={order?.id}>
                  {/* <TableCell>{order?.id}</TableCell> */}
                  <TableCell>{order?.created_at}</TableCell>
                  <TableCell>{order?.balance}</TableCell>
                  <TableCell>
                    {order?.amount_requested}
                  </TableCell>
                  <TableCell>{order.fees}</TableCell>
                  <TableCell>{order.services}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {(payoutList || []).length == 0 ? (
            <img
              src={NoRecordImg}
              className={classes.notFoundImage}
              alt={'Not found!'}
            />
          ) : (
            ''
          )}
        </Box>
      </PerfectScrollbar>
      <Box display="flex" justifyContent="flex-end" p={2}>
        {/* <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text">
          View all
        </Button> */}
      </Box>
    </Card>
  )
}

RequestList.propTypes = {
  className: PropTypes.string
}

export default RequestList
