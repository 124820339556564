import React from 'react'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  Card,
  Box,
  Divider
} from '@material-ui/core'
import clsx from 'clsx'
import { NETWORK_STATUS, PAYMENTSTATUS } from '../../../../Constants'
import { ClinicStore } from '../../../../stores'
import Loading from 'react-loading'
import TablePagination from '@material-ui/core/TablePagination'
import NoRecordImg from '../../../../images/norecord.png'
import { debounce } from 'lodash'
import moment from 'moment'
import Loader from '../../../components/ClinicLoader'
import SearchIcon from '@material-ui/icons/Search'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Subscription from "../../reports/DashboardView/Subscription"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    overflow:"auto"
  },
  tablecontent: {
    '& h6': {
      fontSize: '16px',
      padding: '15px 10px'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      margin: '0px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '0px',
      fontWeight: '400'
    },
    '& .MuiTableCell-head': {
      color: '#fff'
    },
    '& .MuiTableCell-root': {
      padding: '20px 10px'
    },
    '& .MuiPaper-rounded': {
      boxShadow: 'none !important',
      borderRadius: '0px !important'
    }
  },
  Searchcol: {
    '@media (max-width:767px)': {
      // marginBottom: '15px',
      position: 'relative',
      left: '0px'
    },
    display: 'flex',
    top: '-10px',
    left: '14px',
    position: 'relative'
  },
  container: {
    maxHeight: 440,
    display: 'block',
    overflowY: 'auto',
    marginTop: '5px',
    '@media (max-width:767px)': {
      marginTop: '-20px !important'
    }
  },
  formcol: {
    '@media (max-width:767px)': {
      margin: '0px !important'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '18px',
    right: '0px'
  },

  Input: {
    width: '100%'
  },

  overflow: {
    '& .MuiTablePagination-toolbar': {
      position: 'relative',
      right: '2%'
    }
  },
  loadingSpinner: {
    position: 'absolute',
    top: '42px',
    right: '150px',
    left: '430px',
    '@media (max-width:976px)': {
      right: '5px'
    }
  }
}))

export default function SubscriptionHistory() {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [historyList, setHistorysList] = React.useState([])
  const [searchString, setSearchString] = React.useState('')


  const { SubscriptionHistory } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))

  // Store State
  const { subscriptionhistoryList, networkStatus } = ClinicStore.useStoreState(
    state => ({
      ...state.doctorDetails
    })
  )

  React.useEffect(() => {
    if (subscriptionhistoryList == undefined) {
      //
    } else {
      setHistorysList(
        subscriptionhistoryList?.data ? subscriptionhistoryList?.data : []
      )
    }
  }, [subscriptionhistoryList])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  //initial undebounced call
  React.useEffect(() => {
    SubscriptionHistory({
      pattern: searchString,
      table_config_page_no: page + 1,
      table_config_rows_per_page: rowsPerPage
    })
  }, [rowsPerPage, page])

  const debouncedGetHistoryList = React.useCallback(
    debounce(
      searchString =>
        SubscriptionHistory({
          pattern: searchString,
          table_config_page_no: 1,
          table_config_rows_per_page: rowsPerPage
        }),
      1000
    ),
    []
  )

  const onSearchUsed = e => {
    setSearchString(e.target.value)
    debouncedGetHistoryList(e.target.value)
  }

  return (
    <>
    <Subscription/>
    <br/>
    <Card className={classes.overflow}>
      <Box container>
        <h3 style={{ padding: '1rem' }}>Subscription History</h3>
      </Box>
      <Grid item xs={12} md={5}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormGroup
            aria-label="position"
            row
            style={{ width: '100%', position: 'relative' }}>
            <FormControlLabel
              className={classes.formcol}
              style={{ width: '100%' }}
              control={
                <TextField
                  className={classes.Searchinput}
                  id="standard-basic"
                  label="Search"
                  style={{ margin: '20px 0 20px 20px', width: '100%' }}
                  value={searchString}
                  onChange={onSearchUsed}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              }
            />
            {/* <FormControlLabel
              className={classes.searchicon}
              control={<FaSearch />}
            /> */}
            {networkStatus == 'FETCHING' ? (
              <Loading
                type="spin"
                textalign="center"
                color="grey"
                height={20}
                width={20}
                className={classes.loadingSpinner}
              />
            ) : null}
          </FormGroup>
        </FormControl>
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800} className="Datatable">
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>
                     ID
                  </TableCell>
                  <TableCell>
                    Clinic ID
                  </TableCell> */}
                {/* <TableCell>
                    Name
                  </TableCell> */}
                {/* <TableCell>
                    Phone Number
                  </TableCell>
                  <TableCell>
                    Email
                  </TableCell> */}
                <TableCell>Billing Start Date</TableCell>
                <TableCell>Billing End Date</TableCell>
                {/* <TableCell>
                    Billable Earnings
                  </TableCell>
                  <TableCell>
                    Total Net Earnings
                  </TableCell> */}
                <TableCell>Clinic Wallet Balance</TableCell>
                {/* <TableCell>
                    Payment Date
                  </TableCell>
                  <TableCell>
                    Payment Amount Taken
                  </TableCell>
                  <TableCell>
                    Payment Wallet
                  </TableCell>
                <TableCell>Invoice Date</TableCell> */}
                <TableCell>Invoice URL</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Subscription Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {networkStatus === NETWORK_STATUS.FETCHING ? (
                <Loading
                  type="cylon"
                  textAlign="center"
                  color="grey"
                  height={40}
                />
              ) : historyList.length !== 0 ? (
                <>
                  {historyList.map((d, index) => {
                    return (
                      <TableRow key={index} className={classes.tablerow}>
                        {/* <TableCell>{d.subscription_id}</TableCell>
                          <TableCell>{d.clinic_id}</TableCell>
                          <TableCell>{d.name}</TableCell>
                          <TableCell>{d.phone_number}</TableCell>
                          <TableCell>{d.email}</TableCell> */}
                        <TableCell>
                          {moment(d.billing_start_date).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell>
                          {moment(d.billing_end_date).format('DD-MM-YYYY')}
                        </TableCell>
                        {/* <TableCell>
                            {d.billiable_earning == ''
                              ? 'N/A'
                              : d.billiable_earning}
                          </TableCell>
                          <TableCell>
                            {d.total_net_earning == ''
                              ? 'N/A'
                              : d.total_net_earning}
                          </TableCell> */}
                        <TableCell>
                          {d.clinic_wallet_balance == ''
                            ? 'N/A'
                            : d.clinic_wallet_balance}
                        </TableCell>
                        {/* <TableCell>
                            {d.payment_date == '' ? 'N/A' : d.payment_date}
                          </TableCell> */}
                        {/* <TableCell>
                            {d.payment_amount_taken == ''
                              ? 'N/A'
                              : d.payment_amount_taken}
                          </TableCell>
                          <TableCell>
                            {d.payment_from_wallet == ''
                              ? 'N/A'
                              : d.payment_from_wallet}
                          </TableCell>
                        <TableCell>
                          {d.invoice_date == '' ? 'N/A' : d.invoice_date}
                        </TableCell> */}
                        <TableCell>
                          {d.invoice_url == '' ? 'N/A' : d.invoice_url}
                        </TableCell>
                        <TableCell>
                          {d.status == ''
                            ? 'N/A'
                            : d.status == PAYMENTSTATUS.PAID
                            ? 'Paid'
                            : 'Unpaid'}
                        </TableCell>
                        <TableCell>
                          {d.subscription_fee == ''
                            ? 'N/A'
                            : d.subscription_fee}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          {networkStatus !== NETWORK_STATUS.FETCHING &&
          historyList.length === 0 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <img
                alt="No data"
                style={{ display: 'block', width: '200px' }}
                src={NoRecordImg}
              />
            </div>
          ) : (
            ''
          )}
          {historyList ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              className={classes.overflow}
              count={
                subscriptionhistoryList?.total_rows_count
                  ? subscriptionhistoryList?.total_rows_count
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </Box>
      </PerfectScrollbar>
    </Card>
    </>
  )
}
