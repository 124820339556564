import React from 'react'
import { makeStyles, Box, Paper } from '@material-ui/core'
import { DashboardLayout } from '../../doctorLayouts'
import UpdateProfile from '../../components/DoctorProfile/UpdateProfile'

export default function Appointments() {
  const classes = useStyles()
  return (
    <DashboardLayout>
      <Box className={classes.aside}>
        <Paper elevation={3} className={classes.paper}>
          <UpdateProfile />
        </Paper>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles(theme => ({
  aside: {
    // padding: '25px 0 !important'
  },

  paper: {
    padding: '10px',
    marginTop: '20px',
    borderRadius: '10px',
    boxShadow: '0 0px 0px 0 rgba(0,0,0,0.1)',
    backgroundColor: 'transparent'
  }
}))
