import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CardBgImg from '../../images/right-top-img4.png'
import {
  IoMdPulse,
  IoIosCalendar,
  IoMdCopy,
  IoIosMedical
} from 'react-icons/io'

const CardList = ({ linkType }) => {
  const classes = useStyles()

  const openPage = page => {
    window.location.href = page
  }
  return (
    <>
      {linkType === 1 ? (
        <div
          style={{ backgroundImage: `url(${CardBgImg})#fff cover no-repeat` }}
          className={classes.checkedInPatients}
          onClick={() => openPage('/patient/vitals')}>
          <h2>
            <IoMdPulse style={{ color: '#111d4e' }} />
          </h2>
          <p>My Vitals</p>
        </div>
      ) : linkType === 2 ? (
        <div
          style={{ backgroundImage: `url(${CardBgImg})#fff cover no-repeat` }}
          className={classes.checkedInPatients}
          onClick={() => openPage('/patient/appointments')}>
          <h2>
            <IoIosCalendar style={{ color: '#111d4e' }} />
          </h2>
          <p>My Appointments</p>
        </div>
      ) : linkType === 3 ? (
        <div
          style={{ backgroundImage: `url(${CardBgImg})#fff cover no-repeat` }}
          className={classes.checkedInPatients}
          onClick={() => openPage('/patient/medications')}>
          <h2>
            <IoIosMedical style={{ color: '#111d4e' }} />
          </h2>
          <p>Medications/Labs</p>
        </div>
      ) : (
        <div
          style={{ backgroundImage: `url(${CardBgImg})#fff cover no-repeat` }}
          className={classes.checkedInPatients}
          onClick={() => openPage('/patient/documents')}>
          <h2>
            <IoMdCopy style={{ color: '#111d4e' }} />
          </h2>
          <p>Documents</p>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  todayAppointments: {
    width: '100%',
    padding: '20px',
    backgroundSize: 'cover',
    height: '160px',
    marginTop: '0px',
    borderRadius: '7px',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    '& h4': {
      fontSize: '18px',
      fontWeight: '200',
      textAlign: 'center',
      color: '#fff',
      marginTop: '15px',
      marginBottom: '10px'
    },
    '& p': {
      fontSize: '20px',
      color: '#fff',
      marginTop: '0px',
      textAlign: 'center'
    },
    '& h2': {
      fontSize: '20px',
      color: '#fff',
      marginTop: '10px',
      fontWeight: '200',
      marginBottom: '15px',
      textAlign: 'center'
    }
  },
  Online: {
    width: '50%',
    textAlign: 'center',
    '& h3': {
      fontSize: '30px',
      color: '#fff',
      marginTop: '0px',
      fontWeight: '600'
    },
    '& p': {
      fontSize: '18px',
      marginBottom: '0px'
    }
  },
  PatientSummary: {
    width: '100%',
    display: 'flex'
  },
  checkedInPatients: {
    cursor: 'pointer',
    width: '100%',
    padding: '20px',
    borderTop: '4px solid #535f8f',
    backgroundSize: 'contain',
    height: '160px',
    marginTop: '-20px !important',
    marginBottom: '15px',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    borderRadius: '7px',
    '& p': {
      fontSize: '18px',
      color: theme.palette.primary.main,
      marginTop: '-10px'
    },
    '& h2': {
      fontSize: '60px',
      color: theme.palette.primary.main,
      marginTop: '0px',
      fontWeight: '600',
      marginBottom: '0'
    }
  }
}))

export default CardList
