import React from 'react'
import { makeStyles, Box, Paper, Grid } from '@material-ui/core'
import { Layout } from '../../doctorLayouts'
import MediaBgImg from '../../images/right-top-img.jpg'
import PublicNavbar from '../../components/CommonComponents/PublicNavbar'

export default function Privacy() {
  const classes = useStyles()

  return (
    <Layout hideNavbar>
      <Grid container spacing={0} className={classes.Heademain}>
        <PublicNavbar />
        <Box className={classes.aside} style={{ padding: '40px', marginTop:'70px' }}>
          <Paper elevation={3} className={classes.paper}>
            <PrivacyPolicy />
          </Paper>
        </Box>
      </Grid>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  h4: {
    fontSize: '24px',
    marginBottom: '40px'
  },
  p: {
    fontSize: '15px',
    marginBottom: '10px'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    minHeight: '100vh',
    marginTop:'80px',
    '& .MuiTab-root': {
      fontSize: '12px',
      padding: '6px 0px'
    },
    '@media (max-width:767px)': {
      marginTop: '80px'
    }
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '15px'
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%'
  },
  plus: {
    color: '#fff',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    fontSize: '25px',
    background: '#3f4b79',
    fontWeight: 'bold',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0',
    marginRight: '7px'
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  }
}))

const PrivacyPolicy = () => {
  return (
    <div className="privacycol" style={{ marginTop:'0px'}}>
      <h4
        class="privacy-head text-center text-uppercase mb-4"
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '30px',
          marginTop: '20px'
        }}>
        Privacy Policy
      </h4>
      <p>
        <strong>Carenow Healthcare2</strong>("Carenow Healthcare", "Company",
        "We", "Our" or "Us") is the owner of www.Carenow.healthcare and the
        Carenow Healthcare mobile application (the URLs and mobile application
        together referred to as "<strong>Websites</strong>" and individually a "
        <strong>Website</strong>").Websites). The terms 'You' or 'Your' refer to
        You as the User (whether You are a Registered User or Unregistered User
        or an HCP) of the Website or of the services offered by the Website ("
        <strong>Service</strong>").
      </p>
      <p>
        Carenow Healthcare is committed to Your privacy. This privacy policy
        ("Privacy Policy") applies to the collection, use, storage, processing,
        disclosure and transfer of Your Information (defined below) when You use
        the Website and/or the Services. Please read and understand this Privacy
        Policy carefully. Your use or access of the Services shall constitute
        your agreement to this Privacy Policy. If you do not agree with our
        policies and our practices as described herein, do not use the Services
        offered by the Company.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        1. WHY THIS PRIVACY POLICY?
      </h5>
      <p>This Privacy Policy states the following:</p>
      <ul>
        <li>
          i. The type of information collected from the Users, including
          Personal Information (as defined in paragraph 4 below) and Sensitive
          Personal Data or Information (as defined in paragraph 4 below)
          relating to an individual;
        </li>
        <li>
          ii. The purpose, means and modes of collection, usage, processing,
          retention and destruction of such information; and
        </li>
        <li>
          iii. How and to whom Carenow Healthcare will disclose such
          information.
        </li>
      </ul>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        2. CONSENT
      </h5>
      <p>
        2.1. By clicking on the "I accept' button at the end of page containing
        the Terms of Use or by providing us Your Personal Information or by
        making use of the features provided by the Website, You acknowledge that
        this Privacy Policy is a part of the Terms of Use of the Website and the
        other Services, and you unconditionally agree that becoming a User of
        the Website and its Services signifies your (i) assent to this Privacy
        Policy, and (ii) consent to Company using, collecting, processing and/or
        disclosing your Personal Information in the manner and for the purposes
        set out in this Privacy Policy. Your visit to the Website and use of the
        Services is subject to this Privacy Policy and the Terms of Use.
      </p>
      <p>
        2.2. You acknowledge that You are providing Your Personal Information
        out of Your free will. You have the option not to provide us the
        Personal Information sought to be collected. You will also have an
        option to withdraw Your consent at any point, provided such withdrawal
        of consent is intimated to us in writing at help@Carenow.heathcare. If
        You do not provide us Your Personal Information or if You withdraw the
        consent at any point in time, We shall have the option not to fulfill
        the purposes for which the said Personal Information was sought and we
        may restrict You from using the Website.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        3. CHANGES TO THE PRIVACY POLICY
      </h5>
      <p>
        3.1. We may update this Privacy Policy at any time, with or without an
        advance notice. We will indicate as and when required when the Privacy
        Policy was last updated on the Website.
      </p>
      <p>
        3.2. When We update Our Privacy Policy, the next time You visit the
        Website, the Website will provide a message which will intimate You of
        the amendments to the Privacy Policy. You will be required to provide
        Your consent to the amendments before You proceed. If You do not agree
        to the amendments, please do not use the Website any further.
      </p>
      <p>
        3.3. You acknowledge and agree that it is your responsibility to review
        this privacy policy periodically and become aware of the modifications.
        Your continued use of the Website following the posting of changes to
        this policy will be deemed as your acceptance to those changes.
      </p>
      <h4
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        4. WHAT PERSONAL INFORMATION IS COLLECTED FROM YOU?
      </h4>
      <p>
        Generally some of the Services require us to know who you are so that we
        can best meet your needs. When you access the Services, or through any
        interaction with us via emails, telephone calls or other correspondence,
        we may ask you to voluntarily provide us with certain information that
        personally identifies you or could be used to personally identify you.
        You hereby consent to the collection of such information by Carenow
        Healthcare. Without prejudice to the generality of the above,
        information collected by us from you may include (but is not limited to)
        the following:
      </p>
      <ul>
        <li>i. contact data (such as your email address and phone number);</li>
        <li>
          ii. demographic data (such as your gender, your date of birth and your
          pin code);
        </li>
        <li>
          iii. data regarding your usage of the services and history of the
          appointments made by or with you through the use of Services;
        </li>
        <li>
          iv. insurance data (such as your insurance carrier and insurance
          plan);{' '}
        </li>
        <li>
          v. other information that you voluntarily choose to provide to us
          (such as information shared by you with us through emails or letters.
        </li>
      </ul>
      <p>
        The information collected from you by Carenow Healthcare may constitute
        ‘personal information’ or ‘sensitive personal data or information’ under
        the SPI Rules. “Personal Information” is defined under the SPI Rules to
        mean any information that relates to a natural person, which, either
        directly or indirectly, in combination with other information available
        or likely to be available to a body corporate, is capable of identifying
        such person. The SPI Rules further define “Sensitive Personal Data or
        Information” of a person to mean personal information about that person
        relating to:
      </p>
      <ul>
        <li>i. passwords;</li>
        <li>
          ii. financial information such as bank accounts, credit and debit card
          details or other payment instrument details;
        </li>
        <li>iii. physical, physiological and mental health condition;</li>
        <li>iv. sexual orientation;</li>
        <li>v. medical records and history;</li>
        <li>vi. biometric information;</li>
        <li>
          vii. information received by body corporate under lawful contract or
          otherwise;
        </li>
        <li>
          viii. visitor details as provided at the time of registration or
          thereafter; and
        </li>
        <li>ix. call data records.</li>
      </ul>
      <p>
        We will be free to use, collect and disclose information that is freely
        available in the public domain without your consent.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        5. HOW WE COLLECT INFORMATION?
      </h5>
      <p>
        The methods by which We collect Your Information include but are not
        limited to the following:
      </p>
      <ul>
        <li>i. When You register on Our Website,</li>
        <li>
          ii. When You create a profile on Our Website or as part of Services
        </li>
        <li>iii. When You provide Your Information to us,</li>
        <li>
          iv. When You use the features on Our Website and/or when You use
          Services,
        </li>
        <li>v. When You access Website or Services,</li>
        <li>vi. By use of cookies (discussed below).</li>
      </ul>
      <p>
        We will be free to use, collect and disclose information that is freely
        available in the public domain without your consent.
      </p>

      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        6. HOW THE INFORMATION COLLECTED IS USED?
      </h5>
      <p>
        We collect Your Information for various purposes, including the
        following:
      </p>
      <ul>
        <li>
          i. In order to enable You to use the Website and utilize the Services.
          For instance, You may provide Your health related information on the
          Website in order to consult privately with an HCP, or You may provide
          Your financial information in order to process a payment which You
          need to make on the Website.
        </li>
        <li>ii. to respond to Your inquiries;</li>
        <li>
          iii. to provide You with information about products and services
          available on the Website;
        </li>
        <li>iv. to personalize Your experience on the Website;</li>
        <li>
          v. to help You address Your problems incurred on the Website including
          addressing any technical problems;
        </li>
        <li>vi. for proper administering of the Website;</li>
        <li>
          vii. to conduct internal reviews and data analysis for the Website
          (e.g., to determine the number of visitors to specific pages within
          the Website);
        </li>
        <li>
          viii. to improve the services, content and advertising on the Website;
        </li>
        <li>ix. to protect the integrity of the Website; and</li>
        <li>
          x. to respond to judicial process and provide information to law
          enforcement agencies or in connection with an investigation on matters
          related to public safety, as permitted by law.
        </li>
        <li>
          xi. to conduct analytical studies on various aspects including user
          behavior, user preferences etc
        </li>
        <li>
          xii. to make disclosures as may be required under applicable law.
        </li>
        <li>
          xiii. to use information provided by or about the User for the
          following purposes:
          <ul>
            <li>a. Publishing such information on the Website.</li>
            <li>b. Contacting Users for offering new products or services.</li>
            <li>
              c. Contacting Users for taking product and Service feedback.
            </li>
            <li>
              d. Analyzing software usage patterns for improving product design
              and utility.
            </li>
            <li>
              e. Analyzing anonymized practice information for commercial use.
            </li>
          </ul>
        </li>
        <li>xiv. For any other purpose with your consent.</li>
      </ul>
      <p>
        above uses collectively referred to as "<strong>Purpose(s)</strong>"
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        7. DISCLOSURE OF YOUR INFORMATION
      </h5>
      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual, without restriction. We may
        disclose Personal Information or Information that we collect or you
        provide as described in this privacy policy:
      </p>
      <ul>
        <li>i. To our subsidiaries and affiliates.</li>
        <li>
          ii. To contractors, service providers, and other third parties we use
          to support our business, provide services to you and who are bound by
          contractual obligations to keep personal information confidential and
          use it only for the purposes for which we disclose it to them.
        </li>
        <li>
          iii. To a buyer or other successor in the event of a merger,
          divestiture, restructuring, reorganization, dissolution, or other sale
          or transfer of some or all of the Company’s assets, whether as a going
          concern or as part of bankruptcy, liquidation, or similar proceeding,
          in which personal information held by the Company about our Website
          users is among the assets transferred.
        </li>
        <li>
          iv. To third parties to market their products or services to you if
          you have consented to these disclosures. We contractually require
          these third parties to keep personal information confidential and use
          it only for the purposes for which we disclose it to them.
        </li>
        <li>v. To fulfill the Purpose for which you provide it.</li>
        <li>
          vi. For any other purpose disclosed by us when you provide the
          information.
        </li>
        <li>vii. With your consent.</li>
      </ul>
      <p>We may also disclose your Personal Information:</p>
      <ul class="disc-li" style={{ listStyle: 'disk' }}>
        <li>
          To comply with any court order, law, or legal process, including
          responding to any government or regulatory request.
        </li>
        <li>
          To enforce or apply our terms of use and other agreements, including
          for billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of the Company, our customers, or others.
        </li>
      </ul>

      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        8. HOW WE PROTECT YOUR INFORMATION?
      </h5>
      <p>
        8.1. The security of Your Personal Information is important to us. We
        have adopted reasonable security practices and procedure to ensure that
        the Personal Information (protected health information) collected is
        secure. You agree that such measures are secured and adequate. We
        restrict access to Your Personal Information only on a need to know
        basis to Us and Our affiliates' employees, agents, third party service
        providers, partners, and agencies who need to know such Personal
        Information in relation to the Purposes as specified above in this
        Privacy Policy and provided that such entities agree to abide by this
        Privacy Policy and confidentiality obligations.
      </p>
      <p>
        8.2 Your Personal Information is maintained by Company in electronic
        form on its equipment, and on the equipment of its employees. Such
        information may also be converted to physical form from time to time.
        Company takes all necessary precautions to protect your personal
        information both online and off-line, and implements reasonable security
        practices and measures including certain managerial, technical,
        operational and physical security control measures that are commensurate
        with respect to the information being collected and the nature of
        Company’s business.
      </p>
      <p>
        8.3 No administrator at Company will have knowledge of your password. It
        is important for you to protect against unauthorized access to your
        password, your computer and your mobile phone. Be sure to log off from
        the Website when finished. Company does not undertake any liability for
        any unauthorised use of your account and password. If you suspect any
        unauthorized use of your account, you must immediately notify Us to by
        sending an email to{' '}
        <a href="mailto:help@Carenow.healthcare">help@Carenow.healthcare</a>.
        You shall be liable to indemnify Company due to any loss suffered by it
        due to such unauthorized use of your account and password.
      </p>
      <p>
        8.4 Notwithstanding the above, We are not responsible for the
        confidentiality, security or distribution of your Personal Information
        by our partners and third parties outside the scope of our agreement
        with such partners and third parties. Further, Company shall not be
        responsible for any breach of security or for any actions of any third
        parties or events that are beyond the reasonable control of Company
        including but not limited to, acts of government, computer hacking,
        unauthorised access to computer data and storage device, computer
        crashes, breach of security and encryption, poor quality of Internet
        service or telephone service of the User etc.
      </p>

      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        9. COOKIES
      </h5>
      <p>
        We may store temporary or permanent 'cookies' on Your computer. A cookie
        is a small piece of data that is sent to Your browser from a web server
        and stored on Your computer's hard drive. A cookie cannot read data off
        Your hard disk or read cookie files created by other sites. Once You
        close Your browser, the cookie simply terminates. For instance, by
        setting a cookie on Your browser, You would not have to log in a
        password more than once, thereby saving time while on the Websites. You
        can choose whether to accept cookies by changing the settings of Your
        browser. You can reset Your browser to refuse all cookies or allow Your
        browser to show You when a cookie is being sent. If You reject the
        cookies on the websites, You may still be able to use the Websites, but
        it shall be limited to certain minimal functionality. The only drawback
        to this is that You may be limited only to some areas of the Websites or
        limited to certain functions of the Websites. Some of Our business
        partners may use cookies on the Websites. Please note that We have no
        access to or control over such cookies. Carenow Healthcare and Our
        business partners may use cookies to better personalize the content,
        banners, and promotions that You see on the Websites.
      </p>

      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        10. LINKS
      </h5>
      <p>
        The Websites may contain links to other third party sites. The third
        party sites are not under the control of Carenow Healthcare. Please note
        that Carenow Healthcare is not responsible for the privacy practices of
        such third party sites. Carenow Healthcareencourages You to be aware
        when You leave the Websites and to read the privacy policies of each and
        every third party site that collects personal information. If You decide
        to access any of the third-party sites linked to the Websites, You do
        this entirely at Your own risk. Any links to any partner of the Websites
        is the responsibility of the linking party. This Privacy Policy applies
        to Services that are owned and operated by Us. We does not exercise
        control over the sites displayed as search results or links from within
        its Services. These other sites may place their own cookies or other
        files on the Users’ computer, collect data or solicit personal
        information from the Users, for which We are not responsible or liable.
        Accordingly, Carenow Healthcare does not make any representations
        concerning the privacy practices or policies of such third parties or
        terms of use of such websites, nor does We guarantee the accuracy,
        integrity, or quality of the information, data, text, software, sound,
        photographs, graphics, videos, messages or other materials available on
        such websites. The inclusion or exclusion does not imply any endorsement
        by Us of the website, the website's provider, or the information on the
        website. If you decide to visit a third party website linked to the
        Website, you do this entirely at your own risk. Carenow Healthcare
        encourages the User to read the privacy policies of that website
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        11. CORRECTING INACCURACIES OR UPDATING PERSONAL INFORMATION
      </h5>
      <p>
        If Your Personal Information changes, or if You need to update or
        correct Your Personal Information or have any grievance with respect to
        the processing or use of Your Personal Information, for any reason, You
        may send updates and corrections to us at help@Carenow.Healthcare and We
        will take all reasonable efforts to incorporate the changes within a
        reasonable period of time. If Your Personal Information is stored as
        part of Your profile on the Website, You can update Your profile on the
        profile pages of the Websites.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        12. CHILDREN'S AND MINOR'S PRIVACY
      </h5>
      <p>
        The Website are not meant to be used by or intended for minors. The
        Company strongly advises the parents or guardians to supervise the use
        of the Website by their minor children. Any use of the Website and
        provision of Personal Information, Usage Information or other
        information to us by any minor is deemed to have been provided under due
        supervision and consent by such parents or guardians. Where the patient
        is a minor, after confirming the age, Services would be allowed only if
        the minor is consulting along-with an adult whose identity needs to be
        ascertained.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        13. PRIVACY STATEMENTS
      </h5>
      <p>This section applies to all Users:</p>
      <p>
        (a) We do not control or endorse the content, messages or information
        found in any Services and, therefore, We specifically disclaims any
        liability with regard to the Services and any actions resulting from
        your participation in any Services, and you agree that you waive any
        claims against the Company relating to same, and to the extent such
        waiver may be ineffective, you agree to release any claims against the
        Company to relating to the same.
      </p>

      <p>
        (b) If you wish to deactivate or discontinue your account or request
        that we no longer use your information to provide you Services, contact
        us through help@Carenow. Healthcare.We will retain your information for
        as long as your account with the Services is active and as needed to
        provide you the Services. We shall not retain such information for
        longer than is required for the purposes for which the information may
        lawfully be used or is otherwise required under any other law for the
        time being in force. After a period of time, your data may be anonymized
        and aggregated, and then may be held by us as long as necessary for us
        to provide our Services effectively, but our use of the anonymized data
        will be solely for analytic purposes. Please note that your withdrawal
        of consent, or cancellation / deactivation / discontinuation of account
        may result in the Company being unable to provide you with its Services
        or to terminate any existing relationship, the Company may have with
        you.
      </p>

      <p>
        (c) If you wish to opt-out of receiving non-essential communications
        such as promotional and marketing-related information regarding the
        Services, please send us an email at help@Carenow. Healthcare.
      </p>

      <p>
        (d) We may require the You to pay with a credit card, wire transfer,
        debit card or cheque for Services for which fees amount(s) is/are
        payable. We will collect such User’s credit card number and/or other
        financial institution information such as bank account numbers and will
        use that information for the billing and payment processes, including
        but not limited to the use and disclosure of such credit card number and
        information to third parties as necessary to complete such billing
        operation. Verification of credit information, however, is accomplished
        solely by the User through the authentication process. User’s
        credit-card/debit card details are transacted upon secure sites of
        approved payment gateways which are digitally under encryption, thereby
        providing the highest possible degree of care as per current technology.
        However, We provides you an option not to save your payment details.
        User is advised, however, that internet technology is not full proof
        safe and User should exercise discretion on using the same.
      </p>

      <p>
        (e) Due to the communications standards on the Internet, when a User or
        anyone who visits the Website, We automatically receives the URL of the
        site from which anyone visits. We also receives the Internet Protocol
        (IP) address of each User’s computer (or the proxy server a User used to
        access the World Wide Web), User’s computer operating system and type of
        web browser the User is using, email patterns, as well as the name of
        User’s ISP. This information is used to analyze overall trends to help
        Us improve its Service. The linkage between User’s IP address and User’s
        personally identifiable information is not shared with or disclosed to
        third parties. Notwithstanding the above, We may share and/or disclose
        some of the aggregate findings (not the specific data) in anonymized
        form (i.e., non-personally identifiable) with advertisers, sponsors,
        investors, strategic partners, and others in order to help grow its
        business.
      </p>

      <p>
        (f) You may have limited access to the Website without creating an
        account on the Website. In order to have access to all the features and
        benefits on our Website, a User must first create an account on our
        Website. To create an account, a User is required to provide the
        following information, which such User recognizes and expressly
        acknowledges is Personal Information allowing others, including Us, to
        identify the User: name, User ID, email address, country, ZIP/postal
        code, age, phone number, password chosen by the User and valid financial
        account information. Other information requested on the registration
        page, including the ability to receive promotional offers from the
        Company, is optional. We may, in future, include other optional requests
        for information from the User to help Us to customize the Website to
        deliver personalized information to the User.
      </p>

      <p>
        (g) The Website may enable You to communicate with other Users or to
        post information to be accessed by others, whereupon other Users may
        collect such data. Such Users, including any moderators or
        administrators, are not authorized Company’s representatives or agents,
        and their opinions or statements do not necessarily reflect those of
        Company, and they are not authorized to bind Company to any contract.
        Company hereby expressly disclaims any liability for any reliance or
        misuse of such information that is made available by Users or visitors
        in such a manner.
      </p>

      <p>
        (h) We do not collect information about the visitors of the Website from
        other sources, such as public records or bodies, or private
        organisations, save and except for the purposes of registration of the
        Users (the collection, use, storage and disclosure of which each User
        must agree to under the Terms of Use in order for Company to effectively
        render the Services).
      </p>

      <p>
        (i) We maintain a strict "No-Spam" policy, which means that Company does
        not intend to sell, rent or otherwise give your e-mail address to a
        third party without your consent.
      </p>

      <p>
        (j) We have implemented best market practices and security policies,
        rules and technical measures to protect the personal data that it has
        under its control from unauthorized access, improper use or disclosure,
        unauthorized modification and unlawful destruction or accidental loss.
        However, for any data loss or theft due to unauthorized access to the
        User’s electronic devices through which the User avails the Services,
        Company shall not be held liable for any loss whatsoever incurred by the
        User.
      </p>

      <p>
        (k) We have implemented reasonable security practices and procedures and
        have a comprehensive documented information security programme and
        information security policies that contain managerial, technical,
        operational and physical security control measures that are commensurate
        with respect to the information being collected and the nature of
        Company’s business.
      </p>

      <p>
        (l) We take your right to privacy very seriously and other than as
        specifically stated in this Privacy Policy, will only disclose your
        Personal Information in the event it is required to do so by law, rule,
        regulation, law enforcement agency, governmental official, legal
        authority or similar requirements or when Company, in its sole
        discretion, deems it necessary in order to protect its rights or the
        rights of others, to prevent harm to persons or property, to fight fraud
        and credit risk, or to enforce or apply the Terms of Use
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        14. COMPLIANCE{' '}
      </h5>
      <p>
        As per the Telemedicine Practice Guidelines under “Indian Medical
        Council (Professional Conduct, Etiquette and Ethics) (Amendment)
        Regulations, 2020, in the event non-compliance is noted, the Carenow
        Healthcare shall be required to report the same to Board of Governors,
        in supersession to Medical Council of India who may take appropriate
        action.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        15. GRIEVANCE OFFICER
      </h5>
      <p>
        We have appointed a Grievance Officer to address any concerns or
        grievances that You may have regarding the processing of Your Personal
        Information. If You have any such grievances, please write to Our
        Grievance Officer at help@carenow.healthcare and Our officer will
        attempt to resolve Your issues in a timely manner.
      </p>
    </div>
  )
}
