import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import api from '../../api'
import VideoChat from './VideoChat'
import swal from 'sweetalert'
import watingRoom from '../../images/waiting.png'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: '#16171b',
    margin: 0,
    padding: 0
  },
  VideoCol: {
    '& img': {
      width: '100%'
    }
  },

  VideoTab: {
    width: '98%',
    paddingLeft: '3rem',
    position: 'relative',
    '& img': {
      width: '100%'
    },
    '@media (max-width:767px)': {
      width: '100%',
      padding: '0px'
    }
  },
  VideoCol: {
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  VideoDiv: {
    marginTop: '1rem',
    marginRight: '1rem'
  },
  waiting: {
    textAlign: 'center'
  }
}))
export default function OnlineVideo(props) {
  const classes = useStyles()
  const [networkStatusText, setNetworkStatusText] = React.useState('')
  const [networkShowAlert, setNetworkShowAlert] = React.useState(false)
  const [token, setToken] = React.useState('')
  const [meetingPollingTimer, setMeetingPollingTimer] = React.useState(null)

  React.useEffect(() => {
    startMeeting()
    return () => {
      clearInterval(meetingPollingTimer)
      setMeetingPollingTimer(null)
    }
  }, [])

  async function startMeeting() {
    const response = await api.doctor.startAppointment({
      // hardcoded the appointment for now
      appointment_id: props.appointmentID
    })

    if (response.ok) {
      if (response.data.token_patient) {
        setToken(response.data.token_patient)
      } else {
        swal({
          title: 'Appointment Cannot be started',
          text: response.data.message,
          type: 'failure'
        })
        setMeetingPollingTimer(
          setInterval(() => {
            checkMeeting()
          }, 5000)
        )
      }
    }
  }

  async function checkMeeting() {
    const response = await api.doctor.startAppointment({
      appointment_id: props.appointmentID
    })

    if (response.ok) {
      if (response.data.token_patient) {
        clearInterval(meetingPollingTimer)
        setMeetingPollingTimer(null)
        setToken(response.data.token_patient)
      }
    }
  }

  const endMeet = (roomClosed, forceStop, redirect) => {
    let confirmModal = forceStop ? false : true
    if (redirect) {
      swal({
        title: 'Appointment Ended ',
        text: 'Your appointment has ended',
        type: 'success'
      })
      this.pageNavigation = setTimeout(() => {
        window.location.href = '/patient/dashboard'
      }, 5000)
    }
  }

  const displayNetworkText = networkStatusText => {
    if (!!networkStatusText) {
      setNetworkStatusText(networkStatusText)
      setNetworkShowAlert(true)
      setTimeout(() => {
        setNetworkShowAlert(false)
      }, 5000)
    } else {
      setNetworkShowAlert(false)
    }
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Box className={classes.VideoTab}>
            <div className={classes.VideoDiv}>
              {token ? (
                <VideoChat
                  token={token}
                  endSession={endMeet}
                  displayNetworkText={displayNetworkText}
                  isAudioCall={false}
                  appointment={'appointment_details'} //need to pass appointment data
                  // history={this.props.history}
                />
              ) : (
                <div className={classes.waiting}>
                  <img src={watingRoom} />
                  <p style={{ color: 'white' }}>
                    Please wait untill doctor joins the appointment
                  </p>
                </div>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
