import React from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Page from '../../../components/Page'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import superclinic from '../../../../images/image1.jpg'
import IconButton from '@material-ui/core/IconButton'
import Button from '../../../components/Button'

import {SuperClinicStore} from '../../../../stores'

const useStyles = makeStyles(() => ({
  root: {},
  errMsg: {
    color: 'red'
  },
  input: {
    display: 'none',
    margin:'0px !important',
    '&:focus':{
      borderColor:'#111d4e'
    }
  },
  MyaccountTab:{
    boxShadow:'none',
    '& .MuiTabs-flexContainer':{
      justifyContent:'space-between',
      '& button':{
        minWidth:'33%'
      }
    }
  }
  
}))
const ProfileInformation = () => {
  const classes = useStyles()
  const history = useHistory()

  const{
    editSuperClinicDetails
  }=SuperClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))

  const{
    superClinicdetails
  }=SuperClinicStore.useStoreState(state =>({
    ...state.doctorDetails
  }))
  
  return (
    <Page className={classes.root}>
      <Box className="main-content-coll">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} style={{display:'flex', paddingTop:'30px', justifyContent:'space-between', alignItems:'center'}}>  
          <h3 style={{marginBottom:'0px'}}>Profile Infomation</h3>
          <a href="" className="edit-button">Edit <i className="fa fa-pencil"></i></a>
        </Grid>
        <Grid item xs={12} sm={5}>
           <Box className="left-profile">
            <Box className="superclinic-profile-img">
              <Box className="superclinic-profile">
             <img src={superclinic} alt="logo" />
             <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
       <label htmlFor="icon-button-file" style={{position:'absolute', zIndex:'9999',left:'37%', bottom:'0px', color:'#fff'}}>
        <IconButton color="primary" aria-label="upload picture" component="span" style={{color:'#fff'}}>
        <i className="fa fa-pencil"></i>
        </IconButton>
      </label>
             </Box>
             
            </Box>
            <Box className="superclinic-details">
                <div className="form-group" style={{marginBottom:'10px'}}>
                  <p>Name</p>
                <input type="text" value="Adittya Eye Clinic" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
                <div className="form-group" style={{marginBottom:'10px'}}>
                <p>Phone</p>
                    <input type="tel" value="+91 977561702" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
                <div className="form-group" style={{marginBottom:'10px'}}>
                <p>Slug URL</p>
                    <input type="text" value="https://carenowdoc.com/" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
                <div className="form-group about-clinic">
                    <h4><b>About Clinic</b></h4>
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                    </p>
                </div>
            </Box>
            </Box>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box className="superclinic-details">
          <h4><b>Super Clinic Address Details</b></h4>
          <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{paddingBottom:'0px'}}>
              <div className="form-group">
                <p>Email ID</p>
                <input type="text" value="superclinic@carenowdoc.com" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} style={{paddingBottom:'0px'}}>
              <div className="form-group">
              <p>Phone No</p>
                <input type="tel" value="+91 9775613702" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} style={{paddingBottom:'0px'}}>
              <div className="form-group">
              <p>Address</p>
                <input type="text" value="B10/2 Golf Green Urban Complex Kolkata-700095" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} style={{paddingBottom:'0px'}}>
              <div className="form-group">
              <p>Pincode</p>
                <input type="tel" value="700095" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} style={{paddingBottom:'0px'}}>
              <div className="form-group">
              <p>Country</p>
                <input type="text" value="India" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} style={{paddingBottom:'0px'}}>
              <div className="form-group">
              <p>State</p>
                <input type="text" value="West Bengal" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
              <div className="form-group">
              <p>City</p>
                <input type="text" value="Kolkata" className="disabled" style={{margin:'0px', width:'80%'}} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} style={{ paddingBottom:'30px', display:'flex', justifyContent:'flex-end'}}>
              <Button
                variant="contained"
                style={{ padding: '0 1.5rem', height:'40px', fontSize:'12px', color:'#cd4b4b', background:'#ccc', marginRight:'15px' }}
                >Cancel</Button>
              <Button
                variant="contained"
                style={{ padding: '0 1.5rem', height:'40px', fontSize:'12px' }}
                >
                Save
              </Button>
              
              </Grid>
          </Grid>
          </Box>
        </Grid>
        </Grid>
      </Box>
    </Page>
  )
}

export default ProfileInformation
