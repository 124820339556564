import React from 'react'

import { DashboardLayout } from '../../patientLayouts'
import PatientMedications from '../../components/MyMedications/PatientMedicationsLayout'

const PatientDetailsPage = () => {
  return (
    <DashboardLayout>
      <PatientMedications />
    </DashboardLayout>
  )
}

export default PatientDetailsPage
