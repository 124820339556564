/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const viewModalLink = ({ onClick = () => {}, ...props }) => {
  return (
    <a
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
      href="javascript:void(0)"
      {...props}>
      {props.children}
    </a>
  )
}

export default viewModalLink
