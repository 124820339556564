import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  colors,
  withStyles,
  Divider
} from '@material-ui/core'

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

const confirmationModal = ({
  open = false,
  closeModal = () => {},
  headingText = 'Payment Confirmation',
  listDisplay = [
    { patientServiceId: '', totalPrice: '', nameOfTheService: '' }
  ],
  onClickingProceed = () => {}
}) => {
  let totalPriceStyle = { textAlign: 'right' }

  if (headingText === 'Payment Confirmation') {
    totalPriceStyle = { ...totalPriceStyle, color: colors.red[600] }
  } else {
    totalPriceStyle = { ...totalPriceStyle, color: colors.green[600] }
  }

  let doctorListItem = null
  let netCost = 0
  const otherServicesList = []

  listDisplay.forEach(service => {
    netCost = netCost + +service.totalPrice

    if (service.patientServiceId === '---doctor--apointment---') {
      doctorListItem = (
        <ListItem
          key={service.patientServiceId}
          service={service}
          displayDivider={listDisplay.length >= 2}
          totalPriceStyle={totalPriceStyle}
        />
      )
    } else {
      otherServicesList.push(service)
    }
  })

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{headingText}</DialogTitle>
      <DialogContent>
        <div
          style={{
            fontWeight: 'bold',
            backgroundColor: colors.grey[200],
            border: `1px solid ${colors.grey[500]}`,
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(max-content, 1fr))',
            gridColumnGap: '2rem',
            columnGap: '2rem',
            padding: '0.4rem 1rem 1rem 1rem',
            marginBottom: '-0.7rem',
            position: 'relative',
            zIndex: '1'
          }}>
          <div>Service</div>
          <div style={{ textAlign: 'right' }}>Cost (including GST.)</div>
        </div>
        <div
          style={{
            height: '10rem',
            overflowY: 'scroll',
            border: `1px solid ${colors.grey[500]}`,
            backgroundColor: '#fff',
            borderBottomRightRadius: listDisplay.length > 4 ? 0 : '0.5rem',
            borderBottomLeftRadius: '0.5rem',
            fontFamily: 'inherit',
            lineHeight: '1',
            position: 'relative',
            zIndex: '2'
          }}>
          <ul
            style={{
              listStyleType: 'none',
              margin: '0',
              padding: '0.3rem 1rem',
              fontFamily: 'inherit'
            }}>
            {doctorListItem}
            {otherServicesList.map((service, index) => (
              <ListItem
                key={service.patientServiceId}
                service={service}
                displayDivider={index < otherServicesList.length - 1}
                totalPriceStyle={totalPriceStyle}
              />
            ))}
          </ul>
        </div>
        <div
          style={{
            padding: '0.4rem 1.5rem 0 0',
            fontWeight: 'bold',
            textAlign: 'right'
          }}>
          <span style={{ paddingRight: '3rem' }}>Total (including GST)</span>
          <span style={totalPriceStyle}>
            <i class="fa fa-inr" aria-hidden="true"></i>
            <span style={{ paddingLeft: '0.3rem' }}>{netCost.toFixed(2)}</span>
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary" autoFocus>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClickingProceed()
            closeModal()
          }}
          color="primary">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default confirmationModal

function ListItem({ service, totalPriceStyle, displayDivider = false }) {
  return (
    <li
      style={{
        padding: '0',
        margin: '0',
        display: 'block',
        borderRadius: '0',
        fontFamily: 'inherit'
      }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gridColumnGap: '2rem',
          columnGap: '2rem',
          padding: '0.5rem 0.3rem'
        }}>
        <LightTooltip title={service.nameOfTheService} placement="left">
          <div
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
            {service.nameOfTheService}
          </div>
        </LightTooltip>
        <div style={totalPriceStyle}>
          <i class="fa fa-inr" aria-hidden="true"></i>
          <span style={{ paddingLeft: '0.3rem' }}>{service.totalPrice}</span>
        </div>
      </div>
      {displayDivider && <Divider />}
    </li>
  )
}
