import React from 'react'
import { makeStyles, Avatar, Paper, Box, Grid } from '@material-ui/core'
import ListBgHoverImg from '../../images/listbghover.png'
import ListBgImg from '../../images/listbg.png'
import UpdateModal from './UpdateModal'

export default function StaffListItem(props) {
  const classes = useStyles()
  const [isShow, setShow] = React.useState(false);
  const profilePopup = React.useRef(null);

  const Modal = () => {
    profilePopup.current.click();
  }

  return (
    <div>
      <Paper
        square
        className={classes.appointmentItem}
        style={{ margin: '0', marginBottom: '15px' }}>
        <Grid container className={classes.appointmentPage}>
          <Grid
            item
            md={5}
            xs={12}
            className={classes.profilebox + ' ' + classes.item}>
            <Avatar className={classes.image} alt="Image" src={props.image} />
            <div className={classes.profileinfo} style={{ textAlign: '' }}>
              <h5 style={{ marginTop: '0', marginBottom: '0px' }}>
                {props.name}
              </h5>
              <div>
                <b
                  style={{
                    float: 'left',
                    width: '100%',
                    fontSize: '12px',
                    color: '#3f4b79'
                  }}>
                  {props.degree}
                </b>
                <small
                  style={{
                    float: 'left',
                    width: '100%',
                    fontSize: '12px',
                    color: '#999'
                  }}>
                  {props.designation}
                </small>
              </div>
            </div>
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
            className={classes.info + ' ' + classes.item}>
            <Box className={classes.userinfo}>
              <div className={classes.iconContainer}>
                <i>
                  <svg
                    className={classes.icon}
                    aria-hidden="true"
                    focusable="false"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24">
                    <path
                      d="M15.5 1h-8A2.5 2.5 0 0 0 5 3.5v17A2.5 2.5 0 0 0 7.5 23h8a2.5 2.5 0 0 0 2.5-2.5v-17A2.5 2.5 0 0 0 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z"
                      fill="#3f4b79"
                    />
                    <rect
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </i>
                <b className={classes.text}>{props.mobile}</b>
              </div>
              <div className={classes.iconContainer}>
                <i>
                  <svg
                    className={classes.icon}
                    aria-hidden="true"
                    focusable="false"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24">
                    <path
                      d="M21.25 7.52l-8.574 7.6a1 1 0 0 1-1.331 0L2.703 7.56c.064-.055.131-.105.203-.152l7.453-4.872a3 3 0 0 1 3.282 0l7.453 4.872c.054.035.106.073.156.113zM22 9.69V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.698l8.014 6.916a3 3 0 0 0 3.992 0L22 9.69z"
                      fill="#3f4b79"
                    />
                    <rect
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                      fill="rgba(0, 0, 0, 0)"
                    />
                    <rect
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </i>
                <b className={classes.text}>{props.email}</b>
              </div>
            </Box>
          </Grid>

          <Grid
            item
            md={3}
            xs={12}
            className={classes.action + ' ' + classes.item}
            style={{
              display: 'grid',
              gridTemplateColumns: '80% 20%',
              gridColumnGap: '5px'
            }}>
            <div className={classes.bottomtext}>
              <label>
                <b style={{ color: '#3f4b79', paddingRight: '7px' }}>
                  <small>Doctor ID:</small>
                </b>
                <b>
                  <small>{props.doctorId}</small>
                  <small>{props.Id}</small>
                </b>
              </label>
              <br />
              <label>
                <b style={{ color: '#3f4b79', paddingRight: '7px' }}>
                  <small>Status:</small>
                </b>
                <b>
                  <small>
                    {props.status}
                    <span
                      style={{
                        background:
                          props.status === 'Active' ? '#66FF00' : 'orange',
                        marginLeft: '7px'
                      }}
                      className={classes.activeStatus}
                    />
                  </small>
                </b>
              </label>
            </div>
            <a className={classes.anchor}>
              <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" onClick={Modal}>
                <path
                  d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z"
                  fill="#fff"
                />
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  fill="rgba(0, 0, 0, 0)"
                />
              </svg>
            </a>
          </Grid>
        </Grid>
      </Paper>
      <UpdateModal ref={profilePopup} />
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0)
    }
  },
  userinfo: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px'
  },
  bottomtext: {
    '& label': {
      '@media (max-width:767px)': {
        marginRight: '20px'
      }
    },
    '@media (max-width:767px)': {
      display: 'flex'
    }
  },
  image: {
    width: '60px',
    height: '60px',
    objectFit: 'cover'
  },
  name: {
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.primary.dark,
    textAlign: 'center'
  },
  profilebox: {
    display: 'grid',
    gridTemplateColumns: '75px 65%'
  },
  // tag: {
  //     color: '#fff',
  //     width: '120px',
  //     padding: '0px 12px',
  //     background: '#444',
  //     textAlign: 'center',
  //     fontWeight: '100',
  //     borderRadius: '25px',
  //     display: 'flex',
  //     justifyContent: 'center',
  //     fontSize: '12px',
  // },
  appointmentItem: {
    padding: '7px',
    borderRadius: '5px',
    position: 'relative',
    width: '100%',
    background: `url(${ListBgImg}) center top no-repeat`,
    padding: '10px',
    borderRadius: '20px',
    backgroundSize: 'cover',
    display: 'flex',
    marginBottom: '15px',
    flexWrap: 'wrap',
    boxShadow: 'none',
    '&:hover p': {
      color: '#fff'
    },
    '&:hover h5': {
      color: '#fff'
    },
    '&:hover b': {
      color: '#fff !important'
    },
    '&:hover h5 a': {
      color: '#fff !important'
    },
    '&:hover p span': {
      color: '#fff'
    },
    '@media (max-width:767px)': {
      flexDirection: 'column',
      alignItems: 'center'
    },
    '&:hover': {
      background: `url(${ListBgHoverImg}) center top no-repeat`,
      backgroundSize: 'cover',
      color: '#fff'
    },
    '@media (max-width:767px)': {
      padding: '15px'
    }
  },
  profileinfo: {
    '@media (max-width:767px)': {
      marginLeft: '20px'
    }
  },
  icon: {
    width: '1.5em',
    height: '1.5em',
    position: 'relative',
    top: '3px'
  },
  item: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media (max-width:767px)': {
      justifyContent: 'flex-start',
      display: 'flex'
    }
  },
  icon: {
    '@media (max-width:767px)': {
      marginRight: '10px'
    }
  },
  iconContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '25px 70%',
    '@media (max-width:767px)': {
      marginRight: '20px',
      marginTop: '15px'
    }
  },
  anchor: {
    background: '#3f4b79',
    color: '#fff',
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    cursor: "pointer",
    '@media (max-width:767px)': {
      position: 'absolute',
      right: '15px',
      top: '20px'
    }
  },
  text: {
    fontWeight: '500',
    fontSize: '12px'
  },
  activeStatus: {
    display: 'inline-block',
    height: '8px',
    width: '8px',
    borderRadius: '50%'
  }
}))
