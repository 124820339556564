import React, { useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  makeStyles,
  Link,
  Typography,
  Button,
  Avatar,
  Grid,
  Box,
  MenuItem,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  Radio,
  RadioGroup,
  InputLabel
} from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'
import PrimaryInputField from '../../components/CommonComponents/PrimaryInputField'
import NoRecordImg from '../../images/norecord.png'
import MediaBgImg from '../../images/right-top-img.jpg'
import StaffListItem from './StaffListItem'

export default function StaffListDoctors() {
  const classes = useStyles()
  const { control, handleSubmit, errors, register } = useForm()
  const [value, setValue] = React.useState('female')
  const handleChange = event => {
    setValue(event.target.value)
  }
  const [status2, setAge] = React.useState('')
  const [Image, previewImage] = React.useState(null)
  const [file, setFile] = React.useState('')

  const statushandleChange = event => {
    setAge(event.target.value)
  }
  const [addNewActive, setAddNewState] = React.useState(false)
  const Addnew = () => setAddNewState(o => !o)
  const data = [
    {
      id: 1,
      name: 'Bruce Wayne',
      degree: 'MBBS',
      designation: 'General Physician',
      mobile: '9239510870',
      email: 'wayne@mail.com',
      doctorId: 'DC1',
      image:
        'https://images.unsplash.com/photo-1542410613-d073472c3135?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
      status: 'Active'
    },
    {
      id: 2,
      name: 'John Wick',
      degree: 'MBBS',
      designation: 'Neuro Surgeon',
      mobile: '9999999990',
      email: 'wick@mail.com',
      doctorId: 'D99900',
      image:
        'https://images.unsplash.com/photo-1560354508-468e7201bbc2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1494&q=80',
      status: 'Inactive'
    }
  ]
  const hiddenFileInput = useRef()

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const imageChange = event => {
    event.preventDefault()
    let reader = new FileReader()
    let fileimage = event.target.files[0]

    reader.onloadend = () => {
      setFile(fileimage)
      previewImage(reader.result)
    }
    reader.readAsDataURL(fileimage)
  }

  const onSubmit = formData => {
  }

  const customStyles = {
    browse_button: {
      height: '100%',
      position: 'absolute',
      top: '0',
      right: '0',
      zIndex: '99',
      cursor: 'pointer',
      display: 'none',
      /*This makes the button huge. If you want a bigger button, increase the font size*/
      fontSize: '50px',
      /*Opacity settings for all browsers*/
      opacity: '0',
      mozOpacity: '0',
      msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {addNewActive ? (
        <>
          {/* ADD NEW DOCTOR FORM GOES HERRE */}
          <div
            style={{ width: '100%', padding: '20px 40px' }}
            className={classes.MainContent}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.UserId}>
                        <Controller
                          as={PrimaryInputField}
                          name="user_id"
                          control={control}
                          defaultValue=""
                          placeholder="User ID"
                          rules={{ required: 'User ID is required' }}
                        />
                        {errors.user_id ? (
                          <Typography color={'error'}>
                            {errors.user_id?.message}
                          </Typography>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        as={PrimaryInputField}
                        name="firstname"
                        control={control}
                        defaultValue=""
                        placeholder="First Name"
                        rules={{ required: 'First Name is required' }}
                      />
                      {errors.firstname ? (
                        <Typography color={'error'}>
                          {errors.firstname?.message}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        as={PrimaryInputField}
                        name="middlename"
                        control={control}
                        defaultValue=""
                        placeholder="Middle Name"
                        rules={{ required: 'Middle Name is required' }}
                      />
                      {errors.user_id ? (
                        <Typography color={'error'}>
                          {errors.middlename?.message}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        as={PrimaryInputField}
                        name="lastname"
                        control={control}
                        defaultValue=""
                        placeholder="Last Name"
                        rules={{ required: 'Last Name is required' }}
                      />
                      {errors.lastname ? (
                        <Typography color={'error'}>
                          {errors.lastname?.message}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {/* <TextField
                        id="date"
                        label="Birthday"
                        type="date"
                        defaultValue="2017-05-24"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                      /> */}
                      <Controller
                        as={PrimaryInputField}
                        type="date"
                        name="dob"
                        control={control}
                        defaultValue=""
                        placeholder="Date of Birth"
                        rules={{ required: 'Date of Birth is required' }}
                      />
                      {errors.dob ? (
                        <Typography color={'error'}>
                          {errors.dob?.message}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          defaultValue="male">
                          <FormControlLabel
                            value="male"
                            control={<Radio color="primary" />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio color="primary" />}
                            label="Female"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        as={PrimaryInputField}
                        name="phonenumber"
                        control={control}
                        defaultValue=""
                        placeholder="Phone Number"
                        rules={{ required: 'Phone Number is required' }}
                      />
                      {errors.phonenumber ? (
                        <Typography color={'error'}>
                          {errors.phonenumber?.message}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        as={PrimaryInputField}
                        name="email"
                        control={control}
                        defaultValue=""
                        placeholder="Email"
                        rules={{ required: 'Email is required' }}
                      />
                      {errors.email ? (
                        <Typography color={'error'}>
                          {errors.email?.message}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        as={PrimaryInputField}
                        name="address"
                        control={control}
                        defaultValue=""
                        placeholder="Address"
                        rules={{ required: 'Address is required' }}
                      />
                      {errors.address ? (
                        <Typography color={'error'}>
                          {errors.address?.message}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        as={PrimaryInputField}
                        name="department"
                        control={control}
                        defaultValue=""
                        placeholder="Department"
                        rules={{ required: 'Department is required' }}
                      />
                      {errors.department ? (
                        <Typography color={'error'}>
                          {errors.department?.message}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="status"
                        id="demo-simple-select"
                        onChange={handleChange}>
                        <MenuItem value={10}>Active</MenuItem>
                        <MenuItem value={20}>Inactive</MenuItem>
                        <MenuItem value={30}>On Hiatus</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.FileUpload}>
                        <DropzoneArea
                          acceptedFiles={[
                            'image/jpeg',
                            'image/png',
                            'image/bmp'
                          ]}
                          showPreviews={false}
                          maxFileSize={5000000}
                          className={classes.dropzone}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.BtnCol}>
                      <button
                        className={classes.CancelBtn}
                        onClick={() => setAddNewState(false)}>
                        Cancel
                      </button>
                      <button className={classes.AccountBtn} type="submit">
                        Create Account
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    style={{
                      width: '140px',
                      height: '140px',
                      background: 'white',
                      borderRadius: '50%',
                      boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
                              0 2px 2px rgba(0,0,0,0.12), 
                              0 4px 4px rgba(0,0,0,0.12), 
                              0 8px 8px rgba(0,0,0,0.12),
                              0 16px 16px rgba(0,0,0,0.12)`,
                      margin: '25px auto',
                      textAlign: 'right',
                      padding: '10px',
                      display: 'flex'
                    }}>
                    <Avatar className={classes.AvatarCol} src={Image} />
                  </Box>
                  <label
                    style={{
                      textAlign: 'center',
                      margin: '20px auto',
                      display: 'block',
                      fontWeight: 'bold',
                      width:'150px'
                    }}>
                    <div>
                      <Button
                        onClick={handleClick}
                        style={{
                          width: '140px',
                          borderRadius: '50px',
                          background: '#3f4b79',
                          border: 'none',
                          height: '40px',
                          textAlign: 'center',
                          color: '#fff',
                          cursor: 'pointer'
                        }}>
                        Edit Picture
                      </Button>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        accept=" image/jpeg, image/png"
                        ref={hiddenFileInput}
                        style={customStyles.browse_button}
                        onChange={imageChange}
                        className="form-control"
                      />
                    </div>
                  </label>
                </Grid>
              </Grid>
            </form>
          </div>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12} md={9}>
              {data.length ? (
                <>
                  {data.map((d, index) => {
                    return (
                      <StaffListItem
                        image={d.image}
                        name={d.name}
                        degree={d.degree}
                        designation={d.designation}
                        mobile={d.mobile}
                        email={d.email}
                        doctorId={d.doctorId}
                        status={d.status}
                        key={index}
                      />
                    )
                  })}
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <img
                      alt="No data"
                      style={{ display: 'block', width: '200px' }}
                      src={NoRecordImg}
                    />
                  </div>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.addNewContainer}>
                <Box boxShadow={4} className={classes.addNew} onClick={Addnew}>
                  <Link to="/doctor/addnewdoctor">
                    <b className={classes.plus}>
                      <span>+</span>Add New Doctor
                    </b>
                  </Link>
                </Box>
                <div className={classes.media}>
                  <h1 style={{ color: '#fff' }}>{data.length}</h1>
                  <p>Total Active Doctors</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  UserPicture: {
    width: '200px',
    height: '200px',
    background: 'rgba(0,0,0,0.1)',
    borderRadius: '50%',
    position: 'relative',
    margin: '20px auto',
    textAlign: 'center',
    '& img': {
      width: '92%',
      padding: '20px'
    }
  },
  InputFile2: {
    position: 'absolute',
    bottom: '20px',
    left: '45%',
    width: '50px',
    opacity: '0',
    zIndex: '99'
  },
  CameraIcon: {
    position: 'absolute',
    bottom: '20px',
    left: '45%',
    fontSize: '30px',
    color: '#fff'
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  BtnCol: {
    marginTop: '40px'
  },
  UserId: {
    width: '32%'
  },
  MainContent: {
    '@media (max-width:767px)': {
      padding: '20px'
    },
    '& .MuiInputBase-root': {
      width: '100%'
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  AddIcon: {
    marginRight: '10px'
  },
  FileUpload: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer'
  },
  InputFile: {
    opacity: '0',
    position: 'absolute'
  },
  input: {
    width: '100%'
  },
  UploadBtn: {
    width: '130px',
    background: '#3f4b79',
    textAlign: 'center',
    lineHeight: '40px',
    borderRadius: '50px',
    color: '#fff',
    border: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 15px',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  CancelBtn: {
    width: '150px',
    background: '#ccc',
    textAlign: 'center',
    lineHeight: '40px',
    borderRadius: '50px',
    color: '#3f4b79',
    border: 'none',
    marginRight: '15px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  AccountBtn: {
    width: '150px',
    background: '#3f4b79',
    textAlign: 'center',
    lineHeight: '40px',
    borderRadius: '50px',
    color: '#fff',
    border: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  plus: {
    display: 'flex',
    '& span': {
      color: '#fff',
      width: '25px',
      height: '25px',
      display: 'flex',
      fontSize: '25px',
      background: '#3f4b79',
      fontWeight: 'bold',
      justifyContent: 'center',
      borderRadius: '50%',
      alignItems: 'center',
      padding: '0',
      marginRight: '7px'
    }
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    backgroundSize: 'cover',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%',
    '@media (max-width:767px)': {
      marginTop: '20px'
    }
  },
  dropzone: {
    height: '50px',
    width: '50px'
  }
}))
