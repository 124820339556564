import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import MoneyIcon from '@material-ui/icons/Money'
import RuppeeIcon from '../../../../images/rupee.png'
import wallet from '../../../../images/wallet.svg'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow:'0 1px 8px 0 rgba(0,0,0,0.1)',
    borderRadius:'10px',
    '& .MuiCardContent-root:last-child':{
      paddingBottom:'0px !important'
    }
  },
  avatar: {
    backgroundColor: '#111d4e',
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}))

const Budget = ({ className, wallet_balance, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest} style={{paddingBottom:'0px !important',height:'145px'}}>
      <CardContent style={{paddingBottom:'0px !important'}}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Box style={{ display:'flex', alignItems:'center', marginBottom:'10px'}}>
              <img style={{marginRight:'15px', width:'20px'}} src={wallet} alt="wallet" />
              <h4 style={{margin:'0px', fontWeight:'600'}}>Wallet Balance</h4>
            </Box>
            <Typography color="textPrimary" variant="h5">
            <i class="fa fa-inr"></i>{' '}
            {wallet_balance ? wallet_balance : '0'}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={1} display="flex" alignItems="center">
          <Typography color="textSecondary" variant="caption">
            ₹ 0 will be held from your wallet (Subscription cost)
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

Budget.propTypes = {
  className: PropTypes.string
}

export default Budget
