import React, { useEffect, useState } from 'react'
import {
  Paper,
  Modal,
  Backdrop,
  makeStyles,
  Avatar,
  Box,
  Grid,
  Typography,
  Button,
  Fade
} from '@material-ui/core'
import { PatientStore } from '../../stores'
import PatientVisitSummary from './PatientVisitSummary'
import { CONSULTATION_TYPE, PATIENT_ID, PATIENT_USER_ID } from '../../Constants'
import moment from 'moment'
import { useParams, Link } from 'react-router-dom'
import ProfileImg from '../../images/profile-img.png'
import ListBgHoverImg from '../../images/right-top-img3.png'

const PatientDetails = props => {
  const params = useParams()
  const classes = useStyles()
  const [meetingEnabled, setMeetingEnabled] = useState(false)
  const [selectedAppointment, setSelectedAppointment] = useState([])
  const { viewVisitSummary, getSummary } = PatientStore.useStoreActions(
    actions => ({
      ...actions.patients
    })
  )
  const { viewSummary } = PatientStore.useStoreState(state => ({
    ...state.patients
  }))

  const { getList } = PatientStore.useStoreActions(actions => ({
    ...actions.appointments
  }))

  const booking_status = [
    {
      id: '1',
      label: 'Booked'
    },
    {
      id: '2',
      label: 'Confirmed'
    },
    {
      id: '3',
      label: 'Checked in'
    },
    {
      id: '4',
      label: 'In-Progress'
    },
    {
      id: '5',
      label: 'Cancelled'
    },
    {
      id: '6',
      label: 'No Show'
    },
    {
      id: '7',
      label: 'Completed'
    },
    {
      id: '8',
      label: 'Rescheduled'
    }
  ]

  useEffect(() => {
    viewVisitSummary({
      user_id: localStorage.getItem(PATIENT_USER_ID), //'2a717c90-44d2-4fb4-b17e-a3ca0b21931f',
      patient_id: localStorage.getItem(PATIENT_ID), //'eef21d7d-c0e8-4ab0-ad6d-dd9f528af102',
      view_type: 'view',
      appointment_id: props.appointmentId
    })
  }, [])

  useEffect(() => {
    if (selectedAppointment.length !== 0) {
      setInterval(() => {
        const momentStartTime = moment(
          selectedAppointment.start_time +
            ' ' +
            selectedAppointment.appointment_date
        )
        const momentEndTime = moment(
          selectedAppointment.end_time +
            ' ' +
            selectedAppointment.appointment_date
        )

        if (moment().isBetween(momentStartTime, momentEndTime)) {
          setMeetingEnabled(true)
        } else {
          setMeetingEnabled(false)
        }
      }, 1000)
    }
  }, [selectedAppointment])

  useEffect(() => {
    setSelectedAppointment(viewSummary)
  }, [viewSummary])

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const joinConsult = isAudioCall => {
    let audioStatus = isAudioCall ? isAudioCall : false
    localStorage.setItem('audio_call', audioStatus)
  }

  return (
    <Box className={classes.ContentCol}>
      <Box className={classes.PatientInfoCol}>
        <Box className={classes.PatientTop}>
          {selectedAppointment ? (
            <Paper elevation={3} className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} className={classes.Patientleftcol}>
                  <Box className={classes.PatientInfo}>
                    <Box className={classes.PatientData}>
                      <Typography component="h2">
                        Appointment Number:
                        <span>
                          {selectedAppointment ? (
                            <>{selectedAppointment.appointment_number}</>
                          ) : (
                            <>---</>
                          )}
                        </span>
                      </Typography>
                    </Box>
                    <Box className={classes.PatientData}>
                      <Typography component="h2">
                        Appointment Date
                        <span>
                          {selectedAppointment &&
                          selectedAppointment.appointment_date ? (
                            <>
                              {moment(
                                selectedAppointment.appointment_date
                              ).format('DD-MM-YYYY')}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </span>
                      </Typography>
                    </Box>

                    <Box className={classes.PatientData}>
                      <Typography component="h2">
                        Appointment Time
                        <span>
                          {selectedAppointment &&
                          selectedAppointment.start_time &&
                          selectedAppointment.end_time ? (
                            <>
                              {moment(
                                selectedAppointment.start_time,
                                'hh:mm:ss'
                              ).format('hh:mm A')}{' '}
                              -
                              {moment(
                                selectedAppointment.end_time,
                                'hh:mm:ss'
                              ).format('hh:mm A')}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </span>
                      </Typography>
                    </Box>
                    <Box className={classes.PatientData}>
                      <Typography component="h2">
                        Status{' '}
                        <span>
                          {selectedAppointment && selectedAppointment.status ? (
                            booking_status.find(
                              option => option.id === selectedAppointment.status
                            ).label
                          ) : (
                            <>---</>
                          )}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.PatientInfo}>
                    <Box className={classes.PatientData}>
                      <Typography
                        component="h2"
                        style={{ marginBottom: '0px' }}>
                        Appointment Type
                        <span>
                          <Button
                            variant="contained"
                            className={classes.OnlineBtn}>
                            {selectedAppointment &&
                            selectedAppointment.appointment_type ===
                              CONSULTATION_TYPE.IN_PERSON.value
                              ? CONSULTATION_TYPE.IN_PERSON.label
                              : CONSULTATION_TYPE.TELE.label}
                          </Button>
                        </span>
                      </Typography>
                    </Box>
                    {selectedAppointment &&
                    selectedAppointment.appointment_type ===
                      CONSULTATION_TYPE.TELE.value ? (
                      selectedAppointment.status !== '3' ? (
                        <Box className={classes.BtnRow}>
                          <button
                            className={
                              (selectedAppointment.status === '2' ||
                                selectedAppointment.status === '3' ||
                                selectedAppointment.status === '4') &&
                              meetingEnabled
                                ? classes.TeleBtn
                                : classes.DisabledTeleBtn
                            }
                            onClick={handleOpen}
                            disabled={
                              (selectedAppointment.status !== '2' &&
                                selectedAppointment.status !== '3' &&
                                selectedAppointment.status !== '4') ||
                              !meetingEnabled
                            }>
                            Start Tele-Consult with Doctor
                          </button>
                        </Box>
                      ) : null
                    ) : (
                      ''
                    )}
                  </Box>
                </Grid>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500
                  }}>
                  <Fade in={open}>
                    <div className={classes.ModalBody}>
                      <button
                        className={classes.CloseBtn}
                        onClick={handleClose}>
                        X
                      </button>
                      <h2 id="transition-modal-title">
                        You're about to join a tele-consult with doctor
                      </h2>
                      <h4>
                        <span>
                          {selectedAppointment &&
                          selectedAppointment?.doctor?.name ? (
                            <>{selectedAppointment?.doctor?.name}</>
                          ) : (
                            <>---</>
                          )}{' '}
                        </span>
                      </h4>
                      <h5>Scheduled on</h5>
                      <h6>
                        <p>
                          Date:{' '}
                          {selectedAppointment &&
                          selectedAppointment.appointment_date ? (
                            <>
                              {moment(
                                selectedAppointment.appointment_date
                              ).format('DD-MM-YYYY')}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </p>
                        <p>
                          Time:{' '}
                          {selectedAppointment &&
                          selectedAppointment.start_time &&
                          selectedAppointment.end_time ? (
                            <>
                              {moment(
                                selectedAppointment.start_time,
                                'hh:mm:ss'
                              ).format('hh:mm A')}{' '}
                              -
                              {moment(
                                selectedAppointment.end_time,
                                'hh:mm:ss'
                              ).format('hh:mm A')}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </p>
                      </h6>
                      <Link
                        to={{
                          pathname:
                            '/patient/doctor/teleconsult' +
                            '/' +
                            props.appointmentId
                        }}>
                        <button
                          className={classes.JoinBtn}
                          onClick={() => joinConsult(false)}>
                          Join with Video
                        </button>
                      </Link>
                      <Link
                        to={{
                          pathname:
                            '/patient/doctor/teleconsult' +
                            '/' +
                            props.appointmentId
                        }}>
                        <button
                          className={classes.JoinBtn}
                          onClick={() => joinConsult(true)}>
                          Join with Audio
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </Modal>
                <Grid item xs={12} md={4} className={classes.Patientrightcol}>
                  <Box className={classes.PatientInfoRight}>
                    <Box className={classes.PatientStatus}>
                      <Typography component="h3">
                        {selectedAppointment?.doctor?.name}
                      </Typography>
                      <Typography component="h3">
                        {selectedAppointment?.doctor?.qualification}
                      </Typography>
                    </Box>
                    <Box
                      className={
                        selectedAppointment.appointment_type ===
                        CONSULTATION_TYPE.TELE.value
                          ? classes.PatientProfile
                          : classes.PatientProfileWithoutButton
                      }>
                      <Avatar
                        className={classes.AvatarCol}
                        src={
                          selectedAppointment &&
                          selectedAppointment?.doctor?.profile_photo
                            ? selectedAppointment?.doctor?.profile_photo
                            : ProfileImg
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            'Loading...'
          )}
          <PatientVisitSummary
            appointmentId={props.appointmentId}
            visitSummary={viewSummary}
            appointmentDate={selectedAppointment.appointment_date}
            status={selectedAppointment.status}
          />
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '15px',
    float: 'left',
    width: '100%',
    padding: '15px',
    background: `url(${ListBgHoverImg}) center top no-repeat`,
    backgroundSize: 'cover'
  },
  vitals: {
    float: 'left',
    width: '100%',
    fontWeight: 'normal'
  },
  dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  AvatarCol: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  CloseBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: '#3f4b79',
    fontSize: '20px',
    '&:hover': {
      color: '#000'
    }
  },
  JoinBtn: {
    width: '250px',
    height: '50px',
    background: '#3f4b79',
    borderRadius: '40px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    border: 'none',
    display: 'block',
    margin: '15px auto',
    '@media (max-width:767px)': {
      width: '100%'
    },
    '&:hover': {
      backgroundColor: '#000',
      cursor: 'pointer'
    }
  },
  BtnRow: {
    width: 'auto'
  },
  TeleBtn: {
    border: 'none',
    background: '#9138aa',
    borderRadius: '40px',
    padding: '10px 20px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  DisabledTeleBtn: {
    border: 'none',
    background: '#ADADAD',
    borderRadius: '40px',
    padding: '10px 20px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999',
    '& .makeStyles-modal-141': {
      zIndex: '999999999'
    }
  },
  ModalBody: {
    width: '600px',
    backgroundColor: '#fff',
    padding: '20px',
    textAlign: 'center',
    position: 'relative',
    '@media (max-width:767px)': {
      width: '98%'
    },
    '& h2': {
      fontWeight: 'bold',
      fontSize: '20px',
      '@media (max-width:767px)': {
        fontSize: '16px',
        padding: '0 10%'
      }
    },
    '& h5': {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#000',
      '@media (max-width:767px)': {
        fontSize: '16px'
      }
    },
    '& h6': {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#3f4b79',
      marginBottom: '30px',
      '@media (max-width:767px)': {
        fontSize: '16px'
      }
    },
    '& h4': {
      fontSize: '24px',
      color: '#3f4b79',
      fontWeight: 'bold',
      '@media (max-width:767px)': {
        fontSize: '20px'
      }
    }
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },
  Patientleftcol: {
    '@media (max-width:767px)': {
      order: '2'
    }
  },
  Patientrightcol: {
    '@media (max-width:767px)': {
      order: '1'
    }
  },
  dashboard: {
    maxWidth: '75%',
    flexBasis: '75%',
    '@media (max-width:1024px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '35px'
    }
  },
  AreaChart: {
    '& svg rect': {
      fill: '#fff'
    }
  },
  ContentCol: {
    width: '100%',
    borderRadius: '5px',
    padding: '15px',
    marginTop: '0',
    display: 'flex',
    flexDirection: 'column'
  },
  PatientTop: {
    backgroundColor: '#f7f6f6',
    margin: '0 0 15px',
    display: 'flex',
    flexDirection: 'column'
  },
  PatientInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '15px'
  },
  PatientData: {
    paddingRight: '20px',
    '& h2': {
      color: '#fff',
      marginBottom: '15px',
      fontSize: '14px',
      '& span': {
        color: '#fff',
        display: 'block'
      }
    }
  },
  VisitedBtn: {
    textAlign: 'center',
    backgroundColor: '#373737',
    borderRadius: '40px',
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#3f4b79',
      boxShadow: 'none'
    }
  },
  OnlineBtn: {
    textAlign: 'center',
    cursor: 'default',
    backgroundColor: '#98e039',
    borderRadius: '40px',
    color: '#fff',
    boxShadow: 'none',
    padding: '3px 30px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#98e039',
      boxShadow: 'none'
    }
  },
  PatientInfoRight: {
    display: 'flex',
    alignItems: 'center',
    lineBreak: 'anywhere'
  },
  PatientStatus: {
    marginRight: '20px',
    '@media (max-width:767px)': {
      order: '2',
      marginRight: '0px',
      marginLeft: '30px'
    },
    '& h3': {
      fontSize: '16px',
      fontWeight: '700',
      color: '#fff',
      '& span': {
        color: '#d5d9ea',
        display: 'block'
      }
    }
  },
  PatientProfile: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    backgroundColor: '#fff0',
    padding: '5px',
    '&>div': {
      width: '96px',
      height: '96px',
      border: '4px solid #f8f8f8',
      '@media (max-width:767px)': {
        width: '96px',
        height: '96px'
      }
    },
    '& img': {
      width: '100%'
    }
  },
  PatientProfileWithoutButton: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    padding: '5px',
    flex: '0 0 100px',

    '& img': {
      width: '100%'
    }
  },
  TabBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '& button': {
      backgroundColor: '#f7f6f6',
      marginRight: '15px',
      textTransform: 'capitalize',
      color: '#3f4b79'
    }
  },
  PatientChart: {
    display: 'flex',
    marginBottom: '20px',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      marginRight: '30px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  GrapTop: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexWrap: 'wrap'
    },
    '& h4': {
      fontSize: '14px',
      color: '#414141',
      '& span': {
        fontSize: '12px',
        color: '#999'
      }
    }
  },
  BtnCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '75%'
  },
  ToggleBtn: {
    textTransform: 'capitalize',
    '& button': {
      textTransform: 'capitalize',
      padding: '3px 15px'
    }
  },
  ActiveBtn: {
    backgroundColor: '#eff7ff !important',
    borderColor: '#3d8ff7 !important',
    color: '#3d8ff7'
  },
  ChartBox: {
    backgroundColor: '#fff',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    borderRadius: '5px',
    marginBottom: '15px'
  },
  submitButton: {
    color: '#fff',
    borderRadius: '25px'
  }
}))

export default PatientDetails
