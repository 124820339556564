import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  TextField,
  makeStyles
} from '@material-ui/core'
import { NETWORK_STATUS } from '../../Constants'
import { DoctorStore } from '../../stores'
import NoRecordImg from '../../images/norecord.png'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import Loader from '../CommonComponents/Loader'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  modalView: {
    width: '50rem !important'
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px',
    marginTop: '70px'
  }
}))

const Services = ({
  className,
  dialogOpen,
  handleClose,
  patientId,
  appointmentId,
  ...rest
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [optionsList, setOptionsList] = useState([])

  const {
    getClinicServices,
    listPatientServices,
    addPatientServices,
    deletePatientServices
  } = DoctorStore.useStoreActions(action => ({
    ...action.patients
  }))

  const {
    networkStatus,
    servicesList,
    servicesTakenList
  } = DoctorStore.useStoreState(state => ({
    ...state.patients
  }))

  useEffect(() => {
    getClinicServices({})
    listPatientServices({ appointment_id: appointmentId })
  }, [])

  useEffect(() => {
    const uniqueList = servicesList.filter(
      ({ service_name: id1 }) =>
        !servicesTakenList.some(({ service_name: id2 }) => id1 === id2)
    )
    setOptionsList(uniqueList)
  }, [servicesList, servicesTakenList])

  const addService = (serviceId, serviceStatus) => {
    addPatientServices({
      service_status: serviceStatus,
      service_master_id: serviceId,
      appointment_id: appointmentId,
      patient_id: patientId
    }).then(data => {
      if (data) {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('success')
        listPatientServices({ appointment_id: appointmentId })
      } else {
        setOpen(true)
        setSnackBarMessage('Service not moved')
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const deleteService = item => {
    deletePatientServices({
      patient_service_id: item.patient_service_id
    }).then(data => {
      if (data) {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('success')
        listPatientServices({ appointment_id: appointmentId })
      } else {
        setOpen(true)
        setSnackBarMessage('Service not deleted')
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const recommendedServiceChange = (e, value, reason) => {
    if (reason == 'select-option') {
      let serviceID
      let idValue = value.map(item => {
        serviceID = item.id
      })
      addService(serviceID, '2')
    } else if (reason == 'remove-option') {
      let valueList = servicesTakenList.filter(
        item => item.service_status === '2'
      )
      const removedItem = valueList.filter(
        ({ patient_service_id: id1 }) =>
          !value.some(({ patient_service_id: id2 }) => id1 === id2)
      )
      deleteService(removedItem[0])
    }
  }

  const takenServiceChange = (e, value, reason) => {
    if (reason == 'select-option') {
      let serviceID
      let idValue = value.map(item => {
        serviceID = item.id
      })
      addService(serviceID, '1')
    } else if (reason == 'remove-option') {
      let valueList = servicesTakenList.filter(
        item => item.service_status === '1'
      )
      const removedItem = valueList.filter(
        ({ patient_service_id: id1 }) =>
          !value.some(({ patient_service_id: id2 }) => id1 === id2)
      )
      deleteService(removedItem[0])
    }
  }

  return (
    <>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={() => setOpen(false)}
      />
      <Dialog
        style={{ maxWidth: '100% !important' }}
        open={dialogOpen}
        classes={{ paper: classes.modalView }}
        onClose={() => handleClose()}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          Services
        </DialogTitle>
        <DialogContent className="service-popup">
          <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader className="card-title" title="Services Taken" />
            <Divider />
            <Autocomplete
              multiple
              id="tags-standard"
              options={optionsList}
              onChange={takenServiceChange}
              getOptionLabel={option => option.service_name}
              value={servicesTakenList.filter(
                item => item.service_status === '1'
              )}
              disableClearable={true}
              renderInput={params => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Card>

          <Card
            className={clsx(classes.root, className)}
            {...rest}
            style={{ marginTop: '10px' }}>
            <CardHeader className="card-title" title="Recommended Services" />
            <Divider />
            <Autocomplete
              multiple
              id="tags-standard"
              options={optionsList}
              onChange={recommendedServiceChange}
              disableClearable={true}
              getOptionLabel={option => option.service_name}
              value={servicesTakenList.filter(
                item => item.service_status === '2'
              )}
              renderInput={params => (
                <TextField {...params} variant="standard" />
              )}
            />{' '}
          </Card>
        </DialogContent>
        <DialogActions style={{ padding: '20px 40px' }}>
          <button
            onClick={handleClose}
            className={classes.modalSecondaryButton}
            style={{ backgroundColor: '#aeaeae' }}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}

Services.propTypes = {
  className: PropTypes.string
}

export default Services
