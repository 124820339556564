import React from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'
import moment from 'moment'
import {
  Box,
  // Typography,
  Checkbox,
  IconButton,
  Chip,
  Paper,
  Avatar,
  Grid,
  makeStyles
} from '@material-ui/core'
import ProfilePicImg from '../../images/profile_pic.png'
import ListBgHoverImg from '../../images/listbghover.png'
// import CalenderIcon from '../../images/calendar-icon.svg'
import VideoIcon from '../../images/video-call.svg'

export default function AppointmentListItem(props) {
  const classes = useStyles()
  let patientAllChecked = props?.isAllSelected ? props?.isAllSelected : false
  let disabledCard = props?.disabledCard ? props?.disabledCard : false
  const handleCheck = id => {
    props.SelectPatientForSms(id)
  }

  return (
    <Paper
      square
      className={classes.appointmentItem}
      style={{
        margin: '5px 0 15px',
        boxShadow: '0 0px 10px 0 rgba(0,0,0,0.05)'
      }}>
      <Grid
        container
        className={classes.appointmentPage}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <div
          style={{
            width: '20%',
            fontSize: 14,
            display: 'flex',
            alignItems: 'center'
          }}>
          <Link
            disabled={true}
            to={
              disabledCard
                ? '#'
                : {
                    pathname:
                      '/doctor/patient-details' +
                      `/${props.patientId}` +
                      '/' +
                      props.id
                  }
            }>
            <Avatar
              className={classes.image}
              alt="Image"
              src={props.image ? props.image : ProfilePicImg}
            />
          </Link>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h5
              className={classes.Patientname}
              style={{
                marginTop: '0',
                marginBottom: '0px',
                fontSize: 14,
                fontWeight: 500
              }}>
              <Link
                disabled={true}
                to={
                  disabledCard
                    ? '#'
                    : {
                        pathname:
                          '/doctor/patient-details' +
                          `/${props.patientId}` +
                          '/' +
                          props.id
                      }
                }
                style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                {props.first_name} {props.middle_name} {props.last_name}
              </Link>
            </h5>
            {/* <i>
            <svg
              className={classes.icon}
              aria-hidden="true"
              focusable="false"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24">
              <path
                d="M17.58 4H14V2h7v7h-2V5.41l-3.83 3.83A5 5 0 0 1 12 16.9V19h2v2h-2v2h-2v-2H8v-2h2v-2.1A5 5 0 0 1 6 12a5 5 0 0 1 5-5c1 0 1.96.3 2.75.83L17.58 4M11 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3z"
                fill="#3f4b79"
              />
              <rect
                x="0"
                y="0"
                width="24"
                height="24"
                fill="rgba(0, 0, 0, 0)"
              />
            </svg>
          </i> */}
            <b className={classes.text}>
              {props.gender === 'male'
                ? 'Male'
                : props.gender === 'female'
                ? 'Female'
                : props?.gender}
            </b>
          </div>
        </div>
        <div
          style={{
            width: '12%',
            fontSize: 14,
            display: 'flex',
            alignItems: 'center'
          }}>
          <i style={{ marginRight: 5 }}>
            <svg
              className={classes.icon}
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24">
              <path
                d="M7 12h2v2H7v-2m14-6v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1V2h2v2h8V2h2v2h1a2 2 0 0 1 2 2M5 8h14V6H5v2m14 12V10H5v10h14m-4-6v-2h2v2h-2m-4 0v-2h2v2h-2m-4 2h2v2H7v-2m8 2v-2h2v2h-2m-4 0v-2h2v2h-2z"
                fill="rgba(0,0,0,0.7)"
              />
              <rect
                x="0"
                y="0"
                width="24"
                height="24"
                fill="rgba(0, 0, 0, 0)"
              />
            </svg>
          </i>
          <b className={classes.text} style={{ marginTop: 4 }}>
            {props.dob ? (
              <>{moment(props.dob).format('DD-MM-YYYY')}</>
            ) : (
              <>----</>
            )}
            {/* (25yrs) */}
          </b>
        </div>
        <div
          style={{
            width: '28%',
            fontSize: 14,
            display: 'flex',
            alignItems: 'center'
          }}>
          {' '}
          <i>
            <svg
              className={classes.icon}
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24">
              <path
                d="M7 12h2v2H7v-2m14-6v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1V2h2v2h8V2h2v2h1a2 2 0 0 1 2 2M5 8h14V6H5v2m14 12V10H5v10h14m-4-6v-2h2v2h-2m-4 0v-2h2v2h-2m-4 2h2v2H7v-2m8 2v-2h2v2h-2m-4 0v-2h2v2h-2z"
                fill="rgba(0,0,0,0.7)"
              />
              <rect
                x="0"
                y="0"
                width="24"
                height="24"
                fill="rgba(0, 0, 0, 0)"
              />
            </svg>
          </i>
          <b className={classes.text} style={{ marginTop: 4, marginLeft: 5 }}>
            {props.appointmentDate ? (
              <>{moment(props.appointmentDate).format('DD-MM-YYYY')}</>
            ) : (
              <>---</>
            )}
          </b>
          ,
          <b className={classes.text} style={{ marginTop: 4, marginLeft: 5 }}>
            {props.start_time && props.end_time
              ? moment(props.start_time, 'hh:mm:ss').format('hh:mm A') +
                ' - ' +
                moment(props.end_time, 'hh:mm:ss').format('hh:mm A')
              : ''}
          </b>
        </div>
        <div
          style={{
            width: '10%',
            fontSize: 14,
            color: '#000'
          }}>
          {props.initialConsultation ? (
            <>
              <Chip
                size="small"
                label="Initial consultation"
                variant="outlined"
                style={{ color: '#3f4b79' }}
              />
            </>
          ) : (
            <></>
          )}

          {props.status ? (
            <>
              <div className={classes.blockcol}>
                <small
                  style={{
                    fontSize: '13px',
                    color: '#15A560',
                    fontWeight: '400'
                  }}>
                  {props.status === '1'
                    ? 'Booked'
                    : props.status === '2'
                    ? 'Confirmed'
                    : props.status === '3'
                    ? 'Checked-In'
                    : props.status === '4'
                    ? 'In-Progress'
                    : props.status === '5'
                    ? 'Cancelled'
                    : props.status === '6'
                    ? 'No-show'
                    : props.status === '7'
                    ? 'Completed'
                    : props.status === '8'
                    ? 'Rescheduled'
                    : 'NA'}
                </small>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            width: '20%',
            fontSize: 14
          }}>
          {props.status ? (
            <p
              className={classes.appointmentDetails}
              style={{
                padding: '2px 0',
                fontWeight: '400',
                fontSize: '13px',
                margin: '0',
                color: '#0D46C1',
                display: 'flex',
                alignItems: 'center'
              }}>
              <img className={classes.VideoIcon} src={VideoIcon} alt="" />
              <span style={{ color: '#0D46C1', marginLeft: 5 }}>
                {props.appointment_type === '1' ? <>In-Person</> : <>Tele</>}
                {' Consult'}
              </span>
            </p>
          ) : null}
        </div>
        <div
          style={{
            width: '10%',
            fontSize: 14
          }}>
          {' '}
          {props.selectItem === 'true' ? (
            !patientAllChecked ? (
              <Box className={classes.checkbutton}>
                <Checkbox
                  checked={props?.selectedPatientsList?.find(
                    item => item === props.patientId
                  )}
                  name="checkedA"
                  onChange={() => handleCheck(props.patientId)}
                  style={{ color: '#0D46C1' }}
                />
              </Box>
            ) : (
              ''
            )
          ) : disabledCard ? (
            ''
          ) : (
            <div
              style={{
                width: 70,
                height: 30,
                display: 'flex',
                background: '#0D46C1',
                borderRadius: 5,
                color: '#fff',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}>
              <Link
                disabled={true}
                to={{
                  pathname:
                    '/doctor/patient-details' +
                    `/${props.patientId}` +
                    '/' +
                    props.id
                }}>
                View
              </Link>
            </div>
          )}
        </div>
        {/* <Grid item xs={12} md={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className={classes.profilebox + ' ' + classes.item}>
              
              <div>
                
               
                <Box className={classes.responsivetatus}>
                  
                </Box>
                <Box className={classes.desktopstatus}>
                 
                </Box>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              className={classes.info + ' ' + classes.item}>
              <div className={classes.iconContainer}>
                
              </div>
              <div className={classes.iconContainer}>
                
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              className={classes.info + ' ' + classes.item}>
              <div className={classes.schduletime}>
                <div className={classes.iconContainer}>
                  
                </div>
                <div className={classes.iconContainer}>
                  <i>
                    <svg
                      className={classes.icon}
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24">
                      <path
                        d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7h1.5z"
                        fill="#3f4b79"
                      />
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        fill="rgba(0, 0, 0, 0)"
                      />
                    </svg>
                  </i>
                 
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className={classes.info + ' ' + classes.item}
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box className={classes.contactcol}>
                <div className={classes.iconContainer}>
                  <i>
                    <svg
                      className={classes.icon}
                      aria-hidden="true"
                      focusable="false"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24">
                      <path
                        d="M15.5 1h-8A2.5 2.5 0 0 0 5 3.5v17A2.5 2.5 0 0 0 7.5 23h8a2.5 2.5 0 0 0 2.5-2.5v-17A2.5 2.5 0 0 0 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z"
                        fill="#3f4b79"
                      />
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        fill="rgba(0, 0, 0, 0)"
                      />
                    </svg>
                  </i>
                  <b className={classes.text}>
                    {props.mobile ? <>{props.mobile}</> : <>----</>}
                  </b>
                </div>
                <div className={classes.iconContainer}>
                  <i>
                    <svg
                      className={classes.icon}
                      aria-hidden="true"
                      focusable="false"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24">
                      <path
                        d="M21.25 7.52l-8.574 7.6a1 1 0 0 1-1.331 0L2.703 7.56c.064-.055.131-.105.203-.152l7.453-4.872a3 3 0 0 1 3.282 0l7.453 4.872c.054.035.106.073.156.113zM22 9.69V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.698l8.014 6.916a3 3 0 0 0 3.992 0L22 9.69z"
                        fill="#3f4b79"
                      />
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        fill="rgba(0, 0, 0, 0)"
                      />
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        fill="rgba(0, 0, 0, 0)"
                      />
                    </svg>
                  </i>
                  <b className={classes.text}>
                    {props.email ? <>{props.email}</> : <>----</>}
                  </b>
                </div>
              </Box>
              {props.selectItem === 'true' ? (
                !patientAllChecked ? (
                  <Box className={classes.checkbutton}>
                    <Checkbox
                      checked={props?.selectedPatientsList?.find(
                        item => item === props.patientId
                      )}
                      name="checkedA"
                      onChange={() => handleCheck(props.patientId)}
                      style={{ color: '#9637a5' }}
                    />
                  </Box>
                ) : (
                  ''
                )
              ) : disabledCard ? (
                ''
              ) : (
                <IconButton className={classes.button}>
                  <Link
                    disabled={true}
                    style={{ position: 'absolute', right: '0', left: '0' }}
                    to={{
                      pathname:
                        '/doctor/patient-details' +
                        `/${props.patientId}` +
                        '/' +
                        props.id
                    }}>
                    <IoIosArrowForward
                      className={classes.icon}
                      size={32}
                      color="#fff"
                    />
                  </Link>
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={6}
          md={2}
          className={classes.action + ' ' + classes.item}
          style={{
            display: 'grid',
            gridTemplateColumns: '65% 20%',
            gridColumnGap: '5px'
          }}>
          
         
        </Grid> */}
      </Grid>
    </Paper>
  )
}
const useStyles = makeStyles(theme => ({
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0)
    }
  },
  checkbutton: {
    '@media (max-width:767px)': {
      position: 'absolute',
      top: '20px',
      right: '15px'
    }
  },
  contactcol: {
    '& iconContainer': {
      '@media (max-width:767px)': {
        width: '100%',
        marginRight: '0px'
      }
    }
  },
  Patientname: {
    fontSize: '16px',
    '@media (max-width:767px)': {
      width: '100%',
      fontSize: '14px'
    }
  },
  schduletime: {
    '@media (max-width:767px)': {
      display: 'flex',
      width: '100%'
    }
  },
  image: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
    marginRight: '10px'
  },
  name: {
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.primary.dark,
    textAlign: 'center'
  },
  profilebox: {
    display: 'flex',
    gridTemplateColumns: '24% 65%',
    '& a': { display: 'block' },
    '@media (max-width:767px)': {
      display: 'flex',
      marginBottom: '20px'
    }
  },
  tag: {
    color: '#fff',
    width: '120px',
    padding: '0px 12px',
    background: '#444',
    textAlign: 'center',
    fontWeight: '100',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    '@media (max-width:767px)': {
      width: '90px',
      marginLeft: '20px'
    }
  },
  appointmentItem: {
    borderRadius: '10px',
    position: 'relative',
    width: '100%',
    background: `#fff`,
    padding: '10px',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    flexWrap: 'wrap',
    boxShadow: 'none',
    '&:hover p': {
      color: '#202020'
    },
    '&:hover b': {
      color: '#202020 !important'
    },
    '&:hover h5 a': {
      color: '#202020 !important'
    },
    '&:hover p span': {
      color: '#202020'
    },
    '@media (max-width:767px)': {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '15px',
      boxShadow: '0px 0px 9px 0px #00000030'
    },
    '&:hover': {
      // background: `url(${ListBgHoverImg}) center top no-repeat`,
      backgroundSize: 'cover'
    }
  },
  icon: {
    width: '1.5em',
    height: '1.5em',
    position: 'relative',
    top: '3px'
  },
  item: {
    justifyContent: 'flex-start',
    '@media (max-width:767px)': {
      display: 'flex'
    }
  },
  blockcol: {
    '@media (max-width:767px)': {
      display: 'flex',
      alignItems: 'center',
      '& b': {
        marginRight: '10px'
      }
    }
  },
  iconContainer: {
    display: 'grid',
    alignItems: 'center',
    fontSize: '14px',
    '& b': {
      wordBreak: 'break-all',
      lineHeight: '14px',
      fontSize: '12px'
    },
    gridTemplateColumns: '30px 80%',
    '@media (max-width:767px)': {
      gridTemplateColumns: '30px 90%',
      marginRight: '10px'
    }
  },
  anchor: {
    background: '#3f4b79',
    color: '#fff',
    borderRadius: '50%',
    height: '30px',
    width: '30px'
  },
  text: {
    fontWeight: '400',
    fontSize: '13px'
  },
  action: {
    '@media (max-width:767px)': {
      display: 'flex !important'
    }
  },
  desktopstatus: {
    '@media (max-width:767px)': {
      display: 'none'
    }
  },
  responsivetatus: {
    display: 'none',
    '@media (max-width:767px)': {
      display: 'block'
    }
  },
  button: {
    height: 40,
    width: 40,
    padding: '8px',
    background: theme.palette.primary.main,
    marginLeft: '0',
    alignSelf: 'center',
    '@media (max-width:767px)': {
      width: '35px',
      height: '35px',
      position: 'absolute',
      right: '20px',
      top: '25px'
    },
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: theme.palette.primary.main
    }
  }
}))
