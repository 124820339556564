import React from 'react'
import DoctorsContent from './Doctors'
import { DashboardLayout } from '../../../layouts/ClinicDashboardLayout'
import { ClinicStore } from '../../../../stores'

const Doctors = () => {
  return (
    <ClinicStore.Provider>
      <DashboardLayout>
        <DoctorsContent />
      </DashboardLayout>
    </ClinicStore.Provider>
  )
}

export default Doctors
