import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  Menu,
  MenuItem
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  root: {
    width:'100%',
    boxShadow:'0 1px 10px 0 rgba(0,0,0,0.1)'
  }
}))

const Sales = ({ className, data, ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [graphData, setGraphData] = React.useState({})
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [filterType, setFilterType] = React.useState('Daily')
  const menuList = ['Daily', 'Weekly', 'Monthly']

  function color(color, amount) {
    return (
      '#' +
      color
        .replace(/^#/, '')
        .replace(/../g, color =>
          (
            '0' +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    )
  }

  React.useEffect(() => {
    if (data.length !== 0) {
      console.log(data)
      let valuesList1 = []
      let valuesList2 = []
      let valuesList3 = []
      let labelList1 = []
      let labelList2 = []
      let labelList3 = []

      let keys = data.map(item => {
        valuesList1.push(item.clinic_revenue)
      })
      keys = data.map(item => {
        if (item.desc.slice(0, 1) == 'D') {
          valuesList1.push(item.clinic_revenue)
          labelList1.push(moment(item.fromdate).format('DD MMM YY'))
        } else if (item.desc.slice(0, 1) == 'W') {
          valuesList2.push(item.clinic_revenue)
          labelList2.push(moment(item.fromdate).format('DD MMM YY'))
        } else {
          valuesList3.push(item.clinic_revenue)
          labelList3.push(moment(item.fromdate).format('MMM YY'))
        }
      })
      let dataSet = []
      let labelSet = []
      if (filterType === 'Daily') {
        dataSet = valuesList1
        labelSet = labelList1
      } else if (filterType === 'Weekly') {
        dataSet = valuesList2
        labelSet = labelList2
      } else {
        dataSet = valuesList3
        labelSet = labelList3
      }
      setGraphData({
        datasets: [
          {
            backgroundColor: '#111d4e',
            data: dataSet,
            label: 'Revenue'
          }
        ],
        labels: labelSet
      })
    }
  }, [data, filterType])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    setAnchorEl(null)
  }

  const selectOption = (event, value) => {
    setFilterType(value)
    setAnchorEl(null)
  }

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="Revenue Insights"
        action={
          <Button style={{height:'40px'}}
            endIcon={<ArrowDropDownIcon />}
            onClick={handleClick}
            size="small"
            variant="text">
            {menuList.filter(item => item === filterType)}
          </Button>
        }
      />
      <Menu style={{marginTop:'50px'}}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuList.map(menu => {
          return (
            <MenuItem onClick={e => selectOption(e, menu)}>{menu}</MenuItem>
          )
        })}
      </Menu>
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Bar data={graphData} options={options} />
        </Box>
      </CardContent>
    </Card>
  )
}

Sales.propTypes = {
  className: PropTypes.string
}

export default Sales
