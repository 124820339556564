import React, { useEffect } from 'react'
import { makeStyles, Box, Typography, Avatar } from '@material-ui/core'
import { useLocation, useParams } from 'react-router-dom'
import { PublicPatientStore } from '../../stores'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import InfoIcon from '@material-ui/icons/Info'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import BusinessIcon from '@material-ui/icons/Business'
import ProfileImg from '../../images/profile-img.png'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

const DoctorProfile = () => {
  const classes = useStyles()
  const location = useLocation()
  const { shortCode } = useParams()
  const { fetchDetails } = PublicPatientStore.useStoreActions(actions => ({
    ...actions.doctorProfile
  }))
  const history = useHistory()

  const {
    prefix,
    image,
    name,
    specialization,
    degree,
    experience,
    availability,
    description,
    associated_with,
    consultationFees,
    userId,
    networkStatus,
    noDoctorFound,
    clinic_name,
    clinic_address
  } = PublicPatientStore.useStoreState(state => ({
    ...state.doctorProfile
  }))

  const doctorDetails = PublicPatientStore.useStoreState(state => ({
    ...state.doctorProfile
  }))

  useEffect(() => {
    fetchDetails({ shortCode })
  }, [])

  useEffect(() => {
    if (noDoctorFound) {
      Swal.fire(
        'No Doctor Found!',
        'Please enter correct Doctor Name',
        'info'
      ).then(() => {
        history.push('/')
      })
    }
  }, [noDoctorFound])

  useEffect(() => {
    localStorage.setItem('selected_doctor', JSON.stringify(doctorDetails))
    if (userId) {
      localStorage.setItem('doctor_id', userId)
    }
  }, [doctorDetails])

  return (
    <Box>
      <Box className={classes.ProfileCol}>
        <Box className={classes.ProfileImgLeft}>
          <div className={classes.root}>
            <Avatar
              className={classes.AvatarCol}
              alt={name}
              src={image || ProfileImg}
            />
          </div>
        </Box>
        <Box className={classes.DoctorInfo}>
          <Typography
            variant="h3"
            component="h3"
            style={{ fontWeight: '800', color: '#3f4b79', marginTop: '20px', textAlign:'left' }}>
            {name ? prefix + ' ' + name : 'Loading...'}{' '}
            <VerifiedUserIcon
              style={{ color: '#a042af', fontSize: '1.2rem' }}
            />
          </Typography>

          <Typography variant="caption" style={{ fontSize: '1rem' }}>
            {degree || 'Degree Unvailable'}
          </Typography>

          <Typography
            variant="caption"
            style={{
              textAlign: 'left',
              color: '#9637a5',
              marginTop: '10px',
              fontSize: '16px'
            }}>
            {specialization ? specialization : ''}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.DoctorInfo} style={{ marginTop: '10px' }}>
        <p>
          <Box className={classes.Iconcol}>
            <HomeWorkIcon style={{ marginRight: '8px' }} /> Associated with
          </Box>

          <span>{associated_with || '--'}</span>
        </p>
        <br />

        <p>
          <Box className={classes.Iconcol}>
            <BusinessCenterIcon style={{ marginRight: '8px' }} /> Experience{' '}
          </Box>

          <span>{experience || '--'} years</span>
        </p>
        {consultationFees && consultationFees.length === 0 ? (
          ''
        ) : (
          <Box className={classes.DoctorInfo}>
            <p>
              <Box className={classes.Iconcol}>
                <AccountBalanceWalletIcon style={{ marginRight: '8px' }} /> Fees
              </Box>
            </p>
            <p>
              In-Person Consultation Fees{' '}
              <span>
                
                {Number(
                  consultationFees.find(
                    i => i.appointment_type === 'in-person appointment' && i.visit_type == 1
                  ).fees
                ) || 'Rs 0'}
              </span>
            </p>
            <p>
              Tele-Consultation Fees{' '}
              <span>
                
                {Number(
                  consultationFees.find(
                    i => i.appointment_type === 'tele appointment' && i.visit_type == 1
                  ).fees
                ) || 'Rs 0'}
              </span>
            </p>
          </Box>
        )}
        <p>
          <Box className={classes.Iconcol}>
            <LocalHospitalIcon style={{ marginRight: '8px' }} /> Clinic Name{' '}
          </Box>

          <span>{clinic_name || '--'}</span>
        </p>
        <br />
        <p>
          <Box className={classes.Iconcol}>
            <BusinessIcon style={{ marginRight: '8px' }} /> Clinic Address
          </Box>

          <span>{clinic_address || '--'}</span>
        </p>
      </Box>
      <Box className={classes.DoctorInfo}>
        <p>
          <Box className={classes.Iconcol}>
            <InfoIcon style={{ marginRight: '8px' }} /> Bio
          </Box>
        </p>
        <p>
          <span>{description || '--'}</span>
        </p>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  DoctorInfo: {
    width: '100%',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify',
    background: '#fff',
    marginBottom: '10px',
    '& h4': {
      fontSize: '18px',
      color: '#979797',
      margin: '0',
      fontWeight: 'normal'
    },
    '& h5': {
      fontSize: '18px',
      color: '#3f4b79',
      margin: '0px',
      fontWeight: 'normal'
    },
    '& p': {
      fontSize: '16px',
      color: '#111d4e',
      marginTop: '0px',
      marginBottom: '5px',
      '& span': {
        color: '#000',
        fontWeight: '400'
      }
    },
    '& h3': {
      fontSize: '18px',
      margin: '0'
    }
  },
  Iconcol: {
    display: 'flex',
    alignItems: 'center'
  },
  ProfileImgLeft: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    border: '0px #3f4b79 solid',
    marginRight: '20px',
    minWidth: '120px',
    marginTop: '20px'
  },
  ViewText: {
    color: '#3f4b79',
    cursor: 'pointer',
    '&:hover': {
      color: '#333',
      textDecoration: 'none'
    }
  },
  ProfileCol: {
    width: '100%',
    display: 'flex',
    '& p': {
      fontSize: '18px',
      color: '#979797',
      marginTop: '0px'
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: '400',
      marginBottom: '0px',
      marginTop: '20px'
    }
  },
  ContentText: {
    fontSize: '16px',
    color: '#979797'
  },
  AvatarCol: {
    width: '100%',
    height: '120px'
  }
}))

export default DoctorProfile
