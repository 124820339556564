var __extends =
  (this && this.__extends) ||
  (function () {
    var extendStatics = function (d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function (d, b) {
            d.__proto__ = b;
          }) ||
        function (d, b) {
          for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
        };
      return extendStatics(d, b);
    };
    return function (d, b) {
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype =
        b === null
          ? Object.create(b)
          : ((__.prototype = b.prototype), new __());
    };
  })();
var __decorate =
  (this && this.__decorate) ||
  function (decorators, target, key, desc) {
    var c = arguments.length,
      r =
        c < 3
          ? target
          : desc === null
          ? (desc = Object.getOwnPropertyDescriptor(target, key))
          : desc,
      d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
      r = Reflect.decorate(decorators, target, key, desc);
    else
      for (var i = decorators.length - 1; i >= 0; i--)
        if ((d = decorators[i]))
          r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
  };
import { Base } from "./base";
import { Browser } from "./browser";
import { isVisible } from "./dom";
import {
  Property,
  Complex,
  NotifyPropertyChanges,
  Event,
} from "./notify-property-change";
import { EventHandler } from "./event-handler";
import { ChildProperty } from "./child-property";
import {
  select,
  closest,
  setStyleAttribute,
  addClass,
  createElement,
} from "./dom";
import {
  extend,
  isUndefined,
  isNullOrUndefined,
  compareElementParent,
  isBlazor,
} from "./util";
var defaultPosition = { left: 0, top: 0, bottom: 0, right: 0 };
var positionProp = ["offsetLeft", "offsetTop"];
var axisMapper = ["x", "y"];
var axisValueMapper = ["left", "top"];
var isDraggedObject = { isDragged: false };
/**
 * Specifies the position coordinates
 */
var Position = /** @class */ (function (_super) {
  __extends(Position, _super);
  function Position() {
    return (_super !== null && _super.apply(this, arguments)) || this;
  }
  __decorate([Property(0)], Position.prototype, "left", void 0);
  __decorate([Property(0)], Position.prototype, "top", void 0);
  return Position;
})(ChildProperty);
export { Position };
/**
 * Draggable Module provides support to enable draggable functionality in Dom Elements.
 * ```html
 * <div id='drag'>Draggable</div>
 * <script>
 * var ele = document.getElementById('drag');
 * var drag:Draggable = new Draggable(ele,{
 *     clone:false,
 *     drag: function(e) {
 *      //drag handler code.
 *      },
 *     handle:'.class'
 * });
 * </script>
 * ```
 */
var Draggable = /** @class */ (function (_super) {
  __extends(Draggable, _super);
  function Draggable(element, options) {
    var _this = _super.call(this, options, element) || this;
    _this.dragLimit = Draggable_1.getDefaultPosition();
    _this.borderWidth = Draggable_1.getDefaultPosition();
    _this.padding = Draggable_1.getDefaultPosition();
    _this.diffX = 0;
    _this.prevLeft = 0;
    _this.prevTop = 0;
    _this.dragProcessStarted = false;
    /* tslint:disable no-any */
    _this.tapHoldTimer = 0;
    _this.externalInitialize = false;
    _this.diffY = 0;
    _this.parentScrollX = 0;
    _this.parentScrollY = 0;
    _this.droppables = {};
    _this.bind();
    return _this;
  }
  Draggable_1 = Draggable;
  Draggable.prototype.bind = function () {
    this.toggleEvents();
    if (Browser.isIE) {
      addClass([this.element], "e-block-touch");
    }
    this.droppables[this.scope] = {};
  };
  Draggable.getDefaultPosition = function () {
    return extend({}, defaultPosition);
  };
  Draggable.prototype.toggleEvents = function (isUnWire) {
    var ele;
    if (!isUndefined(this.handle)) {
      ele = select(this.handle, this.element);
    }
    var handler =
      this.enableTapHold && Browser.isDevice && Browser.isTouch
        ? this.mobileInitialize
        : this.initialize;
    if (isUnWire) {
      EventHandler.remove(
        ele || this.element,
        Browser.touchStartEvent,
        handler
      );
    } else {
      EventHandler.add(
        ele || this.element,
        Browser.touchStartEvent,
        handler,
        this
      );
    }
  };
  /* istanbul ignore next */
  Draggable.prototype.mobileInitialize = function (evt) {
    var _this = this;
    var target = evt.currentTarget;
    this.tapHoldTimer = setTimeout(function () {
      _this.externalInitialize = true;
      _this.removeTapholdTimer();
      _this.initialize(evt, target);
    }, this.tapHoldThreshold);
    EventHandler.add(
      document,
      Browser.touchMoveEvent,
      this.removeTapholdTimer,
      this
    );
    EventHandler.add(
      document,
      Browser.touchEndEvent,
      this.removeTapholdTimer,
      this
    );
  };
  /* istanbul ignore next */
  Draggable.prototype.removeTapholdTimer = function () {
    clearTimeout(this.tapHoldTimer);
    EventHandler.remove(
      document,
      Browser.touchMoveEvent,
      this.removeTapholdTimer
    );
    EventHandler.remove(
      document,
      Browser.touchEndEvent,
      this.removeTapholdTimer
    );
  };
  /* istanbul ignore next */
  Draggable.prototype.getScrollableParent = function (element, axis) {
    var scroll = { vertical: "scrollHeight", horizontal: "scrollWidth" };
    var client = { vertical: "clientHeight", horizontal: "clientWidth" };
    if (isNullOrUndefined(element)) {
      return null;
    }
    if (element[scroll[axis]] > element[client[axis]]) {
      if (
        axis === "vertical" ? element.scrollTop > 0 : element.scrollLeft > 0
      ) {
        if (axis === "vertical") {
          this.parentScrollY =
            this.parentScrollY +
            (this.parentScrollY === 0
              ? element.scrollTop
              : element.scrollTop - this.parentScrollY);
        } else {
          this.parentScrollX =
            this.parentScrollX +
            (this.parentScrollX === 0
              ? element.scrollLeft
              : element.scrollLeft - this.parentScrollX);
        }
        if (!isNullOrUndefined(element)) {
          return this.getScrollableParent(element.parentNode, axis);
        } else {
          return element;
        }
      } else {
        return this.getScrollableParent(element.parentNode, axis);
      }
    } else {
      return this.getScrollableParent(element.parentNode, axis);
    }
  };
  Draggable.prototype.getScrollableValues = function () {
    this.parentScrollX = 0;
    this.parentScrollY = 0;
    var isModalDialog =
      this.element.classList.contains("e-dialog") &&
      this.element.classList.contains("e-dlg-modal");
    var verticalScrollParent = this.getScrollableParent(
      this.element.parentNode,
      "vertical"
    );
    var horizontalScrollParent = this.getScrollableParent(
      this.element.parentNode,
      "horizontal"
    );
  };
  Draggable.prototype.initialize = function (evt, curTarget) {
    this.currentStateTarget = evt.target;
    if (this.isDragStarted()) {
      return;
    } else {
      this.isDragStarted(true);
      this.externalInitialize = false;
    }
    this.target = evt.currentTarget || curTarget;
    this.dragProcessStarted = false;
    if (this.abort) {
      /* tslint:disable no-any */
      var abortSelectors = this.abort;
      if (typeof abortSelectors === "string") {
        abortSelectors = [abortSelectors];
      }
      for (var i = 0; i < abortSelectors.length; i++) {
        if (!isNullOrUndefined(closest(evt.target, abortSelectors[i]))) {
          /* istanbul ignore next */
          if (this.isDragStarted()) {
            this.isDragStarted(true);
          }
          return;
        }
      }
    }
    if (this.preventDefault && !isUndefined(evt.changedTouches)) {
      evt.preventDefault();
    }
    this.element.setAttribute("aria-grabbed", "true");
    var intCoord = this.getCoordinates(evt);
    this.initialPosition = { x: intCoord.pageX, y: intCoord.pageY };
    if (!this.clone) {
      var pos = this.element.getBoundingClientRect();
      this.getScrollableValues();
      if (evt.clientX === evt.pageX) {
        this.parentScrollX = 0;
      }
      if (evt.clientY === evt.pageY) {
        this.parentScrollY = 0;
      }
      this.relativeXPosition = intCoord.pageX - (pos.left + this.parentScrollX);
      this.relativeYPosition = intCoord.pageY - (pos.top + this.parentScrollY);
    }
    if (this.externalInitialize) {
      this.intDragStart(evt);
    } else {
      EventHandler.add(
        document,
        Browser.touchMoveEvent,
        this.intDragStart,
        this
      );
      EventHandler.add(document, Browser.touchEndEvent, this.intDestroy, this);
    }
    this.toggleEvents(true);
    document.body.classList.add("e-prevent-select");
    this.externalInitialize = false;
    EventHandler.trigger(
      document.documentElement,
      Browser.touchStartEvent,
      evt
    );
  };
  Draggable.prototype.intDragStart = function (evt) {
    this.removeTapholdTimer();
    var isChangeTouch = !isUndefined(evt.changedTouches);
    if (isChangeTouch && evt.changedTouches.length !== 1) {
      return;
    }
    if (isChangeTouch) {
      evt.preventDefault();
    }
    var intCordinate = this.getCoordinates(evt);
    var pos;
    var styleProp = getComputedStyle(this.element);
    this.margin = {
      left: parseInt(styleProp.marginLeft, 10),
      top: parseInt(styleProp.marginTop, 10),
      right: parseInt(styleProp.marginRight, 10),
      bottom: parseInt(styleProp.marginBottom, 10),
    };
    var element = this.element;
    if (this.clone && this.dragTarget) {
      var intClosest = closest(evt.target, this.dragTarget);
      if (!isNullOrUndefined(intClosest)) {
        element = intClosest;
      }
    }
    this.offset = this.calculateParentPosition(element);
    this.position = this.getMousePosition(evt, this.isDragScroll);
    var x = this.initialPosition.x - intCordinate.pageX;
    var y = this.initialPosition.y - intCordinate.pageY;
    var distance = Math.sqrt(x * x + y * y);
    if (distance >= this.distance || this.externalInitialize) {
      var ele = this.getHelperElement(evt);
      if (!ele || isNullOrUndefined(ele)) {
        return;
      }
      var dragTargetElement = (this.helperElement = ele);
      this.parentClientRect = this.calculateParentPosition(
        dragTargetElement.offsetParent
      );
      if (this.dragStart) {
        var curTarget = this.getProperTargetElement(evt);
        var args = {
          event: evt,
          element: element,
          target: curTarget,
          bindEvents: isBlazor() ? this.bindDragEvents.bind(this) : null,
          dragElement: dragTargetElement,
        };
        this.trigger("dragStart", args);
      }
      if (this.dragArea) {
        this.setDragArea();
      } else {
        this.dragLimit = { left: 0, right: 0, bottom: 0, top: 0 };
        this.borderWidth = { top: 0, left: 0 };
      }
      pos = {
        left: this.position.left - this.parentClientRect.left,
        top: this.position.top - this.parentClientRect.top,
      };
      if (this.clone && !this.enableTailMode) {
        this.diffX = this.position.left - this.offset.left;
        this.diffY = this.position.top - this.offset.top;
      }
      this.getScrollableValues();
      var posValue = this.getProcessedPositionValue({
        top: pos.top - this.diffY + "px",
        left: pos.left - this.diffX + "px",
      });
      this.dragElePosition = { top: pos.top, left: pos.left };
      setStyleAttribute(
        dragTargetElement,
        this.getDragPosition({
          position: "absolute",
          left: posValue.left,
          top: posValue.top,
        })
      );
      EventHandler.remove(document, Browser.touchMoveEvent, this.intDragStart);
      EventHandler.remove(document, Browser.touchEndEvent, this.intDestroy);
      if (!isBlazor()) {
        this.bindDragEvents(dragTargetElement);
      }
    }
  };
  Draggable.prototype.bindDragEvents = function (dragTargetElement) {
    if (isVisible(dragTargetElement)) {
      EventHandler.add(document, Browser.touchMoveEvent, this.intDrag, this);
      EventHandler.add(document, Browser.touchEndEvent, this.intDragStop, this);
      this.setGlobalDroppables(false, this.element, dragTargetElement);
    } else {
      this.toggleEvents();
      document.body.classList.remove("e-prevent-select");
    }
  };
  Draggable.prototype.elementInViewport = function (el) {
    this.top = el.offsetTop;
    this.left = el.offsetLeft;
    this.width = el.offsetWidth;
    this.height = el.offsetHeight;
    while (el.offsetParent) {
      el = el.offsetParent;
      this.top += el.offsetTop;
      this.left += el.offsetLeft;
    }
    return (
      this.top >= window.pageYOffset &&
      this.left >= window.pageXOffset &&
      this.top + this.height <= window.pageYOffset + window.innerHeight &&
      this.left + this.width <= window.pageXOffset + window.innerWidth
    );
  };
  Draggable.prototype.getProcessedPositionValue = function (value) {
    if (this.queryPositionInfo) {
      return this.queryPositionInfo(value);
    }
    return value;
  };
  Draggable.prototype.calculateParentPosition = function (ele) {
    if (isNullOrUndefined(ele)) {
      return { left: 0, top: 0 };
    }
    var rect = ele.getBoundingClientRect();
    var style = getComputedStyle(ele);
    return {
      left: rect.left + window.pageXOffset - parseInt(style.marginLeft, 10),
      top: rect.top + window.pageYOffset - parseInt(style.marginTop, 10),
    };
  };
  // tslint:disable-next-line:max-func-body-length
  Draggable.prototype.intDrag = function (evt) {
    if (!isUndefined(evt.changedTouches) && evt.changedTouches.length !== 1) {
      return;
    }
    var left;
    var top;
    this.position = this.getMousePosition(evt, this.isDragScroll);
    var docHeight = this.getDocumentWidthHeight("Height");
    if (docHeight < this.position.top) {
      this.position.top = docHeight;
    }
    var docWidth = this.getDocumentWidthHeight("Width");
    if (docWidth < this.position.left) {
      this.position.left = docWidth;
    }
    if (this.drag) {
      var curTarget = this.getProperTargetElement(evt);
      this.trigger("drag", {
        event: evt,
        element: this.element,
        target: curTarget,
      });
    }
    var eleObj = this.checkTargetElement(evt);
    if (eleObj.target && eleObj.instance) {
      /* tslint:disable no-any */
      var flag = true;
      if (this.hoverObject) {
        if (this.hoverObject.instance !== eleObj.instance) {
          this.triggerOutFunction(evt, eleObj);
        } else {
          flag = false;
        }
      }
      if (flag) {
        eleObj.instance.dragData[this.scope] = this.droppables[this.scope];
        eleObj.instance.intOver(evt, eleObj.target);
        this.hoverObject = eleObj;
      }
    } else if (this.hoverObject) {
      this.triggerOutFunction(evt, eleObj);
    }
    var helperElement = this.droppables[this.scope].helper;
    this.parentClientRect = this.calculateParentPosition(
      this.helperElement.offsetParent
    );
    var tLeft = this.parentClientRect.left;
    var tTop = this.parentClientRect.top;
    var intCoord = this.getCoordinates(evt);
    var pagex = intCoord.pageX;
    var pagey = intCoord.pageY;
    var dLeft = this.position.left - this.diffX;
    var dTop = this.position.top - this.diffY;
    if (this.dragArea) {
      var styles = getComputedStyle(helperElement);
      if (this.pageX !== pagex || this.skipDistanceCheck) {
        var helperWidth =
          helperElement.offsetWidth +
          (parseFloat(styles.marginLeft) + parseFloat(styles.marginRight));
        if (this.dragLimit.left > dLeft && dLeft > 0) {
          left = this.dragLimit.left;
        } else if (
          this.dragLimit.right + window.pageXOffset < dLeft + helperWidth &&
          dLeft > 0
        ) {
          left =
            dLeft -
            (dLeft - this.dragLimit.right) +
            window.pageXOffset -
            helperWidth;
        } else {
          left = dLeft < 0 ? this.dragLimit.left : dLeft;
        }
      }
      if (this.pageY !== pagey || this.skipDistanceCheck) {
        var helperHeight =
          helperElement.offsetHeight +
          (parseFloat(styles.marginTop) + parseFloat(styles.marginBottom));
        if (this.dragLimit.top > dTop && dTop > 0) {
          top = this.dragLimit.top;
        } else if (
          this.dragLimit.bottom + window.pageYOffset < dTop + helperHeight &&
          dTop > 0
        ) {
          top =
            dTop -
            (dTop - this.dragLimit.bottom) +
            window.pageYOffset -
            helperHeight;
        } else {
          top = dTop < 0 ? this.dragLimit.top : dTop;
        }
      }
    } else {
      left = dLeft;
      top = dTop;
    }
    var iTop = tTop + this.borderWidth.top;
    var iLeft = tLeft + this.borderWidth.left;
    if (this.dragProcessStarted) {
      if (isNullOrUndefined(top)) {
        top = this.prevTop;
      }
      if (isNullOrUndefined(left)) {
        left = this.prevLeft;
      }
    }
    var draEleTop;
    var draEleLeft;
    if (this.dragArea) {
      draEleTop = top - iTop < 0 ? this.dragLimit.top : top - iTop;
      draEleLeft = left - iLeft < 0 ? this.dragElePosition.left : left - iLeft;
    } else {
      draEleTop = top - iTop;
      draEleLeft = left - iLeft;
    }
    var dragValue = this.getProcessedPositionValue({
      top: draEleTop + "px",
      left: draEleLeft + "px",
    });
    setStyleAttribute(helperElement, this.getDragPosition(dragValue));
    if (!this.elementInViewport(helperElement) && this.enableAutoScroll) {
      this.helperElement.scrollIntoView();
    }
    this.dragProcessStarted = true;
    this.prevLeft = left;
    this.prevTop = top;
    this.position.left = left;
    this.position.top = top;
    this.pageX = pagex;
    this.pageY = pagey;
  };
  Draggable.prototype.triggerOutFunction = function (evt, eleObj) {
    this.hoverObject.instance.intOut(evt, eleObj.target);
    this.hoverObject.instance.dragData[this.scope] = null;
    this.hoverObject = null;
  };
  Draggable.prototype.getDragPosition = function (dragValue) {
    var temp = extend({}, dragValue);
    if (this.axis) {
      if (this.axis === "x") {
        delete temp.top;
      } else if (this.axis === "y") {
        delete temp.left;
      }
    }
    return temp;
  };
  Draggable.prototype.getDocumentWidthHeight = function (str) {
    var docBody = document.body;
    var docEle = document.documentElement;
    var returnValue = Math.max(
      docBody["scroll" + str],
      docEle["scroll" + str],
      docBody["offset" + str],
      docEle["offset" + str],
      docEle["client" + str]
    );
    return returnValue;
  };
  Draggable.prototype.intDragStop = function (evt) {
    this.dragProcessStarted = false;
    if (!isUndefined(evt.changedTouches) && evt.changedTouches.length !== 1) {
      return;
    }
    var type = ["touchend", "pointerup", "mouseup"];
    if (type.indexOf(evt.type) !== -1) {
      if (this.dragStop) {
        var curTarget = this.getProperTargetElement(evt);
        this.trigger("dragStop", {
          event: evt,
          element: this.element,
          target: curTarget,
          helper: this.helperElement,
        });
      }
      this.intDestroy(evt);
    } else {
      this.element.setAttribute("aria-grabbed", "false");
    }
    var eleObj = this.checkTargetElement(evt);
    if (eleObj.target && eleObj.instance) {
      eleObj.instance.dragStopCalled = true;
      eleObj.instance.dragData[this.scope] = this.droppables[this.scope];
      eleObj.instance.intDrop(evt, eleObj.target);
    }
    this.setGlobalDroppables(true);
    document.body.classList.remove("e-prevent-select");
  };
  /**
   * @private
   */
  Draggable.prototype.intDestroy = function (evt) {
    this.dragProcessStarted = false;
    this.toggleEvents();
    document.body.classList.remove("e-prevent-select");
    this.element.setAttribute("aria-grabbed", "false");
    EventHandler.remove(document, Browser.touchMoveEvent, this.intDragStart);
    EventHandler.remove(document, Browser.touchEndEvent, this.intDragStop);
    EventHandler.remove(document, Browser.touchEndEvent, this.intDestroy);
    EventHandler.remove(document, Browser.touchMoveEvent, this.intDrag);
    if (this.isDragStarted()) {
      this.isDragStarted(true);
    }
  };
  // triggers when property changed
  Draggable.prototype.onPropertyChanged = function (newProp, oldProp) {
    //No Code to handle
  };
  Draggable.prototype.getModuleName = function () {
    return "draggable";
  };
  Draggable.prototype.isDragStarted = function (change) {
    if (change) {
      isDraggedObject.isDragged = !isDraggedObject.isDragged;
    }
    return isDraggedObject.isDragged;
  };
  Draggable.prototype.setDragArea = function () {
    var eleWidthBound;
    var eleHeightBound;
    var top = 0;
    var left = 0;
    var ele;
    var type = typeof this.dragArea;
    if (type === "string") {
      ele = select(this.dragArea);
    } else {
      ele = this.dragArea;
    }
    if (ele) {
      var elementArea = ele.getBoundingClientRect();
      eleWidthBound = ele.scrollWidth
        ? ele.scrollWidth
        : elementArea.right - elementArea.left;
      eleHeightBound = ele.scrollHeight
        ? ele.scrollHeight
        : elementArea.bottom - elementArea.top;
      var keys = ["Top", "Left", "Bottom", "Right"];
      var styles = getComputedStyle(ele);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var tborder = styles["border" + key + "Width"];
        var tpadding = styles["padding" + key];
        var lowerKey = key.toLowerCase();
        this.borderWidth[lowerKey] = isNaN(parseFloat(tborder))
          ? 0
          : parseFloat(tborder);
        this.padding[lowerKey] = isNaN(parseFloat(tpadding))
          ? 0
          : parseFloat(tpadding);
      }
      top = elementArea.top;
      left = elementArea.left;
      this.dragLimit.left = left + this.borderWidth.left + this.padding.left;
      this.dragLimit.top =
        ele.offsetTop + this.borderWidth.top + this.padding.top;
      this.dragLimit.right =
        left + eleWidthBound - (this.borderWidth.right + this.padding.right);
      this.dragLimit.bottom =
        top + eleHeightBound - (this.borderWidth.bottom + this.padding.bottom);
    }
  };
  Draggable.prototype.getProperTargetElement = function (evt) {
    var intCoord = this.getCoordinates(evt);
    var ele;
    var prevStyle = this.helperElement.style.pointerEvents || "";
    if (
      compareElementParent(evt.target, this.helperElement) ||
      evt.type.indexOf("touch") !== -1
    ) {
      this.helperElement.style.pointerEvents = "none";
      ele = document.elementFromPoint(intCoord.clientX, intCoord.clientY);
      this.helperElement.style.pointerEvents = prevStyle;
    } else {
      ele = evt.target;
    }
    return ele;
  };
  Draggable.prototype.getMousePosition = function (evt, isdragscroll) {
    /* tslint:disable no-any */
    var dragEle = evt.srcElement !== undefined ? evt.srcElement : evt.target;
    var intCoord = this.getCoordinates(evt);
    var pageX;
    var pageY;
    var isOffsetParent = isNullOrUndefined(dragEle.offsetParent);
    /* istanbul ignore next */
    if (isdragscroll) {
      pageX = this.clone
        ? intCoord.pageX
        : intCoord.pageX +
          (isOffsetParent ? 0 : dragEle.offsetParent.scrollLeft) -
          this.relativeXPosition;
      pageY = this.clone
        ? intCoord.pageY
        : intCoord.pageY +
          (isOffsetParent ? 0 : dragEle.offsetParent.scrollTop) -
          this.relativeYPosition;
    } else {
      pageX = this.clone
        ? intCoord.pageX
        : intCoord.pageX + window.pageXOffset - this.relativeXPosition;
      pageY = this.clone
        ? intCoord.pageY
        : intCoord.pageY + window.pageYOffset - this.relativeYPosition;
    }
    return {
      left: pageX - (this.margin.left + this.cursorAt.left),
      top: pageY - (this.margin.top + this.cursorAt.top),
    };
  };
  Draggable.prototype.getCoordinates = function (evt) {
    if (evt.type.indexOf("touch") > -1) {
      return evt.changedTouches[0];
    }
    return evt;
  };
  Draggable.prototype.getHelperElement = function (evt) {
    var element;
    if (this.clone) {
      if (this.helper) {
        element = this.helper({ sender: evt, element: this.target });
      } else {
        element = createElement("div", {
          className: "e-drag-helper e-block-touch",
          innerHTML: "Draggable",
        });
        document.body.appendChild(element);
      }
    } else {
      element = this.element;
    }
    return element;
  };
  Draggable.prototype.setGlobalDroppables = function (reset, drag, helper) {
    this.droppables[this.scope] = reset
      ? null
      : {
          draggable: drag,
          helper: helper,
          draggedElement: this.element,
        };
  };
  Draggable.prototype.checkTargetElement = function (evt) {
    var target = this.getProperTargetElement(evt);
    var dropIns = this.getDropInstance(target);
    if (!dropIns && target && !isNullOrUndefined(target.parentNode)) {
      var parent_1 =
        closest(target.parentNode, ".e-droppable") || target.parentElement;
      if (parent_1) {
        dropIns = this.getDropInstance(parent_1);
      }
    }
    return { target: target, instance: dropIns };
  };
  Draggable.prototype.getDropInstance = function (ele) {
    var name = "getModuleName";
    var drop;
    var eleInst = ele && ele.cn_instances;
    if (eleInst) {
      for (var _i = 0, eleInst_1 = eleInst; _i < eleInst_1.length; _i++) {
        var inst = eleInst_1[_i];
        if (inst[name]() === "droppable") {
          drop = inst;
          break;
        }
      }
    }
    return drop;
  };
  Draggable.prototype.destroy = function () {
    this.toggleEvents(true);
    _super.prototype.destroy.call(this);
  };
  var Draggable_1;
  __decorate([Complex({}, Position)], Draggable.prototype, "cursorAt", void 0);
  __decorate([Property(true)], Draggable.prototype, "clone", void 0);
  __decorate([Property()], Draggable.prototype, "dragArea", void 0);
  __decorate([Property()], Draggable.prototype, "isDragScroll", void 0);
  __decorate([Event()], Draggable.prototype, "drag", void 0);
  __decorate([Event()], Draggable.prototype, "dragStart", void 0);
  __decorate([Event()], Draggable.prototype, "dragStop", void 0);
  __decorate([Property(1)], Draggable.prototype, "distance", void 0);
  __decorate([Property()], Draggable.prototype, "handle", void 0);
  __decorate([Property()], Draggable.prototype, "abort", void 0);
  __decorate([Property()], Draggable.prototype, "helper", void 0);
  __decorate([Property("default")], Draggable.prototype, "scope", void 0);
  __decorate([Property("")], Draggable.prototype, "dragTarget", void 0);
  __decorate([Property()], Draggable.prototype, "axis", void 0);
  __decorate([Property()], Draggable.prototype, "queryPositionInfo", void 0);
  __decorate([Property(false)], Draggable.prototype, "enableTailMode", void 0);
  __decorate(
    [Property(false)],
    Draggable.prototype,
    "skipDistanceCheck",
    void 0
  );
  __decorate([Property(true)], Draggable.prototype, "preventDefault", void 0);
  __decorate(
    [Property(false)],
    Draggable.prototype,
    "enableAutoScroll",
    void 0
  );
  __decorate([Property(false)], Draggable.prototype, "enableTapHold", void 0);
  __decorate([Property(750)], Draggable.prototype, "tapHoldThreshold", void 0);
  Draggable = Draggable_1 = __decorate([NotifyPropertyChanges], Draggable);
  return Draggable;
})(Base);
export { Draggable };
