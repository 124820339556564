import React, { useState, useEffect, useReducer } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import localforage from 'localforage'
import Loading from 'react-loading'
import {
  AUTH_STATE,
  LOCAL_STORAGE_SUPER_CLINIC_AUTH_KEY_NAME
} from '../../Constants'
import api from '../../api'

function SuperClinicProtectedRoute({ children }) {
  const location = useLocation()

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setAuthStateSuccess':
        return AUTH_STATE.SUCCESS
      case 'setAuthStateFailed':
        return AUTH_STATE.FAILED
      case 'setAuthStateAuthenticate':
        return AUTH_STATE.AUTHENTICATING
    }
  }
  const [state, dispatch] = useReducer(reducer, AUTH_STATE.NOT_STARTED)

  useEffect(() => {
    dispatch({ type: 'setAuthStateAuthenticate' })
    localforage
      .getItem(LOCAL_STORAGE_SUPER_CLINIC_AUTH_KEY_NAME)
      .then(data => {
        if (data.id_token) {
          api.setAuthHeaders({ Authorization: data.id_token })
          dispatch({ type: 'setAuthStateSuccess' })
        } else {
          dispatch({ type: 'setAuthStateFailed' })
        }
      })
      .catch(err => {
        localStorage.clear()
        localforage.clear()
        dispatch({ type: 'setAuthStateFailed' })
        // console.log('Error retreiving item from local storage', err)
      })
  }, [])

  if (state === AUTH_STATE.AUTHENTICATING) {
    return (
      <div style={{ margin: '25% 45%' }}>
        Authenticating...
        <Loading type="cylon" textAlign="center" color="grey" height={40} />
      </div>
    )
  } else if (state === AUTH_STATE.FAILED) {
    return (
      <Redirect
        to={
          location.pathname.endsWith('/login')
            ? location.pathname
            : `${location.pathname}/login`
        }
      />
    )
  } else {
    return children
  }
}

export default SuperClinicProtectedRoute
