import { action, thunk } from 'easy-peasy'
import { NETWORK_STATUS } from '../../Constants'
import api from '../../api'
import moment from 'moment'
import localforage from 'localforage'
import { DOCTOR_USER_ID, USER_ROLE } from '../../Constants'
import BaseModel from '../Base'

export default {
  ...BaseModel(),

  hospitalId: null,
  associated_with: null,
  hospitalShortcode: null,
  hospitalLogo: null,
  idToken: null,
  userId: null,
  username: null,
  // profileUpdateStatus: false,
  usershortcode: '',
  profileUpdateLoader: false,
  walletDetails: [],
  doctorAppointmentTransactions: [],
  walletRequestList: [],
  doctorMsgTemplates: [],
  doctorGetMsgBalance: [],
  doctorSendMsgResponse: null,
  messageSentStatus: null,

  setData: action((state, payload) => {
    if (payload) {
      state.hospitalId = payload.hospital_details && payload.hospital_details.id
      state.associated_with =
        payload.hospital_details && payload.hospital_details.associated_with
      state.hospitalShortcode =
        payload.hospital_details && payload.hospital_details.hospital_shortcode
      state.hospitalLogo =
        payload.hospital_details && payload.hospital_details.logo
      state.idToken = payload.user && payload.user.id_token
      state.userId =
        payload.hospital_details && payload.hospital_details.user_id
      state.username = payload.user && payload.user.username
    }
  }),

  // update doctor profile
  updatedDoctorProfile: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    actions.setProfileUpdateLoader(true)
    const response = await api.doctor.updateDoctorProfile({
      doctor_id: payload && payload.doctor_id,
      password: payload && payload.password,
      name: payload && payload.firstname,
      user_name: payload && payload.username,
      email: payload && payload.email.toLowerCase(),
      user_short_code: payload && payload.user_short_code,
      picture_name:
        payload && payload?.file && payload?.file?.name
          ? payload?.file?.name
          : '',
      picture:
        payload && payload.format && payload.format[1] ? payload.format[1] : '',
      bio: payload && payload.bio,
      experience: payload && payload.experience,
      specialization: payload && payload.specialization,
      qualification: payload && payload.degree,
      mobile_no: payload && payload.mobile_no,
      prefix: payload && payload.prefix,
      clinic_name: payload?.clinic_name,
      clinic_address: payload?.clinic_address,
      associated_with: payload?.associated_with
    })

    if (response.ok) {
      // actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      localStorage.setItem(
        'user_short_code',
        payload && payload.user_short_code
      )
      actions.setProfileUpdateStatus(response?.data)
      actions.setUserShortCode(payload && payload.user_short_code)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setProfileUpdateStatus: action((state, payload) => {
    state.profileUpdateStatus = payload
  }),
  setUserShortCode: action((state, payload) => {
    state.usershortcode = payload
  }),

  setProfileUpdateLoader: action((state, payload) => {
    state.profileUpdateLoader = payload
  }),

  updateDoctorConsultationFees: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    // actions.setProfileUpdateStatus(false)

    const response = await api.doctor.updateConsultationFees({
      doctor_id: payload?.doctor_id,
      in_person_fees: payload?.in_person_fees,
      tele_fees: payload?.tele_fees
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      // actions.setProfileUpdateStatus(true)
      actions.setProfileUpdateLoader(false)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  doctorBankDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.doctor.addDoctorBankDetails({
      // user_master_id: localStorage.getItem("user_master_id"),
      user_master_id: localStorage.getItem(DOCTOR_USER_ID),
      user_id: localStorage.getItem(DOCTOR_USER_ID),
      primary_account_no: payload?.account_no,
      primary_branch_name: payload?.branch_name,
      primary_bank_name: payload?.bank_name,
      primary_ifsc_code: payload?.ifsc_code,
      primary_beneficiary_name: payload?.beneficiary_name
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDocbankdetailsAddedStatus(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDocbankdetailsAddedStatus: action((state, payload) => {
    state.docbankdetailsAddedStatus = payload
  }),

  getBankDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.doctor.getDoctorBankDetails({
      user_master_id: payload?.user_master_id
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getDoctorBankDetailList(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getDoctorBankDetailList: action((state, payload) => {
    state.getBankList = payload
  }),
  doctorWalletView: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      Authorization: localStorage.getItem('doctor_auth_key')
    })
    const response = await api.doctor.doctorWalletView({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID)
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      if (response?.data?.doctor_data) {
        actions.setWalletDetails(response?.data?.doctor_data)
      }
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setWalletDetails: action((state, payload) => {
    state.walletDetails = payload
  }),

  doctorWalletRequestList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      Authorization: localStorage.getItem('doctor_auth_key')
    })

    const response = await api.doctor.doctorWalletRequestList({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      table_config_rows_per_page: '10',
      table_config_page_no: payload?.currentPage
        ? String(payload?.currentPage)
        : '1',
      order_by: 'created_at',
      order: 'desc'
    })

    if (response?.data?.statusCode === 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setWalletRequestList(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setWalletRequestList: action((state, payload) => {
    state.walletRequestList = payload
  }),

  doctorWalletRequestAdd: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      Authorization: localStorage.getItem('doctor_auth_key')
    })
    const response = await api.doctor.doctorWalletRequestAdd({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      amount_requested: payload ? payload : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

      actions.docWalletAddResponse(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  docWalletAddResponse: action((state, payload) => {
    state.walletAdd = payload
  }),

  doctorWalletRequestUpdate: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.doctor.doctorWalletRequestUpdate({
      id: payload?.id ? payload?.id : '',
      amount_requested: payload?.amount ? payload?.amount : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.docWalletUpdateresponse(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  docWalletUpdateresponse: action((state, payload) => {
    state.walletUpdate = payload
  }),

  doctorWalletRequestCancel: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.doctor.doctorWalletRequestCancel({
      id: payload?.id ? payload?.id : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

      actions.docWalletCancelresponse(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  docWalletCancelresponse: action((state, payload) => {
    state.walletCancel = payload
  }),

  snackClear: action((state, payload) => {
    state.walletAdd = undefined
    state.walletUpdate = undefined
    state.walletCancel = undefined
    state.profileUpdateStatus = undefined
    state.docbankdetailsAddedStatus = undefined
  }),

  getDoctorAppointmentTransactions: thunk(async (actions, payload) => {
    actions.setDoctorAppointmentTransactions([])
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const { type, count, pattern } = payload
    api.setAuthHeaders({
      Authorization: localStorage.getItem('doctor_auth_key')
    })

    const response = await api.doctor.doctorAppointmentTransactions({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      list_type: type,
      table_config_rows_per_page: '10',
      table_config_page_no: `${count}`,
      pattern: pattern,
      order_by: 'date',
      order: 'desc'
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDoctorAppointmentTransactions(
        response?.data?.appointments_details
          ? (function () {
              response.data.appointments_details.forEach(item => { 
                item.localDateObject = moment(
                  `${item.date} ${item.time}`,
                  'DD-MM-YYYY hh:mm A'
                ).subtract({hours:'5', minutes:'30'})
                item.localTime = moment(item.localDateObject).format('hh:mm A')
                item.localDate = moment(item.localDateObject).format('DD/MM/YY')
              })
              return response.data
            })()
          : response?.data

      
      )
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDoctorAppointmentTransactions: action((state, payload) => {
    state.doctorAppointmentTransactions = payload
  }),

  getDoctorMsgTemplates: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      Authorization: localStorage.getItem('doctor_auth_key')
    })
    const response = await api.doctor.doctorGetMsgTemplates({})
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDoctorMsgTemplates(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDoctorMsgTemplates: action((state, payload) => {
    state.doctorMsgTemplates = payload
  }),

  getDoctorMsgBalance: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      Authorization: localStorage.getItem('doctor_auth_key')
    })
    const response = await api.doctor.doctorGetMsgBalance({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID)
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDoctorMsgBalance(response?.data?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDoctorMsgBalance: action((state, payload) => {
    state.doctorGetMsgBalance = payload
  }),

  sendMsgToPatients: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const { template_id, is_all, patient_id_list } = payload
    api.setAuthHeaders({
      Authorization: localStorage.getItem('doctor_auth_key')
    })
    const response = await api.doctor.doctorSendMsg({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id_list: patient_id_list,
      is_all: is_all,
      template_id: template_id
    })
    //neeed to pass response data for both success and fail due to snackbar alert functionality
    if (response.statusCode === 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDoctorSendMessageResponse(response?.data)
      actions.setSendMessageStatus(true)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      actions.setDoctorSendMessageResponse(response?.data)
      actions.setSendMessageStatus(false)
    }
  }),
  setDoctorSendMessageResponse: action((state, payload) => {
    state.doctorSendMsgResponse = payload
  }),

  setSendMessageStatus: action((state, payload) => {
    state.messageSentStatus = payload
  }),

  medicalAsstDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.doctor.medicalAsstCreate({
      hospital_id: '',
      department_id: '',
      first_name: payload?.first_name,
      // middle_name: payload?.middle_name,
      last_name: payload?.last_name,
      mobile_no: payload?.mobile_no,
      email: payload?.email.toLowerCase(),
      address: payload?.address,
      pincode: payload?.pincode,
      dob: payload?.dob,
      gender: payload?.gender,
      username: payload?.username,
      user_id: payload?.user_id,
      picture:
        payload && payload.format && payload.format[1] ? payload.format[1] : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setMedicalStaffStatus(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setMedicalStaffStatus: action((state, payload) => {
    state.medicalStaffUpdate = payload
  }),

  medicalUpdateDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.doctor.medicalAsstUpdate({
      hospital_id: '',
      department_id: '',
      first_name: payload?.first_name,
      // middle_name: payload?.middle_name,
      last_name: payload?.last_name,
      mobile_no: payload?.mobile_no,
      email: payload?.email.toLowerCase(),
      address: payload?.address,
      pincode: payload?.pincode,
      dob: payload?.dob,
      gender: payload?.gender,
      username: payload?.username,
      user_id: payload?.user_id,
      picture:
        payload && payload.format && payload.format[1] ? payload.format[1] : '',
      picture_name:
        payload && payload.format && payload.format[0] ? payload.format[0] : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setMedicalStaffUpdate(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setMedicalStaffUpdate: action((state, payload) => {
    state.medicalStaffUpdate = payload
  })
}
