import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import localforage from 'localforage'
import {
  Grid,
  Avatar,
  Box,
  Backdrop,
  Fade,
  makeStyles
} from '@material-ui/core'
import { LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME } from '../../Constants'
import copy from 'copy-to-clipboard'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { DoctorPublicStore, DoctorStore } from '../../stores'
import CarenowLogo from '../../images/carenow-bottom-logo.jpg'
import Logo from '../../images/logo.png'
import DefaultImage from '../../images/card-bg.jpg'
import profilepic from '../../images/doctor-img3.png'
import whitelogo from '../../images/white-logo.png'
import barcode from '../../images/bar-code.jpg'
import MediaBgImg from '../../images/right-top-img.jpg'
import ListBgImg from '../../images/bg2.png'
import ListBgHoverImg from '../../images/listbghover.png'
import CheckedInTopImg from '../../images/right-top-img2.png'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share'
import TextField from '@material-ui/core/TextField'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AppointmentListItem from '../CommonComponents/AppointmentListItem'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import Loading from 'react-loading'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { HOME_URL } from '../../Environment'

var QRCode = require('qrcode.react')

export default function ProfileShareCard() {
  const classes = useStyles()
  const [qrCodeLink, setQRLink] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [qrCodeLinkGenerate, setqrCodeLinkGenerate] = React.useState(false)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  const profile = DoctorStore.useStoreState(state => ({
    ...state.profile
  }))

  const { fetchDetails } = DoctorPublicStore.useStoreActions(actions => ({
    ...actions.doctorProfile
  }))

  const { image, name, prefix, user_short_code, degree, specialization } =
    DoctorPublicStore.useStoreState(state => ({
      ...state.doctorProfile
    }))

  useEffect(() => {
    if (user_short_code) {
      var link = HOME_URL + user_short_code
      setQRLink(link)
      setqrCodeLinkGenerate(true)
    }
  }, [user_short_code])

  useEffect(() => {
    localforage
      .getItem(LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME)
      .then(data => {
        if (data.hospital_details.user_short_code) {
          let shortCode = data.hospital_details.user_short_code
          let logo = data.hospital_details.logo

          fetchDetails({ shortCode })
        }
      })
      .catch(err => {
        // console.log('Error retreiving item from local storage', err)
      })

    window.scrollTo(0, 0)

    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    // const url = "https://files-carenowdoc-com-official-website-development.s3.ap-south-1.amazonaws.com/doctor/3de71047-f3bb-4e6b-805b-8bef5139e638/profile_photo/ swaroop.JPG2021-01-22 11:06:23.622992.JPG"; // site that doesn’t send Access-Control-*
    // fetch(proxyurl + url) // https://cors-anywhere.herokuapp.com/https://example.com
    // // .then(response => response.text())
    // .then(contents => console.log(contents.url))
    // .catch(() => console.log("Can’t access " + url + " response. Blocked by browser?"))

    // console.log('https://files-carenowdoc-com-official-website-development.s3.ap-south-1.amazonaws.com/doctor/3de71047-f3bb-4e6b-805b-8bef5139e638/profile_photo/ swaroop.JPG2021-01-22 11:06:23.622992.JPG'.replace(/\s/g, ''))
  }, [])

  // const getBase64FromUrl = async (dataurl) => {
  //   const data = await fetch({
  //     url : dataurl,
  //     mode: 'no-cors',
  //     credentials: 'include',
  //     method: 'POST',
  //     headers: {
  //       "X-Requested-With": "XMLHttpRequest"
  //     },
  // });
  //   const blob = await data.blob();
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(blob);
  //     reader.onloadend = function() {
  //       const base64data = reader.result;
  //       resolve(base64data)
  //     }
  //   });
  // }

  const printDocument = () => {
    const input = document.getElementById('divToPrint')

    html2canvas(input, {
      mode: 'no-cors',
      allowTaint: true,
      scale: 1
    }).then(canvas => {
      let img = new Image()
      img.src = canvas.toDataURL('image/png', 1.0)
      img.onload = function () {
        let pdf = new jsPDF('portrait', 'mm', 'a4')
        pdf.addImage(img.src, -20, 0, 250, 200)
        pdf.save('profile_print.pdf')
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const shareDocument = () => {
    setOpen(true)
  }

  const clipboard = () => {
    copy(qrCodeLink)
    setOpenSnackBar(true)
    setSnackBarMessage('Your Profile link is stored in Clipboard!')
    setmultiButton(false)
    setAlertType('success')
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <div>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        // secondaryClick={validationCheck}
      />
      <Box className={classes.Profilecard}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ marginBottom: '10px' }}
            id="divToPrint">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 650,
                margin: '0 auto 20px'
              }}>
              <h2
                style={{
                  textAlign: 'left',
                  fontSize: 20,
                  fontWeight: 500,
                  margin: 0
                }}>
                Profile Card
              </h2>
              <Box className={classes.Btncol}>
                <button className={classes.Sharebtn} onClick={shareDocument}>
                  Share Now
                </button>
                <button
                  className={classes.Downloadbtn}
                  disabled={!image}
                  onClick={printDocument}>
                  Download
                </button>
              </Box>
            </div>

            <Box className="Cardprofile">
              <Box className="card-img">
                <img src={DefaultImage} alt="" />
              </Box>
              <Box className="profile-top">
                <Box className="profile-col">
                  <Box className="top-logo">
                    {' '}
                    <img src={whitelogo} alt="" style={{ width: '160px' }} />
                  </Box>
                  {/* <Box className="profile-row">
                    <Box className="profile-info">
                      {!prefix && !name && !degree ? (
                        <Loading
                          type="bubbles"
                          textAlign="center"
                          color="#9138aa"
                          height={50}
                        />
                      ) : (
                        <React.Fragment>
                          <h3>{prefix + '. ' + name}</h3>
                          <p>
                            <span style={{ color: '#ab358f' }}>
                              {specialization}
                              {
                                'NeuroPsychiatrist ; de addiction spl & Sexologist'
                              }
                            </span>
                            <br />{' '}
                            {
                              'MBBS,MD(PSY),DPM,DCHM(NIMHANS,Bangalore),MIPS,DCHM(CIP,ranchi),CCDM(Apolo),LMIA(Sexology)'
                            }
                            {degree}
                          </p>
                          <p></p>
                        </React.Fragment>
                      )}
                    </Box>
                    <div
                      className="profile-img"
                      style={{
                        width: '10.625rem',
                        height: '10.625rem',
                        position: 'relative'
                      }}>
                      <img
                        id="profileImg"
                        className={classes.AvatarCol}
                        alt={name}
                        src={
                          localStorage.getItem('profileImage') !== 'null'
                            ? localStorage.getItem('profileImage')
                            : DefaultImage
                        }
                      />
                    </div>
                  </Box> */}
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateRows: '5.3125rem max-content',
                      gridTemplateColumns: '1fr 10.625rem',
                      gridColumnGap: '1rem',
                      columnGap: '1rem'
                    }}>
                    {!prefix && !name && !degree ? (
                      <Loading
                        type="bubbles"
                        textAlign="center"
                        color="#9138aa"
                        height={50}
                      />
                    ) : (
                      <React.Fragment>
                        <h3
                          style={{
                            justifySelf: 'flex-end',
                            alignSelf: 'flex-end',
                            fontSize: '24px',
                            fontWeight: 900,
                            color: '#fff',
                            margin: 0,
                            padding: 0,
                            paddingBottom: '0.5rem'
                          }}>
                          {prefix + '. ' + name}
                        </h3>
                        <div
                          style={{
                            display: 'grid',
                            padding: '0.5rem 0 0 0',
                            gridAutoRows: 'max-content',
                            gridAutoColumns: 'max-content',
                            gridRowGap: '0.5rem',
                            fontWeight: 700,
                            fontSize: '18px',
                            justifyItems: 'flex-end',
                            justifyContent: 'flex-end'
                          }}>
                          <div
                            style={{
                              color: '#ab358f',
                              maxWidth: '20rem',
                              'overflow-wrap': 'break-word',
                              'word-wrap': 'break-word',
                              hyphens: 'auto'
                            }}>
                            {specialization}
                          </div>
                          <div
                            style={{
                              maxWidth: '20rem',
                              'overflow-wrap': 'break-word',
                              'word-wrap': 'break-word',
                              hyphens: 'auto'
                            }}>
                            {degree}
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    <div
                      style={{
                        gridRow: '1 / 3',
                        gridColumn: '2 / 3',
                        width: '10.625rem',
                        height: '10.625rem',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        border: '6px solid #fff',
                        boxShadow: '0 0.2rem 0.5rem rgba(0, 0, 0, 0.2)',
                        backgroundColor: '#fff'
                      }}>
                      <img
                        id="profileImg"
                        alt={name}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                        src={
                          localStorage.getItem('profileImage') !== 'null'
                            ? localStorage.getItem('profileImage')
                            : DefaultImage
                        }
                      />
                    </div>
                  </div>
                </Box>
                <Box className="barcode-col">
                  <h5>Scan to book an appointment</h5>
                  <Box className="code-box">
                    {qrCodeLinkGenerate ? (
                      <QRCode value={qrCodeLink} />
                    ) : (
                      'Loading...'
                    )}
                  </Box>
                  <p>
                    Or Visit:
                    <br /> {HOME_URL + user_short_code}
                  </p>
                  <Box className="contact-col">
                    Call 033-418-16533 for Virtual Medical Assistance
                  </Box>
                  <h6>Powered by CareNowDoc | www.carenowdoc.com</h6>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        classes={{ paper: classes.modalView }}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Profile Share
          <span className={classes.cross} onClick={handleClose}>
            Close
          </span>
        </DialogTitle>
        <DialogContent>
          <div className={classes.viewModal}>
            <TextField
              id="outlined-basic"
              value={qrCodeLink}
              variant="outlined"
              style={{ width: '80%' }}
            />{' '}
            <AssignmentIcon
              onClick={clipboard}
              className={classes.AssignButton}
            />
          </div>
          <div className={classes.buttons}>
            <FacebookShareButton
              url={qrCodeLink}
              quote={'CareNowDoc'}
              className={classes.socialMediaButton}>
              <FacebookIcon size={36} />
            </FacebookShareButton>

            <TwitterShareButton
              url={qrCodeLink}
              quote={'CareNowDoc'}
              className={classes.socialMediaButton}>
              <TwitterIcon size={36} />
            </TwitterShareButton>

            <WhatsappShareButton
              url={qrCodeLink}
              quote={'CareNowDoc'}
              className={classes.socialMediaButton}>
              <WhatsappIcon size={36} />
            </WhatsappShareButton>

            <EmailShareButton
              url={qrCodeLink}
              quote={'CareNowDoc'}
              className={classes.socialMediaButton}>
              <EmailIcon size={36} />
            </EmailShareButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  cross: {
    cursor: 'pointer',
    float: 'right',
    color: '#f76f6f'
  },
  Cardprofile: {
    backgroundColor: '#fff',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    backgroundAttachment: 'fixed',
    position: 'relative'
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    minHeight: '100vh'
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '10px',
    marginTop: '20px',
    background: 'transparent',
    '@media (max-width:1024px)': {
      marginTop: '10px'
    }
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%'
  },
  plus: {
    color: '#fff',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    fontSize: '25px',
    background: '#3f4b79',
    fontWeight: 'bold',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0',
    marginRight: '7px'
  },
  Profilecard: {
    width: '100%',
    padding: '10px 20px',
    '@media (max-width:480px)': {
      padding: '10px 10px'
    },
    '& h2': {
      '@media (max-width:767px)': {
        marginTop: '0px'
      }
    }
  },
  ProfilecolTop: {
    width: '100%',
    height: '0px',
    background: `url(${CheckedInTopImg}) center top no-repeat`,
    backgroundSize: 'cover'
  },
  ProfilecolMiddle: {
    width: '100%',
    padding: '25px 30px',
    background: `url(${ListBgImg}) center top no-repeat`,
    backgroundSize: 'cover'
  },
  ProfileImage: {
    width: '170px',
    height: '170px',
    borderRadius: '50%',
    marginBottom: '20px',
    '&>div': {
      width: '170px',
      height: '170px',
      border: '6px solid #ededed'
    }
  },
  Profilecol: {
    width: '100%',
    border: '1px #ccc solid'
  },
  ProfileLeft: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    '& h3': {
      fontSize: '22px',
      color: '#3f4b79',
      fontWeight: 'bold'
    },
    '& p': {
      fontSize: '16px',
      margin: '0px'
    }
  },
  ProfilecolBottom: {
    width: '100%',
    padding: '20px 30px',
    background: `url(${ListBgHoverImg}) center top no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& img': {
      width: '102px',
      height: '28px'
    },
    '& p': {
      fontSize: '14px',
      color: '#fff',
      marginBottom: '0px'
    }
  },
  Barcode: {
    width: '100%',
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      fontSize: '16px',
      textAlign: 'center',
      marginTop: '30px'
    },
    '& h4': {
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '30px',
      marginBottom: '25px'
    }
  },
  Toplogo: {
    width: '100%',
    marginBottom: '75px'
  },
  Btncol: {
    display: 'flex',
    padding: '0 10px 0'
  },
  Sharebtn: {
    width: '150px',
    cursor: 'pointer',
    marginRight: '20px',
    height: '40px',
    background: '#fff',
    fontSize: '14px',
    color: '#F2762F',
    border: '1px #F2762F solid',
    textAlign: 'center',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0px',
    '@media (max-width:600px)': {
      width: '40%'
    }
  },
  Downloadbtn: {
    width: '150px',
    cursor: 'pointer',
    height: '40px',
    background: 'linear-gradient(to right bottom, #3267F1, #3E3BE8)',
    fontSize: '14px',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    '@media (max-width:600px)': {
      width: '40%'
    }
  },
  Btnrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  viewModal: {
    padding: '30px',
    backgroundColor: '#fff'
  },
  buttons: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#fff',
    paddingBottom: '20px'
  },
  ModalBox: {
    width: '40%',
    height: '35%',
    background: '#fff',
    textAlign: 'center',
    padding: '0px',
    outline: 'none',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      color: '#fff',
      background: '#111d4e',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  AssignButton: {
    marginTop: '20px',
    cursor: 'pointer',
    color: '#9138aa'
  }
}))
