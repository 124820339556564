import React, { useState } from 'react'
import {
  Button,
  IconButton,
  Checkbox,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  withStyles,
  colors
} from '@material-ui/core'
import { Delete, Error } from '@material-ui/icons'

import { ClinicStore } from '../../../../../stores'
import { NETWORK_STATUS } from '../../../../../Constants'
import ConfirmationDialog from './ConfirmationDialog'

const ModifiedCheckbox = withStyles({
  root: {
    height: '1rem',
    width: '1rem'
  },
  checked: {}
})(props => <Checkbox color="primary" {...props} />)

const ServicesTaken = ({
  loadStatusAll,
  checkedServicesMarkingList,
  handleChangeMarkingLists,
  checkedAndPaidServicesList,
  checkedAndNotPaidServicesList,
  setCheckedAndPaidServicesList,
  setCheckedAndNotPaidServicesList,
  openConfirmationModal,
  setOpenConfirmationModal,
  onClickMarkAsPaid,
  onClickPrintInvoice,
  onDeleteButtonClicked,
  paymentModalOpen,
  closePaymentModal,
  handlePaymentSubmit,
  paymentModalPaymentValue,
  setPaymentModalPaymentValue,
  paymentModes,
  doctorPaid,
  appointmentData
}) => {
  const classes = useStyles()

  const { takenServiceList } = ClinicStore.useStoreState(state => ({
    ...state.appointment
  }))

  const [listPassedToConfirDialog, setListPassedToConfirDialog] = useState([])
  const [confirmDialogHeader, setConfirmDialogHeader] = useState('')

  const addAndRemoveFromStateList = (
    curState,
    serviceInfo = {
      patientServiceId: '',
      nameOfTheService: '',
      totalPrice: ''
    }
  ) => {
    const index = curState.findIndex(
      item => serviceInfo.patientServiceId === item.patientServiceId
    )
    if (index === -1) {
      return [...curState, serviceInfo]
    }
    return curState.filter(
      item => item.patientServiceId !== serviceInfo.patientServiceId
    )
  }

  const handleCheckBoxChange = (
    patientServiceId,
    isServicePaid = false,
    serviceInfo = {
      nameOfTheService: '',
      totalPrice: ''
    }
  ) => {
    // This is used for handling checkboxs' state changes
    handleChangeMarkingLists(curState => {
      const index = curState.indexOf(patientServiceId)
      if (index === -1) {
        return [...curState, patientServiceId]
      }
      return curState.filter(item => item !== patientServiceId)
    })

    if (isServicePaid) {
      setCheckedAndPaidServicesList(curState =>
        addAndRemoveFromStateList(curState, {
          patientServiceId: patientServiceId,
          ...serviceInfo
        })
      )
    } else {
      setCheckedAndNotPaidServicesList(curState =>
        addAndRemoveFromStateList(curState, {
          patientServiceId: patientServiceId,
          ...serviceInfo
        })
      )
    }
  }

  let totalPayableAmount = +appointmentData?.doctor_fee
  let totalPaymentLeft = doctorPaid ? 0 : +appointmentData?.doctor_fee
  let subTotal = +appointmentData?.doctor_fee
  let totalGST = 0

  for (const ts of takenServiceList) {
    const {
      service_amount: serviceAmount,
      service_gst: serviceGST,
      service_payment_status: servicePaymentStatus
    } = ts

    if (+servicePaymentStatus === 2) {
      totalPaymentLeft = totalPaymentLeft + +serviceAmount + +serviceGST
    }

    totalPayableAmount = totalPayableAmount + +serviceAmount + +serviceGST
    totalGST = totalGST + +serviceGST
    subTotal = subTotal + +serviceAmount
  }

  const paidServiceIdList = []

  takenServiceList.forEach(item => {
    if (+item?.service_payment_status === 1) {
      paidServiceIdList.push(item.patient_service_id)
    }
  })

  return (
    <React.Fragment>
      <ConfirmationDialog
        open={openConfirmationModal}
        closeModal={() => setOpenConfirmationModal(false)}
        listDisplay={listPassedToConfirDialog}
        headingText={confirmDialogHeader}
        onClickingProceed={
          confirmDialogHeader === 'Payment Confirmation'
            ? onClickMarkAsPaid
            : onClickPrintInvoice
        }
      />
      <Dialog
        open={paymentModalOpen}
        onClose={closePaymentModal}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Choose Doctor Appointment Payment mode
        </DialogTitle>
        <DialogContent>
          <FormControl
            style={{ display: 'block', width: '70%', margin: 'auto' }}>
            <InputLabel htmlFor="max-width">Payment Mode</InputLabel>
            <Select
              autoFocus
              style={{ display: 'inline-block', width: '90%' }}
              value={
                paymentModalPaymentValue
                  ? paymentModalPaymentValue.toUpperCase()
                  : null
              }
              onChange={e => setPaymentModalPaymentValue(e.target.value)}>
              {paymentModes.map(i => (
                <MenuItem key={i} value={i.toUpperCase()}>
                  {i
                    .split('-')
                    .map(word => word[0].toUpperCase() + word.substring(1))
                    .join(' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlePaymentSubmit}
            disabled={!paymentModalPaymentValue}
            color="primary">
            Proceed
          </Button>
          <Button onClick={closePaymentModal} color="secondary" autoFocus>
            Exit
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.amountSectionTop}>
        {loadStatusAll === NETWORK_STATUS.FETCHING ? (
          <CircularProgress />
        ) : loadStatusAll === NETWORK_STATUS.FAILED ? (
          <div className={classes.amountAmountRed}>
            Unable to fetch the data!
          </div>
        ) : (
          <React.Fragment>
            {!!totalPaymentLeft && (
              <React.Fragment>
                <div className={classes.amountTitle}>
                  Amount Payable:{' '}
                  <span className={classes.amountAmount}>
                    <i class="fa fa-inr" aria-hidden="true"></i>
                    {totalPayableAmount.toFixed(2)}
                  </span>
                </div>
                <div className={classes.amountTitle}>
                  Payment Left:{' '}
                  <span className={classes.amountAmountRed}>
                    <i class="fa fa-inr" aria-hidden="true"></i>
                    {totalPaymentLeft.toFixed(2)}
                  </span>
                </div>
              </React.Fragment>
            )}

            {!totalPaymentLeft && (
              <div className={classes.amountTitle}>
                Total Amount Paid:{' '}
                <span className={classes.amountAmountGreen}>
                  <i class="fa fa-inr" aria-hidden="true"></i>
                  {totalPayableAmount.toFixed(2)}
                </span>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <div className={classes.sectionHeaderDiv}>
        <h3 style={{ margin: 0 }}>Services Taken </h3>{' '}
        <Button
          onClick={() => {
            setOpenConfirmationModal(true)
            setConfirmDialogHeader('Generate Invoice for Paid Services')
            setListPassedToConfirDialog(checkedAndPaidServicesList)
          }}
          color="primary"
          variant="contained"
          disabled={
            // !takenServiceList.length ||
            !checkedAndPaidServicesList.length ||
            loadStatusAll === NETWORK_STATUS.FETCHING ||
            loadStatusAll === NETWORK_STATUS.FAILED
          }
          style={{ height: '2.5rem' }}>
          Print Invoice
        </Button>{' '}
        <Button
          onClick={() => {
            setOpenConfirmationModal(true)
            setConfirmDialogHeader('Payment Confirmation')
            setListPassedToConfirDialog(checkedAndNotPaidServicesList)
          }}
          color="secondary"
          variant="contained"
          disabled={
            // !takenServiceList.length ||
            !checkedAndNotPaidServicesList.length ||
            loadStatusAll === NETWORK_STATUS.FETCHING ||
            loadStatusAll === NETWORK_STATUS.FAILED
            // || takenServiceList.filter(item => +item?.service_payment_status === 2)
            //   .length === 0
          }
          style={{ height: '2.5rem' }}
          className={classes.markAsPaidButton}>
          Mark As Paid
        </Button>
      </div>
      {loadStatusAll === NETWORK_STATUS.FETCHING ? (
        <div className={classes.containerLoading}>
          <CircularProgress />
        </div>
      ) : loadStatusAll === NETWORK_STATUS.FAILED ? (
        <div className={classes.containerLoading}>
          <div className={classes.hintText}>
            <Error />
            Oops! Something went wrong. Try again later!
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          <ul className={classes.listsContainer}>
            <li className={classes.listItem}>
              <div className={classes.itemInnerContentGrid}>
                <div className={classes.itemInnerContent}>
                  {/* <GreenCheckbox
                    checked={true}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  /> */}
                  {/* <div style={{ height: '2.25rem', width: '2.25rem' }}></div> */}
                  <ModifiedCheckbox
                    checked={
                      checkedServicesMarkingList.indexOf(
                        '---doctor--apointment---'
                      ) !== -1
                    }
                    onChange={() =>
                      handleCheckBoxChange(
                        '---doctor--apointment---',
                        doctorPaid,
                        {
                          nameOfTheService: 'Doctor Fees',
                          totalPrice: `${appointmentData?.doctor_fee}`
                        }
                      )
                    }
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </div>
                <div className={classes.itemInnerContent}>Doctor Fees</div>
                {doctorPaid ? (
                  <div
                    className={[classes.itemInnerContent, classes.paid].join(
                      ' '
                    )}>
                    Paid
                  </div>
                ) : (
                  <div
                    className={[classes.itemInnerContent, classes.notPaid].join(
                      ' '
                    )}>
                    Not Paid
                  </div>
                )}
                <div
                  className={classes.itemInnerContent}
                  style={{ fontSize: '75%' }}>
                  <i class="fa fa-inr" aria-hidden="true"></i>
                  {appointmentData?.convenience_charge} (Convenience fee)
                </div>
                <div
                  className={[
                    classes.itemInnerContent,
                    classes.justifySelfEnd
                  ].join(' ')}>
                  <i class="fa fa-inr" aria-hidden="true"></i>
                  {appointmentData?.doctor_fee}
                </div>
                <div className={classes.itemInnerContent}>
                  <div style={{ height: '1rem', width: '1rem' }}></div>
                  {/* <IconButton
                    onClick={() => {}}
                    size="small"
                    style={{ height: '1rem', width: '1rem' }}
                    className={classes.iconButton}>
                    <Delete color="error" />
                  </IconButton> */}
                </div>
              </div>
              <Divider />
            </li>
            {takenServiceList.map(ts => {
              const {
                patient_service_id: patientServiceId,
                service_name: serviceName,
                service_payment_status: servicePaymentStatus,
                service_amount: serviceAmount,
                service_gst: serviceGST
              } = ts
              let checkBoxCheckedStatus =
                checkedServicesMarkingList.indexOf(patientServiceId) !== -1
              return (
                <li className={classes.listItem} key={patientServiceId}>
                  <div className={classes.itemInnerContentGrid}>
                    <div className={classes.itemInnerContent}>
                      <ModifiedCheckbox
                        checked={checkBoxCheckedStatus}
                        onChange={() =>
                          handleCheckBoxChange(
                            patientServiceId,
                            +servicePaymentStatus === 1,
                            {
                              nameOfTheService: serviceName,
                              totalPrice: `${(
                                +serviceAmount + +serviceGST
                              ).toFixed(2)}`
                            }
                          )
                        }
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </div>
                    <div className={classes.itemInnerContent}>
                      {serviceName}
                    </div>
                    {+servicePaymentStatus === 1 ? (
                      <div
                        className={[
                          classes.itemInnerContent,
                          classes.paid
                        ].join(' ')}>
                        Paid
                      </div>
                    ) : (
                      <div
                        className={[
                          classes.itemInnerContent,
                          classes.notPaid
                        ].join(' ')}>
                        Not Paid
                      </div>
                    )}
                    <div
                      className={classes.itemInnerContent}
                      style={{ fontSize: '75%' }}>
                      <i class="fa fa-inr" aria-hidden="true"></i>
                      {serviceGST} (GST)
                    </div>
                    <div
                      className={[
                        classes.itemInnerContent,
                        classes.justifySelfEnd
                      ].join(' ')}>
                      <i class="fa fa-inr" aria-hidden="true"></i>
                      {serviceAmount}
                    </div>
                    <div className={classes.itemInnerContent}>
                      {+servicePaymentStatus === 1 ? (
                        <div style={{ height: '1rem', width: '1rem' }}></div>
                      ) : (
                        <IconButton
                          onClick={() =>
                            onDeleteButtonClicked(patientServiceId)
                          }
                          size="small"
                          style={{ height: '1rem', width: '1rem' }}
                          className={classes.iconButton}>
                          <Delete color="error" />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  <Divider />
                </li>
              )
            })}
            {/* <li className={classes.listItem}>
            <div className={classes.itemInnerContentGrid}>
              <div className={classes.itemInnerContent}>
                <ModifiedCheckbox
                  checked={true}
                  onChange={() => {}}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
              <div className={classes.itemInnerContent}>X-Ray</div>
              <div
                className={[classes.itemInnerContent, classes.notPaid].join(
                  ' '
                )}>
                Not Paid
              </div>
              <div className={classes.itemInnerContent}>
                <i class="fa fa-inr" aria-hidden="true"></i>500.00
              </div>
              <div className={classes.itemInnerContent}>
                <IconButton
                  onClick={() => {}}
                  size="small"
                  style={{ height: '1rem', width: '1rem' }}
                  className={classes.iconButton}>
                  <Delete color="error" />
                </IconButton>
              </div>
            </div>
            <Divider />
          </li> */}
          </ul>
        </div>
      )}
      <div className={classes.sumSection}>
        {loadStatusAll === NETWORK_STATUS.FETCHING ? (
          <CircularProgress />
        ) : loadStatusAll === NETWORK_STATUS.FAILED ? (
          <div className={classes.amountAmountRed}>
            Unable to fetch the data!
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.sumSectionTitle}>Sub Total</div>
            <div className={classes.sumSectionAmount}>
              <i class="fa fa-inr" aria-hidden="true"></i>
              {subTotal.toFixed(2)}
            </div>
            <div className={classes.sumSectionTitle}>Total GST</div>
            <div className={classes.sumSectionAmount}>
              <span style={{ padding: '0 2rem 0 0' }}>(@ 18%)</span>
              <span>
                <i class="fa fa-inr" aria-hidden="true"></i>
                {totalGST.toFixed(2)}
              </span>
            </div>
            <Divider
              variant="fullWidth"
              className={classes.sumSectionFullWidth}
            />
            <div className={classes.sumSectionTitle}>Grand Total</div>
            <div className={classes.sumSectionAmount}>
              <i class="fa fa-inr" aria-hidden="true"></i>
              {totalPayableAmount.toFixed(2)}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default ServicesTaken

const useStyles = makeStyles(theme => ({
  amountSectionTop: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridColumnGap: '1rem',
    columnGap: '1rem',
    gridRowGap: '0.3rem',
    rowGap: '0.3rem',
    justifyContent: 'end'
  },
  sectionHeaderDiv: {
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridAutoRows: 'min-content',
    gridColumnGap: '1rem',
    columnGap: '1rem',
    marginBottom: '1rem'
  },
  container: {
    height: '30vh',
    overflowY: 'scroll',
    border: `1px solid ${colors.grey[500]}`,
    borderRadius: theme.spacing(1),
    padding: '0 !important'
  },
  containerLoading: {
    height: '30vh',
    border: `1px solid ${colors.grey[500]}`,
    borderRadius: theme.spacing(1),
    padding: '0 !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  listsContainer: {
    margin: '0',
    padding: '0.3rem 1rem',
    listStyleType: 'none',
    display: 'grid',
    gridAutoRows: 'min-content',
    gridAutoFlow: 'rows',
    gridRowGap: '0.5rem',
    rowGap: '0.5rem'
  },
  listItem: {
    margin: '0',
    padding: '0',
    height: '2rem',
    display: 'block'
    // display: 'grid',
    // gridAutoFlow: 'column',
    // gridTemplateColumns: '1fr min-content',
    // alignItems: 'center',
    // gridColumnGap: '0.5rem',
    // columnGap: '0.5rem'
  },
  paid: {
    color: colors.green[600]
  },
  notPaid: {
    color: colors.red[600]
  },
  itemInnerContentGrid: {
    display: 'grid',
    gridTemplateColumns: 'min-content repeat(4, 1fr) min-content',
    alignItems: 'center',
    gridColumnGap: '0.8rem',
    columnGap: '0.8rem'
  },
  itemInnerContent: {
    lineHeight: '1',
    verticalAlign: 'middle',
    fontSize: '90%'
  },
  justifySelfEnd: {
    justifySelf: 'end'
  },
  // button: {
  //   padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
  // },
  markAsPaidButton: {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.getContrastText(theme.palette.success.dark),
    '&:hover, &:focus': {
      backgroundColor: theme.palette.success.main
    }
  },
  sumSection: {
    margin: '0.8rem 3rem 0.8rem 0',
    fontSize: '100%',
    lineHeight: '1',
    verticalAlign: 'middle',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, max-content)',
    gridAutoRows: 'min-content',
    alignItems: 'center',
    justifyContent: 'end',
    gridColumnGap: '0.5rem',
    columnGap: '0.5rem',
    gridRowGap: '0.4rem',
    rowGap: '0.4rem'
  },
  sumSectionTitle: {
    gridColumn: '1 / 2',
    fontWeight: 'bold',
    fontSize: '107%'
  },
  sumSectionAmount: {
    gridColumn: '2 / 3',
    textAlign: 'right'
  },
  sumSectionFullWidth: {
    gridColumn: '1 / -1'
  },
  amountTitle: {
    fontSize: '90%',
    lineHeight: '1',
    textAlign: 'right',
    marginBottom: '0.5rem',
    color: colors.grey[600]
  },
  amountAmount: {
    fontSize: '150%',
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  amountAmountRed: {
    fontSize: '150%',
    fontWeight: 'bold',
    color: theme.palette.error.main
  },
  amountAmountGreen: {
    fontSize: '150%',
    fontWeight: 'bold',
    color: theme.palette.success.main
  },
  hintText: {
    display: 'grid',
    gridColumnGap: '0.5rem',
    columnGap: '0.5rem',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    lineHeight: '1',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.error.main
  }
}))
