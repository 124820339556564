import React, { useEffect, useState, useCallback } from 'react'
import { NETWORK_STATUS } from '../../Constants'
import Pagination from '@material-ui/lab/Pagination'
import { FaSearch } from 'react-icons/fa'
import Loading from 'react-loading'
import {
  TextField,
  Paper,
  Grid,
  FormControl,
  makeStyles,
  FormControlLabel,
  Box,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  Button
} from '@material-ui/core'
import { DoctorStore } from '../../stores'
import NoRecordImg from '../../images/norecord.png'
import AppointmentListItem from '../CommonComponents/AppointmentListItem'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import SmsInitialReminderImg from '../../images/sms-initial-reminder.png'
import SmsFollowReminderImg from '../../images/sms-followup-reminder.png'
import SmsFollowImg from '../../images/sms-followup.png'
import SmsPlainImg from '../../images/sms-plain.png'
import SmsPromotionalImg from '../../images/sms-promotional.png'
import SmsDefaultImg from '../../images/sms-default.png'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import useDebounce from '../../customHooks/useDebounce'

export default function SMSList() {
  const [status, setStatus] = React.useState('')
  const [sortBy, setSortBy] = React.useState('')
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [open, setConfirmOpen] = React.useState(false)
  const [searchDataBackUp, setSearchdataBackUp] = React.useState({})
  const [searchKeyWord, setSearchKeyWord] = React.useState('')
  const [initialPage, setInitialPage] = React.useState(0)
  const [pageCount, setPageCount] = React.useState(10)
  const [contentPerPage, setContentPerPage] = React.useState(10)
  const [finalPage, setFinalPage] = React.useState(contentPerPage)
  const [availableMsg, setAvailableMsg] = React.useState('Loading...')
  const [msgTemplateOption, setMsgTemplateOption] = React.useState([])
  const [selectedPatientsList, setSelectedPatientsList] = React.useState([])
  const [selectedPatientsNameList, setSelectedPatientsNameList] =
    React.useState([])

  const [templateId, setTemplateId] = React.useState('select')
  const [templateContent, setTemplateContent] = React.useState('')
  const [templateType, setTemplateType] = React.useState(null)
  const [isAllSelected, setIsAllSelected] = React.useState(false)
  const [check, setCheck] = React.useState(true)
  const classes = useStyles()
  const debouncedSearchTerm = useDebounce(searchKeyWord, 1000)
  const [currentPage, setCurrentPage] = React.useState(1)

  const { patientsData, networkStatus, totalpages } = DoctorStore.useStoreState(
    state => ({
      ...state.patientsList
    })
  )

  const { getPatientsList } = DoctorStore.useStoreActions(actions => ({
    ...actions.patientsList
  }))

  const { getDoctorMsgTemplates, getDoctorMsgBalance, sendMsgToPatients } =
    DoctorStore.useStoreActions(actions => ({
      ...actions.profile
    }))

  const {
    doctorMsgTemplates,
    doctorSendMsgResponse,
    doctorGetMsgBalance,
    messageSentStatus
  } = DoctorStore.useStoreState(state => ({
    ...state.profile
  }))

  useEffect(() => {
    getPatientsList({
      status: 0,
      table_config_rows_per_page: '10',
      table_config_page_no: currentPage,
      pattern: searchKeyWord
    })
    getDoctorMsgTemplates()
    getDoctorMsgBalance()

    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (searchKeyWord.length > 0) {
      onSearch()
    } else {
      setSearchdataBackUp(patientsData)
    }
  }, [searchKeyWord])

  useEffect(() => {
    setSearchdataBackUp(patientsData)
  }, [patientsData])

  useEffect(() => {
    if (doctorGetMsgBalance === undefined) {
    } else {
      setAvailableMsg(doctorGetMsgBalance)
    }
  }, [doctorGetMsgBalance])

  useEffect(() => {
    if (doctorMsgTemplates.length !== 0) {
      setMsgTemplateOption(doctorMsgTemplates?.data)
    }
  }, [doctorMsgTemplates])

  useEffect(() => {
    if (doctorSendMsgResponse) {
      if (doctorSendMsgResponse?.statusCode === 200) {
        setOpenSnackBar(true)
        setSnackBarMessage(doctorSendMsgResponse?.message)
        setmultiButton(false)
        setAlertType('success')
        getDoctorMsgBalance()
      } else {
        setOpenSnackBar(true)
        setSnackBarMessage(doctorSendMsgResponse?.message)
        setmultiButton(false)
        setAlertType('error')
      }
    }
  }, [doctorSendMsgResponse])

  const SelectPatientForSms = useCallback(
    id => {
      let listItem = selectedPatientsList.find(item => item === id)
      let list = selectedPatientsList
      let nameList = selectedPatientsNameList
      if (listItem) {
        list = selectedPatientsList.filter(item => item !== id)
      } else {
        list.push(id)
      }
      patientsData.map(item => {
        list.map(id => {
          if (item.patient_id === id) {
            let name = item.first_name + ' ' + item.last_name
            let available = selectedPatientsNameList.find(item => item === name)
            if (available) {
              nameList = selectedPatientsNameList.filter(item => {
                let value = item.first_name + ' ' + item.last_name
                return value !== name
              })
            } else {
              nameList.push(item.first_name + ' ' + item.last_name)
            }
          }
        })
      })
      setCheck(list.length > 0 ? false : true)
      setSelectedPatientsList(list)
      setSelectedPatientsNameList(nameList)
    },
    [
      selectedPatientsList,
      patientsData,
      setSelectedPatientsNameList,
      setSelectedPatientsList
    ]
  )

  useEffect(() => {
    let x = Math.floor(searchDataBackUp.length / contentPerPage)
    let y = searchDataBackUp.length - x * contentPerPage
    if (y > 0) {
      setPageCount(x + 1)
    } else {
      setPageCount(x)
    }
  }, [searchDataBackUp])

  const onPaginationClick = (event, value) => {
    setCurrentPage(value)
  }

  const onSearch = e => {
    let x = patientsData.filter(item =>
      item.first_name.toLowerCase().includes(searchKeyWord.toLowerCase())
    )
    setSearchdataBackUp(x)
  }

  const onTextChange = e => {
    if (e.target.value.length === 0) {
      setSearchdataBackUp(patientsData)
    }
    setSearchKeyWord(e.target.value)
  }

  const selectAllPatients = event => {
    let checked = event?.target?.checked
    setCheck(!checked)
    setSelectedPatientsList([])
    setSelectedPatientsNameList([])
    setIsAllSelected(!isAllSelected)
  }

  const handleChange = event => {
    setTemplateId(event.target.value)

    let value = msgTemplateOption.find(
      item => item.template_id === event.target.value
    )
    let templateImg = findTemplateImg(value?.display_name)
    setTemplateType(templateImg)
    setTemplateContent(value?.content)
  }

  const findTemplateImg = value => {
    switch (value) {
      case 'Followup Reminder':
        return SmsFollowReminderImg
      case 'Initial Reminder':
        return SmsInitialReminderImg
      case 'Follow-up':
        return SmsFollowImg
      case 'Plain':
        return SmsPlainImg
      case 'Promotion':
        return SmsPromotionalImg
      default:
        return SmsDefaultImg
    }
  }

  const SendMessage = () => {
    if (templateId === 'select') {
      setOpenSnackBar(true)
      setSnackBarMessage('Please select any one message template')
      setmultiButton(false)
      setAlertType('error')
    } else {
      setConfirmOpen(true)
    }
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  const validationCheck = () => {
    setConfirmOpen(false)
    sendMsgToPatients({
      template_id: templateId,
      is_all: isAllSelected,
      patient_id_list: selectedPatientsList
    })
  }

  const handleClose = () => {
    setConfirmOpen(false)
  }

  useEffect(() => {
    getPatientsList({
      status: 0,
      pattern: searchKeyWord,
      table_config_rows_per_page: '10',
      table_config_page_no: currentPage
    })
  }, [debouncedSearchTerm, currentPage])

  return (
    <div className={classes.communicationcol}>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />
      <Box className={classes.aside}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">Send SMS</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {isAllSelected
                ? 'Do you like to send a sms to all the selected patients?'
                : 'Do you like to send sms to the selected patients?'}
              {selectedPatientsNameList.map((item, index) => (
                <div>
                  <span>{index + 1}.</span> {item}
                </div>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={validationCheck} color="primary" autoFocus>
              Send
            </Button>
          </DialogActions>
        </Dialog>
        <Paper elevation={3} className={classes.paper}>
          <Grid container className={classes.topTitle}>
            <Grid item xs={12} sm={12} md={12}>
              <h3 style={{ fontWeight: '500', color: '#000', fontSize: 20 }}>
                Communication Center
              </h3>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl component="fieldset" className={classes.searchcol}>
                <TextField
                  className={classes.inputElement}
                  onChange={onTextChange}
                  label="Search"
                  value={searchKeyWord}
                />
                <FormControlLabel
                  className={classes.searchbtn}
                  control={<FaSearch onClick={onSearch} />}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={8}>
              <div className={classes.messageBox}>
                <FormControl
                  className={classes.formControl}
                  style={{ margin: '10px 25px 10px 0' }}>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ padding: '0 0 0 0' }}>
                    Select SMS Template:
                  </InputLabel>

                  <Select
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom'
                      }
                    }}
                    id="demo-simple-select"
                    value={templateId}
                    label="Select SMS Template:"
                    onChange={handleChange}
                    // style={{ border: '2px solid #3f4b79' }}
                    className={classes.smsSelect}>
                    <MenuItem value="select" style={{ display: 'none' }}>
                      Select SMS Template
                    </MenuItem>
                    {msgTemplateOption.map(item => (
                      <MenuItem value={item.template_id}>
                        {item.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {check ? (
                  <button
                    onClick={SendMessage}
                    disabled={true}
                    className={classes.disabledButton}>
                    Send SMS
                  </button>
                ) : (
                  <button onClick={SendMessage} className={classes.button}>
                    Send SMS
                  </button>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <div className={classes.msgTemplate}>
                {templateType ? (
                  <img
                    src={templateType}
                    style={{ width: '100%', float: 'right' }}
                    alt=""
                  />
                ) : null}
              </div>
            </Grid>

            <Grid item xs={6} sm={6} md={9}>
              {' '}
              <FormControlLabel
                className={classes.formcol}
                value="All"
                control={
                  <Checkbox
                    color="primary"
                    onChange={selectAllPatients}
                    name="All"
                  />
                }
                label="Check All"
                labelPlacement="end"
                checked={isAllSelected}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
              <h4
                style={{
                  fontWeight: '400',
                  fontSize: '14px',
                  marginBottom: '0px',
                  textAlign: 'right'
                }}>
                Available SMS :{' '}
                {availableMsg.current_balance
                  ? availableMsg.current_balance
                  : 0}
              </h4>
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              borderTop: '1px rgba(25,40,82,0.1) solid',
              marginTop: 10,
              padding: 10
            }}>
            <div
              style={{
                width: '20%',
                fontSize: 12,
                fontWeight: 600,
                color: 'rgba(25,40,82,0.6)'
              }}>
              Patient
            </div>
            <div
              style={{
                width: '12%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}>
              Date of Birth
            </div>
            <div
              style={{
                width: '28%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}>
              Date & Time
            </div>
            <div
              style={{
                width: '10%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}></div>
            <div
              style={{
                width: '20%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}></div>
            <div
              style={{
                width: '10%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}>
              Action
            </div>
          </div>
          <div>
            {networkStatus === NETWORK_STATUS.FETCHING ? (
              <Loading
                type="cylon"
                textAlign="center"
                color="grey"
                height={40}
              />
            ) : patientsData.length ? (
              <>
                {patientsData.map((d, index) => {
                  return (
                    <AppointmentListItem
                      image={d.photo_uri}
                      first_name={d.first_name}
                      middle_name={d.middle_name}
                      last_name={d.last_name}
                      id={d.id}
                      selectedPatientsList={selectedPatientsList}
                      SelectPatientForSms={SelectPatientForSms}
                      patientId={d.patient_id}
                      gender={d.gender}
                      dob={d.dob}
                      mobile={d.mobile_no}
                      email={d.email}
                      appointmentDate={d.appointment_date}
                      start_time={d.start_time}
                      end_time={d.end_time}
                      tag={d.tag}
                      initialConsultation={d.initialConsultation}
                      selectItem="true"
                      isAllSelected={isAllSelected}
                      key={index}
                    />
                  )
                })}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <img
                    alt="No data"
                    style={{ display: 'block', width: '200px' }}
                    src={NoRecordImg}
                  />
                </div>
              </>
            )}
            <Pagination
              count={totalpages}
              shape="rounded"
              onChange={onPaginationClick}
            />
          </div>
        </Paper>
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  communicationcol: {
    padding: '20px 10px',
    '@media (max-width:767px)': {
      padding: '15px'
    }
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    minHeight: '100vh',
    // padding: '50px 0 !important',
    '& .MuiInput-underline:before': {
      border: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none !important'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    },
    '& .MuiInput-underline:after': {
      border: 'none'
    }
  },
  searchbtn: {
    position: 'absolute',
    top: '20px',
    right: '0px',
    '@media (max-width:767px)': {
      position: 'absolute',
      top: '20px',
      right: '0px'
    }
  },
  button: {
    backgroundColor: '#3E3BE8',
    color: '#fff',
    borderRadius: '8px',
    height: '40px',
    padding: '0 20px',
    textAlign: 'center',
    border: 'none',
    width: '185px',
    marginTop: '20px',
    fontSize: '14px',
    cursor: 'pointer'
  },
  disabledButton: {
    background: '#adadad',
    color: '#fff',
    borderRadius: '8px',
    height: '40px',
    width: '185px',
    marginTop: '20px',
    fontSize: '14px',
    padding: '0 20px',
    textAlign: 'center',
    border: 'none',
    cursor: 'pointer',
    '@media (max-width:767px)': {
      width: '150px'
    }
  },
  topTitle: {
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
      padding: '0 15px'
    },
    '& .MuiSelect-select ': {
      color: '#3f4b79'
    },
    '& span': {
      color: '#3f4b79'
    },
    '& .MuiSelect-icon': {
      color: '#3f4b79'
    }
  },
  formcol: {
    marginLeft: '-10px !important',
    '@media (max-width:767px)': {
      margin: '0px !important'
    }
  },
  inputElement: {
    borderBottom: '1px #ccc solid',
    width: '100%',
    paddingRight: '40px'
  },
  searchcol: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  paper: {
    padding: '30px 20px',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    boxShadow: '0 0px 0px 0 rgba(0,0,0,0.1)',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  smsSelect: {
    border: '1px solid #393a7d',
    borderRadius: '5px',
    width: 200,
    // width:"100%",
    // width: 'calc(100% - 20px)',
    paddingLeft: '10px',
    '@media (max-width:767px)': {
      width: 'calc(100% - 2px)'
    }
  },
  msgTemplate: {
    paddingTop: '25px',
    '& span': {
      color: '#3f4b79',
      fontSize: '14px'
    },
    '& p': {
      borderRadius: '5px',
      fontWeight: 600
    }
  },
  selectLabel: {
    fontWeight: 600,
    margin: '-18px 10px',
    width: '100%'
  },
  messageBox: {
    display: 'flex',
    '@media (max-width:767px)': {
      flexDirection: 'column',
      flex: '0 0 100%'
    }
  }
}))
