import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Button, ListItem, makeStyles } from '@material-ui/core'
import localforage from 'localforage'

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 15px',
    textTransform: 'none',
    color:'#fefefe',
    width: '100%',
    borderRadius:'0px',
    '&:hover':{
      backgroundColor:'#9138aa',
      color: '#fefefe',
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    backgroundColor:'#9138aa',
    color: '#fefefe',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: '#fefefe'
    }
  }
}))

const NavItem = ({ className, href, icon: Icon, title, ...rest }) => {
  const classes = useStyles()

  const handleLogout = () => {
    localforage.clear().then(() => {
      localStorage.removeItem('clinic_user_id')
      localStorage.removeItem('clinic_id')
    })
  }
  return (
    <ListItem style={{padding:'0px'}}
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}>
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        onClick={title === 'Logout' ? handleLogout : ''}
        to={href}>
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  )
}

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
}

export default NavItem
