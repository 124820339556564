import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Backdrop,
  Button,
  Grid,
  makeStyles,
  Modal,
  Fade,
  TextField,
  Select,
  MenuItem
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { PatientStore } from '../../stores'
import { NETWORK_STATUS, PATIENT_ID } from '../../Constants'
import { useParams } from 'react-router'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const SelfUpload = ({
  open,
  handleClose,
  editModal,
  previousDocument,
  instruct,
  upload,
  document
}) => {
  const classes = useStyles()
  const [edit, setEditModal] = useState(false)
  const [Image, previewImage] = useState('')
  const [file, setFile] = useState('')

  const {
    addPatientDocument,
    editPatientDocument
  } = PatientStore.useStoreActions(actions => ({
    ...actions.patients
  }))

  // const submit = async values => {
  //   console.log(report.length)
  //   // if (report.length) {
  //     const file = report[0]
  //     let fileResponse = await convertBase64(file)
  //     if (editModal) {
  //       let document_id = previousDocument.document_id
  //       let document_uri = previousDocument.document_uri

  //       editPatientDocument({
  //         result: fileResponse,
  //         type: 'other',
  //         doc_name: file.name,
  //         document_id: document_id,
  //         document_uri: document_uri,
  //         instruct: values.instructions
  //       })
  //     } else {
  //       addPatientDocument({
  //         result: fileResponse,
  //         type: 'other',
  //         doc_name: file.name,
  //         labRecords: '',
  //         instruct: values.instructions
  //       })
  //     }

  //     setTimeout(() => {
  //       handleClose()
  //     }, 500)
  //   // }
  // }
  const docChange = event => {
    setFieldValue('picture', event.currentTarget.files[0])
    event.preventDefault()

    let reader = new FileReader()
    let fileimage = event.target.files[0]

    reader.onloadend = () => {
      setFile(fileimage)
      previewImage(reader.result)
    }
    reader.readAsDataURL(fileimage)
  }

  React.useEffect(() => {
    if (upload) {
      setEditModal(!edit)
    }
  }, [upload])

  const submit = async values => {
    const file = values.picture

    if (edit == true) {
      let document_id = previousDocument?.document_id
      let document_uri = previousDocument?.document_uri

      if (file?.name) {
        let base64 = await convertBase64(file)
        let format = base64.split(',')
        editPatientDocument({
          result: format[1],
          type: 'other',
          doc_name: file?.name,
          document_id: document_id,
          document_uri: document_uri,
          instruct: values.instructions
        })
      } else {
        editPatientDocument({
          result: '',
          type: 'other',
          doc_name: '',
          document_id: document_id,
          document_uri: document_uri,
          instruct: values.instructions
        })
      }
    } else {
      let base64 = await convertBase64(file)
      let format = base64.split(',')
      addPatientDocument({
        result: format[1],
        type: 'other',
        doc_name: file.name,
        labRecords: '',
        instruct: values.instructions
      })
    }

    setTimeout(() => {
      handleClose()
    }, 500)
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: {
      instructions: instruct,
      picture: previousDocument?.document_uri
        ? previousDocument?.document_uri
        : ''
    },
    onSubmit: submit,
    validationSchema
  })

  const isError = (error, touched) => error && touched

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
        {upload ? 'Update Self Upload' : 'Add New Self Upload Report'}
      </DialogTitle>
      <form noValidate={true} onSubmit={handleSubmit}>
        <DialogContent>
          <Box className={classes.ModalForm}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} style={{ padding: '8px 0' }}>
                <Box className={classes.InputCol}>
                  <TextField
                    multiline={true}
                    className={classes.textArea}
                    variant={'outlined'}
                    label="Instructions *"
                    name={'instructions'}
                    error={isError(errors.instructions, touched.instructions)}
                    helperText={
                      isError(errors.instructions, touched.instructions)
                        ? errors.instructions
                        : null
                    }
                    value={values.instructions}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} style={{ padding: '8px 0' }}>
                <input
                  accept="image/*,.pdf,.doc*"
                  className={classes.uploadInput}
                  id="file-input"
                  name={'picture'}
                  type="file"
                  onChange={docChange}
                />
                <label htmlFor="file-input">
                  <Button
                    variant="contained"
                    color="#111d4e"
                    size="large"
                    className={classes.button}
                    component="span"
                    startIcon={<CloudUploadIcon />}>
                    Upload Document *
                  </Button>
                </label>
                {file && (
                  <Typography className={classes.fileUploaded}>
                    File Uploaded
                  </Typography>
                )}
                {!file && !edit && (
                  <Typography className={classes.error}>
                    File is mandatory !
                  </Typography>
                )}
                {/* {file ? (
                  <Typography className={classes.fileUploaded}>
                    File Uploaded
                  </Typography>
                ) : <Typography className={classes.error}>
                File is mandatory !
              </Typography>} */}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: '20px 40px' }}>
          <Button
            onClick={handleClose}
            className={classes.modalSecondaryButton}>
            Cancel
          </Button>
          <Button type="submit" className={classes.modalPrimaryButton}>
            {upload ? 'Edit' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const validationSchema = yup.object().shape({
  instructions: yup.string().trim().required('Instructions are required')
})

const convertBase64 = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = error => {
      reject(error)
    }
  })
}

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main
  },
  fileUploaded: {
    color: theme.palette.success.main,
    margin: '8px 0'
  },
  uploadInput: {
    display: 'none'
  },
  textArea: {
    width: '100%'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formControl: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  select: {
    width: '100%'
  },
  InputCol: {
    '& .MuiInput-root': {
      width: '100%'
    }
  },
  Textara: {
    width: '97%',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    padding: '10px',
    marginLeft:'5px'
  },
  ModalBox: {
    width: '550px',
    background: '#fff',
    padding: '0px',
    '@media (max-width:767px)': {
      width: '100%'
    },
    // height:'400px',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  button:{
    '@media (max-width:767px)': {
      width:'100%'
    }
  },
  ModalForm: {
    width: '100%',
    padding: '15px',
    '@media (max-width:767px)': {
      padding: '20px',
      height: 'auto',
      overflowY: 'auto'
    }
  },
  DownloadBtn: {
    width: '120px',
    background: '#3f4b79',
    height: '40px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '40px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '50px',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ModalHead: {
    backgroundColor: '#fff',
    fontFamily:'Poppins',
    borderBottom:'1px #ccc solid',
      fontWeight:'600',
      '& .MuiDialogActions-root':{
        '@media (max-width:767px)': {
          padding:'20px !important'
        }
      }
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#fff',
    cursor: 'pointer',
    height:'40px',
    '&:hover':{
      backgroundColor: '#252b46',
    }
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    cursor: 'pointer',
    height: '40px'
  }
}))

export default SelfUpload
