import React from 'react'
import { ClinicStore } from '../../../stores'
import PaymentStepView from './PaymentStepView'

const PaymentStepLayout = () => {
  return (
    <ClinicStore.Provider>
      <PaymentStepView />
    </ClinicStore.Provider>  
  )
}


export default PaymentStepLayout
