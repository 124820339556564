import React from 'react'
import AppointmentsContent from './Appointments'
import { DashboardLayout } from '../../../layouts/ClinicDashboardLayout'
import { ClinicStore } from '../../../../stores'

const Appointments = () => {
  return (
    <ClinicStore.Provider>
      <DashboardLayout>
        <AppointmentsContent />
      </DashboardLayout>
    </ClinicStore.Provider>
  )
}

export default Appointments
