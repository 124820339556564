import React from 'react'
import { Box } from '@material-ui/core'

const Plan = ({
  inputId,
  inputValue,
  inputName,
  cardHeading,
  annualActualPrice,
  annualStrikedPrice,
  annualPercentageOff,
  monthlyActualPrice,
  monthlyStrikedPrice,
  monthlyPercentageOff,
  noOfDoctors,
  component,
  children,
  viewClicked,
  show,
  setPlan,
  checkedValue
}) => {
  const PlanNameMapping = {
    Microplan: 'Micro Tier',
    Miniplan: 'Mini Tier',
    'Pay as you go': 'Pay as you go'
  }

  const changePlan = event => {
    // let value = PlanNameMapping[event.target.value]
    setPlan(PlanNameMapping[event.target.value])
  }

  return (
    <div className="custom-choose">
      <input
        type="radio"
        id={inputId}
        className="plan1"
        value={inputValue}
        name={inputName}
        onChange={changePlan}
        checked={inputValue == checkedValue}
      />
      <label for={inputId} className="plan-box">
        <h3>{cardHeading}</h3>
        <div className="pricing-feature feature2">
          <Box className="price text-red plan-info">
            {component ? (
              component
            ) : (
              <>
                {annualActualPrice != 0 && (
                  <>
                    <span className="price">
                      <i class="fa fa-inr"></i> {annualActualPrice} /yr
                    </span>
                    <br />
                    <span className="">Billed Annually</span>
                    <br />
                  </>
                )}
                <br />
                <span className="price">
                  <i class="fa fa-inr"></i> {monthlyActualPrice} /mo
                </span>
                <br />
                <span className="">Billed Monthly</span>
                <br />
                <br />
                <h6 className="doctor-text">Upto {noOfDoctors} Doctors</h6>
              </>
            )}
            {show[inputId] && children}
            <button
              type="button"
              className="details-btn"
              onClick={() => viewClicked(inputId)}>
              {show[inputId] ? 'View Less' : 'View More'}
            </button>
          </Box>
        </div>
      </label>
    </div>
  )
}

export default Plan
