import React, { useEffect } from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import Page from '../../../components/Page'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DoctorSchedule from './DoctorScheduler'
import { ClinicStore } from '../../../../stores'
import { NETWORK_STATUS } from '../../../../Constants'
import Loader from '../../../components/ClinicLoader'
import { useParams } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const ScheduleContent = () => {
  const classes = useStyles()
  const params = useParams()
  const [docAssociationId, setDoctorAssociationId] = React.useState(null)
  const [doctor, setDoctor] = React.useState('null')

  const { getDoctorList } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))
  const { doctorList, networkStatus } = ClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))

  const handleChange = event => {
    let docId = event.target.value
    doctorList.map(item => {
      if (item.id === docId) {
        setDoctorAssociationId(item.clinic_doctor_association)
      }
    })
    setDoctor(docId)
  }

  useEffect(() => {
    getDoctorList({ filterInactive: true })
  }, [])

  useEffect(() => {
    if (doctorList.length !== 0) {
      if (params.doctor_id) {
        setDoctor(params.doctor_id)
      } else {
        setDoctor(doctorList[0].id)
        setDoctorAssociationId(doctorList[0].clinic_doctor_association)
      }
    }
  }, [doctorList])

  return (
    <>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Page className={classes.root} title="Clinic Doctors">
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h5"
                style={{ color: '#111d4e' }}>
                Set Schedule & Manage Appointments
              </Typography>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Doctor
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={doctor}
                  disabled={doctorList.length == 0}
                  onChange={handleChange}
                  MenuProps={{
                    style: { top: '50px' }
                  }}
                  label="Select Doctor">
                  {doctorList.map(item => {
                    return (
                      <MenuItem value={item.id}>{item.doctor_name}</MenuItem>
                    )
                  })}
                  {doctorList.length == 0 ? (
                    <MenuItem value={'null'}>No Doctor Found</MenuItem>
                  ) : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <DoctorSchedule
                doctorId={doctor}
                docAssociationId={docAssociationId}
                rescheduledView={params.doctor_id ? true : false}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  )
}

export default ScheduleContent
