import React, { useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
// import DashboardLayoutImg from '../images/bg2.png'

import { StylesProvider, ThemeProvider } from '../providers/ThemeProvider'
import { PublicPatientStore, PatientStore } from '../stores'
import Navbar from '../components/CommonComponents/Navbar'
import PatientMenuDrawer from '../components/CommonComponents/PatientMenuDrawer'
import { PATIENT_DRAWER_ITEMS } from '../Constants'

const DashboardLayout = ({ children, noNavbar }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  return (
    <PublicPatientStore.Provider>
      <PatientStore.Provider>
        <StylesProvider>
          <ThemeProvider>
            <CssBaseline />

            <div onClick={() => setOpen(!open)}></div>
            <Grid container className={classes.dashboardPage} spacing={0}>
              {noNavbar ? (
                ''
              ) : (
                <Grid item md={3} className={classes.drawer}>
                  <PatientMenuDrawer
                    listItems={PATIENT_DRAWER_ITEMS}
                    open={open}
                  />
                </Grid>
              )}
              <Grid item md={9} className={classes.dashboard}>
                <Navbar />
                {children}
              </Grid>
            </Grid>
          </ThemeProvider>
        </StylesProvider>
      </PatientStore.Provider>
    </PublicPatientStore.Provider>
  )
}

const useStyles = makeStyles(() => ({
  dashboardPage: {
    flexWrap: 'nowrap',
    background: `#FAFAFA`,
    backgroundAttachment: 'fixed',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  drawer: {
    width: '20%',
    flexBasis: '22%',
    maxWidth: '300px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },

  dashboard: {
    maxWidth: '80%',
    flexBasis: '80%',
    '& .makeStyles-root-102': {
      '@media (max-width:767px)': {
        width: '95% !important'
      }
    },
    '& .scheduler-view': {
      width: '100%',
      '@media (max-width:1366px)': {
        width: '900px !important'
      },
      '@media (max-width:1024px)': {
        width: '900px !important'
      },
      '@media (max-width:767px)': {
        width: '700px !important'
      }
    },
    '@media (max-width:1024px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '0px'
    }
  }
}))

export default DashboardLayout
