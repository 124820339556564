import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Box,
  Modal,
  Backdrop,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import { PatientStore } from '../../stores'
import moment from 'moment'
import { API_REQUEST_DATETIME_FORMAT, NETWORK_STATUS } from '../../Constants'
import Loading from 'react-loading'
import Pagination from '@material-ui/lab/Pagination'
import { PATIENT_ID } from '../../Constants'

const ViewVitalsHistoryModal = ({ showHideModal, isShow, pateintId }) => {
  const classes = useStyles()

  const startDate = moment().format(API_REQUEST_DATETIME_FORMAT)
  const endDate = moment()
    .subtract(60, 'days')
    .format(API_REQUEST_DATETIME_FORMAT)

  const { getPatientVitals } = PatientStore.useStoreActions(actions => ({
    ...actions.patients
  }))

  const { patientVitalsHistory, networkStatus } = PatientStore.useStoreState(
    state => ({
      ...state.patients
    })
  )

  const [initialPage, setInitialPage] = React.useState(0)
  const [pageCount, setPageCount] = React.useState(8)
  const [contentPerPage, setContentPerPage] = React.useState(8)
  const [finalPage, setFinalPage] = React.useState(contentPerPage)

  useEffect(() => {
    getPatientVitals({
      patient_id: localStorage.getItem(PATIENT_ID),
      start_time: startDate,
      end_time: endDate,
      list_type: 'history',
      table_config_rows_per_page: 20,
      table_config_page_no: 1
    })
  }, [])

  useEffect(() => {
    let x = Math.floor(patientVitalsHistory.length / contentPerPage)
    let y = patientVitalsHistory.length - x * contentPerPage
    if (y > 0) {
      setPageCount(x + 1)
    } else {
      setPageCount(x)
    }
  }, [patientVitalsHistory])

  const onPaginationClick = (event, value) => {
    setInitialPage((value - 1) * contentPerPage)
    setFinalPage((value - 1) * contentPerPage + contentPerPage)
  }

  return (
    <Dialog
      classes={{ paper: classes.modalView }}
      open={isShow}
      onClose={showHideModal}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.Toptittle}>
        Vitals History
      </DialogTitle>
      <DialogContent>
        <Box className={classes.ModalForm}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table">
              <TableHead>
                <TableRow style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
                  <TableCell>Date Recorded</TableCell>
                  <TableCell>Temparature (in F)</TableCell>
                  <TableCell>Height (in cm)</TableCell>
                  <TableCell>Weight (in Kg)</TableCell>
                  <TableCell>BMI index</TableCell>
                  <TableCell>Blood Pressure</TableCell>
                  <TableCell>Heart Rate</TableCell>
                  <TableCell>Respiratory Rate</TableCell>
                  <TableCell>Oxygen Saturation (in %)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {networkStatus === NETWORK_STATUS.FETCHING ? (
                  <Loading
                    type="cylon"
                    textAlign="center"
                    color="grey"
                    height={50}
                  />
                ) : patientVitalsHistory.length ? (
                  patientVitalsHistory
                    .slice(initialPage, finalPage)
                    .map(row => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {moment(row.datetime_recorded).format(
                            'DD/MM/YYYY, h:mm:ss a'
                          )}
                        </TableCell>
                        <TableCell align="center">{row.temperature}</TableCell>
                        <TableCell align="center">
                          {Math.floor(row.height)}
                        </TableCell>
                        <TableCell align="center">{row.weight}</TableCell>
                        <TableCell align="center">{row.bmi_index}</TableCell>
                        <TableCell align="center">
                          {Math.floor(row.sbp) + ' / ' + Math.floor(row.dbp)}
                        </TableCell>
                        <TableCell align="center">
                          {Math.floor(row.heart_rate)}
                        </TableCell>
                        <TableCell align="center">
                          {Math.floor(row.respiratory_rate)}
                        </TableCell>
                        <TableCell align="center">
                          {Math.floor(row.oxygen_saturation_rate)}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  'No Data found'
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Pagination
          count={pageCount}
          shape="rounded"
          onChange={onPaginationClick}
        />
      </DialogContent>
    </Dialog>
  )
}
const useStyles = makeStyles(() => ({
  ModalBox: {
    width: '80%',
    background: '#fff',
    padding: '0px',
    '@media (max-width:767px)': {
      padding: '20px 0',
      height: '450px',
      overflowY: 'auto',
      width: '100%'
    },
    // height:'400px',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  Toptittle: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  ModalForm: {
    width: '100%',
    padding: '20px 0',
    '@media (max-width:767px)': {
      padding: '10px',
      height: '400px',
      overflowY: 'auto'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalView: {
    maxWidth: '70rem !important'
  }
}))

export default ViewVitalsHistoryModal
