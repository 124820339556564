import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import InputIcon from '@material-ui/icons/Input'
import Logo from '../../components/Logo'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    padding: '2px 0',
    '& .MuiSvgIcon-root': {
      fill: '#000 !important'
    }
  },
  Logo: {
    '@media (max-width:767px)': {
      marginTop: '10px'
    }
  },
  avatar: {
    width: 60,
    height: 60
  }
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  const [notifications] = useState([])

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/" className={classes.Logo}>
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit" style={{ borderRadius: '5px' }}>
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
              style={{ fontSize: '1rem', color: '#111d4e' }}>
              <HeadsetMicIcon
                style={{ fontSize: '1rem', marginRight: '6px' }}
              />{' '}
              033-418-16533
            </Badge>
          </IconButton>
          <IconButton
            color="inherit"
            style={{ fontSize: '1rem', color: '#111d4e', borderRadius: '5px' }}>
            <OndemandVideoIcon
              style={{ fontSize: '1rem', marginRight: '6px' }}
            />{' '}
            Tutorials
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
}

export default TopBar
