import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  Fade,
  Snackbar,
  makeStyles,
  withStyles
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { Close } from '@material-ui/icons'
import fileDownload from 'js-file-download'
import axios from 'axios'
import moment from 'moment'

import { ClinicStore } from '../../../../stores'
import { NETWORK_STATUS } from '../../../../Constants'
import RecommendedServices from './BillDeskDialogComponents/RecommendedServices'
import AddAsTaken from './BillDeskDialogComponents/AddAsTaken'
import ServicesTaken from './BillDeskDialogComponents/ServicesTaken'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const DialogTitle = withStyles({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '2rem'
  }
})(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <AppBar {...other}>
      <Toolbar className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}>
            <Close style={{ color: 'white' }} color="error" />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade in ref={ref} {...props} />
})

const BillDeskDialog = ({
  open,
  onClose,
  appointmentData,
  refreshAppointmentList
}) => {
  const classes = useStyles()

  const { id: appointmentId, patient_id: patientId } = appointmentData

  const {
    billDeskAppointmentServicesList,
    recommendServiceToPatient,
    movePatientServiceToTaken,
    getServiceLists,
    appointmentServicesTakenPayment,
    getInvoiceDownloadLink,
    deleteServiceFromServicesTaken,
    deleteServiceFromRecommendedServices,
    doctorPaymentUsingClinic,
    updateNetworkStatus
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  const [showErrorSnackbarMessage, setShowErrorSnackbarMessage] = useState(null)

  const [netStatusServicesList, setNetStatusServicesList] = useState(null)
  const [serviceList, setServiceList] = useState([])

  const [
    netStatusRecommendedServicesList,
    setNetStatusRecommendedServicesList
  ] = useState(null)

  const [netStatusTakenServicesList, setNetStatusTakenServicesList] = useState(
    null
  )

  const [checkedServicesMarkingList, setCheckedServicesMarkingList] = useState(
    []
  )
  const [checkedAndPaidServicesList, setCheckedAndPaidServicesList] = useState(
    []
  )
  const [
    checkedAndNotPaidServicesList,
    setCheckedAndNotPaidServicesList
  ] = useState([])

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [paymentMode, setPaymentMode] = useState(null)
  const [doctorPaid, setDoctorPaid] = useState(false)

  const paymentModes = useRef([
    'credit-card',
    'debit-card',
    'UPI',
    'cash',
    'net-banking'
  ])

  const fetchServiceLists = useCallback(
    async list_type => {
      if (list_type === 'rec') {
        setNetStatusRecommendedServicesList(NETWORK_STATUS.FETCHING)
      } else if (list_type === 'taken') {
        setNetStatusTakenServicesList(NETWORK_STATUS.FETCHING)
      }

      try {
        const responseData = await getServiceLists({
          appointment_id: appointmentId,
          list_type
        })

        if (
          !!responseData &&
          responseData.status === 200 &&
          !!responseData.response &&
          Array.isArray(responseData.response)
        ) {
          if (list_type === 'rec') {
            setNetStatusRecommendedServicesList(NETWORK_STATUS.SUCCESS)
          } else if (list_type === 'taken') {
            setNetStatusTakenServicesList(NETWORK_STATUS.SUCCESS)
          }
          return
        }

        if (list_type === 'rec') {
          setNetStatusRecommendedServicesList(NETWORK_STATUS.FAILED)
        } else if (list_type === 'taken') {
          setNetStatusTakenServicesList(NETWORK_STATUS.FAILED)
        }
        setShowErrorSnackbarMessage(
          !!responseData && !!responseData.message
            ? responseData.message
            : 'Oops! Something went wrong. Please try again later...'
        )
      } catch (error) {
        if (list_type === 'rec') {
          setNetStatusRecommendedServicesList(NETWORK_STATUS.FAILED)
        } else if (list_type === 'taken') {
          setNetStatusTakenServicesList(NETWORK_STATUS.FAILED)
        }
        setShowErrorSnackbarMessage(
          'Oops! Something went wrong. Please try again later...'
        )
      }
    },
    [getServiceLists, appointmentId]
  )

  useEffect(() => {
    const fetchBillDeskAppointmentServicesList = async () => {
      setNetStatusServicesList(NETWORK_STATUS.FETCHING)
      try {
        const responseData = await billDeskAppointmentServicesList({
          appointment_id: appointmentId
        })
        if (
          !responseData ||
          responseData.status !== 200 ||
          !responseData.response
        ) {
          setNetStatusServicesList(NETWORK_STATUS.FAILED)
        }
        setServiceList(responseData.response)
        setNetStatusServicesList(NETWORK_STATUS.SUCCESS)
      } catch (error) {
        setNetStatusServicesList(NETWORK_STATUS.FAILED)
      }
    }

    if (open) {
      fetchBillDeskAppointmentServicesList()
      fetchServiceLists('rec')
      fetchServiceLists('taken')
    }
    if (!open) {
      setPaymentMode(null)
      setCheckedServicesMarkingList([])
      setCheckedAndPaidServicesList([])
      setCheckedAndNotPaidServicesList([])
    }
  }, [open, billDeskAppointmentServicesList, appointmentId, fetchServiceLists])

  useEffect(() => {
    setDoctorPaid(+appointmentData?.is_payment_done === 2)
  }, [appointmentData])

  const onAddButtonClicked = async selectedRecommendedService => {
    setNetStatusServicesList(NETWORK_STATUS.FETCHING)
    try {
      const responseData = await recommendServiceToPatient({
        service_master_id: selectedRecommendedService,
        appointment_id: appointmentId,
        patient_id: patientId
      })

      if (
        !!responseData &&
        !!responseData.statusCode &&
        responseData.statusCode === 200
      ) {
        setNetStatusServicesList(NETWORK_STATUS.SUCCESS)
        fetchServiceLists('rec')
        return
      }

      setNetStatusServicesList(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        !!responseData && !!responseData.message
          ? responseData.message
          : 'Oops! Something went wrong. Please try again later...'
      )
    } catch (error) {
      setNetStatusServicesList(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        'Oops! Something went wrong. Please try again later...'
      )
    }
  }

  // const oldStateToNewState = (curState, check = () => {}, newItem) => {
  //   let newState = [...curState]
  //   const index = newState.findIndex(check)
  //   if (index === -1) {
  //     newState = [...newState, newItem]
  //   } else {
  //     newState[index] = newItem
  //   }
  //   return newState
  // }

  const onClickMoveToTakenButton = async patient_service_id => {
    updateNetworkStatus(NETWORK_STATUS.FETCHING)

    try {
      const responseData = await movePatientServiceToTaken({
        patient_service_id: [patient_service_id]
      })

      if (
        !!responseData &&
        !!responseData.statusCode &&
        responseData.statusCode === 201
      ) {
        updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        fetchServiceLists('taken')
        fetchServiceLists('rec')
        return
      }

      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        !!responseData && !!responseData.message
          ? responseData.message
          : 'Oops! Something went wrong. Please try again later...'
      )
    } catch (error) {
      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        'Oops! Something went wrong. Please try again later...'
      )
    }
  }

  const markPaidServicesExceptDoctor = async () => {
    const serviceIdList = checkedAndNotPaidServicesList.map(
      item => item.patientServiceId
    )

    const markAsPaidServices = serviceIdList.filter(
      item => item !== '---doctor--apointment---'
    )

    if (markAsPaidServices.length > 0) {
      updateNetworkStatus(NETWORK_STATUS.FETCHING)
      try {
        const responseData = await appointmentServicesTakenPayment({
          patient_service_id: markAsPaidServices
        })
        setShowErrorSnackbarMessage(null)
        if (
          !!responseData &&
          !!responseData.statusCode &&
          responseData.statusCode === 201
        ) {
          updateNetworkStatus(NETWORK_STATUS.SUCCESS)
          setCheckedServicesMarkingList([])
          setCheckedAndPaidServicesList([])
          setCheckedAndNotPaidServicesList([])
          fetchServiceLists('taken')
          return
        }

        updateNetworkStatus(NETWORK_STATUS.FAILED)
        setShowErrorSnackbarMessage(
          !!responseData && !!responseData.message
            ? responseData.message
            : 'Oops! Something went wrong. Please try again later...'
        )
      } catch (error) {
        updateNetworkStatus(NETWORK_STATUS.FAILED)
        setShowErrorSnackbarMessage(null)
        setShowErrorSnackbarMessage(
          'Oops! Something went wrong. Please try again later...'
        )
      }
    }
    return 'failed'
  }

  const onClickMarkAsPaid = async () => {
    const markAsPaidPaymentDoctor =
      checkedAndNotPaidServicesList.findIndex(
        item => item.patientServiceId === '---doctor--apointment---'
      ) !== -1

    if (markAsPaidPaymentDoctor) {
      // if (doctorPaid) {
      //   setShowErrorSnackbarMessage('Doctor Fees is already paid!')
      // } else {
      setPaymentMode(paymentModes.current[0].toUpperCase())
      setOpenPaymentModal(true)
      // }
    } else {
      markPaidServicesExceptDoctor()
    }

    // if (markAsPaidServices.length > 0) {
    //   updateNetworkStatus(NETWORK_STATUS.FETCHING)
    //   try {
    //     const responseData = await appointmentServicesTakenPayment({
    //       patient_service_id: markAsPaidServices
    //     })
    //     setShowErrorSnackbarMessage(null)
    //     if (
    //       !!responseData &&
    //       !!responseData.statusCode &&
    //       responseData.statusCode === 201
    //     ) {
    //       updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    //       if (!markAsPaidPaymentDoctor) {
    //         setCheckedServicesMarkingList([])
    //         setCheckedAndPaidServicesList([])
    //         setCheckedAndNotPaidServicesList([])
    //         fetchServiceLists('taken')
    //       }
    //       return
    //     }

    //     updateNetworkStatus(NETWORK_STATUS.FAILED)
    //     setShowErrorSnackbarMessage(
    //       !!responseData && !!responseData.message
    //         ? responseData.message
    //         : 'Oops! Something went wrong. Please try again later...'
    //     )
    //   } catch (error) {
    //     updateNetworkStatus(NETWORK_STATUS.FAILED)
    //     setShowErrorSnackbarMessage(null)
    //     setShowErrorSnackbarMessage(
    //       'Oops! Something went wrong. Please try again later...'
    //     )
    //   }
    // }
  }

  const onClickProceedInPaymentModal = async () => {
    setOpenPaymentModal(false)
    updateNetworkStatus(NETWORK_STATUS.FETCHING)
    try {
      const responseData = await doctorPaymentUsingClinic({
        appointment_id: appointmentId,
        offline_payment_mode: paymentMode
      })

      if (
        !!responseData &&
        !!responseData.statusCode &&
        responseData.statusCode === 200
      ) {
        updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        setDoctorPaid(true)
        refreshAppointmentList()
        setPaymentMode(null)
        const returnValue = await markPaidServicesExceptDoctor()
        if (returnValue === 'failed') {
          setCheckedServicesMarkingList([])
          setCheckedAndPaidServicesList([])
          setCheckedAndNotPaidServicesList([])
          fetchServiceLists('taken')
        }
        return
      }

      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        !!responseData && !!responseData.message
          ? responseData.message
          : 'Oops! Something went wrong. Please try again later...'
      )
    } catch (error) {
      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        'Oops! Something went wrong. Please try again later...'
      )
    }
    // fetchServiceLists('taken')
    // setCheckedServicesMarkingList([])
    // setCheckedAndPaidServicesList([])
    // setCheckedAndNotPaidServicesList([])
    setPaymentMode(null)
  }

  const onClosePaymentModal = () => {
    setOpenPaymentModal(false)
    setShowErrorSnackbarMessage(
      "Sorry! We couldn't proceed with the doctor payment"
    )
    setPaymentMode(null)
    markPaidServicesExceptDoctor()
    // fetchServiceLists('taken')
    // setCheckedServicesMarkingList([])
    // setCheckedAndPaidServicesList([])
    // setCheckedAndNotPaidServicesList([])
  }

  // const onPrintInvoiceClicked = async serviceList => {
  const onPrintInvoiceClicked = async () => {
    const serviceIdList = checkedAndPaidServicesList.map(
      item => item.patientServiceId
    )

    const markAsPaidServices = serviceIdList.filter(
      item => item !== '---doctor--apointment---'
    )
    const markAsPaidPaymentDoctor =
      serviceIdList.indexOf('---doctor--apointment---') !== -1

    updateNetworkStatus(NETWORK_STATUS.FETCHING)

    try {
      const responseData = await getInvoiceDownloadLink({
        appointment_id: appointmentId,
        doctor_fee: markAsPaidPaymentDoctor,
        service_id_list: markAsPaidServices
      })

      if (
        !!responseData &&
        !!responseData.statusCode &&
        responseData.statusCode === 200 &&
        !!responseData.files &&
        Array.isArray(responseData.files)
      ) {
        updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        updateNetworkStatus(NETWORK_STATUS.FETCHING)
        for (const file of responseData.files) {
          const { data } = await axios.get(file, {
            responseType: 'blob'
          })
          if (!data) {
            updateNetworkStatus(NETWORK_STATUS.FAILED)
            setShowErrorSnackbarMessage(
              'Oops! Something went wrong. Please try again later...'
            )
            continue
          }
          fileDownload(
            data,
            `Invoice_${moment().format('dddd_MMMM-Do-YYYY_h-mm-ss-a')}.pdf`
          )
        }
        updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return
      }

      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        !!responseData && !!responseData.message
          ? responseData.message
          : 'Oops! Something went wrong. Please try again later...'
      )
    } catch (error) {
      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        'Oops! Something went wrong. Please try again later...'
      )
    }
  }

  const onDeleteButtonClickedInServicesTaken = async patient_service_id => {
    updateNetworkStatus(NETWORK_STATUS.FETCHING)
    try {
      const responseData = await deleteServiceFromServicesTaken({
        patient_service_id
      })
      if (
        !!responseData &&
        !!responseData.statusCode &&
        responseData.statusCode === 201
      ) {
        updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        setCheckedServicesMarkingList(curState =>
          curState.filter(item => item !== patient_service_id)
        )
        setCheckedAndNotPaidServicesList(curState =>
          curState.filter(item => item.patientServiceId !== patient_service_id)
        )
        fetchServiceLists('taken')
        fetchServiceLists('rec')
        return
      }
      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        !!responseData && !!responseData.message
          ? responseData.message
          : 'Oops! Something went wrong. Please try again later...'
      )
    } catch (error) {
      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        'Oops! Something went wrong. Please try again later...'
      )
    }
  }

  const onDeleteButtonClickedInRecommendedService = async patient_service_id => {
    updateNetworkStatus(NETWORK_STATUS.FETCHING)
    try {
      const responseData = await deleteServiceFromRecommendedServices({
        patient_service_id
      })
      if (
        !!responseData &&
        !!responseData.statusCode &&
        responseData.statusCode === 200
      ) {
        updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        fetchServiceLists('rec')
        return
      }
      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        !!responseData && !!responseData.message
          ? responseData.message
          : 'Oops! Something went wrong. Please try again later...'
      )
    } catch (error) {
      updateNetworkStatus(NETWORK_STATUS.FAILED)
      setShowErrorSnackbarMessage(
        'Oops! Something went wrong. Please try again later...'
      )
    }
  }

  const handleSnackbarClose = (event, reason, fileWrapper) => {
    if (reason === 'clickaway') {
      return
    }
    setShowErrorSnackbarMessage(null)
  }

  return (
    <React.Fragment>
      <Snackbar
        open={!!showErrorSnackbarMessage}
        autoHideDuration={2000}
        onClose={(e, r) => handleSnackbarClose(e, r)}>
        <Alert onClose={(e, r) => handleSnackbarClose(e, r)} severity="error">
          {showErrorSnackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}>
        <DialogTitle onClose={onClose}>BillDesk</DialogTitle>
        <DialogContent>
          <div className={classes.contentContainer}>
            <ServicesTaken
              loadStatusAll={netStatusTakenServicesList}
              checkedServicesMarkingList={checkedServicesMarkingList}
              handleChangeMarkingLists={setCheckedServicesMarkingList}
              checkedAndPaidServicesList={checkedAndPaidServicesList}
              checkedAndNotPaidServicesList={checkedAndNotPaidServicesList}
              setCheckedAndPaidServicesList={setCheckedAndPaidServicesList}
              setCheckedAndNotPaidServicesList={
                setCheckedAndNotPaidServicesList
              }
              openConfirmationModal={openConfirmationModal}
              setOpenConfirmationModal={setOpenConfirmationModal}
              onClickMarkAsPaid={onClickMarkAsPaid}
              onClickPrintInvoice={onPrintInvoiceClicked}
              onDeleteButtonClicked={onDeleteButtonClickedInServicesTaken}
              paymentModalOpen={openPaymentModal}
              closePaymentModal={onClosePaymentModal}
              handlePaymentSubmit={onClickProceedInPaymentModal}
              paymentModalPaymentValue={paymentMode}
              setPaymentModalPaymentValue={setPaymentMode}
              paymentModes={paymentModes.current}
              doctorPaid={doctorPaid}
              appointmentData={appointmentData}
            />
            <Divider variant="middle" />
            <div className={classes.bottomSection}>
              <RecommendedServices
                disabled={
                  netStatusServicesList === NETWORK_STATUS.FETCHING ||
                  netStatusServicesList === NETWORK_STATUS.FAILED ||
                  !serviceList.length
                }
                servicesList={serviceList}
                loadStatus={netStatusServicesList}
                handleClick={onAddButtonClicked}
              />
              <AddAsTaken
                onClickMoveToTakenButton={onClickMoveToTakenButton}
                loadStatusAll={netStatusRecommendedServicesList}
                onDeleteButtonClicked={
                  onDeleteButtonClickedInRecommendedService
                }
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default BillDeskDialog

const useStyles = makeStyles(theme => ({
  bottomSection: {
    margin: '1rem 0 2rem 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 1fr))',
    gridAutoRows: 'min-content',
    gridColumnGap: '2rem',
    columnGap: '2rem',
    gridRowGap: '1rem',
    rowGap: '1rem'
  },
  contentContainer: {
    marginTop: '6rem'
  }
}))
