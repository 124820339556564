import React from 'react'
import { makeStyles, Paper, Box, Grid } from '@material-ui/core'
import { Layout } from '../../doctorLayouts'
import MediaBgImg from '../../images/right-top-img.jpg'
import PublicNavbar from '../../components/CommonComponents/PublicNavbar'

export default function Termofuse() {
  const classes = useStyles()

  return (
    <Layout hideNavbar>
      <Grid container spacing={0} className={classes.Heademain}>
        <PublicNavbar />
        <Box className={classes.aside} style={{ padding: '40px', marginTop:'70px' }}>
          <Paper elevation={3} className={classes.paper}>
            <Terms />
          </Paper>
        </Box>
      </Grid>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    minHeight: '100vh',
    '& .MuiTab-root': {
      fontSize: '12px',
      padding: '6px 0px'
    },
    '@media (max-width:767px)': {
      marginTop: '80px'
    }
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '15px'
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%'
  },
  plus: {
    color: '#fff',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    fontSize: '25px',
    background: '#3f4b79',
    fontWeight: 'bold',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0',
    marginRight: '7px'
  },
  p: {
    fontSize: '15px',
    marginBottom: '10px'
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  }
}))

const Terms = () => {
  return (
    <div>
      <h4
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '30px',
          marginTop: '20px',
          color: '#000',
          textAlign: 'center'
        }}>
        Terms &amp; Conditions
      </h4>
      <p>
        Welcome to the portal www.carenow.healtcare and carenow healthcare’s
        mobile application (the URLs and mobile application together referred to
        as "Websites" and individually a "Website"). The Website is operated by
        CareNow healthcare in association with Craftveda Technologies (A Unit of
        Craftveda Retail Private Limited). The terms 'You' or 'Your' refer to
        You as the User (irrespective of whether You are a Registered User or a
        Non-Registered User or an Healthcare Practitioner (HCP)) and the terms
        'carenow healthcare','We', 'Us', 'Company', and 'Our' refer to Carenow
        healthcare.
      </p>
      <p>
        PLEASE CAREFULLY READ THE FOLLOWING TERMS OF USE OF THE WEBSITE. THESE
        TERMS OF USE, TOGETHER WITH THE PRIVACY POLICYAND ANY OTHER POLICIES AS
        MAY BE ADDED FROM TIME TO TIME. BY CLICKING ON THE 'I ACCEPT' OR “I
        AGREE” BUTTON WHILE ACCESSING OR USING THIS WEBSITE, YOU AGREE TO BE
        BOUND BY THESE TERMS OF USE. IF YOU DO NOT AGREE TO BE BOUND BY THESE
        TERMS OF USE, PLEASE DO NOT ACCESS OR USE THE WEBSITE.
      </p>
      <p>
        These Terms of Use, the Privacy Policy, the Additional Terms of Use for
        HCPs, if applicable, together with any other policies which may be
        applicable to specific portions of Website and any disclaimers which may
        be present on the Website are referred to as "Agreement". This Agreement
        applies to those services made available by carenow healthcare on the
        Website to the Users (“Services”)
      </p>
      <p>
        If You are accessing the carenow healthcare mobile application, then
        this Agreement will continue to apply to such use. In addition, there
        may be additional terms (such as the terms imposed by mobile application
        stores) which will govern the use of the mobile application. In such
        case, the term 'Website' will be deemed to refer to the carenow
        healthcare mobile application.
      </p>
      <p>
        This Agreement defines the terms and conditions under which you are
        allowed to use the Website and describes the manner in which we shall
        treat your account while you are registered as a member with us.
      </p>
      <p>
        By downloading or accessing the Website, you irrevocably accept all the
        conditions stipulated in this Agreement and Privacy Policy, as available
        on the Website, and agree to abide by them. The features / services of
        this Website are available only in India. However Non-Registered Users
        outside India may browse through the information provided in the
        Website. The Agreement supersedes all previous oral and written terms
        and conditions (if any) communicated to You.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        1. FEATURES OF THE WEBSITE
      </h5>
      <p>
        1.1. Users: The Website is accessible to registered users and healthcare
        practitioners. "Registered Users" are users who have registered
        themselves an account on the Website by providing carenow healthcare
        with certain information and who can log on to the Website by providing
        their username and password. "Non-Registered Users" are users who are
        not registered with the Website but may access information on the
        Website. Certain features of the Website are available to Registered
        Users only. "Health Care Practitioners" or "HCPs" are duly qualified
        members of medical or dental profession and (a) with whom Registered
        Users can book appointment using the Website or (b) who can provide
        information to questions raised by Registered Users or (c) with whom
        Registered Users can consult privately with respect to their queries or
        d) who can generate and provide health related information and education
        material for general use of Registered Users. Registered Users,
        Non-Registered Users and HCPs shall collectively be referred to as
        "Users".
      </p>
      <h6
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        1.2. The Website offers the following features:
      </h6>
      <p>
        <b>a) For Registered Users:</b>
      </p>
      <p>
        i. Platform for obtaining generic and preliminary information from HCP
        to a health related question: You may ask any health related queries
        from your respective HCP on the Website and obtain preliminary and
        generic information to such question from a HCP.
      </p>
      <p>
        ii. Platform to interact with HCP privately: You may interact with an
        HCP privately on the chat feature available on the Website or via other
        communication feature which the Website may provide after paying
        consultation fee to the HCP and convenience fee to carenow healthcare as
        applicable from time to time.
      </p>
      <p>
        <b>b) For HCPs:</b>
      </p>
      <p>
        i. Platform to publish profile: You may upload Your profile containing
        Your name, qualification, experience, specialization, consultation fees,
        personal statement, education, professional memberships etc. on Website.
        Your profile is accessible to all Registered Users.
      </p>
      <p>
        ii. Platform for private interaction: You may use Website for private
        interaction with Registered Users. In course of the private interaction,
        You can exchange texts, images, videos over the chat feature of the
        platform and communicate through other means provided by the carenow
        healthcare such as calls.
      </p>
      <p>
        iii. Platform to provide information to questions to health related
        questions submitted by Registered Users: You may provide Your
        preliminary and generic information to health related questions
        submitted by Registered Users.
      </p>
      <p>
        iv. Platform to publish HCP-contributed health related information and
        education material: You may contribute health related information and
        education material which carenow healthcare may, with or without
        modification, in its sole discretion, publish on the Website.
      </p>
      <p>
        <b>c) For Non-Registered Users:</b>
      </p>
      <p>
        Access to HCP Profiles: Depending on the extent of information shared by
        HCPs at the time of registering with the Website, the HCPs' profile
        containing their name, qualification, experience, specialization,
        consultant fees, personal statement, education, professional memberships
        etc. can be accessed by You.
      </p>
      <p>
        <b>1.3. Types of content on the Website:</b>
      </p>
      <p>
        The various types of content available on the Website may be categorised
        as follows:
      </p>
      <p>
        1.3.1. Content provided by Registered Users including health related
        queries provided by Registered Users to their HCP and interactions in
        the course of private interactions with HCPs ("User Content")
      </p>
      <p>
        1.3.2. Content provided by HCP including health related information and
        educational material; and information provided by way of responses to
        questions raised by Registered Users or in course of private
        interactions with Registered Users ("HCP Content");
      </p>
      <p>
        1.3.3. Content which carenow healthcare (through itself or its
        contractors) has generated or procured for the Website ("carenow
        healthcare Content").
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        2. TERMS OF USE OF THE WEBSITE
      </h5>
      <p>
        2.1. Terms of Use by all Users (Registered User, Non-Registered User and
        HCP)
      </p>
      <p>
        2.1.1. You will use the Website and the features provided by the Website
        only in relation to and in compliance with all applicable Indian laws.
        You will not use this Website or any feature provided by the Website for
        any purposes not intended under this Agreement.
      </p>
      <p>
        2.1.2. You will not deliberately use the Website in any way that is
        unlawful or harms carenow healthcare, its directors, employees,
        affiliates, distributors, partners, service providers and/or any User
        and/or data or content on the Website.
      </p>
      <p>
        2.1.3. You understand that as part of Your registration process as well
        as in course of Your use of the Website, You may receive SMS messages
        from carenow healthcare on Your registered mobile number. These messages
        will relate to Your registration, transactions that You carry out
        through the Website and any such information found suitable of Your
        attention by carenow healthcare. Please note that carenow healthcare
        will send these SMS messages only to the mobile number that You provide
        on the Website. It is Your responsibility to ensure that You provide the
        correct number for the transaction You wish to enter into. Further,
        carenow healthcare may also send notifications and reminders to You with
        respect to appointments scheduled by You for the features that You may
        be using on the Website. You hereby consent to receive such messages
        from carenow healthcare.
      </p>
      <p>
        2.1.4. By using the website and/or registering yourself at carenow
        healthcare you authorize us to contact you via email or phone call or
        sms and offer you services you have opted for, imparting Services
        knowledge, offer promotional offers, if any running on website, for
        which reasons, personally identifiable information may be collected. And
        Irrespective of the fact if also you have registered yourself under DND
        or DNC or NCPR service, you still authorize us to give you a call from
        the carenow healthcare & associate partners for the above mentioned
        purposes for such number of days till your engagement with us.
      </p>
      <p>
        2.1.5. carenow healthcare is not a party to any service contract between
        HCP and Registered User. Each HCP hereby acknowledges and agrees that
        carenow healthcare is not a party to any oral or written agreement for
        service, negotiation worksheet, or any contract entered into between HCP
        and Registered User in connection with any service offered, directly or
        indirectly, through the Website. Registered User, HCP and Non-Registered
        User acknowledges, agrees and understands that carenow healthcare only
        seeks to provide a platform wherein the Registered User and HCP can be
        brought together and carenow healthcare itself has not role in the
        execution or provision of services. It is clarified that HCP provides
        services directly to Registered User and carenow healthcare does not
        avail any services from HCP.
      </p>
      <p>
        2.1.6. Nothing contained in this agreement shall be construed as to make
        the carenow healthcare liable for any services provided or to be
        provided by HCP to the Registered User. carenow healthcare shall not be
        and shall not be deemed to be a party, directly or indirectly, to any
        contract for services between the HCP and Registered User.
      </p>
      <p>
        <b>2.2. Terms of Use by Registered User</b>
      </p>
      <p>
        2.2.1. You acknowledge that this Website is an informative platform
        only. carenow healthcare and this Website are not in the business of
        providing healthcare service or hospital services.
      </p>
      <p>
        2.2.2. We encourage You to independently verify any information You see
        on the Website including information provided by a HCP. You will use the
        Website and the features provided by the Website only in relation to and
        in compliance with all applicable Indian laws. You will not use this
        Website or any feature provided by the Website for any purposes not
        intended under this Agreement.
      </p>
      <p>
        2.2.3. The Website allows You to select and schedule appointments with
        HCPS. In doing so, carenow healthcare can help providing You with lists
        and/or profile previews of HCPs who may be suitable to deliver the
        health care that You are seeking based on information that You provide
        to carenow healthcare (such as area of specialty and geographical
        location).
      </p>
      <p>
        2.2.4. Please note that carenow healthcare (i) does not recommend or
        endorse any HCPs mentioned on the Website; and (ii) does not make any
        representations or warranties with respect to HCPs or the quality of the
        healthcare services they may provide, and (iii) does not grade
        physicians.
      </p>
      <p>
        2.2.5. You will obtain all relevant consents and approvals prior to
        posting or providing any User Content.
      </p>
      <p>
        2.2.6. You may schedule an appointment on the Website for Your family
        members and friends. However please note that such You will be solely
        responsible for any activity that is undertaken through his/her account
        on behalf of his/her family members and friends. 
      </p>
      <p>
        2.2.7. You have the option of uploading Your identifiable health
        information including physical, physiological and mental health
        condition, and medical records and history on the Website which may be
        accessed by the HCP at the time of private interactions using the
        Website. 
      </p>
      <p>
        2.2.8. carenow healthcare will levy convenience fees for each private
        interaction that You have with an HCP through the Website. carenow
        healthcare will also collect the consultation fee payable to the HCP in
        advance on behalf of HCP and pay to HCP without retaining any share from
        consultation fees.
      </p>
      <p>
        2.2.9. Where the patient is a minor, after confirming the age, Services
        would be allowed only if the minor is consulting along-with an adult
        whose identity needs to be ascertained.
      </p>
      <p>
        2.2.10. You acknowledge that your ability to access and use the Service
        is conditioned upon the truthfulness of this certification and that the
        HCPs you access are relying upon this certification in order to interact
        with you. In the event that your certification is inaccurate, you agree
        to indemnify the Company and the HCPs you interact with from any
        resulting damages, costs or claims as set forth in the Indemnification
        Section below.
      </p>
      <p>
        2.2.11. To access the Service, you must first enroll to establish an
        individual user account ("Account"), by providing certain information.
        With the exception of subaccounts established for minor children of whom
        you are a parent or legal guardian, you agree that you will not create
        more than one Account, or create an account for anyone other than
        yourself without first receiving permission from the other person. In
        exchange for your use of the Service and, if applicable, in order for
        HCPs to send notices to you, you agree to: (i) provide true, accurate,
        current and complete information about yourself as prompted by our
        Account enrollment form; and (ii) each time you log in, maintain and
        promptly update such Account information to keep it true, accurate,
        current and complete. If you provide any information that is untrue,
        inaccurate, not current or incomplete, or we have reasonable grounds to
        suspect that such Account information is untrue, inaccurate, not current
        or incomplete, we reserve the right to suspend or terminate your Account
        and refuse any and all current or future use of the Service.
      </p>
      <p>
        2.2.12. You agree not to access or use the Services in an unlawful way
        or for an unlawful or illegitimate purpose or in any manner that
        contravenes this Agreement. You shall not post, use, store or transmit
        (a) a message or information under a false name; (b) information that is
        unlawful, libelous, defamatory, obscene, fraudulent, predatory of
        minors, harassing, threatening or hateful to any person; or (c)
        information that infringes or violates any of the intellectual property
        rights of others or the privacy or publicity rights of others. You shall
        not attempt to disrupt the operation of the Service by any method,
        including through use of viruses, Trojan horses, worms, time bombs,
        denial of service attacks, flooding or spamming. You shall not use the
        Service in any manner that could damage, disable or impair the Service.
        You shall not attempt to gain unauthorized access to any user accounts
        or computer systems or networks, through hacking, password mining or any
        other means. You shall not use any robot, scraper or other means to
        access the Service for any purpose.
      </p>
      <p>
        <b>2.2.13. Possible Risks of Services:</b>
      </p>
      <p>
        As with any medical procedure, there are potential risks associated with
        the use of Services. We believe that the likelihood of these risks
        materializing is very low. These risks may include, without limitation,
        the following:
      </p>
      <ul>
        <li>
          Delays in medical evaluation and consultation or treatment may occur
          due to deficiencies or failures of the equipment which may include
          poor video and data quality.
        </li>
        <li>
          Security protocols could fail, causing a breach of privacy of personal
          medical information.
        </li>
        <li>
          Lack of access to complete medical records may result in adverse drug
          interactions or allergic reactions or other negative outcomes.
        </li>
        <li>
          <b>
            By accepting these Terms of Use, you acknowledge that you understand
            and agree with the following:
          </b>
          <ul>
            <li>
              You understand that the laws that protect privacy and the
              confidentiality of medical information also apply to these
              Services.
            </li>
            <li>
              You understand that Services may involve electronic communication
              of your personal medical information to medical practitioners who
              may be located in other areas, including out of state.
            </li>
            <li>
              You understand that you may expect the anticipated benefits from
              the use of Services, but that no results can be guaranteed or
              assured.
            </li>
            <li>
              You understand that all information will be part of your medical
              record and available to you by printing the summary from the
              visit. This information will have the same restrictions on
              dissemination without your consent.
            </li>
            <li>
              Except to the extent already relied upon, you understand you may
              withdraw your consent at any time by reaching out to our customer
              support and withdraw your consent and inactivate your account.
            </li>
            <li>
              You understand that your healthcare information may be shared with
              other individuals for treatment, payment and healthcare operations
              purposes. Psychotherapy notes are maintained by clinicians but are
              not shared with others, while billing codes and encounter
              summaries are shared with others. If you obtain psychotherapy
              services, you understand that your therapist has the right to
              limit the information provided to you if in your therapist's
              professional judgment sharing the information with you would be
              harmful to you.
            </li>
            <li></li>
          </ul>
        </li>
        <li>
          <b>
            You further understand that your healthcare information may be
            shared in the following circumstances:
          </b>
          <ul>
            <li>When a valid court order is issued for medical records.</li>
            <li>Reporting suspected abuse, neglect, or domestic violence.</li>
            <li>
              Preventing or reducing a serious threat to anyone's health or
              safety. 2.2.14. Your consent whether implied or explicit is
              necessary for availing the Service of the Website. The consent can
              be implied or explicit depending upon the following situation:
            </li>
          </ul>
        </li>
        <li>If, You initiates the Services , then the consent is implied</li>
        <li>
          An Explicit consent is needed if HCP initiates Services. An Explicit
          consent can be recorded in any form. You can send an email, text or
          audio/video message. You can state your intent on phone/video to the
          HCP, who must record this in his records.
        </li>
      </ul>
      <p>
        2.2.15. You may not access or use, or attempt to access or use, the
        Service to take any action that could harm us or any third party,
        interfere with the operation of the Service, or use the Service in a
        manner that violates any laws.
      </p>
      <p>
        <b>Without limiting the foregoing, you agree not to:</b>
      </p>
      <ul>
        <li>
          Impersonate any person or entity or falsely state or otherwise
          misrepresent your affiliation with any person or entity or the origin
          of any information you provide;
        </li>
        <li>
          Use any device, software, or routine to interfere or attempt to
          interfere with the proper working of the Service or any activity
          conducted on the Service or attempt to probe, scan, test the
          vulnerability of, or breach the security of any system or network;
        </li>
        <li>
          Circumvent, reverse engineer, decipher, decompile, disassemble,
          decrypt, or otherwise alter or interfere with (or attempt, encourage,
          or support anyone else's attempt to engage in such activities) any of
          the software comprising or in any way making up a part of the Service.
          The use or distribution of tools designed for compromising security
          (e.g., password guessing programs, cracking tools or network probing
          tools) is strictly prohibited;
        </li>
        <li>
          Engage in unauthorized use of bots, spiders, scraping, or harvesting
          of content or information, or use any other unauthorized automated
          means to compile information;
        </li>
        <li>
          Obtain or attempt to gain unauthorized access to other computer
          systems, materials, information or any services available on or
          through the Service;
        </li>
        <li>
          Take any action that imposes an unreasonable or disproportionately
          large load on our network or infrastructure;
        </li>
        <li>
          Upload or otherwise transmit any communication, software, or material
          that contains a virus or is otherwise harmful to our users' computers
          or systems; or
        </li>
        <li>
          Engage in any other conduct that restricts or inhibits any person from
          using the Service, or that, in our sole judgement, exposes us or any
          of our users, affiliates, or any other third party to any liability,
          damages, or detriment of any type.
        </li>
        <li>Information Disclosure</li>
        <li>
          Disclosure under Law: We reserves the right at all times to disclose
          any information as necessary to satisfy any applicable law,
          regulation, legal process or governmental request, including
          personally identifiable information, or to edit, refuse to allow or to
          remove any information or materials, in whole or in part, in carenow
          healthcare's sole discretion.{' '}
        </li>
        <li>
          Providing Correct Personal Information: In the course of using the
          Website, you may be required to enter certain information, including
          without limitation personal information (collectively, "Information").
          You represent and warrant that you will provide Us full, true and
          correct Information, and to update such Information on the Website
          promptly as reasonably necessary and as required by the Website.
        </li>
        <li>Your Account</li>
        <li>
          Security of Account and Password: In the event you establish an
          account and receive or establish a password for such account, you are
          responsible for maintaining the confidentiality of your account and
          password, and for restricting access to your computer. You accept full
          responsibility and liability for all activities that occur under your
          account or password. We reserve the right to refuse service, terminate
          accounts, or remove content in its sole discretion.
        </li>
        <li>
          Notification of Unauthorized Use/Ceasing Access: You will notify Us
          immediately of any unauthorized use or threat of unauthorized use of
          your account or the Website or of any other breach or potential breach
          of security known to you with respect to your account or the Website,
          including without limitation any loss or compromise of any password,
          and will cooperate with Us in every reasonable way to help Us to
          prevent the further unauthorized use, threat of unauthorized use,
          disclosure or threat of disclosure regarding the Website, your account
          and/or your password. You agree that immediately upon termination of
          your right to use the Website or any password-protected portion of the
          Website, or upon any earlier demand by carenow healthcare at any time,
          you will cease all access and/or use of the Website or such password
          protected portion of the Website, and will not attempt to access
          and/or use same.
        </li>
      </ul>
      <p>
        2.2.18. By using Website, You agree that any information shared by you
        with carenow healthcare or with any HCP will be subject to our Privacy
        Policy. You are solely responsible for the content that you choose to
        submit for publication on the Website, including any feedback, ratings,
        or reviews relating to HCPs. We disclaim all responsibility with respect
        to such content. We will not be liable to pay any consideration to any
        Registered User for re-publishing any content across any of its
        platforms. Your publication of reviews and feedback on the Website is
        governed by these Terms of Use. You hereby agree not to post or publish
        any content on Website that (a) infringes any third-party intellectual
        property or publicity or privacy rights, or (b) violates any applicable
        law or regulation. We, at its sole discretion, may choose not to publish
        your reviews and feedback, if so required by applicable law, and in
        accordance with Terms of Use. You agree that We may contact you through
        telephone, email, SMS, or any other electronic means of communication
        for the purpose of:
      </p>
      <ul>
        <li>Obtaining feedback in relation to Website or Services; and/or</li>
        <li>
          Obtaining feedback in relation to any HCPs listed on the Website;
          and/or
        </li>
        <li>
          Resolving any complaints, information, or queries by HCP regarding
          your feedbacks or reviews.
        </li>
      </ul>
      <p>
        and you agree to provide your fullest co-operation further to such
        communication by carenow healthcare.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        2.3. Terms of Use by HCPs
      </h5>
      <p>
        2.3.1. You are entitled to provide Services based consultation to Users
        from any part of India.
      </p>
      <p>
        2.3.2. While using Services You will uphold the same professional and
        ethical norms and standards as applicable to traditional in-person care,
        within the intrinsic limitations of telemedicine guidelines.
      </p>
      <p>
        2.3.3. To enable all those HCPs under the Indian Medical Council Act,
        1956 who would want to practice Services should get familiar with
        Telemedicine Practice Guidelines under “Indian Medical Council
        (Professional Conduct, Etiquette and Ethics) (Amendment) Regulations,
        2020 as well as with the process and limitations of telemedicine
        practice:{' '}
      </p>
      <p>
        {' '}
        An online program will be developed and made available by the Board of
        Governors in supersession of Medical Council of India.{' '}
      </p>
      <p>
        {' '}
        All registered medical practitioners intending to provide online
        consultation need to complete a mandatory online course within 3 years
        of its notification.{' '}
      </p>
      <p>
        {' '}
        In the interim period, the principles mentioned in these guidelines need
        to be followed.{' '}
      </p>
      <p>
        {' '}
        Thereafter, undergoing and qualifying such a course, as prescribed, will
        be essential prior to practice of telemedicine.
      </p>
      <p>
        2.3.4. Services based consultation should be treated the same way as
        in-person consultations from a fee perspective. You will charge an
        appropriate fee for the consultation provided. You should also give a
        receipt/invoice for the fee charged for providing Services based
        consultation.
      </p>
      <p>
        2.3.5. In addition to these Term of Use, the Additional Terms of Use for
        HCPs are applicable to such HCPs availing these Services. For details
        please read carefully{' '}
      </p>
      <p>2.4. Refund Policy for Paid Consultations</p>
      <p>
        2.4.1. While the carenow healthcare makes its best efforts to ensure
        that an appointment taken by a User on its Website with HCP is
        satisfied, the Company, however, does not make any guarantees or
        warranties that such appointments will be confirmed and delivered. You
        understand that these services are offered by third party service
        providers and that the Company has no control, directly or indirectly,
        over such third party entities.
      </p>
      <p>
        2.4.2. In case a HCP is not available to respond to a paid consult, You
        can report the issue to carenow healthcare and request for cancellation,
        post which carenow healthcare will process the refund amount equalling
        to the total actual amount paid by You, including any convenience fees.
        However, the refund will not include the discount value provided by
        carenow healthcare.
      </p>
      <p>
        2.4.3. In case You are not available to attend consultation, you can
        request for cancellation, post which carenow healthcare will process the
        refund amount equaling to the total actual amount paid by you deducting
        any convenience fees paid at your end.{' '}
      </p>
      <p>
        2.4.4. In the event it is proved that a HCP has acted in a manner that
        is against any applicable laws, carenow healthcare shall provide
        complete refund to You, subject to investigation undertaken by carenow
        healthcare.
      </p>
      <p>
        2.4.5. In the event it is proved that You have demonstrated abusive
        nature during the consult, You shall not be eligible for any refund and
        carenow healthcare/HCP shall be entitled to take any legal action as
        applicable.
      </p>
      <p>
        2.4.6. You are allowed a period of seven (7) days from the booking date
        to report any request for a refund. No refund requests shall be
        entertained thereafter.
      </p>
      <p>
        2.4.7. You can request a cancellation / rescheduling of appointment / or
        refund by dropping a mail at help@carenow.healthcare
      </p>
      <p>
        2.4.8. In the event carenow healthcare establishes that Your refund
        claim meets the above conditions, carenow healthcare shall process the
        refund accordingly and the money will be refunded to You in five (5)
        working days from the day refund has been approved from carenow
        healthcare.
      </p>
      <p>2.5. Book appointment and Call Facility</p>
      <p>
        2.5.1. carenow healthcare will ensure Registered Users are provided
        confirmed appointment. However, carenow healthcare has no liability if
        such an appointment is later cancelled by the HCP, or the same HCP is
        not available for appointment.
      </p>
      <p>
        2.5.2. If a Registered User has utilized the telephonic services,
        carenow healthcare reserves the right to share the information provided
        by the User with the HCP and store such information and/or conversation
        of the User with the HCP, in accordance with our Privacy Policy.
      </p>
      <p>
        2.5.3. Without prejudice to the generality of the above, carenow
        healthcare is not involved in providing any healthcare or medical advice
        or diagnosis and hence is not responsible for any interactions between
        User and the HCP. Registered User understands and agrees that carenow
        healthcare will not be liable for:
      </p>
      <ul>
        <li>
          User interactions and associated issues User has with the
          Practitioner;
        </li>
        <li>
          the ability or intent of the Practitioner(s) or the lack of it, in
          fulfilling their obligations towards Users;
        </li>
        <li>
          any wrong medication or quality of treatment being given by the
          Practitioner(s), or any medical negligence on part of the
          Practitioner(s);
        </li>
        <li>
          inappropriate treatment, or similar difficulties or any type of
          inconvenience suffered by the User due to a failure on the part of the
          Practitioner to provide agreed Services;
        </li>
        <li>
          any misconduct or inappropriate behaviour by the Practitioner or the
          Practitioner’s staff;
        </li>
        <li>
          cancellation or no show by the Practitioner or rescheduling of booked
          appointment or any variation in the fees charged.
        </li>
      </ul>
      <p>2.5.4. Rescheduling the appointment</p>
      <p>
        You may reschedule an appointment for another available slot within 72
        hours from the last booked slot / hours at no additional fee.
      </p>
      <p>
        2.5.5. Registered Users are allowed to provide feedback about their
        experiences with the HCP, however, the Registered User shall ensure
        that, the same is provided in accordance with applicable law. Registered
        User however understands that, carenow healthcare shall not be obliged
        to act in such manner as may be required to give effect to the content
        of Users feedback, such as suggestions for delisting of a particular HCP
        from the Website.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        3. WHAT WE DO
      </h5>
      <p>
        3.1. carenow healthcare's role is limited to a) providing a platform for
        hosting and publishing HCP Content b) providing a platform for
        Registered Users to raise health related queries and HCP's to provide
        preliminary and generic information to the questions c) providing a
        platform for private interaction between Registered User and HCP d)
        providing a platform to find HCP profiles created by HCPs themselves and
        e) facilitating collection and disbursal of consultation and service
        fees for HCPs.
      </p>
      <p>
        3.2. carenow healthcare does not have the obligation to pre-screen or
        monitor User Content or HCP Content at any time. However carenow
        healthcare may elect to monitor any User Content or HCP Content and
        remove any User Content and HCP Content from the Website if carenow
        healthcare determines in its sole discretion that such User Content
        and/or HCP Content is in violation of this Agreement or any applicable
        law and best practices. Where carenow healthcare removes any User
        Content or HCP Content from the Website, carenow healthcare will make
        reasonable efforts to inform the User or HCP who had posted such User
        Content or HCP Content respectively. Such actions do not in manner
        impose any liability on carenow healthcare with respect to User Content
        and/or HCP Content.
      </p>
      <p>
        3.3. If carenow healthcare suspects any illegal, wrongful or fraudulent
        activity on the Website by any Registered User or HCP, notwithstanding
        any other rights carenow healthcare may have, carenow healthcare
        reserves the right to inform the relevant government or law enforcement
        authorities. carenow healthcare will cooperate in all investigative
        procedures or requests for access / information initiated by any such
        government or law enforcement authorities.
      </p>
      <p>
        3.4. carenow healthcare does not endorse or influence control over any
        particular branch of medicine, theory, opinion, viewpoint or position on
        any topic.
      </p>
      <p>
        3.5. carenow healthcare includes all channels of communication with the
        User that leverage Information Technology platforms, including Voice,
        Audio, Text & Digital Data exchange
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        4. LINKS TO THIRD PARTY WEBSITES
      </h5>
      <p>
        4.1. You may be provided with links on the Website that direct You to
        third party websites / applications / content or service providers,
        including advertisers and e-commerce websites (collectively "Third Party
        Websites").
      </p>
      <p>
        4.1. You may be provided with links on the Website that direct You to
        third party websites / applications / content or service providers,
        including advertisers and e-commerce websites (collectively "Third Party
        Websites").
      </p>
      <p>
        4.2. carenow healthcare does not endorse any Third Party Websites that
        You may be directed to from the Website.
      </p>
      <p>
        4.3. Links to such Third Party Websites are provided for Your
        convenience only. Please exercise Your independent judgment and prudence
        when visiting / using any Third Party Websites via a link available on
        the Website. Should You decide to click on the links to visit such Third
        Party Website, You do so of Your own volition. Your usage of such Third
        Party Websites and all content available on such Third Party Websites is
        subject to the terms of use of the respective Third Party Website and
        carenow healthcare is not responsible for Your use of any Third Party
        Websites.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        5. PROPRIETARY RIGHTS
      </h5>
      <p>
        5.1. carenow healthcare respects the intellectual property rights of
        others, and We expect the same from You. The Website, User Content, HCP
        Content and the carenow healthcare Content are protected by applicable
        intellectual property laws of India.
      </p>
      <p>5.2. carenow healthcare's Rights to the Website and Content</p>
      <p>
        5.2.1. All intellectual property in and to the Website, any software,
        techniques and processes used in connection with the Website, belongs
        exclusively to carenow healthcare. Through Your use of the Website, by
        no means are any rights impliedly or expressly granted by carenow
        healthcare to You in respect of such works.
      </p>
      <p>
        5.2.2. carenow healthcare asserts all proprietary rights in and to all
        names / logos and trademarks contained on the Website. Notwithstanding
        the generality of the foregoing, the name, "carenow healthcare" is the
        trademark and copyright (or any other intellectual property right) of
        carenow healthcare. Any use of the carenow healthcare’s trademarks or
        copyright, unless otherwise authorized in a written agreement, will
        constitute an infringement upon the trademark and copyright (or any
        other such intellectual property right) of carenow healthcare and may be
        actionable under the applicable laws.
      </p>
      <p>
        5.2.3. Except as stated otherwise in this Agreement all carenow
        healthcare Content (including all intellectual property rights to such
        content) is owned exclusively by carenow healthcare.
      </p>
      <p>
        5.2.4. When You use the Website in accordance with this Agreement, a
        limited royalty-free right is granted to You by carenow healthcare to
        use carenow healthcare Content for personal and non-commercial use in
        India for the time that You are either a Registered User or an HCP or
        for the time that You make use of the Website, whichever is less.
      </p>
      <p>
        5.2.5. Unless explicitly stated herein, nothing in this Agreement shall
        be construed as conferring on You any license to intellectual property
        rights, whether by estoppel, implication, or otherwise.
      </p>
      <p>
        5.2.6. Unless expressly authorized by carenow healthcare, You agree not
        to reproduce, modify, sell, distribute, mirror, frame, republish,
        download, transmit, or create derivative works of the carenow healthcare
        Content, in whole or in part, by any means. You must not remove or
        modify any copyright or trademark notice, or other notice of ownership.
      </p>
      <p>
        5.2.7.  You must not reproduce, distribute, modify, create derivative
        works of, publicly display, publicly perform, republish, download, store
        or transmit any of the material on our Website except as follows:
      </p>
      <ul>
        <li>
          Your computer may temporarily store copies of such materials in RAM
          incidental to your accessing and viewing those materials.
        </li>
        <li>
          You may store files that are automatically cached by your web browser
          for display enhancement purposes.
        </li>
        <li>
          You may print or download a reasonable number of pages of the Website
          for your own personal, non-commercial use and not for further
          reproduction, publication or distribution.
        </li>
        <li>
          You may download the mobile application to your computer or mobile
          device solely for your own personal, non-commercial use, provided you
          agree to be bound by these Terms of Use or other end-user license
          agreements for such applications.
        </li>
      </ul>
      <p>
        If you print, copy, modify, download or otherwise use or provide any
        other person with access to any part of the Website in breach of the
        Terms of Use, your right to use the Website will cease immediately and
        you must, at our option, return or destroy any copies of the materials
        you have made. No right, title or interest in or to the Website or any
        content on the Website is transferred to you, and all rights not
        expressly granted are reserved by the Company. Any use of the Website
        not expressly permitted by these Terms of Use is a breach of these Terms
        of Use and may violate copyright, trademark and other laws.
      </p>
      <p>5.3. Rights in User Content And HCP Content</p>
      <p>
        5.3.1. Users are the owner of their content specifically and exclusively
        including all rights in their content as posted on the Website or
        provide to carenow healthcare.
      </p>
      <p>
        5.3.2. You hereby grant carenow healthcare a perpetual, non-revocable,
        worldwide, royalty-free rights to make use of such HCP Content (in case
        You are a HCP) including the right to copy, distribute, display,
        reproduce, modify, adapt, the HCP Content, as the case may me, and
        create derivate works of the HCP Content, as the case may be.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        6. PERSONAL INFORMATION
      </h5>
      <p>
        6.1.1. We may collect various personal information from You when you use
        the Website and or the features provided by the Website.
      </p>
      <p>
        6.1.2. Our sets out Our policy with respect to the collection, storage,
        use, transfer and protection of any such personal information.
      </p>
      <p>
        6.1.3. When You click on the 'I accept' or 'I agree' tab at the time of
        registering or otherwise provide Your personal information, You
        specifically consent to Our Privacy Policy.
      </p>
      <p>
        6.1.4. carenow healthcare may disclose Your Personal Information in
        accordance with applicable law.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        7. DISCLAIMERS
      </h5>
      <p>7.1. Interaction with HCP on the Website:</p>
      <p>
        7.1.1. You understand and agree that any interactions and associated
        issues with the HCP(s) on the Website, is strictly between You and the
        HCP(s). You shall not hold carenow healthcare responsible for any such
        interactions and associated issues. carenow healthcare is not involved
        in the providing any healthcare or medical advice or diagnosis and hence
        is not responsible for any outcome between You and the HCP You interact
        with. If You decide to engage with a HCP to provide medical services to
        You, You do so at Your own risk.
      </p>
      <p>
        7.1.2. Provision of access to any HCP on the basis of Your specific
        request, should not be construed as endorsement by this Website of any
        such particular HCP. We make no guarantees, representations or
        warranties, whether expressed or implied, with respect to professional
        qualifications, quality of work, expertise of the HCP.
      </p>
      <p>
        7.1.3. carenow healthcare shall not be responsible for any breach of
        service or service deficiency by any HCP.
      </p>
      <p>
        7.1.4. We can neither assure that all transactions on the Website will
        be completed nor do We guarantee the ability or intent of HCP to fulfill
        their obligations in any transaction. We advise You to perform Your own
        investigation prior to selecting a HCP. 
      </p>
      <p>
        7.1.5. carenow healthcare is intended for general purposes only and is
        not meant to be used in Emergencies / serious illnesses requiring
        physical consultation. Further, if the HCP adjudges that a physical
        examination would be required and advises ‘in-person consultation’, it
        is the sole responsibility of the User, to book an appointment for
        physical examination and in-person consultation whether the same is with
        the HCP listed on the Website or otherwise. In case of any negligence on
        the part of the User in acting on the same and the condition of the User
        deteriorates, carenow healthcare shall not be held liable.
      </p>
      <p>
        7.2. Fees: Services based consultation should be treated the same way as
        in-person consultations from a fee perspective. HCP may charge an
        appropriate fee for the consultation provided. A HCP should also give a
        receipt/invoice for the fee charged for providing Services based
        consultation.
      </p>
      <p>
        7.3. Clinical Support Information: We may provide information to assist
        You in clinical decision-making. This may include information and
        reminders concerning drug interactions, lab and diagnostic services,
        allergies, dosages, as well as general health-care related information
        and resources. The information and materials available through the
        Website are for informational and educational purposes only and are not
        intended to constitute professional advice, diagnosis or treatment, or
        to substitute professional judgment. Information that may be placed on
        the Website by third parties, including HCP Content is beyond Our
        control. We are not responsible for the accuracy or completeness of
        information available from or through Our Website. You assume full risk
        and responsibility for the use of information You obtain from or through
        this site, and You agree that carenow healthcare is not responsible or
        liable for any claim, loss, or liability arising from the use of the
        information. We do not recommend or endorse any HCP, Lab or diagnostic
        or health-related products, items or services, and the appearance of
        materials on the Website relating to any such products, items or
        services is not an endorsement or recommendation of them. You agree to
        review the definitions, functionality, and limitations of the
        information on the Website, and to make an independent determination of
        their suitability for Your use. We make no guarantees, representations
        or warranties, whether expressed or implied, with respect to information
        provided on the website
      </p>
      <p>
        7.4. Information regarding HCP and practice information: Information
        regarding HCP and practice information found on the Website is intended
        for general reference purposes only. Such information found on the
        Website is mainly self-reported by the HCP. Such information often
        changes frequently and may become out of date or inaccurate. Neither the
        Website nor carenow healthcare provides any advice or qualification
        certification about any particular HCP. You are encouraged to
        independently verify any such information You see on the Website with
        respect to a HCP that You seek to make an appointment with. 
      </p>
      <p>
        7.5. No Doctor – Patient Relationship / Professional Relationship:
        Please note that some of the content including text, data, graphics,
        images, information, suggestions, guidance, and other material that may
        be available on the Website (including information provided in direct
        response to Your questions or postings) may be provided by HCPs. The
        provision of such content to You by carenow healthcare does not
        constitute delivery of health care or diagnostic services to You and
        does not create a professional relationship between carenow healthcare
        and You. 
      </p>
      <p>
        7.6. No Medical Advice Provided: The content provided by the Website,
        either by carenow healthcare or by HCP, should not be construed under
        any circumstances to be an opinion, medical advice, or diagnosis or
        treatment of any particular condition, but is only provided to assist
        you with locating appropriate medical care from a qualified
        practitioner. The Services are not intended to be a substitute for
        getting in touch with emergency healthcare. If you are facing a medical
        emergency (either on your or a another person’s behalf), please contact
        an ambulance service or hospital directly.
      </p>
      <p>
        If You rely on any of the Information provided by the Website, You do so
        solely at Your own risk. The Information that You obtain or receive from
        carenow healthcare, and its employees, contractors, partners, sponsors,
        advertisers, licensors or otherwise on the Website is for informational
        and scheduling purposes only. In no event shall We be liable to You or
        anyone else for any decision made or action taken by You in reliance on
        such information.
      </p>
      <p>
        7.7. Non-substitution of professional medical advice: The Information
        provided on the Website and in any other communications from or provided
        through carenow healthcare is not intended as a substitute for, nor does
        it replace, professional medical advice, diagnosis, or treatment. Do not
        disregard, avoid or delay obtaining medical advice from a qualified
        health care professional because of advice that You may have obtained
        through the Website. Your use of Information provided on the Website is
        solely at Your own risk. Nothing stated or posted on the Website or
        available through any Services is intended to be, and must not be taken
        to be, the practice of medicine or the provision of medical care. You
        acknowledge and confirm that carenow healthcare shall not be responsible
        for Your treatment or be treated as a health care provider on account of
        collection of the consultation fees for private consultation by HCP for
        any reason whatsoever.
      </p>
      <p>
        7.8. CARENOW HEALTHCARE DISCLAIMS ANY REPRESENTATION AND/OR WARRANTYFOR
        THE SECURITY, RELIABILITY, QUALITY, TIMELINESS, AND PERFORMANCE OF (I)
        THE WEBSITE AND ITS FEATURES; (II) HCP WHO RESPONDS TO QUERIES OF A
        REGISTERED USER OR WHO INTERACTS PRIVATELY WITH A REGISTERED USER; (III)
        ANY INFORMATION, CONTENT OR ADVICE AVAILABLE ON OR RECEIVED THROUGH THE
        WEBSITE (IV) ACCESS TO OR ALTERATION OF USER CONTENT OR HCP CONTENT (V)
        TRANSMISSIONS OR DATA AND (VI) ANY OTHER MATTER RELATING TO THE WEBSITE
        AND / OR SERVICES.
      </p>
      <p>
        7.9. CARENOW HEALTHCARE MAKES NO WARRANTY OR REPRESENTATION THAT ANY
        ERRORS IN THE WEBSITE WILL BE CORRECTED EXCEPT AS REQUIRED BY LAW.
      </p>
      <p>
        7.10. CARENOW HEALTHCARE ASSUMES NO RESPONSIBILITY AND/OR LIABILITY WITH
        RESPECT TO HCP CONTENT (AS APPLICABLE) WHICH YOU MAY POST ON THE WEBSITE
        AND YOU ARE SOLELY RESPONSIBLE FOR SUCH USER CONTENT POSTED ON THE
        WEBSITE.
      </p>
      <p>
        7.11. YOU AGREE THAT THE OPEN AND REAL-TIME NATURE OF THE PLATFORM MAKE
        IT IMPOSSIBLE FOR CARENOW HEALTHCARE TO VOUCH FOR THE VALIDITY,
        AUTHETICITY AND HONESTY OF USER CONTENT OR HCP CONTENT. CONNECT2CLINI IS
        NOT RESPONSIBLE FOR ANY USER CONTENT OR HCP CONTENT ON THE SITE, OR FOR
        THE CONSEQUENCES OF YOU READING OR RELYING ON SUCH CONTENT.
      </p>
      <p>
        7.12. THE SITE IS PROVIDED ON A "AS IS" AND "AS AVAILABLE" BASIS.
        NEITHER CARENOW HEALTHCARE NOR ANY THIRD PARTY SERVICE PROVIDERS ARE
        LIABLE FOR ANY TECHNICAL OR OTHER OPERATIONAL DIFFICULTIES OR PROBLEMS
        WHICH MAY RESULT IN LOSS OF YOUR DATA, PERSONALIZATION SETTINGS OR OTHER
        INTERRUPTIONS IN THE FEATURES OFFERED ON THE WEBSITE. NEITHER CARENOW
        HEALTHCARE NOR ANY THIRD PARTY IS LIABLE FOR THE DELETION, LOSS,
        MIS-DELIVERY, TIMELINESS OR FAILURE TO STORE OR TRANSMIT ANY USER
        CONTENT OR HCP CONTENT, CARENOW HEALTHCARE CONTENT OR YOUR
        PERSONALIZATION SETTINGS.
      </p>
      <p>
        7.13. CARENOW HEALTHCARE DOES NOT PROVIDE ANY GUARANTEE AND SHALL NOT BE
        HELD LIABLE OR RESPONSIBLE FOR THE FAILURE TO SEND SMS OR OTHER
        NOTIFICATIONS OR REMINDERS TO YOU AS A FEATURE OF THE WEBSITE.
      </p>
      <p>
        7.14. WITHOUT LIMITING THE FOREGOING, WE DO NOT WARRANT THAT ACCESS TO
        THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS, IF
        ANY, WILL BE CORRECTED; NOR DO WE MAKE ANY REPRESENTATIONS ABOUT THE
        ACCURACY, RELIABILITY, CURRENCY, QUALITY, COMPLETENESS, USEFULNESS,
        PERFORMANCE, SECURITY, LEGALITY OR SUITABILITY OF THE SERVICE OR ANY OF
        THE INFORMATION CONTAINED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF
        THE SERVICE AND YOUR RELIANCE UPON ANY OF ITS CONTENTS IS AT YOUR SOLE
        RISK.
      </p>
      <p>
        7.15. YOU SHALL BE SOLELY AND FULLY RESPONSIBLE FOR ANY DAMAGE TO THE
        SERVICE OR ANY COMPUTER SYSTEM, ANY LOSS OF DATA, OR ANY IMPROPER USE OR
        DISCLOSURE OF INFORMATION ON THE SERVICE CAUSED BY YOU OR ANY PERSON
        USING YOUR USERNAME OR PASSWORD. COMPANY CANNOT AND DOES NOT ASSUME ANY
        RESPONSIBILITY FOR ANY LOSS, DAMAGES OR LIABILITIES ARISING FROM THE
        FAILURE OF ANY TELECOMMUNICATIONS INFRASTRUCTURE, OR THE INTERNET OR FOR
        YOUR MISUSE OF ANY PROTECTED HEALTH INFORMATION, ADVICE, IDEAS,
        INFORMATION, INSTRUCTIONS OR GUIDELINES ACCESSED THROUGH THE SERVICE.
      </p>
      <p>
        7.16. Neither the HCPs nor carenow healthcare shall be responsible or
        liable for any breach or loss of data including Personal Information
        caused due to events beyond the control of HCPs / carenow healthcare or
        due to technical reasons or third party actions.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        8. LIMITATION OF LIABILITY
      </h5>
      <p>
        8.1. To the fullest extent permitted by law, in no event will carenow
        healthcare or its affiliates be liable for any direct, indirect,
        special, incidental, punitive, exemplary or consequential damages
        (including, without limitation, loss of business, revenue, profits,
        goodwill), whether or not carenow healthcare has been warned of the
        possibility of such damages or could have reasonably foreseen such
        damages. Notwithstanding anything to the contrary in this Agreement,
        carenow healthcare's liability under this Agreement to YOU shall in no
        event exceed the fee amounts collected from You in the preceding one
        year or <i class="fa fa-inr"></i> 5000 whichever is lower. 
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        9. REPRESENTATION AND WARRANTIES
      </h5>
      <p>
        <b>By Using This Website You Represent And Warrant That: </b>
      </p>
      <p>
        9.1. You are 18 years of age or older and that Your use of the Website
        shall not violate any applicable law or regulation;
      </p>
      <p>
        9.2. All registration information You submit is truthful and accurate
        and that You agree to maintain the accuracy of such information;
      </p>
      <p>
        9.3. You as a Registered User will use the Website solely for Your
        personal and non-commercial use. Any use of this Website or its content
        other than for personal purposes is prohibited.
      </p>
      <p>
        9.4. Your use of this Website shall be subjected to the following
        restrictions:
      </p>
      <p>
        9.4.1. You will not delete or modify any content of the Website
        including but not limited to, legal notices, disclaimers or proprietary
        notices such as copyright or trademark symbols, logos, that You do not
        own or have express permission to modify;
      </p>
      <p>
        9.4.2. You will not decompile, reverse engineer, or disassemble the
        content, or
      </p>
      <p>
        9.4.3. You will not remove any copyright, trademark registration, or
        other proprietary notices from the Website. You further agree not to
        access or use this Website in any manner that may be harmful to the
        operation of this Website or its content. 
      </p>
      <p>
        9.5. You will not use the Website and / or services in any way that is
        unlawful, or harms the Company or any other person or entity, as
        determined in the Company's sole discretion.
      </p>
      <p>
        9.6. You will not engage in any form of antisocial, disrupting, or
        destructive acts, including "flaming", "spamming", "flooding",
        "trolling", "phishing" and "griefing" as those terms are commonly
        understood and used on the Internet.
      </p>
      <p>
        9.7. You will not host, display, upload, modify, publish, transmit,
        update or share any information that —
      </p>
      <p>
        (a) belongs to another person and to which the user does not have any
        right to;
      </p>
      <p>
        (b) is grossly harmful, harassing, blasphemous defamatory, obscene,
        pornographic, pedophilic, libelous, invasive of another's privacy,
        hateful, or racially, ethnically objectionable, disparaging, relating or
        encouraging money laundering or gambling, or otherwise unlawful in any
        manner whatever;
      </p>
      <p>(c) harm minors in any way;</p>
      <p>
        (d) infringes any patent, trademark, copyright or other proprietary
        rights(s), violates any law for the time being in force;
      </p>
      <p>
        (f) deceives or misleads the addressee about the origin of such messages
        or communicates any information which is grossly offensive or menacing
        in nature;
      </p>
      <p>(g) impersonate another person;</p>
      <p>
        (h) contains software viruses or any other computer code, files or
        programs designed to interrupt, destroy or limit the functionality of
        any computer resource;
      </p>
      <p>
        (i) threatens the unity, integrity, defence, security or sovereignty of
        India, friendly relations with foreign states, or public order or causes
        incitement to the commission of any cognizable offence or prevents
        investigation of any offence or is insulting any other nation.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        10. INDEMNIFICATION
      </h5>
      <p>
        You hereby indemnify, defend, and hold carenow healthcare, the carenow
        healthcare's distributors, agents, representatives and other authorized
        users (Registered Users, HCPs), and each of the foregoing entities'
        respective resellers, distributors, service providers and suppliers, and
        all of the foregoing entities' respective officers, directors, owners,
        employees, agents, representatives, harmless from and against any and
        all losses, damages, liabilities and costs arising from (i)Your use of
        the Website (ii) Your breach of provisions of this Agreement and (iii)
        any negligent or intentional wrongdoing on Your part. 
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        11. TERMINATION 
      </h5>
      <p>
        carenow healthcare reserves the right, in the event it finds out in its
        sole discretion that You breached the Agreement, to suspend and / or
        terminate Your user account and/or access to the Website by blocking
        Your IP address or email id with or without notice to You. Any suspected
        illegal, fraudulent or abusive activity may be grounds for suspending
        and / or terminating Your user account and/or Your access to the
        Website. Upon suspension or termination, Your right to use the features
        on the Website shall immediately cease and the Company reserves the
        right to remove or delete Your information that is available with the
        Company, including but not limited to login and account
        information. carenow healthcare shall not be liable to You or any third
        party for any claims or damages arising out of any termination or
        suspension of Your user account or any other actions taken by Us in
        connection with such account termination or suspension.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        12. MODIFICATIONS
      </h5>
      <p>
        12.1. carenow healthcare reserves the right to modify any part of the
        Agreement at any time without giving You prior notice.
      </p>
      <p>
        12.2. When We update the Agreement, the next time You visit the Website,
        the Website will provide a message to you (which may be sent by email if
        You have provided Your Email Id) which will intimate You of the
        amendments. You do not agree to the amendments, please do not use the
        Website any further.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        13. GOVERNING LAW AND JURISDICTION
      </h5>
      <p>
        The interpretation of this Agreement and the resolution of any disputes
        arising under this Agreement shall be governed by the laws of India. 
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        14. DISPUTE RESOLUTION
      </h5>
      <p>
        14.1. Any dispute, claim or controversy arising out of or relating to
        this notice or the breach, termination, enforcement, interpretation or
        validity thereof, including the determination of the scope or
        applicability of this Agreement to arbitrate, or to Your use of the
        Website or its features or the information to which it gives access,
        shall be determined by arbitration in India, before a single arbitrator
        in accordance with Indian Laws. The venue of such arbitration shall be
        Kolkata, India. The governing law of the Agreement shall be the
        substantive law of India. All proceedings of such arbitration,
        including, without limitation, any awards, shall be in the English
        language. The award shall be final and binding on the Parties.
      </p>
      <p>
        14.2. The Parties shall have the right to apply to a court of competent
        jurisdiction to obtain interim injunctive relief in respect of any
        dispute, pending resolution of such dispute in accordance with the
        Agreement.
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        15. GENERAL PROVISIONS
      </h5>
      <p>
        15.1. Severability: If a provision of these terms and conditions is
        determined by any court or other competent authority to be unlawful
        and/or unenforceable, the other provisions will continue in effect. If
        any unlawful and/or unenforceable provision would be lawful or
        enforceable if part of it were deleted, that part will be deemed to be
        deleted, and the rest of the provision will continue in effect.
      </p>
      <p>
        15.2. Notices: All notices and other communications required or
        permitted hereunder to be given to a Party shall be in writing, in the
        English language, and shall be sent by facsimile, e-mail, or mailed by
        prepaid internationally-recognized courier, or otherwise delivered by
        hand or by messenger, addressed to such Party's address as set forth
        below or at such other address as the Party shall have furnished to the
        other Party in writing in accordance with this provision:
      </p>
      <h5
        style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '15px' }}>
        If to You:
      </h5>
      <p>
        at the email address provided by You to us when You registered as a
        carenow healthcare User.
      </p>
      <p>
        15.3. Waiver: No term of the Agreement shall be deemed waived and no
        breach excused, unless such waiver or consent shall be in writing and
        signed by the Party claimed to have waived or consented. Any consent by
        any Party to, or waiver of a breach by the other, whether expressed or
        implied, shall not constitute consent to, waiver of, or excuse for any
        other different or subsequent breach. 
      </p>
      <p>
        15.4. Complete Understanding: The Agreement contains the entire
        understanding of the Parties, and there are no other written or oral
        understandings or promises between the Parties with respect to the
        subject matter of the Agreement other than those contained or referenced
        in the Agreement. 
      </p>
      <p>
        15.5. Force Majeure: carenow healthcare shall not be liable for any
        downtime or delay or unavailability of the Website caused by
        circumstances beyond carenow healthcare's reasonable control, including
        without limitation, acts of God, acts of government, floods, fires,
        earthquakes, civil unrest, acts of terror, strikes or other labour
        problems, internet service provider failures or delays, or denial of
        service attacks.
      </p>
      <p>
        15.6. Advice of Counsel: Each Party acknowledges: (a) having fully read
        the Agreement in its entirety; (b) having had full opportunity to study
        and review the Agreement.
      </p>
      <p>
        15.7. Advertisement: carenow healthcare shall have the right to place
        advertisements and publicity materials of its choice, including that
        pertaining to parties other than itself for commercial use through
        electronic means on the Website.  
      </p>
      <p>
        15.8. Assignment: You may not assign or sub-license, without the prior
        written consent of carenow healthcare, the rights, duties or obligations
        under this Agreement, in whole or in part, to any person or entity.
      </p>
      <p>
        15.9. Conflict: In the event there is any conflict between the terms set
        out in these Terms of Use, the Privacy Policy, the Additional Terms of
        Use for HCPs and any other policies applicable to specific pages of the
        Website, the following order of prevalence shall apply: (i) the Privacy
        Policy (ii) these Terms of Use, (iii) the Additional Terms of Use for
        HCPs and (iv) any other policies applicable to specific pages of the
        Website.
      </p>
      <p>
        15.10. No Agency or Partnership: No agency, partnership, joint venture,
        service relationship or employment is created as a result of the terms
        of use or your use of any part of the Website. You do not have any
        authority whatsoever to bind carenow healthcare in any respect. All HCP
        are independent contractors. Neither carenow healthcare nor any users of
        the Website may direct or control the day-to-day activities of the
        other, or create or assume any obligation on behalf of the other.
      </p>
      <p>
        15.11. As per the Telemedicine Practice Guidelines under “Indian Medical
        Council (Professional Conduct, Etiquette and Ethics) (Amendment)
        Regulations, 2020, in the event non-compliance is noted, the carenow
        healthcare shall be required to report the same to Board of Governors,
        in supersession to Medical Council of India who may take appropriate
        action.
      </p>
    </div>
  )
}
