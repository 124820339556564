import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Modal, Button, makeStyles } from '@material-ui/core'
import NoRecordImg from '../../images/norecord.png'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalMain: {
    width: '50%',
    height: '50%',
    background: '#fff',
    padding: '0px',
    justifyContent: 'center',
    // overflow: 'scroll',
    '& h2': {
      textAlign: 'center',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '0 20px',
      marginTop: 0,
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    },
    '@media (max-width:750px)': {
      width: '90%',
      height: '55%'
    }
  },
  closeBtn: {
    background: '#3f4b79',
    borderRadius: '50px',
    border: 'none',
    width: '115px',
    height: '30px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  fileImage: {
    height: '90%',
    width: '100%',
    justifyContent: 'center',
    display: 'flex'
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%'
    // '&:hover': {
    //   transform: 'scale(2)'
    // }
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px',
    marginTop: '70px'
  },
  cross: {
    cursor: 'pointer',
    float: 'right',
    color: '#f76f6f'
  }
}))

export default function ViewLabRecordModal({ open, handleClose, record }) {
  const classes = useStyles({})
  const [docx, setDocx] = React.useState()

  React.useEffect(() => {
    if (record.split(' ').pop().search('.pdf') === 15) {
      // let document_url= ` https://view.officeapps.live.com/op/view.aspx?src=${record}`
      let document_url = `https://docs.google.com/viewer?url=${record}`
      setDocx(document_url)
    } else if (
      record.split(' ').pop().search('.docx') === 15 ||
      record.split(' ').pop().search('.docx') === 15
    ) {
      // let document_url= ` https://view.officeapps.live.com/op/view.aspx?src=${record}`
      let document_url = `https://docs.google.com/viewer?url=${record}`
      setDocx(document_url)
    } else {
      setDocx(null)
    }
  }, [record])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Preview Image
        <span className={classes.cross} onClick={handleClose}>
          Close
        </span>
      </DialogTitle>
      <DialogContent>
        {record ? (
          <div className={classes.fileImage}>
            {docx !== null ? (
              <a href={docx} target="blank">
                <u>Click to View Pdf/Document file</u>
              </a>
            ) : (
              <img type="image/jpg" className={classes.img} src={record} />
            )}
            {/* <img type="image/jpg" className={classes.img} src={document} /> */}
          </div>
        ) : (
          <img
            src={NoRecordImg}
            className={classes.notFoundImage}
            alt={'Not found!'}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
