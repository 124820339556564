import React, { useEffect } from 'react'
import {
  makeStyles,
  FormControlLabel,
  Checkbox,
  TextField,
  FormGroup,
  Grid,
  FormControl,
  Box
} from '@material-ui/core'
import {
  APPOINTMENT_TYPE,
  BOOKING_STATUS,
  VISIT_TYPE,
  LIST_TYPE,
  NETWORK_STATUS
} from '../../Constants'
import { FaSearch } from 'react-icons/fa'
import Loading from 'react-loading'
import AppointmentListItem from '../CommonComponents/AppointmentListItem'
import NoRecordImg from '../../images/norecord.png'
import Pagination from '@material-ui/lab/Pagination'
import { DoctorStore } from '../../stores'
import useDebounce from '../../customHooks/useDebounce'

export default function PastAppointments() {
  const [searchKeyWord, setSearchKeyWord] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageCount, setPageCount] = React.useState(1)
  const debouncedSearchTerm = useDebounce(searchKeyWord, 1000)

  const { upcomingdata, networkStatus, totalPagesCount } =
    DoctorStore.useStoreState(state => ({
      ...state.appointments
    }))
  const { getList } = DoctorStore.useStoreActions(actions => ({
    ...actions.appointments
  }))

  //start filter code
  const [filter, setFilter] = React.useState({
    all: true,
    Tele: false,
    In_Person: false
  })
  const [filter2, setFilter2] = React.useState({
    confirmed: false,
    checked_in: false,
    completed: false
  })

  const classess = useStyless()

  useEffect(() => {
    getAppointmentList()
  }, [filter, filter2, debouncedSearchTerm, currentPage])

  useEffect(() => {
    setPageCount(totalPagesCount)
  }, [totalPagesCount])

  const onPaginationClick = (event, value) => {
    setCurrentPage(value)
  }

  const handleChange = e => {
    let value = e.target.checked
    if (!value && e.target.name === 'all') {
      return
    } else if (!value) {
      setFilter({
        all: true,
        Tele: false,
        In_Person: false
      })
    } else {
      onPaginationClick('', 1)
      let newFilter = filter
      Object.keys(filter).map(key => {
        newFilter[key] = false
      })
      setFilter({ ...newFilter, [e.target.name]: e.target.checked })
    }
  }

  const handleChange2 = e => {
    let value = e.target.checked
    if (!value) {
      setFilter2({
        confirmed: false,
        checked_in: false,
        completed: false
      })
    } else {
      onPaginationClick('', 1)
      let newFilter = filter2
      Object.keys(filter2).map(key => {
        newFilter[key] = false
      })
      setFilter2({ ...newFilter, [e.target.name]: e.target.checked })
    }
  }

  const getAppointmentList = () => {
    let filterResult = Object.keys(filter).find(key => filter[key] === true)
    let filter2Result = Object.keys(filter2).find(key => filter2[key] === true)
    let filterValue = Object.keys(APPOINTMENT_TYPE).find(
      key => APPOINTMENT_TYPE[key].label === filterResult
    )
    let filter2Value = Object.keys(BOOKING_STATUS).find(
      key => BOOKING_STATUS[key].label === filter2Result
    )
    if (filterValue || filter2Value) {
      let status = filter2Value ? BOOKING_STATUS[filter2Value].value : '0'
      let appointment_type = filterValue
        ? APPOINTMENT_TYPE[filterValue].id
        : '0'
      getList({
        list_type: LIST_TYPE.PAST.label,
        status: status,
        visit_type: VISIT_TYPE.ALL.id,
        appointment_type: appointment_type,
        currentPage: currentPage,
        searchString: searchKeyWord,
        sorting: {
          appointment_date: 'DESC',
          start_time: 'DESC'
        }
      })
    }
  }

  const onTextChange = e => {
    setSearchKeyWord(e.target.value)
  }

  return (
    <div>
      <div className={classess.checkboxes}>
        <Box
          className={classess.Topfilter}
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControl
            className={classess.CheckFilter}
            component="fieldset"
            style={{ width: '30%', marginTop: '15px' }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="all"
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleChange}
                    name="all"
                  />
                }
                label="All"
                labelPlacement="end"
                checked={filter.all}
              />
              <FormControlLabel
                value="In_Person"
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleChange}
                    name="In_Person"
                  />
                }
                label="In-Person"
                labelPlacement="end"
                checked={filter.In_Person}
              />
              <FormControlLabel
                value="Tele"
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleChange}
                    name="Tele"
                  />
                }
                label="Tele"
                labelPlacement="end"
                checked={filter.Tele}
              />
            </FormGroup>
          </FormControl>

          <Box
            className={classess.SearchFilter}
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControl
              component="fieldset"
              className={classess.SearchCol}
              style={{ width: '35%' }}>
              <FormGroup
                aria-label="position"
                row
                className={classess.SearchRow}>
                <FormControlLabel
                  className={classess.SearchRow2}
                  control={
                    <TextField
                      className={classess.inputElement}
                      onChange={onTextChange}
                      label="Search"
                      value={searchKeyWord}
                    />
                  }
                />
                <FormControlLabel
                  className={classess.SearchBtn}
                  control={<FaSearch onClick={onTextChange} />}
                />
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" style={{ marginTop: '15px' }}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="confirmed"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleChange2}
                      name="confirmed"
                    />
                  }
                  label="Confirmed"
                  labelPlacement="end"
                  checked={filter2.confirmed}
                />
                <FormControlLabel
                  value="checked_in"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleChange2}
                      name="checked_in"
                    />
                  }
                  label="Checked In"
                  labelPlacement="end"
                  checked={filter2.checked_in}
                />
                <FormControlLabel
                  value="completed"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleChange2}
                      name="completed"
                    />
                  }
                  label="Completed"
                  labelPlacement="end"
                  checked={filter2.completed}
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={4} sm={5} className={classess.checkboxcol}>
            
          </Grid>
          <Grid item xs={12} md={5} sm={6}>
            
          </Grid>
          <Grid
            item
            md={3}
            style={{
              textAlign: 'right',
              display: 'flex',
              alignItems: 'center'
            }}>
            
          </Grid>
        </Grid> */}
      </div>
      <div
        style={{
          display: 'flex',
          borderTop: '1px rgba(25,40,82,0.1) solid',
          marginTop: 10,
          padding: 10
        }}>
        <div
          style={{
            width: '20%',
            fontSize: 12,
            fontWeight: 600,
            color: 'rgba(25,40,82,0.6)'
          }}>
          Patient
        </div>
        <div
          style={{
            width: '12%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Date of Birth
        </div>
        <div
          style={{
            width: '28%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Date & Time
        </div>
        <div
          style={{
            width: '10%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Status
        </div>
        <div
          style={{
            width: '20%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Type of Appointment
        </div>
        <div
          style={{
            width: '10%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Action
        </div>
      </div>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loading
          type="bubbles"
          textAlign="center"
          color="#9138aa"
          height={50}
        />
      ) : upcomingdata.length ? (
        <>
          {upcomingdata.map((d, index) => {
            return (
              <AppointmentListItem
                image={d.photo_uri}
                first_name={d.patient_name}
                middle_name={d.middle_name}
                last_name={d.last_name}
                id={d.id}
                patientId={d.patient_id}
                gender={d.gender}
                dob={d.dob}
                mobile={d.mobile_no}
                email={d.email}
                appointmentDate={d.appointment_date}
                start_time={d.start_time}
                end_time={d.end_time}
                tag={d.tag}
                initialConsultation={d.initialConsultation}
                status={d.status}
                key={index}
                appointment_type={d.appointment_type}
              />
            )
          })}
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <img
              alt="No data"
              style={{ display: 'block', width: '200px' }}
              src={NoRecordImg}
            />
          </div>
        </>
      )}
      <Pagination
        count={pageCount}
        shape="rounded"
        onChange={onPaginationClick}
      />
    </div>
  )
}

const useStyless = makeStyles(() => ({
  checkboxes: {
    marginTop: '15px',
    '& label > span': {
      fontSize: '14px'
    },
    '& span > svg': {
      width: '0.8em',
      height: '0.8em'
    }
  },
  SearchRow2: {
    width: '100%'
  },
  formcol: {
    '@media (max-width:767px)': {
      margin: '0px !important'
    }
  },
  Topfilter: {
    '@media (max-width:767px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column'
    }
  },
  SearchFilter: {
    '@media (max-width:767px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column'
    }
  },
  SearchCol: {
    '@media (max-width:767px)': {
      width: '98% !important',
      marginLeft: '12px'
    }
  },
  CheckFilter: {
    '@media (max-width:767px)': {
      width: '100% !important'
    }
  },
  SearchBtn: {
    position: 'absolute',
    top: '18px',
    right: '0px'
  },
  Searchcol: {
    width: '100% !important',
    '@media (max-width:767px)': {
      marginBottom: '15px'
    }
  },
  SearchRow: {
    width: '100%'
  },
  Rightcheckbox: {
    '@media (max-width:767px)': {
      textAlign: 'left !important'
    }
  },
  inputElement: {
    width: '100%',
    borderWidth: 1,
    fontSize: '14px',
    '& .MuiInput-input': {
      paddingRight: '30px'
    },
    '&>div': {
      '&::before': {
        borderWidth: '1px'
      }
    }
  }
}))
