import localforage from 'localforage'
import IndianFlag from './images/indian-flag.svg'
import AmericanFlag from './images/american-flag.svg'

//constants related to login and auth
export const LOCAL_STORAGE_NAME = 'carenowdoc'
export const LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME = 'doctor_auth'
export const LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME = 'patient_auth'
export const LOCAL_STORAGE_CLINIC_AUTH_KEY_NAME = 'clinic_auth'
export const LOCAL_STORAGE_SUPER_CLINIC_AUTH_KEY_NAME = 'super_clinc_auth'
export const LOCAL_STORAGE_SUPER_CLINIC_AUTH = 'super_clinic_auth_key'
export const DOCTOR_USER_ID = 'doctor_user_id'
export const PATIENT_USER_ID = 'patient_user_id'
export const CLINIC_USER_ID = 'clinic_user_id'
export const SUPER_CLINIC_USER_ID = 'super_clinic_user_id'
export const HOSPITAL_ID = 'hospital_id'
export const DOCTOR_ID = 'doctor_id'
export const PATIENT_ID = 'patient_id'

//other constants
export const PATIENT_DETAILS = 'selected_patient'
export const VALIDATE_WHITESPACES = /^[^\s]+(\s+[^\s]+)*$/gm
export const SINGLE_WHITESPACE = /^\S+$/
export const DOUBLE_WHITESPACES = /^(?!.* {2}).+/
export const VALIDATE_NUMERIC_VALUES = /^\d+$/
export const VALIDATE_PIN_CODE = /^[1-9][0-9]{5}$/
export const VALIDATE_ONLY_ALPHABETS = /^[A-Za-z ]+$/
export const VALIDATE_ALPHANUMERIC_VALUES = /^[A-Za-z0-9 ]+$/
export const VALIDATE_URL_SLUG_PREFIX = /^[0-9A-Za-z_.-]+$/
export const VALIDATE_IFSC_CODE = /^[A-Z]{4}0[A-Z0-9]{6}$/
export const SHORT_CODE = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
export const LOCAL_STORAGE_STORE_NAME = 'store'
export const VALIDATE_PHONE = /^((\+){1}91){1}[1-9]{1}[0-9]{9}$/ // --- Keeps a check with number with a prefix of +91
export const VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE = /^[1-9]{1}[0-9]{9}$/
export const SPECIAL_CHAR = /^([a-zA-Z0-9-]+\s)*[a-zA-Z0-9-]+$/
export const EMAIL_CHECK = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/

export const NETWORK_STATUS = {
  SUCCESS: 'SUCCESS',
  NOT_STARTED: 'NOT_STARTED',
  FETCHING: 'FETCHING',
  FAILED: 'FAILED'
}

export const AUTH_STATE = {
  NOT_STARTED: 'NOT_STARTED',
  AUTHENTICATING: 'AUTHENTICATING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED'
}

export const API_REQUEST_DATE_FORMAT = 'YYYY-MM-DD'
export const API_REQUEST_TIME_FORMAT = 'HH:mm:ss'
export const API_REQUEST_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const USER_TYPE = {
  // ADMIN: { id: 1, value: 1, label: 'Admin' },
  // { id: 2, value: 2, label: 'Nurse' },
  DOCTOR: { id: 2, value: 2, label: 'Doctor' },
  PATIENT: { id: 3, value: 3, label: 'Patient' }
}

export const USER_ROLE = {
  1: 'Admin',
  2: 'Doctor',
  3: 'Patient'
}

export const SCHEDULER_RESOURCES = [
  {
    id: '0',
    name: 'Monday',
    minuteStep: 15
    // groupOnly: true,
  },
  {
    id: '1',
    name: 'Tuesday',
    minuteStep: 15
    // parentId: 'r0',
  },
  {
    id: '2',
    name: 'Wednesday',
    minuteStep: 30
    // parentId: 'r3',
  },
  {
    id: '3',
    name: 'Thursday',
    minuteStep: 30
    // parentId: 'r1',
  },
  {
    id: '4',
    name: 'Friday',
    minuteStep: 30
  },
  {
    id: '5',
    name: 'Saturday',
    minuteStep: 60
  },
  {
    id: '6',
    name: 'Sunday',
    minuteStep: 15
  }
  // {
  //     id: 'r7',
  //     name: 'Resource7',
  // }
]

export const CONSULTATION_TYPE = {
  IN_PERSON: {
    value: '1',
    label: 'In-Person',
    color: '#3f4b79',
    bookedColor: '#e3e3e3',
    icon: '👤'
  },
  TELE: {
    value: '2',
    label: 'Tele',
    color: '#9abd0f',
    bookedColor: '#e3e3e3',
    icon: '💻'
  }
}

export const SUPPORTED_COUNTRIES = [
  {
    id: 1,
    value: 'IND',
    name: 'India',
    code: '91',
    flag: IndianFlag
  },
  {
    id: 2,
    value: 'USA',
    name: 'United States of America',
    code: '1',
    flag: AmericanFlag
  }
]

export const DOCTOR_DRAWER_ITEMS = [
  {
    id: 0,
    label: 'Insights',
    path: '/doctor/dashboard',
    class: 'dashboard-menu',
    action: () => {}
  },
  {
    id: 1,
    label: 'Appointment List',
    path: '/doctor/appointments',
    class: 'appointments-menu',
    action: () => {}
  },
  {
    id: 2,
    label: 'My Patients',
    path: '/doctor/patients',
    class: 'patients-menu',
    action: () => {}
  },
  // { id: 3, label: 'My Staffs', path: '/doctor/staffs',class: 'staffs-menu', action: () => { } },
  {
    id: 4,
    label: 'My Account',
    path: '/doctor/account',
    class: 'accounts-menu',
    action: () => {}
  },
  {
    id: 5,
    label: 'My Schedule',
    path: '/doctor/scheduler',
    class: 'scheduler-menu',
    action: () => {}
  },
  {
    id: 7,
    label: 'Clinical Insights',
    path: '/doctor/clinicalinsights',
    class: 'clinical-insights-menu',
    action: () => {}
  },
  {
    id: 12,
    label: 'Communication Center',
    path: '/doctor/communication',
    class: 'communication-menu',
    action: () => {}
  },
  {
    id: 13,
    label: 'Profile Card',
    path: '/doctor/profileCard',
    class: 'profile-menu',
    action: () => {}
  },
  {
    id: 8,
    label: 'Update Profile',
    path: '/doctor/updateprofile',
    class: 'update-profile-menu',
    action: () => {}
  },
  {
    id: 11,
    label: 'Carenow Sketch',
    path: '/doctor/sketch',
    class: 'sketch-menu',
    action: () => {}
  },
  {
    id: 9,
    label: 'Logout',
    path: '/doctor/login',
    class: 'logout-menu',
    action: async () =>
      localforage.clear().then(() => {
        localStorage.clear()
      })
  }
]

export const PATIENT_DRAWER_ITEMS = [
  {
    id: 0,
    label: 'Insights',
    path: '/patient/dashboard',
    class: 'dashboard-menu',
    action: () => {}
  },
  {
    id: 1,
    label: 'My Vitals',
    path: '/patient/vitals',
    class: 'vitals-menu',
    action: () => {}
  },
  {
    id: 2,
    label: 'My Medications',
    path: '/patient/medications',
    class: 'medications-menu',
    action: () => {}
  },
  {
    id: 3,
    label: 'My Appointments',
    path: '/patient/appointments',
    class: 'appointments-menu',
    action: () => {}
  },
  {
    id: 4,
    label: 'My Documents',
    path: '/patient/documents',
    class: 'documents-menu',
    action: () => {}
  },
  {
    id: 5,
    label: 'Update Profile',
    path: '/patient/updateprofile',
    class: 'profile-update-menu',
    action: () => {}
  },
  // { id: 6, label: "Messages", path: "/patient/messages", action:() => {} },
  {
    id: 7,
    label: 'Logout',
    class: 'logout-menu',
    path: '/patient/login',
    action: async () =>
      localforage.clear().then(() => {
        localStorage.clear()
      })
  }
]

export const SCHEDULE_TYPE = {
  STATIC: { id: 0, label: 'STATIC' },
  FIXED: { id: 1, label: 'FIXED' },
  SPECIFIC: { id: 2, label: 'SPECIFIC' }
}

export const BOOKING_STATUS = {
  ALL: {
    value: '0',
    label: 'all'
  },
  BOOKED: {
    value: '1',
    label: 'booked'
  },
  CONFIRMED: {
    value: '2',
    label: 'confirmed'
  },
  CHECKED_IN: {
    value: '3',
    label: 'checked_in'
  },
  IN_PROGRESS: {
    value: '4',
    label: 'in-progress'
  },
  CANCELLED: {
    value: '5',
    label: 'cancelled'
  },
  NO_SHOW: {
    value: '6',
    label: 'no-show'
  },
  COMPLETED: {
    value: '7',
    label: 'completed'
  },
  RESCHEDULED: {
    value: '8',
    label: 'rescheduled'
  }
}

export const APPOINTMENT_TYPE = {
  ALL: { id: '0', label: 'all' },
  IN_PERSON: { id: '1', label: 'In_Person' },
  TELE: { id: '2', label: 'Tele' }
}

export const VISIT_TYPE = {
  ALL: { id: 0, label: 'All', value: 0 },
  INITIAL: { id: 1, label: 'initial consult', value: 1 },
  FOLLOW_UP: { id: 2, label: 'follow-up', value: 2 }
}

export const LIST_TYPE = {
  ALL: { id: 0, label: 'all' },
  UPCOMING: { id: 1, label: 'upcoming' },
  PAST: { id: 2, label: 'past' }
}

export const DEMOGRAPHIC_TYPE = {
  CAREGIVER: { id: 1, label: 'caregiver' },
  EMERGENCY: { id: 2, label: 'emergency' }
}

export const SNACKBAR_ALERT_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info'
}

export const PAYMENTSTATUS = {
  PAID: 1,
  UNPAID: 0
}

export const SUBSCRIPTION_PRICES = {
  MINI_MONTHLY: '0',
  MINI_YEARLY: '0',
  MICRO_MONTHLY: '0',
  MICRO_YEARLY: '0',
  PAY_AS_YOU_GO: '0'
}
