import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useRoutes } from 'react-router-dom'
import {
  NotFoundPage,
  Privacy,
  Termofuse,
  BlogDetailsMRPage,
  FAQPage,
  HomePage,
  OnboardingPage,
  DoctorPage,
  DoctorPageNew,
  LoginStepPage,
  PatientDetailInfoLayout,
  BlogDetailsPage,
  DoctorInfo,
  Updateclinic,
  AddDoctor
} from './pages/'
import DoctorPortal from './routes/DoctorPortal'
import PatientPortal from './routes/PatientPortal'
import ClinicPortal from './routes/ClinicPortal'
import SuperClinicPortal from './routes/SuperClinicPortal'
import ReactGA from 'react-ga'
import { ADMIN_URL, GA_KEY } from './Environment'
import { ThemeProvider } from '@material-ui/core'

import theme from './Clinic/theme'
import OrientationError from './components/CommonComponents/OrientationError'
import DoctorList from './pages/DoctorList'

const App = () => {
  //google analytics configuration

  ReactGA.initialize(GA_KEY)
  ReactGA.pageview(window.location.pathname)
  localStorage.setItem('routing_mode', 'common')
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <OrientationError />
        <Router>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/onboarding" component={OnboardingPage} />
            <Route
              exact
              path="/Carenow_Sketch"
              render={() => {
                window.location.href = '/Carenow_Sketch'
              }}
            />
            <Route exact path="/doctor-list" component={DoctorList} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/termsofuse" component={Termofuse} />
            <Route exact path="/faq" component={FAQPage} />
            <Route exact path="/blog-details" component={BlogDetailsPage} />
            <Route exact path="/BlogDetailslMR" component={BlogDetailsMRPage} />

            {/* Routed based on respective portals */}
            <Route path="/doctor" component={DoctorPortal} />
            <Route path="/patient" component={PatientPortal} />
            <Route path="/clinic" render={ClinicPortal} />
            <Route path="/superclinic" component={SuperClinicPortal} />
            <Route path="/admin" render={() => (window.location = ADMIN_URL)} />

            <Route exact path="/clinicold/dashboard" component={DoctorInfo} />
            <Route
              exact
              path="/clinic/update-clinic"
              component={Updateclinic}
            />
            <Route exact path="/clinic/add-doctor" component={AddDoctor} />

            <Route
              path="/:shortCode"
              exact
              strict
              sensitive
              render={({ match }) => {
                return match ? (
                  <DoctorPageNew match={match} />
                ) : (
                  <NotFoundPage />
                )
              }}
            />
            <Route
              path="/new/:shortCode"
              exact
              strict
              sensitive
              render={({ match }) => {
                return match ? <DoctorPage match={match} /> : <NotFoundPage />
              }}
            />
            <Route
              exact
              path="/booking/patientdetailinfo"
              component={PatientDetailInfoLayout}
            />
            <Route exact path="/booking/loginstep" component={LoginStepPage} />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </>
  )
}

export default App
