import React, { useEffect } from 'react'
import {
  Typography,
  Divider,
  CircularProgress,
  Snackbar,
  makeStyles
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import { ClinicStore } from '../../../../../stores'
import { NETWORK_STATUS } from '../../../../../Constants'
import PrintPrescriptionItem from './PrintPrescriptionItem'

import NoRecordImg from '../../../../../images/norecord.png'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ManualPrescriptionList = ({ open, appointmentId }) => {
  const classes = useStyles()

  const { networkStatus, manualPrescriptionList } = ClinicStore.useStoreState(
    state => ({
      ...state.appointment
    })
  )

  const {
    fetchManualPrescriptionList,
    updateNetworkStatus
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  useEffect(() => {
    if (open) {
      fetchManualPrescriptionList({
        appointment_id: appointmentId
      })
    }
  }, [open, fetchManualPrescriptionList, appointmentId])

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    updateNetworkStatus(null)
  }

  let contentInsideCard = (
    <div className={classes.containerDiv}>
      <CircularProgress />
    </div>
  )

  if (networkStatus === NETWORK_STATUS.FETCHING) {
    contentInsideCard = (
      <div className={classes.containerDiv}>
        <CircularProgress />
      </div>
    )
  } else if (!manualPrescriptionList || !manualPrescriptionList.length) {
    contentInsideCard = (
      <img
        src={NoRecordImg}
        className={classes.notFoundImage}
        alt="Not found!"
      />
    )
  } else {
    contentInsideCard = (
      <div className={classes.containerDiv}>
        <ul className={classes.listContainer}>
          {manualPrescriptionList.map(({ id, prescription_url, filename, fileType }) => (
            <PrintPrescriptionItem
              key={id}
              fileName={filename}
              imageSrc={prescription_url}
              fileExtention={fileType}
              updateStatus={updateNetworkStatus}
            />
          ))}
        </ul>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom>
        Manual Prescription
      </Typography>
      <Divider variant="middle" />
      {contentInsideCard}
      <Snackbar
        open={networkStatus === NETWORK_STATUS.FAILED}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error">
          Oops! Something went wrong. Try again later!
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default ManualPrescriptionList

const useStyles = makeStyles(theme => ({
  containerDiv: {
    height: theme.spacing(25),
    padding: `0 -${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
    margin: `0 -${theme.spacing(2)}px`,
    '& *': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0
    }
  },
  notFoundImage: {
    height: theme.spacing(25),
    width: theme.spacing(25),
    display: 'block',
    padding: theme.spacing(2),
    margin: 'auto'
  },
  listContainer: {
    listStyleType: 'none',
    display: 'grid',
    gridAutoRows: 'min-content',
    width: '100%',
    gridRowGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    alignSelf: 'stretch',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  }
}))
