import React from 'react'
import PatientDetaildemographic from './PatientDetaildemographic'

const Demographics = props => {
  const { TabPanel, value, classes, disabled, params } = props
  return (
    <React.Fragment>
      <TabPanel
        value={value}
        index={1}
        className={classes.MedicationCol}
        style={{
          background: '#fff',

          borderRadius: 15
        }}>
        <PatientDetaildemographic
          classes={classes}
          value={value}
          params={params}
          disabled={disabled}
        />
      </TabPanel>
    </React.Fragment>
  )
}

export default Demographics
