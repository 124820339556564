import React, { useEffect, useState, useCallback } from 'react'
import {
  Box,
  Grid,
  makeStyles,
  Modal,
  CircularProgress,
  Button,
  Fade,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@material-ui/core'
import localforage from 'localforage'
import { Formik, Field, useFormik } from 'formik'
import moment from 'moment'
import * as yup from 'yup'
import { DoctorStore } from '../../stores'
import { NETWORK_STATUS } from '../../Constants'
import { useParams } from 'react-router'
import { Autocomplete } from '@material-ui/lab'
import { debounce, throttle } from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useDebounce from '../../customHooks/useDebounce'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const EditMedicationModal = ({ open, handleClose, defaultValues }) => {
  const classes = useStyles()
  const params = useParams()
  const [value, setValue] = React.useState([])
  const [medications, setMedication] = React.useState('')
  const [otherMedications, setOtherMedications] = React.useState([])
  const [searchString, setSearchString] = useState('')
  const debouncedSearchTerm = useDebounce(searchString, 500)
  const [state, setState] = React.useState({
    brand_name: false,
    generic_name: true
  })
  const [type, setType] = React.useState('')

  const { editMedication, getMedicalSearch } = DoctorStore.useStoreActions(
    actions => ({
      ...actions.patients
    })
  )

  const {
    networkStatus,
    MedicalList,
    medicationsFetchingStatus
  } = DoctorStore.useStoreState(state => ({
    ...state.patients
  }))

  // Check the type of Drug
  useEffect(() => {
    let status = Object.keys(state).find(key => state[key] === true)
    setType(status)
  }, [state, setState])

  // Filter of data coming from api fetch of Medical List.
  let medicalListData = MedicalList && MedicalList.length ? MedicalList : []
  const submit = values => {
    let medication = []
    let other = []
    // let Drug = values.drugName
    if (values.drugName.type === 'others') {
      other.push(values.drugName.name)
    } else if (values?.drugName?.medication_id) {
      medication.push(values.drugName.medication_id)
    } else {
      medication.push(values.medication_id)
    }

    editMedication({
      ...values,
      patientId: params.patient_id,
      appointmentId: params.appointment_id,
      id: defaultValues.id,
      medication_id: medication,
      others: other,
      medication_name_type: type
    })

    setTimeout(() => {
      handleClose()
    }, 500)
  }

  useEffect(() => {
    getMedicalSearch({
      searchString: searchString,
      table_config_rows_per_page: '20',
      table_config_page_no: '1',
      medication_name_type: type
    })
  }, [debouncedSearchTerm])

  const MedicalChange = (event, value) => {
    let eventData = event.target.value
    setSearchString(eventData)
  }

  const handleMedicalChange = (event, value) => {
    if (value?.type === 'others') {
      let others = otherMedications
      others.push(value)
      setOtherMedications(others)
    }
    setFieldValue('drugName', value)
  }
  let dayCount = 0
  let durationStringId = 1
  if (defaultValues.duration) {
    dayCount =
      defaultValues.duration % 7 === 0
        ? defaultValues.duration / 7
        : defaultValues.duration % 30 === 0
        ? defaultValues.duration / 30
        : defaultValues.duration
    durationStringId =
      defaultValues.duration % 7 === 0
        ? 7
        : defaultValues.duration % 30 === 0
        ? 30
        : 1
  }
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: {
      drugName: defaultValues.proprietaryname,
      frequency: defaultValues.frequency,
      duration: dayCount,
      startDate: defaultValues.start_date,
      durationString: durationStringId,
      instructions: defaultValues.instructions,
      medication_id: defaultValues.medication_id
    },
    onSubmit: submit,
    validationSchema
  })

  const isError = (error, touched) => error && touched

  useEffect(() => {
    const endDate = moment(values.startDate, 'YYYY-MM-DD')
      .add(values.duration * values.durationString, 'days')
      .format('YYYY-MM-DD')

    setFieldValue('endDate', endDate)
  }, [values.startDate, values.duration, values.durationString])

  const filterChange = event => {
    if (event.target.name == 'brand_name') {
      setState({
        brand_name: true,
        generic_name: false
      })
    } else {
      setState({
        brand_name: false,
        generic_name: true
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
        Edit Medication
      </DialogTitle>
      <DialogContent>
        <Box className={classes.ModalForm}>
          <Box marginY={1}>
            <FormGroup row style={{ justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.generic_name}
                    onChange={filterChange}
                    name="generic_name"
                    color="primary"
                  />
                }
                label="Generic Name"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.brand_name}
                    onChange={filterChange}
                    name="brand_name"
                    color="primary"
                  />
                }
                label="Brand Name"
              />
            </FormGroup>
          </Box>
          <form noValidate={true} onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  // multiple
                  value={values.drugName}
                  options={medicalListData}
                  style={{ width: '100%' }}
                  getOptionLabel={option => {
                    if (option.type === 'others') {
                      let added = otherMedications.find(
                        item => item?.name === option?.name
                      )
                      if (added) {
                        return option?.name
                      } else {
                        return option.title
                      }
                    }
                    if (option.inputValue) {
                      return option.title
                    }
                    return option?.name ? option?.name : option
                  }}
                  loadingText="Loading..."
                  noOptionsText={
                    medicationsFetchingStatus ? 'Loading...' : 'No Options'
                  }
                  filterOptions={(options, params) => {
                    const filtered = options
                    if (
                      options.length === 0 &&
                      !medicationsFetchingStatus &&
                      params.inputValue !== ''
                    ) {
                      filtered.push({
                        type: 'others',
                        title: `Add "${params.inputValue}"`,
                        name: params.inputValue
                      })
                    }
                    return filtered
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  onChange={handleMedicalChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={classes.Input}
                      label="Drug Name"
                      name={'drugName'}
                      value={values.drugName}
                      // variant="outlined"
                      error={isError(errors.drugName, touched.drugName)}
                      helperText={
                        isError(errors.drugName, touched.drugName)
                          ? errors.drugName
                          : null
                      }
                      loading={medicationsFetchingStatus}
                      onChange={MedicalChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {medicationsFetchingStatus ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box className={classes.InputCol}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Frequency *</InputLabel>
                    <Select
                      className={classes.Input}
                      name={'frequency'}
                      error={isError(errors.frequency, touched.frequency)}
                      helperText={
                        isError(errors.frequency, touched.frequency)
                          ? errors.frequency
                          : null
                      }
                      value={values.frequency}
                      onChange={handleChange}>
                      {FREQUENCY_OPTIONS.map(option => (
                        <MenuItem value={option} key={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  className={classes.Input}
                  type={'number'}
                  label="Duration *"
                  name={'duration'}
                  error={isError(errors.duration, touched.duration)}
                  helperText={
                    isError(errors.duration, touched.duration)
                      ? errors.duration
                      : null
                  }
                  value={values?.duration}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box className={classes.InputCol}>
                  <FormControl className={classes.formControl}>
                    <InputLabel></InputLabel>
                    <Select
                      className={classes.Input}
                      name={'durationString'}
                      value={values.durationString}
                      onChange={handleChange}>
                      {DURATION_OPTIONS.map(option => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <TextField
                    label="Start Date *"
                    type="date"
                    name={'startDate'}
                    error={isError(errors.startDate, touched.startDate)}
                    helperText={
                      isError(errors.startDate, touched.startDate)
                        ? errors.startDate
                        : null
                    }
                    value={values.startDate}
                    onChange={handleChange}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <TextField
                    label="End Date *"
                    type="date"
                    name={'endDate'}
                    disabled={true}
                    error={isError(errors.endDate, touched.endDate)}
                    helperText={
                      isError(errors.endDate, touched.endDate)
                        ? errors.endDate
                        : null
                    }
                    value={values.endDate}
                    onChange={handleChange}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box className={classes.InputCol}>
                  <TextField
                    multiline={true}
                    className={classes.textArea}
                    variant={'outlined'}
                    label="Instructions *"
                    name={'instructions'}
                    error={isError(errors.instructions, touched.instructions)}
                    helperText={
                      isError(errors.instructions, touched.instructions)
                        ? errors.instructions
                        : null
                    }
                    value={values.instructions}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: '20px 40px' }}>
        <Button onClick={handleClose} className={classes.modalSecondaryButton}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} className={classes.modalPrimaryButton}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const validationSchema = yup.object().shape({
  drugName: yup.string().trim().required('Drug name is required'),
  frequency: yup.string().required('Frequency is required'),
  duration: yup.string().trim().required('Duration is required'),
  startDate: yup.string().required('Start date is required'),
  endDate: yup.string().required('End date is required'),
  instructions: yup
    .string()
    .trim()
    .required('Instructions are required')
    .max(250, 'You have exceeded the character limit')
})

const FREQUENCY_OPTIONS = [
  'Once a day',
  'Twice a day',
  'Thrice a day',
  'Four times a day',
  'Five times a day',
  'Six times a day'
]
const DURATION_OPTIONS = [
  { id: 1, label: 'Days' },
  { id: 7, label: 'Weeks' },
  { id: 30, label: 'Months' }
]

const useStyles = makeStyles(theme => ({
  textArea: {
    width: '100%'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formControl: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  select: {
    width: '100%'
  },
  InputCol: {
    '& .MuiInput-root': {
      width: '100%'
    }
  },
  Input: {
    width: '100%'
  },
  Textara: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    padding: '10px'
  },
  ModalBox: {
    width: '650px',
    background: '#fff',
    padding: '0px',
    '@media (max-width:767px)': {
      width: '100%'
    },
    // height:'400px',
    '& h2': {
      textAlign: 'center',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      marginTop: 0,
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer',
        paddingRight: '5px'
      }
    }
  },
  ModalForm: {
    width: '100%',
    padding: '40px',
    '@media (max-width:767px)': {
      padding: '10px',
      height: '400px',
      overflowY: 'auto'
    }
  },
  DownloadBtn: {
    width: '120px',
    background: '#3f4b79',
    height: '40px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '40px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '50px',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#fff',
    cursor: 'pointer',
    height:'40px',
    fontSize:'14px'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    cursor: 'pointer',
    height:'40px',
    fontSize:'14px'
  },
  ModalHead: {
    width:'500px',
    margin:'0px auto',
    fontFamily:'Poppins',
    '& h3':{
      padding:'15px 24px 10px',
      fontSize:'18px',
      marginBottom:'0px',
      borderBottom:'1px #ccc solid',
      fontWeight:'600'
    }
  },
  errMsg: {
    color: 'red',
    fontSize: '12px',
    position:'absolute'
  }
}))

export default EditMedicationModal
