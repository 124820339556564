import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import PublicNavbar from '../components/CommonComponents/PublicNavbar'
import { SUPPORTED_COUNTRIES } from '../Constants'
import { Layout } from '../patientLayouts'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import HomeBanner from '../images/home_banner.png'
import HomeBanner2 from '../images/home_banner2.png'
import HomeBanner3 from '../images/home_banner3.png'
import blog from '../images/blog.jpg'
import blog2 from '../images/blog2.jpg'
import blog3 from '../images/blog3.jpg'
import homebg1 from '../images/homebg1.png'
import homebg2 from '../images/homebg2.png'
import homebg3 from '../images/homebg3.png'
import contactBg from '../images/contactBg.png'
import why1 from '../images/why1.png'
import why2 from '../images/why2.png'
import why3 from '../images/why3.png'
import why4 from '../images/why4.png'
import Dashboard from '../images/dashboard.png'
import BookAppointmentIcon from '../images/docappt.png'
import labTest from '../images/labtest.png'
import chronicProgram from '../images/chronicprog.png'
import premiumCarePlix from '../images/premium.png'
import Tele from '../images/c24.png'
import Scheduler from '../images/scheduler.png'
import Profile from '../images/profile.png'
import drpic1 from '../images/drpic1.png'
import drpic2 from '../images/drpic2.png'
import drpic3 from '../images/drpic3.png'
import { withRouter } from 'react-router'
import UserBrowser from '../BrowserSupport'
import Slider from 'infinite-react-carousel'
import Link from '@material-ui/core/Link'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Portal from '@material-ui/core/Portal'
import OrientationError from '../components/CommonComponents/OrientationError'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  }
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

const Home = () => {
  const classes = useStyles()
  const { push } = useHistory()

  const [searchValue, setSearchValue] = useState('')
  const [country, setCountry] = useState(SUPPORTED_COUNTRIES[0])
  const scrollRef = React.useRef()

  const searchChangeHandler = event => {
    setSearchValue(event.target.value)
  }

  const countryChangeHandler = event => {
    const country = SUPPORTED_COUNTRIES.find(
      ({ value }) => value === event.target.value
    )
    setCountry(country)
  }

  const settings = {
    arrows: false,
    arrowsBlock: false,
    autoplay: true,
    dots: true,
    duration: 100
  }

  const onSearch = () => {
    push(`/${searchValue}`)
  }

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      push(`/${searchValue}`)
    }
  }
  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  const responsive3 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const [show, setShow] = React.useState('')
  const [show2, setShow2] = React.useState('')
  const [show3, setShow3] = React.useState('')
  const container = React.useRef(null)

  const handleClick = React.useCallback(
    (e, id) => {
      if (id == 1) {
        if (e.target.textContent == 'View Less') {
          setShow('')
        } else {
          setShow(id)
          setShow2('')
          setShow3('')
        }
      } else if (id == 2) {
        if (e.target.textContent == 'View Less') {
          setShow2('')
        } else {
          setShow('')
          setShow2(id)
          setShow3('')
        }
      } else if (id == 3) {
        if (e.target.textContent == 'View Less') {
          setShow3('')
        } else {
          setShow('')
          setShow2('')
          setShow3(id)
        }
      }
    },
    [show]
  )
  // **********//

  return (
    <Layout hideNavbar>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box className="top-header">
            <PublicNavbar />
          </Box>
        </Grid>
        <Grid item md={12} style={{ width: '100%', position: 'relative' }}>
          <Slider
            className="top-slider"
            {...settings}
            style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              <img className={classes.image} src={HomeBanner} alt="banner" />
            </div>
            <div style={{ width: '100%' }}>
              <img className={classes.image} src={HomeBanner2} alt="banner" />
            </div>
            <div style={{ width: '100%' }}>
              <img className={classes.image} src={HomeBanner3} alt="banner" />
            </div>
            <div style={{ width: '100%' }}>
              <img className={classes.image} src={HomeBanner} alt="banner" />
            </div>
          </Slider>
        </Grid>
        <Grid item md={12}>
          <Box
            m={{ xs: 2, sm: 3, md: 8 }}
            textAlign={{ xs: 'center', md: 'center' }}
            color="primary.dark"
            style={{ marginBottom: '2px' }}>
            <Typography
              variant="h4"
              className={classes.annotation}
              style={{
                marginBottom: '24px',
                fontFamily: 'Poppins',
                fontWeight: 700,
                color: '#063bc4'
              }}>
              Your all-in-one solution to find doctors,
              <br /> manage health records, and monitor your daily well-being.
            </Typography>
            <p p={{ xs: 1, sm: 2, md: 8 }} style={{ paddingBottom: '5px' }}>
              CarePlix brings convenience to your fingertips by offering a
              comprehensive product suite to meet all your healthcare needs.
              Easily find and connect with doctors, keep all your health records
              in one place, and monitor your daily health metrics to stay on top
              of your well-being. Simplify your healthcare journey with
              CarePlix.
            </p>
          </Box>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={5} spacing={1}>
          <Box
            m={{ xs: 2, sm: 3, md: 8 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark"
            style={{ marginTop: '30px', margin: '40px' }}>
            <img
              src={why1}
              alt="dashboard img"
              style={{
                width: '100%',
                borderRadius: '20px',
                marginBottom: '10px'
              }}
            />
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{
                marginBottom: '24px',
                fontFamily: 'Poppins',
                fontWeight: 700,
                color: '#063bc4'
              }}>
              Find A Nearby Doctor
            </Typography>
            <p>
              Connect with healthcare professionals in your area and schedule
              appointments effortlessly.
            </p>
          </Box>
        </Grid>

        <Grid item md={5} spacing={1}>
          <Box
            m={{ xs: 2, sm: 3, md: 8 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark"
            style={{ marginTop: '30px', margin: '40px' }}>
            <img
              src={why3}
              alt="dashboard img"
              style={{
                width: '100%',
                borderRadius: '20px',
                marginBottom: '10px'
              }}
            />
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{
                marginBottom: '24px',
                fontFamily: 'Poppins',
                fontWeight: 700,
                color: '#063bc4'
              }}>
              Manage All Your Health Records
            </Typography>
            <p>
              Download HealthHub, the secure repository for all your health
              data. Keep your medical records safe and easily accessible.
            </p>
          </Box>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={1}></Grid>
        <Grid item md={5} spacing={1}>
          <Box
            m={{ xs: 2, sm: 3, md: 8 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark"
            style={{ marginTop: '30px', margin: '40px' }}>
            <img
              src={why2}
              alt="dashboard img"
              style={{
                width: '100%',
                borderRadius: '20px',
                marginBottom: '10px'
              }}
            />
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{
                marginBottom: '24px',
                fontFamily: 'Poppins',
                fontWeight: 700,
                color: '#063bc4'
              }}>
              Wellness In A Box
            </Typography>
            <p>
              Download CarePlix - All in One wellness app to know and improve
              your health.
            </p>
          </Box>
        </Grid>
        <Grid item md={5} spacing={1}>
          <Box
            m={{ xs: 2, sm: 3, md: 8 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark"
            style={{ marginTop: '30px', margin: '40px' }}>
            <img
              src={why4}
              alt="dashboard img"
              style={{
                width: '100%',
                borderRadius: '20px',
                marginBottom: '10px'
              }}
            />
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{
                marginBottom: '24px',
                fontFamily: 'Poppins',
                fontWeight: 700,
                color: '#063bc4'
              }}>
              Create Your ABHA ID
            </Typography>
            <p>
              Join the integrated digital health infrastructure of the country
            </p>
          </Box>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item xs={12} sm={3} className={classes.colorDarkBg}>
          <Box
            className={classes.countbox}
            textAlign={{ xs: 'center', md: 'left' }}>
            <img
              src={BookAppointmentIcon}
              alt="dashboard img"
              style={{ width: '50%' }}
            />
            <Typography variant="h3" className={classes.count}>
              <p>Book Doctor Appointment</p>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.colorDarkBg}>
          <Box
            className={classes.countbox}
            textAlign={{ xs: 'center', md: 'left' }}>
            <img src={labTest} alt="dashboard img" style={{ width: '50%' }} />
            <Typography variant="h3" className={classes.count}>
              <p>Book Lab Tests</p>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.colorDarkBg}>
          <Box
            className={classes.countbox}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.white">
            <img
              src={chronicProgram}
              alt="dashboard img"
              style={{ width: '50%' }}
            />
            <Typography variant="h3" className={classes.count}>
              <p>Chronic Programs</p>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.colorDarkBg}>
          <Box
            className={classes.countbox}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.white">
            <img
              src={premiumCarePlix}
              alt="dashboard img"
              style={{ width: '50%' }}
            />
            <Typography variant="h3" className={classes.count}>
              <p>CarePlix Premium</p>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            className={classes.featureheading}
            m={{ xs: 2, sm: 3, md: 8 }}
            textAlign={{ xs: 'center', md: 'center' }}
            color="primary.dark"
            style={{ marginBottom: '2px' }}>
            <Typography
              variant="h4"
              className={classes.annotation}
              style={{ marginBottom: '0px' }}
              id="features">
              Our <strong style={{ color: '#313d6a' }}>FEATURES</strong>
            </Typography>
          </Box>
        </Grid>

        <Grid item md={6}>
          <Box
            m={{ xs: 4, sm: 6, md: 16 }}
            textAlign={{ xs: 'center', md: 'right' }}
            color="primary.dark">
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px', color: '#000' }}>
              Practice Management
            </Typography>
            <p style={{ marginBottom: '10px' }}>
              Industry-leading front office and scheduling software all
              integrated billing and payment solutions*
            </p>

            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px', color: '#000' }}>
              EHR Software
            </Typography>
            <p style={{ marginBottom: '10px' }}>
              Smart clinical apps for quick access to patient information from
              any browser.
            </p>

            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px', color: '#000' }}>
              Smart EHR Dashboard
            </Typography>
            <p style={{ marginBottom: '10px' }}>
              Schedule snapshot and task overviews that shows your completed and
              critical items.
            </p>
          </Box>
        </Grid>

        <Grid item md={6} className={classes.Serviceimg}>
          <img src={Dashboard} alt="dashboard img" style={{ width: '100%' }} />
        </Grid>

        <Grid
          item
          md={6}
          // className={classes.Serviceimg}
          className={classes.homebg2}>
          <img src={Profile} alt="dashboard img" style={{ width: '100%' }} />
        </Grid>

        <Grid item md={6}>
          <Box
            m={{ xs: 4, sm: 6, md: 16 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark">
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px', color: '#000' }}>
              Doctor Profile
            </Typography>
            <p style={{ marginBottom: '10px' }}>
              Enable convenient, timesaving, self-service options for your
              patients, giving them access to schedule appointments, receive
              price estimates and statements, and even make payments.
            </p>

            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px', color: '#000' }}>
              Reporting & Analytics
            </Typography>
            <p style={{ marginBottom: '10px' }}>
              Get real-time information of your financial health and uncover
              hidden opportunities.
            </p>

            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px', color: '#000' }}>
              Patient Engagement
            </Typography>
            <p style={{ marginBottom: '10px' }}>
              Engage patient’s healthcare experience online, while capturing
              feedback in real-time.
            </p>
          </Box>
        </Grid>

        <Grid item md={6}>
          <Box
            m={{ xs: 4, sm: 6, md: 16 }}
            textAlign={{ xs: 'center', md: 'right' }}
            color="primary.dark">
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px', color: '#000' }}>
              Patient Portal
            </Typography>
            <p style={{ marginBottom: '10px' }}>
              Engage your patients with a wide variety of self-help tools and
              time-saving features.
            </p>

            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px', color: '#000' }}>
              Personal Telemedicine
            </Typography>
            <p style={{ marginBottom: '10px' }}>
              Expand your care coverage and reduce costs whi le increasing
              patient satisfaction.
            </p>
          </Box>
        </Grid>

        <Grid
          item
          md={6}
          // className={classes.Serviceimg}
          className={classes.homebg1}>
          <img src={Tele} alt="tele img" style={{ width: '100%' }} />
        </Grid>

        <Grid
          item
          md={6}
          // className={classes.Serviceimg}
          className={classes.homebg2}>
          <img src={Scheduler} alt="scheduler img" style={{ width: '100%' }} />
        </Grid>

        <Grid item md={6}>
          <Box
            m={{ xs: 4, sm: 6, md: 16 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark">
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px', color: '#000' }}>
              CareNow Scheduler
            </Typography>
            <p>
              Increase staff efficiencies and close gaps in care by allowing
              your patients to schedule appointments on their preferred device,
              anytime.
            </p>

            <p>
              By allowing your patients better access to care, your organization
              will see increased appointment, improved call center efficiency, a
              reduction in noshows, and an overall enhanced patient experience.
            </p>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          style={{
            backgroundImage:
              'linear-gradient(to right, rgb(134 50 155), #111d4e)',
            padding: '50px'
          }}>
          <Slider {...settings}>
            <div style={{ width: '100%' }}>
              <img
                src={drpic1}
                alt="drpic img"
                style={{
                  width: '100px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block'
                }}
              />
              <p style={{ textAlign: 'center', color: '#fff' }}>
                Started using CareNowDoc recently, and it's really super easy to
                do consultations. I am doing both physical and tele
                consultations and must say this application is super helpful for
                doctors as well as patients.
              </p>
              <h3 style={{ textAlign: 'center', color: '#fff' }}>
                Dr Nita Dasgupta
              </h3>
            </div>
            <div style={{ color: '#fff' }}>
              <img
                src={drpic2}
                alt="drpic img"
                style={{
                  width: '100px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block'
                }}
              />
              <p style={{ textAlign: 'center', color: '#fff' }}>
                Very useful for doctors, specially the scheduler. Also, the
                patient chart and e-prescription are really good and
                professional.
              </p>
              <h3 style={{ textAlign: 'center', color: '#fff' }}>
                Dr Arvind Malhotra
              </h3>
            </div>
            <div style={{ color: '#fff' }}>
              <img
                src={drpic3}
                alt="drpic img"
                style={{
                  width: '100px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block'
                }}
              />
              <p style={{ textAlign: 'center', color: '#fff' }}>
                Best EMR and remote care management application I have used so
                far. Their 24/7 Virtual medical assistance is really good, and
                honestly speaking CareNowDoc has increased my appointments count
                by 2x. My profile card is now very easily shared from patient to
                patient as recommendations.
              </p>
              <h3 style={{ textAlign: 'center', color: '#fff' }}>
                Dr Rakesh Singh
              </h3>
            </div>
          </Slider>
        </Grid>

        <Grid item md={6}>
          <Box
            m={{ xs: 4, sm: 6, md: 16 }}
            textAlign={{ xs: 'center', md: 'left' }}
            color="primary.dark">
            <Typography
              variant="h6"
              className={classes.annotation}
              style={{ marginBottom: '24px' }}>
              CareNow At A Glimpse
            </Typography>
            <p>
              Higher patient satisfaction rates are just a few clicks away. Our
              Doc platform are the all-in-one solution to improving the patient
              experience, spending more time with your patients, and eliminating
              the redundancies of inefficient practice management systems.
              <br />
              <br />
              Provide a full picture of health and membership information for
              your members and a secure web portal for providers to interact
              with and view managed care information Improve your patients'
              health and care with CareNowDoc. Predictive analytics and embedded
              decision support tools support clinical practice to yield better
              outcomes.
            </p>
            {/* <a href="#request-callback"> */}
            <Button
              className={classes.contactButton}
              onClick={() => window.scrollTo(0, scrollRef.current.offsetTop)}>
              Request a callback
            </Button>
            {/* </a> */}
          </Box>
        </Grid>

        <Grid item md={6} className={classes.homebg2}>
          <Box mt={{ xs: 4, sm: 6, md: 16 }} className="home-video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/nvh68VmwJiM"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </Box>
        </Grid>
      </Grid>
      <div className="pricing-col" id="pricing">
        <Typography variant="h4" className={classes.annotation}>
          Our Pricing Plans
        </Typography>
        <Typography variant="h5" className={classes.Subheading}>
          It's{' '}
          <span>
            <i class="fa fa-inr"></i> 0*
          </span>{' '}
          initially. Pay only when you earn.
        </Typography>
        <AppBar
          position="static"
          className="PricingTab"
          style={{ width: '360px', margin: '0px auto' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example">
            <LinkTab
              label="Fixed Subscription"
              href="/drafts"
              {...a11yProps(0)}
            />
            <LinkTab
              className="Blanktab"
              style={{
                minWidth: '25px !important',
                maxWidth: '25px !important'
              }}
              label="/"
            />
            <LinkTab label="Pay As You Go" href="/spam" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Box className="owl-stage">
            <Box className="ui-card ui-curve shadow-lg">
              <Box className="card-header bg-dark-gray left-box1">
                <h2>Micro Tier</h2>
              </Box>
              <div className="card-body">
                <div className="st-pricing-feature feature2">
                  <Box className="price text-red">
                    {/* <span className="curency">£</span> */}
                    <span className="price">
                      <s>
                        <i class="fa fa-inr"></i> 1399 /mo
                      </s>
                      <br />
                      <i class="fa fa-inr"></i> 699 /mo
                    </span>
                    <span className="">Billed Annually (50% Off)</span>
                    <p className="price-discount">
                      <span>
                        <s>
                          <i class="fa fa-inr"></i> 1599 /mo
                        </s>
                      </span>
                      <br />
                      <span>
                        <i class="fa fa-inr"></i> 799 /mo
                      </span>{' '}
                      Billed Monthly (50% Off)
                    </p>
                    <h6 className="doctor-text">Upto 5 Doctors</h6>
                  </Box>
                  <Box>
                    {show == 1 ? (
                      <div className={classes.alert}>
                        <ul className="st-pricing-feature-list st-mp0">
                          <li>10 Days FREE Trial</li>
                          <li>Intelligent Scheduler</li>
                          <li>
                            Unlimited In-Person and Tele Consult, @3.25% trans
                            fee
                          </li>
                          <li>Unlimited Emails</li>
                          <li>1000 SMS notifications</li>
                          <li>Patient Charting with Ai based Vitals Capture</li>
                          <li>Easy Reschedule and Cancellation</li>
                          <li>
                            Doctor / Clinic smart profile card with QR code
                          </li>
                          <li>
                            For Clinic (FREE - Landing page with doctor listing)
                          </li>
                          <li>24/7 Virtual Medical Assistance</li>
                          <li>e-Prescription</li>
                          <li>Clinical Insights</li>
                          <li>Easy Wallet (No transactional cost)</li>
                          <li>Suggestive Medications</li>
                          <li>No App Required</li>
                        </ul>
                        <div className="st-pricing-btn">
                          <a
                            href="#"
                            className="st-btn st-style2 st-color1 st-size-medium">
                            Contact Now
                          </a>
                        </div>
                      </div>
                    ) : null}

                    <div className={classes.alert} ref={container} />
                    <button
                      type="button"
                      className="details-btn"
                      onClick={(e, id) => handleClick(e, (id = 1))}>
                      {show === 1 ? 'View Less' : 'View More'}
                    </button>
                  </Box>

                  {/* <div className="st-height-b30 st-height-lg-b30"></div> */}
                </div>
              </div>
            </Box>

            <Box className="ui-card ui-curve shadow-lg color-card">
              <Box className="card-header bg-sky">
                <h2>Mini Tier</h2>
              </Box>
              <div className="card-body">
                <div className="st-pricing-feature">
                  <Box className="price text-red">
                    {/* <span className="curency">£</span> */}
                    <span className="price">
                      <s>
                        <i class="fa fa-inr"></i> 1199 /mo
                      </s>
                      <br />
                      <i class="fa fa-inr"></i> 599 /mo
                    </span>
                    <span className="">Billed Annually (50% Off)</span>
                    <p className="">
                      <s>
                        <i class="fa fa-inr"></i> 1399 /mo
                      </s>
                      <br />
                      <i class="fa fa-inr"></i> 699 /mo Billed Monthly (50% Off)
                    </p>
                    <h6 className="doctor-text">Upto 15 Doctors</h6>
                  </Box>

                  <Box>
                    {show2 === 2 ? (
                      <div className={classes.alert}>
                        <ul className="st-pricing-feature-list st-mp0">
                          <li>10 Days FREE Trial</li>
                          <li>Intelligent Scheduler</li>
                          <li>
                            Unlimited In-Person and Tele Consult, @3.25% trans
                            fee
                          </li>
                          <li>Unlimited Emails</li>
                          <li>1000 SMS notifications</li>
                          <li>Patient Charting with Ai based Vitals Capture</li>
                          <li>Easy Reschedule and Cancellation</li>
                          <li>
                            Doctor / Clinic smart profile card with QR code
                          </li>
                          <li>
                            For Clinic (FREE - Landing page with doctor listing)
                          </li>
                          <li>24/7 Virtual Medical Assistance</li>
                          <li>e-Prescription</li>
                          <li>Clinical Insights</li>
                          <li>Easy Wallet (No transactional cost)</li>
                          <li>Suggestive Medications</li>
                          <li>No App Required</li>
                        </ul>
                        <div className="st-pricing-btn">
                          <a
                            href="#"
                            className="st-btn st-style2 st-color1 st-size-medium">
                            Contact Now
                          </a>
                        </div>
                      </div>
                    ) : null}
                    <div className={classes.alert} ref={container} />
                    <button
                      type="button"
                      className="details-btn"
                      onClick={(e, id) => handleClick(e, (id = 2))}>
                      {show2 === 2 ? 'View Less' : 'View More'}
                    </button>
                  </Box>
                  {/* <div className="st-height-b30 st-height-lg-b30"></div> */}
                </div>
              </div>
            </Box>

            <Box className="ui-card ui-curve shadow-lg">
              <Box className="card-header bg-dark-gray right-box3">
                <h2>Enterprise</h2>
              </Box>
              <div className="card-body">
                <div className="st-pricing-feature feature3">
                  <Box className="price text-red">
                    {/* <span className="curency">£</span> */}
                    <span className="price">Contact </span>
                    <span className="">For Pricing</span>

                    <p className="phone-number">033-418-16533</p>
                    <h6 className="doctor-text">Unlimited Doctors</h6>
                  </Box>

                  <Box>
                    {show3 === 3 ? (
                      <div className={classes.alert}>
                        <ul className="st-pricing-feature-list st-mp0">
                          <li>10 Days FREE Trial</li>
                          <li>Intelligent Scheduler</li>
                          <li>
                            Unlimited In-Person and Tele Consult, @3.25% trans
                            fee
                          </li>
                          <li>Unlimited Emails</li>
                          <li>1000 SMS notifications</li>
                          <li>Patient Charting with Ai based Vitals Capture</li>
                          <li>Easy Reschedule and Cancellation</li>
                          <li>
                            Doctor / Clinic smart profile card with QR code
                          </li>
                          <li>
                            For Clinic (FREE - Landing page with doctor listing)
                          </li>
                          <li>24/7 Virtual Medical Assistance</li>
                          <li>e-Prescription</li>
                          <li>Clinical Insights</li>
                          <li>Easy Wallet (No transactional cost)</li>
                          <li>Suggestive Medications</li>
                          <li>No App Required</li>
                        </ul>
                        <div className="st-pricing-btn">
                          <a
                            href="#"
                            className="st-btn st-style2 st-color1 st-size-medium">
                            Contact Now
                          </a>
                        </div>
                      </div>
                    ) : null}
                    <div className={classes.alert} ref={container} />
                    <button
                      type="button"
                      className="details-btn"
                      onClick={(e, id) => handleClick(e, (id = 3))}>
                      {show3 === 3 ? 'View Less' : 'View More'}
                    </button>
                  </Box>

                  {/* <div className="st-height-b30 st-height-lg-b30"></div> */}
                </div>
              </div>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box className="pay-box">
            <Box className="pay-top">
              <h4>Total Earnings (Online + Offline)</h4>
              <h4>Charge</h4>
            </Box>
            <Box className="pay-row">
              <h6>0 - 25,000</h6>
              <h6>2.5%</h6>
            </Box>
            <Box className="pay-row">
              <h6>25,000 - 75,000</h6>
              <h6>2%</h6>
            </Box>
            <Box className="pay-row">
              <h6>75,000 - 250,000</h6>
              <h6>1.75%</h6>
            </Box>
            <Box className="pay-row">
              <h6>2,50,000 - 5,00,000</h6>
              <h6>1%</h6>
            </Box>
            <Box className="pay-row">
              <h6>5,00,000 +</h6>
              <h6>0.5%</h6>
            </Box>
          </Box>
        </TabPanel>
      </div>

      {/*
      <div className="blog">
        <Typography
          variant="h4"
          className={classes.annotation}
          style={{ marginBottom: '0px' }}>
          Our <strong style={{ color: '#313d6a' }}>Blog</strong>
        </Typography>
        <h6>Check our latest blog</h6>
        <Carousel responsive={responsive2}>
          <div>
            <div className="featured-imagebox featured-imagebox-blog">
              <div className="featured-thumbnail">
                <img className={classes.image} src={blog} alt="blog" />
              </div>
              <div className="featured-content">
                <div className="ttm-box-post-date">
                  <span className="ttm-entry-date">
                    <time
                      className="entry-date"
                      datetime="2019-01-16T07:07:55+00:00">
                      17<span className="entry-month entry-year">Apr</span>
                    </time>
                  </span>
                </div>
                <div className="featured-title">
                  <h5> You Must Try 20 Secret Of Digital Transform</h5>
                </div>
                <div className="post-meta">
                  <span className="ttm-meta-line">Posted by: Admin</span>
                </div>
                <div className="featured-desc">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the...
                  </p>
                </div>
                <Link href="#">
                  <b>Read More</b>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="featured-imagebox featured-imagebox-blog">
              <div className="featured-thumbnail">
                <img className={classes.image} src={blog2} alt="blog" />
              </div>
              <div className="featured-content">
                <div className="ttm-box-post-date">
                  <span className="ttm-entry-date">
                    <time
                      className="entry-date"
                      datetime="2019-01-16T07:07:55+00:00">
                      17<span className="entry-month entry-year">Apr</span>
                    </time>
                  </span>
                </div>
                <div className="featured-title">
                  <h5> You Must Try 20 Secret Of Digital Transform</h5>
                </div>
                <div className="post-meta">
                  <span className="ttm-meta-line">Posted by: Admin</span>
                </div>
                <div className="featured-desc">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the...
                  </p>
                </div>
                <Link href="#">
                  <b>Read More</b>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="featured-imagebox featured-imagebox-blog">
              <div className="featured-thumbnail">
                <img className={classes.image} src={blog3} alt="blog" />
              </div>
              <div className="featured-content">
                <div className="ttm-box-post-date">
                  <span className="ttm-entry-date">
                    <time
                      className="entry-date"
                      datetime="2019-01-16T07:07:55+00:00">
                      17<span className="entry-month entry-year">Apr</span>
                    </time>
                  </span>
                </div>
                <div className="featured-title">
                  <h5> You Must Try 20 Secret Of Digital Transform</h5>
                </div>
                <div className="post-meta">
                  <span className="ttm-meta-line">Posted by: Admin</span>
                </div>
                <div className="featured-desc">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the...
                  </p>
                </div>
                <Link href="#">
                  <b>Read More</b>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="featured-imagebox featured-imagebox-blog">
              <div className="featured-thumbnail">
                <img className={classes.image} src={blog} alt="blog" />
              </div>
              <div className="featured-content">
                <div className="ttm-box-post-date">
                  <span className="ttm-entry-date">
                    <time
                      className="entry-date"
                      datetime="2019-01-16T07:07:55+00:00">
                      17<span className="entry-month entry-year">Apr</span>
                    </time>
                  </span>
                </div>
                <div className="featured-title">
                  <h5> Easy steps to maintain proper diet</h5>
                </div>
                <div className="post-meta">
                  <span className="ttm-meta-line">Posted by: Admin</span>
                </div>
                <div className="featured-desc">
                  <p>
                    A healthy diet helps pave the way to a healthy heart and
                    blood vessels, strong bones and muscles, a sharp mind, and
                    so much more.
                  </p>
                </div>
                <Link href="#">
                  <b>Read More</b>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="featured-imagebox featured-imagebox-blog">
              <div className="featured-thumbnail">
                <img className={classes.image} src={blog2} alt="blog" />
              </div>
              <div className="featured-content">
                <div className="ttm-box-post-date">
                  <span className="ttm-entry-date">
                    <time
                      className="entry-date"
                      datetime="2019-01-16T07:07:55+00:00">
                      17<span className="entry-month entry-year">Apr</span>
                    </time>
                  </span>
                </div>
                <div className="featured-title">
                  <h5> You Must Try 20 Secret Of Digital Transform</h5>
                </div>
                <div className="post-meta">
                  <span className="ttm-meta-line">Posted by: Admin</span>
                </div>
                <div className="featured-desc">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the...
                  </p>
                </div>
                <Link href="#">
                  <b>Read More</b>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="featured-imagebox featured-imagebox-blog">
              <div className="featured-thumbnail">
                <img className={classes.image} src={blog3} alt="blog" />
              </div>
              <div className="featured-content">
                <div className="ttm-box-post-date">
                  <span className="ttm-entry-date">
                    <time
                      className="entry-date"
                      datetime="2019-01-16T07:07:55+00:00">
                      17<span className="entry-month entry-year">Apr</span>
                    </time>
                  </span>
                </div>
                <div className="featured-title">
                  <h5> You Must Try 20 Secret Of Digital Transform</h5>
                </div>
                <div className="post-meta">
                  <span className="ttm-meta-line">
                    <i className="fa fa-comments"></i>2,comments
                  </span>
                  <span className="ttm-meta-line">
                    <i className="fa fa-user"></i>Admin
                  </span>
                </div>
                <div className="featured-desc">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the...
                  </p>
                </div>
                <Link href="#">
                  <b>Read More</b>
                </Link>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      */}
      <Grid item md={12}>
        <h3
          ref={scrollRef}
          id="contactus"
          style={{ paddingTop: '70', color: '#fafafa', fontSize: '30px' }}>
          Request Your FREE Trial Today
        </h3>
      </Grid>
      <Grid item md={12} className={classes.contact}>
        <h2 style={{ textAlign: 'center', color: '#fff', paddingTop: '40px' }}>
          Request Your FREE Trial Today !
        </h2>
        <p style={{ textAlign: 'center', color: '#fff' }}>
          Our onboarding team will get in touch with you within 24 hours.
        </p>
        <iframe
          frameborder="0"
          style={{
            height: '700px',
            width: '100%',
            border: 'none',
            padding: '20px'
          }}
          src="https://forms.zohopublic.in/carenowhealthcare/form/RequestFREETrial/formperma/TVSXB9KgAym_VUIseSLaZiwnI2Ssq5sySk4rwsdr0aA"></iframe>
      </Grid>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%'
  },
  count: {
    color: '#fff',
    '@media (max-width:780px)': {
      fontSize: '1.5rem'
    },
    '& p': {
      fontSize: '24px',
      marginTop: '0',
      '@media (max-width:780px)': {
        fontSize: '18px'
      }
    }
  },
  Subheading: {
    fontSize: '24px',
    color: '#11224b',
    textAlign: 'center',
    marginBottom: '35px',
    '@media (max-width:780px)': {
      fontSize: '18px',
      marginBottom: '15px'
    },
    '& span': {
      fontSize: '34px',
      fontWeight: 'bold',
      '@media (max-width:780px)': {
        fontSize: '24px'
      }
    }
  },
  featureheading: {
    '@media (max-width:780px)': {
      textAlign: 'center',
      marginTop: '40px'
    }
  },
  annotation: {
    color: '#063bc4 !important',
    fontFamily: 'Poppins !important',
    fontWeight: '700 !important',
    '@media (max-width:780px)': {
      marginBottom: '15px !important'
    }
  },
  countbox: {
    padding: '40px 80px',
    '@media (max-width:780px)': {
      padding: '10px 80px'
    }
  },
  Serviceimg: {
    marginTop: '80px',
    '@media (max-width:780px)': {
      marginTop: '20px'
    }
  },
  contactButton: {
    borderRadius: '40px',
    backgroundImage: 'linear-gradient(to right, #46558d , #212b50)',
    color: '#fff',
    padding: '10px 20px',
    textTransform: 'initial'
  },
  mobileImage: {
    width: '100%',
    objectFit: 'cover',
    height: '30vh'
  },
  annotation: {
    marginBottom: theme.spacing(3),
    '@media (max-width:780px)': {
      fontSize: '20px'
    }
  },
  colorWhiteBg: {
    backgroundColor: '#fff'
  },
  colorDarkBg: {
    backgroundColor: '#191355',
    '@media (max-width:780px)': {
      paddingTop: '10px'
    }
  },
  homebg1: {
    background: `url(${homebg1})`,
    backgroundRepeat: 'no-repeat'
  },
  homebg2: {
    '& iframe': {
      width: '100%'
    }
  },
  homebg2: {
    background: `url(${homebg2})`,
    backgroundRepeat: 'no-repeat',
    '@media (max-width:780px)': {
      width: '100%'
    }
  },
  homebg3: {
    background: `url(${homebg3})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'inherit'
  },
  contact: {
    background: `url(${contactBg})`
  }
}))

export default UserBrowser(withRouter(Home))
