import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import Dashboard from '../Clinic/views/reports/DashboardView'
import Doctors from '../Clinic/views/doctors/doctorsView'
import Appointments from '../Clinic/views/Appointments/AppointmentsView'
import Schedule from '../Clinic/views/schedule/scheduleView'
import Booking from '../Clinic/views/bookappointments/bookingView'
import Services from '../Clinic/views/services/servicesView'
import Wallet from '../Clinic/views/Wallet/WalletView'
import AccountView from '../Clinic/views/account/AccountView'
import LoginPage from '../Clinic/views/auth/Login'
import NotFoundView from '../Clinic/views/errors/NotFoundView'
import SettingsView from '../Clinic/views/settings/SettingsView'
import ClinicProtectedRoute from '../components/CommonComponents/ClinicProtectedRoute'
import PaymentLayout from '../Clinic/views/payment/PaymentLayout'
import { CLINIC_USER_ID } from '../Constants'
import PaymentErrorMessage from '../Clinic/views/PaymentError/PaymentErrorMessage'
import PaymentStepLayout from '../Clinic/views/PaymentStep/PaymentStepLayout'

export default function ClinicRoutes() {
  localStorage.setItem('routing_mode', 'clinic')
  console.log(localStorage.getItem('routing_mode'))

  return (
    <Switch>
      <Route
        exact
        path="/clinic/login"
        render={() => {
          return localStorage.getItem(CLINIC_USER_ID) ? (
            <Redirect to="/clinic/dashboard" />
          ) : (
            <LoginPage />
          )
        }}
      />
      <Route exact path="/clinic/payment/:clinic_slug" component={PaymentLayout} />
      <Route exact path="/clinic/payment-status/:status" component={PaymentErrorMessage} />

      <ClinicProtectedRoute>
        <Route exact path="/clinic/dashboard" component={Dashboard} />
        <Route exact path="/clinic/doctors" component={Doctors} />
        <Route exact path="/clinic/appointments" component={Appointments} />
        <Route exact path="/clinic/schedule" component={Schedule} />
        <Route exact path="/clinic/schedule/:doctor_id" component={Schedule} />
        <Route exact path="/clinic/booking" component={Booking} />
        <Route exact path="/clinic/payment" component={PaymentStepLayout} />
        <Route
          exact
          path="/clinic/booking/patient_selection"
          component={Booking}
        />
        <Route exact path="/clinic/services" component={Services} />
        <Route exact path="/clinic/settings" component={SettingsView} />
        <Route exact path="/clinic/account" component={AccountView} />
        <Route exact path="/clinic/wallet" component={Wallet} />
      </ClinicProtectedRoute>
      <Route exact path="/clinic/*" component={NotFoundView} />
    </Switch>
  )
}
