import React from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'
import * as moment from 'moment'
import {
  Avatar,
  Paper,
  Grid,
  Typography,
  makeStyles,
  Chip,
  IconButton,
  Button
} from '@material-ui/core'
import { CONSULTATION_TYPE } from '../../Constants'
import ListBgHoverImg from '../../images/listbghover.png'
import ListBgImg from '../../images/listbg.png'

export default function AppointmentListItem(props) {
  const classes = useStyles()
  const appointmentItem = props.appointment
  let disabledCard = props?.disabledCard ? props?.disabledCard : false
  let selectedCard = props?.selected ? props?.selected : false

  const visit_type = [
    { id: '1', label: 'Initial consult' },
    { id: '2', label: 'Follow-up' }
  ]
  const booking_status = [
    {
      id: '1',
      label: 'Booked'
    },
    {
      id: '2',
      label: 'Confirmed'
    },
    {
      id: '3',
      label: 'Checked in'
    },
    {
      id: '4',
      label: 'In-Progress'
    },
    {
      id: '5',
      label: 'Cancelled'
    },
    {
      id: '6',
      label: 'No Show'
    },
    {
      id: '7',
      label: 'Completed'
    },
    {
      id: '8',
      label: 'Rescheduled'
    }
  ]

  return (
    <Paper
      square
      className={
        selectedCard ? classes.appointmentItemSelected : classes.appointmentItem
      }
      style={{ margin: '15px 0' }}>
      <Grid container className={classes.appointmentPage}>
        <Grid item xs={12} md={11}>
          <Grid container spacing={3} style={{ alignItems: 'center' }}>
            <Grid
              item
              xs={12}
              md={props.isForFollowupBooking === false ? 4 : 12}
              className={classes.profilebox + ' ' + classes.item}>
              <Avatar
                className={classes.image}
                alt="Image"
                src={
                  appointmentItem?.doctor_pic_uri
                    ? appointmentItem.doctor_pic_uri
                    : 'https://www.searchpng.com/wp-content/uploads/2019/02/Deafult-Profile-Pitcher.png'
                }
              />

              <h5
                style={{
                  marginTop: '15px',
                  marginBottom: '0',
                  color: '#000',
                  flex: '0 0 75%'
                }}>
                {appointmentItem?.doctor_name}
              </h5>
              <small
                style={{
                  background:
                    props.tag === 'Checked in' || props.tag === 'New'
                      ? '#AAE71D'
                      : props.tag === 'Ongoing'
                      ? '#AF1279'
                      : '#444'
                }}
                className={classes.tag}>
                {props.tag}
              </small>
            </Grid>

            <Grid
              item
              xs={props.isForFollowupBooking === false ? 12 : 12}
              md={props.isForFollowupBooking === false ? 3 : 12}
              className={classes.info + ' ' + classes.item}>
              <div>
                <div className={classes.iconContainer}>
                  <i>
                    <svg
                      className={classes.icon}
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24">
                      <path
                        d="M7 12h2v2H7v-2m14-6v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1V2h2v2h8V2h2v2h1a2 2 0 0 1 2 2M5 8h14V6H5v2m14 12V10H5v10h14m-4-6v-2h2v2h-2m-4 0v-2h2v2h-2m-4 2h2v2H7v-2m8 2v-2h2v2h-2m-4 0v-2h2v2h-2z"
                        fill="#3f4b79"
                      />
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        fill="rgba(0, 0, 0, 0)"
                      />
                    </svg>
                  </i>
                  <b className={classes.text}>
                    {appointmentItem?.appointment_date ? (
                      <>
                        {moment(appointmentItem.appointment_date).format(
                          'DD-MM-YYYY'
                        )}
                      </>
                    ) : (
                      <>---</>
                    )}
                  </b>
                </div>
              </div>
              <div>
                <div className={classes.iconContainer}>
                  <i>
                    <svg
                      className={classes.icon}
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24">
                      <path
                        d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7h1.5z"
                        fill="#3f4b79"
                      />
                      <rect
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                        fill="rgba(0, 0, 0, 0)"
                      />
                    </svg>
                  </i>
                  <b className={classes.text}>
                    {moment(appointmentItem?.start_time, 'hh:mm:ss').format(
                      'hh:mm A'
                    )}
                    -
                    {moment(appointmentItem?.end_time, 'hh:mm:ss').format(
                      'hh:mm A'
                    )}
                  </b>
                </div>
              </div>
            </Grid>
            {props.isForFollowupBooking === false && (
              <Grid
                item
                xs={props.isForFollowupBooking === false ? 12 : 12}
                md={props.isForFollowupBooking === false ? 3 : 12}
                className={classes.info + ' ' + classes.item}>
                <Typography className={classes.appointmentDetails}>
                  {'Status: '}
                  <span>
                    {
                      booking_status.find(
                        option => option.id === appointmentItem?.status
                      )?.label
                    }
                  </span>
                </Typography>
                <Typography className={classes.appointmentDetails}>
                  {'Visit Type: '}
                  <span>
                    {
                      visit_type.find(
                        option => option.id === appointmentItem?.visit_type
                      ).label
                    }
                  </span>
                </Typography>
              </Grid>
            )}
            {props.isForFollowupBooking === false && (
              <Grid
                item
                xs={12}
                md={2}
                className={classes.info + ' ' + classes.item}>
                <Typography className={classes.appointmentDetails}>
                  Appointment Type
                  <div>
                    <Button variant="contained" className={classes.OnlineBtn}>
                      {appointmentItem &&
                      appointmentItem?.appointment_type ===
                        CONSULTATION_TYPE.IN_PERSON.value
                        ? CONSULTATION_TYPE.IN_PERSON.label
                        : CONSULTATION_TYPE.TELE.label}
                    </Button>
                  </div>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {props.isForFollowupBooking === false && (
          <Grid
            item
            xs={12}
            md={1}
            className={classes.action + ' ' + classes.item}
            style={{
              display: 'grid',
              gridTemplateColumns: '65% 20%',
              gridColumnGap: '5px'
            }}>
            {props.initialConsultation ? (
              <>
                <Chip
                  size="small"
                  label="Initial consultation"
                  variant="outlined"
                  style={{ color: '#3f4b79' }}
                />
              </>
            ) : (
              <></>
            )}

            {props.status ? (
              <>
                <div>
                  <b style={{ color: '#3f4b79', fontSize: '14px' }}>Status</b>
                  <br />
                  <small style={{ fontSize: '12px' }}>
                    {props.status === '3' ? <>Checked-in</> : <>Booked</>}
                  </small>
                </div>
              </>
            ) : (
              <></>
            )}
            {disabledCard ? (
              ''
            ) : (
              <IconButton className={classes.button}>
                <Link
                  to={{
                    pathname: '/patient/details' + '/' + appointmentItem.id
                  }}>
                  <IoIosArrowForward
                    className={classes.icon}
                    size={32}
                    color="#fff"
                  />
                </Link>
              </IconButton>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}
const useStyles = makeStyles(theme => ({
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0)
    }
  },
  checkbutton: {
    '@media (max-width:767px)': {
      position: 'absolute',
      top: '20px',
      right: '15px'
    }
  },
  contactcol: {
    '& iconContainer': {
      '@media (max-width:767px)': {
        width: '100%',
        marginRight: '0px'
      }
    }
  },
  Patientname: {
    '@media (max-width:767px)': {
      width: '150px'
    }
  },
  schduletime: {
    '@media (max-width:767px)': {
      display: 'flex',
      width: '100%'
    }
  },
  image: {
    width: '60px',
    height: '60px',
    objectFit: 'cover',
    marginRight: '20px',
    '@media (max-width:767px)': {
      marginRight: 0
    }
  },
  name: {
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.primary.dark,
    textAlign: 'center'
  },
  profilebox: {
    display: 'flex',
    gridTemplateColumns: '24% 65%',
    '& a': { display: 'block' },
    '@media (max-width:767px)': {
      display: 'flex',
      marginBottom: '5px'
    }
  },
  tag: {
    color: '#fff',
    width: '120px',
    padding: '0px 12px',
    background: '#444',
    textAlign: 'center',
    fontWeight: '100',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    '@media (max-width:767px)': {
      width: '90px',
      marginLeft: '20px'
    }
  },
  appointmentItem: {
    padding: '8px',
    borderRadius: '5px',
    position: 'relative',
    width: '100%',
    background: `url(${ListBgImg}) center top no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    marginBottom: '15px',
    flexWrap: 'wrap',
    boxShadow: 'none',
    '& p': {
      '@media (max-width:767px)': {
        fontSize: '14px'
      }
    },
    '&:hover p': {
      color: '#202020'
    },
    '&:hover b': {
      color: '#202020 !important'
    },
    '&:hover h5 a': {
      color: '#202020 !important'
    },
    '&:hover p span': {
      color: '#202020'
    },
    '@media (max-width:767px)': {
      flexDirection: 'column',
      alignItems: 'center'
    },
    '&:hover': {
      background: `url(${ListBgHoverImg}) center top no-repeat`,
      backgroundSize: 'cover'
    },
    '@media (max-width:767px)': {
      padding: '15px',
      boxShadow: '0px 0px 9px 0px #00000030'
    }
  },
  appointmentItemSelected: {
    position: 'relative',
    width: '100%',
    // background: `url(${ListBgHoverImg}) center top no-repeat`,
    padding: '10px',
    borderRadius: '20px',
    backgroundSize: 'cover',
    color: '#202020',
    display: 'flex',
    marginBottom: '15px',
    flexWrap: 'wrap',
    boxShadow: 'none',
    '&:hover p': {
      color: '#202020'
    },
    '&:hover b': {
      color: '#202020 !important'
    },
    '&:hover h5 a': {
      color: '#202020 !important'
    },
    '&:hover p span': {
      color: '#202020'
    },
    '@media (max-width:767px)': {
      flexDirection: 'column',
      alignItems: 'center'
    },
    '&:hover': {
      background: `url(${ListBgHoverImg}) center top no-repeat`,
      backgroundSize: 'cover'
    },
    '@media (max-width:767px)': {
      padding: '15px',
      boxShadow: '0px 0px 9px 0px #00000030'
    }
  },
  icon: {
    width: '1.5em',
    height: '1.5em',
    position: 'relative',
    top: '3px'
  },
  item: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media (max-width:767px)': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  blockcol: {
    '@media (max-width:767px)': {
      display: 'flex',
      alignItems: 'center',
      '& b': {
        marginRight: '10px'
      }
    }
  },
  iconContainer: {
    display: 'grid',
    alignItems: 'center',
    '& b': {
      wordBreak: 'break-all',
      lineHeight: '14px',
      fontSize: '14px'
    },
    gridTemplateColumns: '35px 80%',
    '@media (max-width:767px)': {
      gridTemplateColumns: '35px 90%',
      marginRight: '20px'
    }
  },
  OnlineBtn: {
    textAlign: 'center',
    cursor: 'default',
    backgroundColor: '#98e039',
    borderRadius: '40px',
    color: 'black',
    boxShadow: 'none',
    padding: '3px 30px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#98e039',
      boxShadow: 'none'
    }
  },
  anchor: {
    background: '#3f4b79',
    color: '#fff',
    borderRadius: '50%',
    height: '30px',
    width: '30px'
  },
  text: {
    fontWeight: '500',
    fontSize: '12px'
  },
  action: {
    '@media (max-width:767px)': {
      display: 'flex !important'
    }
  },
  desktopstatus: {
    '@media (max-width:767px)': {
      display: 'none'
    }
  },
  responsivetatus: {
    display: 'none',
    '@media (max-width:767px)': {
      display: 'block'
    }
  },
  button: {
    height: 40,
    width: 40,
    padding: '8px',
    background: theme.palette.primary.main,
    marginLeft: '0',
    alignSelf: 'center',
    '@media (max-width:767px)': {
      position: 'absolute',
      right: '20px',
      top: '25px'
    },
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: theme.palette.primary.main
    }
  }
}))
