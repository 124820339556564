import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import UpcomingAppointments from '../../components/PatientAppointment/UpcomingAppointments'
import PastAppointments from '../../components/PatientAppointment/PastAppointments'
import FollowupAppointments from '../../components/PatientAppointment/FollowupAppointments'
import RescheduledAppointments from '../../components/PatientAppointment/RescheduledAppointments'
import { DashboardLayout } from '../../patientLayouts'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'

export default function Appointments() {
  const classes = useStyles()
  return (
    <DashboardLayout>
      <Link to="/patient/selectPatient" className={classes.navLink}>
        <button
          className={classes.Backbtn}
          style={{ marginTop: 30, marginLeft: 15 }}>
          <IoIosArrowBack className={classes.Backicon} />
          Select Patient Account
        </button>
      </Link>
      <Box className={classes.aside}>
        <Paper elevation={3} className={classes.paper}>
          <FullWidthTabs />
        </Paper>
      </Box>
    </DashboardLayout>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const FullWidthTabs = () => {
  const classess = useStyless()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // const handleChangeIndex = index => {
  //   setValue(index)
  // }

  return (
    <div>
      <h4
        style={{
          fontSize: '20px',
          color: '#000',
          marginTop: '0px',
          fontWeight: 500
        }}>
        My Appointment
      </h4>
      <AppBar
        position="static"
        color="default"
        style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="white"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className="appointment-tab"
          style={{ backgroundColor: 'transparent' }}>
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Upcoming Appointments"
            {...a11yProps(0)}
          />
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Past Appointments"
            {...a11yProps(1)}
          />
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Followup Appointments"
            {...a11yProps(2)}
          />
          <Tab
            style={{ textTransform: 'capitalize' }}
            label="Rescheduled Appointments"
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <div
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        style={{
          backgroundColor: '#fff',
          borderRadius: 20,
          padding: 20,
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
        }}
        index={value}
        // onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <UpcomingAppointments />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <PastAppointments />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <FollowupAppointments />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <RescheduledAppointments />
        </TabPanel>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  aside: {
    padding: '10px 20px 20px 10px',
    '@media (max-width:767px)': {
      padding: '25px 10px 20px 10px'
    }
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  SearchBtn: {
    '@media (max-width:767px)': {
      position: 'absolute',
      right: '0px',
      top: '20px'
    }
  },
  SearchCol: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:767px)': {
      width: '100%',
      '&>div': {
        width: '100%'
      }
    }
  },
  SearchRow2: {
    '@media (max-width:767px)': {
      width: '100%',
      margin: '0px'
    }
  },
  paper: {
    padding: '15px',
    backgroundColor: 'transparent',
    boxShadow: '0px 0px 0px #000'
  },
  AppBar: {
    '& .MuiBox-root': {
      padding: '0'
    },
    checkboxes: {
      marginTop: '15px'
    }
  },
  Backbtn: {
    marginRight: '20px',
    background: 'none',
    border: '0px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    cursor: 'pointer',
    marginBottom: '0',
    '@media (max-width:550px)': {
      marginBottom: '0px'
    }
  },
  Backicon: {
    marginRight: '5px'
  }
}))
const useStyless = makeStyles(() => ({
  checkboxes: {
    marginTop: '15px',
    '& label > span': {
      fontSize: '14px'
    },
    '& span > svg': {
      width: '0.8em',
      height: '0.8em'
    }
  }
}))
