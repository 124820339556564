import React from 'react'
import Medication from './Medication'

const PatientMedication = props => {
  const { disabled } = props
  return (
    <React.Fragment>
      <Medication disabled={disabled} />
    </React.Fragment>
  )
}

export default PatientMedication
