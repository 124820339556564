import React, { useEffect, useState } from 'react'
import {
  Container,
  Grid,
  makeStyles,
  colors,
  Snackbar
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import IconButton from '@material-ui/core/IconButton'

import EditField from './EditField'
import Page from '../../../components/Page'
import Button from '../../../components/Button'
import Loader from '../../../components/ClinicLoader'
import { NETWORK_STATUS } from '../../../../Constants'
import { SuperClinicStore } from '../../../../stores'
import { SUPER_CLINIC_USER_ID } from '../../../../Constants'

import superclinic from '../../../../images/image1.jpg'

const useStyles = makeStyles(() => ({
  root: {},
  errMsg: {
    color: 'red'
  },
  input: {
    display: 'none'
  },
  MyaccountTab: {
    boxShadow: 'none',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
      '& button': {
        minWidth: '33%'
      }
    }
  }
}))

const BankDetails = () => {
  const classes = useStyles()
  const history = useHistory()

  //Store state
  const {
    bankinfo,
    networkStatus,
    reload,
    message,
    messageToggle
  } = SuperClinicStore.useStoreState(state => ({
    ...state.bankDetails
  }))

  //Store acitons
  const {
    getBankInfo,
    editBankInfo,
    setReload,
    setMessage
  } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.bankDetails
  }))

  useEffect(() => {
    if (reload) {
      getBankInfo({
        super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID)
      })
    }
    setReload(false)
  }, [reload])

  const handleClose = (event, reason) => {
    setMessage({
      message: '',
      messageToggle: false
    })
  }

  //Form inital valule
  const initialValues = {
    accnummber: bankinfo.account_no,
    cnfAccNumber: bankinfo.account_no,
    ifcCode: bankinfo.ifsc_code,
    beneficiaryName: bankinfo.benificiary_name,
    bankName: bankinfo.bank_name,
    branchName: bankinfo.branch_name
  }

  //Form validation
  const formValidation = Yup.object({
    accnummber: Yup.string().oneOf(
      [Yup.ref('cnfAccNumber'), null],
      'Account No. shoudld match'
    ),
    cnfAccNumber: Yup.string().oneOf(
      [Yup.ref('accnummber'), null],
      'Account No. shoudld match'
    ),
    ifcCode: Yup.string().required('IFC Code required'),
    beneficiaryName: Yup.string().required('Beneficiar required'),
    bankName: Yup.string().required('Bank Name required'),
    branchName: Yup.string().required('Branch Name required')
  })

  //handel form submit
  const submitHandler = val => {
    editBankInfo({
      primary_account_no: val.accnummber,
      primary_ifsc_code: val.ifcCode,
      primary_bank_name: val.bankName,
      primary_beneficiary_name: val.beneficiaryName,
      primary_branch_name: val.branchName
    })
  }

  return (
    <Page className={classes.root}>
      <Loader open={networkStatus === NETWORK_STATUS.FETCHING} />
      <Snackbar
        bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={messageToggle}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
      />
      <Box className="main-content-coll">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: 'flex',
              paddingTop: '30px',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <h3 style={{ marginBottom: '0px' }}>
              Super Clinic Financial Information
            </h3>
            <p
              style={{
                fontStyle: 'italic',
                fontSize: '85%',
                fontWeight: 'normal',
                color: colors.grey[500]
              }}>
              Click on any field to edit!
            </p>
          </Grid>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={val => {
              submitHandler(val)
            }}
            validationSchema={formValidation}>
            {props => {
              // console.log(props.dirty)
              return (
                <Grid item xs={12} sm={12}>
                  <Box
                    className="account-details"
                    style={{ marginBottom: '0px', width: '100%' }}>
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <div className="form-group">
                            {/* <p>Account No.</p>
                            <p>{bankinfo.account_no}</p> */}
                            <EditField
                              phone={false}
                              name={'accnummber'}
                              info={bankinfo.account_no}
                              lable={'Account No.'}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="form-group">
                            {/* <p>Confirm Account No.</p>
                            <p>{bankinfo.account_no}</p> */}
                            <EditField
                              phone={false}
                              name={'cnfAccNumber'}
                              info={bankinfo.account_no}
                              lable={'Confirm Account No.'}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="form-group">
                            {/* <p>IFSC Code</p>
                            <p>{bankinfo.ifsc_code}</p> */}
                            <EditField
                              phone={false}
                              name={'ifcCode'}
                              info={bankinfo.ifsc_code}
                              lable={'IFSC Code'}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="form-group">
                            {/* <p>Beneficiary Name</p>
                            <p>{bankinfo.benificiary_name}</p> */}
                            <EditField
                              phone={false}
                              name={'beneficiaryName'}
                              info={bankinfo.benificiary_name}
                              lable={'Beneficiary Name'}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="form-group">
                            {/* <p>Bank Name</p>
                            <p>{bankinfo.bank_name}</p> */}
                            <EditField
                              phone={false}
                              name={'bankName'}
                              info={bankinfo.bank_name}
                              lable={'Bank Name'}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="form-group">
                            {/* <p>Branch Name</p>
                            <p>{bankinfo.branch_name}</p> */}
                            <EditField
                              phone={false}
                              name={'branchName'}
                              info={bankinfo.branch_name}
                              lable={'Branch Name'}
                            />
                          </div>
                        </Grid>

                        {/* button enable and disable with edit */}
                        {props.dirty && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}>
                            <Button
                              variant="contained"
                              style={{
                                padding: '0 1.5rem',
                                height: '40px',
                                fontSize: '12px',
                                color: '#cd4b4b',
                                background: '#ccc',
                                marginRight: '15px'
                              }}>
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              type="submit"
                              style={{
                                padding: '0 1.5rem',
                                height: '40px',
                                fontSize: '12px'
                              }}>
                              Save
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Form>
                  </Box>
                </Grid>
              )
            }}
          </Formik>
        </Grid>
      </Box>
    </Page>
  )
}

export default BankDetails
