import React from 'react'
import Player from './Player'
import { detect } from 'detect-browser'
import swal from 'sweetalert'

const { isSupported } = require('twilio-video')
const browser = detect()

class VideoChat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      identity: localStorage.getItem('user_type'),
      roomName: props.appointment.id,
      error_msg: 'Browser not Compatible!!'
    }
  }
  componentWillUnmount() {
    var swalObject = swal.getState()
    if (swalObject.isOpen) {
      swal.close()
    }
  }
  render() {
    let render
    let token = this.props.token
    let audioStatus =
      localStorage.getItem('audio_call') === 'true' ? true : false
    if ((isSupported || browser.name === 'chromium-webview') && token) {
      render = (
        <Player
          roomName={this.state.roomName}
          token={token}
          displayNetworkText={this.props.displayNetworkText}
          endSession={this.props.endSession}
          isAudioCall={audioStatus}
          appointment={this.props.appointment}
          history={this.props.history}
        />
      )
    } else if (!isSupported) {
      render = (
        <div className="preload">
          <div className="preload-text">{this.state.error_msg}</div>
        </div>
      )
    } else {
      render = (
        <div className="preload">
          <div className="preload-text">Loading... Please Wait!!</div>
        </div>
      )
    }
    return render
  }
}

export default VideoChat
