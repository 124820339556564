import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import localforage from 'localforage'
import { PatientStore } from '../../stores'
import ProfileImg from '../../images/profile-img.png'

const PatientProfile = props => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const classes = useStyles()
  const profilePicOnly = props.profileImage
  const { setSelectedPatient } = PatientStore.useStoreActions(actions => ({
    ...actions.profile
  }))
  const { selectedPatient } = PatientStore.useStoreState(state => ({
    ...state.profile
  }))

  const [displayPic, setdisplayPic] = React.useState(null)
  useEffect(() => {
    localforage.getItem('selected_patient').then(data => {
      setSelectedPatient(data)
      // console.log(data)
      setdisplayPic(data?.photo_uri)
    })
    setHasLoaded(true)
  }, [])

  return (
    <div className={classes.root}>
      {hasLoaded == true ? (
        <div
          className={
            profilePicOnly ? classes.AvatarProfileCol : classes.avatar
          }>
          <Avatar
            className={classes.image}
            alt="Profile Image"
            src={selectedPatient?.photo_uri || ProfileImg}
          />
        </div>
      ) : (
        <div
          className={
            profilePicOnly ? classes.AvatarProfileCol : classes.avatar
          }>
          <Avatar
            className={classes.image}
            alt="Profile Image"
            src={selectedPatient?.photo_uri || ProfileImg}
          />
        </div>
      )}
      {profilePicOnly ? (
        ''
      ) : (
        <Box mt={2}>
          {props.sideNav && selectedPatient?.first_name ? (
            <Typography className={classes.name}>
              {selectedPatient?.first_name + ' ' + selectedPatient?.last_name}
            </Typography>
          ) : (
            <Typography className={classes.name}>"Loading..."</Typography>
          )}
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },

  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
      0 2px 2px rgba(0,0,0,0.12), 
      0 4px 4px rgba(0,0,0,0.12), 
      0 8px 8px rgba(0,0,0,0.12),
      0 16px 16px rgba(0,0,0,0.12)`,
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0)
    },
    marginTop: '20px'
  },
  image: {
    width: '100%',
    height: '100%',
    '& > *': {
      margin: theme.spacing(0)
    }
  },
  AvatarProfileCol: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
      0 2px 2px rgba(0,0,0,0.12), 
      0 4px 4px rgba(0,0,0,0.12), 
      0 8px 8px rgba(0,0,0,0.12),
      0 16px 16px rgba(0,0,0,0.12)`,
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0)
    }
  },
  image: {
    width: '100%',
    height: '100%',
    '& > *': {
      margin: theme.spacing(0)
    }
  },
  name: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none',
    marginBottom: '50px',
    display: 'flex',
    margin: '0 auto',
    width: 'fit-content',
    '&>h5': {
      fontSize: '12px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      textAlign: 'center'
    }
  }
}))

export default PatientProfile
