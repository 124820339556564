import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import PhoneIcon from '@material-ui/icons/Phone'
import Notification from './Notification'
import Tutorial from '../../Tutorial'
import { DoctorSteps } from '../../tutorials/DoctorSteps'
import { PatientSteps } from '../../tutorials/PatientSteps'

const NavLinks = () => {
  const classes = useStyles()
  const [openTutorial, setOpenTutorial] = React.useState(false)
  const [prevState, setPrevState] = React.useState(false)
  const tutorialSteps =
    localStorage.getItem('routing_mode') === 'doctor'
      ? DoctorSteps
      : PatientSteps
  const setTutorialOn = () => {
    if (openTutorial) {
      setPrevState(!prevState)
    } else {
      localStorage.setItem('tutorial', 'true')
      setOpenTutorial(true)
    }
  }

  return (
    <div className={classes.root}>
      <Tutorial
        open={openTutorial}
        previousState={prevState}
        steps={tutorialSteps}
      />
      <Grid container spacing={0} className={classes.navBar}>
        <Grid item>
          <Link className={classes.navLink} onClick={setTutorialOn}>
            <VideoLibraryIcon
              style={{ fontSize: '16px', color: '#ab358f', marginRight: '8px' }}
            />
            <span>Tutorials</span>
          </Link>
        </Grid>
        <Grid item>
          <Link className={classes.navLink} to="/faq">
            <ContactSupportIcon
              style={{ fontSize: '16px', color: '#ab358f', marginRight: '8px' }}
            />
            <span>FAQs</span>
          </Link>
        </Grid>
        <Grid item>
          <Link className={classes.navLink} style={{ cursor: 'default' }}>
            <PhoneIcon
              style={{ fontSize: '16px', color: '#ab358f', marginRight: '8px' }}
            />
            <span>033-418-16533</span>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.dark,
    marginLeft: '20px',
    lineHeight: '45px',
    '@media (max-width:550px)': {
      marginLeft: '0px'
    },
    '@media (max-width:1024px)': {
      lineHeight: '25px'
    }
  },
  navBar: {
    '@media (max-width:550px)': {
      justifyContent: 'center'
    }
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.primary.dark,
    fontSize: '16px',
    fontWeight: '400',
    padding: '8px 10px',
    '@media (max-width:1024px)': {
      padding: '8px 0px'
    },
    '@media (max-width:767px)': {
      padding: '8px',
      fontSize: '14px'
    },
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main
    }
  }
}))

export default NavLinks
