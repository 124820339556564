import React from 'react'
import LoginStep from './LoginStep'
import { PublicPatientStore, PatientStore } from '../../stores'

const LoginStepLayout = () => {
  return (
    <PublicPatientStore.Provider>
      <PatientStore.Provider>
        <LoginStep />
      </PatientStore.Provider>
    </PublicPatientStore.Provider>
  )
}

export default LoginStepLayout
