import React from 'react'
import { makeStyles, Grid, Box } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import PatientDetailsVitals from '../../components/PatientDetails/PatientDetailVitals'
import OnlineVideo from '../../components/OnlineConsult/OnlineVideo'
import { Layout } from '../../doctorLayouts'
import Arrow from '../../images/next-arrow.png'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: '#16171b',
    margin: 0,
    padding: 0
  },
  MainContent: {
    width: '100%',
    padding: '0',
    marginTop: '10px',
    backgroundColor: '#16171b',
    height: '100vh',
    '& .MuiGrid-item': {
      width: '100%'
    },
    '@media (max-width:991px)': {
      padding: '0px 20px;'
    },
    '@media (max-width:850px)': {
      height: 'auto'
    }
  },
  TabContent: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: 'none',
    '& .MuiTab-textColorInherit.Mui-selected': {
      backgroundColor: '#21caf1',
      color: '#fff'
    },
    '& .MuiTab-root': {
      width: '33%',
      textTransform: 'capitalize'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#fff',
      height: '0px'
    }
  },
  VideoCol: {
    '& img': {
      width: '100%'
    }
  },
  NextBtn: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
    textAlign: 'center',
    lineHeight: '55px',
    border: 'none',
    position: 'absolute',
    marginLeft: '30px',
    marginTop: '25px',
    cursor: 'pointer',
    zIndex: '999',
    '@media (max-width:767px)': {
      marginLeft: '5px !important',
      marginTop: '10px !important',
      width: '40px',
      height: '40px',
      lineHeight: '45px'
    },
    '& img': {
      height: '16px',
      width: '11px'
    }
  },
  VideoTab: {
    width: '100%',
    paddingLeft: '50px',
    position: 'relative',
    '& img': {
      width: '100%'
    },
    '@media (max-width:767px)': {
      width: '100%',
      padding: '0px'
    }
  },
  FullVideo: {
    transition: 'all .75s ease',
    flexBasis: '50%'
  },
  VideoCol: {
    width: '100%',
    maxWidth: '100%',
    flexBasis: '100%',
    '@media (max-width:991px)': {
      width: '100%',
      order: '1'
    }
  },
  TabCol: {
    backgroundColor: '#fff',
    height: '100vh',
    overflowY: 'auto',
    paddingBottom: '50px',
    '@media (max-width:991px)': {
      width: '100%',
      order: '2'
    }
  }
}))
function a11yProps() {
  //alert(index);
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`
  //   }
}
export default function TeleConsult() {
  const classes = useStyles()
  const params = useParams()
  const [value, setValue] = React.useState(0)
  const [classvalue, setClassValue] = React.useState('') //1
  const [classvalue0, setClassValue0] = React.useState('') //0
  const [classvalue2, setClassValue2] = React.useState('') //2
  const [classvalue3, setClassValue3] = React.useState('') //3
  var element
  const onClick = event => {
    if (value === 0) {
      setValue(1)
      element = document.getElementById('div2')
      setClassValue0(element.classList[0])
      setClassValue(element.classList[1])
      setClassValue2(element.classList[2])
      setClassValue3(element.classList[3])
      element.classList.remove(element.classList[1])
      document.getElementById('div1').style.display = 'block'
    } else {
      setValue(0)
      element = document.getElementById('div2')
      element.classList.remove(classvalue0)
      element.classList.remove(classvalue)
      element.classList.remove(classvalue2)
      element.classList.remove(classvalue3)
      element.classList.add(classvalue0)
      element.classList.add(classvalue)
      element.classList.add(classvalue2)
      element.classList.add(classvalue3)
      document.getElementById('div1').style.display = 'none'
    }
  }

  return (
    <Layout showLogout={false} hideNavbar={true}>
      <div className={classes.root}>
        {/* <Header /> */}
        <Box className={classes.MainContent}>
          <Grid container spacing={3}>
            <Grid
              id="div1"
              item
              md={6}
              className={classes.TabCol}
              style={{ display: 'none' }}>
              <PatientDetailsVitals
                className="patientvitals-table"
                patientId={params.patient_id}
              />
            </Grid>
            <Grid id="div2" item md={6} className={classes.VideoCol}>
              <button
                className={classes.NextBtn}
                onClick={onClick}
                {...a11yProps()}>
                <img src={Arrow} alt="" />
              </button>
              <OnlineVideo appointmentId={params.appointment_id} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </Layout>
  )
}
