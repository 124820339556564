import { action, thunk } from 'easy-peasy'
import BaseModel from '../Base'
import { BOOKING_STATUS, NETWORK_STATUS } from '../../Constants'
import api from '../../api'
import { CLINIC_USER_ID } from '../../Constants'

export default {
  ...BaseModel(),

  data: [],
  events: [],
  scheduleUpdateFailed: false,
  scheduleCreateFailed: false,
  appointment: [],
  inpersonconsultationSettings: {
    duration: 0,
    noOfPerson: 0
  },
  teleconsultationSettings: {
    duration: 0,
    noOfPerson: 0
  },
  updatedScheduleSuccess: false,
  rescheduleAppointmentSuccess: false,
  rescheduleAppointmentFailure: false,
  doctorList: [],
  totalPagesCount: 1,
  appointments: [],
  wallet: null,
  walletAdd: null,
  payoutList: [],
  earningsList: [],
  activeCount: 0,
  inactiveCount: 0,
  consultSettingUpdateStatus: 0,

  getDoctorList: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })

      const response = await api.clinic.getDoctorList({
        table_config_page_no: payload?.table_config_page_no
          ? JSON.stringify(payload?.table_config_page_no)
          : '1',
        table_config_rows_per_page: payload?.table_config_rows_per_page
          ? JSON.stringify(payload?.table_config_rows_per_page)
          : payload.filterInactive
          ? '200'
          : '10',
        pattern: payload.pattern ? payload?.pattern : ''
      })
      if (response?.status === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        let list = payload.filterInactive
          ? response?.data?.response?.filter(item => item.status == '2')
          : response?.data?.response
        actions.setDoctorList(list)
        actions.activeDoctors(response?.data?.active)
        actions.inactiveDoctors(response?.data?.inactive)
        actions.setTotalPagesCount(response?.data?.total_rows_count)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setDoctorList: action((state, payload) => {
    if (payload == undefined) {
    } else {
      state.doctorList = payload
    }
  }),

  activeDoctors: action((state, payload) => {
    state.activeCount = payload
  }),
  inactiveDoctors: action((state, payload) => {
    state.inactiveCount = payload
  }),

  setTotalPagesCount: action((state, payload) => {
    if (payload) {
      state.totalPagesCount = payload
    }
  }),

  updateDoctorStatus: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })

      const response = await api.clinic.updateDoctorStatus({
        doctor_id: payload?.doctor_id,
        status: payload?.status
      })
      if (response) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return response.data
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        return false
      }
    }
  ),

  billingPermission: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.clinic.billingPermission({
      clinic_id: localStorage.getItem('clinic_user_id'),
      doctor_id: payload?.doctor_id
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.billingupdate(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  billingupdate: action((state, payload) => {
    state.billingUpdateStatus = payload
  }),

  fetchSchedule: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })
      const { schedulerView, doctor_id } = payload
      let list_type = schedulerView === 'true' ? 'calendar' : 'appointments'
      const response = await api.doctor.schedulesFetch({
        doctor_id: localStorage.getItem('clinic_doctor_id'),
        list_type: list_type,
        clinic_doc_assoc_id: payload?.docAssociationId
      })

      if (response?.data?.statusCode === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

        actions.setResourceData(response.data.categories)
        if (list_type === 'calendar') {
          actions.setSchedulerData(response.data.events)
        } else {
          actions.setAppointmentData(response.data.events)
        }
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setSchedulerData: action((state, payload) => {
    state.events = payload
  }),

  setAppointmentData: action((state, payload) => {
    state.appointment = payload
  }),

  setResourceData: action((state, payload) => {
    state.data = payload
  }),

  setAppointmentData: action((state, payload) => {
    state.appointment = payload
  }),

  createSchedule: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })

      const response = await api.doctor.schedulesAdd({
        doctor_id: localStorage.getItem('clinic_doctor_id'),
        calendar_specific_event_id: payload[0].id,
        ...payload[0]
      })

      if (response?.data?.statusCode === 200) {
        actions.fetchSchedule({ schedulerView: 'true' })
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.setScheduleCreateFailure(true)
        actions.fetchSchedule({ schedulerView: 'true' })
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  updateSchedule: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })
      const response = await api.doctor.schedulesUpdate({
        doctor_id: localStorage.getItem('clinic_doctor_id'),
        ...payload[0]
      })

      if (response?.data?.statusCode === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return true
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        actions.setScheduleUpdateFailure(true)
        actions.fetchSchedule({ schedulerView: 'true' })
      }
    }
  ),

  deleteSchedule: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })
      const response = await api.doctor.schedulesDelete({
        doctor_id: localStorage.getItem('clinic_doctor_id'),
        ...payload[0]
      })

      if (response?.data?.statusCode === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.setScheduleUpdateFailure(true)
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        actions.fetchSchedule({ schedulerView: 'true' })
      }
    }
  ),
  setScheduleUpdateFailure: action((state, payload) => {
    state.scheduleUpdateFailed = payload
  }),
  setScheduleCreateFailure: action((state, payload) => {
    state.scheduleCreateFailed = payload
  }),

  getConsultationSettings: thunk(
    async (actions, payload, { getStoreState }) => {
      const { visitType, appointmentType, doctor_id } = payload
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })

      const response = await api.doctor.getConsultationSettings({
        doctor_id: localStorage.getItem('clinic_doctor_id'),
        visit_type: visitType,
        appointment_type: appointmentType,
        clinic_doc_assoc_id: payload?.docAssociationId
      })

      if (response?.data?.consultation_settings) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        if (response?.data?.consultation_settings?.appointment_type === '1') {
          actions.setInpersonConsultationSettings({
            duration: response.data.consultation_settings.length,
            noOfPerson:
              response.data.consultation_settings.consultations_per_hour
          })
        } else {
          actions.setTeleConsultationSettings({
            duration: response.data.consultation_settings.length,
            noOfPerson:
              response.data.consultation_settings.consultations_per_hour
          })
        }
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setInpersonConsultationSettings: action((state, payload) => {
    state.inpersonconsultationSettings = payload
  }),
  setTeleConsultationSettings: action((state, payload) => {
    state.teleconsultationSettings = payload
  }),

  updateConsultationSettings: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      const {
        visitType,
        appointmentType,
        visitLength,
        consultPerHour,
        doctor_id
      } = payload
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      const response = await api.doctor.updateConsultationSettings({
        doctor_id: localStorage.getItem('clinic_doctor_id'),
        visit_type: visitType,
        appointment_type: appointmentType,
        visit_length: visitLength,
        consultations_per_hour: consultPerHour
      })

      if (response.ok) {
        actions.getConsultationSettings({
          visitType: visitType,
          appointmentType: appointmentType,
          doctor_id: localStorage.getItem('clinic_doctor_id')
        })
        actions.setUpdatedSchedule(response.ok)
        actions.setConsultSettingUpdateStatus(response?.data?.statusCode)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setUpdatedSchedule: action((state, payload) => {
    state.updatedScheduleSuccess = payload
  }),

  setConsultSettingUpdateStatus: action((state, payload) => {
    state.consultSettingUpdateStatus = payload
  }),

  rescheduleAppointment: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      const {
        appointment_id,
        appointment_date,
        appointment_start_time,
        appointment_end_time,
        calendar_schedule_id,
        doctor_id
      } = payload
      let appointment_type =
        payload.category_id === 'eab4da05-c870-4a5e-b4e3-25fc0ece7d15'
          ? '1'
          : '2'
      // const availablityResponse = await api.getDoctorScheduleSlots({
      //   doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      //   appointment_type:
      //     payload.category_id === 'eab4da05-c870-4a5e-b4e3-25fc0ece7d15'
      //       ? '1'
      //       : '2',
      //   appointment_date: appointment_date,
      //   visit_type: '1'
      // })
      // if (availablityResponse?.data) {
      //   let slotAvailable = availablityResponse?.data?.slot_data?.find(item => {
      //     let startingTime = moment(
      //       item.appointment_start_time,
      //       'YYYY-MM-DD hh:mm:ss'
      //     ).format('hh:mm:ss')
      //     let endingTime = moment(
      //       item.appointment_end_time,
      //       'YYYY-MM-DD hh:mm:ss'
      //     ).format('hh:mm:ss')
      //     if (
      //       startingTime === appointment_start_time &&
      //       endingTime === appointment_end_time
      //     ) {
      //       return item
      //     }
      //   })
      //   if (slotAvailable) {
      const response = await api.doctor.rescheduleAppointment({
        doctor_id: localStorage.getItem('clinic_doctor_id'),
        appointment_id: appointment_id,
        appointment_date: appointment_date,
        appointment_start_time: appointment_start_time,
        appointment_end_time: appointment_end_time,
        appointment_type: appointment_type
      })

      if (response.data.statusCode === 201) {
        actions.setRescheduleResponse(true)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setRescheduleResponse(null)
        return true
      } else {
        actions.setRescheduleResponse(false)
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        return false
      }
    }
  ),
  setRescheduleResponse: action((state, payload) => {
    if (payload || payload == null) {
      state.rescheduleAppointmentSuccess = payload
    } else {
      state.rescheduleAppointmentFailure = true
    }
  }),
  getList: thunk(async (actions, payload, { getStoreState }) => {
    actions.setAppointments([])
    // actions.setTotalPages(0)
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    if (payload.isForFollowup) {
      api.setAuthHeaders({
        authorization: localStorage.getItem('clinic_booking_doctor_id')
      })
    }

    const {
      list_type,
      status,
      visit_type,
      appointment_type,
      sorting,
      currentPage,
      searchString,
      doctor_id
    } = payload

    const response = await api.doctor.getAppointmentList({
      user_id: localStorage.getItem('clinic_booking_doctor_id'),
      doctor_id: doctor_id,
      status_list:
        status !== BOOKING_STATUS.ALL.value
          ? [status]
          : list_type === 'past' || list_type === 'all'
          ? ['2', '3', '4', '7']
          : ['2', '3', '4'],
      visit_type: visit_type ? visit_type : 0,
      table_config_rows_per_page: '20',
      table_config_page_no: currentPage ? currentPage : '1',
      list_type: list_type,
      appointment_type: appointment_type,
      pattern: searchString ? searchString : '',
      sorting: sorting ? sorting : ''
    })
    // sorting:{
    //   created_at: 'ASC',
    //   start_time: 'ASC',
    //   appointment_date: 'ASC'
    // }
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      if (response?.data?.appointments) {
        actions.setAppointments(response?.data?.appointments)
        // actions.setTotalPages(response?.data?.total_pages_count)
      }
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setAppointments: action((state, payload) => {
    state.appointments = payload
  }),

  getWalletDetails: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      // const {
      //   visitType,
      //   appointmentType,
      //   visitLength,
      //   consultPerHour,
      //   doctor_id
      // } = payload
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      const response = await api.clinic.clinicWalletView({
        clinic_id: localStorage.getItem('clinic_user_id')
      })

      if (response.ok) {
        actions.setWallet(response.data)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setWallet: action((state, payload) => {
    state.wallet = payload
  }),
  clinicWalletRequestAdd: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      Authorization: localStorage.getItem('clinic_auth_key')
    })
    const response = await api.doctor.clinicWalletRequestAdd({
      clinic_id: localStorage.getItem('clinic_user_id'),
      amount_requested: payload ? payload : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

      actions.clinicWalletRequestAddResponse(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  resetRequestAdddResponse: action((state, payload) => {
    state.walletAdd = null
  }),

  clinicWalletRequestAddResponse: action((state, payload) => {
    state.walletAdd = payload
  }),

  getPayoutList: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      let f_date = new Date(payload.start_date)
      let e_date = new Date(payload.end_date)

      let start_date = JSON.stringify(f_date)
      start_date = start_date.slice(1, 11)

      let end_date = JSON.stringify(e_date)
      end_date = end_date.slice(1, 11)

      const { page_no, rows_per_page } = payload
      const response = await api.clinic.getPayoutList({
        clinic_id: localStorage.getItem('clinic_user_id'),
        start_date,
        end_date,
        table_config_page_no: page_no,
        table_config_rows_per_page: rows_per_page
      })

      if (response.ok) {
        actions.setPayoutList(response?.data?.response)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        actions.setPayoutList([])
      }
    }
  ),

  setPayoutList: action((state, payload) => {
    state.payoutList = payload
  }),

  getClinicEarnings: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      let f_date = new Date(payload.start_date)
      let e_date = new Date(payload.end_date)

      let start_date = JSON.stringify(f_date)
      start_date = start_date.slice(1, 11)

      let end_date = JSON.stringify(e_date)
      end_date = end_date.slice(1, 11)

      const { page_no, rows_per_page } = payload
      const response = await api.clinic.getClinicEarnings({
        clinic_id: localStorage.getItem('clinic_user_id'),
        start_date,
        end_date,
        table_config_page_no: page_no,
        table_config_rows_per_page: rows_per_page
      })

      if (response.ok) {
        actions.setEarningsList(response?.data?.response)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        actions.setEarningsList([])
      }
    }
  ),

  setEarningsList: action((state, payload) => {
    state.earningsList = payload
  }),

  clinicWalletRequestList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    api.setAuthHeaders({
      Authorization: localStorage.getItem('clinic_auth_key')
    })

    const response = await api.doctor.clinicWalletRequestList({
      clinic_id: localStorage.getItem('clinic_user_id'),
      table_config_rows_per_page: '10',
      table_config_page_no: payload?.currentPage
        ? String(payload?.currentPage)
        : '1',
      order_by: 'created_at',
      order: 'desc'
    })

    if (response?.data?.statusCode === 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setWalletRequestList(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setWalletRequestList: action((state, payload) => {
    state.walletRequestList = payload
  }),

  clinicWalletRequestUpdate: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.doctor.clinicWalletRequestUpdate({
      id: payload?.id ? payload?.id : '',
      amount_requested: payload?.amount ? payload?.amount : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.clinicWalletUpdateresponse(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  clinicWalletUpdateresponse: action((state, payload) => {
    state.walletUpdate = payload
  }),

  clinicWalletRequestCancel: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.doctor.clinicWalletRequestCancel({
      id: payload?.id ? payload?.id : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

      actions.clinicWalletCancelresponse(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  clinicWalletCancelresponse: action((state, payload) => {
    state.walletCancel = payload
  }),

  snackClear: action((state, payload) => {
    state.walletAdd = undefined
    state.walletUpdate = undefined
    state.walletCancel = undefined
    state.profileUpdateStatus = undefined
    // state.docbankdetailsAddedStatus = undefined
  }),

  SubscriptionHistory: thunk(async (action, payload) => {
    // api.setAuthHeaders({
    //   Authorization: localStorage.getItem('clinic_auth_key')
    // })
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.clinic.subscriptionhistory({
      clinic_id: localStorage.getItem('clinic_user_id'),
      pattern: payload.pattern ? payload?.pattern : '',
      table_config_page_no: JSON.stringify(payload?.table_config_page_no),
      table_config_rows_per_page: JSON.stringify(
        payload?.table_config_rows_per_page
      )
    })
    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.subscriptionhistoryList(response?.data)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  subscriptionhistoryList: action((state, payload) => {
    state.subscriptionhistoryList = payload
  })
}
