import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  makeStyles,
  useTheme,
  Button
} from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import copy from 'copy-to-clipboard'

import ContainedButton from '../../components/Button'
import { SuperClinicStore } from '../../../stores'

const CopyPasswordDialog = ({
  open = false,
  onClose = () => {},
  email = '',
  password = ''
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const { setMessageFromBackend } = SuperClinicStore.useStoreActions(
    actions => ({
      ...actions.clinicListDetails
    })
  )

  const [copiedToClipBoard, setCopiedToClipBoard] = useState(false)

  return (
    <Dialog open={open}>
      <DialogTitle>Login Credentials</DialogTitle>
      <DialogContent>
        <div className={classes.fieldViewer}>
          <span>Email:</span>
          <span>{email}</span>
        </div>
        <div
          className={[classes.fieldViewer, classes.passwordField].join(' ')}
          style={{
            borderColor: copiedToClipBoard
              ? theme.palette.success.main
              : theme.palette.primary.main,
            color: copiedToClipBoard
              ? theme.palette.success.main
              : theme.palette.primary.main
          }}>
          <span>Password:</span>
          <span>{password}</span>
          <span>
            <Tooltip
              placement="top"
              title={copiedToClipBoard ? 'Copied' : 'Copy to Clipboard'}>
              <Button
                onClick={() => {
                  if (copy(password)) {
                    setCopiedToClipBoard(true)
                    setMessageFromBackend({
                      message: 'Copied to Clipboard!',
                      success: true
                    })
                  } else {
                    setMessageFromBackend({
                      message: "Sorry! Couldn't copy to the clipboard!!!"
                    })
                  }
                }}
                variant="contained"
                color="primary"
                style={{
                  height: '100%',
                  display: 'block',
                  borderRadius: 0,
                  backgroundColor: copiedToClipBoard
                    ? theme.palette.success.main
                    : theme.palette.primary.main
                }}
                classes={{
                  label: classes.buttonLabel
                }}>
                {copiedToClipBoard ? (
                  <AssignmentTurnedInIcon className={classes.icon} />
                ) : (
                  <AssignmentIcon className={classes.icon} />
                )}
              </Button>
            </Tooltip>
          </span>
        </div>
        <div
          className={classes.pleaseCopyThePassword}
          style={{
            color: copiedToClipBoard
              ? theme.palette.success.main
              : theme.palette.error.main
          }}>
          {copiedToClipBoard
            ? 'Password copied To Clipboard!'
            : 'Please copy the password!'}
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '0 1.2rem 1rem 0' }}>
        <ContainedButton
          variant="contained"
          onClick={onClose}
          style={{ padding: '0 2rem', height: '2.5rem' }}>
          Done
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )
}

export default CopyPasswordDialog

const useStyles = makeStyles(theme => ({
  fieldViewer: {
    display: 'grid',
    gridTemplateColumns: '7rem minmax(max-content, 1fr)',
    padding: '0 1rem',
    border: `2px solid transparent`,
    lineHeight: 1,
    '& *': {
      display: 'block'
    }
  },
  passwordField: {
    padding: '0.5rem 1rem',
    gridTemplateColumns: '7rem minmax(max-content, 1fr) min-content',
    alignItems: 'center',
    gridAutoRows: 'min-content',
    border: `2px dashed ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    margin: '0.5rem 0 0.3rem 0',
    position: 'relative'
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    color: 'currentColor !important'
  },
  pleaseCopyThePassword: {
    color: theme.palette.error.main,
    fontSize: '80%',
    margin: '0 0 0.3rem 1.125rem'
  }
}))
