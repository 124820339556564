import React from 'react'

export const PatientSteps = [
  {
    title: 'Welcome to CareNowDoc',
    target: 'body',
    placement: 'center',
    content: (
      <div>
        <h4>The simple teleconsultation platform</h4>
        <p>Hope you enjoy this platform to the fullest designed by Carenow</p>
      </div>
    ),
    disableBeacon: true
  },
  {
    title: 'This is the menu',
    target: '.menu-view',
    content: 'Look, this is your menu',
    placement: 'right-start'
  },
  {
    title: 'This is the dashboard menu',
    target: '.dashboard-menu',
    content: 'Look, this is your dashboard menu',
    placement: 'right-start'
  },
  {
    title: 'This is the vitals menu',
    target: '.vitals-menu',
    content: 'Look, this is your vitals menu',
    placement: 'right-start'
  },
  {
    title: 'This is the medications menu',
    target: '.medications-menu',
    content: 'Look, this is your medications menu',
    placement: 'right-start'
  },
  {
    title: 'This is the appointments menu',
    target: '.appointments-menu',
    content: 'Look, this is your appointments menu',
    placement: 'right-start'
  },
  {
    title: 'This is the documents menu',
    target: '.documents-menu',
    content: 'Look, this is your documents menu',
    placement: 'right-start'
  },
  {
    title: 'This is the profile update menu',
    target: '.profile-update-menu',
    content: 'Look, this is your profile update menu',
    placement: 'right-start'
  }
]
