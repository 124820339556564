import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Box,
  Grid,
  Button,
  Backdrop,
  TextField,
  Fade
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Snackbar from '@material-ui/core/Snackbar'
import { DoctorStore } from '../../stores'
import { useParams } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const MAX_NUMBER = 999
const AddVitalsModal = ({ showHideModal, isShow, pateintId }) => {
  const params = useParams()
  const classes = useStyles()
  const numRegex = /^\d+$/
  const numRegexDecimal = /^(?=.*[1-9])\d*(?:\.\d{1,2})?$/

  const { addPatientVitals } = DoctorStore.useStoreActions(actions => ({
    ...actions.patients
  }))
  const [bmi_index, setBmi] = useState('NA')
  const [snack, setSnack] = useState(false)

  const addPatientApiCall = payload => {
    addPatientVitals(payload)
    showHideModal()
    setSnack(true)
  }

  const validationSchema = yup.object().shape({
    temperature: yup
      .number()
      .required('Temperature is required')
      .positive('Temperature must be greater than 0')
      .max(115, 'Temperature cannot be greater than 115 degrees'),
    height: yup
      .number()
      .required('Height is required')
      .positive('Height must be greater than 0')
      .max(250, 'Height cannot be greater than 250cm'),
    weight: yup
      .number()
      .required('Weight is required')
      .positive('Weight must be greater than 0')
      .max(635, 'Weight cannot be greater than 635 kg'),
    sbp: yup
      .number()
      .required('SBP is required')
      .positive('SBP must be greater than 0')
      .max(180, 'SBP cannot be greater than 180 mmHg'),
    dbp: yup
      .number()
      .required('DBP is required')
      .positive('DBP must be greater than 0')
      .max(110, 'DBP cannot be greater than 110 mmHg'),
    heart_rate: yup
      .number()
      .required('Heart Rate is required')
      .positive('Heart rate must be greater than 0')
      .max(200, 'Heart Rate cannot be greater than 200 bpm'),
    respiratory_rate: yup
      .number()
      .required('Respiratory Rate is required')
      .positive('Respiratory Rate must be greater than 0')
      .max(60, 'Respiratory Rate cannot be greater than 60'),
    oxygen_saturation_rate: yup
      .number()
      .required('Oxy. Saturation Rate is required')
      .positive('Oxy. Saturation Rate must be greater than 0')
      .max(100, 'Oxy. Saturation Rate cannot be greater than 100')
  })

  const submit = values => {
    let payload = {
      patient_id: pateintId,
      temperature: values.temperature.toString(),
      bmi_index: bmi_index,
      sbp: values.sbp.toString(),
      dbp: values.dbp.toString(),
      heart_rate: values.heart_rate.toString(),
      respiratory_rate: values.respiratory_rate.toString(),
      oxygen_saturation_rate: values.oxygen_saturation_rate.toString(),
      height: values.height.toString(),
      weight: values.weight.toString(),
      appointment_id: params.appointment_id
    }

    addPatientApiCall(payload)
  }

  const formik = useFormik({
    initialValues: {
      temperature: '',
      height: '',
      weight: '',
      sbp: '',
      dbp: '',
      heart_rate: '',
      respiratory_rate: '',
      oxygen_saturation_rate: '',
      bmi_index: ''
    },
    validationSchema,
    onSubmit: submit
  })

  useEffect(() => {
    calcBMI()
  }, [formik.values.height, formik.values.weight])

  const calcBMI = () => {
    if (formik.values.height > 0 && formik.values.weight > 0) {
      let ht = formik.values.height / 100
      let bmi = formik.values.weight / (ht * ht)
      setBmi(bmi.toFixed(2))
    } else {
      setBmi(0)
    }
  }

  const handleClose = () => {
    setSnack(false)
  }

  const showSnackbar = () => {}

  return (
    <div>
      <Snackbar
        open={snack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'bottom' }}>
        <Alert onClose={handleClose} severity="success">
          Vitals updated successfully!
        </Alert>
      </Snackbar>
      <Dialog
        open={isShow}
        onClose={showHideModal}
        aria-labelledby="form-dialog-title"
        className={classes.ModalHead}>
        <h3 style={{ fontSize: 18, fontWeight: 500, color: '#000' }}>
          Add Vitals
        </h3>
        {/* <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          {' '}
          Add Vitals
        </DialogTitle> */}
        <DialogContent>
          <Box className={classes.ModalForm}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <TextField
                    id="temperature"
                    type="number"
                    placeholder="Temperature"
                    onChange={formik.handleChange}
                    value={formik && formik.values && formik.values.temperature}
                    onBlur={formik.handleBlur}
                    name="temperature"
                    label="Temperature (in F) *:"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.temperature && formik.errors.temperature
                          ? formik.errors.temperature
                          : null}
                      </div>
                    }
                    InputProps={{ inputProps: { min: 0, max: 200 } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <TextField
                    id="height"
                    // style={{ width: "100%" }}
                    type="number"
                    placeholder="Height"
                    onChange={formik.handleChange}
                    value={formik && formik.values && formik.values.height}
                    onBlur={formik.handleBlur}
                    name="height"
                    label="Height (in cm): *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.height && formik.errors.height
                          ? formik.errors.height
                          : null}
                      </div>
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <TextField
                    id="weight"
                    // style={{ width: "100%" }}
                    type="number"
                    placeholder="Weight"
                    onChange={formik.handleChange}
                    value={formik && formik.values && formik.values.weight}
                    onBlur={formik.handleBlur}
                    name="weight"
                    label="Weight (in kg): *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.weight && formik.errors.weight
                          ? formik.errors.weight
                          : null}
                      </div>
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <p style={{ marginTop: '0' }}>BMI index: </p>
                  <label id="bmi_index" className={classes.Input}>
                    {bmi_index}
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <TextField
                    id="sbp"
                    // style={{ width: "100%" }}
                    type="number"
                    placeholder="SBP"
                    onChange={formik.handleChange}
                    value={formik && formik.sbp && formik.values.sbp}
                    onBlur={formik.handleBlur}
                    name="sbp"
                    label="SBP: *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.sbp && formik.errors.sbp
                          ? formik.errors.sbp
                          : null}
                      </div>
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <TextField
                    id="dbp"
                    // style={{ width: "100%" }}
                    type="number"
                    placeholder="DBP"
                    onChange={formik.handleChange}
                    value={formik && formik.dbp && formik.values.dbp}
                    onBlur={formik.handleBlur}
                    name="dbp"
                    label="DBP: *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.dbp && formik.errors.dbp
                          ? formik.errors.dbp
                          : null}
                      </div>
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <TextField
                    id="heart_rate"
                    // style={{ width: "100%" }}
                    type="number"
                    placeholder="Heart Rate"
                    onChange={formik.handleChange}
                    value={
                      formik && formik.heart_rate && formik.values.heart_rate
                    }
                    onBlur={formik.handleBlur}
                    name="heart_rate"
                    label="Heart Rate: *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.heart_rate && formik.errors.heart_rate
                          ? formik.errors.heart_rate
                          : null}
                      </div>
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.InputCol}>
                  <TextField
                    id="respiratory_rate"
                    // style={{ width: "100%" }}
                    type="number"
                    placeholder="Respiratory Rate"
                    onChange={formik.handleChange}
                    value={
                      formik &&
                      formik.respiratory_rate &&
                      formik.values.respiratory_rate
                    }
                    onBlur={formik.handleBlur}
                    name="respiratory_rate"
                    label="Respiratory Rate: *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.respiratory_rate &&
                        formik.errors.respiratory_rate
                          ? formik.errors.respiratory_rate
                          : null}
                      </div>
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box className={classes.InputCol}>
                  <TextField
                    id="oxygen_saturation_rate"
                    // style={{ width: "100%" }}
                    type="number"
                    placeholder="Oxygen Saturation Rate"
                    onChange={formik.handleChange}
                    value={
                      formik &&
                      formik.oxygen_saturation_rate &&
                      formik.values.oxygen_saturation_rate
                    }
                    onBlur={formik.handleBlur}
                    name="oxygen_saturation_rate"
                    label="Oxygen Saturation Rate: *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.oxygen_saturation_rate &&
                        formik.errors.oxygen_saturation_rate
                          ? formik.errors.oxygen_saturation_rate
                          : null}
                      </div>
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: '10px 40px 20px' }}>
          <Button
            onClick={showHideModal}
            className={classes.modalSecondaryButton}>
            Cancel
          </Button>
          <Button
            onClick={formik.handleSubmit}
            className={classes.modalPrimaryButton}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
const useStyles = makeStyles(() => ({
  ModalBox: {
    width: '550px',
    background: '#fff',
    padding: '0px',
    marginBottom: '0px auto',
    '@media (max-width:767px)': {
      width: '100%'
    },
    // height:'400px',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      color: '#fff',
      background: '#000',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  ModalForm: {
    width: '100%',
    '@media (max-width:767px)': {
      height: '400px'
      // overflowY: 'auto'
    }
  },
  DownloadBtn: {
    width: '120px',
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
    height: '40px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '40px',
    fontSize: '14px',
    textTransform: 'capitalize',
    fontWeight: '500',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  Input: {
    width: '100%'
  },
  InputCol: {
    width: '95%',
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'center',
    marginLeft: '15px',
    textAlign: 'left',
    flexDirection: 'column',
    '& p': {
      fontSize: '14px',
      fontWeight: '400',
      marginRight: '10px',
      marginBottom: '0px'
    }
  },
  modalPrimaryButton: {
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
    padding: '0 25px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: 14,
    height: '40px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111d4e'
    }
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '0 25px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: 14,
    height: '40px',
    color: '#000',
    '&:hover': {
      backgroundColor: '#e4e4e4'
    }
  },
  ModalHead: {
    width: '500px',
    margin: '0px auto',
    fontFamily: 'Poppins',
    '& .MuiDialogContent-root': {
      overflowX: 'hidden'
    },
    '@media (max-width:767px)': {
      width: '100%'
    },
    '& MuiDialogActions-root': {
      padding: '20px !important'
    },
    '& .MuiDialog-scrollPaper': {
      width: '100%'
    },
    '& h3': {
      padding: '20px 24px 10px',
      fontSize: '16px',
      marginBottom: '0px',
      borderBottom: '1px #ccc solid',
      fontWeight: '500'
    }
  },
  errMsg: {
    color: 'red',
    fontSize: '12px',
    position: 'absolute'
  }
}))

export default AddVitalsModal
