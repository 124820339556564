import { action, thunk } from 'easy-peasy'
import BaseModel from '../Base'
import { NETWORK_STATUS } from '../../Constants'
import api from '../../api'

export default {
  ...BaseModel(),

  username: null,
  userId: null,
  email: null,
  image: null,
  name: null,
  specialisation: null,
  degree: null,
  experience: null,
  availability: [],
  description: null,
  hospitalMasterId: null,
  associated_with: null,
  hospitalShortcode: null,
  userShortCode: null,
  qualification: null,
  consultationFees: [],
  bio: null,
  mobile_no: null,
  prefix: null,
  picture: null,
  noDoctorFound: false,
  clinic_name: null,
  clinic_address: null,
  clinic_logo: null,

  fetchDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const { shortCode } = payload

    const response = await api.getUserDetails({
      user_short_code: shortCode
    })
    if (response.data.statusCode === 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.fetchedDetails(response.data.user_details)
      if(response.data.user_details.clinic_association_id){
        localStorage.setItem('clinic_association_id' , JSON.stringify(response.data.user_details?.clinic_association_id[0].clinic_doctor_associate))
      }else if(response.data.user_details.sub_clinics){
        localStorage.removeItem('clinic_association_id')
      }
      localStorage.setItem(
        'doctor_id',
        response?.data?.user_details?.user_master_id
      )
      actions.setNoDoctorFound(false)
    } else {
      actions.setNoDoctorFound(true)
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setNoDoctorFound: action((state, payload) => {
    state.noDoctorFound = payload
  }),

  fetchedDetails: action((state, payload) => {
    if (payload) {
      state.username = payload.username
      state.userId = payload.id
      state.email = payload.email
      state.name = payload.name
      state.associated_with = payload.associated_with
      state.hospitalShortcode = payload.hospital_shortcode
      state.userShortCode = payload.user_short_code
      state.consultationFees = payload.fees
      state.image = payload.picture
      state.description = payload.bio
      state.experience = payload.experience
      state.specialization = payload.specialization
      state.degree = payload.qualification
      state.user_short_code = payload.user_short_code
      state.bio = payload.bio
      state.mobile_no = payload.mobile_no
      state.prefix = payload.prefix
      state.picture = payload.picture
      state.clinic_name = payload.clinic_name
      state.clinic_address = payload.clinic_address
      state.clinic_logo = payload.clinic_logo
      state.sub_clinics = payload.sub_clinics ? payload.sub_clinics : ""
    }
  }),

  doctorDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      authorization: '3478513a548d82b32d4aaadb04e44028b7ea3aabaaa2d3145ae736ef'
    })
    const response = await api.hospital.createDoctorDetails({
      name: payload?.name,
      user_name: payload?.username,
      email: payload?.email.toLowerCase(),
      user_short_code: payload?.user_short_code,
      picture_name:
        payload && payload.format && payload.format[0] ? payload.format[0] : '',
      bio: payload?.bio,
      experience: payload?.experience,
      picture:
        payload && payload.format && payload.format[1] ? payload.format[1] : '',
      specialization: payload?.specialization,
      qualification: payload?.qualification,
      mobile_no: payload?.mobile_no,
      is_new_user: 'true',
      department_id: '5ce4398c-a908-47a8-b624-da80711cc59b',
      role_id: '52c81c76-5236-4085-adfb-e8b4c7e4ba86',
      // subscription_master_id: "2",
      document:
        payload && payload.docformat && payload.docformat[1]
          ? payload.docformat[1]
          : '',
      document_name:
        payload && payload.docformat && payload.docformat[0]
          ? payload.docformat[0]
          : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDocdetailsAddedStatus(response.data)
      localStorage.setItem('user_master_id', response.data.user_master_id)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDocdetailsAddedStatus: action((state, payload) => {
    state.docdetailAddedStatus = payload
  }),

  doctorBankDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      authorization: '3478513a548d82b32d4aaadb04e44028b7ea3aabaaa2d3145ae736ef'
    })
    const response = await api.addDoctorBankDetails({
      user_id: 'b3f82a31-dc0a-4a87-a808-838b1aa0d2f0',
      user_master_id: '274f820c-f74c-453c-8029-e0c3f30054ec',
      primary_account_no: payload?.account_no,
      primary_branch_name: payload?.branch_name,
      primary_bank_name: payload?.bank_name,
      primary_ifsc_code: payload?.ifsc_code,
      primary_beneficiary_name: payload?.beneficiary_name
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDocbankdetailsAddedStatus(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDocbankdetailsAddedStatus: action((state, payload) => {
    state.docbankdetailsAddedStatus = payload
  }),

  subscriptionPlanList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.getSubscriptionList({
      subscription: payload?.subscription_plan
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getSubscriptionPlanList(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getSubscriptionPlanList: action((state, payload) => {
    state.getSubscriptionList = payload
  }),

  addSubscriptionChoice: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.subscriptionChoice({
      user_master_id: '274f820c-f74c-453c-8029-e0c3f30054ec',
      subscription_id: payload?.subscription_id
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.subscriptionChoiceAdded(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  subscriptionChoiceAdded: action((state, payload) => {
    state.subscriptionChoiceStatus = payload
  })
}
