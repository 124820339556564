import React, { useState, useEffect, useRef } from 'react'
import Toolbar from './Toolbar'

const Participant = ({
  room,
  participant,
  muteVideo,
  muteAudio,
  screenShare,
  remote,
  endSession,
  isAudioCall,
  ...props
}) => {
  const [videoTracks, setVideoTracks] = useState([])
  const [audioTracks, setAudioTracks] = useState([])

  const videoRef = useRef()
  const audioRef = useRef()
  const remoteUserClass = remote ? 'local' : 'participant'
  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null)

  //participant added and removal which triggers when participant comes in or leaves
  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks))
    setAudioTracks(trackpubsToTracks(participant.audioTracks))

    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track])
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => [...audioTracks, track])
      }
    }

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track))
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track))
      }
    }

    participant.on('trackSubscribed', trackSubscribed)
    participant.on('trackUnsubscribed', trackUnsubscribed)

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [participant])

  //used for attaching the video track to video element reference
  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      return () => {
        videoTrack.detach()
      }
    }
  }, [videoTracks])

  //used for attaching the audio track to audio element reference
  useEffect(() => {
    const audioTrack = audioTracks[0]
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
      return () => {
        audioTrack.detach()
      }
    }
  }, [audioTracks])

  var participantText = ''
  if (
    localStorage.getItem('user_type') == 'doctor' &&
    props.participantStatus !== 'Connected'
  ) {
    participantText = props.participantStatus
  } else if (
    !props.participantAudioTrackStatus &&
    !props.participantVideoTrackStatus
  ) {
    participantText = 'Your Participant has muted the audio and video'
  } else if (!props.participantVideoTrackStatus) {
    participantText = 'Your Participant has muted the video'
  } else if (!props.participantAudioTrackStatus) {
    participantText = 'Your Participant has muted the audio'
  }

  return (
    <div>
      <div className={remoteUserClass}>
        <video ref={videoRef} autoPlay={true} />
        <audio ref={audioRef} autoPlay={true} />
      </div>
      {!remote ? (
        <>
          <div className="participant-status">{participantText}</div>
          <Toolbar
            muteVideo={muteVideo}
            muteAudio={muteAudio}
            endSession={endSession}
            screenShare={screenShare}
            isAudioCall={isAudioCall}
            {...props}
          />
        </>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default Participant
