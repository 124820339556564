import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  // Link,
  Typography,
  // IconButton,
  Button,
  makeStyles
} from '@material-ui/core'
import {
  // IoIosArrowForward,
  IoIosArrowRoundUp,
  IoIosArrowRoundDown
} from 'react-icons/io'
import moment from 'moment'
import { BeatLoader } from 'react-spinners'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Chart } from 'react-google-charts'
// import Card from '../CommonComponents/Card'
import AddVitalsModal from './AddVitalsModal'
import ViewVitalsHistoryModal from './ViewVitalsHistoryModal'
import NoRecordImg from '../../images/norecord.png'
import RightArrow from '../../images/right-arrow.svg'
import BloodPreser from '../../images/sphygmomanometer.svg'
import Temperature from '../../images/temperature.svg'
import Height from '../../images/height.svg'
import Oxygen from '../../images/oxygen-tank.svg'
import Weight from '../../images/weighing-scale.svg'
import Cardiogram from '../../images/cardiogram.svg'
import Walking from '../../images/step.svg'
import Energy from '../../images/caloriesicon.svg'
import Distance from '../../images/distance.svg'

const HealthParameters = [
  { param: 'STEPS', logo: Walking, unit: 'steps' },
  { param: 'CALORIES', logo: Energy, unit: 'kcal' },
  { param: 'DISTANCE', logo: Distance, unit: 'km' }
]

const PatientDetailchart = props => {
  const classes = useStyles()
  const {
    showVitalsModal,
    hideVitalsModal,
    VitalsHistoryModal,
    VitalsData,
    AddVitals,
    showVitalsHistory,
    hideVitalsHistoryModal,
    handleChange,
    value,
    params,
    alignment,
    TabPanel,
    disabled,
    chartData
  } = props

  const [gFitData, setGFitData] = useState({})

  useEffect(() => {
    const controller = new AbortController()
    ;(async () => {
      setGFitData({})
      try {
        const today = new Date().toISOString().split('T')[0]
        const results = await Promise.allSettled(
          HealthParameters.map(
            ({ param }) =>
              new Promise(async resolve => {
                try {
                  const resp = await fetch(
                    'https://luzvk76flk.execute-api.ap-south-1.amazonaws.com/uat/sync-controller',
                    {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({
                        action: 'HEALTHHUB_SYNC',
                        carenowdoc_id: params.patient_id,
                        fit_param: param,
                        start_date: today,
                        end_date: today
                      }),
                      signal: controller.signal
                    }
                  )
                  const respJSON = await resp.json()
                  if (respJSON?.statusCode?.toString().startsWith('2')) {
                    const value = respJSON.response?.[0]?.fitness_value
                    if (param === 'DISTANCE')
                      resolve([param, (value / 1000).toFixed(2)])
                    else resolve([param, Math.round(value).toString()])
                  } else
                    throw new Error(
                      respJSON?.message ?? 'Error in Fetching Scores'
                    )
                } catch (err) {
                  resolve([param, undefined])
                }
              })
          )
        )
        setGFitData(Object.fromEntries(results.map(result => result.value)))
      } catch (err) {
        console.error('Failed to fetch activity data from HealthHub', err)
      }
    })()
    return () => {
      controller.abort()
    }
  }, [params.patient_id])

  return (
    <React.Fragment>
      <TabPanel
        value={value}
        index={0}
        style={{ background: '#fff', borderRadius: 15 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box
              className={classes.vitalTop}
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3 style={{ fontSize: 20, fontWeight: 500, color: '#000' }}>
                Vitals Record
              </h3>
              <Box>
                <Button
                  onClick={() => showVitalsModal(!disabled)}
                  className={disabled ? classes.disabledIcon : classes.button}
                  style={{
                    height: '35px',
                    fontSize: '12px',
                    color: '#fff',
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    borderRadius: '5px',
                    background:
                      'linear-gradient(to right bottom, #3267F1, #3E3BE8)',
                    fontFamily: 'Poppins',
                    marginLeft: '10px'
                  }}>
                  Add New Record
                  <img
                    alt="next"
                    src={RightArrow}
                    style={{ width: '18px', marginLeft: '10px' }}
                  />
                </Button>
                <Button
                  onClick={() => VitalsHistoryModal(true)}
                  className={classes.button}
                  style={{
                    height: '35px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    color: '#fff',
                    borderRadius: '5px',
                    background: '#FA7429',
                    fontFamily: 'Poppins',
                    marginLeft: '10px'
                  }}>
                  View Vitals History{' '}
                  <img
                    alt="next"
                    src={RightArrow}
                    style={{ width: '18px', marginLeft: '10px' }}
                  />
                </Button>
              </Box>
              {/* <Card className={classes.vitalsCard}>
                <h5></h5>
                <IconButton
                  >
                  <Link
                    style={{
                      position: 'absolute',
                      right: '0',
                      left: '0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <IoIosArrowForward
                      className={classes.icon}
                      size={32}
                      color="#fff"
                    />
                  </Link>
                </IconButton>
              </Card> */}
              {/* <Card className={classes.vitalsCard} mt={2}>
                <h5></h5>
                <IconButton
                  >
                  <Link
                    style={{
                      position: 'absolute',
                      right: '0',
                      left: '0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <IoIosArrowForward
                      className={classes.icon}
                      size={32}
                      color="#fff"
                    />
                  </Link>
                </IconButton>
              </Card> */}
            </Box>
          </Grid>
          {VitalsData.length ? (
            <Grid item xs={12} md={12}>
              <h4 style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                Latest Record :{' '}
                <small
                  className={classes.vitalName}
                  style={{ fontSize: '13px', fontWeight: '600' }}>
                  {VitalsData[0] && VitalsData[0].datetime_recorded
                    ? moment(VitalsData[0].datetime_recorded).format(
                        'DD/MM/YYYY, h:mm:ss a'
                      )
                    : 'NA'}
                </small>
              </h4>
              <ul className={classes.RecordList}>
                <li style={{}}>
                  <Box style={{ display: 'flex' }}>
                    <img
                      alt="Blood Pressure"
                      src={BloodPreser}
                      style={{ marginRight: '15px' }}
                    />
                    <div>
                      <h3 className={classes.patientVital}>
                        {VitalsData[0] && ~~VitalsData[0].sbp} /{' '}
                        {VitalsData[0] && ~~VitalsData[0].dbp}
                      </h3>
                    </div>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                    <p className={classes.vitalName}>Blood Pressure (mmHg)</p>
                    <div>
                      {' '}
                      {VitalsData[0].sbp > 124 && VitalsData[0].sbp <= 140 ? (
                        <IoIosArrowRoundUp
                          size={32}
                          color="#f2a208"
                          style={{ float: 'right' }}
                        />
                      ) : VitalsData[0].sbp > 140 || VitalsData[0].dbp > 90 ? (
                        <IoIosArrowRoundUp
                          size={32}
                          color="red"
                          style={{ float: 'right' }}
                        />
                      ) : VitalsData[0].sbp > 104 &&
                        VitalsData[0].sbp <= 115 ? (
                        <IoIosArrowRoundDown
                          size={32}
                          color="#f2a208"
                          style={{ float: 'right' }}
                        />
                      ) : VitalsData[0].sbp <= 104 &&
                        VitalsData[0].dbp <= 64 ? (
                        <IoIosArrowRoundDown
                          size={32}
                          color="red"
                          style={{ float: 'right' }}
                        />
                      ) : null}
                    </div>
                  </Box>
                </li>
                <li style={{}}>
                  <Box style={{ display: 'flex' }}>
                    <img
                      alt="Temperature"
                      src={Temperature}
                      style={{ marginRight: '15px' }}
                    />
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].temperature
                        ? VitalsData[0].temperature
                        : 'NA'}
                    </h3>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                    <p className={classes.vitalName}> Temperature (F)</p>
                    {VitalsData[0].temperature < 97 &&
                    VitalsData[0].temperature > 95.5 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].temperature <= 95.5 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].temperature > 98.6 &&
                    VitalsData[0].temperature < 100.4 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].temperature >= 100.4 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                  </Box>
                </li>
                <li style={{}}>
                  <Box style={{ display: 'flex' }}>
                    <img
                      alt="Oxygen"
                      src={Oxygen}
                      style={{ marginRight: '15px' }}
                    />
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].oxygen_saturation_rate
                        ? Math.floor(VitalsData[0].oxygen_saturation_rate)
                        : 'NA'}
                    </h3>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                    <p className={classes.vitalName}> Oxygen Sat.(spO2%)</p>
                    {VitalsData[0].oxygen_saturation_rate > 92 &&
                    VitalsData[0].oxygen_saturation_rate <= 97 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].oxygen_saturation_rate <= 92 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                  </Box>
                </li>
                <li style={{}}>
                  <Box style={{ display: 'flex' }}>
                    <img
                      alt="Height"
                      src={Height}
                      style={{ marginRight: '15px' }}
                    />
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].height
                        ? Math.floor(VitalsData[0].height)
                        : 'NA'}
                    </h3>
                  </Box>
                  <p className={classes.vitalName}> Height (cm)</p>
                </li>
                <li>
                  <Box style={{ display: 'flex' }}>
                    <img
                      alt="Weight"
                      src={Weight}
                      style={{ marginRight: '15px' }}
                    />
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].weight
                        ? VitalsData[0].weight
                        : 'NA'}
                    </h3>
                  </Box>
                  <p className={classes.vitalName}> Weight (kg)</p>
                </li>
                <li>
                  <Box style={{ display: 'flex' }}>
                    <img
                      alt="Cardiogram"
                      src={Cardiogram}
                      style={{ marginRight: '15px' }}
                    />
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].heart_rate
                        ? Math.floor(VitalsData[0].heart_rate)
                        : 'NA'}
                    </h3>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                    <p className={classes.vitalName}> Heart Rate (bpm)</p>
                    {VitalsData[0].heart_rate > 94 &&
                    VitalsData[0].heart_rate <= 115 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].heart_rate > 115 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].heart_rate >= 50 &&
                    VitalsData[0].heart_rate < 60 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].heart_rate < 50 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                  </Box>
                </li>
                {HealthParameters.map(({ logo, param, unit }) => (
                  <li key={param}>
                    <Box style={{ display: 'flex' }}>
                      <img
                        alt={param}
                        src={logo}
                        style={{ marginRight: '15px' }}
                      />
                      <h3 className={classes.patientVital}>
                        {gFitData[param] ?? '--'}
                      </h3>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <p className={classes.vitalName}>
                        <span style={{ textTransform: 'capitalize' }}>
                          {param.toLowerCase()}
                        </span>
                        &nbsp;
                        <span>({unit})</span>
                      </p>
                    </Box>
                  </li>
                ))}
              </ul>
              {/* <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                  <Card style={{ background: '#fff' }}>
                    
                  </Card>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Card style={{ background: '#fff' }}>
                    
                  </Card>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Card style={{ background: '#fff' }}>
                   
                  </Card>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Card style={{ background: '#fff' }}>
                   
                  </Card>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Card style={{ background: '#fff' }}>
                    
                  </Card>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Card style={{ background: '#fff' }}>
                    
                  </Card>
                </Grid>
              </Grid> */}
            </Grid>
          ) : VitalsData.length === 0 ? (
            <Box className="no-data-img">
              <img alt="No data" src={NoRecordImg} />
            </Box>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '180px',
                  marginTop: '80px'
                }}>
                <BeatLoader size={48} color="#3f4b79" loading />
              </div>
            </>
          )}

          {AddVitals ? (
            <AddVitalsModal
              showHideModal={hideVitalsModal}
              isShow={AddVitals}
              pateintId={params.patient_id}></AddVitalsModal>
          ) : (
            <></>
          )}

          {showVitalsHistory ? (
            <ViewVitalsHistoryModal
              showHideModal={hideVitalsHistoryModal}
              isShow={showVitalsHistory}
              pateintId={params.patient_id}></ViewVitalsHistoryModal>
          ) : (
            <></>
          )}
          {chartData ? (
            <Grid item xs={12} md={12}>
              <Box className={classes.GrapTop}>
                <Typography
                  component="h4"
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: 18,
                    fontWeight: 500,
                    color: '#000'
                  }}>
                  Vitals <span>Average for month</span>
                </Typography>
                <Box className={classes.BtnCol}>
                  <ToggleButtonGroup
                    size="small"
                    className={classes.ToggleBtn}
                    value={alignment}
                    exclusive
                    onChange={handleChange}>
                    <ToggleButton
                      style={{ fontFamily: 'Poppins', height: '40px' }}
                      value="weekly"
                      className={
                        alignment === 'weekly' ? classes.ActiveBtn : ''
                      }>
                      Latest
                    </ToggleButton>
                    <ToggleButton
                      style={{ fontFamily: 'Poppins', height: '40px' }}
                      value="monthly"
                      className={
                        alignment === 'monthly' ? classes.ActiveBtn : ''
                      }>
                      Month
                    </ToggleButton>
                    <ToggleButton
                      style={{ fontFamily: 'Poppins', height: '40px' }}
                      value="yearly"
                      className={
                        alignment === 'yearly' ? classes.ActiveBtn : ''
                      }>
                      Year
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.bp}
                      options={{
                        title: 'Blood Pressure',
                        isStacked: true,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      className={classes.Areachart}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.temp}
                      options={{
                        title: 'Temperature',
                        isStacked: true,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      style={{ background: '#c3dcfd' }}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.hrate}
                      options={{
                        title: 'Heart Rate',
                        isStacked: false,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      style={{ background: '#c3dcfd' }}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.bmi}
                      options={{
                        title: 'BMI',
                        isStacked: false,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      style={{ background: '#c3dcfd' }}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.oxy}
                      options={{
                        title: 'Oxygen saturation',
                        isStacked: false,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      style={{ background: '#c3dcfd' }}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.resp}
                      options={{
                        title: 'Respiration Rate',
                        isStacked: false,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </TabPanel>
    </React.Fragment>
  )
}

const useStyles = makeStyles(() => ({
  RecordList: {
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& li': {
      width: '32%',
      border: '1px #D3E1FF solid',
      boxShadow: '0 2px 10px 0 rgba(0,0,0,0.05)',
      borderRadius: '8px',
      padding: '20px',
      margin: '0 0 20px',
      '@media (max-width:767px)': {
        width: '100%'
      }
    }
  },
  vitalTop: {
    '@media (max-width:767px)': {
      flexDirection: 'column'
    }
  },
  button: {
    '@media (max-width:767px)': {
      marginLeft: '0px !important',
      marginTop: '15px',
      width: '100% !important'
    }
  },
  disabledIcon: {
    background: '#adadad !important',
    '@media (max-width:767px)': {
      marginLeft: '0px !important',
      width: '100% !important'
    }
  },
  vitalName: {
    fontSize: '16px',
    color: '#0c1741',
    fontWeight: '500',
    fontFamily: 'Poppins',
    marginBottom: 0
  },
  patientVital: {
    fontWeight: '600',
    fontSize: '30px',
    fontFamily: 'Poppins',
    marginBottom: '0px',
    color: '#0D46C1'
  },
  GrapTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px'
  },
  ChartBox: {
    boxShadow: '0px 2px 20px -3px rgba(0,0,0,0.1)',
    borderRadius: '10px',
    overflow: 'hidden',
    background: '#fff'
  },
  ToggleBtn: {
    fontFamily: 'Poppins !important',
    height: '40px'
  },
  ActiveBtn: {
    background: '#9138aa !important',
    color: '#fff !important'
  }
}))

export default PatientDetailchart
