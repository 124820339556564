import React from 'react'
import { SwipeableDrawer } from '@material-ui/core'
// import Profile from './PatientProfile'
import Profile from '../../components/CommonComponents/PatientProfile'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useHistory } from 'react-router-dom'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined'
import TollOutlinedIcon from '@material-ui/icons/TollOutlined'
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import CardBgImg from '../../images/right-top-img3.png'

const PatientMenuDrawer = ({ listItems = [], open }) => {
  const classes = useStyles()
  const { push, location } = useHistory()

  const listItemClicked = async (path, action) => {
    action()
    push(path)
  }

  return (
    <SwipeableDrawer
      className={classes.root}
      open={false}
      // anchor={} TODO: Add navbar tag =useRef()
      // onOpen={console.log}
      variant="permanent"
      // onClose={console.log}
    >
      <Profile sideNav={'sideNav'} />
      <div
        style={{ marginBottom: '5rem', marginTop: 30 }}
        className="menu-view">
        {listItems.map(item => (
          <ListItem
            button
            key={item.id}
            className={`${classes.listItem + ' ' + item.class} ${
              location.pathname === item.path ? classes.activeItem : ''
            }`}
            onClick={() => listItemClicked(item.path, item.action)}>
            {item.id === 0 ? (
              <DashboardOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 1 ? (
              <FavoriteBorderOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 2 ? (
              <TollOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 3 ? (
              <TodayOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 4 ? (
              <AssignmentOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 5 ? (
              <AccountCircleOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}
            {item.id === 7 ? (
              <ExitToAppOutlinedIcon
                style={{ height: '0.8em', width: '0.8em', marginRight: '8px' }}
              />
            ) : null}

            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </div>
    </SwipeableDrawer>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    '&>div': {
      width: '22vw',
      maxWidth: '300px',
      background: 'linear-gradient(45deg, #680fad, #063bc4)',
      backgroundSize: 'cover',
      color: '#fff',
      top: 0,
      border: 0,
      paddingTop: 10,
      '@media (max-width:991px)': {
        width: '40vw',
        maxWidth: '300px',
        top: '0px'
      },
      '@media (max-width:767px)': {
        width: '75vw',
        maxWidth: '300px',
        top: '0px'
      }
    }
  },
  listItem: {
    padding: '8px 32px',
    '& span': {
      fontSize: '14px',
      textAlign: 'left',
      margin: '10px 0',
      padding: ''
    }
  },
  activeItem: {
    background: '#9138aa !important'
  }
}))

export default PatientMenuDrawer
