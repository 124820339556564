import 'date-fns'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import moment from 'moment'

export default function MaterialUIPickers({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  showPastAppointmentsDate,
  showUpcomingAppointmentsDate
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-between">
        <KeyboardDatePicker style={{marginRight:'20px', fontWeight:'600', width:'45%'}}
          disableToolbar
          autoOk
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Start Date"
          minDate={showUpcomingAppointmentsDate ? new Date() : moment('1900-01-01')}
          maxDate={showPastAppointmentsDate ? new Date() : moment('2100-01-01')}
          minDateMessage={
            showUpcomingAppointmentsDate &&
            moment(startDate).isBefore(new Date()) &&
            "Upcoming Appointments... so the minimal date is today's date"
          }
          maxDateMessage={
            moment(startDate).isAfter(endDate) &&
            "'Start Date' should not be after the 'End Date'"
          }
          value={startDate}
          onChange={onStartDateChange}
          
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
        <KeyboardDatePicker style={{fontWeight:'600'}}
          disableToolbar
          autoOk
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="End Date"
          minDate={startDate}
          maxDate={showPastAppointmentsDate ? new Date() : moment('2100-01-01')}
          minDateMessage={
            moment(startDate).isAfter(endDate) &&
            "'End Date' should not be before the 'Start Date'"
          }
          maxDateMessage={
            showPastAppointmentsDate &&
            moment(endDate).isAfter(new Date()) &&
            "Past Appointments... so the maximal date is today's date"
          }
          value={endDate}
          onChange={onEndDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
