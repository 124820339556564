import { hi } from 'date-fns/locale'
import React from 'react'
import Orientation from '../../images/Orientation-icon.png'

export default function OrientationError() {
    return (
        <div>
          <div className="mobile-orientation">
            <div className="orientation-col">
            <img src={Orientation} alt="Orientation" />
          <p>Please switch to portrait mode</p>
            </div>
         
            </div> 
        </div>
    )
}
