import React, { useState, useEffect } from 'react'
import { makeStyles, Grid, Box, Typography, TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import tele1 from '../images/tele1.png'
import map1 from '../images/map1.png'
import { Layout } from '../patientLayouts'
import LoginForm from '../components/CommonComponents/LoginForm'
import Swal from 'sweetalert2'
import LoginBg from '../images/medical_pattern.svg'
import PatientImg from '../images/patient.svg'
import { withRouter } from 'react-router'
import UserBrowser from '../BrowserSupport'
import PublicNavbar from '../components/CommonComponents/PublicNavbar'
import { HOME_URL } from '../Environment'

const PatientLogin = props => {
  const history = useHistory()
  const classes = useStyles()
  const [doctorName, setDoctorName] = useState('')
  const onLoginSuccess = ({ request, response }) => {
    if (response.statusCode == 200) {
      history.replace({ pathname: '/patient/selectPatient' })
    } else {
      return Swal.fire(
        'Oops! Login failed',
        'Please enter your credentials correctly',
        ''
      )
    }
  }

  const [showAlert, setAlert] = useState(false)

  useEffect(() => {
    if (props.history?.location?.state?.unauthorized == true) {
      // setAlert(true);
    }
    return () => {}
  }, [])

  const searchDocText = event => {
    setDoctorName(event.target.value)
  }

  const searchDoctor = event => {
    event.preventDefault()

    if (doctorName !== '') {
      window.location.href = HOME_URL + doctorName
    } else {
      Swal.fire(
        'Doctor name is required',
        'Please enter doctor name to check their slots.',
        'info'
      )
    }
  }

  const fireAlert = () => {
    Swal.fire('', 'Please log in to continue', 'info').then(() => {
      setAlert(false)
      const state = { ...history.location.state }
      state.unauthorized = false
      history.replace({ ...history.location, state })
    })
  }

  return (
    <Layout hideNavbar>
      <PublicNavbar />
      <Grid container spacing={0} className={classes.Heademain}>
        <div
          style={{
            background: `url(${LoginBg}) #3265F0`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '50%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 85
          }}
          className={classes.LoginLeft}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              marginTop: '-65px'
            }}>
            <div
              style={{
                backgroundColor: '#E2EAFF',
                borderRadius: 15,
                padding: '30px 20px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
                width: 300,
                margin: '0px auto 20px'
              }}>
              <img src={PatientImg} alt="" />
            </div>

            <h4
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: 'white',
                marginBottom: 10
              }}>
              Modernizing the doctor consultation
            </h4>
            <p
              style={{
                fontSize: 12,
                color: '#fff',
                width: '50%',
                margin: '0px auto',
                textAlign: 'center'
              }}>
              Mobile application that makes it easier for patients to
              communicate online-based consultations
            </p>
          </div>
          <div
            style={{
              width: '100%',
              backgroundColor: '#285CEB',
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0
            }}>
            <p
              style={{
                fontSize: 16,
                fontWeight: 600,
                color: '#fff',
                textAlign: 'center'
              }}>
              Call Virtual Medical Assistance at <br />
              (033) 418-16522
            </p>
          </div>
        </div>
        {/* <Grid item xs={12} className={classes.LoginImg}>
          <img src={LoginBGImg} alt="Hero" className={classes.heroImage} />
        </Grid>
        <Box className={classes.Logintext}>
          <h2>
            Call Virtual Medical Assistance at <br />
            033-418-16533
          </h2>
        </Box> */}
        <div
          className={classes.LoginRight}
          style={{
            width: '50%',
            backgroundColor: '#F8F8FB',
            padding: '145px 0 60px'
          }}>
          <div style={{ width: 390, margin: '0px auto' }}>
            <h2
              style={{
                fontSize: 24,
                fontWeight: 600,
                color: '#FF7F40',
                margin: 0
              }}>
              Welcome!
            </h2>
            <p
              style={{
                fontSize: 14,
                color: '#000',
                letterSpacing: 2,
                margin: '0 0 30px'
              }}>
              Sign in to continue
            </p>
          </div>
          <Box className={classes.loginForm}>
            <div className={classes.form}>
              <LoginForm onSuccess={onLoginSuccess} />
            </div>
          </Box>
        </div>
      </Grid>
      <Box className={classes.LoginContent}>
        <h2 style={{ color: '#fff' }}>Search Your Doctor or Clinic</h2>
        <form className={classes.root} noValidate autoComplete="off">
          <Box className={classes.SearchCol}>
            <h5>{HOME_URL}</h5>
            <Box className={classes.SearchInput}>
              <TextField
                className={classes.InputCol}
                id="standard-basic"
                onChange={searchDocText}
                label=""
              />
            </Box>
          </Box>

          <button className={classes.LoginButton} onClick={searchDoctor}>
            Search
          </button>
        </form>
      </Box>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.colorWhiteBg}
          style={{ padding: '18% 4% 0' }}>
          <Box
            className={classes.countbox}
            textAlign={{ xs: 'center', md: 'left' }}>
            <Typography variant="h3" className={classes.count}>
              <p>
                Looking for a doctor to consult online ? Please call our virtual
                medical assistant at
              </p>
              <p>033-418-16533</p>
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.colorDarkBg}
          style={{ padding: '0% 5%', backgroundColor: '#fff' }}>
          <Box
            className={classes.countbox}
            textAlign={{ xs: 'center', md: 'left' }}>
            <img src={tele1} alt="tele img" style={{ width: '100%' }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.colorDarkBg}
          style={{ padding: '0% 5%', backgroundColor: '#fff' }}>
          <Box
            className={classes.countbox}
            textAlign={{ xs: 'center', md: 'left' }}>
            <img src={map1} alt="tele img" style={{ width: '100%' }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.colorWhiteBg}
          style={{ padding: '15% 4% 0' }}>
          <Box
            className={classes.countbox}
            textAlign={{ xs: 'center', md: 'left' }}>
            <Typography variant="h3" className={classes.count}>
              <p>
                Looking for a doctor or a clinic nearby you ? Our virtual
                medical assistance team can help you find the best doctor nearby
                your location.{' '}
              </p>
              <p>033-418-16533</p>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {showAlert && fireAlert()}
    </Layout>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '1300px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '0px',
    padding: '0px',
    color: '#fff',
    paddingBottom: '20px',
    '@media (max-width:1250px)': {
      maxWidth: '100%'
    }
  },
  SearchInput: {
    width: '100%'
  },
  InputCol: {
    width: '100%',
    marginTop: '0px'
  },
  LoginButton: {
    width: '150px',
    height: '45px',
    background: '#3267F1',
    color: '#fff',
    fontSize: '15px',
    borderRadius: '8px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    textAlign: 'center',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '15px',
    '& h2': {
      fontSize: '30px',
      color: '#263159',
      fontWeight: 'normal',
      marginBottom: '30px'
    }
  },
  colorWhiteBg: {
    backgroundColor: '#fff'
  },
  LoginContent: {
    width: '100%',
    textAlign: 'center',
    background: 'linear-gradient(to right bottom, #680FAD, #063BC4)',
    paddingTop: '20px',
    paddingBottom: 20,
    '@media (max-width:767px)': {
      paddingTop: '5px'
    },
    '& h6': {
      fontSize: '20px',
      color: '#263159',
      padding: '0 31%',
      margin: '0 0 30px',
      fontWeight: 'normal',
      '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape)':
        {
          padding: '0 10%'
        },
      '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait)':
        {
          padding: '0 20%'
        },
      '@media (max-width:767px)': {
        padding: '0 5%',
        fontSize: '16px'
      }
    },
    '& h4': {
      fontSize: '20px',
      color: '#263159',
      fontWeight: 'bold',
      marginBottom: '0px',
      '@media (max-width:767px)': {
        fontSize: '16px'
      },
      '& span': {
        fontWeight: 'normal'
      }
    },
    '& p': {
      fontSize: '16px',
      color: '#787878',
      padding: '0 10%',
      fontWeight: 'normal',
      '@media (max-width:767px)': {
        fontSize: '14px',
        padding: '0 5%'
      }
    },
    '& h5': {
      fontSize: '16px',
      color: '#263159',
      marginRight: '15px',
      marginTop: '0px',
      marginBottom: '0px',
      '@media (max-width:767px)': {
        fontSize: '16px'
      }
    }
  },
  Heademain: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: 0,
    '@media (max-width:767px)': {
      marginTop: 0,
      flexDirection: 'column'
    }
  },
  LoginLeft: {
    '@media (max-width:767px)': {
      width: '100% !important',
      height: 600,
      order: 2,
      paddingTop: 0,

      '& p': {
        width: '100% !important'
      }
    }
  },
  LoginRight: {
    '@media (max-width:767px)': {
      width: '100% !important',
      padding: '110px 0 60px !important',
      '& h2, p': {
        marginLeft: '25px !important'
      }
    }
  },
  logo: {
    marginBottom: '4.5rem',
    maxWidth: 'fit-content'
  },

  heroImage: {
    height: '100%',
    objectFit: 'cover',
    width: '100%'
    // TODO: Add media Query in Image
  },
  loginFormRoot: {
    display: 'flex',
    backgroundColor: 'white',
    justifyContent: 'center',
    '@media (max-width:767px)': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  SearchCol: {
    width: '600px',
    padding: '20px',
    boxShadow: '0 1px 5px 2px rgba(0,0,0,0.1)',
    margin: '0px auto 25px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '30px',
    '@media (max-width:767px)': {
      maxWidth: '85%',
      flexWrap: 'wrap'
    },
    '& h6': {
      margin: '0px'
    }
  },
  loginForm: {
    padding: '45px 35px',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    background: '#fff',
    width: 390,
    margin: '0px auto',
    borderRadius: 30,
    zIndex: '99',
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape)':
      {
        bottom: '2%'
      },
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait)':
      {
        width: '100%',
        position: 'relative',
        right: '0px'
      },
    '@media (max-width:767px)': {
      width: '100%',
      position: 'relative',
      right: '0px',
      marginTop: '20px'
    }
  },
  form: {
    maxWidth: '380px',
    width: '100%',
    '@media (max-width:767px)': {
      maxWidth: '100%'
    }
  },
  Logintext: {
    background: '#263159',
    width: '100%',
    padding: '30px 7%',
    '& h2': {
      fontSize: '30px',
      color: '#fff',
      margin: '0px',
      '@media (max-width:1024px)': {
        fontSize: '24px'
      },
      '@media (max-width:767px)': {
        fontSize: '20px',
        padding: '15px 0'
      }
    }
  },
  topLogo: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '70px'
  },
  count: {
    color: '#000',
    padding: '20px 40px',
    '@media (max-width:780px)': {
      fontSize: '1.5rem'
    },
    '& p': {
      fontSize: '24px',
      marginTop: '0',
      '@media (max-width:780px)': {
        fontSize: '18px'
      }
    }
  }
}))

export default withRouter(UserBrowser(PatientLogin))
