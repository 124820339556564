import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import '../../../../components/SlotBooking/style.css'
import moment from 'moment'
import { PublicPatientStore } from '../../../../stores'
import { makeStyles, Box, TextField, Grid } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import {
  CONSULTATION_TYPE,
  NETWORK_STATUS,
  VALIDATE_PHONE
} from '../../../../Constants'
import { useParams } from 'react-router-dom'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import Loader from '../../../components/ClinicLoader'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as yup from 'yup'
import { useFormik } from 'formik'

var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
var days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export default function ScheduleSlots({ doctorId, docAssociationId }) {
  let today = moment().format('YYYY-MM-DD')
  let max = moment().add(3, 'month')
  let maxLimit = moment(max).format('YYYY-MM-DD')
  const [dateSelected, setDate] = useState([])
  const [timeSlotShow, showTime] = useState(false)
  const [timesAvailable, setTimeSlotsAvailable] = useState([])
  const [confirmShowId, showConfirmBtnId] = useState(null)
  const [sameDay, setSameDay] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const classes = useStyles()
  const { shortCode } = useParams()

  const [selectedDate, setSelectedDate] = useState('')
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [consultationType, setConsultationType] = useState(
    CONSULTATION_TYPE.IN_PERSON.value
  )

  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [alertType, setAlertType] = React.useState('error')
  const [multiButton, setmultiButton] = React.useState(false)

  const { fetchSchedule } = PublicPatientStore.useStoreActions(actions => ({
    ...actions.doctorSchedule
  }))
  const { setSchedule } = PublicPatientStore.useStoreActions(state => ({
    ...state.appointment
  }))

  const { data, networkStatus } = PublicPatientStore.useStoreState(state => ({
    ...state.doctorSchedule
  }))
  const calendarRef = React.createRef()

  useEffect(() => {
    let todayDate = moment().format('YYYY-MM-DD')
    setSelectedDate(todayDate)
    let currentDay = new Date()
    setDate(currentDay)
    showTime(true)
    setSameDay(true)
  }, [])

  useEffect(() => {
    setTimeSlotsAvailable(data)
  }, [data])

  useEffect(() => {
    const date = selectedDate
    if (doctorId !== 'null') {
      fetchSchedule({
        consultationType,
        doctor_id: doctorId,
        clinic_doc_assoc_id: docAssociationId,
        schedule_type: consultationType,
        date
      })
    }
  }, [selectedDate, doctorId])

  useEffect(() => {
    let date = selectedDate
    fetchSchedule({
      doctor_id: doctorId,
      schedule_type: consultationType,
      date,
      consultationType
    })
  }, [consultationType])

  const onChangeConsultationType = (event, value) => {
    if (value === CONSULTATION_TYPE.IN_PERSON.value) {
      setConsultationType(CONSULTATION_TYPE.IN_PERSON.value)
    }
    if (value === CONSULTATION_TYPE.TELE.value) {
      setConsultationType(CONSULTATION_TYPE.TELE.value)
    }
    setSelectedSchedule(null)
    showConfirmBtnId(null)
  }

  const onSelectScheduleItem = React.useCallback(
    scheduleItem => {
      localStorage.setItem('schedule', JSON.stringify(scheduleItem))
      localStorage.removeItem('selected_booking_account')

      setSelectedSchedule(scheduleItem)
      setSchedule(scheduleItem)
    },
    [setSelectedSchedule, setSchedule]
  )

  const onDateSelected = async info => {
    let currentDay = new Date()
    let dateInfo = info?.start
    let daySelected = moment(dateInfo, 'YYYY-MM-DD h:mm a').format('YYYY-MM-DD')
    let current = moment(currentDay, 'YYYY-MM-DD h:mm a').format('YYYY-MM-DD')
    if (daySelected === current) {
      setSameDay(true)
    } else {
      setSameDay(false)
    }
    if (daySelected >= current) {
      setSelectedDate(daySelected)
      setDate(dateInfo)
      showTime(true)
    } else {
      setSnackBarMessage(
        'Sorry that date you selected is in the past. Please select another date.'
      )
      setOpenSnackBar(true)
    }
  }

  const onTimeSlotSelected = async timeSlot => {
    let selectedSlot = timeSlot.id
    onSelectScheduleItem(timeSlot)
    showConfirmBtnId(selectedSlot)
  }

  const onConfirmSelected = async timeSlot => {
    if (
      moment().format('YYYY-MM-DD HH:mm') >
      moment(timeSlot.appointment_end_time)
        .subtract(5, 'm')
        .format('YYYY-MM-DD HH:mm')
    ) {
      setSnackBarMessage('Booking time expired. Try the next available slot')
      setOpenSnackBar(true)
    } else {
      localStorage.setItem(
        'selectedSchedule',
        JSON.stringify({
          ...timeSlot
        })
      )
      localStorage.setItem('clinic_booking_doctor_id', doctorId)
      localStorage.removeItem('payment_success')
      localStorage.removeItem('clinic_booking_details')
      setDialogOpen(true)
      window.location.href = '/clinic/booking/patient_selection'
    }
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  let buttonClassName = consultationType === '1' ? 'time-btn' : 'tele-time-btn'

  const date =
    new Date().getFullYear() +
    '-' +
    (new Date().getMonth() + 1) +
    '-' +
    new Date().getDate() +
    ' ' +
    new Date().getHours() +
    ':' +
    new Date().getMinutes() +
    ':' +
    new Date().getSeconds()

  return (
    <div className={classes.root}>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Box mb={4}>
        <ToggleButtonGroup
          value={consultationType}
          onChange={onChangeConsultationType}
          className={classes.ToggleBtn}
          size="small"
          exclusive>
          <ToggleButton value={CONSULTATION_TYPE.IN_PERSON.value}>
            In-Person Consultation
          </ToggleButton>
          {/* <ToggleButton value={CONSULTATION_TYPE.TELE.value}>
            Tele Consultation
          </ToggleButton> */}
        </ToggleButtonGroup>
      </Box>
      <Box>
        <SnackbarAlert
          isOpen={isSnackBarOpen}
          message={snackBarMessage}
          alertType={alertType}
          multibutton={multiButton}
          primaryClick={hideSnackBar}
        />
      </Box>
      <section id="calendar-section" class="body-section">
        <h3>Select a Date & Time</h3>
        <div id="schedule-div">
          {timeSlotShow ? (
            <div id="available-times-div">
              <h4>
                {days[dateSelected.getDay()] +
                  ', ' +
                  months[dateSelected.getMonth()] +
                  ' ' +
                  dateSelected.getDate()}
              </h4>
              {timesAvailable.map(item => {
                return (
                  <div class="time-slot">
                    <button
                      disabled={
                        !moment(item.appointment_start_time).isAfter(date)
                      }
                      className={
                        moment(item.appointment_start_time).isAfter(date)
                          ? buttonClassName
                          : 'disabledTimeSlot'
                      }
                      onClick={() => onTimeSlotSelected(item)}>
                      <>
                        {moment(
                          item.appointment_start_time,
                          'YYYY-MM-DD hh:mm:ss'
                        ).format('hh:mm A')}{' '}
                        -
                        {moment(
                          item.appointment_end_time,
                          'YYYY-MM-DD hh:mm:ss'
                        ).format('hh:mm A')}
                      </>
                    </button>
                    {item.id === confirmShowId ? (
                      <button
                        class="confirm-btn"
                        onClick={() => onConfirmSelected(item)}>
                        Confirm
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}
              {timesAvailable.length === 0 ? 'Sorry no schedules found' : ''}
            </div>
          ) : (
            ''
          )}

          <div id="calendar" className={sameDay ? 'same-day' : ''}>
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              defaultView="dayGridMonth"
              // defaultView={
              //   window.innerWidth < 780 ? 'listWeek' : 'dayGridMonth'
              // }
              selectable={true}
              select={onDateSelected}
              unselectAuto={false}
              selectConstraint={{
                start: today,
                end: maxLimit
              }}
              showNonCurrentDates={false}
              validRange={{
                end: maxLimit
              }}
              ref={calendarRef}
              longPressDelay={0}
              selectLongPressDelay={0}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  TabCol: {
    backgroundColor: '#efefef',
    borderRadius: '5px',
    color: '#414141',
    boxShadow: 'none',
    overflow: 'hidden'
  },
  TabList: {
    '& span': {
      top: '0px',
      height: '0px',
      zIndex: '0'
    },
    '& button': {
      maxWidth: '108px',
      minWidth: '108px',
      padding: '5px 0',
      zIndex: '99',
      position: 'relative'
    }
  },
  TimeSelected: {
    backgroundColor: '#3f4b79',
    color: '#fff',
    borderRadius: '10px',
    boxShadow: 'none',
    marginRight: '10px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#3f4b79'
    }
  },
  TimeButton: {
    backgroundColor: '#efefef',
    color: '#3f4b79',
    borderRadius: '10px',
    boxShadow: 'none',
    marginRight: '10px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#3f4b79',
      color: '#fff'
    }
  },
  TimeSchdule: {
    marginBottom: '30px'
  },
  BookBtn: {
    width: '140px',
    textAlign: 'center',
    backgroundColor: '#3f4b79',
    borderRadius: '40px',
    boxShadow: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#414141',
      boxShadow: 'none'
    }
  },
  TopHeading: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '20px',
    alignItems: 'center',
    fontSize: '18px',
    color: '#979797',
    '& img': {
      marginRight: '10px'
    }
  },
  ToggleBtn: {
    backgroundColor: '#efefef',
    color: '#3f4b79',
    borderRadius: '0px',
    border: 'none',
    '& button': {
      borderRadius: '0px',
      border: 'none',
      textTransform: 'capitalize',
      fontSize: '16px',
      paddingLeft: '20px',
      paddingRight: '20px',
      '@media (max-width:767px)': {
        paddingLeft: '10px',
        paddingRight: '10px',
        fontSize: '14px'
      }
    },
    '& .Mui-selected': {
      color: '#fff',
      background: '#3f4b79',
      '&:hover': {
        backgroundColor: '#3f4b79',
        boxShadow: 'none'
      }
    },
    '& .Mui-selected:nth-child(2)': {
      background: '#9abd0f',
      '&:hover': {
        backgroundColor: '#9abd0f',
        boxShadow: 'none'
      }
    }
  },
  ActiveToggle: {
    backgroundColor: '#3f4b79',
    color: '#fff'
  },
  errMsg: {
    color: 'red'
  },
  modalView: {
    width: '20rem !important'
  }
}))
