import { action, thunk } from 'easy-peasy'
import moment from 'moment'
import Axios from 'axios'
import BaseModel from '../Base'
import {
  NETWORK_STATUS,
  SCHEDULER_RESOURCES,
  API_REQUEST_DATE_FORMAT
} from '../../Constants'
import api from '../../api'
import localforage from 'localforage'
import {
  DOCTOR_USER_ID,
  LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME
} from '../../Constants'

export default {
  ...BaseModel(),

  data: [],
  events: [],
  scheduleUpdateFailed: false,
  scheduleCreateFailed: false,
  appointment: [],
  inpersonconsultationSettings: {
    duration: 0,
    noOfPerson: 0
  },
  teleconsultationSettings: {
    duration: 0,
    noOfPerson: 0
  },
  updatedScheduleSuccess: false,
  rescheduleAppointmentSuccess: false,
  rescheduleAppointmentFailure: false,
  consultSettingUpdateStatus: 0,

  fetchSchedule: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const { schedulerView } = payload
      let list_type = schedulerView === 'true' ? 'calendar' : 'appointments'
      const response = await api.doctor.schedulesFetch({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        list_type: list_type
      })

      if (response?.data?.statusCode === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

        actions.setResourceData(response.data.categories)
        if (list_type === 'calendar') {
          actions.setSchedulerData(response.data.events)
        } else {
          actions.setAppointmentData(response.data.events)
        }
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setSchedulerData: action((state, payload) => {
    state.events = payload
  }),

  setAppointmentData: action((state, payload) => {
    state.appointment = payload
  }),

  setResourceData: action((state, payload) => {
    state.data = payload
  }),

  setAppointmentData: action((state, payload) => {
    state.appointment = payload
  }),

  createSchedule: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const response = await api.doctor.schedulesAdd({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        calendar_specific_event_id: payload[0].id,
        ...payload[0]
      })

      if (response?.data?.statusCode === 200) {
        actions.fetchSchedule({ schedulerView: 'true' })
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.setScheduleCreateFailure(true)
        actions.fetchSchedule({ schedulerView: 'true' })
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  updateSchedule: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const response = await api.doctor.schedulesUpdate({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        ...payload[0]
      })

      if (response?.data?.statusCode === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return true
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        actions.setScheduleUpdateFailure(true)
        actions.fetchSchedule({ schedulerView: 'true' })
      }
    }
  ),

  deleteSchedule: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const response = await api.doctor.schedulesDelete({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        ...payload[0]
      })

      if (response?.data?.statusCode === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.setScheduleUpdateFailure(true)
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        actions.fetchSchedule({ schedulerView: 'true' })
      }
    }
  ),
  setScheduleUpdateFailure: action((state, payload) => {
    state.scheduleUpdateFailed = payload
  }),
  setScheduleCreateFailure: action((state, payload) => {
    state.scheduleCreateFailed = payload
  }),

  getConsultationSettings: thunk(
    async (actions, payload, { getStoreState }) => {
      const { visitType, appointmentType } = payload
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })

      const response = await api.doctor.getConsultationSettings({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        visit_type: visitType,
        appointment_type: appointmentType
      })

      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        if (response?.data?.consultation_settings?.appointment_type === '1') {
          actions.setInpersonConsultationSettings({
            duration: response.data.consultation_settings.length,
            noOfPerson:
              response.data.consultation_settings.consultations_per_hour
          })
        } else {
          actions.setTeleConsultationSettings({
            duration: response.data.consultation_settings.length,
            noOfPerson:
              response.data.consultation_settings.consultations_per_hour
          })
        }
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setInpersonConsultationSettings: action((state, payload) => {
    state.inpersonconsultationSettings = payload
  }),
  setTeleConsultationSettings: action((state, payload) => {
    state.teleconsultationSettings = payload
  }),

  updateConsultationSettings: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      const {
        visitType,
        appointmentType,
        visitLength,
        consultPerHour
      } = payload
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      const response = await api.doctor.updateConsultationSettings({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        visit_type: visitType,
        appointment_type: appointmentType,
        visit_length: visitLength,
        consultations_per_hour: consultPerHour
      })

      if (response.ok) {
        actions.getConsultationSettings({
          visitType: visitType,
          appointmentType: appointmentType
        })
        actions.setUpdatedSchedule(response.ok)
        actions.setConsultSettingUpdateStatus(response?.data?.statusCode)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setUpdatedSchedule: action((state, payload) => {
    state.updatedScheduleSuccess = payload
  }),

  setConsultSettingUpdateStatus: action((state, payload) => {
    state.consultSettingUpdateStatus = payload
  }),

  rescheduleAppointment: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      const {
        appointment_id,
        appointment_date,
        appointment_start_time,
        appointment_end_time,
        calendar_schedule_id
      } = payload
      let appointment_type =
        payload.category_id === 'eab4da05-c870-4a5e-b4e3-25fc0ece7d15'
          ? '1'
          : '2'
      // const availablityResponse = await api.getDoctorScheduleSlots({
      //   doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      //   appointment_type:
      //     payload.category_id === 'eab4da05-c870-4a5e-b4e3-25fc0ece7d15'
      //       ? '1'
      //       : '2',
      //   appointment_date: appointment_date,
      //   visit_type: '1'
      // })
      // if (availablityResponse?.data) {
      //   let slotAvailable = availablityResponse?.data?.slot_data?.find(item => {
      //     let startingTime = moment(
      //       item.appointment_start_time,
      //       'YYYY-MM-DD hh:mm:ss'
      //     ).format('hh:mm:ss')
      //     let endingTime = moment(
      //       item.appointment_end_time,
      //       'YYYY-MM-DD hh:mm:ss'
      //     ).format('hh:mm:ss')
      //     if (
      //       startingTime === appointment_start_time &&
      //       endingTime === appointment_end_time
      //     ) {
      //       return item
      //     }
      //   })
      //   if (slotAvailable) {
      const response = await api.doctor.rescheduleAppointment({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        appointment_id: appointment_id,
        appointment_date: appointment_date,
        appointment_start_time: appointment_start_time,
        appointment_end_time: appointment_end_time,
        appointment_type: appointment_type  
      })

      if (response.ok) {
        actions.setRescheduleResponse(response?.data)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setRescheduleResponse(null)
        return true
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        return false
      }
      // } else {
      //   actions.setRescheduleResponse(false)
      //   actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      //   return false
      // }
      // } else {
      //   actions.setRescheduleResponse(false)
      //   actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      //   return false
      // }
    }
  ),
  setRescheduleResponse: action((state, payload) => {
      state.rescheduleAppointmentStatus = payload
  })
}
