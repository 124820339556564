import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import { AUTH_STATE, LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME } from '../../Constants'
import localforage from 'localforage'
import { DoctorPublicStore, DoctorStore } from '../../stores'
import DefaultImage from '../../images/profile_pic.png'

const Profile = () => {
  const classes = useStyles()
  const [profileImg, setProfileImage] = useState()

  const profile = DoctorStore.useStoreState(state => ({
    ...state.profile
  }))

  const { fetchDetails } = DoctorPublicStore.useStoreActions(actions => ({
    ...actions.doctorProfile
  }))

  const {
    image,
    name,
    prefix,
    user_short_code,
    specialisation,
    degree,
    experience,
    availability,
    description,
    associated_with,
    consultationFees
  } = DoctorPublicStore.useStoreState(state => ({
    ...state.doctorProfile
  }))

  useEffect(() => {
    localforage
      .getItem(LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME)
      .then(data => {
        // if (data.user.user_short_code) {
        //   let shortCode = data.user.user_short_code
        //   localStorage.setItem('user_short_code', shortCode)
        // }
        const doctorDetails = localStorage.getItem('doctor_auth_key')
        if (!!doctorDetails) {
          fetchDetails({})
        }
      })
      .catch(err => {
        // console.log('Error retreiving item from local storage', err)
      })
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.avatar}>
        <Avatar
          className={classes.image}
          alt="Profile Picture"
          src={image ? image : DefaultImage}
        />
      </div>
      <Box mt={2} className="userinfo">
        <Typography className={classes.name} style={{ wordBreak: 'break-all' }}>
          {prefix} {name}
        </Typography>
        <Link className={classes.link}>
          <Typography variant="h5" style={{ color: '#fff' }}>
            {user_short_code ? '/' + user_short_code : 'Loading...'}
          </Typography>
        </Link>
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: '20px',
    backgroundSize: 'contain'
  },
  link: {
    '@media (max-width:767px)': {
      color: '#000 !important'
    }
  },
  name: {
    '@media (max-width:767px)': {
      color: '#000 !important'
    }
  },
  userinfo: {
    '& h5': {
      '@media (max-width:767px)': {
        color: '#000 !important'
      }
    }
  },
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
      0 2px 2px rgba(0,0,0,0.12), 
      0 4px 4px rgba(0,0,0,0.12), 
      0 8px 8px rgba(0,0,0,0.12),
      0 16px 16px rgba(0,0,0,0.12)`,
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0)
    }
  },
  image: {
    width: '100%',
    height: '100%'
  },
  name: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none',
    marginBottom: '50px',
    display: 'flex',
    margin: '0 auto',
    width: 'fit-content',
    '&>h5': {
      fontSize: '12px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      textAlign: 'center'
    }
  }
}))

export default Profile
