import React from 'react'
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { Delete, Error, CheckCircle, CloudUpload } from '@material-ui/icons'

const SingleFileUpload = ({
  file,
  fileName,
  singleFileErrorMessage,
  onTextInputChange,
  onDelete,
  onUpload,
  uploading,
  successful,
  errors = []
}) => {
  return (
    <Grid item style={{ width: '100%' }}>
      {file && (
        <Grid
          container
          justify="space-between"
          alignItems="center"
          direction="row">
          <Grid item style={{ width: '78%' }}>
            {successful ? (
              <Typography color="primary" style={{ fontSize: '90%' }}>
                {fileName}
              </Typography>
            ) : (
              <TextField
                required
                label="Name"
                variant="outlined"
                value={fileName}
                onChange={e => onTextInputChange(e, file)}
                disabled={!!errors.length || uploading}
                error={!!singleFileErrorMessage.length}
                style={{ width: '100%' }}
              />
            )}
          </Grid>
          <Grid item spacing={1}>
            {uploading ? (
              <CircularProgress />
            ) : successful ? (
              <CheckCircle style={{ color: '#4caf50' }} />
            ) : (
              <React.Fragment>
                {!!errors.length || (
                  <IconButton
                    color="primary"
                    disabled={!!singleFileErrorMessage.length}
                    onClick={() => onUpload(file, fileName)}>
                    <CloudUpload />
                  </IconButton>
                )}
                <IconButton onClick={() => onDelete(file)}>
                  <Delete color="error" />
                </IconButton>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      )}
      {!!singleFileErrorMessage.length &&
        singleFileErrorMessage.map(error => (
          <div key={error} style={{ marginTop: '0.5rem' }}>
            <Typography color="error" variant="caption">
              <Error
                style={{ marginBottom: '-0.4rem', marginRight: '0.3rem' }}
              />
              {error}
            </Typography>
          </div>
        ))}
    </Grid>
  )
}

export default React.memo(SingleFileUpload)
