import React, { useCallback } from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
// import AppointmentTopImg from '../../images/right-top-img3.png'
import CheckedInTopImg from '../../images/right-top-img2.png'
import virtualIcon from '../../images/virtual_icon.svg'
import ClinicIcon from '../../images/clinic_icon.svg'
import CheckedPatient from '../../images/checked_patient.svg'
import { Link } from 'react-router-dom'
import { DoctorStore } from '../../stores'
// import { Provider } from 'react-dnd/lib/cjs/DragDropContext'
// import UpcomingAppointments from '../Appointments/UpcomingAppointments'

export default function DayStatus({
  offlineLength,
  onlineLength,
  checkedInLength
}) {
  const classes = useStyles()

  const { upcomingType } = DoctorStore.useStoreState(state => ({
    ...state.appointments
  }))

  const { setTele, setInPerson } = DoctorStore.useStoreActions(actions => ({
    ...actions.appointments
  }))

  React.useEffect(() => {
    localStorage.removeItem('upcomingType')
  }, [])

  const teleClicked = () => {
    setTele()
    window.location.href = '/doctor/appointments'
  }

  const inPersonClicked = () => {
    setInPerson()
    window.location.href = '/doctor/appointments'
  }
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <div className={classes.todayAppointments}> */}
        {/* <Typography
            component="h4"
            style={{
              marginTop: '0px',
              fontFamily: 'Poppins',
              fontWeight: '600',
              borderBottom: '1px rgba(255,255,255,0.1) solid',
              marginBottom: '20px',
              paddingBottom: '10px'
            }}>
            Upcoming Appointments
          </Typography> */}
        {/* <Box className={classes.PatientSummary}> */}
        <Box
          className={classes.Online}
          style={{
            border: '1px #0D46C1 solid',
            borderRadius: 20,
            width: '32%',
            background: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: 30,
            height: 120,
            marginBottom: 25,
            boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);'
          }}>
          <img src={virtualIcon} alt="" style={{ marginRight: 30 }} />
          <Link onClick={teleClicked}>
            <p
              style={{
                fontSize: '18px',
                color: '#0D46C1',
                textAlign: 'left',
                marginTop: 0
              }}>
              virtual consultation
            </p>
            <Typography
              variant="h1"
              component="h3"
              style={{
                color: '#0D46C1',
                marginBottom: 0,
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: 30
              }}>
              {onlineLength}
            </Typography>
          </Link>
        </Box>
        <Box
          className={classes.offline}
          style={{
            border: '1px #FEB388 solid',
            borderRadius: 20,
            width: '32%',
            background: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: 30,
            height: 120,
            marginBottom: 25,
            boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);'
          }}>
          <img src={ClinicIcon} alt="" style={{ marginRight: 30 }} />
          <Link onClick={inPersonClicked}>
            <p
              style={{
                fontSize: '18px',
                color: '#FE9255',
                textAlign: 'left',
                marginTop: 0
              }}>
              In-Person-consult
            </p>
            <Typography
              variant="h1"
              component="h3"
              style={{
                color: '#FE9255',
                fontWeight: 'bold',
                textAlign: 'left',
                marginBottom: 0
              }}>
              {offlineLength}
            </Typography>
          </Link>
        </Box>
        {/* </Box> */}
        {/* </div> */}
        <div
          className={classes.checkedInPatients}
          style={{
            border: '1px #3E3BE8 solid',
            borderRadius: 20,
            width: '32%',
            display: 'flex',
            alignItems: 'center'
          }}>
          <img src={CheckedPatient} alt="" style={{ marginRight: 30 }} />
          <div>
            <p style={{ fontSize: 18, color: '#3E3BE8', textAlign: 'left' }}>
              Checked-In Patients
            </p>
            <h2
              style={{
                fontSize: 32,
                color: '#3E3BE8',
                textAlign: 'left',
                marginBottom: 0
              }}>
              {checkedInLength}
            </h2>
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  todayAppointments: {
    background: 'none center top no-repeat',
    width: '100%',
    padding: '15px 5px',
    backgroundSize: 'cover',
    height: '220px',
    borderRadius: '10px',
    marginTop: '0px',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    '& h4': {
      fontSize: '18px',
      fontWeight: '200',
      textAlign: 'center',
      color: '#fff',
      marginTop: '15px',
      marginBottom: '10px'
    },
    '& p': {
      fontSize: '18px',
      color: '#fff',
      marginTop: '0px',
      textAlign: 'center'
    },
    '& h2': {
      fontSize: '20px',
      color: '#fff',
      marginTop: '10px',
      fontWeight: '200',
      marginBottom: '15px',
      textAlign: 'center'
    }
  },
  Online: {
    width: '100%',
    textAlign: 'center',
    '& h3': {
      fontSize: '32px',
      color: '#fff',
      marginTop: '0px',
      fontWeight: '600'
    },
    '& p': {
      fontSize: '18px',
      marginBottom: '0px'
    }
  },
  offline: {
    width: '100%',
    textAlign: 'center',
    '& h3': {
      fontSize: '32px',
      color: '#fff',
      marginTop: '0px',
      fontWeight: '600'
    },
    '& p': {
      fontSize: '18px',
      marginBottom: '0px'
    }
  },
  PatientSummary: {
    width: '100%',
    display: 'flex'
  },
  checkedInPatients: {
    background: '#fff',
    // width: '100%',
    padding: '30px',
    backgroundSize: 'cover',
    height: '120px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    '& p': {
      fontSize: '18px',
      color: '#fff',
      fontFamily: 'Poppins',
      margin: 0
    },
    '& h2': {
      fontSize: '32px',
      color: '#fff',
      marginTop: '0px',
      fontWeight: '700',
      fontFamily: 'Poppins',
      marginBottom: '0'
    }
  }
}))
