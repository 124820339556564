import React, { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core'
import {
  Dashboard,
  MonetizationOn,
  BarChart,
  People,
  Business,
  Person,
  Info,
  AccountBalance,
  PermContactCalendar,
  ExitToApp
} from '@material-ui/icons'
import NavItem from './NavItem'

import { SuperClinicStore } from '../../../../stores'

import CardBgImg from '../../../../images/right-top-img3.png'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Clinic Admin',
  name: 'Care Medical Clinic'
}

const items = [
  {
    listTitle: 'Dashboard',
    listIcon: Dashboard,
    baseUrl: '/superclinic/dashboard/',
    sub: [
      {
        href: 'revenue',
        title: 'Revenue',
        icon: MonetizationOn
      },
      {
        href: 'clinicsanddoctors',
        title: 'Clinics & Doctors',
        icon: BarChart
      }
    ]
  },
  {
    href: '/superclinic/doctors',
    title: 'My Doctors',
    icon: People
  },
  {
    href: '/superclinic/myclinic',
    title: 'My Clinics',
    icon: Business
  },
  {
    listTitle: 'My Account',
    listIcon: Person,
    baseUrl: '/superclinic/myaccount/',
    sub: [
      {
        href: 'profileinfo',
        title: 'Profile Info',
        icon: Info
      },
      {
        href: 'bankdetails',
        title: 'Bank Details',
        icon: AccountBalance
      },
      {
        href: 'pocdetails',
        title: 'POC Details',
        icon: PermContactCalendar
      }
    ]
  },
  {
    href: '/superclinic/login',
    title: 'Logout',
    icon: ExitToApp
  }
]

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const location = useLocation()
  // const { getClinicProfileDetails } = SuperClinicStore.useStoreActions(actions => ({
  //   ...actions.profile
  // }))
  // const { profileDetails, networkStatus } = SuperClinicStore.useStoreState(
  //   state => ({
  //     ...state.profile
  //   })
  // )

  // useEffect(() => {
  //   getClinicProfileDetails({ cachedValues: true })
  // }, [])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          // src={profileDetails?.logo ? profileDetails?.logo : user.avatar}
          to="/superclinic/myaccount"
        />
        <Typography className={classes.name} variant="h6">
          {/* {profileDetails?.display_name
            ? profileDetails?.display_name
            : 'Loading...'} */}
        </Typography>
        <Typography className={classes.name} variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      {/* <Divider /> */}
      <nav style={{ marginTop: '10px' }}>
        <ul className={classes.listGroup}>
          {items.map((item, index) => (
            <li key={index} className={classes.listItem}>
              <NavItem {...item} />
            </li>
          ))}
        </ul>
      </nav>
      <Box flexGrow={1} />
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
}

export default NavBar

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256,
    background: `linear-gradient(45deg, #680fad, #063bc4)`,
    backgroundSize: 'cover'
  },
  desktopDrawer: {
    width: 256,
    top: 110,
    background: `linear-gradient(45deg, #680fad, #063bc4)`,
    backgroundSize: 'cover',
    height: 'calc(100% - 79px)'
  },
  name: {
    color: '#fff !important',
    marginTop: '10px'
  },
  avatar: {
    cursor: 'pointer',
    width: 84,
    height: 84,
    border: '3px solid #ececec'
  },
  listGroup: {
    listStyleType: 'none',
    padding: 0,
    margin: 0
  },
  fullList: {
    width: 'auto',
    background: `url(${CardBgImg}) center top no-repeat`,
    backgroundSize: 'cover'
  },
  listItem: { display: 'block', padding: 0, margin: 0 }
}))
