import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { ClinicStore } from '../../../../stores'
import Loader from '../../../components/ClinicLoader'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import { NETWORK_STATUS } from '../../../../Constants'
import NoRecordImg from '../../../../images/noRecordFound.png'

import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px',
    marginTop: '70px'
  }
}))

const Services = ({ className, doctors, appointmentId, ...rest }) => {
  const classes = useStyles()
  const [services, setServices] = useState([])
  const [servicesRecommended, setServicesRecommended] = useState([])
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')

  const { networkStatus, servicesList } = ClinicStore.useStoreState(state => ({
    ...state.appointment
  }))
  const {
    appointmentServicesList,
    appointmentServicesDelete,
    appointmentServicesMove,
    appointmentServicesPayment
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  useEffect(() => {
    appointmentServicesList({
      appointment_id: appointmentId
    })
  }, [])

  useEffect(() => {
    if (servicesList.length !== 0) {
      setServices(servicesList.filter(i => i.service_status === '1'))
      setServicesRecommended(servicesList.filter(i => i.service_status === '2'))
    }
  }, [servicesList])

  const moveService = item => {
    appointmentServicesMove({
      patient_service_id: item.patient_service_id
    }).then(data => {
      if (data) {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('success')
        appointmentServicesList({
          appointment_id: appointmentId
        })
      } else {
        setOpen(true)
        setSnackBarMessage('Service not moved')
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const deleteService = item => {
    appointmentServicesDelete({
      patient_service_id: item.patient_service_id
    }).then(data => {
      if (data) {
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('success')
        appointmentServicesList({
          appointment_id: appointmentId
        })
      } else {
        setOpen(true)
        setSnackBarMessage('Service not deleted')
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const handleChange = (event, item) => {
    let value = event.target.checked
    if (value) {
      appointmentServicesPayment({
        patient_service_id: item.patient_service_id
      }).then(data => {
        if (data) {
          setOpen(true)
          setSnackBarMessage(data.message)
          setmultiButton(false)
          setAlertType('success')
          appointmentServicesList({
            appointment_id: appointmentId
          })
        } else {
          setOpen(true)
          setSnackBarMessage('Payment not updated')
          setmultiButton(false)
          setAlertType('error')
        }
      })
    } else {
      setOpen(true)
      setSnackBarMessage('Cannot mark the payment as unpaid')
      setmultiButton(false)
      setAlertType('info')
    }
  }

  return (
    <>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={() => setOpen(false)}
      />
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Services Taken" />
        <Divider />
        <PerfectScrollbar style={{ overflow: 'auto' }}>
          <Box>
            <Table>
              <TableBody>
                {services.map(item => (
                  <TableRow hover>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.service_name}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.service_amount}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.service_payment_status === '1'
                        ? 'Payment Done'
                        : 'Payment Due'}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      Mark As Paid
                      <Checkbox
                        onChange={e => handleChange(e, item)}
                        checked={item.service_payment_status === '1'}
                        disabled={item.service_payment_status === '1'}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      <Button
                        onClick={() => deleteService(item)}
                        style={{
                          backgroundColor: '#111d4e',
                          color: '#fff',
                          margin: '0 10px'
                        }}>
                        DELETE
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {services.length == 0 ? (
              <img
                src={NoRecordImg}
                className={classes.notFoundImage}
                alt={'Not found!'}
              />
            ) : (
              ''
            )}
          </Box>
        </PerfectScrollbar>
      </Card>

      <Card
        className={clsx(classes.root, className)}
        {...rest}
        style={{ marginTop: '10px' }}>
        <CardHeader title="Recommended Services" />
        <Divider />
        <PerfectScrollbar style={{ overflow: 'auto' }}>
          <Box>
            <Table>
              <TableBody>
                {servicesRecommended.map(item => (
                  <TableRow hover>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.service_name}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.service_amount}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      Pending
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      Move To - Services Taken
                      <Button
                        onClick={() => moveService(item)}
                        style={{
                          backgroundColor: '#111d4e',
                          color: '#fff',
                          margin: '0 10px'
                        }}>
                        Move
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {services.length == 0 ? (
              <img
                src={NoRecordImg}
                className={classes.notFoundImage}
                alt={'Not found!'}
              />
            ) : (
              ''
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

Services.propTypes = {
  className: PropTypes.string
}

export default Services
