import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import Dialog from '@material-ui/core/Dialog'

const Loader = ({ open, label = '' }) => {
  const classes = useStyles()

  return (
    <>
      <Backdrop className={classes.backdrop} open={open}>
        {!!label ? (
          <Dialog open className={classes.dialogBox}>
            <div className={classes.dialogContent}>
              <CircularProgress color="inherit" />
              <div>{label}</div>
            </div>
          </Dialog>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Backdrop>
    </>
  )
}

const useStyles = makeStyles(() => ({
  backdrop: {
    position: 'fixed',
    zIndex: 1200,
    color: '#fff'
  },
  dialogBox: {
    position: 'fixed',
    zIndex: 9999,
  },
  dialogContent: {
    padding: '2rem',
    display: 'grid',
    justifyContent: 'center',
    gridAutoRows: 'min-content',
    gridAutoColumns: 'max-content',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gridColumnGap: '2rem',
    columnGap: '2rem',

    '& *': {
      display: 'block'
    }
  }
}))

export default Loader
