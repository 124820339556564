import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import api from '../../api'
import VideoChat from './VideoChat'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  VideoCol: {
    '& img': {
      width: '100%'
    }
  },

  VideoTab: {
    width: '98%',
    paddingLeft: '105px',
    position: 'relative',
    '& img': {
      width: '100%'
    },
    '@media (max-width:767px)': {
      width: '100%',
      padding: '0px'
    }
  },
  VideoCol: {
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  VideoDiv: {
    marginTop: '1rem',
    marginRight: '1rem'
  }
}))
export default function OnlineVideo(props) {
  const classes = useStyles()
  const [networkStatusText, setNetworkStatusText] = React.useState('')
  const [networkShowAlert, setNetworkShowAlert] = React.useState(false)
  const [token, setToken] = React.useState('')
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  React.useEffect(() => {
    startMeeting()
  }, [])

  async function startMeeting() {
    const response = await api.doctor.startAppointment({
      appointment_id: props.appointmentId
    })

    if (response.ok) {
      if (response.data.token) {
        setToken(response.data.token)
      } else {
        setOpenSnackBar(true)
        setSnackBarMessage(response?.data?.message)
        setmultiButton(false)
        setAlertType('info')
      }
    }
  }

  const endMeet = (roomClosed, forceStop, redirect) => {
    let confirmModal = forceStop ? false : true
    if (redirect) {
      setOpenSnackBar(true)
      setSnackBarMessage('Your appointment has ended')
      setmultiButton(false)
      setAlertType('success')
      this.pageNavigation = setTimeout(() => {
        window.location.href = '/doctor/dashboard'
      }, 5000)
    }
  }

  const displayNetworkText = networkStatusText => {
    if (!!networkStatusText) {
      setNetworkStatusText(networkStatusText)
      setNetworkShowAlert(true)
      setTimeout(() => {
        setNetworkShowAlert(false)
      }, 5000)
    } else {
      setNetworkShowAlert(false)
    }
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <div className={classes.root}>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      // secondaryClick={validationCheck}
      />
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Box className={classes.VideoTab}>
            <div className={classes.VideoDiv}>
              <VideoChat
                token={token}
                endSession={endMeet}
                displayNetworkText={displayNetworkText}
                isAudioCall={false}
                appointment={'appointment_details'} //need to pass appointment data
              // history={this.props.history}
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
