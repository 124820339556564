import { action, thunk } from 'easy-peasy'

import BaseModel from '../Base'
import { NETWORK_STATUS } from '../../Constants'
import api from '../../api'
import moment from 'moment'
import {
  PATIENT_USER_ID,
  PATIENT_ID,
  API_REQUEST_DATETIME_FORMAT
} from '../../Constants'
import Swal from 'sweetalert2'

// let count = 1;

export default {
  ...BaseModel(),
  patientsList: [],
  patientView: '',
  patientVitals: [],
  successResponse: false,
  patientVitalsHistory: [],
  medications: [],
  oldMedications: [],
  labs: [],
  documentList: [],
  getVisitSummariesData: [],
  viewSummary: [],
  MedicalList: [],
  patientVitalsGraph: [],
  medicationsFetchingStatus: false,
  lablist: [],
  labsFetchingStatus: false,
  documentPageNumber: 1,

  // for patients detail page view section
  getPatientView: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const { doctor_id, patient_id, appointment_id } = payload

      const response = await api.doctor.getPatientView({
        doctor_id: localStorage.getItem(PATIENT_USER_ID),
        patient_id: patient_id,
        appointment_id: appointment_id
      })

      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setPatientView(response.data.patient)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setPatientView: action((state, payload) => {
    state.patientView = payload
  }),

  // for patients detail page vitals section
  getPatientVitals: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.setPatientVitalsHistory([])
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      const {
        start_time,
        end_time,
        list_type,
        table_config_page_no,
        table_config_rows_per_page
      } = payload

      const response = await api.patient.getPatientVitals({
        doctor_id: '',
        patient_id: localStorage.getItem(PATIENT_ID),
        start_time: start_time,
        end_time: end_time,
        list_type: list_type,
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no
      })

      const latestHealthHubVitals = await new Promise(async resolve => {
        try {
          const resp = await fetch(
            'https://luzvk76flk.execute-api.ap-south-1.amazonaws.com/uat/sync-controller',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                action: 'SCAN_SYNC',
                carenowdoc_id: localStorage.getItem(PATIENT_ID),
                table_config_rows_per_page: 1,
                table_config_page_no: 1
              })
            }
          )
          const respJSON = await resp.json()
          if (respJSON.statusCode?.toString().startsWith('2')) {
            const result = respJSON.result?.[0]
            resolve({
              id: 'health-hub-latest-vitals-data',
              patient_id: localStorage.getItem(PATIENT_ID),
              temperature: '98.6',
              bmi_index: result?.physiological_scores?.bmi,
              sbp: result?.vitals?.bp_sys,
              dbp: result?.vitals?.bp_dia,
              heart_rate: result?.vitals?.heart_rate,
              respiratory_rate: result?.vitals?.resp_rate,
              oxygen_saturation_rate: result?.vitals?.oxy_sat_prcnt,
              datetime_recorded: result?.created_at,
              last_modified: result?.created_at,
              height: result?.physiological_scores?.height,
              weight: result?.physiological_scores?.weight
            })
          } else throw new Error(respJSON?.message)
        } catch (err) {
          console.error(
            'Failed to fetch latest vitals data from Health Hub',
            err
          )
          resolve(undefined)
        }
      })

      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        if (latestHealthHubVitals)
          response.data.vitals.unshift(latestHealthHubVitals)
        if (list_type === 'chart') {
          actions.setPatientVitals(response.data.vitals)
        }
        if (list_type === 'history') {
          actions.setPatientVitalsHistory(response.data.vitals)
        }
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  addPatientVitals: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const dateTime = moment().format(API_REQUEST_DATETIME_FORMAT)
    const endDate = moment().format(API_REQUEST_DATETIME_FORMAT)
    const startDate = moment()
      .subtract(60, 'days')
      .format(API_REQUEST_DATETIME_FORMAT)

    const {
      patient_id,
      temperature,
      bmi_index,
      sbp,
      dbp,
      heart_rate,
      respiratory_rate,
      oxygen_saturation_rate,
      height,
      weight,
      appointment_id
    } = payload

    const response = await api.patient.addPatientVitals({
      doctor_id: '',
      patient_id,
      datetime_recorded: dateTime,
      temperature: temperature.toString(),
      bmi_index,
      sbp: sbp.toString(),
      dbp: dbp.toString(),
      heart_rate: heart_rate.toString(),
      respiratory_rate: respiratory_rate.toString(),
      oxygen_saturation_rate: oxygen_saturation_rate.toString(),
      height: height.toString(),
      weight: weight.toString(),
      appointment_id
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.updatePatientVitals(response?.data)
      actions.getPatientVitals({
        patient_id,
        start_time: startDate,
        end_time: endDate,
        list_type: 'chart',
        table_config_rows_per_page: 20,
        table_config_page_no: 1
      })
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  updatePatientVitals: action((state, payload) => {
    state.updateVitals = payload
  }),

  setPatientVitals: action((state, payload) => {
    state.patientVitals = payload
  }),

  setSuccessResponse: action((state, payload) => {
    state.successResponse = payload
  }),

  setPatientVitalsHistory: action((state, payload) => {
    state.patientVitalsHistory = payload
  }),

  setMedications: action((state, payload) => {
    const medications = []
    const oldMedications = []
    payload &&
      payload.forEach(medication => {
        const momentEndDate = moment(medication.end_date, 'YYYY-MM-DD')
        if (moment().isAfter(momentEndDate)) oldMedications.push(medication)
        else medications.push(medication)
      })
    state.medications = medications
    state.oldMedications = oldMedications
  }),

  getPatientMedications: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { data } = await api.patient.getPatientMedications({
      doctor_id: '',
      patient_id: localStorage.getItem(PATIENT_ID),
      active: true,
      table_config_page_no: 1,
      table_config_rows_per_page: 10
    })
    actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    actions.setMedications(data?.medications)
  }),

  addMedications: thunk(async (actions, payload) => {
    const {
      patientId,
      drugName,
      frequency,
      duration,
      instructions,
      startDate,
      medication_id,
      others,
      durationString,
      medication_name_type
    } = payload

    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.addMedications({
      doctor_id: '',
      appointment_id: '',
      patient_id: localStorage.getItem(PATIENT_ID),
      drug_name: drugName,
      start_date: startDate,
      frequency,
      duration: duration * durationString,
      instructions,
      medication_id,
      others,
      medication_name_type: medication_name_type
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setAddMedicationResponse(response?.data)
      actions.getPatientMedications({ patientId })
    }
  }),

  setAddMedicationResponse: action((state, payload) => {
    state.addMedicationsResponse = payload
  }),
  editMedication: thunk(async (actions, payload) => {
    const {
      patientId,
      appointmentId,
      drugName,
      frequency,
      duration,
      instructions,
      startDate,
      medication_id,
      others,
      id,
      durationString,
      medication_name_type
    } = payload

    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.updateMedication({
      doctor_id: '',
      patient_id: localStorage.getItem(PATIENT_ID),
      appointment_id: appointmentId,
      drug_name: drugName,
      start_date: startDate,
      medication_id: medication_id ? medication_id.toString() : '',
      others: payload.others ? others.toString() : '',
      id: id,
      frequency,
      duration: duration * durationString,
      instructions,
      medication_name_type: medication_name_type
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setEditMedicationResponse(response?.data)
      actions.getPatientMedications({ patientId })
    }
  }),
  setEditMedicationResponse: action((state, payload) => {
    state.editMedicationsResponse = payload
  }),
  deleteMedication: thunk(async (actions, payload) => {
    const { medicationId, patientId } = payload

    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.deleteMedication({
      medication_id: medicationId,
      // doctor_id: localStorage.getItem(PATIENT_USER_ID),
      patient_id: localStorage.getItem(PATIENT_ID)
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDeletedMedicationResponse(response?.data)
      actions.getPatientMedications({ patientId })
    }
  }),
  setDeletedMedicationResponse: action((state, payload) => {
    state.deleteMedicationsResponse = payload
  }),
  addPatientDocument: thunk(async (actions, payload) => {
    const { result, doc_name, type, labRecords, instruct } = payload

    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.patient.addPatientDocument({
      patient_id: localStorage.getItem(PATIENT_ID),
      user_id: localStorage.getItem(PATIENT_USER_ID),
      document_status: '1',
      type_of_document: type,
      document: result,
      doc_name: doc_name,

      testname: labRecords,
      instructions: instruct
    })

    actions.getDocuments({})

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDocumentResponse(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDocumentResponse: action((state, payload) => {
    state.addPatientDocumentResponse = payload
  }),

  editPatientDocument: thunk(async (actions, payload) => {
    const { result, doc_name, type, document_id, document_uri, instruct } =
      payload

    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.editPatientDocument({
      patient_id: localStorage.getItem(PATIENT_ID),
      document_id: document_id,
      document_uri: document_uri,
      user_id: localStorage.getItem(PATIENT_USER_ID),
      document_status: '1',
      type_of_document: type,
      document: result,
      doc_name: doc_name,
      instructions: instruct,
      testname: doc_name
    })

    actions.getDocuments({})
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setEditDocumentResponse(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setEditDocumentResponse: action((state, payload) => {
    state.editDocumentResponse = payload
  }),

  deletePatientDocument: thunk(async (actions, payload) => {
    const { selectedDoc } = payload

    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.deletePatientDocument({
      patient_id: localStorage.getItem(PATIENT_ID),
      doc_id: selectedDoc
    })
    actions.getDocuments({})

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDeleteDocumentResponse(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setDeleteDocumentResponse: action((state, payload) => {
    state.deleteDocumentResponse = payload
  }),

  getLab: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { data } = await api.patient.getLab({
      doctor_id: '',
      patient_id: localStorage.getItem(PATIENT_ID), //params.patient_id
      table_config_rows_per_page: '25',
      table_config_page_no: '1',
      type_of_document: 'lab',
      list_type: 'labs'
    })

    actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

    actions.setLabs(data?.response || [])
  }),

  getDocuments: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    actions.setDocuments([])
    const { data } = await api.patient.getLab({
      doctor_id: '',
      patient_id: localStorage.getItem(PATIENT_ID),
      table_config_rows_per_page: '10',
      table_config_page_no: payload?.pageNumber ? payload?.pageNumber : '1',
      type_of_document: 'all',
      list_type: 'documents'
    })

    actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    actions.setDocuments(data?.response || [])
    actions.setDocumentPageNumber(data?.total_pages_count || 1)
  }),
  setDocumentPageNumber: action((state, payload) => {
    state.documentPageNumber = payload
  }),
  setLabs: action((state, payload) => {
    state.labs = payload
  }),
  setDocuments: action((state, payload) => {
    let count = 1
    payload.forEach(item => {
      if (item.type_of_document == 2) {
        item.testname = `Document ${count}`
        count++
      }
    })
    state.documentList = payload
  }),
  addLab: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const {
      patientId,
      report,
      reportName,
      type,
      prescribedOn,
      testName,
      instructions,
      appointmentId
    } = payload

    const response = await api.patient.addLab({
      patient_id: localStorage.getItem(PATIENT_ID),
      report: report || '',
      report_name: reportName || '',
      report_status: reportName ? '2' : '1',
      // prescribed_on: prescribedOn,
      doctor_id: '',
      others: testName ? testName : '',
      lab_master_id: type !== 'others' ? type : '',
      instructions: instructions,
      type_of_document: 'lab',
      appointment_id: appointmentId
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setLabResponse(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }

    actions.getLab({ patientId })
    actions.setLabs(response.data.labs || [])
  }),
  setLabResponse: action((state, payload) => {
    state.addLabDataResponse = payload
  }),
  editLab: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const {
      patientId,
      report,
      reportName,
      prescribedOn,
      testName,
      type,
      instructions,
      labId,
      documentFetch,
      documentId
    } = payload

    const response = await api.patient.editLab({
      patient_id: patientId,
      report: report || '',
      report_name: reportName || '',
      report_status: reportName ? '2' : '1',
      prescribed_on: prescribedOn,
      doctor_id: localStorage.getItem(PATIENT_USER_ID),
      others: testName ? testName : '',
      lab_master_id: type !== 'others' && type !== 'initial' ? type : '',
      lab_id: labId,
      instructions: instructions,
      document_id: documentId,
      type_of_document: 'lab'
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setEditLabResponse(response?.data.statusCode)
      // console.log('Response test', response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }

    actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    if (documentFetch && report) {
      actions.getDocuments({})
    } else {
      actions.getLab({ patientId })
      actions.setLabs(response.data.labs || [])
    }
  }),
  setEditLabResponse: action((state, payload) => {
    state.editLabDataResponse = payload
  }),
  deleteLab: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    // console.log(payload)

    const { labId } = payload
    const patientId = localStorage.getItem('patient_id')

    const response = await api.patient.deleteLab({
      lab_id: labId,
      doctor_id: '',
      patient_id: patientId
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDeleteLabResponse(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
    actions.getLab({ patientId })
  }),
  setDeleteLabResponse: action((state, payload) => {
    state.deleteLabDataResponse = payload
  }),

  getVisitSummaries: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const {
      patient_id,
      table_config_rows_per_page,
      table_config_page_no,
      user_id,
      view_type
    } = payload
    const response = await api.patient.getVisitSummaries({
      patient_id: patient_id ? patient_id : '',
      table_config_rows_per_page: table_config_rows_per_page
        ? table_config_rows_per_page
        : '',
      table_config_page_no: table_config_page_no ? table_config_page_no : '',
      user_id: user_id ? user_id : '',
      view_type: view_type
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setGetVisitSummariesData(
        response && response.data && response.data.response
      )
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setGetVisitSummariesData: action((state, payload) => {
    state.getVisitSummariesData = payload
  }),
  viewVisitSummary: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.patient.getVisitSummaries({
      appointment_id: payload.appointment_id,
      patient_id: localStorage.getItem(PATIENT_ID),
      user_id: payload.user_id,
      view_type: payload.view_type
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setViewSummaries(
        response && response.data && response.data.response
      )
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setViewSummaries: action((state, payload) => {
    state.viewSummary = payload
  }),

  getMedicalSearch: thunk(async (actions, payload) => {
    actions.setMedicationsFetchingStatus(true)
    const response = await api.patient.getMedicalSearchData({
      pattern: payload && payload.searchString,
      table_config_page_no: payload && payload.table_config_page_no,
      table_config_rows_per_page: payload && payload.table_config_rows_per_page,
      medication_name_type: payload?.medication_name_type
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setMedicalSearch(response?.data?.response)
      actions.setMedicationsFetchingStatus(false)
    } else {
      actions.setMedicationsFetchingStatus(false)
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setMedicalSearch: action((state, payload) => {
    if (payload == undefined) {
      //
    } else {
      let sortedPayload = payload.sort((a, b) => a.type.localeCompare(b.type))
      // console.log(sortedPayload, 'SORTED PAYLOAD')
      state.MedicalList = sortedPayload
    }
  }),
  setMedicationsFetchingStatus: action((state, payload) => {
    state.medicationsFetchingStatus = payload
  }),
  getPatientGraphDetails: thunk(async (actions, payload) => {
    actions.setPatientVitalsGraph([])
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { patient_id, type } = payload

    const response = await api.patient.getPatientGraphDetails({
      patient_id: patient_id,
      type: type
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setPatientVitalsGraph(response.data.vitals)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setPatientVitalsGraph: action((state, payload) => {
    state.patientVitalsGraph = payload
  }),
  getLabRecordsNames: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.setLabListFetching(true)
      const response = await api.patient.getLabTypes({
        table_config_rows_per_page: '10',
        table_config_page_no: '1',
        table_config_search_string: payload?.table_config_search_string
          ? payload?.table_config_search_string
          : ''
      })
      if (response?.data?.statusCode === 201) {
        actions.setLabList(response?.data)
        actions.setLabListFetching(false)
      } else {
        actions.setLabListFetching(false)
      }
    }
  ),
  setLabList: action((state, payload) => {
    state.lablist = payload?.data
  }),
  setLabListFetching: action((state, payload) => {
    state.labsFetchingStatus = payload
  })
}
