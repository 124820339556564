import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'

const Card = ({
  heading,
  children,
  headingProps = {},
  contentProps = {},
  className = '',
  ...props
}) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.root, className)} {...props}>
      {heading && (
        <Typography
          component='h2'
          className={classNames(classes.heading, headingProps.className)}
          {...headingProps}
        >
          {heading}
        </Typography>
      )}
      <div
        className={classNames(classes.content, contentProps.className)}
        {...contentProps}
      >
        {children}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '15px',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);'
  },
  heading: {
    fontSize: '18px',
    fontWeight: '600',
    marginTop: '5px',
    marginBottom: '20px',
    color: theme.palette.dark
  },
  content: {}
}))

export default Card
