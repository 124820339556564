import React from 'react'
import { TableCell, TableRow, makeStyles } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

const DoctorListItem = ({
  doctorName,
  specialization,
  mobileNo,
  email,
  clinicName,
  clinicAddress,
  handleViewDoctorModalOpen = () => {}
}) => {
  const classes = useStyles()

  return (
    <TableRow hover>
      <TableCell style={{ wordBreak: 'break-word' }}>{doctorName}</TableCell>
      <TableCell style={{ wordBreak: 'break-word' }}>
        {specialization}
      </TableCell>
      <TableCell style={{ wordBreak: 'break-word' }}>{mobileNo}</TableCell>
      <TableCell style={{ wordBreak: 'break-word' }}>{email}</TableCell>
      <TableCell style={{ wordBreak: 'break-word' }}>{clinicName}</TableCell>
      <TableCell style={{ wordBreak: 'break-word' }}>{clinicAddress}</TableCell>
      <TableCell style={{ wordBreak: 'break-word' }}>
        <button
          className="view action-btn"
          style={{
            background: 'none',
            color: 'rgb(147, 148, 154)'
          }}
          onClick={handleViewDoctorModalOpen}>
          <VisibilityIcon fontSize="small" className={classes.icon} />
        </button>
      </TableCell>
    </TableRow>
  )
}

export default DoctorListItem

const useStyles = makeStyles(theme => ({
  icon: {
    color: 'currentColor !important',
    fill: 'currentColor !important'
  }
}))
