import React, { useEffect, useState, useRef } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Avatar } from '@material-ui/core'
import localforage from 'localforage'
import { USER_ID, SPECIAL_CHAR, VALIDATE_WHITESPACES } from '../../../../Constants'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import MuiAlert from '@material-ui/lab/Alert'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { ClinicStore } from '../../../../stores'


function rand() {
  return Math.round(Math.random() * 20) - 10
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

const singleSpaceRegex = /^(\w+\s)*\w+$/

const Bankdetails = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [accVisibility, setAccVisibility] = React.useState(false)

  const {
    doctorBankDetails,
    subscriptionPlanList
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const { docbankdetailsAddedStatus } = ClinicStore.useStoreState(state => ({
    ...state.profile
  }))

  useEffect(() => {
    if (
      docbankdetailsAddedStatus === undefined ||
      docbankdetailsAddedStatus === ''
    ) {
      //
    } else if (
      docbankdetailsAddedStatus.statusCode === 400 ||
      docbankdetailsAddedStatus.statusCode === 533
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage(
        docbankdetailsAddedStatus.message.replace(/['"]+/g, '')
      )
      // setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [docbankdetailsAddedStatus])

  const submit = async values => {
    doctorBankDetails({
      ...values
    })

    subscriptionPlanList({
      subscription_plan: 'premium extension'
    })
  }

  const formik = useFormik({
    initialValues: {
      account_no: '',
      cnfrmaccount_no: '',
      bank_name: '',
      branch_name: '',
      ifsc_code: '',
      beneficiary_name: ''
    },

    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  const Input = e => {
    e.preventDefault()
    setOpenSnackBar(true)
    setSnackBarMessage('Paste option disabled...')
    setmultiButton(false)
    setAlertType('info')
  }

  const goBackClicked = () => {
    // console.log('Go to previous step!')
    props.back()
  }

  return (
    <div style={{ padding: '40px' }}>
      {/* <Box border={1} style={{ boxShadow: '5px 10px #3f4b79' }}> */}
      <Paper elevation={3} className={classes.paper}>
        {/* {docbankdetailsAddedStatus ===
        undefined ? null : docbankdetailsAddedStatus.statusCode === 400 ||
          docbankdetailsAddedStatus.statusCode === 533 ? (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'bottom' }}>
            <Alert onClose={handleClose} severity="error">
              {docbankdetailsAddedStatus.message}
            </Alert>
          </Snackbar>
        ) : null} */}

        <SnackbarAlert
          isOpen={isSnackBarOpen}
          message={snackBarMessage}
          alertType={alertType}
          multibutton={multiButton}
          primaryClick={hideSnackBar}
        />

        <form
          onSubmit={formik.handleSubmit}
          noValidate={true}
          className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <TextField
                    id="account_no"
                    style={{ width: '100%' }}
                    // type="password"
                    type={accVisibility == true ? 'text' : 'password'}
                    placeholder="Account No"
                    onChange={formik.handleChange}
                    value={formik && formik.values && formik.values.account_no}
                    onBlur={formik.handleBlur}
                    name="account_no"
                    label="Account No *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.account_no && formik.errors.account_no
                          ? formik.errors.account_no
                          : null}
                      </div>
                    }
                    InputProps={{
                      endAdornment: (
                        <VisibilityIcon
                          onClick={() => setAccVisibility(!accVisibility)}
                          className={classes.viewIcon}
                        />
                      )
                    }}
                  />
                  <TextField
                    id="cnfrmaccount_no"
                    style={{ width: '100%' }}
                    placeholder="Confirm Account No"
                    name="cnfrmaccount_no"
                    label="Confirm Account No *"
                    onPaste={Input}
                    type="password"
                    value={
                      formik && formik.values && formik.values.cnfrmaccount_no
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.cnfrmaccount_no &&
                        formik.errors.cnfrmaccount_no
                          ? formik.errors.cnfrmaccount_no
                          : null}
                      </div>
                    }
                  />
                  <TextField
                    id="bank_name"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Bank Name"
                    label="Bank Name *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.bank_name}
                    name="bank_name"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.bank_name && formik.errors.bank_name
                          ? formik.errors.bank_name
                          : null}
                      </div>
                    }
                  />
                  <TextField
                    id="branch_name"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="Branch Name "
                    label="Branch Name  *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.branch_name}
                    name="branch_name"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.branch_name && formik.errors.branch_name
                          ? formik.errors.branch_name
                          : null}
                      </div>
                    }
                  />
                </Grid>
                {/* <Grid item xs={1} /> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="ifsc_code"
                style={{ width: '95%' }}
                type="text"
                placeholder="IFSC Code"
                value={formik && formik.values && formik.values.ifsc_code}
                name="ifsc_code"
                label="IFSC Code *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // disabled={true}
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.ifsc_code && formik.errors.ifsc_code
                      ? formik.errors.ifsc_code
                      : null}
                  </div>
                }
              />
              <TextField
                id="beneficiary_name"
                style={{ width: '95%' }}
                placeholder="Beneficiary Name"
                name="beneficiary_name"
                label="Beneficiary Name *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                  formik && formik.values && formik.values.beneficiary_name
                }
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.beneficiary_name &&
                    formik.errors.beneficiary_name
                      ? formik.errors.beneficiary_name
                      : null}
                  </div>
                }
              />
              <Button className={classes.backBtn} onClick={goBackClicked}>
                Go Back
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <button
                type={'submit'}
                ref={ref}
                style={{
                  display: 'none'
                }}>
                Save
              </button>
            </Grid>
          </Grid>
        </form>
        {/* </Box> */}
      </Paper>
    </div>
  )
})

const validationSchema = yup.object().shape({
  account_no: yup
    .string()
    .required('Account No is required')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .min(9, 'Account number should be between 9 to 18 digits')
    .max(18, 'Account number should be between 9 to 18 digits')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),
  cnfrmaccount_no: yup
    .string()
    .required('Confirm Account No is required')
    .oneOf([yup.ref('account_no'), null], 'Confirm Account No must match')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .min(9, 'Account number should be between 9 to 18 digits')
    .max(18, 'Account number should be between 9 to 18 digits')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces'),

  bank_name: yup
    .string()
    .required('Bank Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(50, 'Character exceeded the limit!')
    // .matches(SPECIAL_CHAR, 'Special Characters are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces'),

  branch_name: yup
    .string()
    .max(50, 'Character exceeded the limit!')
    .required('Branch Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces'),

  ifsc_code: yup
    .string()
    .max(11, 'IFSC_Code must be 11 digit code!')
    .required('IFSC Code is required')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces'),

  beneficiary_name: yup
    .string()
    .max(50, 'Character exceeded the limit!')
    .required('Beneficiary Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(VALIDATE_WHITESPACES, 'Remove extra spaces')
    .matches(singleSpaceRegex, 'Remove extra spaces')
})

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },

  errMsg: {
    color: 'red'
  },

  paper: {
    padding: '10px',
    '@media (max-width:767px)': {
      marginTop: '40px'
    }
  },
  viewIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  backBtn: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#e4e4e4',
    float: 'right',
    '&:hover': {
      backgroundColor: '#111d4e',
      color: '#e4e4e4'
    }
  }
}))

export default Bankdetails
