import { action, thunk, computed } from 'easy-peasy'

import BaseModel from '../Base'
import { NETWORK_STATUS } from '../../Constants'
import api from '../../api'

export default {
  ...BaseModel(),

  servicesList: [],
  totalPagesCount: 1,

  getClinicServices: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })
      const response = await api.clinic.fetchServicesList({
        table_config_rows_per_page: 10,
        table_config_page_no: payload?.current_page ? payload?.current_page : 1,
        pattern: ''
      })
      if (response.data.status == '200') {
        actions.fetchedServices(response.data.response)
        actions.setTotalPagesCount(response.data.total_pages_count)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  fetchedServices: action((state, payload) => {
    if (payload) {
      state.servicesList = payload
    }
  }),
  setTotalPagesCount: action((state, payload) => {
    if (payload) {
      state.totalPagesCount = payload
    }
  }),

  addClinicServices: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })
      const response = await api.clinic.clinicServicesAdd({
        cost: payload?.cost,
        description: payload?.service_description,
        service_name: payload?.service_name
      })
      if (response.data.statusCode == '200') {
        actions.fetchedServices(response.data.response)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return response.data
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILURE)
        return response.data
      }
    }
  ),

  editClinicServices: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })
      const response = await api.clinic.clinicServicesEdit({
        cost: payload?.cost,
        service_id: payload?.service_id,
        description: payload?.service_description,
        service_name: payload?.service_name
      })
      if (response.data.statusCode == '200') {
        actions.fetchedServices(response.data.response)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return response.data
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        return response.data
      }
    }
  ),

  deleteClinicServices: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })

      const response = await api.clinic.clinicServicesDelete({
        service_id: payload?.id
      })
      if (response.data.statusCode == '200') {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.fetchedServices(response.data.response)
        return response.data
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        return response.data
      }
    }
  ),

  clinicServicesUpdateStatus: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })

      let status = payload?.status == '2' ? '1' : '2'
      const response = await api.clinic.clinicServicesUpdateStatus({
        service_id: payload?.id,
        status: status
      })
      if (response.data.statusCode == '200') {
        actions.fetchedServices(response.data.response)
        return response.data
      } else {
        return response.data
      }
    }
  )
}
