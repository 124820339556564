import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Paper, Grid, Box, Link } from '@material-ui/core'
import { DashboardLayout } from '../doctorLayouts'
import 'react-multi-carousel/lib/styles.css'
import Blogdetailsimg from '../images/blog-details.jpg'
import Blogitem1 from '../images/image1.jpg'
import Blogitem2 from '../images/image2.jpg'
import Blogitem3 from '../images/image31.jpg'

export default function BlogDetails() {
  const classes = useStyles()

  return (
    <DashboardLayout>
      <Box className={classes.aside}>
        <Paper elevation={3} className={classes.paper}>
          <BlogDetailsMR />
        </Paper>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles(theme => ({
  Profilecard: {
    width: '100%',
    padding: '10px 30px',
    marginTop: '40px',
    '& h2': {
      marginBottom: '25px',
      fontWeight: 'bold',
      marginTop: '15px'
    }
  },
  VirtualBox: {
    background: '#535f8f',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '25px',
    '& img': {
      width: '100%'
    }
  },
  VirtualGrey: {
    background: '#b7d419',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '25px',
    '& img': {
      width: '100%'
    }
  },
  VirtualRight: {
    padding: '0 5px',

    '& h3': {
      fontSize: '25px',
      color: '#fff',
      marginTop: '0',
      fontWeight: 'bold',

      '@media (max-width:767px)': {
        fontSize: '18px'
      }
    },
    '& p': {
      fontSize: '16px',
      color: '#fff',
      fontWeight: 'normal',
      '@media (max-width:767px)': {
        fontSize: '14px'
      }
    },
    '@media (max-width:767px)': {
      padding: '0 0'
    }
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box style={{ marginTop: '15px' }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const BlogDetailsMR = () => {
  const classes = useStyles()
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  return (
    <div>
      <Box className={classes.Profilecard}>
        <Box className="bodycontent">
          <Box className="OurDoctor">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8}>
                <Box className="Blogdetails">
                  <h1>
                    TeleHealth Services Rapid Response to Coronavirus Pandemic
                  </h1>
                  <div className="ttm-post-entry-header">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">
                        <i class="fa fa-user"></i>By Admin
                      </span>
                      <span className="ttm-meta-line entry-date">
                        <i className="fa fa-calendar"></i>
                        <time
                          className="entry-date published"
                          datetime="2018-07-28T00:39:29+00:00">
                          July 28, 2018
                        </time>
                      </span>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                    commodo viverra maecenas accumsan lacus vel facilisis.Lorem
                    Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown specimen book.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                    commodo viverra maecenas accumsan lacus vel facilisis.Lorem
                    Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown specimen book.
                  </p>
                  <img
                    className={classes.blog}
                    src={Blogdetailsimg}
                    alt="blogdetails"
                  />
                  <h2>Chat With Your Care Team Anytime You Need to</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                    commodo viverra maecenas accumsan lacus vel facilisis.Lorem
                    Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown specimen book.
                  </p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box className="blogsidebar">
                  <section className="widget widget_tinzer_posts_thumb">
                    <h3 className="widget-title">Recent Posts</h3>
                    <article className="item">
                      <Link href="#" className="thumb">
                        <span className="fullimage cover bg1" role="img">
                          <img
                            className={classes.blogimg}
                            src={Blogitem1}
                            alt="blog"
                          />
                        </span>
                      </Link>
                      <div className="info">
                        <time className="2020-06-30">30 January</time>
                        <h4 className="title usmall">
                          <a href="index.html">Ensure at the Hygenic office</a>
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </article>

                    <article className="item">
                      <Link href="#" className="thumb">
                        <span className="fullimage cover bg2" role="img">
                          <img
                            className={classes.blogimg}
                            src={Blogitem2}
                            alt="blog"
                          />
                        </span>
                      </Link>
                      <div className="info">
                        <time className="2020-06-30">17 May</time>
                        <h4 className="title usmall">
                          <a href="index.html">Aliqua tuatorn grate hjyrdre</a>
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </article>

                    <article className="item">
                      <Link href="#" class="thumb">
                        <span className="fullimage cover bg3" role="img">
                          <img
                            className={classes.blogimg}
                            src={Blogitem3}
                            alt="blog"
                          />
                        </span>
                      </Link>
                      <div className="info">
                        <time className="2020-06-30">18 March</time>
                        <h4 className="title usmall">
                          <a href="index.html">How to protect from Germ</a>
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </p>
                      </div>
                    </article>
                  </section>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
