import React from 'react'
import { DashboardLayout } from '../../patientLayouts'
import PatientInsights from '../../components/PatientInsights/PatientInsights'

const PatientDetailsPage = () => {
  return (
    <DashboardLayout>
      <PatientInsights />
    </DashboardLayout>
  )
}

export default PatientDetailsPage
