import React, { useState } from 'react'
import { Box, Grid, InputAdornment } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Toplogo from '../images/logo-icon.png'
import Searchicon from '../images/search-icon.png'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import ListBgImg from '../images/7879.png'

const faqs = [
  {
    title: 'What is CARENOW Platform?',
    description: `CARENOW HEALTHCARE is the integration of applications and technologies to provide a customized, end-to-end, Healthcare solution. The digital customer's desire to access healthcare information and changes to this information delivery are revolutionizing how healthcare functions today.`
  },
  {
    title: 'How can I access CARENOW?',
    description: `Carenow healthcare will be available across computers, smartphones and tablets.`
  },
  {
    title: 'How will patients book an appointment with me?',
    description: `Carenow Healthcare should be used when your patients are looking for non-critical care, a follow up or second opinion, reviewing medical reports, initial treatments etc. While Carenow Healthcare is not intended to replace your regular doctor or specialist for common or chronic conditions, an online consultation can sometimes substitute a doctor’s visit. But Here you can monitor clinic’s visit patients as well.`
  },
  {
    title: 'Is the patient health data secure?',
    description: `Carenow Healthcare is compliant with HIPAA (Health Insurance Portability and Accountability Act) guidelines. Health records are kept totally secure and only shared between the doctor and the patient. There is nothing more important to us than keeping your data secure.`
  },
  {
    title: 'What happens if my Internet speed fluctuates in between the consultation?',
    description: `Carenow Healthcare is all about making your healthcare experience better....Our systems will automatically detect the drop in your Internet bandwidth and turn off the video off. The video session will be restored when the network is back to the required speed. In such a scenario, the video will be turned off and the consultation will move to an audio only mode. The video will resume once the bandwidth has been restored. `
  },
  {
    title: 'What do I do if I can’t be available during the booked appointment?',
    description: `We understand that sometimes treatments/procedures can go beyond schedule. Incase you are not able to attend the appointment, you have the option to recommend another time to the patient and if accepted by the patient, the appointment will be rescheduled else the full payment will be refunded back to the customer. `
  },
  {
    title: 'I’m already on another health portal, then why should I be on yours?',
    description: `Yes we understand. We are an online health telemedicine solution which allows patients and doctors to stay connected with each other via video. We offer a holistic healthcare solution than a free appointment booking website. As well as there is an unique feature which we are providing is an OFFLINE consultation, i.e. Clinic Visit, Patients can book an online appointment with our platform & then he / she can visit the clinic for consultation as per their selected slots. Even patients can pay the fees via online payment option as well as clinic visits also.`
  },
  {
    title: 'I am not very technology savvy so I can’t be able to upload all the required information in your CareNow vital platform…what do I do?',
    description: ` Yes we understand. We are an online health telemedicine solution which allows patients and doctors to stay connected with each other via video. We offer a holistic healthcare solution than a free appointment booking website.`
  },
  {
    title: 'Can I upload a photographed version of the Prescription written on my prescription’s notepad before ending the appointment?',
    description: ` The prescription format generated via the system is as per the published guidelines of the Medical Council.`
  },
  {
    title: 'Can we have an option to extend the consultation time in slots of 5/10/15min during the consultation for specific patients?',
    description: ` The duration of the consultation is decided by the doctor. Currently we have provided options to configure 15, 30 and 60 min. In case the ongoing consultation is overlapping the next patient's schedule, the doctor will be intimated by the system. It is upto the doctor on how long they would like to extend the consultation. The consultation can be ended only by the doctor. `
  },
  {
    title: 'How will I generate the report that how many consultations were done in a month from your platform?',
    description: `For a time being, the Generating report option is not there, But Once the Doctor will login with their credential, the “Appointment List” option  helps you track, monitor and analyse the appointment booking trend at your practice. Doctors can check / track the list of Upcoming, Past & Reschedule Appointment lists including In-person & Tele Patients as well.`
  },
  {
    title: 'If I don’t use the application or no patient has booked an appointment from your platform after payment the monthly subscription. Will the subscription amount be carried forward to next month?',
    description: ` No, We cannot carry forward the subscription as we are providing a package & it depends on the doctor how the doctor will connect with the patient via our platform,Either the doctor can inform the patient to book online for in-person or Tele consultation. That all depends upon the doctor’s availability.`
  },
  {
    title: 'I have my own website. Can you integrate this feature in my website?',
    description: `Yes Our Technical Team is providing the same with an extra charge.`
  },
  {
    title: 'Can I be logged into multiple devices at the same time?',
    description: `Yes, You can log in your credentials into multiple devices at the same time.`
  },
  {
    title: 'What is the use of Carenow sketch?',
    description: `Carenow Healthcare is the practice of providing clinical care to patients at a distance using telecommunications technology. In other words, it is a method of treating patients using the internet and telephone. This can take the form of real-time video visits, secure email, or remotely monitoring a patient’s vital signs.`
  }
]

export default function FAQ() {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const [activeFaqs, setFaqs] = useState(faqs)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const search = e => {
    e.preventDefault()

    setFaqs(
      e.target.value.length == 0
        ? faqs
        : faqs.filter(
            item =>
              JSON.stringify(item)
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) >= 0
          )
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.Topheader}>
        <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
            <Link to="/"><img className={classes.logo} src={Toplogo} alt="banner" /></Link>
            </Grid>
          <Grid item xs={6} sm={9}>
            {/* <Link to="/doctor/dashboard"> */}
     
              <Box className={classes.Topcol}>
                <button onClick={()=> window.history.go(-1)} className={classes.backbtn}>Back</button>
              </Box>

          </Grid>
        </Grid>
      </div>

      <Box className={classes.body}>
        <div className={classes.top}>
          <div className={classes.searchCol}>
            <h3>How can we help you?</h3>
            <TextField
              // id="outlined-basic"
              // className="formcontrol"
              label="Describe your issue"
              variant="outlined"
              onChange={search}
              className={classes.searchBar}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>

          <h2>Frequently Asked Questions by DOCTORS</h2>

          {activeFaqs.map((item, index) => (
            <>
              <Accordion
                className={classes.accordioncol}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}>
                <AccordionSummary
                  className={classes.accordiontab}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header">
                  <Typography className={classes.heading}>
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.faqdetails}>
                  <p>{item.description}</p>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
          {activeFaqs.length == 0 ? (
            <div className={classes.undefined}>
              <strong>Enter a relevant search topic...</strong>
            </div>
          ) : null}
        </div>
      </Box>
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
      '@media (max-width:767px)': {
        width: '100% !important'
      }
    }
  },
  body: {
    background: `url(${ListBgImg}) center top no-repeat`,
    backgroundSize: 'contain',
    padding: theme.spacing(15),
    '@media (max-width:767px)': {
       padding: theme.spacing(5),
    },
  },
 
  accordiontab: {
    borderRadius: '0px'
  },
  Topcol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '82px'
  },
  backbtn: {
    cursor: 'pointer',
    background: '#3f4d81',
    padding: '0 20px',
    borderRadius: '50px',
    color: '#fff',
    width: '80px',
    height: '35px',
    textAlign: 'center',
    lineHeight: '35px',
    fontSize: '14px',
    fontWeight: '600',
    border: 'none'
  },
  accordioncol: {
    marginBottom: '15px'
  },
  Topheader: {
    padding: '10px 3% 0',
    background: '#fff'
  },
  faqdetails: {
    background: '#e9f1fe',
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'left'
    }
  },
  image: {
    width: '100%'
  },
  logo: {
    cursor: 'pointer',
    height: '80px'
  },
  doctorimg: {
    width: '100%'
  },
  Navbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  searchBar: {
    width: '100% !important',
    '& .MuiInputBase-input:focus': {
      border: 'none'
    }
  },
  searchCol: {
    width: '50%',
    margin: '0px auto',
    position: 'relative',
    '@media (max-width:767px)': {
      width: '100%',
    },
    '& h3': {
      fontSize: '25px',
      textAlign: 'center',
      color: '#111d4e'
    }
  },
  undefined: {
    textAlign: 'center',
    fontSize: '20px'
  }
}))
