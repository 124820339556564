import {
  isNullOrUndefined,
  append,
  createElement,
  isBlazor,
  addClass
} from 'cn_scheduler/base'
import { updateBlazorTemplate, resetBlazorTemplate } from 'cn_scheduler/base'
import { Tooltip } from 'cn_scheduler/popups'
import * as cls from '../base/css-constant'
import * as util from '../base/util'
/**
 * Tooltip for Schedule
 */
var EventTooltip = /** @class */ (function () {
  function EventTooltip(parent) {
    this.parent = parent
    this.tooltipObj = new Tooltip({
      content: 'No title',
      position: 'BottomRight',
      offsetY: 10,
      mouseTrail: this.parent.isAdaptive ? false : true,
      showTipPointer: false,
      cssClass: this.parent.cssClass + ' ' + cls.EVENT_TOOLTIP_ROOT_CLASS,
      target: this.getTargets(),
      beforeRender: this.onBeforeRender.bind(this),
      enableRtl: this.parent.enableRtl
    })
    if (isBlazor()) {
      this.tooltipObj.beforeOpen = this.onBeforeOpen.bind(this)
      this.tooltipObj.beforeClose = this.onBeforeClose.bind(this)
      this.tooltipObj.animation = { close: { effect: 'None' } }
    }
    this.tooltipObj.appendTo(this.parent.element)
    this.tooltipObj.isStringTemplate = true
  }
  EventTooltip.prototype.getTargets = function () {
    var targets = []
    if (this.parent.activeViewOptions.group.headerTooltipTemplate) {
      targets.push('.' + cls.RESOURCE_CELLS_CLASS)
    }
    if (this.parent.eventSettings.enableTooltip) {
      targets.push('.' + cls.APPOINTMENT_CLASS)
    }
    return targets.join(',')
  }
  EventTooltip.prototype.onBeforeOpen = function () {
    if (this.parent.group.headerTooltipTemplate) {
      var templateId = this.parent.element.id + '_headerTooltipTemplate'
      updateBlazorTemplate(
        templateId,
        'HeaderTooltipTemplate',
        this.parent.group
      )
    }
    if (this.parent.eventSettings.tooltipTemplate) {
      var templateId = this.parent.element.id + '_tooltipTemplate'
      updateBlazorTemplate(
        templateId,
        'TooltipTemplate',
        this.parent.eventSettings
      )
    }
  }
  EventTooltip.prototype.onBeforeClose = function () {
    if (this.parent.group.headerTooltipTemplate) {
      var templateId = this.parent.element.id + '_headerTooltipTemplate'
      resetBlazorTemplate(templateId, 'HeaderTooltipTemplate')
    }
    if (this.parent.eventSettings.tooltipTemplate) {
      var templateId = this.parent.element.id + '_tooltipTemplate'
      resetBlazorTemplate(templateId, 'TooltipTemplate')
    }
  }
  EventTooltip.prototype.getResourceText = function (args) {
    if (this.parent.resourceCollection.length === 0) {
      return null
    }
    var resourceValue = ''
    let textField = ''
    let categoryId = this.parent.resourceBase.resourceCollection[0].field
    if (this.parent.resourceBase.resourceCollection.length !== 0) {
      textField = this.parent.resourceBase.resourceCollection[0].textField
      var value = this.parent.resourceBase.resourceCollection[0].dataSource.find(
        item => item.id === args[categoryId]
      )
      resourceValue = value[textField]
    }
    return resourceValue
  }

  EventTooltip.prototype.onBeforeRender = function (args) {
    if (!isNullOrUndefined(args.target.getAttribute('data-tooltip-id'))) {
      return
    }
    if (
      args.target.classList.contains(cls.RESOURCE_CELLS_CLASS) &&
      this.parent.activeViewOptions.group.resources.length > 0
    ) {
      var resCollection = void 0
      if (this.parent.activeView.isTimelineView()) {
        var index = parseInt(args.target.getAttribute('data-group-index'), 0)
        resCollection = this.parent.resourceBase.lastResourceLevel[index]
      } else {
        var rowIndex = args.target.parentNode.sectionRowIndex
        var cellIndex = args.target.cellIndex
        resCollection = this.parent.activeView.getColumnLevels()[rowIndex][
          cellIndex
        ]
      }
      var data = {
        resource: resCollection.resource,
        resourceData: resCollection.resourceData
      }
      var contentContainer = createElement('div')
      var templateId = this.parent.element.id + '_headerTooltipTemplate'
      var tooltipTemplate = [].slice.call(
        this.parent.getHeaderTooltipTemplate()(
          data,
          this.parent,
          'headerTooltipTemplate',
          templateId,
          false
        )
      )
      append(tooltipTemplate, contentContainer)
      this.setContent(contentContainer)
      return
    }
    var record = this.parent.eventBase.getEventByGuid(
      args.target.getAttribute('data-guid')
    )
    if (!isNullOrUndefined(this.parent.eventSettings.tooltipTemplate)) {
      var contentContainer = createElement('div')
      var templateId = this.parent.element.id + '_tooltipTemplate'
      var templateArgs = util.addLocalOffsetToEvent(
        record,
        this.parent.eventFields
      )
      var tooltipTemplate = [].slice.call(
        this.parent.getEventTooltipTemplate()(
          templateArgs,
          this.parent,
          'tooltipTemplate',
          templateId,
          false
        )
      )
      append(tooltipTemplate, contentContainer)
      this.setContent(contentContainer)
    } else {
      var globalize = this.parent.globalize
      var fields = this.parent.eventFields
      var eventStart = new Date('' + record[fields.startTime])
      var eventEnd = new Date('' + record[fields.endTime])
      eventEnd =
        eventEnd.getHours() === 0 && eventEnd.getMinutes() === 0
          ? new Date(eventEnd.setMilliseconds(-1000))
          : eventEnd
      var startDate = util.resetTime(new Date('' + eventStart))
      var endDate = util.resetTime(new Date('' + eventEnd))
      var tooltipSubject =
        record[fields.subject] ||
        this.parent.eventSettings.fields.subject.default
      var tooltipLocation = !isNullOrUndefined(record[fields.location])
        ? record[fields.location]
        : ''
      var startMonthDate = globalize.formatDate(eventStart, {
        type: 'date',
        skeleton: 'MMMd',
        calendar: this.parent.getCalendarMode()
      })
      var startMonthYearDate = globalize.formatDate(eventStart, {
        type: 'date',
        skeleton: 'medium',
        calendar: this.parent.getCalendarMode()
      })
      var endMonthYearDate = globalize.formatDate(eventEnd, {
        type: 'date',
        skeleton: 'medium',
        calendar: this.parent.getCalendarMode()
      })
      startMonthDate = util.capitalizeFirstWord(startMonthDate, 'single')
      startMonthYearDate = util.capitalizeFirstWord(
        startMonthYearDate,
        'single'
      )
      endMonthYearDate = util.capitalizeFirstWord(endMonthYearDate, 'single')
      var startTime = globalize.formatDate(eventStart, {
        type: 'time',
        skeleton: 'short',
        calendar: this.parent.getCalendarMode()
      })
      var endTime = globalize.formatDate(eventEnd, {
        type: 'time',
        skeleton: 'short',
        calendar: this.parent.getCalendarMode()
      })
      var tooltipDetails = void 0
      if (startDate.getTime() === endDate.getTime()) {
        tooltipDetails = globalize.formatDate(eventStart, {
          type: 'date',
          skeleton: 'long',
          calendar: this.parent.getCalendarMode()
        })
        tooltipDetails = util.capitalizeFirstWord(tooltipDetails, 'single')
      } else {
        tooltipDetails =
          startDate.getFullYear() === endDate.getFullYear()
            ? startMonthDate + ' - ' + endMonthYearDate
            : startMonthYearDate + ' - ' + endMonthYearDate
      }
      var resourceText = this.parent.disabledView
        ? tooltipSubject
        : this.getResourceText(record)
      var tooltipTime = record[fields.isAllDay]
        ? this.parent.localeObj.getConstant('allDay')
        : startTime + ' - ' + endTime
      var content =
        '<div><div class="e-subject">' +
        resourceText +
        '</div>' +
        '<div class="e-location">' +
        tooltipLocation +
        '</div>' +
        '<div class="e-details">' +
        tooltipDetails +
        '</div>' +
        '<div class="e-all-day">' +
        tooltipTime +
        '</div></div>'
      this.setContent(content)
    }
  }
  EventTooltip.prototype.setContent = function (content) {
    this.tooltipObj.setProperties({ content: content }, true)
  }
  EventTooltip.prototype.close = function () {
    this.tooltipObj.close()
  }
  /**
   * To destroy the event tooltip.
   * @return {void}
   * @private
   */
  EventTooltip.prototype.destroy = function () {
    this.tooltipObj.destroy()
    addClass([this.parent.element], 'e-control')
    this.tooltipObj = null
  }
  return EventTooltip
})()
export { EventTooltip }
