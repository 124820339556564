import { action, thunk } from 'easy-peasy'
import localforage from 'localforage'
import BaseModel from '../Base'
import {
  NETWORK_STATUS,
  LOCAL_STORAGE_CLINIC_AUTH_KEY_NAME,
  CLINIC_USER_ID,
  USER_ROLE
} from '../../Constants'
import api from '../../api'
import Swal from 'sweetalert2'

const updateLocalStorage = async data => {
  const { hospital_details, user, access_specificatin, id_token} = data

  const authn = {
    user,
    hospital_details,
    id_token
  }

  return Promise.all([
    localforage.setItem(LOCAL_STORAGE_CLINIC_AUTH_KEY_NAME, authn),
    localStorage.setItem(
      CLINIC_USER_ID,
      hospital_details && hospital_details.user_id
    ),
    localforage.setItem(
      CLINIC_USER_ID,
      hospital_details && hospital_details.user_id
    )
  ])
}

export default {
  ...BaseModel(),

  login: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { email, password } = payload
    // const response = await api.getUserRole({
    //   user_type: '2',
    //   url_code: 'craftveda-demo-hospital'
    // })

    // if (response.ok) {
    //   let userRole = response.data.roles.filter(
    //     item => item.display_name === USER_ROLE[2]
    //   )
    const responseAuth = await api.authenticateUser({
      user_name: email,
      password,
      type: 'password',
      user_type: '6'
      // url_code: 'craftveda-demo-hospital',
      // user_role: userRole.length > 0 ? userRole[0].id : '0'
    })

    if (responseAuth.data.statusCode === 200) {
      var data = responseAuth.data
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      localStorage.setItem('clinic_auth_key', data?.id_token)
      await updateLocalStorage(responseAuth.data)
      return responseAuth.data
    } else {
      Swal.fire('', "User doesn't exists", 'error')
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return { statusCode: responseAuth.data.statusCode }
    }
  }),

  changePassword: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { password } = payload

    const responseAuth = await api.clinic.changeClinicPassword({
      password: password,
      conf_password: password
    })
    if (responseAuth.status == 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      return responseAuth.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      return false
    }
  })
}
