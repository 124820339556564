import { action, thunk } from 'easy-peasy'
import BaseModel from '../Base'
import { NETWORK_STATUS } from '../../Constants'
import api from '../../api'
import swal from 'sweetalert'

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest()
  xhr.onload = function () {
    var reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export default {
  ...BaseModel(),

  username: null,
  userId: null,
  email: null,
  image: null,
  name: null,
  specialization: null,
  degree: null,
  experience: null,
  availability: [],
  description: null,
  hospitalMasterId: null,
  associated_with: null,
  hospitalShortcode: null,
  userShortCode: null,
  qualification: null,
  consultationFees: [],
  bio: null,
  mobile_no: null,
  prefix: null,
  picture: null,
  clinic_address: null,
  clinic_name: null,
  clinic_logo: null,

  fetchDetails: thunk(async (actions, payload) => {
    const { shortCode, bypassFetch, reloadPage } = payload
    let fetchAgain = bypassFetch ? true : false
    let reload = reloadPage ? true : false
    if (localStorage.getItem('doctor_details') && !fetchAgain) {
      let doctorDetails = JSON.parse(localStorage.getItem('doctor_details'))
      actions.fetchedDetails(doctorDetails)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      const response = await api.getUserDetails({
        // user_short_code: shortCode
        user_short_code: localStorage.getItem('user_short_code')
      })
      if (response?.data?.user_details) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.fetchedDetails(response.data.user_details)
        if (response.data.user_details.picture) {
          toDataURL(response.data.user_details.picture, function (imageData) {
            // console.log('RESULT:', imageData)
            localStorage.setItem('profileImage', imageData)
          })
        } else {
          localStorage.setItem('profileImage', 'null')
        }
        localStorage.setItem('clinic_id', response.data.user_details.clinic_id)
        localStorage.setItem(
          'doctor_details',
          JSON.stringify(response?.data?.user_details)
        )
        localStorage.setItem('photo_uri', response?.data?.user_details?.picture)
        if (reload) {
          window.location.reload()
        }
      } else {
        swal({
          title: 'Account Inactive',
          text: 'Please Contact your clinic admin',
          type: 'info',
          closeOnClickOutside: false
        }).then(confirm => {
          localStorage.clear()
          window.location.href = '/doctor/login'
        })
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  }),

  fetchedDetails: action((state, payload) => {
    if (payload) {
      state.username = payload.username
      state.userId = payload.user_master_id
      state.email = payload.email
      state.name = payload.name
      state.associated_with = payload.associated_with
      state.hospitalShortcode = payload.hospital_shortcode
      state.userShortCode = payload.user_short_code
      state.consultationFees = payload.fees
      state.image = payload.picture
      state.description = payload.bio
      state.experience = payload.experience
      state.specialization = payload.specialization
      state.degree = payload.qualification
      state.user_short_code = payload.user_short_code
      state.bio = payload.bio
      state.mobile_no = payload.mobile_no
      state.prefix = payload.prefix
      state.picture = payload.picture
      state.clinic_address = payload.clinic_address
      state.clinic_name = payload.clinic_name
      state.clinic_logo = payload.clinic_logo
    }
  })
}
