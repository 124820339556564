import React from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core'
import Page from '../../../components/Page'
import ProfileDetails from './ProfileDetails'
import { DashboardLayout } from '../../../layouts/ClinicDashboardLayout'
import { ClinicStore } from '../../../../stores'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const Account = () => {
  const classes = useStyles()

  return (
    <ClinicStore.Provider>
      <DashboardLayout>
        <Page className={classes.root} title="Account">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <ProfileDetails />
            </Grid>
          </Container>
        </Page>
      </DashboardLayout>
    </ClinicStore.Provider>
  )
}

export default Account
