import React, { useState } from 'react'
import { Box, colors } from '@material-ui/core'

const ImageInput = ({
  field,
  form,
  meta: { touched } = {},
  id,
  submitting
}) => {
  const [image, setImage] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)

  const onImageChange = (e, form) => {
    const value = e.currentTarget.files[0]

    if (!value) {
      return
    }

    if (
      value.type !== 'image/jpeg' &&
      value.type !== 'image/png' &&
      value.type !== 'image/jpg'
    ) {
      setImage(null)
      form.setFieldValue('logo_image', '')
      form.setFieldValue('logo_image_name', '')
      form.setFieldTouched('logo_image')
      setErrorMsg('Only jpg-s, jpeg-s & png-s are allowed')
    } else if (Math.round(value.size / Math.pow(1024, 2), 2) > 4.5) {
      setImage(null)
      form.setFieldValue('logo_image', '')
      form.setFieldValue('logo_image_name', '')
      form.setFieldTouched('logo_image')
      setErrorMsg('File size should be less than 4.5 MB')
    } else {
      var reader = new FileReader()

      reader.onload = e => {
        const logoPic = new Image()
        logoPic.onload = event => {
          const imageEl = event.path[0]
          setImage([e.target.result, imageEl.height > imageEl.width])
        }
        logoPic.src = e.target.result
        form.setFieldValue(
          'logo_image',
          e.target.result.substring(e.target.result.indexOf(',') + 1)
        )
        form.setFieldValue(
          'logo_image_name',
          `${form.values.name.replace(' ', '')}-logo.${
            value.type.split('/')[1]
          }`
        )
        setErrorMsg(null)
        form.setFieldTouched('logo_image')
      }
      reader.readAsDataURL(value)
    }
  }

  return (
    <React.Fragment>
      <Box className="clinic-profile">
        {!image ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '2rem',
              fontWeight: 'bold',
              letterSpacing: '0.1rem',
              textAlign: 'center',
              backgroundColor: colors.grey[100],
              color: 'rgb(17, 29, 78)',
              '-webkit-touch-callout': 'none',
              '-webkit-user-select': 'none',
              '-khtml-user-select': 'none',
              '-moz-user-select': 'none',
              '-ms-user-select': 'none',
              'user-select': 'none'
            }}>
            {!!form.values.name
              ? form.values.name
                  .split(' ')
                  .map(word => word.charAt(0))
                  .join('')
              : 'Logo'}
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <img
              src={image[0]}
              style={{
                [image[1] ? 'width' : 'height']: '100%',
                display: 'block'
              }}
              alt={`${form.values.name} logo`}
              loading="lazy"
            />
          </div>
        )}
      </Box>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          color: colors.red[500],
          fontSize: '80%',
          marginTop: errorMsg && touched ? '-0.5rem' : 0,
          marginBottom: errorMsg && touched ? '0.5rem' : 0
        }}>
        {errorMsg}
      </div>
      {submitting || (
        <input
          id={id}
          accept="image/x-png,image/jpg,image/jpeg"
          style={{ display: 'none' }}
          type="file"
          name={field.name}
          onBlur={field.onBlur}
          onChange={e => onImageChange(e, form)}
        />
      )}
    </React.Fragment>
  )
}

export default ImageInput
