import React, { useEffect, useRef } from 'react'
import { TextField as MuiTextField, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    fontSize: '80% !important'
  }
})

const TextField = ({ focusNow, ...props }) => {
  const classes = useStyles()

  const inputRef = useRef(null)

  useEffect(() => {
    if (focusNow) {
      inputRef.current.focus()
    }
  }, [focusNow])

  return (
    <MuiTextField
      {...props}
      inputRef={inputRef}
      FormHelperTextProps={{
        className: classes.root
      }}
    />
  )
}

export default TextField
