import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Tabs, Tab, Box, makeStyles, useTheme } from '@material-ui/core'
import IncomeBlance from './IncomeBalance'
import Bankdetails from './Bankdetails'
import Transactionhistory from './Transactionhistory'
import Subscriptionplan from './Subscriptionplan'
import TabBgImg from '../../images/tab-bg.png'
import MediaBgImg from '../../images/right-top-img.jpg'
import WalletRequests from './WalletRequests'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box style={{ marginTop: '15px' }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

export default function FullWidthTabs() {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.accountcol}>
      <h2 style={{ fontSize: 20, color: '#000', fontWeight: 500 }}>
        My Account
      </h2>
      {/* <AppBar
        className={classes.accounttab}
        position="static"
        style={{ boxShadow: 'none' }}> */}
      {/* <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="white"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"> */}
      {/* <Tab
            className={classes.Tabbutton}
            // style={{ fontWeight: "bold" }}
            label="My Income & Balance"
            {...a11yProps(0)}
          /> */}
      {/* <Tab
            className={classes.Tabbutton}
            // style={{ fontWeight: "bold" }}
            label="Transaction History"
            {...a11yProps(1)}
          /> */}
      {/*
          <Tab
            className={classes.Tabbutton}
            // style={{ fontWeight: "bold" }}
            label="Wallet Requests"
            {...a11yProps(1)}
          />
          <Tab
            className={classes.Tabbutton}
            // style={{ fontWeight: "bold" }}
            label="Bank Details"
            {...a11yProps(2)}
          />
          */}
      {/* <Tab
            className={classes.Tabbutton}
            label="Subscription Plan"
            {...a11yProps(4)}
          /> */}
      {/* </Tabs> */}
      {/* </AppBar> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Box
          className={classes.tabcontentimg}
          value={value}
          index={0}
          dir={theme.direction}
          style={{ marginBottom: 50 }}>
          <IncomeBlance />
        </Box>
        <Box
          className={classes.tabcontentimg}
          value={value}
          index={1}
          dir={theme.direction}
          style={{ marginBottom: 30 }}>
          <Transactionhistory />
        </Box>
      </div>

      <div
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        // onChangeIndex={handleChangeIndex}
      >
        {/* <TabPanel
          className={classes.tabcontentimg}
          value={value}
          index={0}
          dir={theme.direction}>
          <IncomeBlance />
        </TabPanel> */}
        {/* <TabPanel
          className={classes.tabcontentimg}
          value={value}
          index={1}
          dir={theme.direction}>
          <Bankdetails />
        </TabPanel> */}
        {/* <TabPanel
          className={classes.tabcontentimg}
          value={value}
          index={1}
          dir={theme.direction}>
          <Transactionhistory />
        </TabPanel> */}
        {/* <TabPanel
          className={classes.tabcontentimg}
          value={value}
          index={3}
          dir={theme.direction}>
          <WalletRequests />
        </TabPanel> */}
        {/* <TabPanel
          className={classes.tabcontentimg}
          value={value}
          index={4}
          dir={theme.direction}>
          <Subscriptionplan />
        </TabPanel> */}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  accountcol: {
    '& h2': {
      marginTop: '0px',
      '@media (max-width:767px)': {
        fontSize: '20px'
      }
    }
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    '& .MuiTab-root': {
      fontSize: '12px',
      padding: '6px 0px'
    },
    '@media (max-width:767px)': {
      marginTop: '5px'
    }
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '15px',
    marginTop: '20px',
    boxShadow: '0 1px 8px 0 rgba(0,0,0,0.1)',
    borderRadius: '10px'
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%'
  },
  Tabbutton: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '16px',
    '& span': {
      fontSize: '16px',
      '@media (max-width:767px)': {
        fontSize: '14px',
        width: '200px',
        flexBasis: '200px'
      }
    }
  },
  plus: {
    color: '#fff',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    fontSize: '25px',
    background: '#3f4b79',
    fontWeight: 'bold',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0',
    marginRight: '7px'
  },
  accounttab: {
    // backgroundImage: 'linear-gradient(to right, #f5f5f5 , #f5f5f5)',
    padding: '0px',
    borderRadius: '5px',
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#fff',
      background: '#9138aa',
      '@media (max-width:767px)': {
        fontSize: '14px'
      }
    },
    '& .MuiTab-textColorPrimary': {
      color: '#979797',
      fontWeight: 'normal'
    }
  },
  tabcontentimg: {
    backgroundColor: 'transparent'
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  }
}))
