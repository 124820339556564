import { action, thunk } from 'easy-peasy'

import BaseModel from '../Base'
import { NETWORK_STATUS, PATIENT_ID, BOOKING_STATUS } from '../../Constants'
import api from '../../api'
import moment from 'moment'

export default {
  ...BaseModel(),
  upcomingdata: [],
  prescription: [],
  totalPagesCount: 0,
  manualPrescriptionList: [],

  getList: thunk(async (actions, payload, { getStoreState }) => {
    actions.setAppointments([])
    actions.setTotalPages(0)
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    if (payload.isForFollowup) {
      api.setAuthHeaders({
        authorization: localStorage.getItem('patient_auth_key')
      })
    }

    const {
      list_type,
      status,
      visit_type,
      appointment_type,
      sorting,
      currentPage,
      searchString,
      doctor_id
    } = payload

    const response = await api.doctor.getAppointmentList({
      user_id: localStorage.getItem(PATIENT_ID),
      doctor_id: doctor_id,
      status_list:
        status !== BOOKING_STATUS.ALL.value
          ? [status]
          : list_type === 'past' || list_type === 'all'
          ? ['2', '3', '4', '7']
          : ['2', '3', '4'],
      visit_type: visit_type ? visit_type : 0,
      table_config_rows_per_page: '10',
      table_config_page_no: currentPage ? currentPage : '1',
      list_type: list_type,
      appointment_type: appointment_type,
      pattern: searchString ? searchString : '',
      sorting: sorting ? sorting : ''
    })
    // sorting:{
    //   created_at: 'ASC',
    //   start_time: 'ASC',
    //   appointment_date: 'ASC'
    // }
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      if (response?.data?.appointments) {
        actions.setAppointments(response?.data?.appointments)
        actions.setTotalPages(response?.data?.total_pages_count)
      }
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setAppointments: action((state, payload) => {
    state.upcomingdata = payload
  }),
  setTotalPages: action((state, payload) => {
    state.totalPagesCount = payload
  }),

  generatePrescriptionPdf: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const { appointment_id } = payload

      const response = await api.patient.generatePrescription({
        appointment_id: appointment_id,
        view_type: 'pdf'
      })

      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setPrescriptionPdf(response.data)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),
  setPrescriptionPdf: action((state, payload) => {
    state.prescription = payload
  }),

  setManualPrescriptionList: action((state, payload) => {
    state.manualPrescriptionList = payload
  }),

  fetchManualPrescriptionList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    try {
      const response = await api.clinic.clinicListManualPrescription(payload)
      const responseData = response?.data

      if (!responseData) {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      } else if (responseData?.statusCode === 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

        const baseURL = responseData?.base_url
        const presList = responseData?.prescriptions
          ? responseData?.prescriptions
          : []

        const modifiedList = presList.map(pres => ({
          ...pres,
          prescription_url: `${baseURL}${pres.prescription_url}`
        }))

        actions.setManualPrescriptionList(modifiedList)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    } catch (error) {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
    return
  })
}
