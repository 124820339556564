import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Profile from './Profile'
import NavLinks from '../CommonComponents/NavLinks'
import PatientProfile from '../CommonComponents/PatientProfile'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {
  DOCTOR_DRAWER_ITEMS,
  PATIENT_DRAWER_ITEMS,
  PATIENT_ID
} from '../../Constants'
import { useHistory } from 'react-router-dom'
import MenuIcon from '../../images/menu.png'
import Logo from '../../images/logo.png'
import CardBgImg from '../../images/right-top-img3.png'

const Navbar = () => {
  const classes = useStyles()

  return (
    <>
      <header className={classes.header}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item sm={3} className={classes.Logo}>
            <Link to="/">
              <img className={classes.LogoImg} src={Logo} alt="Logo" />
            </Link>
          </Grid>{' '}
          <Grid item sm={9} className={classes.nav}>
            <Typography component="h3" className={classes.welcome}></Typography>
            <NavLinks />
          </Grid>
        </Grid>
      </header>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    background: '#fff',
    padding: '5px 15px',
    position: 'fixed',
    height: '76px',
    zIndex: '999',
    top: '0px',
    left: '0px !importaant',
    '@media (max-width:767px)': {
      height: 'auto'
    }
  },
  ResponsiveNav: {
    display: 'none',
    '@media (max-width:1024px)': {
      display: 'block',
      marginRight: '10px',
      marginTop: '5px'
    }
  },
  Responsivebtn: {
    padding: '0px',
    minWidth: 'auto'
  },
  LogoImg: {
    height: '75px',
    '@media (max-width:1024px)': {
      height: '75px'
    }
  },
  Logo: {
    '@media (max-width:1024px)': {
      height: 'auto',
      maxWidth: '30%',
      flexBasis: '30%',
      marginLeft: '20px',
      paddingLeft: '10px !important',
      display: 'flex'
    },
    '@media (max-width:550px)': {
      maxWidth: '50%',
      flexBasis: '50%',
      marginLeft: '20px'
    }
  },
  welcome: {
    fontWeight: 700,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:550px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      justifyContent: 'center'
    }
  },
  Modalslide: {
    width: '300px',
    height: '100vh',
    background: '#fff',
    position: 'fixed',
    left: '0px',
    zIndex: '999'
  },
  hospitalName: {
    color: theme.palette.primary.main,
    fontSize: '.75rem'
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:1024px)': {
      height: 'auto',
      maxWidth: '65%',
      flexBasis: '65%',
      paddingRight: '10px !important'
    },
    '@media (max-width:550px)': {
      flexDirection: 'column',
      maxWidth: '100%',
      flexBasis: '100%',
      paddingRight: '0px !important'
    }
  },
  bottomMargin: {
    marginBottom: '80px',
    '@media (max-width:767px)': {
      marginBottom: '60px'
    },
    '@media (max-width:550px)': {
      marginBottom: '100px'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  list: {
    width: 250,
    height: '100vh',
    background: `url(${CardBgImg}) center top no-repeat`,
    backgroundSize: 'cover',
    position: 'fixed',
    overflowY: 'auto'
  },
  fullList: {
    width: 'auto',
    background: `url(${CardBgImg}) center top no-repeat`,
    backgroundSize: 'cover'
  },
  navtext: {
    color: '#fff'
  }
}))

export default Navbar
