import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  TextField
} from '@material-ui/core'
import { IoMdMic, IoMdPause } from 'react-icons/io'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { debounce } from 'lodash'
import Axios from 'axios'
import localforage from 'localforage'
import { DoctorStore } from '../../stores'
import PrimaryButton from '../../components/CommonComponents/PrimaryButton'
import AddLabOrders from '../PatientMedication/AddLabOrders'
import AddServices from './Services'
import AddMedicationModal from '../PatientMedication/AddMedicationModal'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import { BASE_URL } from '../../Environment'

const PatientDiagnosis = props => {
  const { classes, disabled, params } = props

  const [observationsError, setobservationsError] = useState('')
  const [suggestionsError, setsuggestionsError] = useState('')
  const [diagnosisError, setdiagnosisError] = useState('')
  const [clinicalnotesError, setclinicalnotesError] = useState('')

  const [values, setValues] = useState({
    diagnosis: '',
    observations: '',
    suggestions: '',
    clinical_notes: '',
    others: ''
  })

  const {
    getDiagnosisData,
    getDiagnosisSearch,
    editDiagnosisData,
    clearSnack
  } = DoctorStore.useStoreActions(action => ({
    ...action.patients
  }))
  const {
    diagnosisData,
    diagnosisSearch,
    diagnosisFetchingStatus,
    editDiagnosisResponse
  } = DoctorStore.useStoreState(state => ({
    ...state.patients
  }))

  let searchData = diagnosisSearch.length > 0 ? diagnosisSearch : []
  const [otherDiagnosis, setOtherDiagnosis] = React.useState([])
  const [conditions, setConditions] = React.useState([])
  const [medicationsMapping, setMedicationsMapping] = React.useState(null)
  const [otherConditions, setOtherConditions] = React.useState([])
  const [addMedication, setAddMedication] = useState(false)
  const [addLab, setAddLab] = useState(false)
  const [addServices, setAddServices] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  const fetchMapping = async conditions => {
    let tokenStorage = await localforage.getItem('doctor_auth')
    let token = tokenStorage.user.id_token
    Axios.post(
      BASE_URL + 'user/condition_medication_mapping/view',
      {
        condition_ids: conditions
        // condition_ids: ['24', '32']
      },
      {
        headers: {
          Authorization: token
        }
      }
    ).then(res => {
      setMedicationsMapping(res?.data?.data)
    })
  }

  useEffect(() => {
    let conditions = []
    if (diagnosisData?.chronic_conditions) {
      for (let key in diagnosisData.chronic_conditions) {
        conditions.push({
          chronic_conditions_id: key,
          chronic_conditions_name: diagnosisData.chronic_conditions[key]
          // chronic_conditions_name: ""
        })
      }
    }
    setConditions(conditions)
    if (conditions.length > 0) {
      fetchMapping(conditions.map(item => String(item.chronic_conditions_id)))
    }
  }, [diagnosisData?.chronic_conditions])

  const delayedHandleChange = debounce(
    eventData =>
      getDiagnosisSearch({
        eventData,
        table_config_rows_per_page: '20',
        table_config_page_no: '1'
      }),
    1000
  )

  const handleChange = e => {
    let eventData = e.target.value
    delayedHandleChange(eventData)
  }

  const handleAutocompleteChange = (e, value) => {
    let observations = []
    let others = []
    value.map(item => {
      if (item.chronic_conditions_id) {
        observations.push(Number(item.chronic_conditions_id))
      } else {
        others.push(item.chronic_conditions_name)
      }
    })
    setOtherConditions(others)
    setValues({
      ...values,
      observations: observations,
      others: others
    })
    setobservationsError('')
    setConditions(value)
    fetchMapping(observations)
  }

  useEffect(() => {
    getDiagnosisData({
      appointment_id: params.appointment_id
    })
    return () => {
      clearSnack()
    }
  }, [])

  useEffect(() => {
    if (editDiagnosisResponse == undefined) {
      //
    } else if (editDiagnosisResponse?.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Diagnosis details has been updated...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Details not inserted properly...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [editDiagnosisResponse])

  useEffect(() => {
    let observations = []
    for (let key in diagnosisData?.chronic_conditions) {
      observations.push(parseInt(key))
    }
    setValues({
      diagnosis:
        diagnosisData && diagnosisData.observations
          ? diagnosisData.observations
          : '',
      observations: observations,
      suggestions:
        diagnosisData && diagnosisData.suggestions
          ? diagnosisData.suggestions
          : '',
      clinical_notes:
        diagnosisData && diagnosisData.clinical_notes
          ? diagnosisData.clinical_notes
          : '',
      others: diagnosisData && diagnosisData.others ? diagnosisData.others : ''
    })
  }, [diagnosisData])

  const formSubmit = item => {
    let isValid = true

    if (values?.observations == '') {
      setobservationsError('Please enter a valid Diagnosis')
      isValid = false
    }

    if (values?.diagnosis == '') {
      setdiagnosisError('Please enter a valid Observations')
      isValid = false
    }

    // if (values.suggestions.length <= 0) {
    //   setsuggestionsError('Please enter a valid Suggestion')
    //   isValid = false
    // }

    // if (values.clinical_notes.length <= 0) {
    //   setclinicalnotesError('Please enter a valid Clinical Notes')
    //   isValid = false
    // }
    if (isValid) {
      editDiagnosisData({
        values,
        appointment_id:
          item && item.appointment_id
            ? item.appointment_id
            : params.appointment_id,

        patient_id:
          item && item.patient_id ? item.patient_id : params.patient_id
      })
    }
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <React.Fragment>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        autoHideDuration={3000}
      />
      <Grid item xs={12} md={12}>
        {addLab ? (
          <AddLabOrders
            open={addLab}
            handleClose={() => {
              setAddLab(false)
            }}
          />
        ) : null}
        {addServices ? (
          <AddServices
            dialogOpen={addServices}
            appointmentId={params.appointment_id}
            patientId={params.patient_id}
            handleClose={() => {
              setAddServices(false)
            }}
          />
        ) : null}

        {addMedication ? (
          <AddMedicationModal
            open={addMedication}
            handleClose={() => setAddMedication(false)}
          />
        ) : null}
        <form>
          <Box className={classes.PatientChart}>
            <Typography component="h3">
              <strong className={classes.vitals}>
                Chief Complaints / Observations *
              </strong>

              <TextField
                name="diagnosis"
                disabled={disabled}
                style={{ width: '100%' }}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: e.target.value
                  })
                  setdiagnosisError('')
                }}
                value={values && values.diagnosis ? values.diagnosis : null}
              />
              <label
                style={{ color: 'red', postition: 'relative', top: '-10px' }}>
                {diagnosisError}
              </label>
            </Typography>
          </Box>
          <Box className={classes.PatientChart}>
            <Typography component="h3">
              <strong className={classes.vitals}>Diagnosis *</strong>
              <Autocomplete
                multiple
                style={{ width: '100%' }}
                id="size-small-standard"
                options={searchData}
                loadingText="Loading..."
                noOptionsText={
                  diagnosisFetchingStatus ? 'Loading...' : 'No Options'
                }
                filterOptions={(options, params) => {
                  const filtered = options
                  if (
                    options.length === 0 &&
                    !diagnosisFetchingStatus &&
                    params.inputValue !== ''
                  ) {
                    filtered.push({
                      type: 'others',
                      title: `Add "${params.inputValue}"`,
                      chronic_conditions_name: params.inputValue
                    })
                  }
                  return filtered
                }}
                disabled={disabled}
                value={conditions}
                getOptionLabel={option => {
                  if (option.type === 'others') {
                    let added = otherConditions.find(
                      item => item === option?.chronic_conditions_name
                    )
                    if (added) {
                      return option?.chronic_conditions_name
                    } else {
                      return option.title
                    }
                  }
                  if (option.inputValue) {
                    return option.title
                  }
                  return option?.chronic_conditions_name
                }}
                onChange={handleAutocompleteChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="normal"
                    loading={diagnosisFetchingStatus}
                    onChange={handleChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {diagnosisFetchingStatus ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </Typography>
          </Box>

          <Box>
            <Typography>
              {medicationsMapping ? (
                <div
                  className="medications-box"
                  style={{ width: '100%', border: '2px solid #dedede' }}>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                  &nbsp;Suggested Medications
                  {medicationsMapping.length !== 0 ? (
                    medicationsMapping.map(item => (
                      <>
                        <h4>
                          <strong>{item.name}</strong>
                        </h4>
                        <p>{item.medications.join(', ')}</p>
                      </>
                    ))
                  ) : (
                    <p>
                      {' '}
                      <i className="text-muted">
                        There currently are no medication suggestions for this
                        condition{' '}
                      </i>
                    </p>
                  )}
                </div>
              ) : null}
              <label
                style={{ color: 'red', position: 'relative', top: '-25px' }}>
                {observationsError}
              </label>
            </Typography>
          </Box>

          <Grid container>
            <Grid item xs={12} md={12} sm={12} style={{ margin: '0 0 20px 0' }}>
              <Box style={{ display: 'flex' }}>
                <Typography
                  className="medication-btn"
                  onClick={() => setAddMedication(true)}
                  style={{
                    backgroundColor: disabled ? '#ADADAD' : '#0D46C1',
                    color: '#fff',
                    padding: '10px 40px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    width: '250px',
                    marginRight: '20px',
                    marginBottom: '8px',
                    pointerEvents: disabled ? 'none' : 'inherit'
                  }}
                  disabled={disabled}>
                  Add Medications
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sm={6} style={{ margin: '0 0 20px 0' }}>
              <Box>
                <Typography
                  className="medication-btn"
                  onClick={() => setAddLab(true)}
                  style={{
                    backgroundColor: disabled ? '#ADADAD' : '#0D46C1',
                    color: '#fff',
                    padding: '10px 40px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    width: '250px',
                    marginBottom: '8px',
                    pointerEvents: disabled ? 'none' : 'inherit'
                  }}
                  disabled={disabled}>
                  Add Lab Orders
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={6} sm={6} style={{ margin: '0 0 20px 0' }}>
              <Box>
                
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} md={6} sm={6} style={{ margin: '0 0 20px 0' }}>
              <Box>
                <Typography
                  className="medication-btn"
                  onClick={() => setAddServices(true)}
                  style={{
                    backgroundColor: disabled ? '#ADADAD' : '#111d4e',
                    color: '#fff',
                    padding: '10px 40px',
                    borderRadius: '40px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    // width: '80%',
                    marginBottom: '8px',
                    pointerEvents: disabled ? 'none' : 'inherit'
                  }}
                  disabled={disabled}>
                  Add / Recommend Services
                </Typography>
              </Box>
            </Grid> */}
          </Grid>

          <Box className={classes.PatientChart}>
            <Typography component="h3">
              <strong className={classes.vitals}>Suggestions</strong>
              <TextField
                style={{ width: '100%' }}
                name="suggestions"
                disabled={disabled}
                value={values && values.suggestions ? values.suggestions : null}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: e.target.value
                  })
                  setsuggestionsError('')
                }}
              />
              <label style={{ color: 'red' }}>{suggestionsError}</label>
            </Typography>
          </Box>

          <Box className={classes.PatientChart}>
            <Typography component="h3">
              <strong className={classes.vitals}>
                {/* Clinical Notes <IoMdMic /> <IoMdPause />{' '} */}
                Clinical Notes
              </strong>
              <TextField
                disabled={disabled}
                style={{ width: '100%' }}
                name="clinical_notes"
                value={
                  values && values.clinical_notes ? values.clinical_notes : null
                }
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: e.target.value
                    // ['observations'] : e.target.value
                  })
                  setclinicalnotesError('')
                }}
              />
              <label style={{ color: 'red' }}>{clinicalnotesError}</label>
            </Typography>
          </Box>
        </form>
        {/* {!edit && ( */}
        <>
          <PrimaryButton
            rounded
            disabled={disabled}
            className={classes.submitButton}
            onClick={() => formSubmit(diagnosisData)}
            style={{ height: '40px', padding: '0 30px', borderRadius: 8 }}>
            Save
          </PrimaryButton>
        </>
      </Grid>
    </React.Fragment>
  )
}

export default PatientDiagnosis
