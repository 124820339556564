import React, { useEffect } from 'react'
import VideocamIcon from '@material-ui/icons/Videocam'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import CallEndIcon from '@material-ui/icons/CallEnd'
import ScreenShareIcon from '@material-ui/icons/ScreenShare'
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare'

const MuteAudioButton = ({ audio, muteAudio }) => {
  if (!audio) {
    return (
      <button onClick={muteAudio} className="callBtn">
        <div>
          <MicOffIcon color="secondary" />
        </div>
      </button>
    )
  } else {
    return (
      <button onClick={muteAudio} className="callBtn">
        <div>
          <MicIcon color="secondary" />
        </div>
      </button>
    )
  }
}
const endAppointment = () => {
  window.history.back()
}
const CallButton = ({ endSession, roomClosed }) => {
  // if (localStorage.getItem('user_type') === 'support') {
  return (
    <button
      onClick={() => {
        endAppointment(roomClosed)
      }}
      className="callBtn endCall">
      <div>
        <CallEndIcon style={{ color: 'red' }} />
      </div>
    </button>
  )
  // } else {
  //   return <span></span>
  // }
}

const MuteVideoButton = ({ video, muteVideo }) => {
  if (!video) {
    return (
      <button onClick={muteVideo} className="callBtn">
        <div>
          <VideocamOffIcon color="secondary" />
        </div>
      </button>
    )
  } else {
    return (
      <button onClick={muteVideo} className="callBtn">
        <div>
          <VideocamIcon color="secondary" />
        </div>
      </button>
    )
  }
}

const Toolbar = ({
  muteAudio,
  muteVideo,
  screenShare,
  endSession,
  ...props
}) => {
  useEffect(() => {
    if (props.isAudioCall && props.videoStatus) {
      muteVideo()
    }
  }, [])

  return (
    <div className={'twilio-toolbar '}>
      <MuteAudioButton audio={props.audioStatus} muteAudio={muteAudio} />
      <CallButton
        endSession={endSession}
        roomClosed={props.roomClosed}
        roomInfo={props.room}
      />
      <MuteVideoButton video={props.videoStatus} muteVideo={muteVideo} />
    </div>
  )
}

export default Toolbar
