/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  makeStyles
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Page from './../../components/Page'
import ClinicListItem from './ClinicListItem'
import ViewAndEditDialog from './ViewAndEditDialog'
import DeleteDialog from './DeleteDialog'
import { SuperClinicStore } from '../../../stores'
import { NETWORK_STATUS } from '../../../Constants'
import SnackBarAlert from '../../components/SnackBarAlert'
import Button from '../../components/Button'
import Loader from '../../components/ClinicLoader'

import noRecordFound from '../../../images/noRecordFound.png'

const ClinicList = ({ onClickAddButton = () => {} }) => {
  const classes = useStyles()

  const {
    networkStatus,
    loadMessage,
    clinicList,
    clinicPaginationInfo,
    clinicListNetworkStatus,
    errorMessageFromBackend,
    successMessageFromBackend,
    loadList
  } = SuperClinicStore.useStoreState(state => ({
    ...state.clinicListDetails
  }))

  const {
    getClinicList,
    setMessageFromBackend,
    setLoadList
  } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.clinicListDetails
  }))

  const {
    total_rows_count: totalRowsCount,
    current_page: currentPage,
    rows_per_page: currentRowsPerPage
  } = clinicPaginationInfo

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [viewEditDialogOpen, setViewEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedClinic, setSelectedClinic] = useState(null)


  useEffect(() => {
    if (loadList) {
      getClinicList({
        table_config_page_no: page + 1,
        table_config_rows_per_page: rowsPerPage
      }).then(data => {
        if (data) {
          setLoadList(false)
        }
      })
    }
  }, [getClinicList, page, rowsPerPage, loadList, setLoadList])

  useEffect(() => {
    if (!currentPage || !currentRowsPerPage) {
      setPage(0)
      setRowsPerPage(10)
    } else {
      setPage(+currentPage - 1)
      setRowsPerPage(+currentRowsPerPage)
    }
  }, [currentPage, currentRowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setLoadList(true)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    setLoadList(true)
  }

  const handleListButtonsClicked = (buttonType, clinicId) => {
    if (buttonType === 'view') {
      setViewEditDialogOpen(true)
    } else if (buttonType === 'delete') {
      setDeleteDialogOpen(true)
    }
    setSelectedClinic(clinicList.find(ci => ci?.clinic_id === clinicId))
  }

  const handleListDialogsClose = buttonType => {
    if (buttonType === 'view') {
      setViewEditDialogOpen(false)
    } else if (buttonType === 'delete') {
      setDeleteDialogOpen(false)
    }
  }

  return (
    <Page title="My Clinics">
      <Loader
        label={loadMessage}
        open={networkStatus === NETWORK_STATUS.FETCHING}
      />
      <SnackBarAlert
        message={errorMessageFromBackend || successMessageFromBackend}
        error={!successMessageFromBackend}
        closeSnackBar={() =>
          !!errorMessageFromBackend
            ? setMessageFromBackend({ message: null })
            : setMessageFromBackend({ message: null, success: true })
        }
      />
      {/* <ViewAndEditDialog
        openDialog={true}
        handleDialogClose={handleListDialogsClose.bind(this, 'view')}
        clinicInfo={{
          address: 'Chennai',
          address_line2: '',
          clinic_id: 'b59106d7-5210-4d49-b7fe-390bc6f950d3',
          display_name: 'Swaroop Anand',
          email: 'swaroop.anand@craftvedatechnology.com',
          logo:
            'https://files-carenowdoc-com-official-website-development.s3.ap-south-1.amazonaws.com/clinic/b59106d7-5210-4d49-b7fe-390bc6f950d3/logo/SwaroopAnand-logo.jpeg',
          onboarded_on: '2021-04-21',
          phone_number: '+919163277710',
          poc_email_id: 'satadru.roy@craftvedatechnology.com',
          poc_phone_no: '+919163277705',
          point_of_contact_name: 'Satadru Roy'
        }}
      /> */}
      <ViewAndEditDialog
        openDialog={viewEditDialogOpen}
        handleDialogClose={handleListDialogsClose.bind(this, 'view')}
        clinicInfo={selectedClinic}
      />
      <DeleteDialog
        clinicListID={selectedClinic?.clinic_id}
        openDialog={deleteDialogOpen}
        handleDialogClose={handleListDialogsClose.bind(this, 'delete')}
      />
      <Box className="main-content-coll">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: 'flex',
              paddingTop: '30px',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Box>
              <h3 style={{ marginBottom: '0px' }}>All Clinics</h3>
              <p
                style={{
                  marginTop: '5px',
                  marginBottom: '0px',
                  fontSize: '14px',
                  color: 'rgb(147 148 154)'
                }}>
                More than 400 + new Clinics
              </p>
            </Box>
            <Button
              variant="contained"
              style={{ padding: '0 1.5rem', height: '40px', fontSize: '12px' }}
              onClick={onClickAddButton}>
              <AddIcon style={{ color: '#fff', paddingRight: '0.5rem' }} /> Add
              Clinic
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            {clinicListNetworkStatus === NETWORK_STATUS.FETCHING ? (
              <div
                style={{
                  width: '100%',
                  borderTop: '1px rgba(224, 224, 224, 1) solid',
                  height: '50vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <CircularProgress />
              </div>
            ) : clinicList.length <= 0 ? (
              <div
                style={{
                  width: '100%',
                  borderTop: '1px rgba(224, 224, 224, 1) solid',
                  height: '50vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <img
                  src={noRecordFound}
                  alt="No Clinics Found"
                  style={{ display: 'block', height: '15rem', width: '15rem' }}
                />
              </div>
            ) : (
              <>
                <Box
                  style={{
                    width: '100%',
                    borderTop: '1px rgba(224, 224, 224, 1) solid',
                    minHeight: '50vh'
                  }}>
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: 'none' }}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ color: 'rgb(147, 148, 154)' }}>
                            Clinic Name
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ color: 'rgb(147, 148, 154)' }}>
                            Address
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ color: 'rgb(147, 148, 154)' }}>
                            Phone Number
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ color: 'rgb(147, 148, 154)' }}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {clinicList.map(clinicInfo => (
                          <ClinicListItem
                            key={clinicInfo?.clinic_id}
                            clinicId={clinicInfo?.clinic_id}
                            clinicName={clinicInfo?.display_name}
                            address={clinicInfo?.address}
                            phoneNumber={clinicInfo?.phone_number}
                            onButtonsClicked={handleListButtonsClicked}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                  style={{ marginBottom: 0, paddingBottom: 0 }}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={totalRowsCount ? +totalRowsCount : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}

export default ClinicList

const useStyles = makeStyles(() => ({
  table: {
    '& p': {
      margin: '7px 0'
    }
  }
}))
