import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core'
import MoneyIcon from '@material-ui/icons/Money'
import RuppeeIcon from '../../../../images/rupee.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow: '0 1px 7px 0 rgba(0,0,0,0.1)'
  },
  avatar: {
    background: 'linear-gradient(45deg, #063bc4, #680fad)',
    height: 45,
    width: 45
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  splitValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}))

const Budget = ({ className, data, view, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              style={{ fontSize: '1rem', textAlign: 'left' }}>
              Total Earnings
              <div>
                (
                {view == 'monthly'
                  ? new Date().toLocaleString('default', { month: 'long' })
                  : 'Annually'}
                )
              </div>
            </Typography>
            <Typography color="textPrimary" variant="h5">
              <i class="fa fa-inr"></i>{' '}
              {data?.total_fees ? data?.total_fees : '0'}
            </Typography>
            <Box mt={1} display="flex" alignItems="center">
              <Typography className={classes.splitValue} variant="body2">
                ₹ {data.doctor_fee ? data.doctor_fee : '0'}
              </Typography>
              <Typography color="textSecondary" variant="caption">
                Doctor Fees
              </Typography>
            </Box>
            <Box mt={1} display="flex" alignItems="center">
              <Typography className={classes.splitValue} variant="body2">
                ₹ {data.service_fee ? data.service_fee : '0'}
              </Typography>
              <Typography color="textSecondary" variant="caption">
                Service Fees
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

Budget.propTypes = {
  className: PropTypes.string
}

export default Budget
