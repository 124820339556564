import React, { useState, useRef, useLayoutEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Box,
  Slide,
  DialogActions,
  makeStyles,
  InputAdornment,
  colors
} from '@material-ui/core'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'

import Button from '../../components/Button'
import FieldComponent from './ViewAndEditDialogComponents/FieldComponent'
import {
  DOUBLE_WHITESPACES,
  VALIDATE_ALPHANUMERIC_VALUES,
  VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE
} from '../../../Constants'
import TextField from '../../components/TextField'
import PhoneNumberField from '../../components/PhoneNumberField'
import ImageComponent from './ViewAndEditDialogComponents/ImageComponent'
import { SuperClinicStore } from '../../../stores'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ViewAndEditDialog = ({ openDialog, handleDialogClose, clinicInfo }) => {
  const classes = useStyles()

  const {
    editClinicProfile,
    viewClinicInfo
  } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.clinicListDetails
  }))

  const [cachedImages, setCachedImages] = useState([])

  const [newForm, setNewForm] = useState(false)

  const [showSaveButton, setShowSaveButton] = useState(false)

  clinicInfo = clinicInfo || ''

  const formikRef = useRef(null)

  const initialValues = {
    clinic_id: clinicInfo.clinic_id,
    clinic_name: clinicInfo.display_name,
    address: clinicInfo.address,
    phone: clinicInfo.phone_number?.replace('+91', ''),
    email: clinicInfo.email,
    commission_value: clinicInfo.commission_value,
    logo_image: clinicInfo.logo,
    logo_image_name: `${clinicInfo.display_name?.replace(
      ' ',
      ''
    )}-logo.${clinicInfo.logo?.substring(
      clinicInfo.logo?.lastIndexOf('.') + 1
    )}`,
    newLogoImage: '',
    newLogoImageName: ''
  }

  useLayoutEffect(() => {
    if (openDialog) {
      setNewForm(true)
    }
  }, [openDialog])

  const onSubmit = (values, actions) => {
    const submittedValues = Object.entries(values).reduce(
      (prevValue, [key, value]) => {
        switch (key) {
          case 'commission_value':
            if (!value) {
              return {
                ...prevValue,
                [key]: '0'
              }
            }
            return {
              ...prevValue,
              [key]: value.trim()
            }

          case 'phone':
            return {
              ...prevValue,
              [key]: `+91${value.trim()}`
            }

          case 'logo_image':
            return prevValue

          case 'logo_image_name':
            return prevValue

          case 'newLogoImage':
            if (!value) {
              return {
                ...prevValue,
                logo_image: ''
              }
            }
            return {
              ...prevValue,
              logo_image: value
            }

          case 'newLogoImageName':
            if (!value) {
              return {
                ...prevValue,
                logo_image_name: ''
              }
            }
            return {
              ...prevValue,
              logo_image_name: value
            }

          default:
            return {
              ...prevValue,
              [key]: value.trim()
            }
        }
      },
      {}
    )

    editClinicProfile(submittedValues).then(data => {
      if (data) {
        viewClinicInfo({ clinic_id: submittedValues.clinic_id })
        setShowSaveButton(false)
        handleDialogClose()
      }
    })
  }

  return (
    <Dialog
      open={openDialog}
      onExited={() => {
        setNewForm(false)
        setShowSaveButton(false)
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      {newForm && (
        <DialogContent className="edit-modal" style={{ height: 'initial' }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Box
              className="modal-top"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <h3>Clinic Infomation</h3>{' '}
              <p
                style={{
                  fontStyle: 'italic',
                  fontSize: '85%',
                  fontWeight: 'normal',
                  color: colors.grey[500]
                }}>
                Click on any field to edit!
              </p>
            </Box>
          </DialogContentText>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}>
            {props => {
              setShowSaveButton(props.dirty)

              return (
                <Form>
                  <Field name="newLogoImage">
                    {props => (
                      <ImageComponent
                        cachedImages={cachedImages}
                        setCachedImages={setCachedImages}
                        {...props}
                        submitting={false}
                      />
                    )}
                  </Field>
                  <div className={classes.dialogContentFieldsContainer}>
                    <FieldComponent
                      id="clinic-name"
                      label="Clinic Name"
                      nameUsedByFormik="clinic_name"
                    />

                    <FieldComponent
                      id="address"
                      label="Address"
                      nameUsedByFormik="address"
                    />

                    <FieldComponent
                      id="phone-number"
                      label="Phone No"
                      nameUsedByFormik="phone"
                      render={({
                        id,
                        nameUsedByFormik,
                        field,
                        touched,
                        error
                      }) => (
                        <PhoneNumberField
                          id={id}
                          label=""
                          {...field}
                          error={touched && error}
                          helperText={<ErrorMessage name={nameUsedByFormik} />}
                        />
                      )}
                      renderText={fieldValue => `+91${fieldValue}`}
                    />

                    <FieldComponent
                      id="email"
                      label="Email ID"
                      nameUsedByFormik="email"
                    />

                    <FieldComponent
                      id="commission"
                      label="Commission"
                      nameUsedByFormik="commission_value"
                      render={({
                        id,
                        nameUsedByFormik,
                        field,
                        touched,
                        error
                      }) => (
                        <TextField
                          id={id}
                          style={{ width: '100%' }}
                          {...field}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    fontWeight: 600,
                                    color: 'rgb(147, 148, 154)'
                                  }}>
                                  %
                                </span>
                              </InputAdornment>
                            )
                          }}
                          error={touched && error}
                          helperText={<ErrorMessage name={nameUsedByFormik} />}
                        />
                      )}
                      renderText={fieldValue =>
                        `${fieldValue === '' ? '0.0' : fieldValue}%`
                      }
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </DialogContent>
      )}
      <DialogActions>
        <div
          style={{
            padding: '0 1rem 0.8rem 0',
            display: 'grid',
            gridAutoFlow: 'column',
            gridColumnGap: '0.8rem',
            justifyContent: 'flex-end'
          }}>
          <Button
            variant="contained"
            onClick={() => {
              formikRef.current.resetForm()
              setShowSaveButton(false)
              handleDialogClose()
            }}
            style={{
              padding: '0 1.5rem',
              height: '2.5rem',
              color: '#cd4b4b',
              background: '#ccc'
            }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.modalBtn}
            disabled={!showSaveButton}
            style={{
              padding: '0 1.5rem',
              height: '2.5rem',
              width: '6.733375rem'
            }}
            onClick={() => {
              formikRef.current.submitForm()
            }}>
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ViewAndEditDialog

const validationSchema = Yup.object().shape({
  clinic_name: Yup.string()
    .trim()
    .required('Clinic name is required')
    .matches(DOUBLE_WHITESPACES, 'Remove extra spaces')
    .matches(
      VALIDATE_ALPHANUMERIC_VALUES,
      'Only alphabets and digits are allowed'
    )
    .max(30, 'Max 30 characters allowed'),

  address: Yup.string()
    .trim()
    .required('Address is required')
    .matches(DOUBLE_WHITESPACES, 'Remove extra spaces'),

  phone: Yup.string()
    .trim()
    .required('Mobile number is required')
    .matches(
      VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
      'Please enter a valid mobile number'
    ),

  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),

  commission_value: Yup.number()
    .transform((value, originalValue) =>
      /\s/.test(originalValue.trim()) ? NaN : value
    )
    .typeError('Please enter a valid number without spaces')
    .max(100, 'Please enter a number between 0 and 100')
    .min(0, 'Please enter a number between 0 and 100')
})

const useStyles = makeStyles(theme => ({
  modalBtn: {
    height: 'initial',
    padding: `0 ${theme.spacing(4)}px`,
    lineHeight: 1
  },
  dialogContentFieldsContainer: {
    lineHeight: 1,
    paddingBottom: '1rem',
    display: 'grid',
    gridTemplateColumns: 'max-content 17.5rem',
    gridAutoRows: 'min-content',
    gridColumnGap: '1.5rem',
    columnGap: '1.5rem',
    gridRowGap: '1.8rem',
    rowGap: '1.8rem',
    alignItems: 'flex-start'
  }
}))
