import React from 'react'
import { DashboardLayout } from '../../doctorLayouts'

const DoctorDashboardPage = () => {
  return (
    <DashboardLayout>
      <iframe
        style={{ height: '100vh', width: '100%', marginTop: '40px' }}
        title="static_html"
        src="https://sketch-carenow.web.app/"></iframe>
    </DashboardLayout>
  )
}

export default DoctorDashboardPage
