import React, { useState, useEffect } from 'react'
import { Box, Grid, MenuItem, TextField } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import NativeSelect from '@material-ui/core/NativeSelect'
import { makeStyles } from '@material-ui/core/styles'
import { createStore, action } from 'easy-peasy'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { SuperClinicStore } from '../../../../stores'
import doctor from '../../../../images/doctor.svg'
import clinic from '../../../../images/hospital.svg'
import SuperClinic from '../../../../stores/SuperClinic'
import LineChart from './lineCharts'
import BarChart from './barChart'
import BarChartTwo from './barChartTwo'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))
const Revenue = () => {
  const classes = useStyles()
  const {
    getClinicList,
    getmyDoctorList,
    getRevenue,
    getDoctorEarnings,
    getLastMonthsRevenue,
    getTopFiveRevenue
  } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.dashboard
  }))
  const {
    clinicLists,
    doctorList,
    doctorEarnings,
    earnings
  } = SuperClinicStore.useStoreState(state => ({
    ...state.dashboard
  }))
  const [clinic, setClinic] = useState('')
  const [doctor, setDoctor] = useState('')
  const periodLists = [
    { id: 'Last_week', label: 'Last week' },
    { id: 'Last_month', label: 'Last month' },
    { id: 'Last_3_month', label: 'Last 3 month' },
    { id: 'Last_6_month', label: 'Last 6 month' },
    { id: 'Last_9_month', label: 'Last 9 month' },
    { id: 'This_Quarter', label: 'This Quarter' },
    { id: 'Last_Quarter', label: 'Last Quarter' }
  ]
  const handleClinicChange = e => {
    setClinic(e.target.value)
  }
  const handleDoctorChange = e => {
    setDoctor(e.target.value)
  }
  const handleRevenueChange = e => {
    getRevenue({
      data_period: e.target.value
    })
  }
  const handleDoctorRevenue = e => {
    getDoctorEarnings({
      data_period: e.target.value
    })
  }
  const handleTopGenerators = e => {
    getTopFiveRevenue({
      data_period: e.target.value
    })
  }
  useEffect(() => {
    getClinicList({})
    getmyDoctorList({})
  }, [])

  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  return (
    <Box className="page-content">
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">Select Clinic</InputLabel>
            <Select
              value={clinicLists.display_name}
              onChange={handleClinicChange}
              name="clinic"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                anchorReference: 'anchorEl',
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                },
                getContentAnchorEl: null
              }}>
              {clinicLists.length > 0 &&
                clinicLists.map(({ clinic_id, display_name }) => (
                  <MenuItem key={clinic_id} value={display_name}>
                    {display_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">
              Select Data Period
            </InputLabel>
            <Select
              value={periodLists.id}
              onChange={handleRevenueChange}
              name="data"
              id="data-list"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                anchorReference: 'anchorEl',
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                },
                getContentAnchorEl: null
              }}>
              {periodLists.map(({ label, id }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4}>
          <Box className="box-col">
            <img
              src={doctor}
              alt="doctor"
              style={{ display: 'block', height: '50px', width: '50px' }}
            />
            <Box className="wiget-content">
              <h4>Doctor Earning</h4>
              <h3>
                <i class="fa fa-inr"></i> 500
              </h3>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box className="box-col">
            <img
              src={clinic}
              alt="doctor"
              style={{ display: 'block', height: '50px', width: '50px' }}
            />
            <Box className="wiget-content">
              <h4>Super Clinic Earning</h4>
              <h3>
                <i class="fa fa-inr"></i> 500
              </h3>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box className="box-col">
            <img
              src={clinic}
              alt="doctor"
              style={{ display: 'block', height: '50px', width: '50px' }}
            />
            <Box className="wiget-content">
              <h4>Sub Clinic Earning</h4>
              <h3>
                <i class="fa fa-inr"></i> 500
              </h3>
            </Box>
          </Box>
        </Grid>
        
        {/* <Grid container spacing={3}>
          <Grid item xs={6} sm={12}>
            <Box className="box-col">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <h4 style={{ marginBottom: '0px' }}>
                    Top 5 Revenue Generators
                  </h4>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">
                      Select Data Period
                    </InputLabel>
                    <Select
                      native
                      value={periodLists.label}
                      onChange={handleTopGenerators}
                      name="data-list3"
                      id="data-list3">
                      {periodLists.map(({ label, id }) => (
                        <option key={id} value={id}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
        {/* <Grid item xs={12} sm={9}>
                  <LineChart
                    date={earnings.date}
                    earnings={earnings.doctor_earnings}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid> 
        </Grid>*/}
        
      </Grid>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
      <Box className="box-col" style={{flexDirection:'column'}}>
      <Grid container spacing={3}>
      <Grid item xs={12} sm={3}>
                  <h4 style={{ margin:'0', fontWeight:'600'}}>Doctor’s Earnings</h4>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">
                      Select Doctor
                    </InputLabel>
                    <Select
                      value={doctorList.display_name}
                      onChange={handleDoctorChange}
                      name="doctor"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center'
                        },
                        anchorReference: 'anchorEl',
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'center'
                        },
                        getContentAnchorEl: null
                      }}>
                      {doctorList.length > 0 &&
                        doctorList.map(({ clinic_id, doctor_name }) => (
                          <MenuItem key={clinic_id} value={doctor_name}>
                            {doctor_name}
                          </MenuItem>
                        ))}

                      {/* <option aria-label="None" value="" />
                      <option value={10}>Ten</option>
                      <option value={20}>Twenty</option>
                      <option value={30}>Thirty</option> */}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">
                      Select Data Period
                    </InputLabel>
                    <Select
                      value={periodLists.id}
                      onChange={handleDoctorRevenue}
                      name="data-list2"
                      id="data-list2"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center'
                        },
                        anchorReference: 'anchorEl',
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'center'
                        },
                        getContentAnchorEl: null
                      }}>
                      {periodLists.map(({ label, id }) => (
                        <MenuItem key={id} value={id}>
                          {label}
                        </MenuItem>
                      ))}
                      {/* <option aria-label="None" value="" />
                      <option value={10}>Ten</option>
                      <option value={20}>Twenty</option>
                      <option value={30}>Thirty</option> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={9}></Grid>
      </Grid>
      </Box>
      </Grid>
                
              </Grid>

      <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box className="box-col" style={{flexDirection:'column', paddingTop:'8px'}}>
              <Box style={{ display:'flex', justifyContent:'space-between', width:'100%',alignItems:'center'}}>
                <h4 style={{ margin:'18px 0 0', fontWeight:'600'}}>Total Revenue Last 3 Month</h4>
                <FormControl className={classes.formControl} style={{ minWidth:'200px',margin:'0px'}}>
        <InputLabel htmlFor="age-native-simple">Select Data Period</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value={10}>Ten</option>
          <option value={20}>Twenty</option>
          <option value={30}>Thirty</option>
        </Select>
      </FormControl>
              </Box>
              <BarChart />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className="box-col" style={{flexDirection:'column', paddingTop:'8px'}}>
            <Box style={{ display:'flex', justifyContent:'space-between', width:'100%', alignItems:'center'}}>
                <h4 style={{ margin:'18px 0 0', fontWeight:'600'}}>Top 5 Revenue Generators</h4>
                <FormControl className={classes.formControl} style={{ minWidth:'200px',margin:'0px'}}>
        <InputLabel htmlFor="age-native-simple">Select Data Period</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value={10}>Ten</option>
          <option value={20}>Twenty</option>
          <option value={30}>Thirty</option>
        </Select>
      </FormControl>
              </Box>
              <BarChartTwo doctors={doctorList.display_name} />
            </Box>
          </Grid>
        </Grid>
    </Box>
  )
}

export default Revenue
