import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  FormControl,
  CircularProgress,
  InputAdornment,
  FormControlLabel,
  Switch,
  Collapse,
  makeStyles
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import Page from './../../components/Page'
import { SuperClinicStore } from '../../../stores'
import SnackBarAlert from '../../components/SnackBarAlert'
import {
  NETWORK_STATUS,
  VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
  SINGLE_WHITESPACE,
  DOUBLE_WHITESPACES,
  VALIDATE_NUMERIC_VALUES,
  VALIDATE_PIN_CODE,
  VALIDATE_ONLY_ALPHABETS,
  VALIDATE_URL_SLUG_PREFIX,
  VALIDATE_IFSC_CODE,
  VALIDATE_ALPHANUMERIC_VALUES
} from '../../../Constants'
import TextArea from './TextArea'
import PhoneNumberField from '../../components/PhoneNumberField'
import TextField from '../../components/TextField'
import Button from '../../components/Button'
import ImageInput from './ImageInput'
import CopyPasswordDialog from './CopyPasswordDialog'
import Loader from '../../components/ClinicLoader'

import hospital from '../../../images/hospital.png'

const AddNewClinic = ({ onClickBackToClinicListButton = () => {} }) => {
  const classes = useStyles()

  const {
    addClinicProfile,
    fetchCountryStateCityList,
    setCountryStateCityLists,
    clearStateAndCityList,
    setMessageFromBackend
  } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.clinicListDetails
  }))

  const {
    networkStatus,
    loadMessage,
    formSubmitNetworkStatus,
    countryList,
    stateList,
    cityList,
    fetchingCountryListNetworkStatus,
    fetchingStateListNetworkStatus,
    fetchingCityListNetworkStatus,
    errorMessageFromBackend,
    successMessageFromBackend
  } = SuperClinicStore.useStoreState(state => ({
    ...state.clinicListDetails
  }))

  const [stateInputValue, setStateInputValue] = useState('')
  const [cityInputValue, setCityInputValue] = useState('')

  const [bankDetailsMandatory, setBankDetailsMandatory] = useState(0)

  const [openCopyPasswordDialog, setOpenCopyPasswordDialog] = useState(null)

  const submittingTheForm = formSubmitNetworkStatus === NETWORK_STATUS.FETCHING

  useEffect(() => {
    if (countryList.length === 0) {
      fetchCountryStateCityList()
    }
  }, [fetchCountryStateCityList, countryList])

  const onCountryOrStateChange = (
    { country_id = null, state_id = null },
    updateLocalFormState,
    reason
  ) => {
    if (reason === 'select-option') {
      setCountryStateCityLists({ ids: { country_id, state_id }, list: [] })

      if (!!state_id) {
        updateLocalFormState.setFieldValue('state', state_id, true)
        updateLocalFormState.setFieldValue('city', '', false)
        setCityInputValue('')
        fetchCountryStateCityList({ state_id })
      } else if (!!country_id) {
        updateLocalFormState.setFieldValue('country', country_id, true)
        updateLocalFormState.setFieldValue('state', '', false)
        updateLocalFormState.setFieldValue('city', '', false)
        setStateInputValue('')
        setCityInputValue('')
        fetchCountryStateCityList({ country_id })
      }
    } else if (reason === 'clear') {
      clearStateAndCityList({ country_id, state_id })

      if (state_id === '') {
        updateLocalFormState.setFieldValue('city', '', true)
        setCityInputValue('')
      } else if (country_id === '') {
        updateLocalFormState.setFieldValue('state', '', true)
        updateLocalFormState.setFieldValue('city', '', true)
        setStateInputValue('')
        setCityInputValue('')
      }
    }
  }

  const onChangeStateAndCityInputValue = (
    newInputValue,
    reason,
    fieldValue,
    updateState
  ) => {
    if (reason === 'reset' && fieldValue === '') {
      updateState('')
      return
    }
    updateState(newInputValue)
  }

  const initialValues = {
    name: '',
    registration_no: '',
    url_slug_prefix: '',
    gst_no: '',
    about: '', // optional
    email: '',
    phone_number: '',
    address: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    logo_image: '', // optional,
    logo_image_name: '', // optional,
    poc_name: '',
    poc_email_id: '',
    poc_phone_no: '',
    poc_phone_country_code: '+91',
    commission_rate: '0',
    is_parent_bank: '1',
    // the below section is optional (either mention all or none),
    primary_account_no: '',
    confirmaccountno: '',
    primary_bank_name: '',
    primary_beneficiary_name: '',
    primary_branch_name: '',
    primary_ifsc_code: ''
  }

  const onSubmit = (values, actions) => {
    const submittedValues = Object.entries(values).reduce(
      (prevValue, [key, value]) => {
        switch (key) {
          case 'confirmaccountno':
            return prevValue

          case 'commission_rate':
            if (!value) {
              return {
                ...prevValue,
                [key]: '0'
              }
            }
            return {
              ...prevValue,
              [key]: value.trim()
            }

          case 'is_parent_bank':
            return {
              ...prevValue,
              [key]: bankDetailsMandatory.toString()
            }

          case 'phone_number':
            return {
              ...prevValue,
              [key]: `+91${value.trim()}`
            }

          case 'poc_phone_no':
            return {
              ...prevValue,
              [key]: `+91${value.trim()}`
            }

          default:
            return {
              ...prevValue,
              [key]: value.trim()
            }
        }
      },
      {}
    )

    addClinicProfile(submittedValues).then(data => {
      if (data) {
        setOpenCopyPasswordDialog({
          actions,
          email: submittedValues.email,
          password: data.password
        })
      }
    })
  }

  return (
    <React.Fragment>
      <SnackBarAlert
        message={errorMessageFromBackend || successMessageFromBackend}
        error={!successMessageFromBackend}
        closeSnackBar={() =>
          !!errorMessageFromBackend
            ? setMessageFromBackend({ message: null })
            : setMessageFromBackend({ message: null, success: true })
        }
      />
      <CopyPasswordDialog
        open={!!openCopyPasswordDialog}
        onClose={() => {
          const { actions = { resetForm: () => {} } } = openCopyPasswordDialog
          setOpenCopyPasswordDialog(false)
          actions.resetForm()
          onClickBackToClinicListButton()
        }}
        email={openCopyPasswordDialog?.email}
        password={openCopyPasswordDialog?.password}
      />
      <Page title="Add Clinic">
        <Loader
          label={loadMessage}
          open={networkStatus === NETWORK_STATUS.FETCHING}
        />
        <Box className="clinic-content-coll">
          <Box className="clinic-top">
            <Box className="back">
              <Button
                variant="text"
                style={{ textTransform: 'none' }}
                disabled={submittingTheForm}
                disableRipple
                backgroundTransparent
                onClick={onClickBackToClinicListButton}>
                <i class="fa fa-chevron-left"></i> Back to Clinic List
              </Button>
            </Box>
            <Box className="top-col">
              <img
                src={hospital}
                alt="clinic"
                style={{ display: 'block', height: '50px', width: '50px' }}
              />
              <h3>Clinic Registration Details</h3>
              <p>
                After details are submitted, confirmation email will be send
              </p>
            </Box>
          </Box>
          <Box className="clinic-field">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={yupValidation(bankDetailsMandatory === 0)}>
              <Form>
                <div style={{ display: 'flex' }}>
                  <h4>1. Clinic Details</h4>
                  {submittingTheForm && (
                    <div style={{ margin: '0.2rem 0 0 1rem' }}>
                      <CircularProgress size={30} />
                    </div>
                  )}
                </div>
                <Box className="clinic-details">
                  <Grid container spacing={3}>
                    <Grid item sm={9}>
                      <Grid container spacing={3}>
                        <Grid item sm={6}>
                          <Field name="name">
                            {({ field, meta: { touched, error } }) => (
                              <TextField
                                label="Name *"
                                {...field}
                                style={{ width: '100%' }}
                                error={touched && error}
                                helperText={<ErrorMessage name="name" />}
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={6}>
                          <Field name="registration_no">
                            {({ field, meta: { touched, error } }) => (
                              <TextField
                                label="Registration Number"
                                {...field}
                                style={{ width: '100%' }}
                                error={touched && error}
                                helperText={
                                  <ErrorMessage name="registration_no" />
                                }
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={6}>
                          <Field name="url_slug_prefix">
                            {({ field, meta: { touched, error } }) => (
                              <TextField
                                label="URL Prefix *"
                                {...field}
                                style={{ width: '100%' }}
                                error={touched && error}
                                helperText={
                                  <ErrorMessage name="url_slug_prefix" />
                                }
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={6}>
                          <Field name="gst_no">
                            {({ field, meta: { touched, error } }) => (
                              <TextField
                                label="GST Number"
                                {...field}
                                style={{ width: '100%' }}
                                error={touched && error}
                                helperText={<ErrorMessage name="gst_no" />}
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={12}>
                          <Field name="about">
                            {({ field, meta: { touched, error } }) => (
                              <TextArea
                                id="about"
                                label="About"
                                rows={4}
                                maxLength={200}
                                {...field}
                                style={{ width: '100%' }}
                                error={touched && error}
                                helperText={<ErrorMessage name="about" />}
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={6}>
                          <Field name="email">
                            {({ field, meta: { touched, error } }) => (
                              <TextField
                                label="Email *"
                                {...field}
                                style={{ width: '100%' }}
                                error={touched && error}
                                helperText={<ErrorMessage name="email" />}
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={6}>
                          <Field name="phone_number">
                            {({ field, meta: { touched, error } }) => (
                              <PhoneNumberField
                                id="phone"
                                label="Phone Number *"
                                {...field}
                                error={touched && error}
                                helperText={
                                  <ErrorMessage name="phone_number" />
                                }
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={12}>
                          <Field name="address">
                            {({ field, meta: { touched, error } }) => (
                              <TextArea
                                id="address"
                                rows={2}
                                label="Address *"
                                {...field}
                                style={{ width: '100%' }}
                                error={touched && error}
                                helperText={<ErrorMessage name="address" />}
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={6}>
                          <FormControl
                            className={classes.formControl}
                            style={{ width: '100%' }}>
                            <Field name="country">
                              {({ field, form, meta: { touched, error } }) => (
                                <Autocomplete
                                  getOptionSelected={(option, value) =>
                                    option.id === value.id
                                  }
                                  getOptionLabel={option =>
                                    option.name ? option.name : ''
                                  }
                                  options={countryList}
                                  loading={
                                    fetchingCountryListNetworkStatus ===
                                    NETWORK_STATUS.FETCHING
                                  }
                                  {...field}
                                  value={field.value.id}
                                  onChange={(e, newValue, reason) =>
                                    onCountryOrStateChange(
                                      {
                                        country_id:
                                          reason === 'clear' ? '' : newValue.id
                                      },
                                      form,
                                      reason
                                    )
                                  }
                                  disabled={submittingTheForm}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      label="Country *"
                                      variant="standard"
                                      error={touched && error}
                                      helperText={
                                        <ErrorMessage name="country" />
                                      }
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {fetchingCountryListNetworkStatus ===
                                            NETWORK_STATUS.FETCHING ? (
                                              <InputAdornment position="start">
                                                <CircularProgress
                                                  color="inherit"
                                                  size={20}
                                                  className={
                                                    classes.circularProgress
                                                  }
                                                />
                                              </InputAdornment>
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                        className: classes.inputButtonStyles
                                      }}
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item sm={6}>
                          <FormControl
                            className={classes.formControl}
                            style={{ width: '100%' }}>
                            <Field name="state">
                              {({ field, form, meta: { touched, error } }) => (
                                <Autocomplete
                                  getOptionSelected={(option, value) =>
                                    option.id === value.id
                                  }
                                  getOptionLabel={option =>
                                    option.name ? option.name : ''
                                  }
                                  options={stateList}
                                  noOptionsText="Select a country first"
                                  loading={
                                    fetchingStateListNetworkStatus ===
                                    NETWORK_STATUS.FETCHING
                                  }
                                  {...field}
                                  value={field.value.id}
                                  onChange={(e, newValue, reason) =>
                                    onCountryOrStateChange(
                                      {
                                        state_id:
                                          reason === 'clear' ? '' : newValue.id
                                      },
                                      form,
                                      reason
                                    )
                                  }
                                  inputValue={stateInputValue}
                                  onInputChange={(
                                    event,
                                    newInputValue,
                                    reason
                                  ) =>
                                    onChangeStateAndCityInputValue(
                                      newInputValue,
                                      reason,
                                      field.value,
                                      setStateInputValue
                                    )
                                  }
                                  disabled={submittingTheForm}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      label="State *"
                                      variant="standard"
                                      error={touched && error}
                                      helperText={<ErrorMessage name="state" />}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {fetchingStateListNetworkStatus ===
                                            NETWORK_STATUS.FETCHING ? (
                                              <InputAdornment position="start">
                                                <CircularProgress
                                                  color="inherit"
                                                  size={20}
                                                  className={
                                                    classes.circularProgress
                                                  }
                                                />
                                              </InputAdornment>
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                        className: classes.inputButtonStyles
                                      }}
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item sm={6}>
                          <FormControl
                            className={classes.formControl}
                            style={{ width: '100%' }}>
                            <Field name="city">
                              {({ field, form, meta: { touched, error } }) => (
                                <Autocomplete
                                  getOptionSelected={(option, value) =>
                                    option.id === value.id
                                  }
                                  getOptionLabel={option =>
                                    option.name ? option.name : ''
                                  }
                                  options={cityList}
                                  noOptionsText="Select a state first"
                                  loading={
                                    fetchingCityListNetworkStatus ===
                                    NETWORK_STATUS.FETCHING
                                  }
                                  {...field}
                                  value={field.value.id}
                                  onChange={(e, newValue, reason) => {
                                    if (reason === 'select-option') {
                                      form.setFieldValue(
                                        'city',
                                        newValue.id,
                                        true
                                      )
                                    } else if (reason === 'clear') {
                                      form.setFieldValue('city', '', true)
                                    }
                                  }}
                                  inputValue={cityInputValue}
                                  onInputChange={(
                                    event,
                                    newInputValue,
                                    reason
                                  ) =>
                                    onChangeStateAndCityInputValue(
                                      newInputValue,
                                      reason,
                                      field.value,
                                      setCityInputValue
                                    )
                                  }
                                  disabled={submittingTheForm}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      label="City *"
                                      variant="standard"
                                      error={touched && error}
                                      helperText={<ErrorMessage name="city" />}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {fetchingCityListNetworkStatus ===
                                            NETWORK_STATUS.FETCHING ? (
                                              <InputAdornment position="start">
                                                <CircularProgress
                                                  color="inherit"
                                                  size={20}
                                                  className={
                                                    classes.circularProgress
                                                  }
                                                />
                                              </InputAdornment>
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                        className: classes.inputButtonStyles
                                      }}
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item sm={6}>
                          <Field name="pincode">
                            {({ field, meta: { touched, error } }) => (
                              <TextField
                                label="Pincode *"
                                {...field}
                                style={{ width: '100%' }}
                                error={touched && error}
                                helperText={<ErrorMessage name="pincode" />}
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                      }}>
                      <Field name="logo_image">
                        {({ field, form, meta }) => (
                          <ImageInput
                            id="contained-button-file"
                            field={field}
                            form={form}
                            meta={meta}
                            submitting={submittingTheForm}
                          />
                        )}
                      </Field>
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          component="span"
                          disabled={submittingTheForm}
                          style={{
                            padding: '10px 30px'
                          }}>
                          Select
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </Box>

                <div style={{ display: 'flex' }}>
                  <h4>2. Poc Details</h4>
                  {submittingTheForm && (
                    <div style={{ margin: '0.2rem 0 0 1rem' }}>
                      <CircularProgress size={30} />
                    </div>
                  )}
                </div>
                <Box className="clinic-details">
                  <Grid container spacing={3}>
                    <Grid item sm={9}>
                      <Grid container spacing={3}>
                        <Grid item sm={4}>
                          <Field name="poc_name">
                            {({ field, meta: { touched, error } }) => (
                              <TextField
                                label="Poc Name *"
                                style={{ width: '100%' }}
                                {...field}
                                error={touched && error}
                                helperText={<ErrorMessage name="poc_name" />}
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={4}>
                          <Field name="poc_email_id">
                            {({ field, meta: { touched, error } }) => (
                              <TextField
                                label="Poc Email *"
                                style={{ width: '100%' }}
                                {...field}
                                error={touched && error}
                                helperText={
                                  <ErrorMessage name="poc_email_id" />
                                }
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item sm={4}>
                          <Field name="poc_phone_no">
                            {({ field, meta: { touched, error } }) => (
                              <PhoneNumberField
                                id="pocphone"
                                label="Poc Phone Number *"
                                {...field}
                                error={touched && error}
                                helperText={
                                  <ErrorMessage name="poc_phone_no" />
                                }
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <div style={{ display: 'flex' }}>
                  <h4>3. Commission Details</h4>
                  {submittingTheForm && (
                    <div style={{ margin: '0.2rem 0 0 1rem' }}>
                      <CircularProgress size={30} />
                    </div>
                  )}
                </div>
                <Box className="clinic-details">
                  <Grid container spacing={3}>
                    <Grid item sm={9}>
                      <Grid container spacing={3}>
                        <Grid item sm={6} style={{ position: 'relative' }}>
                          <Field name="commission_rate">
                            {({ field, meta: { touched, error } }) => (
                              <TextField
                                id="commission"
                                label="Commission Percentage"
                                style={{ width: '100%' }}
                                {...field}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <span
                                        style={{
                                          fontWeight: 600,
                                          color: 'rgb(147, 148, 154)'
                                        }}>
                                        %
                                      </span>
                                    </InputAdornment>
                                  )
                                }}
                                error={touched && error}
                                helperText={
                                  <ErrorMessage name="commission_rate" />
                                }
                                disabled={submittingTheForm}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <FormControlLabel
                  style={{ marginLeft: '40px', marginTop: '-40px' }}
                  control={
                    <Switch
                      color="primary"
                      checked={!!bankDetailsMandatory}
                      classes={{
                        root: classes.whenSwitchChecked
                      }}
                      onChange={() =>
                        setBankDetailsMandatory(cur => (!!cur ? 0 : 1))
                      }
                      disabled={submittingTheForm}
                    />
                  }
                  label="Use Superclinic Bank Details"
                />

                <Collapse in={!bankDetailsMandatory}>
                  <div style={{ display: 'flex' }}>
                    <h4>4. Bank Account Details</h4>
                    {submittingTheForm && (
                      <div style={{ margin: '0.2rem 0 0 1rem' }}>
                        <CircularProgress size={30} />
                      </div>
                    )}
                  </div>
                  <Box className="clinic-details">
                    <Grid container spacing={3}>
                      <Grid item sm={9}>
                        <Grid container spacing={3}>
                          <Grid item sm={6}>
                            <Field name="primary_account_no">
                              {({ field, meta: { touched, error } }) => (
                                <TextField
                                  label="Account No *"
                                  style={{ width: '100%' }}
                                  {...field}
                                  error={touched && error}
                                  helperText={
                                    <ErrorMessage name="primary_account_no" />
                                  }
                                  disabled={submittingTheForm}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item sm={6}>
                            <Field name="confirmaccountno">
                              {({ field, meta: { touched, error } }) => (
                                <TextField
                                  label="Confirm Account No *"
                                  style={{ width: '100%' }}
                                  {...field}
                                  error={touched && error}
                                  helperText={
                                    <ErrorMessage name="confirmaccountno" />
                                  }
                                  disabled={submittingTheForm}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item sm={6}>
                            <Field name="primary_beneficiary_name">
                              {({ field, meta: { touched, error } }) => (
                                <TextField
                                  label="Beneficiary Name *"
                                  style={{ width: '100%' }}
                                  {...field}
                                  error={touched && error}
                                  helperText={
                                    <ErrorMessage name="primary_beneficiary_name" />
                                  }
                                  disabled={submittingTheForm}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item sm={6}>
                            <Field name="primary_ifsc_code">
                              {({ field, meta: { touched, error } }) => (
                                <TextField
                                  label="IFSC Code *"
                                  style={{ width: '100%' }}
                                  {...field}
                                  error={touched && error}
                                  helperText={
                                    <ErrorMessage name="primary_ifsc_code" />
                                  }
                                  disabled={submittingTheForm}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item sm={6}>
                            <Field name="primary_bank_name">
                              {({ field, meta: { touched, error } }) => (
                                <TextField
                                  label="Bank Name *"
                                  style={{ width: '100%' }}
                                  {...field}
                                  error={touched && error}
                                  helperText={
                                    <ErrorMessage name="primary_bank_name" />
                                  }
                                  disabled={submittingTheForm}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item sm={6}>
                            <Field name="primary_branch_name">
                              {({ field, meta: { touched, error } }) => (
                                <TextField
                                  label="Branch Name *"
                                  style={{ width: '100%' }}
                                  {...field}
                                  error={touched && error}
                                  helperText={
                                    <ErrorMessage name="primary_branch_name" />
                                  }
                                  disabled={submittingTheForm}
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
                <Grid
                  item
                  sm={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: !bankDetailsMandatory ? -20 : 0
                  }}>
                  <Button
                    variant="contained"
                    component="button"
                    type="submit"
                    disabled={submittingTheForm}
                    style={{
                      padding: '0 5rem',
                      marginBottom: '1rem',
                      position: 'relative',
                      color: submittingTheForm ? 'transparent' : '#fff',
                      textTransform: 'uppercase'
                    }}>
                    Save
                    {submittingTheForm && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -40%)'
                        }}>
                        <CircularProgress size={30} />
                      </div>
                    )}
                  </Button>
                </Grid>
              </Form>
            </Formik>
          </Box>
        </Box>
      </Page>
    </React.Fragment>
  )
}

export default AddNewClinic

function yupValidation(bankDetailsMandatory = true) {
  let bankDetailsYup = {
    primary_account_no: Yup.string()
      .trim()
      .required('Account No is required')
      .matches(SINGLE_WHITESPACE, 'Remove extra spaces')
      .matches(VALIDATE_NUMERIC_VALUES, 'Only numbers are allowed')
      .min(9, 'Account number should be between 9 to 18 digits')
      .max(18, 'Account number should be between 9 to 18 digits'),

    confirmaccountno: Yup.string()
      .trim()
      .required('Confirm Account No is required')
      .oneOf(
        [Yup.ref('primary_account_no'), null],
        'Confirm Account No must match with Account No'
      )
      .matches(SINGLE_WHITESPACE, 'Remove extra spaces')
      .matches(VALIDATE_NUMERIC_VALUES, 'Only numbers are allowed')
      .min(9, 'Account number should be between 9 to 18 digits')
      .max(18, 'Account number should be between 9 to 18 digits'),

    primary_beneficiary_name: Yup.string()
      .trim()
      .required('Beneficiary Name is required')
      .matches(DOUBLE_WHITESPACES, 'Remove extra spaces')
      .matches(VALIDATE_ONLY_ALPHABETS, 'Only alphabets are allowed')
      .max(50, 'Character count exceeded the limit!'),

    primary_ifsc_code: Yup.string()
      .trim()
      .required('IFSC Code is required')
      .matches(SINGLE_WHITESPACE, 'Remove extra spaces')
      .matches(VALIDATE_IFSC_CODE, 'Please enter a valid IFSC code'),

    primary_bank_name: Yup.string()
      .trim()
      .required('Bank Name is required')
      .matches(DOUBLE_WHITESPACES, 'Remove extra spaces')
      .matches(VALIDATE_ONLY_ALPHABETS, 'Only alphabets are allowed')
      .max(50, 'Character count exceeded the limit!'),

    primary_branch_name: Yup.string()
      .trim()
      .required('Branch Name is required')
      .matches(DOUBLE_WHITESPACES, 'Remove extra spaces')
      .matches(VALIDATE_ONLY_ALPHABETS, 'Only alphabets are allowed')
      .max(50, 'Character count exceeded the limit!')
  }

  if (!bankDetailsMandatory) {
    bankDetailsYup = {}
  }

  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Name is required')
      .matches(DOUBLE_WHITESPACES, 'Remove extra spaces')
      .matches(
        VALIDATE_ALPHANUMERIC_VALUES,
        'Only alphabets and digits are allowed'
      )
      .max(30, 'Max 30 characters allowed'),

    registration_no: Yup.string()
      .trim()
      .matches(
        VALIDATE_ALPHANUMERIC_VALUES,
        'Only alphabets and digits are allowed'
      )
      .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),

    url_slug_prefix: Yup.string()
      .required('Prefix is required')
      .matches(SINGLE_WHITESPACE, 'Remove extra spaces')
      .matches(
        VALIDATE_URL_SLUG_PREFIX,
        'Only alphabets, numbers, hyphens, underscore and dots are allowed'
      ),

    gst_no: Yup.string()
      .trim()
      .length(15, 'Must be 15 characters')
      .matches(
        VALIDATE_ALPHANUMERIC_VALUES,
        'Only alphabets and digits are allowed'
      )
      .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),

    about: Yup.string()
      .trim()
      .max(200, 'Max 200 characters allowed')
      .matches(DOUBLE_WHITESPACES, 'Remove extra spaces'),

    email: Yup.string()
      .trim()
      .required('Email is required')
      .email('Invalid email address'),

    phone_number: Yup.string()
      .trim()
      .required('Mobile number is required')
      .matches(
        VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
        'Please enter a valid mobile number'
      ),

    address: Yup.string()
      .trim()
      .required('Address is required')
      .matches(DOUBLE_WHITESPACES, 'Remove extra spaces'),

    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),

    pincode: Yup.string()
      .trim()
      .required('Pincode is required')
      .matches(SINGLE_WHITESPACE, 'Remove extra spaces')
      .matches(VALIDATE_PIN_CODE, 'Incorrect pincode format'),

    poc_name: Yup.string()
      .trim()
      .required('Poc Name is required')
      .matches(DOUBLE_WHITESPACES, 'Remove extra spaces')
      .matches(
        VALIDATE_ALPHANUMERIC_VALUES,
        'Only alphabets and digits are allowed'
      )
      .max(30, 'Max 30 characters allowed'),

    poc_email_id: Yup.string()
      .trim()
      .required('Poc Email is required')
      .email('Invalid email address'),

    poc_phone_no: Yup.string()
      .trim()
      .required('Poc Phone Number is required')
      .matches(
        VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
        'Please enter a valid mobile number'
      ),

    commission_rate: Yup.number()
      .transform((value, originalValue) =>
        /\s/.test(originalValue.trim()) ? NaN : value
      )
      .typeError('Please enter a valid number without spaces')
      .max(100, 'Please enter a number between 0 and 100')
      .min(0, 'Please enter a number between 0 and 100'),

    ...bankDetailsYup
  })
}

const useStyles = makeStyles(theme => ({
  circularProgress: {
    position: 'relative',
    right: 50
  },
  inputButtonStyles: {
    '& > * button': {
      width: 30,
      height: 30
    }
  },
  whenSwitchChecked: {
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      background: 'rgb(17, 29, 78) !important'
    }
  }
}))
