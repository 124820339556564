import React, { useEffect, useState } from 'react'
import {
  Box,
  TableCell,
  makeStyles,
  withStyles,
  TableBody,
  Link,
  TableRow,
  Table,
  TableHead,
  Button
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import EditIcon from '@material-ui/icons/Edit'
import { HighlightOff } from '@material-ui/icons'
import localforage from 'localforage'
import moment from 'moment'
import { useParams } from 'react-router'
import NoRecordImg from '../../images/norecord.png'
import { PatientStore } from '../../stores'
import { NETWORK_STATUS, PATIENT_ID } from '../../Constants'
import AddMedicationModal from './AddMedicationModel'
import EditMedicationModal from './EditMedicationModel'
import AddLabOrders from './AddLabOrders'
import EditLabOrders from './EditLabOrders'
import ViewDocumentModal from './ViewDocumentModal'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#fff',
    color: 'rgba(55,55,55,0.6)',
    fontSize: '14px',
    fontFamily: 'Poppins',
    padding: '7px 5px !important'
    // borderTop: '1px rgba(224, 224, 224, 1) solid'
  },
  tbody: {
    color: '#3f4b79',
    '& td': {
      color: '#111d4e'
    },
    '& th': {
      fontSize: '0.8rem',
      fontWeight: '500'
    }
  }
}))(TableCell)

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f7f7',
      color: '#777777'
    },
    '& th': {
      fontSize: '0.8rem'
    },
    '& td': {
      fontSize: '0.8rem'
    }
  }
}))(TableRow)

//? Main Function
const Medication = props => {
  const classes = useStyles()
  const params = useParams()
  const disabled = props.disabled
  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [addLab, setAddLab] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmLabDeleteModal, setConfirmLabDeleteModal] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState({})
  const [selectedLab, setSelectedLab] = useState({})
  const [showEditLabModal, setShowEditLabModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState('')
  const [viewRecordModal, setViewRecordModal] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [deleteMed, setDeleteMed] = useState('')
  const [labRecords, setLabRecords] = useState([])
  const [statusText, setStatusText] = useState('')
  const [statusTitle, setStatusTitle] = useState('')
  const { medications, oldMedications, labs, networkStatus } =
    PatientStore.useStoreState(state => ({
      ...state.patients
    }))

  const { getPatientMedications, getLab, deleteLab, deleteMedication } =
    PatientStore.useStoreActions(actions => ({
      ...actions.patients
    }))

  const addMedicationClicked = () => {
    setAddModal(true)
  }
  const addLabOrdersClicked = () => {
    setAddLab(true)
  }

  useEffect(() => {
    getPatientMedications({ patientId: localStorage.getItem(PATIENT_ID) })
    getLab({ patientId: localStorage.getItem(PATIENT_ID) })
  }, [])

  const deleteClicked = medication => {
    setSelectedMedication(medication)
    setAlertOpen(true)
    setStatusTitle('Delete Medication')
    setStatusText(
      `Do you want to delete the Drug Name : ${medication?.proprietaryname} ?`
    )
    setDeleteMed(true)
  }

  const editClicked = medication => {
    setSelectedMedication(medication)
    setEditModal(true)
  }

  const labEditClicked = lab => {
    setSelectedLab(lab)
    setShowEditLabModal(true)
  }

  useEffect(() => {
    setLabRecords(labs)
  }, [labs])

  const labDeleteClicked = lab => {
    setSelectedLab(lab)
    setAlertOpen(true)
    setStatusTitle('Delete Lab Record')
    setStatusText(`Do you want to delete the Test name : ${lab?.testname}?`)
    setDeleteMed(null)
  }

  const viewLabClicked = record => {
    setSelectedRecord(record)
    setViewRecordModal(true)
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }
  const handleAlertClose = () => {
    setAlertOpen(false)
  }
  const validationCheck = () => {
    setAlertOpen(false)
    if (deleteMed) {
      deleteMedication({
        medicationId: selectedMedication.id,
        patientId: params.patient_id
      })
    } else {
      deleteLab({
        labId: selectedLab.labs_id,
        patientId: params.patient_id
      })
      setLabRecords([])
    }
    setOpenSnackBar(false)
  }

  let Lab_data = labRecords.sort((a, b) => {
    return new Date(b.prescribed_on) - new Date(a.prescribed_on)
  })

  return (
    <>
      <Box className={classes.InfoColTop}>
        <h3>Current Medications</h3>
        <button
          disabled={disabled}
          className={disabled ? classes.DisabledAddBtn : classes.AddBtn}
          onClick={addMedicationClicked}>
          Add New
        </button>
      </Box>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{statusTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{statusText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAlertClose} color="primary">
            Cancel
          </Button>
          <Button onClick={validationCheck} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box style={{ width: '100%', overflowX: 'auto' }}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Drug Name</StyledTableCell>
              <StyledTableCell align="left">Frequency</StyledTableCell>
              <StyledTableCell align="left">Duration</StyledTableCell>
              <StyledTableCell align="left">Instructions </StyledTableCell>
              <StyledTableCell align="left">Prescribed by</StyledTableCell>
              <StyledTableCell align="left">End Date</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {networkStatus !== NETWORK_STATUS.FETCHING
              ? medications?.map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.proprietaryname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.frequency}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.duration} days
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.instructions}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.prescribed_by == ' ' ? '' : row.prescribed_by}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(row.end_date).format('DD-MM-YYYY')}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Link
                        className={
                          disabled || row.prescribed_by !== ' '
                            ? classes.DisabledEditBtn
                            : classes.EditBtn
                        }
                        onClick={() => editClicked(row)}>
                        {disabled || row.prescribed_by !== ' ' ? null : (
                          <EditIcon />
                        )}
                      </Link>
                      <Link
                        className={
                          disabled || row.prescribed_by !== ' '
                            ? classes.DisabledDeleteBtn
                            : classes.DeleteBtn
                        }
                        onClick={() => deleteClicked(row)}>
                        {disabled || row.prescribed_by !== ' ' ? null : (
                          <HighlightOff />
                        )}
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {networkStatus === NETWORK_STATUS.FETCHING ? (
          <Skeleton variant={'rect'} height={40} width={'100%'} />
        ) : null}
        {networkStatus !== NETWORK_STATUS.FETCHING &&
        medications.length === 0 ? (
          <img
            src={NoRecordImg}
            className={classes.notFoundImage}
            alt={'Not found!'}
          />
        ) : null}
      </Box>
      <Box style={{ width: '100%', overflowX: 'auto' }}>
        <Box className={classes.InfoColTop}>
          <h3>Medications History</h3>
        </Box>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: '40%' }}>
                Drug Name
              </StyledTableCell>
              <StyledTableCell align="left">Frequency</StyledTableCell>
              <StyledTableCell align="left">Duration</StyledTableCell>
              <StyledTableCell align="left">Instructions</StyledTableCell>
              <StyledTableCell align="left">Prescribed by</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {networkStatus !== NETWORK_STATUS.FETCHING
              ? oldMedications?.map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.proprietaryname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.frequency}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.duration} days
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.instructions}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Dr {row.prescribed_by}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {networkStatus === NETWORK_STATUS.FETCHING ? (
          <Skeleton variant={'rect'} height={40} width={'100%'} />
        ) : null}
        {networkStatus !== NETWORK_STATUS.FETCHING &&
        oldMedications.length === 0 ? (
          <img
            src={NoRecordImg}
            className={classes.notFoundImage}
            alt={'Not found!'}
          />
        ) : null}
      </Box>
      <Box className={classes.LabReport}>
        <Box
          className={classes.InfoColTop}
          display={'flex'}
          justifyContent={'space-between'}>
          <h3> Labs </h3>
          <button
            disabled={disabled}
            className={disabled ? classes.DisabledAddBtn : classes.AddBtn}
            onClick={addLabOrdersClicked}>
            Add New
          </button>
        </Box>
        <Box style={{ width: '100%', overflowX: 'auto' }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.TableRow2}>
                  Test Name
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.TableRow2}>
                  Instructions
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.TableRow2}>
                  Prescribed by
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.TableRow2}>
                  Prescribed on
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.TableRow2}>
                  Document
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.TableRow2}>
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {networkStatus !== NETWORK_STATUS.FETCHING
                ? Lab_data?.map(row => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.testname}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.instructions}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.name == '' ? '' : `Dr.${row.name}`}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(row.prescribed_on).format('DD-MM-YYYY')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <button
                          // className={classes.AddBtn}
                          onClick={() => viewLabClicked(row.document_uri)}
                          disabled={row.document_uri === ''}
                          className={
                            row.document_uri === ''
                              ? classes.DisabledAddBtn
                              : classes.AddBtn
                          }>
                          View
                        </button>
                      </StyledTableCell>
                      {/* <StyledTableCell align="left"> */}
                      <StyledTableCell align="left">
                        <Link
                          className={
                            disabled || row.name !== ''
                              ? classes.DisabledEditBtn
                              : classes.EditBtn
                          }
                          onClick={() => labEditClicked(row)}>
                          {disabled || row.name !== '' ? null : <EditIcon />}
                        </Link>
                        <Link
                          className={
                            disabled || row.name !== ''
                              ? classes.DisabledDeleteBtn
                              : classes.DeleteBtn
                          }
                          onClick={() => labDeleteClicked(row)}>
                          {disabled || row.name !== '' ? null : (
                            <HighlightOff />
                          )}
                        </Link>
                      </StyledTableCell>
                      {/* </StyledTableCell> */}
                    </StyledTableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          {networkStatus === NETWORK_STATUS.FETCHING ? (
            <Skeleton variant={'rect'} height={40} width={'100%'} />
          ) : null}
          {networkStatus !== NETWORK_STATUS.FETCHING &&
          labRecords.length === 0 ? (
            <img
              src={NoRecordImg}
              className={classes.notFoundImage}
              alt={'Not found!'}
            />
          ) : null}
        </Box>
      </Box>
      <AddMedicationModal
        open={addModal}
        handleClose={() => setAddModal(false)}
      />
      {editModal ? (
        <EditMedicationModal
          open={editModal}
          handleClose={() => setEditModal(false)}
          defaultValues={selectedMedication}
        />
      ) : null}

      {addLab ? (
        <AddLabOrders
          open={addLab}
          handleClose={() => {
            setAddLab(false)
          }}
        />
      ) : null}

      {showEditLabModal ? (
        <EditLabOrders
          open={showEditLabModal}
          handleClose={() => setShowEditLabModal(false)}
          defaultValues={selectedLab}
        />
      ) : null}

      {viewRecordModal ? (
        <ViewDocumentModal
          open={viewRecordModal}
          handleClose={() => setViewRecordModal(false)}
          record={selectedRecord}
        />
      ) : null}
    </>
  )
}

const useStyles = makeStyles(() => ({
  notFoundImage: {
    margin: '20px auto',
    display: 'flex',
    height: '120px'
  },
  InfoColTop: {
    width: '100%',
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    '& h3': {
      fontSize: '15px',
      fontWeight: '600',
      color: '#505158',
      flexGrow: '10',
      marginBottom: '0px'
    }
  },
  AddBtn: {
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
    borderRadius: '8px',
    border: 'none',
    padding: '0 15px',
    height: '30px',
    textAlign: 'center',
    fontSize: '12px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  DisabledAddBtn: {
    background: '#adadad',
    borderRadius: '8px',
    border: 'none',
    height: '30px',
    textAlign: 'center',
    fontSize: '12px',
    padding: '0 15px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  table: {
    minWidth: '100%',
    '@media (max-width:767px)': {
      width: '800px',
      overflowX: 'auto'
    }
  },
  EditBtn: {
    float: 'left',
    cursor: 'pointer',
    color: '#3f4b79',
    marginRight: '5px',
    '&:hover': {
      textDecoration: 'none',
      color: '#3f4b79',
      cursor: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  DeleteBtn: {
    float: 'left',
    cursor: 'pointer',
    color: '#c10505',
    marginRight: '0',
    '&:hover': {
      textDecoration: 'none',
      color: '#c10505',
      cursor: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  DisabledEditBtn: {
    float: 'left',
    color: 'grey',
    marginRight: '5px',
    '&:hover': {
      textDecoration: 'none',
      color: 'grey',
      cursor: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  DisabledDeleteBtn: {
    float: 'left',
    cursor: 'pointer',
    color: 'grey',
    marginRight: '20px',
    '&:hover': {
      textDecoration: 'none',
      color: 'grey',
      curosr: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  LabReport: {
    marginTop: '40px'
  },
  TableRow2: {
    borderBottom: '0px !important',
    padding: '7px 10px !important',
    background: '#fff',
    fontSize: '14px'
  },
  TableRow: {
    background: '#fff !important',
    borderBottom: '0px !important'
  }
}))

export default Medication
