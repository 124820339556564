import { Query, DataManager } from "cn_scheduler/data";
/**
 * data module is used to generate query and data source.
 * @hidden
 */
var Data = /** @class */ (function () {
  /**
   * Constructor for data module
   * @private
   */
  function Data(dataSource, query) {
    this.initDataManager(dataSource, query);
  }
  /**
   * The function used to initialize dataManager and query
   * @return {void}
   * @private
   */
  Data.prototype.initDataManager = function (dataSource, query) {
    this.dataManager =
      dataSource instanceof DataManager
        ? dataSource
        : new DataManager(dataSource);
    this.query = query instanceof Query ? query : new Query();
  };
  /**
   * The function used to generate updated Query from schedule model
   * @return {void}
   * @private
   */
  Data.prototype.generateQuery = function (startDate, endDate) {
    var query = this.query.clone();
    if (startDate) {
      query.addParams("StartDate", startDate.toISOString());
    }
    if (endDate) {
      query.addParams("EndDate", endDate.toISOString());
    }
    return query;
  };
  /**
   * The function used to get dataSource by executing given Query
   * @param  {Query} query - A Query that specifies to generate dataSource
   * @return {void}
   * @private
   */
  Data.prototype.getData = function (query) {
    return this.dataManager.executeQuery(query);
  };
  return Data;
})();
export { Data };
