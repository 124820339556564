import { action, thunk } from 'easy-peasy'
import { NETWORK_STATUS } from '../../Constants'
import BaseModel from '../Base'
import api from '../../api'

export default {
  ...BaseModel(),
  data: [],
  scheduleForTheDay: [],
  selectedDate: '',

  fetchSchedule: thunk(async (actions, payload) => {
    actions.reset()
    const { doctor_id, schedule_type, date, consultationType } = payload
    if (date) {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      let response = await api.getDoctorScheduleSlots({
        doctor_id: doctor_id,
        appointment_type: consultationType.toString(),
        appointment_date: date,
        visit_type: '1',
        clinic_doc_assoc_id: payload?.clinic_doc_assoc_id
          ? payload?.clinic_doc_assoc_id
          : ''
      })
      if (response?.data?.status == 200) {
        let res = {
          data: response.data.slot_data,
          consultationType: consultationType,
          doctorId: doctor_id
        }
        //
        actions.fetched(res)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        let res = {
          data: [],
          consultationType: consultationType,
          doctorId: doctor_id
        }
        actions.fetched(res)
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  }),

  fetched: action((state, payload) => {
    let id = 0
    if (payload.data) {
      if (payload.data.length > 0) {
        state.data = payload.data.map(item => {
          id = id + 1
          return {
            ...item,
            scheduleType: payload.consultationType,
            appointment_id: payload.id,
            calendar_schedule_id: item.id,
            id: id
          }
        })
      } else {
        state.data = []
      }
    } else {
      state.data = []
    }
  }),

  reset: action(state => {
    state.data = []
  }),
  setScheduleForTheDay: action((state, payload) => {
    state.scheduleForTheDay = payload
  }),
  setSelectedDate: action((state, payload) => {
    state.selectedDate = payload
  })
}
