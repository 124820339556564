import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  API_REQUEST_DATETIME_FORMAT,
  SNACKBAR_ALERT_TYPES,
  DOCTOR_USER_ID
} from '../../Constants'
import {
  Tabs,
  Tab,
  AppBar,
  Paper,
  Box,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import Loader from '../CommonComponents/Loader'
import MuiAlert from '@material-ui/lab/Alert'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import PatientDetailchart from '../PatientDetailChart/PatientDetailchart'
import Demographics from '../PatientDetailDemographic/Demographics'
import PatientVisitSummaries from '../PatientVisitSummary/PatientVisitSummaries'
import PatientMedication from '../PatientMedication/PatientMedication'
import PatientDocuments from '../PatientDocument/PatientDocuments'
import Diagnosis from '../PatientDiagnosis/Diagnosis'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DoctorStore } from '../../stores'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />
// }

const PatientDetailsVitals = props => {
  const params = useParams()
  const disabled = props.completed
  const patientId = props.patientId
  const [open, setOpen] = React.useState(false)
  const [chartData, setChartData] = React.useState(null)

  const [values, setValues] = useState({
    diagnosis: '',
    observations: '',
    suggestions: '',
    clinical_notes: '',
    others: ''
  })
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [alignment, setAlignment] = React.useState('weekly')
  const [showVitalsHistory, setShowVitalsHistory] = useState(false)
  const [showAddVitals, setShowAddVitals] = useState(false)
  const [responseStatus, setResponse] = useState(false)

  const [value, setValue] = React.useState(0)

  const {
    getPatientVitals,
    deleteDemoGraphicData,
    getPatientGraphDetails,
    clearSnack
  } = DoctorStore.useStoreActions(actions => ({
    ...actions.patients
  }))

  const {
    patientVitals,
    addVitalsResponse,
    addMedicationsResponse,
    editMedicationsResponse,
    deleteMedicationsResponse,
    addLabDataResponse,
    editLabDataResponse,
    deleteLabDataResponse,
    patientVitalsGraph
  } = DoctorStore.useStoreState(state => ({
    ...state.patients
  }))

  const { successResponse } = DoctorStore.useStoreState(state => ({
    ...state.patients
  }))

  useEffect(() => {
    getPatientVitals({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id: patientId ? patientId : params.patient_id,
      start_time: startDate,
      end_time: endDate,
      list_type: 'chart',
      table_config_rows_per_page: 20,
      table_config_page_no: 1
    })

    return () => {
      clearSnack()
    }
  }, [])

  useEffect(() => {
    setResponse(successResponse)
  }, [successResponse])

  useEffect(() => {
    getPatientGraphDetails({
      patient_id: params.patient_id,
      type: alignment
    })
  }, [alignment])

  useEffect(() => {
    if (addVitalsResponse === undefined) {
      //
    } else if (addVitalsResponse?.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Vitals added successfully...')
      setmultiButton(false)
      setAlertType('success')
      getPatientGraphDetails({
        patient_id: params.patient_id,
        type: alignment
      })
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage(addVitalsResponse?.message.replace(/['"]+/g, ''))
      setmultiButton(false)
      setAlertType('error')
    }
  }, [addVitalsResponse])

  useEffect(() => {
    if (addMedicationsResponse === undefined) {
      //
    } else if (addMedicationsResponse?.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Medication Data added successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Medication Data not added successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [addMedicationsResponse])

  useEffect(() => {
    if (editMedicationsResponse === undefined) {
      //
    } else if (editMedicationsResponse?.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Medication Data updated successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Medication Data not updated successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [editMedicationsResponse])

  useEffect(() => {
    if (deleteMedicationsResponse === undefined) {
      //
    } else if (deleteMedicationsResponse?.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Drug Name deleted successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Error while deleting the drug from record!')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [deleteMedicationsResponse])

  useEffect(() => {
    if (addLabDataResponse === undefined) {
      //
    } else if (addLabDataResponse?.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Lab Data added successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Lab Data not added successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [addLabDataResponse])

  useEffect(() => {
    if (editLabDataResponse === undefined) {
      //
    } else if (editLabDataResponse?.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Lab Data updated successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Lab Data not updated successfully...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [editLabDataResponse])

  useEffect(() => {
    if (deleteLabDataResponse === undefined) {
      //
    } else if (deleteLabDataResponse?.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Lab Data deleted successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Error while deleting the lab record')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [deleteLabDataResponse])

  useEffect(() => {
    if (patientVitalsGraph?.length > 0) {
      let vitals = patientVitalsGraph.reduce(
        (accumulator, currentValue, index) => {
          accumulator.bmi.push([currentValue.desc, parseInt(currentValue.bmi)])
          accumulator.bp.push([
            currentValue.desc,
            parseInt(currentValue.dbp),
            parseInt(currentValue.sbp)
          ])
          accumulator.hrate.push([
            currentValue.desc,
            parseInt(currentValue.hrate)
          ])
          accumulator.oxy.push([currentValue.desc, parseInt(currentValue.oxy)])
          accumulator.resp.push([
            currentValue.desc,
            parseInt(currentValue.resp)
          ])
          accumulator.temp.push([
            currentValue.desc,
            parseInt(currentValue.temp)
          ])
          return accumulator
        },
        {
          bmi: [['', '']],
          bp: [['', '', '']],
          hrate: [['', '']],
          oxy: [['', '']],
          resp: [['', '']],
          temp: [['', '']]
        }
      )
      setChartData(vitals)
    }
  }, [patientVitalsGraph])

  const endDate = moment().format(API_REQUEST_DATETIME_FORMAT)
  const startDate = moment()
    .subtract(60, 'days')
    .format(API_REQUEST_DATETIME_FORMAT)

  const classes = useStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const buttonhandleChange = (event, newAlignment) => {
    setAlignment(newAlignment)
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  let patientVitalsData =
    patientVitals && patientVitals.length ? patientVitals : []

  const showVitalsModal = show => {
    if (show) {
      setShowAddVitals(true)
    }
  }

  const hideVitalsModal = () => {
    setShowAddVitals(false)
  }

  const showVitalsHistoryModal = showModal => {
    if (showModal) {
      setShowVitalsHistory(true)
    }
  }

  const hideVitalsHistoryModal = () => {
    setShowVitalsHistory(false)
  }

  return (
    <div>
      <Loader />

      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        autoHideDuration={5000}
      />

      <Paper elevation={3} className={classes.paper}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ paddingTop: '15px', overflowY: 'auto' }}>
          <AppBar position="static" className={classes.TabBar}>
            <Tabs
              style={{ background: 'none' }}
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example">
              <Tab
                className={classes.ActiveTab}
                label="Patient Vitals"
                {...a11yProps(0)}
              />
              <Tab label="Demographic" {...a11yProps(1)} />
              <Tab label="Visit Summaries" {...a11yProps(2)} />
              <Tab label="Medications / Labs" {...a11yProps(3)} />
              <Tab label="Documents" {...a11yProps(4)} />
              <Tab label="Diagnosis" {...a11yProps(5)} />
            </Tabs>
          </AppBar>

          {/* PatientDetailchart */}
          <PatientDetailchart
            classes={classes}
            showVitalsModal={showVitalsModal}
            hideVitalsModal={hideVitalsModal}
            VitalsHistoryModal={showVitalsHistoryModal}
            VitalsData={patientVitalsData}
            AddVitals={showAddVitals}
            value={value}
            showVitalsHistory={showVitalsHistory}
            hideVitalsHistoryModal={hideVitalsHistoryModal}
            handleChange={buttonhandleChange}
            params={params}
            alignment={alignment}
            TabPanel={TabPanel}
            chartData={chartData}
            disabled={disabled}
          />

          {/* PatientDetaildemographic */}
          <Demographics
            TabPanel={TabPanel}
            classes={classes}
            value={value}
            params={params}
            disabled={disabled}
          />

          {/* visit summaries component */}
          <PatientVisitSummaries
            TabPanel={TabPanel}
            value={value}
            disabled={disabled}
          />

          {/* PatientMedication */}
          <PatientMedication
            TabPanel={TabPanel}
            value={value}
            classes={classes}
            disabled={disabled}
          />

          {/* PatientDocuments */}
          <PatientDocuments
            classes={classes}
            TabPanel={TabPanel}
            value={value}
            disabled={disabled}
          />

          {/* PatientDiagnosis */}
          <Diagnosis
            TabPanel={TabPanel}
            value={value}
            values={values}
            classes={classes}
            params={params}
            disabled={disabled}
            appointmentStatus={props?.patientView?.status}
          />
        </Grid>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '15px',
    float: 'left',
    width: '100%',
    padding: '15px',
    borderRadius: '10px',
    background: 'none',
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.1)'
  },
  // TabBar: {
  //   backgroundColor: 'transparent',
  //   backgroundImage: 'linear-gradient(to right, #f5f5f5 , #f5f5f5)',
  //   padding: '0px',
  //   borderRadius: '5px',
  //   '& .MuiTab-textColorInherit.Mui-selected': {
  //     color: '#fff !important',
  //     background: '#9138aa !important',
  //     '@media (max-width:767px)': {
  //       fontSize: '14px',
  //       width: '200px',
  //       flexBasis: '200px'
  //     }
  //   },
  //   '& .MuiTab-textColorPrimary': {
  //     color: '#000',
  //     fontWeight: 'normal'
  //   }
  // },

  LabReport: {
    overflowX: 'auto',
    '& table': {
      '@media (max-width:767px)': {
        width: '700px'
      }
    }
  },
  // LabReport: {
  //   marginTop: '40px'
  // },
  patientVital: {
    fontWeight: '500',
    fontSize: 26
  },
  vitalName: {
    fontWeight: '400'
  },
  MedicationCol: {
    '& h3': {
      color: '#3f4b79',
      marginBottom: '0px'
    }
  },
  InfoRow: {
    marginBottom: '20px'
  },
  TableRow: {
    background: '#fff !important',
    borderBottom: '0px !important'
  },
  // TableRow2: {
  //   borderBottom: '0px !important',
  //   padding: '2px 0 !important'
  // },
  BtnRow: {
    display: 'flex'
  },
  InfoCol: {
    '& p': {
      fontSize: '14px',
      color: '#787878',
      marginBottom: '7px',
      display: 'block',
      '& span': {
        width: '200px',
        float: 'left'
      }
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: '600',
      color: '#414141',
      marginBottom: '20px'
    }
  },
  InfoColTop: {
    width: '100%',
    marginTop: '50px',
    display: 'flex',
    '@media (max-width:767px)': {
      marginTop: '20px'
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: '500',
      color: '#3f4b79',
      flexGrow: '10'
    }
  },
  EditBtn: {
    float: 'left',
    color: '#3f4b79',
    marginRight: '20px',
    '&:hover': {
      textDecoration: 'none',
      color: '#3f4b79',
      cursor: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },

  DeleteBtn: {
    float: 'left',
    color: '#c10505',
    marginRight: '0',
    '&:hover': {
      textDecoration: 'none',
      color: '#c10505',
      cursor: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  AddBtn: {
    background: '#111d4e',
    borderRadius: '50px',
    border: 'none',
    width: '115px',
    height: '30px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  DisabledAddBtn: {
    background: '#ADADAD',
    borderRadius: '50px',
    border: 'none',
    width: '115px',
    height: '30px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  customized: {
    padding: '0px'
  },
  TabContent: {
    '& .MuiTable-root': {
      width: '100%',
      overflowX: 'auto'
    },
    '@media (max-width:767px)': {
      width: '100%',
      overflowX: 'auto'
    }
  },
  table: {
    minWidth: '100%',
    '@media (max-width:767px)': {
      width: '700px'
    }
  },
  vitals: {
    float: 'left',
    width: '100%',
    fontWeight: 'normal'
  },
  dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },
  dashboard: {
    maxWidth: '75%',
    flexBasis: '75%',
    '@media (max-width:1024px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '15px'
    }
  },
  AreaChart: {
    '& svg rect': {
      fill: '#fff'
    }
  },
  ContentCol: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '15px',
    marginTop: '20px'
  },
  PatientTop: {
    backgroundColor: '#f7f6f6',
    margin: '15px 0'
  },
  PatientInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '15px'
  },
  PatientData: {
    paddingRight: '20px',
    '& h2': {
      color: '#3f4b79',
      marginBottom: '15px',
      fontSize: '14px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  VisitedBtn: {
    textAlign: 'center',
    backgroundColor: '#3f4b79',
    borderRadius: '40px',
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#3f4b79',
      boxShadow: 'none'
    }
  },
  PatientInfoRight: {
    display: 'flex',
    flexDirection: 'flex-end',
    alignItems: 'center'
  },
  PatientStatus: {
    marginRight: '80px',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      marginBottom: '20px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  PatientProfile: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    padding: '5px',
    '& img': {
      width: '100%'
    }
  },
  TabBar: {
    backgroundColor: 'transparent',
    fontFamily: 'Poppins !important',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '& button': {
      backgroundColor: 'transparent',
      fontFamily: 'Poppins !important',
      marginRight: '15px',
      textTransform: 'capitalize',
      color: '#000'
    },
    '& .MuiTabScrollButton-root': {
      color: 'black'
    }
  },
  PatientChart: {
    display: 'flex',
    marginBottom: '20px',
    width: '100%',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      width: '100%',
      marginRight: '30px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  GrapTop: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexWrap: 'wrap'
    },
    '& h4': {
      fontSize: '14px',
      color: '#414141',
      '& span': {
        fontSize: '12px',
        color: '#999'
      }
    }
  },
  BtnCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '75%'
  },
  ToggleBtn: {
    textTransform: 'capitalize',
    '& button': {
      textTransform: 'capitalize',
      padding: '3px 15px'
    }
  },
  ActiveBtn: {
    backgroundColor: '#eff7ff !important',
    borderColor: '#3d8ff7 !important',
    color: '#3d8ff7'
  },
  ChartBox: {
    backgroundColor: '#fff',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    borderRadius: '5px',
    marginBottom: '15px'
  },
  submitButton: {
    color: '#fff',
    borderRadius: '25px'
  },
  Input: {
    width: '100%'
  },
  Input1: {
    margin: theme.spacing(1),
    width: '70%'
  },

  InputCol: {
    width: '100%',
    display: 'flex',
    marginBottom: '0',
    justifyContent: 'center',
    '& p': {
      fontSize: '18px',
      lineHeight: '55px',
      // lineHeight: '40px',
      marginRight: '10px',
      width: '90px'
    }
  },
  ModalForm: {
    width: '100%',
    padding: '40px;'
  },
  ModalBox: {
    width: '500px',
    background: '#fff',
    padding: '0px',
    height: '600px',
    '& h2': {
      textAlign: 'center',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  DownloadBtn: {
    width: '120px',
    background: '#53caf1',
    height: '35px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '35px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '50px',
    border: 'none',
    cursor: 'pointer'
  },
  button: {
    height: 40,
    width: 40,
    padding: '8px',
    background: '#3f4b79',
    marginLeft: 'auto',
    alignSelf: 'right',
    float: 'right',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: '#3f4b79'
    }
  },
  vitalsCard: {
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    marginTop: '10px',
    height: 'auto',
    padding: '0px 15px',
    '& h5': {
      color: '#000000',
      float: 'left'
    }
  },
  disabledIcon: {
    height: 40,
    width: 40,
    padding: '8px',
    background: '#ADADAD',
    marginLeft: 'auto',
    alignSelf: 'right',
    float: 'right',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: '#ADADAD'
    }
  }
}))

export default PatientDetailsVitals
