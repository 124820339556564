import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Profile from './Profile'
import NavLinks from '../CommonComponents/NavLinks'
import PatientProfile from '../CommonComponents/PatientProfile'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {
  DOCTOR_DRAWER_ITEMS,
  PATIENT_DRAWER_ITEMS,
  PATIENT_ID
} from '../../Constants'
import { useHistory } from 'react-router-dom'
import MenuIcon from '../../images/menu.png'
import Logo from '../../images/logo.png'
import CardBgImg from '../../images/right-top-img3.png'

const Navbar = () => {
  const classes = useStyles()

  const { push } = useHistory()

  const listItemClicked = (path, action) => {
    // console.log('clicked')
    action()
    push(path)
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }
  let MENU_DRAWER = localStorage.getItem(PATIENT_ID)
    ? PATIENT_DRAWER_ITEMS
    : DOCTOR_DRAWER_ITEMS

  let path = window.location.pathname
  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      {localStorage.getItem(PATIENT_ID) ? (
        <PatientProfile sideNav={'sideNav'} />
      ) : (
        <Profile sideNav={'sideNav'} />
      )}
      <List>
        {MENU_DRAWER.map((item, index) => (
          <ListItem
            button
            key={item.id}
            onClick={() => listItemClicked(item.path, item.action)}>
            <Link></Link>
            <ListItemText primary={item.label} className={classes.navtext} />
          </ListItem>
        ))}
      </List>
    </div>
  )
  return (
    <>
      <header className={classes.header}>
        <Grid container alignItems="center" spacing={0}>
          <Grid item sm={3} className={classes.Logo}>
            <Box className={classes.ResponsiveNav}>
              {['left'].map(anchor => (
                <React.Fragment key={anchor}>
                  <Button
                    className={classes.Responsivebtn}
                    onClick={toggleDrawer(anchor, true)}>
                    <img src={MenuIcon} alt="nav" />
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}>
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </Box>
            {path !== '/patient/selectPatient' ? (
              <Link to="/">
                <img className={classes.LogoImg} src={Logo} alt="Logo" />
              </Link>
            ) : (
              <div>
                <img className={classes.LogoImg} src={Logo} alt="Logo" />
              </div>
            )}
          </Grid>{' '}
          <Grid item sm={9} className={classes.nav}>
            <Typography component="h3" className={classes.welcome}></Typography>
            <NavLinks />
          </Grid>
        </Grid>
      </header>
      <div className={classes.bottomMargin} />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    background: '#fff',
    padding: '5px 15px',
    height: '76px',
    zIndex: '999',
    top: '0px',
    left: '0px !important',
    '@media (max-width:767px)': {
      height: 'auto'
    }
  },

  ResponsiveNav: {
    display: 'none',
    '@media (max-width:1024px)': {
      display: 'block',
      marginRight: '10px',
      marginTop: '5px'
    }
  },
  Responsivebtn: {
    padding: '0px',
    minWidth: 'auto',
    position: 'relative',
    zIndex: '99999999999999'
  },
  LogoImg: {
    height: '60px',
    '@media (max-width:1024px)': {
      height: '60px'
    }
  },
  Logo: {
    '@media (max-width:1024px)': {
      height: 'auto',
      maxWidth: '30%',
      flexBasis: '30%',
      marginLeft: '20px',
      paddingLeft: 0,
      display: 'flex'
    },
    '@media (max-width:550px)': {
      maxWidth: '50%',
      flexBasis: '50%',
      marginLeft: 0
    }
  },
  welcome: {
    fontWeight: 700,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:550px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      justifyContent: 'center'
    }
  },
  Modalslide: {
    width: '300px',
    height: '100vh',
    background: '#fff',
    position: 'fixed',
    left: '0px',
    zIndex: '999'
  },
  hospitalName: {
    color: theme.palette.primary.main,
    fontSize: '.75rem'
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:1024px)': {
      height: 'auto',
      maxWidth: '65%',
      flexBasis: '65%',
      paddingRight: '10px !important'
    },
    '@media (max-width:550px)': {
      flexDirection: 'column',
      maxWidth: '100%',
      flexBasis: '100%',
      paddingRight: '0px !important'
    }
  },
  bottomMargin: {
    marginBottom: '0px',
    '@media (max-width:767px)': {
      marginBottom: 0
    },
    '@media (max-width:550px)': {
      marginBottom: 0
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  list: {
    width: 250,
    height: '100vh',
    background: `url(${CardBgImg}) center top no-repeat`,
    backgroundSize: 'cover',
    position: 'fixed',
    overflowY: 'auto',
    zIndex: '99999',
    '@media (max-width:767px)': {
      paddingBottom: '60px'
    }
  },
  fullList: {
    width: 'auto',
    background: `url(${CardBgImg}) center top no-repeat`,
    backgroundSize: 'cover'
  },
  navtext: {
    color: '#fff'
  }
}))

export default Navbar
