import React, { useState } from 'react'
import { InputAdornment } from '@material-ui/core'

import TextField from './TextField'

import indianFlag from '../../images/flag.png'

const PhoneNumberField = ({ label, id, focusNow = false, ...rest }) => {
  const [focused, setFocused] = useState(false)

  let frontInputComponent = {
    startAdornment: (
      <InputAdornment position="start">
        <img
          src={indianFlag}
          alt=""
          width="25"
          height="25"
          style={{ marginRight: '0.4rem' }}
        />
        <span
          style={{
            color: 'rgb(17, 29, 78)',
            fontSize: '92%'
          }}>
          +91
        </span>
      </InputAdornment>
    )
  }

  if (!focused && !rest.value) {
    frontInputComponent = {}
  }

  return (
    <TextField
      id={id}
      focusNow={focusNow}
      label={label}
      style={{ width: '100%' }}
      InputProps={{
        ...frontInputComponent,
        onFocus: () => {
          setFocused(true)
        },
        onBlur: e => {
          if (rest.onBlur) {
            rest.onBlur(e)
          }
          setFocused(false)
        }
      }}
      {...rest}
    />
  )
}

export default PhoneNumberField
