import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    boxShadow: '0 1px 7px 0 rgba(0,0,0,0.1)'
  },
  avatar: {
    background: 'linear-gradient(45deg, #063bc4, #680fad)',
    height: 45,
    width: 45
  }
}))

const TotalProfit = ({ className, data, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              style={{ fontSize: '1rem' }}>
              Total Offline Patient
            </Typography>
            <Typography color="textPrimary" variant="h5">
              {data?.inperson_patient ? data?.inperson_patient : '0'}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PersonIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

TotalProfit.propTypes = {
  className: PropTypes.string
}

export default TotalProfit
