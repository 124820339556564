import React, { useCallback, useMemo } from 'react'
import clsx from 'clsx'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core'

import moment from 'moment'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import { RAZOR_KEY } from '../../../../Environment'
import ReactGA from 'react-ga'
import RuppeeIcon from '../../../../images/rupee.png'
import { ClinicStore } from '../../../../stores'
import localforage from 'localforage'
// import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => ({
  root: {
    width:'100%',
    boxShadow:'0 1px 10px 0 rgba(0,0,0,0.1)'
  },
  button: {
    padding: '10px 8px',
    fontWeight: '500',
    letterSpacing: '0',
    textTransform: 'none',
    justifyContent: 'flex-start',
    backgroundColor: '#111d4e',
    color: '#aeaeae',
    marginLeft: 'auto',
    display: 'block'
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  plansContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    justifyContent: 'space-around'
  },
  cardContainer: {
    width: '250px'
  },
  activeCheck: {
    border: '1px solid black',
    borderRadius: ' 50%',
    fontSize: '14px',
    padding: '5px'
  },
  disabled: {
    pointerEvents: 'none',
    background: '#e3e3e3'
  },
  activePlan: {
    backgroundColor: '#9138aa',
    '& p, h3': {
      color: '#fff !important'
    }
  },
  card: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    boxShadow: 'none',
    '& MuiCardContent.Mui-selected': {
      color: 'red'
    }
  }
}))

const snackBarInitialState = {
  isOpen: false,
  message: '',
  alertType: '',
  multibutton: false,
  primaryClick: () => {}
}

const Subscription = ({
  className,
  // planData,
  // profileDetails,
  // getSubscriptions,
  // plans,
  // activateSubscription,
  // activationResponse,
  // initializePayment,
  // transactionDetails,
  // clinicVerifyPayment,
  // paymentVerificationResponse,
  // getClinicProfileDetails,
  ...rest
}) => {
  const classes = useStyles()

  const [planData, setPlanData] = React.useState(null)
  const [alert, ButtonAlert] = React.useState(false)

  const {
    getClinicSubscriptionDetails,
    getClinicProfileDetails
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const {
    networkStatus,
    clinicSubscription,
    profileDetails,
    getSubscriptionList
  } = ClinicStore.useStoreState(state => ({
    ...state.profile
  }))

  const { inactiveCount } = ClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))

  const { getDoctorList } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))

  const queryParams = window.location.pathname

  React.useEffect(() => {
    getClinicSubscriptionDetails()
    subscriptionPlanCheck()
    getClinicProfileDetails({ cachedValues: false }).then(() => {
      getClinicProfileDetails({ cachedValues: true })
    })
    getDoctorList({ filterInactive: true })
  }, [])

  const subscriptionPlanCheck = () => {
    localforage
      .getItem('subscription_details')
      .then(data => {
        // let end_date = moment(data.next_due_date, 'YYYY-MM-DD').format(
        //   'DD/MM/YYYY'
        // )

        // Time difference
        let day_diff = Math.abs(
          new Date().getTime() - new Date(data.next_due_date).getTime()
        )

        // days difference
        let days = Math.ceil(day_diff / (1000 * 3600 * 24))

        // let end_date = '2021-02-22'
        // let days = moment().diff(moment(end_date), 'days')
        // console.log(days)
        if (days <= 3) {
          ButtonAlert(!alert)
        }
      })
      .catch(err => {
        // console.log(err)
      })
  }

  let today = moment().valueOf()

  const subcribe = () => {
    // if (doctorList.length === 0) {
    //   Swal.fire({
    //     icon: 'info',
    //     title: 'Oops...',
    //     text:
    //       'No Doctor found in this Clinic! Add a Doc in the registration or contact the helpline number'
    //   })
    //   return
    // }
    window.location.href = '/clinic/payment'
  }

  React.useEffect(() => {
    if (clinicSubscription) {
      setPlanData(clinicSubscription)
    }
  }, [clinicSubscription])

  const isExpired =
    moment(
      planData?.clinic_wallet_Data?.next_due_date,
      'YYYY-MM-DD'
    ).valueOf() < today

  const billing_start_date = useMemo(() => {
    let date = moment(
      planData?.clinic_wallet_Data?.billing_start_date,
      'YYYY-MM-DD'
    ).format('DD/MM/YYYY')
    return date == 'Invalid date' ? 'N/A' : date
  }, [planData?.clinic_wallet_Data?.billing_start_date])

  const billing_end_date = useMemo(() => {
    let date = moment(
      planData?.clinic_wallet_Data?.next_due_date,
      'YYYY-MM-DD'
    ).format('DD/MM/YYYY')
    return date == 'Invalid date' ? 'N/A' : date
  }, [planData?.clinic_wallet_Data?.next_due_date])

  let paymentStatus = null
  if (planData?.statusCode !== 200) {
    if (
      moment(new Date()).isAfter(profileDetails?.trial_end_date) &&
      (profileDetails?.next_due_date === '' ||
        moment(new Date()).isAfter(profileDetails?.next_due_date))
    ) {
      paymentStatus = 'Not-Paid'
    } else {
      paymentStatus = 'Trial Period'
    }
  } else {
    if (
      planData?.clinic_wallet_Data?.billing_end_date === '' ||
      moment(new Date()).isAfter(planData?.clinic_wallet_Data?.billing_end_date)
    ) {
      paymentStatus = 'Not-Paid'
    } else {
      paymentStatus = 'Paid'
    }
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <h3 style={{ padding:'20px 15px 0', marginBottom:'0px', fontWeight:'600'}}>Subscription Plan</h3>
      <CardContent className={classes.flex} style={{marginTop:'0'}}>
        <div>
          <p style={{marginBottom:'0px', marginTop:'0px', fontSize:'14px', textAlign:'left'}}>
          <span style={{fontWeight:'600'}}>Plan Type:&nbsp;</span>{' '}
          <span style={{ textTransform: 'capitalize' }}>
            {planData?.clinic_wallet_Data?.display_name.replace(/[_-]/g, ' ')}{' '}
          </span>{' '}
          {planData?.message}
          </p>
         
          <p style={{marginBottom:'0px', fontSize:'14px'}}>
          <span style={{fontWeight:'600'}}>Status:&nbsp;</span>
          <span>{paymentStatus}</span>
          {/* {moment(new Date()).isAfter(profileDetails?.trial_end_date) &&
            (profileDetails?.next_due_date === '' ||
              moment(new Date()).isAfter(profileDetails?.next_due_date)) && (
              <span>Not-Paid</span>
            )}
          {moment(new Date()).isBefore(profileDetails?.next_due_date) && (
            <span>Paid</span>
          )}
          {moment(new Date()).isBefore(profileDetails?.trial_end_date) &&
            profileDetails?.next_due_date === '' && <span>Trial Period</span>} */}
          {/* {planData?.statusCode == 422 || planData?.statusCode == 500 ? (
            <span>Trial Period</span>
          ) : (
            <span>
              {planData?.clinic_wallet_Data?.status == '1'
                ? 'Paid'
                : 'Not-Paid'}
            </span>
          )} */}
          {/* {planData?.clinic_wallet_Data?.status == 0 ? (
             <span>
               Unpaid{' '}
               <i
                className={`fa fa-times ${classes.activeCheck}`}
                style={{ background: 'rgb(241 61 61)', color: 'white' }}
              />{' '}
            </span>
          ) : (
            <span>
              Paid{' '}
              <i
                className={`fa fa-check ${classes.activeCheck}`}
                style={{ background: '#379b37', color: 'white' }}
              />{' '}
            </span>
          )} */}
          </p>
         
          <p style={{fontSize:'14px'}}>
          <span style={{fontWeight:'600'}}>Bill Cycle:&nbsp;</span>
          <span>From: {billing_start_date}</span> &nbsp;{' '}
          <span>To: {billing_end_date}</span>
          </p>
         
          
          <i style={{ color: '#a3a3a3', fontSize:'12px', marginBottom:'15px' }}>
            It may take up to 24 hours for your account to reflect changes
          </i>
        </div>
        {(planData?.clinic_wallet_Data?.subscription_start_date == null ||
          inactiveCount > 0 ||
          alert) &&
        queryParams == '/clinic/wallet' ? (
          <button className={classes.button} onClick={subcribe}>
            Activate Plan
          </button>
        ) : queryParams == '/clinic/wallet' ? (
          <button
            className={`${classes.button} ${!isExpired && classes.disabled}`}
            onClick={subcribe}>
            Renew Plan
          </button>
        ) : null}

        {/* {(planData?.clinic_wallet_Data?.subscription_start_date != null || !alert) &&
        queryParams == '/clinic/wallet' ? (
          <button
            className={`${classes.button} ${!isExpired && classes.disabled}`}
            onClick={subcribe}>
            Renew Plan
          </button>
        ) : null} */}
      </CardContent>
      <Divider />
    </Card>
  )
}

export default Subscription
