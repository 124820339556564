import React, { useEffect, useState, useRef, useCallback } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import SearchIcon from '@material-ui/icons/Search'

import TextField from '../../components/TextField'
import { NETWORK_STATUS } from '../../../Constants'

const SearchField = ({
  value = '',
  setValue = () => {},
  apiCall = async () => {},
  disabled = false,
  initNetworkStatus = null
}) => {
  const initLoad = useRef(false)
  
  const [loading, setLoading] = useState(false)
  const [takeInputDisabled, setTakeInputDisabled] = useState(true)

  const sendApiRequestAndSetLoader = useCallback(async () => {
    setLoading(true)
    await apiCall({ searchString: value })
    setLoading(false)
  }, [value, apiCall])

  useEffect(() => {
    if (initNetworkStatus === NETWORK_STATUS.SUCCESS) {
      setTakeInputDisabled(false)
    }
  }, [initNetworkStatus])

  useEffect(() => {
    let timer = null

    if (value !== '') {
      initLoad.current = true
    }

    if (initLoad.current) {
      timer = setTimeout(sendApiRequestAndSetLoader, 500)
    }

    return () => {
      clearTimeout(timer)
      timer = null
    }
  }, [value, sendApiRequestAndSetLoader])

  return (
    <TextField
      disabled={takeInputDisabled ? disabled : false}
      label="Search by doctor name, email or phone no."
      style={{ width: '100%' }}
      value={value}
      onChange={e => setValue(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {loading && <CircularProgress size={20} />}
            <SearchIcon style={{ marginLeft: '0.3rem' }} />
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchField
