import Auth from './Auth'
import Profile from './Profile'
import DoctorDetails from './DoctorDetails'
import Services from './Services'
import Appointment from './Appointments'

export default {
  auth: Auth,
  profile: Profile,
  doctorDetails: DoctorDetails,
  services: Services,
  appointment: Appointment
}
