import React from 'react'
import PatientDiagnosis from './PatientDiagnosis'

const Demographics = props => {
  const { TabPanel, value, classes, disabled, params } = props
  return (
    <React.Fragment>
      <TabPanel
        value={value}
        index={5}
        className={classes.MedicationCol}
        style={{
          background: '#fff',
          borderRadius: 15
        }}>
        <PatientDiagnosis
          params={params}
          classes={classes}
          disabled={disabled}
          appointmentStatus={props.appointmentStatus}
        />
      </TabPanel>
    </React.Fragment>
  )
}

export default Demographics
