import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import moment from 'moment'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { IoIosArrowBack } from 'react-icons/io'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import swal from 'sweetalert'
import { useFormik } from 'formik'
import ReactGA from 'react-ga'
import Swal from 'sweetalert2'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import Loader from '../../../components/ClinicLoader'
import { RAZOR_KEY } from '../../../../Environment'
import DashboardLayoutImg from '../../../../images/bg2.png'
import PaymentSuccesImg from '../../../../images/thankyou_icon.png'
import PaymentFailureImg from '../../../../images/failedicon.png'
import AppointmentListItem from '../../../../components/PatientAppointment/AppointmentListItem'

import {
  NETWORK_STATUS,
  DOCTOR_ID,
  PATIENT_ID,
  VISIT_TYPE,
  LIST_TYPE,
  BOOKING_STATUS,
  VALIDATE_WHITESPACES,
  APPOINTMENT_TYPE
} from '../../../../Constants'
import { ClinicStore } from '../../../../stores'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '10px 0px'
  },
  TopNav: {
    width: '96%',
    padding: '0 2%'
  },
  ProfilePicture: {
    width: '100px',
    height: '100px',
    borderRadius: '50%'
  },
  bottomMargin: {
    marginBottom: '0px !important'
  },
  DoctorProfile: {
    width: '95%',
    background: '#3f4c79',
    padding: '20px 2.5%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '@media (max-width:767px)': {
      padding: '15px 2.5%',
      width: '95%',
      marginTop: '20px'
    },
    '@media (max-width:480px)': {
      padding: '15px 3%',
      width: '94%',
      marginTop: '0px'
    }
  },
  CameraIcon: {
    color: '#a0d65e',
    fontSize: '24px',
    marginRight: '10px'
  },
  ProfileInfo: {
    marginLeft: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h4': {
      color: '#a0d65e',
      fontSize: '16px',
      marginBottom: '0px'
    },
    '& p': {
      fontSize: '16px',
      color: '#fff',
      marginBottom: '0px'
    },
    '& h3': {
      fontSize: '20px',
      color: '#fff',
      margin: '0px'
    }
  },
  InfoText: {
    width: '100%',
    padding: '30px 0',
    '& h3': {
      fontSize: '22px',
      color: '#3f4c79',
      fontWeight: 'bold',
      '& span': {
        width: '200px',
        fontSize: '16px',
        fontWeight: 'normal',
        float: 'left'
      }
    }
  },
  DetailInfo: {
    color: '#fff',
    fontSize: '15px',
    marginTop: '0px',
    marginRight: '30px',
    '@media (max-width:550px)': {
      marginTop: '20px'
    },
    '&>div': {
      display: 'flex'
    },
    '& h5': {
      marginTop: '5px',
      marginLeft: '20px',
      fontSize: '15px',
      marginBottom: '10px',
      '@media (max-width:780px)': {
        marginLeft: '0px'
      }
    },
    '& h4': {
      marginLeft: '20px',
      marginTop: '5px',
      marginBottom: '0px',
      '@media (max-width:780px)': {
        marginLeft: '0px'
      }
    }
  },
  Datecol: {
    '& h4': {
      marginLeft: '20px',
      color: '#fff',
      marginTop: '5px',
      marginBottom: '0px',
      '@media (max-width:780px)': {
        marginLeft: '0px'
      }
    }
  },
  DoctorActive: {
    color: '#fff',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '30px'
  },
  DoctorSearch: {
    background: '#FAFAFA',
    padding: '0',
    width: '100%',
    marginTop: '-80px',
    overflowX: 'hidden',
    justifyContent: 'center',
    '@media (max-width:780px)': {
      width: '100%'
    }
  },
  BtnCol: {
    width: '100%',
    padding: '30px 0',
    '& h4': {
      fontSize: '20px',
      color: '#3f4c79',
      marginBottom: '20px'
    }
  },
  ProceedBtn: {
    width: '150px',
    height: '40px',
    background: '#3f4c79',
    borderRadius: '40px',
    textAlign: 'center',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '40px',
    border: 'none',
    cursor: 'pointer'
  },
  dashboardBtn: {
    width: '150px',
    height: '40px',
    background: '#3f4c79',
    borderRadius: '40px',
    textAlign: 'center',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '40px',
    border: 'none',
    cursor: 'pointer'
  },
  ContentText: {
    fontSize: '16px',
    color: '#979797'
  },
  button: {
    height: 43,
    width: 43,
    // float:"right",
    marginLeft: '0',
    marginTop: 15,
    background: '#3f4c79',
    alignSelf: 'center',
    position: 'absolute',
    right: '30px',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: '#3f4c79'
    }
  },
  ContentCol: {
    width: '40%',
    minHeight: '500px',
    padding: '0 3%',
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  ContentColFollowup: {
    width: '44%',
    minHeight: '500px',
    padding: '0 3%',
    marginTop: '5%',
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  ContentPic: {
    width: '94%',
    minHeight: '500px',
    padding: '0 3%',
    textAlign: 'center',
    '@media (max-width:767px)': {
      width: '94%'
    }
  },
  FormElement: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '15px',
    '& p': {
      marginBottom: '0px',
      marginTop: '0px',
      fontSize: '16px',
      marginRight: '15px'
    }
  },
  InputRow: {
    width: '475px',
    marginBottom: '15px',
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  InputCol: {
    width: '100%'
  },
  InputColHalf: {
    width: '45%'
  },
  formControl: {
    width: '150px',
    marginRight: '15px',
    marginTop: '20px',
    '@media (max-width:767px)': {
      width: '33%'
    },
    '&:lastChild': {
      marginRight: '0px'
    }
  },
  BottomTent: {
    width: '98%',
    marginTop: '60px',
    textAlign: 'right',
    '@media (max-width:767px)': {
      textAlign: 'center'
    },
    '& p': {
      color: '#5c637e'
    }
  },
  rediobtn: {
    marginRight: '20px'
  },
  TopCol: {
    display: 'flex',
    '& h3': {
      width: '60%',
      fontSize: '20px',
      fontWeight: '500'
    }
  },
  statecity: {
    paddingTop: '25px'
  },
  paymentText: {
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '0% 25%'
  },
  paymentProcessing: {
    textAlign: 'center',
    margin: '20% 25%'
  },
  errMsg: {
    color: 'red'
  },
  errMsg2: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingTop: '4px',
    fontWeight: 'bolder'
  },
  FollowupCards: {
    '& h5': {
      color: '#000000'
    }
  },
  UnsetLabel: {
    fontFamily: 'unset',
    fontSize: 'unset',
    fontWeight: 'unset',
    '& a': {
      color: 'blue',
      textDecoration: 'underline'
    }
  },
  disabledProceedBtn: {
    width: '150px',
    height: '40px',
    background: '#ADADAD',
    borderRadius: '40px',
    textAlign: 'center',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '40px',
    border: 'none',
    cursor: 'pointer'
  }
}))
const ClinicBookingPage = props => {
  const classes = useStyles()
  const [address, setaddress] = useState('')
  const [Country, setCountry] = useState({})
  const [State, setState] = useState({})
  const [city, setCity] = useState({})
  const [initial, setInitial] = useState(false)
  const [patientName, setPatName] = useState(null)
  const [peerName, setPeerName] = useState('')
  const [bookingSchedule, setBookingSchedule] = useState({})
  const [patientDetails, setPatientDetails] = useState(null)
  const [newFlag, setFlag] = useState(false)
  const [bookingType, setBookingType] = useState('')
  const [profileFetching, setprofileFetching] = useState(false)
  const [transactionInfo, setTransactionInfo] = useState(null)
  const [transactionPollingTimer, setTransactionPollingTimer] = useState(null)
  const [paymentProcessing, setPaymentProcessing] = useState(false)
  const [visitType, setVisitType] = useState(VISIT_TYPE.INITIAL.id)
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [errors, setErrors] = useState({})
  const [paymentDialog, setOpenPaymentDialog] = useState(false)
  const [closed, setClosed] = useState(false)

  const [acceptedSelected, setAcceptedSelected] = useState(false)

  let paymentInitiated = localStorage.getItem('payment_success') ? true : false
  const [paymentBookingDone, setPaymentBookingDone] = useState(paymentInitiated)

  let successPayment =
    localStorage.getItem('payment_success') === 'true' ? true : false
  const [paymentBookingSuccess, setPaymentBookingSuccess] =
    useState(successPayment)

  const { fetchCountryList, fetchStateList, fetchCityList } =
    ClinicStore.useStoreActions(actions => ({
      ...actions.profile
    }))

  const { clinicAppointmentBooking } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  const {
    successMessage,
    networkStatus,
    fetchingCountry,
    fetchingState,
    fetchingCity,
    countryList,
    stateList,
    cityList
  } = ClinicStore.useStoreState(state => ({
    ...state.profile
  }))

  const { getList } = ClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))

  const { appointments } = ClinicStore.useStoreState(actions => ({
    ...actions.doctorDetails
  }))

  const docDetails = JSON.parse(localStorage.getItem('selected_doctor'))

  useEffect(() => {
    if (localStorage.getItem('selectedSchedule')) {
      setBookingSchedule(JSON.parse(localStorage.getItem('selectedSchedule')))
      let data = localStorage.getItem('clinic_booking_details')
      setPatientDetails(data ? JSON.parse(data) : null)
      if (data) setFlag(true)
    }
  }, [])

  //setting the patient name from ls to patName state
  useEffect(() => {
    if (patientDetails) {
      setPatName(patientDetails?.first_name + ' ' + patientDetails?.last_name)
      formik.setFieldValue('firstname', patientDetails.first_name)
      formik.setFieldValue('lastname', patientDetails.last_name)
      let bookingBy =
        patientDetails.user_type === '1'
          ? 'self'
          : localStorage.getItem('new_patient') === 'true'
          ? 'self'
          : 'else'
      let initialPatient =
        localStorage.getItem('new_patient') === 'true' ? true : false
      setInitial(initialPatient)
      setBookingType(bookingBy)
      // setGender(patientDetails?.gender)
      // setPincode(patientDetails?.pincode)
      formik.setFieldValue('booking', bookingBy)
      formik.setFieldValue('gender', patientDetails?.gender)
      formik.setFieldValue('pincode', patientDetails?.pincode)
      formik.setFieldValue('address', patientDetails?.address)
      formik.setFieldValue('date', patientDetails?.dob)
      formik.setFieldValue('email', patientDetails?.email)
      setprofileFetching(true)
      fetchCountryList({})
      if (!initialPatient) {
        fetchStateList(patientDetails.country_id)
        fetchCityList(patientDetails.state_id)
        setaddress(patientDetails.address)
      }
    } else {
      fetchCountryList({})
    }
  }, [patientDetails])

  useEffect(() => {
    if (initial) {
      setprofileFetching(false)
    } else if (newFlag) {
      if (countryList?.length === 0) {
        // when country select event triggered means it will change state value to true then it will not affected anything,
        // IMP : without countrySelect(true) state value it will always go to whatever code written in else statement it will run,
        // so we can't see updated country there
      } else {
        let countryName =
          countryList &&
          countryList.filter(country => {
            return country.id === patientDetails?.country_id
          })
        setCountry(countryName[0])
        formik.setFieldValue('country_id', countryName[0]?.id)
      }
      if (stateList?.length === 0) {
      } else {
        let stateName =
          stateList &&
          stateList.filter(state => {
            return state.id === patientDetails?.state_id
          })
        setState(stateName[0])
        formik.setFieldValue('state_id', stateName[0]?.id)
      }
      if (cityList?.length === 0) {
      } else {
        let cityName =
          cityList &&
          cityList.filter(city => {
            return city.id === patientDetails?.city_id
          })
        setCity(cityName[0])
        formik.setFieldValue('city_id', cityName[0]?.id)
        setprofileFetching(false)
      }
    }
  }, [countryList, stateList, cityList])

  const countryChange = async (event, value) => {
    event.preventDefault()
    let country = await value
    setState('')
    setCity('')
    setCountry(country)
    setState('')
    setCity('')
    fetchStateList(country?.id)
    formik.setFieldValue('country_id', country?.id)
  }

  const stateChange = async (e, value) => {
    e.preventDefault()
    let state = await value
    setState(state)
    setCity('')
    fetchCityList(state?.id)
    formik.setFieldValue('state_id', state?.id)
  }

  const cityChange = async (e, value) => {
    e.preventDefault()
    let city = await value
    setCity(city)
    formik.setFieldValue('city_id', city?.id)
  }

  const submit = async values => {
    const accountData = localStorage.getItem('account-data')
      ? JSON.parse(localStorage.getItem('account-data'))
      : {}
    const data = { ...patientDetails, ...bookingSchedule, ...accountData }
    let patient = localStorage.getItem('clinic_booking_details')

    let appointment_data = {
      username: '',
      patient_id: patient
        ? JSON.parse(localStorage.getItem('clinic_booking_details'))?.id
        : '',
      doctor_id: localStorage.getItem('clinic_booking_doctor_id'),
      appointment_date: data.start_date,
      start_time: data.start_time,
      end_time: data.end_time,
      start_time_slot: data.appointment_start_time,
      end_time_slot: data.appointment_end_time,
      status: 1,
      calendar_schedule_id: data.calendar_schedule_id,
      is_profile_completed: data.is_profile_completed ? '0' : '1',
      visit_type: String(visitType),
      appointment_type: data.scheduleType,
      purpose: values?.purpose,
      description: values?.purpose,
      booked_by: values?.booking,
      booked_by_first_name: peerName,
      booked_by_last_name: '',
      first_name: values?.firstname,
      middle_name: '',
      last_name: values?.lastname,
      email: values?.email,
      mobile_no: data?.mobile_no,
      address: values?.address,
      country_id: values?.country_id,
      state_id: values?.state_id,
      city_id: values?.city_id,
      pincode: values?.pincode,
      dob: values?.date,
      payment_mode: values?.payment,
      gender: values?.gender,
      consultation_id: selectedAppointment?.id || null
    }
    clinicAppointmentBooking(appointment_data).then(transactionDetails => {
      if (transactionDetails.length !== 0) {
        if (
          transactionDetails.statusCode === 599 ||
          transactionDetails.statusCode === 597
        ) {
          bookedSuccessfull()
        } else if (transactionDetails.statusCode === 201) {
          setPaymentProcessing(true)
          setTransactionInfo(transactionDetails?.RequestData)
          if (closed == false) setOpenPaymentDialog(true)
        } else if (transactionDetails.statusCode === 400) {
          swal({
            title: 'Booking Failed',
            text: transactionDetails.message,
            type: 'Failure'
          }).then(confirm => {
            if (confirm) {
              window.location.href = '/clinic/payment'
            }
          })
        } else {
          swal({
            title: 'Booking Failed',
            text: transactionDetails.message,
            type: 'Failure'
          }).then(confirm => {
            if (confirm) {
              // window.location.href = '/patient/dashboard'
              // window.location.href = '/clinic/payment'
              window.location.href = '/clinic/booking'
              localStorage.removeItem('selectedSchedule')
              localStorage.removeItem('clinic_booking_details')
            }
          })
        }
      }
    })
  }

  const youRadioClicked = () => {
    // setYou(true);
  }

  const peerRadioClicked = () => {
    // setYou(false);
  }
  let doc_details = [JSON.parse(localStorage.getItem('schedule'))]

  const bookedSuccessfull = noredirect => {
    let redirection = noredirect ? false : true
    swal({
      title: 'Success',
      text: 'Appointment Booked Successfully',
      type: 'Success'
    }).then(confirm => {
      if (confirm && redirection) {
        window.location.href = '/clinic/booking'
        localStorage.removeItem('selectedSchedule')
        localStorage.removeItem('clinic_booking_details')
      }
    })
  }

  const dashboardClick = () => {
    window.location.href = '/clinic/booking'
    localStorage.removeItem('selectedSchedule')
    localStorage.removeItem('clinic_booking_details')
  }

  const checkout = async () => {
    setClosed(true)
    setOpenPaymentDialog(false)
    let transactionDetails = transactionInfo
    let razorpayID = RAZOR_KEY
    let options = {
      key: razorpayID, //,
      amount: Number(transactionDetails.amount) * 100, // 2000 paise = INR 20, amount in paisa
      name: 'CareNowDoc',
      description:
        doc_details[0].scheduleType === '1'
          ? 'In-Person Consultation'
          : 'Tele Consultation',
      image: process.env.PUBLIC_URL + '/logo-icon.png',
      order_id: transactionDetails.order_id,
      handler: function (response) {
        localStorage.setItem('paymentID', response.razorpay_payment_id)
        localStorage.setItem('orderID', response.razorpay_order_id)
        localStorage.setItem('signature', response.razorpay_signature)
        localStorage.setItem('txnId', transactionDetails.txnid)
        if (response.razorpay_payment_id && response.razorpay_order_id) {
          let gaLabel =
            response.razorpay_payment_id +
            ': ' +
            localStorage.getItem('patient_user_id')
          ReactGA.event({
            category: 'razorpay',
            action: response.razorpay_order_id,
            label: gaLabel
          })
          setTransactionPollingTimer(
            setInterval(() => {
              confirmPayment()
            }, 5000)
          )
        } else {
          let gaLabel =
            transactionDetails.txnid +
            ': ' +
            localStorage.getItem('patient_user_id')
          ReactGA.event({
            category: 'razorpay',
            action: 'failed',
            label: gaLabel
          })
        }
      },
      modal: {
        ondismiss: function () {
          closedPaymentModal()
        }
      },
      prefill: {
        name: transactionDetails.name,
        contact: transactionDetails.phone,
        email: transactionDetails.email
      },
      theme: {
        color: '#17BAE0'
      }
    }
    let rzp = new window.Razorpay(options)
    rzp.open()

    const modalDocument = document.querySelector('#modal.mchild')

    //console.log(modalDocument);
    if (modalDocument) {
      modalDocument.style.top = '60px'
    }
  }

  const onvisitTypeChange = e => {
    setVisitType(Number(e.target.value))
    formik.setFieldValue('visit', e.target.value)
  }

  const closedPaymentModal = () => {
    swal({
      title: 'Payment Error',
      text: 'Since you closed the payment modal, you are redirected to the dashboard',
      type: 'Success',
      closeOnClickOutside: false
    }).then(confirm => {
      window.location.href = '/clinic/booking'
      localStorage.removeItem('selectedSchedule')
      localStorage.removeItem('clinic_booking_details')
    })
  }
  const confirmPayment = () => {
    // verifyPayment({
    //   transaction_id: localStorage.getItem('txnId')
    // })
  }
  let paymentImg = paymentBookingSuccess ? PaymentSuccesImg : PaymentFailureImg

  useEffect(() => {
    if (visitType === VISIT_TYPE.FOLLOW_UP.id) {
      getList({
        list_type: LIST_TYPE.PAST.label,
        status: String(BOOKING_STATUS.COMPLETED.value),
        visit_type: VISIT_TYPE.ALL.id,
        appointment_type: 1,
        sorting: {
          appointment_date: 'DESC',
          start_time: 'DESC'
        },
        doctor_id: localStorage.getItem('clinic_booking_doctor_id'),
        isForFollowup: true
      })
    }
  }, [visitType])

  var formik = useFormik({
    initialValues: {
      visit: '1',
      payment: '2',
      booking: 'self',
      patname: '',
      gender: 'male',
      date: new Date(),
      pincode: '',
      purpose: '',
      address: '',
      country_id: '',
      firstname: '',
      lastname: '',
      email: '',
      city_id: '',
      state_id: '',
      accepted: true
    },
    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: false,
    validationSchema,
    onSubmit: submit
  })

  return (
    <>
      <div className={classes.root}>
        {networkStatus === 'FETCHING' ? (
          <div>
            <Loader open={true} />
          </div>
        ) : (
          <div>
            <Loader open={false} />
          </div>
        )}
        <div className={classes.DoctorSearch}>
          <Dialog
            disableBackdropClick={true}
            open={paymentDialog}
            onClose={() => {
              setClosed(true)
              setOpenPaymentDialog(false)
            }}
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">Payment Info</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <span>Doctor Fees: </span>
                  <span>₹ {transactionInfo?.doctor_fee}</span>
                </div>
                <div>
                  <span>Convenience Fee: </span>
                  <span>₹ {transactionInfo?.convenience_fee}</span>
                </div>
                <div style={{ fontWeight: 'bold' }}>
                  <span>Total Amount: </span>
                  <span>₹ {transactionInfo?.amount}</span>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  closedPaymentModal()
                  setOpenPaymentDialog(false)
                }}
                color="primary">
                Cancel
              </Button>
              <Button onClick={checkout} color="primary" autoFocus>
                Proceed to Payment
              </Button>
            </DialogActions>
          </Dialog>
          {paymentBookingDone ? (
            <Box className={classes.ContentPic}>
              <img
                className={classes.paymentImg}
                src={paymentImg}
                alt="Payment"
              />
              <p className={classes.paymentText}>
                {paymentBookingSuccess
                  ? `Thank you for booking an appointment with ${
                      docDetails.prefix + ' ' + docDetails.name
                    } For any doubt get in touch with our customer happiness team at 033-418-16533`
                  : 'Transaction Failed. Please try again. If amount got deducted please get in touch with our customer happiness team at 033-418-16533'}
              </p>
              <Box className={classes.BtnCol}>
                <button
                  className={classes.dashboardBtn}
                  onClick={dashboardClick}>
                  Dashboard
                </button>
              </Box>
            </Box>
          ) : paymentProcessing && !paymentBookingDone ? (
            <Box className={classes.ContentPic}>
              <p className={classes.paymentProcessing}>
                Please wait...we are processing your payment
              </p>
            </Box>
          ) : (
            <div className="d-flex">
              <Box className={classes.ContentCol}>
                <Box className={classes.InfoText}>
                  <h2>Patient Details</h2>
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl
                      component="fieldset"
                      className={classes.FormElement}>
                      <p>Visit Type</p>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="visit"
                        value={formik?.values?.visit}
                        onChange={onvisitTypeChange}
                        onBlur={formik.handleBlur}
                        // ref={register({ required: true })}
                        // onChange={e => setVisitType(Number(e.target.value))}
                      >
                        <FormControlLabel
                          className={classes.rediobtn}
                          value={'1'}
                          control={<Radio color="primary" />}
                          label="Initial Consult"
                        />
                        {/* {newFlag && (
                          <FormControlLabel
                            value={'2'}
                            control={<Radio color="primary" />}
                            label="Follow up Consult"
                          />
                        )} */}
                      </RadioGroup>
                    </FormControl>
                    {/* <FormControl
                      component="fieldset"
                      className={classes.FormElement}
                      defa>
                      <p>Payment Mode</p>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="payment"
                        value={formik?.values?.payment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // onChange={e => setPaymentMode(e.target.value)}
                      >
                        <FormControlLabel
                          className={classes.rediobtn}
                          value={'1'}
                          control={<Radio color="primary" />}
                          label="Online"
                        />
                        {doc_details[0].scheduleType === '1' && (
                          <FormControlLabel
                            value={'2'}
                            control={<Radio color="primary" />}
                            label="Cash"
                          />
                        )}
                      </RadioGroup>
                    </FormControl> */}
                    <FormControl
                      component="fieldset"
                      className={classes.FormElement}>
                      <p>Booking for</p>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="booking"
                        disabled={newFlag && true}
                        value={formik?.values?.booking}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // onChange={e => setBookedBy(e.target.value)}
                      >
                        {bookingType === 'self' || bookingType == '' ? (
                          <FormControlLabel
                            className={classes.rediobtn}
                            value="self"
                            control={<Radio color="primary" />}
                            label="Self"
                          />
                        ) : (
                          <FormControlLabel
                            value="else"
                            control={<Radio color="primary" />}
                            label="Someone else"
                          />
                        )}
                      </RadioGroup>
                    </FormControl>

                    <Box className={classes.InputRow}>
                      {/* {you ? (
                    
                  ) : (
                    <TextField
                      id="someone"
                      value={caregiverName}
                      className={classes.InputCol}
                      onChange={(e) => setCaregiverName(e.target.value)}
                    />
                  )} */}
                      <TextField
                        id="firstname"
                        name="firstname"
                        placeholder="First Name*"
                        value={formik?.values?.firstname}
                        className={classes.InputColHalf}
                        disabled={newFlag && true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.firstname && formik.errors.firstname
                              ? formik.errors.firstname
                              : null}
                          </div>
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      &nbsp;
                      <TextField
                        id="lastname"
                        name="lastname"
                        placeholder="Last Name*"
                        value={formik?.values?.lastname}
                        className={classes.InputColHalf}
                        disabled={newFlag && true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.lastname && formik.errors.lastname
                              ? formik.errors.lastname
                              : null}
                          </div>
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Box>
                    <Box className={classes.InputRow}>
                      <TextField
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={formik?.values?.email}
                        className={classes.InputCol}
                        disabled={newFlag && true}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : null}
                          </div>
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Box>
                    <FormControl
                      component="fieldset"
                      className={classes.FormElement}
                      disabled={newFlag && !initial}>
                      <p>Gender</p>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="gender"
                        value={formik?.values?.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        <FormControlLabel
                          className={classes.rediobtn}
                          value="male"
                          control={<Radio color="primary" />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio color="primary" />}
                          label="Female"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box className={classes.FormElement}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="DD-MM-YYYY"
                        margin="normal"
                        name="date"
                        label="Date of birth"
                        disabled={newFlag && !initial}
                        maxDate={new Date()}
                        value={formik.values.date}
                        onChange={value => formik.setFieldValue('date', value)}
                        onBlur={formik.handleBlur}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.date && formik.errors.date
                              ? formik.errors.date
                              : null}
                          </div>
                        }
                        autoOk={true}
                      />
                    </Box>
                    <Box className={classes.FormElement}>
                      <p>Pincode*</p>
                      <TextField
                        id="pincode"
                        type="pincode"
                        name="pincode"
                        disabled={newFlag && !initial}
                        value={formik?.values?.pincode}
                        className={classes.textField}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.pincode && formik.errors.pincode
                              ? formik.errors.pincode
                              : null}
                          </div>
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                        // onChange={e => setPincode(e.target.value)}
                      />
                    </Box>
                    <Box className={classes.InputRow}>
                      <TextField
                        style={{ color: '#000', fontSize: '16px' }}
                        id="purpose"
                        name="purpose"
                        label="Purpose of consultation*"
                        placeholder="Purpose of consultation*"
                        value={formik?.values?.purpose}
                        className={classes.InputCol}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.purpose && formik.errors.purpose
                              ? formik.errors.purpose
                              : null}
                          </div>
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                        // onChange={e => setPurpose(e.target.value)}
                      />
                      <div className="input-error-message">
                        {errors?.purpose}
                      </div>
                    </Box>
                    <Box className={classes.InputRow}>
                      <TextField
                        id="address"
                        label="Address*"
                        name="address"
                        placeholder="Address*"
                        value={formik?.values?.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{
                          shrink: true
                        }}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.address && formik.errors.address
                              ? formik.errors.address
                              : null}
                          </div>
                        }
                        className={classes.InputCol}
                        disabled={newFlag && !initial}
                      />
                    </Box>
                    <Box className={classes.FormElement}>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id="size-small-outlined"
                          name="country_id"
                          size="small"
                          style={{ width: '100%' }}
                          options={countryList}
                          disabled={newFlag && !initial}
                          getOptionLabel={option => option.name}
                          onChange={countryChange}
                          value={Country}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Country*"
                              loading={fetchingCountry}
                              style={{ width: '100%' }}
                              // error={
                              //   (formik.errors.country_id, formik.touched.country_id)
                              // }
                              // helperText={
                              //   (formik.errors.country_id,
                              //   formik.touched.country_id
                              //     ? formik.errors.country_id
                              //     : null)
                              // }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {fetchingCountry ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                        <div className={classes.errMsg2}>
                          {formik.touched.country_id && formik.errors.country_id
                            ? formik.errors.country_id
                            : null}
                        </div>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id="size-small-outlined"
                          size="small"
                          name="state_id"
                          style={{ width: '100%' }}
                          options={Country ? stateList : []}
                          disabled={newFlag && !initial}
                          getOptionLabel={option => option.name}
                          onChange={stateChange}
                          value={State}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="State*"
                              loading={fetchingState}
                              style={{ width: '100%' }}
                              // error={
                              //   (formik.errors.state_id, formik.touched.state_id)
                              // }
                              // helperText={
                              //   (formik.errors.state_id,
                              //   formik.touched.state_id
                              //     ? formik.errors.state_id
                              //     : null)
                              // }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {fetchingState ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                        <div className={classes.errMsg2}>
                          {formik.touched.state_id && formik.errors.state_id
                            ? formik.errors.state_id
                            : null}
                        </div>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <Autocomplete
                          id="size-small-outlined"
                          size="small"
                          name="city_id"
                          style={{ width: '100%' }}
                          options={Country && State ? cityList : []}
                          disabled={newFlag && !initial}
                          getOptionLabel={option => option.name}
                          onChange={cityChange}
                          value={city}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="City*"
                              loading={fetchingCity}
                              style={{ width: '100%' }}
                              // error={(formik.errors.city_id, formik.touched.city_id)}
                              // helperText={
                              //   (formik.errors.city_id,
                              //   formik.touched.city_id ? formik.errors.city_id : null)
                              // }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {fetchingCity ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                        <div className={classes.errMsg2}>
                          {formik.touched.city_id && formik.errors.city_id
                            ? formik.errors.city_id
                            : null}
                        </div>
                      </FormControl>
                    </Box>
                    <Box className={classes.InputRow}>
                      <FormControlLabel
                        className={classes.formcol}
                        style={{ marginRight: '0px' }}
                        value="accepted"
                        control={
                          <Checkbox
                            color="primary"
                            onChange={formik.handleChange}
                            name="accepted"
                          />
                        }
                        // label={`I accept the <a href="/termsofuse" target="_blank">terms and conditions"</a`}
                        // labelPlacement="end"
                        checked={formik.values.accepted}
                      />
                      <label className={classes.UnsetLabel}>
                        I accept{' '}
                        <a href="/termsofuse" target="_blank">
                          terms and conditions
                        </a>{' '}
                        and{' '}
                        <a href="/privacy" target="_blank">
                          privacy policy{' '}
                        </a>
                      </label>
                      {/* &nbsp;
                      <div className={classes.errMsg2}>
                        {formik.touched.accepted && formik.errors.accepted
                          ? formik.errors.accepted
                          : null}
                      </div> */}
                    </Box>
                    <Box className={classes.BtnCol}>
                      <button
                        className={
                          formik.values.accepted === false
                            ? classes.disabledProceedBtn
                            : classes.ProceedBtn
                        }
                        // onClick={confirmHandler}
                        type={'submit'}
                        disabled={formik.values.accepted === false}>
                        Confirm
                      </button>
                    </Box>
                  </form>
                </Box>
              </Box>
              {appointments.length !== 0 &&
                visitType === VISIT_TYPE.FOLLOW_UP.id && (
                  <Box className={classes.ContentColFollowup}>
                    <h3>
                      Please select the appointment you'd like a follow up to
                    </h3>
                    <Box className={classes.InfoText}>
                      {appointments.map(item => (
                        <div
                          className={classes.FollowupCards}
                          style={{ width: '50%' }}
                          onClick={() => setSelectedAppointment(item)}>
                          <AppointmentListItem
                            appointment={item}
                            selected={item.id === selectedAppointment?.id}
                            isForFollowupBooking={true}
                          />
                        </div>
                      ))}
                    </Box>
                  </Box>
                )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const validationSchema = yup.object().shape({
  firstname: yup
    .string()
    .required('First Name is mandatory')
    .max(105, 'You may enter up to 105 characters'),

  lastname: yup
    .string()
    .required('Last Name is mandatory')
    .max(105, 'you may enter up to 105 characters'),
  date: yup
    .date()
    .max(
      new Date(new Date().setDate(new Date().getDate() + 1)),
      `Date should be earlier than today's date`
    )
    .required('Dob is required')
    .typeError('Please enter your date in the correct format'),
  pincode: yup
    .string()
    .trim()
    .required('Pincode is required')
    .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Incorrect pincode format'),

  purpose: yup
    .string()
    .required('Purpose of consultation is mandatory')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),
  address: yup
    .string()
    .required('Address is mandatory')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),
  email: yup
    .string()
    .email('Invalid email')
    // .required('Email is required')
    .max(50, 'You have exceeded the character limit'),
  country_id: yup.string().required('Country is required'),
  state_id: yup.string().required('State is required'),
  city_id: yup.string().required('City is required')
})

export default ClinicBookingPage
