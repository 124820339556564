import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
  Box
} from '@material-ui/core'

import Button from '../../components/Button'
import {SuperClinicStore} from "../../../stores"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DeleteDialog = ({ openDialog, handleDialogClose, clinicListID }) => {

  const { delClinic } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.clinicListDetails
  }))

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent className="delete-modal">
        <DialogContentText id="alert-dialog-slide-description">
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '30px'
            }}>
            <i class="fa fa-exclamation-triangle"></i> <h3>Are you sure?</h3>
          </Box>
        </DialogContentText>
        <p>
          Do you really want to delete these clinic? This process cannot be
          undone.
        </p>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={()=>{
              delClinic(clinicListID)
              handleDialogClose()
            }}
            style={{
              background: '#ff5252',
              marginRight: '15px',
              height: '40px',
              width: '100px',
              boxShadow: 'none'
            }}>
            Yes
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            style={{
              background: '#cccdd0',
              color: '#727478',
              height: '40px',
              width: '100px',
              boxShadow: 'none'
            }}>
            No
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteDialog
