import React from 'react'
import { Avatar, IconButton, makeStyles, colors } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import fileDownload from 'js-file-download'
import axios from 'axios'

import { NETWORK_STATUS } from '../../../../../Constants'

const PrintPrescriptionItem = ({
  fileName = null,
  imageSrc = null,
  fileExtention = null,
  updateStatus,
  pdfBlob = null,
  appointmentDate = null
}) => {
  const classes = useStyles()

  const onDownloadClick = async () => {
    if (pdfBlob) {
      imageSrc = `data:application/pdf;base64,${pdfBlob}`
    }

    try {
      const { data } = await axios.get(imageSrc, { responseType: 'blob' })
      if (!data) {
        updateStatus(NETWORK_STATUS.FAILED)
      }
      fileDownload(data, `${fileName}.${fileExtention}`)
    } catch (error) {
      updateStatus(NETWORK_STATUS.FAILED)
    }
  }

  if (!fileExtention) {
    fileExtention = imageSrc.split('.').pop()
  }
  if (pdfBlob) {
    fileExtention = 'pdf'
    fileName = `Prescription_${appointmentDate}`
  }

  return (
    <li className={classes.listItem}>
      <div>
        {fileExtention === 'pdf' ? (
          <div
            className={[classes.red, classes.avatar].join(
              ' '
            )}>{`.${fileExtention}`}</div>
        ) : fileExtention === 'jpeg' || fileExtention === 'jpg' ? (
          <div
            className={[classes.green, classes.avatar].join(
              ' '
            )}>{`.${fileExtention}`}</div>
        ) : fileExtention === 'png' ? (
          <div
            className={[classes.orange, classes.avatar].join(
              ' '
            )}>{`.${fileExtention}`}</div>
        ) : (
          <div className={[classes.purple, classes.avatar].join(' ')}>
            {fileName.slice(0, 1)}
          </div>
        )}
      </div>
      <p className={classes.listText}>{fileName}</p>
      <div className={classes.listTrailingActions}>
        {/* <IconButton edge="end" aria-label="delete">
          <Delete color="error" />
        </IconButton> */}
        <IconButton edge="end" aria-label="download" onClick={onDownloadClick}>
          <CloudDownload color="primary" />
        </IconButton>
      </div>
    </li>
  )
}

export default PrintPrescriptionItem

const useStyles = makeStyles(theme => ({
  avatar: {
    width: '2.5rem',
    height: '2.5rem',
    padding: '0.3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    borderRadius: '50%'
  },
  red: {
    color: theme.palette.getContrastText(colors.red[900]),
    backgroundColor: colors.red[900]
  },
  green: {
    color: theme.palette.getContrastText(colors.green[900]),
    backgroundColor: colors.green[900]
  },
  orange: {
    color: theme.palette.getContrastText(colors.deepOrange[600]),
    backgroundColor: colors.deepOrange[600]
  },
  purple: {
    color: theme.palette.getContrastText(colors.deepPurple[500]),
    backgroundColor: colors.deepPurple[500]
  },
  listItem: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr max-content',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}px`,
    gridColumnGap: theme.spacing(1),
    columnGap: theme.spacing(1)
  },
  listText: {
    fontSize: '90%',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  listTrailingActions: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: `0.5rem`,
    columnGap: `0.5rem`
  }
}))
