import React from 'react'
import { makeStyles, Box, Paper } from '@material-ui/core'
import { DashboardLayout } from '../../doctorLayouts'
import Appointment from '../../components/Appointments/Appointment'

export default function Appointments() {
  const classes = useStyles()
  return (
    <DashboardLayout>
      <Box className={classes.aside}>
        <Paper elevation={3} className={classes.paper}>
          <Appointment />
        </Paper>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles(() => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  aside: {
    // padding: '25px 0 !important'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  paper: {
    padding: '20px 30px',
    marginTop: '20px',
    borderRadius: '10px',
    background: 'none !important',
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.1)',
    '@media (max-width:767px)': {
      padding: '20px'
    }
  },
  AppBar: {
    '& .MuiBox-root': {
      padding: '0'
    },
    checkboxes: {
      marginTop: '15px'
    }
  }
}))
