import { action, thunk } from 'easy-peasy'
import localforage from 'localforage'
import BaseModel from '../Base'
import {
  NETWORK_STATUS,
  LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME,
  DOCTOR_USER_ID,
  USER_ROLE
} from '../../Constants'
import api from '../../api'

const updateLocalStorage = async data => {
  const { hospital_details, user, access_specificatin } = data

  const authn = {
    user,
    hospital_details
  }

  return Promise.all([
    localforage.setItem(LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME, authn),
    localStorage.setItem(DOCTOR_USER_ID, user && user.user_id),
    localforage.setItem(DOCTOR_USER_ID, user && user.user_id),
    localforage.setItem('doctor_subscription', user)
  ])
}

export default {
  ...BaseModel(),
  resendOtp: false,
  phoneNo: null,

  login: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { email, password } = payload
    // const response = await api.getUserRole({
    //   user_type: '2',
    //   url_code: 'craftveda-demo-hospital'
    // })

    // if (response.ok) {
    //   let userRole = response.data.roles.filter(
    //     item => item.display_name === USER_ROLE[2]
    //   )
    const responseAuth = await api.authenticateUser({
      user_name: email,
      password,
      type: 'password',
      user_type: '2'
      // url_code: 'craftveda-demo-hospital',
      // user_role: userRole.length > 0 ? userRole[0].id : '0'
    })
    if (responseAuth.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      localStorage.setItem(
        'doctor_auth_key',
        responseAuth?.data?.user?.id_token
      )
      localStorage.setItem(
        'user_short_code',
        responseAuth?.data?.user?.user_short_code
      )
      updateLocalStorage(responseAuth.data)
      return responseAuth.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
    // } else {
    //   actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    // }
  }),

  sendOtp: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { mobile_no } = payload

    const responseAuth = await api.sendOtp({
      mobile_no: `+91${mobile_no}`,
      channel_id: 'doctor_login'
    })

    if (responseAuth.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setResendOTP(false)
      actions.setPhoneNo(`+91${mobile_no}`)
      actions.otpSent(responseAuth?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  postResendOtp: thunk(async (actions, payload, { getState }) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { retry_type = 'text' } = payload

    const { phoneNo } = getState()

    try {
      const { data = {} } = await api.resendOtp({
        mobile_no: phoneNo,
        retry_type
      })

      if (!!data && (data.statusCode === 200 || data.statusCode === 201)) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setResendOTP(true)
        actions.otpSent(data)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        actions.otpSent(
          !data.message
            ? {
                message: 'Oops! Something went wrong. Please try again later...'
              }
            : data.message === 'OTP retry count maxed out'
            ? {
                ...data,
                message: 'Maximum limit to RESEND OTP exceeded'
              }
            : data
        )
      }
    } catch (err) {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      actions.otpSent({
        message: 'Oops! Something went wrong. Please try again later...'
      })
    }
  }),

  setResendOTP: action((state, payload) => {
    state.resendOtp = payload
  }),

  setPhoneNo: action((state, payload) => {
    state.phoneNo = payload
  }),

  otpSent: action((state, payload) => {
    state.otpResponse = payload
  }),

  otpLogin: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { mobile_no, otp } = payload
    let phone_no = mobile_no
    // const response = await api.getUserRole({
    //   user_type: '2',
    //   url_code: 'craftveda-demo-hospital'
    // })

    // if (response.ok) {
    //   let userRole = response.data.roles.filter(
    //     item => item.display_name === USER_ROLE[2]
    //   )
    const responseAuth = await api.authenticateUser({
      mobile_no: `+91${phone_no}`,
      otp: otp,
      // url_code: 'craftveda-demo-hospital',
      type: 'otp',
      user_type: '2'
      // user_role: userRole.length > 0 ? userRole[0].id : '0'
    })
    if (responseAuth.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      localStorage.setItem(
        'doctor_auth_key',
        responseAuth?.data?.user?.id_token
      )
      localStorage.setItem(
        'user_short_code',
        responseAuth?.data?.user?.user_short_code
      )
      updateLocalStorage(responseAuth.data)
      return responseAuth.data
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  })
}
