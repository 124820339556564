import { create } from 'apisauce'

import {
  LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME,
  LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME,
  LOCAL_STORAGE_CLINIC_AUTH_KEY_NAME,
  LOCAL_STORAGE_SUPER_CLINIC_AUTH_KEY_NAME
} from '../Constants'
import { BASE_URL } from '../Environment'
import localforage from 'localforage'
import ReactGA from 'react-ga'
export const URIS = {
  AUTHENTICATION: 'auth/user-login',
  SEND_OTP: '/public/send-otp',
  RESEND_OTP: '/public/resend-otp',
  PUBLIC_GET_USER_ROLE: '/public/get-user-roles',
  PUBLIC_CREATE_PATIENT: '/public/add-patient',
  PUBLIC_CREATE_APPOINTMENT: '/patient/add-appointment',
  DOCTOR_GET_APPOINTMENTS_BY_DATE: '/user/appointments/list',
  GET_PATIENTS_LIST: '/doctor/patients/list',

  // Lab data
  DELETE_LAB: '/user/lab/delete',
  EDIT_LAB: '/user/lab/edit',

  GENERATE_PRESCRIPTION: '/patient/prescription/generate-pdf',
  GET_DOCTOR_BANK_DETAILS: '/doctor/bankdetails/view',
  ADD_DOCTOR_BANK_DETAILS: '/doctor/bankdetails/add-edit',
  LAB_RECORDS_TYPES: '/user/lab-test-master/list',

  REGISTER_UPDATE_PATIENT_PROFILE: '/public/edit-patient',
  UPDATE_PATIENT_PROFILE: '/patient/profile/update',
  GET_PATIENT_PROFILE: '/patient/profile/get',

  // Doctor profile udpate
  UPDATE_DOCTOR_PROFILE: '/doctor/profile/update',
  DOCTOR_UPDATE_FEES: '/doctor/fees/update',

  //Doctor Scheduler
  DOCTOR_CREATE_SCHEDULE: '/doctor/schedules/add',
  DOCTOR_GET_SCHEDULES: '/doctor/schedules/list',
  DOCTOR_DELETE_SCHEDULES: '/doctor/schedules/delete',
  DOCTOR_UPDATE_CALENDER_SCHEDULES: '/doctor/schedules/update',
  DOCTOR_SCHEDULE_STATIC: '/doctor/schedules/static',
  PUBLIC_DOCTOR_GET_SCHEDULES_BY_DATE: '/public/get-doctor-schedules',

  //Doctor Consulation Setting
  GET_CONSULT_SETTINGS: '/doctor/settings/get',
  UPDATE_CONSULT_SETTINGS: '/doctor/settings/update',

  //Doctor add lab records
  DOCTOR_LAB_RECORDS_TYPES: '/user/lab-test-master/list',

  //doctor accounts
  DOCTOR_ACCOUNTS_EDIT: '/doctor/bankdetails/add-edit',
  DOCTOR_ACCOUNTS_VIEW: '/doctor/bankdetails/view',

  //doctor wallet api
  DOCTOR_WALLET_VIEW: '/doctor/wallet/view',
  CLINIC_WALLET_REQUEST_LIST: '/clinic/payout-request/list',
  CLINIC_WALLET_REQUEST_ADD: '/clinic/payout-request/add',
  CLINIC_WALLET_REQUEST_UPDATE: '/clinic/payout-request/edit',
  CLINIC_WALLET_REQUEST_CANCEL: '/clinic/payout-request/cancel',
  DOCTOR_APPOINTMENT_TRANSACTIONS: '/doctor/appointments/payment-transactions',

  //scheduler api
  SCHEDULES_FETCH: '/doctor/calendar/fetch',
  SCHEDULES_CREATE: '/doctor/calendar/add',
  SCHEDULES_UPDATE: '/doctor/calendar/edit',
  SCHEDULES_DELETE: '/doctor/calendar/delete',
  RESCHEDULE_APPOINTMENT: '/user/appointments/reschedule',

  //doctor communication center api
  DOCTOR_MSG_BALANCE: '/doctor/communication-sms/check-balance',
  DOCTOR_MSG_TEMPLATES: '/doctor/communication-sms/list',
  DOCTOR_SEND_MSG: '/doctor/communication-sms/send',

  //Subscriptionplan apis
  SUBSCRIPTION_LIST: '/public/subscription/plan-list',
  DOCTOR_SUBSCRIPTION_HISTORY: '/doctor/subscription/history',
  DOCTOR_SUBSCRIPTION_ACTIVATE: '/doctor/subscription/activate',
  DOCTOR_SUBSCRIPTION_CHOICE: '/doctor/subscription/choice',

  //Doctor Staff list Create & Update
  MED_STAFF_CREATE: '/admin/medical-assistant/add',
  MED_STAFF_UPDATE: '/admin/medical-assistant/update',

  //Patient-Registration
  PATIENT_REGISTRATION: '/public/patient-registration',

  //Document data
  ADD_DOCUMENT: '/user/documents/add',
  EDIT_DOCUMENT: '/user/documents/edit',
  DELETE_DOCUMENT: '/user/documents/delete',

  //Appointment Booking
  CREATE_APPOINTMENT: '/patient/add-appointment',
  DOCTOR_SCHEDULE_SLOTS: '/public/calendar-slots',
  PUBLIC_USER_DETAILS: '/public/get-user',
  GET_PATIENTS_ACCOUNTS_LIST: '/patient/patient-account-list',
  PUBLIC_COUNTRY_STATE_CITY: 'public/get-country-state-city',

  //Payment api
  INITIALIZE_PAYMENT: '/patient/payment/appointment/initialize',
  VERIFY_PAYMENT: '/patient/payment/appointment/check',

  //Appointment Process and patient charting
  GET_APPOINTMENTS_LIST: '/user/appointments/list',
  GET_VISTIT_SUMMARIES: '/user/visit-summary/view',
  UPDATE_APPOINTMENT_STATUS: '/doctor/appointments/edit',
  DOCTOR_PATIENT_VIEW: '/doctor/patients/view',
  START_APPOINTMENT: '/user/appointments/start',
  GET_DIAGNOSIS_SEARCH: '/user/conditions/search',

  // Vitals data
  ADD_PATIENT_VITALS: '/user/vitals/add',
  PATIENT_VITALS_LIST: '/user/vitals/list',
  EDIT_PATIENT_VITALS: '/user/vitals/edit',
  FETCH_GRAPH_DATA: '/user/vitals/graph',

  // Demographic data
  ADD_DEMOGRAPHIC_DATA: '/user/demographic/add',
  GET_DEMOGRAPHIC_DATA: '/user/demographic/list',
  EDIT_DEMOGRAPHIC_DATA: '/user/demographic/edit',
  DELETE_DEMOGRAPHIC_DATA: '/user/demographic/delete',

  // Medication data
  ADD_MEDICATION: '/user/medications/add',
  GET_PATIENT_MEDICATION: '/user/medications/list',
  GET_MEDICAL_LIST: '/user/medications/search',
  UPDATE_MEDICATION: '/user/medications/edit',
  DELETE_MEDICATION: '/user/medications/delete',

  // Labs data
  GET_LAB: '/user/documents/list',
  ADD_LAB: '/user/lab/add',

  // Diagnosis data
  GET_DIAGNOSIS_DATA: '/user/diagnosis/get',
  EDIT_DIAGNOSIS_DATA: '/user/diagnosis/add-edit',

  //Clinic api
  CLINIC_DASHBOARD_VIEW: '/clinic/dashboard/stats',
  CLINIC_CHANGE_PASSWORD: '/clinic/profile/update-password',
  CLINIC_PROFILE_VIEW: '/clinic/profile/view',
  CLINIC_PROFILE_UPDATE: '/clinic/profile/update',
  CLINIC_DOCTORS_LIST: '/clinic/doctors',
  CLINIC_DOCTOR_STATUS_UPDATE: '/clinic/doctors/update-status',
  CLINIC_SERVICES_LIST: '/clinic/services',
  CLINIC_SERVICES_ADD: '/clinic/services/add',
  CLINIC_SERVICES_EDIT: '/clinic/services/edit',
  CLINIC_SERVICES_DELETE: '/clinic/services/delete',
  CLINIC_SERVICES_STATUS_UPDATE: '/clinic/services/update-status',
  CANCEL_APPOINTMENT: '/user/appointments/cancel',
  CLINIC_PATIENT_SEARCH: '/clinic/patient-search',
  CLINIC_BOOK_APPOINTMENT: '/clinic/book-appointment',
  CLINIC_PAYOUT_LIST: '/clinic/payout-history/list',
  CLINIC_DOCTOR_REVENUE: '/clinic/revenue/doctor_list',
  CLINIC_GENERATE_INVOICE: '/clinic/genrate-invoice',
  CLINIC_PATIENT_SERVICE_MOVE: '/clinic/patient-service/move',
  CLINIC_PATIENT_SERVICE_PAYMENT: '/clinic/patient-service/payment',
  CLINIC_PATIENT_SERVICE_DELETE: '/clinic/patient-service/delete',
  CLINIC_PATIENT_SERVICE_LIST: '/patient/services/list',
  CLINIC_APPOINTMENT_PAYMENT_CONFIRM: '/clinic/confirm-payment',
  PATIENT_SERVICE_ADD: '/patient/services/add',
  PATIENT_SERVICE_LIST: '/patient/services/list',
  PATIENT_SERVICE_EDIT: '/patient/services/edit',
  PATIENT_SERVICE_DELETE: '/patient/services/delete',
  CLINIC_WALLET_VIEW: '/clinic/wallet/view',
  CLINIC_SUBSCRIPTION_VIEW: '/clinic/subscrption-plan/view',
  INITIALIZE_CLINIC_PAYMENT: '/clinic/subscription/initialize-payment',
  GET_CLINIC_BILL: '/clinic/subscription/calculate',
  VERIFY_CLINIC_PAYMENT: '/clinic/subscription/check',
  SUBSCRIPTION_HISTORY_LIST: '/hospital-admin/clinic/subscription-history/list',
  ACTIVATE_BILLING: '/clinic/doctors/update-billingpermission',
  PAYMENT_INITIALIZE: '/clinic/subscription-plan/activate',
  CLINIC_UPLOAD_PRESCRIPTION: '/clinic/manual_prescription/add',
  CLINIC_LIST_MANUAL_PRESCRIPTION: '/clinic/manual_prescription/list',
  CLINIC_DOWNLOAD_REPORT: '/clinic/service_report/download',
  CLINIC_REMOVE_RECOMMENDED_SERVICE: '/clinic/recommend_service/remove',
  CLINIC_EDIT_APPOINTMENT_STATUS: '/clinic/appointment/edit',

  // Super Clinic
  GET_SUP_CLINIC_DOCTOR_LIST: '/superclinic/doctor-list',
  CLINIC_LIST_IN_SUPERCLINIC: '/user/clinic-list',
  CLINIC_PROFILE_ADD_SUPERCLINIC: '/clinic/profile/add',
  CLINIC_EDIT_SUPERCLINIC: '/superclinic/edit',
  CLINIC_LIST_REMOVAL: '/superclinic/clinic/remove',
  CLINIC_REVENUE_SUPERCLINIC: '/superclinic/dashboard_revenue/main_dashboard',
  CLINIC_REVENUE_DOCTOR_EARNINGS_SUPERCLINIC:
    '/superclinic/dashboard_revenue/doctors_earnings',
  CLINIC_LAST_THREE_MONTHS_REVENUE_SUPERCLINIC:
    '/superclinic/dashboard_revenue/total_revenue_last_3_months',
  CLINIC_TOP_FIVE_REVENUE_SUPERCLINIC:
    '/superclinic/dashboard_revenue/top_5_revenu_generated',
  CLINIC_DOCTOR_TOP_FIVE_REVENUE_SUPERCLINIC:
    '/superclinic/dashboard_clinic_doctor/top_5_revenue_generated',
  CLINIC_DOCTOR_TOP_FIVE_SERVICES_SUPERCLINIC:
    '/superclinic/dashboard_clinic_doctor/top_5_clinics_services',
  CLINIC_CLINICS_CONSULTATIONS_SUPERCLINIC:
    '/superclinic/dashboard_clinic_doctor/clinics_with_most_consultations',
  CLINIC_DOCTORS_CONSULTATIONS_SUPERCLINIC:
    '/superclinic/dashboard_clinic_doctor/doctors_with_most_consultations',
  EDIT_SUB_CLINIC_FROM_SUPERCLINIC: '/superclinic/sub-clinic/update',
  VIEW_SUB_CLINIC_OR_SUPERCLINIC: '/superclinic/profile-details/view',

  //Super Clinic BANK DETAILS
  SUPERCLINIC_BANK_DETAILS_VIEW: '/superclinic/bank-details/view',
  SUPERCLINIC_BANK_DETAILS_EDIT: '/superclinic/edit/bankinfo',

  //Super Clinic POC DETAILS
  SUPERCLINIC_POC_DETAILS_VIEW: '/superclinic/poc-details/view',
  SUPERCLINIC_POC_DETAILS_EDIT: '/superclinic/edit/pocinfo',

  // DOCTOR ONBOARD
  ADD_DOCTOR_DETAILS: '/hospital-admin/user/add',
  GET_SUBSCRIPTION_PLAN_LIST: '/public/subscription/plan-list',
  ACTIVATE_SUBSCRIPTION_CHOICE: '/clinic/subscription/activate'
}

const createApiClient = (baseURL = BASE_URL) => {
  ReactGA.event({
    category: 'api-call',
    action: 'tracking calls',
    label: BASE_URL
  })
  const api = create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    // timeout: 15000
  })
  let idToken = ''
  if (localStorage.getItem('routing_mode') === 'doctor') {
    localforage
      .getItem(LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME)
      .then(data => {
        if (data?.user?.id_token) {
          idToken = data.user.id_token
        } else {
          // console.log('nothing here!')
        }
      })
      .catch(err => {
        // console.log('Error retreiving item from local storage', err)
      })
  } else if (localStorage.getItem('routing_mode') === 'doctor') {
    localforage
      .getItem(LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME)
      .then(data => {
        if (data?.user?.id_token) {
          idToken = data.user.id_token
        } else {
          // console.log('nothing here!')
        }
      })
      .catch(err => {
        // console.log('Error retreiving item from local storage', err)
      })
  } else if (localStorage.getItem('routing_mode') === 'clinic') {
    localforage
      .getItem(LOCAL_STORAGE_CLINIC_AUTH_KEY_NAME)
      .then(data => {
        if (data?.user?.id_token) {
          idToken = data.user.id_token
        } else {
          // console.log('nothing here!')
        }
      })
      .catch(err => {
        // console.log('Error retreiving item from local storage', err)
      })
  } else {
    localforage
      .getItem(LOCAL_STORAGE_SUPER_CLINIC_AUTH_KEY_NAME)
      .then(data => {
        if (data?.user?.id_token) {
          idToken = data.user.id_token
        } else {
          // console.log('nothing here!')
        }
      })
      .catch(err => {
        // console.log('Error retreiving item from local storage', err)
      })
  }

  const setHeaders = params => {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key]
        api.setHeader(key, element)
      }
    }
  }

  const setAuthHeaders = params => {
    setHeaders(params)
  }

  return {
    setHeaders,

    setAuthHeaders,

    authenticateUser: payload => {
      return new Promise((resolve, reject) => {
        api.post(URIS.AUTHENTICATION, payload).then(response => {
          if (response.ok) {
            resolve(response)
          } else {
            reject(response)
          }
        })
      })
    },
    sendOtp: payload => {
      return new Promise((resolve, reject) => {
        api.post(URIS.SEND_OTP, payload).then(response => {
          if (response.ok) {
            resolve(response)
          } else {
            reject(response)
          }
        })
      })
    },
    resendOtp: payload => {
      return api.post(URIS.RESEND_OTP, payload)
    },
    getUserRole: payload => {
      return api.post(URIS.PUBLIC_GET_USER_ROLE, payload)
    },

    getUserDetails: payload => {
      return api.post(URIS.PUBLIC_USER_DETAILS, payload)
    },

    getCountryStateCity: payload => {
      return api.post(URIS.PUBLIC_COUNTRY_STATE_CITY, payload)
    },

    getDoctorSchedule: payload => {
      return api.post(URIS.PUBLIC_DOCTOR_GET_SCHEDULES_BY_DATE, payload)
    },

    getDoctorScheduleSlots: payload => {
      return api.post(URIS.DOCTOR_SCHEDULE_SLOTS, payload)
    },

    createPatient: payload => {
      return api.post(URIS.PUBLIC_CREATE_PATIENT, payload)
    },

    createAppointment: payload => {
      return api.post(URIS.PUBLIC_CREATE_APPOINTMENT, payload)
    },
    updateAppointmentStatus: payload => {
      return api.post(URIS.UPDATE_APPOINTMENT_STATUS, payload)
    },

    doctor: {
      updateSchedule: payload => {
        return api.post(URIS.DOCTOR_SCHEDULE_STATIC, payload)
      },
      getAppointmentList: payload => {
        return api.post(URIS.DOCTOR_GET_APPOINTMENTS_BY_DATE, payload)
      },
      getScheduleList: payload => {
        return api.post(URIS.DOCTOR_GET_SCHEDULES, payload)
      },
      deleteSchedule: payload => {
        return api.post(URIS.DOCTOR_DELETE_SCHEDULES, payload)
      },
      updateCalenderSchedule: payload => {
        return api.post(URIS.DOCTOR_UPDATE_CALENDER_SCHEDULES, payload)
      },
      rescheduleAppointment: payload => {
        return api.post(URIS.RESCHEDULE_APPOINTMENT, payload)
      },
      getPatientView: payload => {
        return api.post(URIS.DOCTOR_PATIENT_VIEW, payload)
      },
      createSchedule: payload => {
        return api.post(URIS.DOCTOR_CREATE_SCHEDULE, payload)
      },
      getConsultationSettings: payload => {
        return api.post(URIS.GET_CONSULT_SETTINGS, payload)
      },
      updateConsultationSettings: payload => {
        return api.post(URIS.UPDATE_CONSULT_SETTINGS, payload)
      },
      startAppointment: payload => {
        return api.post(URIS.START_APPOINTMENT, payload)
      },
      getPatientsList: payload => {
        return api.post(URIS.GET_PATIENTS_LIST, payload)
      },
      updateDoctorProfile: payload => {
        return api.post(URIS.UPDATE_DOCTOR_PROFILE, payload)
      },
      updateConsultationFees: payload => {
        return api.post(URIS.DOCTOR_UPDATE_FEES, payload)
      },
      schedulesFetch: payload => {
        return api.post(URIS.SCHEDULES_FETCH, payload)
      },
      schedulesAdd: payload => {
        return api.post(URIS.SCHEDULES_CREATE, payload)
      },
      schedulesUpdate: payload => {
        return api.post(URIS.SCHEDULES_UPDATE, payload)
      },
      schedulesDelete: payload => {
        return api.post(URIS.SCHEDULES_DELETE, payload)
      },
      getDoctorBankDetails: payload => {
        return api.post(URIS.GET_DOCTOR_BANK_DETAILS, payload)
      },
      addDoctorBankDetails: payload => {
        return api.post(URIS.ADD_DOCTOR_BANK_DETAILS, payload)
      },
      doctorWalletView: payload => {
        return api.post(URIS.DOCTOR_WALLET_VIEW, payload)
      },
      clinicWalletRequestList: payload => {
        return api.post(URIS.CLINIC_WALLET_REQUEST_LIST, payload)
      },
      clinicWalletRequestAdd: payload => {
        return api.post(URIS.CLINIC_WALLET_REQUEST_ADD, payload)
      },
      clinicWalletRequestUpdate: payload => {
        return api.post(URIS.CLINIC_WALLET_REQUEST_UPDATE, payload)
      },
      clinicWalletRequestCancel: payload => {
        return api.post(URIS.CLINIC_WALLET_REQUEST_CANCEL, payload)
      },
      doctorAppointmentTransactions: payload => {
        return api.post(URIS.DOCTOR_APPOINTMENT_TRANSACTIONS, payload)
      },
      doctorGetMsgTemplates: payload => {
        return api.post(URIS.DOCTOR_MSG_TEMPLATES, payload)
      },
      doctorGetMsgBalance: payload => {
        return api.post(URIS.DOCTOR_MSG_BALANCE, payload)
      },
      doctorSendMsg: payload => {
        return api.post(URIS.DOCTOR_SEND_MSG, payload)
      },
      doctorSubscriptionHistory: payload => {
        return api.post(URIS.DOCTOR_SUBSCRIPTION_HISTORY, payload)
      },
      doctorSubscriptionAdd: payload => {
        return api.post(URIS.DOCTOR_SUBSCRIPTION_ACTIVATE, payload)
      },
      doctorSubscriptionChoice: payload => {
        return api.post(URIS.DOCTOR_SUBSCRIPTION_CHOICE, payload)
      },
      getSubscriptionList: payload => {
        return api.post(URIS.SUBSCRIPTION_LIST, payload)
      },
      medicalAsstCreate: payload => {
        return api.post(URIS.MED_STAFF_CREATE, payload)
      },
      medicalAsstUpdate: payload => {
        return api.post(URIS.MED_STAFF_UPDATE, payload)
      },
    },

    patient: {
      getPatientVitals: payload => {
        return api.post(URIS.PATIENT_VITALS_LIST, payload)
      },
      getPatientGraphDetails: payload => {
        return api.post(URIS.FETCH_GRAPH_DATA, payload)
      },
      addPatientVitals: payload => {
        return api.post(URIS.ADD_PATIENT_VITALS, payload)
      },
      addDemographicData: payload => {
        return api.post(URIS.ADD_DEMOGRAPHIC_DATA, payload)
      },
      getPatientMedications: payload => {
        return api.post(URIS.GET_PATIENT_MEDICATION, payload)
      },
      getDemographicData: payload => {
        return api.post(URIS.GET_DEMOGRAPHIC_DATA, payload)
      },
      editDemographicData: payload => {
        return api.post(URIS.EDIT_DEMOGRAPHIC_DATA, payload)
      },
      getDiagnosisData: payload => {
        return api.post(URIS.GET_DIAGNOSIS_DATA, payload)
      },
      editDiagnosisData: payload => {
        return api.post(URIS.EDIT_DIAGNOSIS_DATA, payload)
      },
      addMedications: payload => {
        return api.post(URIS.ADD_MEDICATION, payload)
      },
      updateMedication: payload => {
        return api.post(URIS.UPDATE_MEDICATION, payload)
      },
      deleteDemographicData: payload => {
        return api.post(URIS.DELETE_DEMOGRAPHIC_DATA, payload)
      },
      deleteMedication: payload => {
        return api.post(URIS.DELETE_MEDICATION, payload)
      },
      getLab: payload => {
        return api.post(URIS.GET_LAB, payload)
      },
      addLab: payload => {
        return api.post(URIS.ADD_LAB, payload)
      },
      deleteLab: payload => {
        return api.post(URIS.DELETE_LAB, payload)
      },
      editLab: payload => {
        return api.post(URIS.EDIT_LAB, payload)
      },
      getVisitSummaries: payload => {
        return api.post(URIS.GET_VISTIT_SUMMARIES, payload)
      },
      getDiagnosisSearchData: payload => {
        return api.post(URIS.GET_DIAGNOSIS_SEARCH, payload)
      },
      getMedicalSearchData: payload => {
        return api.post(URIS.GET_MEDICAL_LIST, payload)
      },
      generatePrescription: payload => {
        return api.post(URIS.GENERATE_PRESCRIPTION, payload)
      },
      getLabTypes: payload => {
        return api.post(URIS.DOCTOR_LAB_RECORDS_TYPES, payload)
      },
      doPatientRegistration: payload => {
        return api.post(URIS.PATIENT_REGISTRATION, payload)
      },
      initialAccountRegistration: payload => {
        return api.post(URIS.REGISTER_UPDATE_PATIENT_PROFILE, payload)
      },
      getPatientAccountsList: payload => {
        return api.post(URIS.GET_PATIENTS_ACCOUNTS_LIST, payload)
      },
      setPatientinfoDetails: payload => {
        return api.post(URIS.PUBLIC_CREATE_PATIENT, payload)
      },
      addPatientDocument: payload => {
        return api.post(URIS.ADD_DOCUMENT, payload)
      },
      editPatientDocument: payload => {
        return api.post(URIS.EDIT_DOCUMENT, payload)
      },
      deletePatientDocument: payload => {
        return api.post(URIS.DELETE_DOCUMENT, payload)
      },
      updatedPatientProfile: payload => {
        return api.post(URIS.UPDATE_PATIENT_PROFILE, payload)
      },
      getPatientProfile: payload => {
        return api.post(URIS.GET_PATIENT_PROFILE, payload)
      },
      serviceAdd: payload => {
        return api.post(URIS.PATIENT_SERVICE_ADD, payload)
      },
      serviceEdit: payload => {
        return api.post(URIS.PATIENT_SERVICE_EDIT, payload)
      },
      serviceDelete: payload => {
        return api.post(URIS.PATIENT_SERVICE_DELETE, payload)
      },
      serviceList: payload => {
        return api.post(URIS.PATIENT_SERVICE_LIST, payload)
      }
    },
    payment: {
      initializePayment: payload => {
        return api.post(URIS.INITIALIZE_PAYMENT, payload)
      },
      verifyPayment: payload => {
        return api.post(URIS.VERIFY_PAYMENT, payload)
      }
    },
    clinic: {
      clinicWalletView: payload => {
        return api.post(URIS.CLINIC_WALLET_VIEW, payload)
      },
      clinicDashboardData: payload => {
        return api.post(URIS.CLINIC_DASHBOARD_VIEW, payload)
      },
      getDoctorList: payload => {
        return api.post(URIS.CLINIC_DOCTORS_LIST, payload)
      },
      getClinicProfileDetails: payload => {
        return api.post(URIS.CLINIC_PROFILE_VIEW, payload)
      },
      updateClinicProfileDetails: payload => {
        return api.post(URIS.CLINIC_PROFILE_UPDATE, payload)
      },
      updateDoctorStatus: payload => {
        return api.post(URIS.CLINIC_DOCTOR_STATUS_UPDATE, payload)
      },
      changeClinicPassword: payload => {
        return api.post(URIS.CLINIC_CHANGE_PASSWORD, payload)
      },
      fetchServicesList: payload => {
        return api.post(URIS.CLINIC_SERVICES_LIST, payload)
      },
      clinicServicesAdd: payload => {
        return api.post(URIS.CLINIC_SERVICES_ADD, payload)
      },
      clinicServicesEdit: payload => {
        return api.post(URIS.CLINIC_SERVICES_EDIT, payload)
      },
      clinicServicesDelete: payload => {
        return api.post(URIS.CLINIC_SERVICES_DELETE, payload)
      },
      clinicServicesUpdateStatus: payload => {
        return api.post(URIS.CLINIC_SERVICES_STATUS_UPDATE, payload)
      },
      cancelAppointment: payload => {
        return api.post(URIS.CANCEL_APPOINTMENT, payload)
      },
      patientSearch: payload => {
        return api.post(URIS.CLINIC_PATIENT_SEARCH, payload)
      },
      patientBookAppointment: payload => {
        return api.post(URIS.CLINIC_BOOK_APPOINTMENT, payload)
      },
      getPayoutList: payload => {
        return api.post(URIS.CLINIC_PAYOUT_LIST, payload)
      },
      getClinicEarnings: payload => {
        return api.post(URIS.CLINIC_DOCTOR_REVENUE, payload)
      },
      clinicGenerateInvoice: payload => {
        return api.post(URIS.CLINIC_GENERATE_INVOICE, payload)
      },
      clinicPatientServiceMove: payload => {
        return api.post(URIS.CLINIC_PATIENT_SERVICE_MOVE, payload)
      },
      clinicPatientServicePayment: payload => {
        return api.post(URIS.CLINIC_PATIENT_SERVICE_PAYMENT, payload)
      },
      clinicPatientServiceDelete: payload => {
        return api.post(URIS.CLINIC_PATIENT_SERVICE_DELETE, payload)
      },
      clinicPatientConfirmPayment: payload => {
        return api.post(URIS.CLINIC_APPOINTMENT_PAYMENT_CONFIRM, payload)
      },
      clinicPatientServiceList: payload => {
        return api.post(URIS.CLINIC_PATIENT_SERVICE_LIST, payload)
      },
      clinicSubscriptionView: payload => {
        return api.post(URIS.CLINIC_SUBSCRIPTION_VIEW, payload)
      },
      clinicActivateSubscription: payload => {
        return api.post(URIS.ACTIVATE_SUBSCRIPTION_CHOICE, payload)
      },
      clinicInitializePayment: payload => {
        return api.post(URIS.INITIALIZE_CLINIC_PAYMENT, payload)
      },
      clinicGetBill: payload => {
        return api.post(URIS.GET_CLINIC_BILL, payload)
      },
      clinicVerifyPayment: payload => {
        return api.post(URIS.VERIFY_CLINIC_PAYMENT, payload)
      },
      subscriptionhistory: payload => {
        return api.post(URIS.SUBSCRIPTION_HISTORY_LIST, payload)
      },
      billingPermission: payload => {
        return api.post(URIS.ACTIVATE_BILLING, payload)
      },
      paymentInitalize: payload => {
        return api.post(URIS.INITIALIZE_CLINIC_PAYMENT, payload)
      },
      billingDetails: payload => {
        return api.post(URIS.GET_CLINIC_BILL, payload)
      },
      clinicUploadPrescription: payload => {
        return api.post(URIS.CLINIC_UPLOAD_PRESCRIPTION, payload)
      },
      clinicListManualPrescription: payload => {
        return api.post(URIS.CLINIC_LIST_MANUAL_PRESCRIPTION, payload)
      },
      clinicDownloadReport: payload => {
        return api.post(URIS.CLINIC_DOWNLOAD_REPORT, payload)
      },
      clinicRemoveRecommendedService: payload => {
        return api.post(URIS.CLINIC_REMOVE_RECOMMENDED_SERVICE, payload)
      },
      clinicEditAppointmentStatus: payload => {
        return api.post(URIS.CLINIC_EDIT_APPOINTMENT_STATUS, payload)
      }
    },

    super_clinic: {
      //getting bank info view and edit
      getBankDetails: payload => {
        return api.post(URIS.SUPERCLINIC_BANK_DETAILS_VIEW, payload)
      },

      editBankDetails: payload => {
        return api.post(URIS.SUPERCLINIC_BANK_DETAILS_EDIT, payload)
      },

      //getting Poc info view and edit
      getPocDetails: paylaod => {
        return api.post(URIS.SUPERCLINIC_POC_DETAILS_VIEW, paylaod)
      },

      editPocDetails: paylaod => {
        return api.post(URIS.SUPERCLINIC_POC_DETAILS_EDIT, paylaod)
      },

      removeClinicList: payload => {
        return api.post(URIS.CLINIC_LIST_REMOVAL, payload)
      },
      getmyDoctorList: payload => {
        return api.post(URIS.GET_SUP_CLINIC_DOCTOR_LIST, payload)
      },
      getClinicList: payload => {
        return api.post(URIS.CLINIC_LIST_IN_SUPERCLINIC, payload)
      },
      getClinicProfile: payload => {
        return api.post(URIS.CLINIC_PROFILE_ADD_SUPERCLINIC, payload)
      },
      superClinicEdit: payload => {
        return api.post(URIS.CLINIC_EDIT_SUPERCLINIC, payload)
      },
      getRevenue: payload => {
        return api.post(URIS.CLINIC_REVENUE_SUPERCLINIC, payload)
      },
      getDoctorEarnings: payload => {
        return api.post(
          URIS.CLINIC_REVENUE_DOCTOR_EARNINGS_SUPERCLINIC,
          payload
        )
      },
      getLastMonthsRevenue: payload => {
        return api.post(
          URIS.CLINIC_LAST_THREE_MONTHS_REVENUE_SUPERCLINIC,
          payload
        )
      },
      getTopFiveRevenue: payload => {
        return api.post(URIS.CLINIC_TOP_FIVE_REVENUE_SUPERCLINIC, payload)
      },
      getClinicDoctorFiveRevenue: payload => {
        return api.post(
          URIS.CLINIC_DOCTOR_TOP_FIVE_REVENUE_SUPERCLINIC,
          payload
        )
      },
      getClinicDoctorFiveServices: payload => {
        return api.post(
          URIS.CLINIC_DOCTOR_TOP_FIVE_SERVICES_SUPERCLINIC,
          payload
        )
      },
      getMostClinicConsultations: payload => {
        return api.post(URIS.CLINIC_CLINICS_CONSULTATIONS_SUPERCLINIC, payload)
      },
      getMostDoctorConsultations: payload => {
        return api.post(URIS.CLINIC_DOCTORS_CONSULTATIONS_SUPERCLINIC, payload)
      },
      editSubClinicInSuperClinic: payload => {
        return api.post(URIS.EDIT_SUB_CLINIC_FROM_SUPERCLINIC, payload)
      },
      viewSubClinicOrSuperclinicDetails: payload => {
        return api.post(URIS.VIEW_SUB_CLINIC_OR_SUPERCLINIC, payload)
      }
    },
    hospital: {
      getMyDoctorsList: payload => {
        return api.post(URIS.GET_DOCTOR_LIST, payload)
      },
      getHospitalAppointments: payload => {
        return api.post(URIS.GET_HOSPITAL_APPOINTMENTS, payload)
      },
      cancelHospitalAppointment: payload => {
        return api.post(URIS.CANCEL_HOSPITAL_APPOINTMENT, payload)
      },
      createDoctorDetails: payload => {
        return api.post(URIS.ADD_DOCTOR_DETAILS, payload)
      },
      addDoctorBankDetails: payload => {
        return api.post(URIS.ADD_DOCTOR_BANK_DETAILS, payload)
      },
      getSubscriptionList: payload => {
        return api.post(URIS.GET_SUBSCRIPTION_PLAN_LIST, payload)
      },
      subscriptionChoice: payload => {
        return api.post(URIS.DOCTOR_SUBSCRIPTION_CHOICE, payload)
      },
      authenticateAdminUser: payload => {
        return api.post(URIS.AUTH_ADMIN_USER, payload)
      },
      getDoctorProfileDetails: payload => {
        return api.post(URIS.GET_DOCTOR_DETAILS, payload)
      },
      getDoctorBankDetails: payload => {
        return api.post(URIS.GET_DOCTOR_BANK_DETAILS, payload)
      },
      updateDoctorProfile: payload => {
        return api.post(URIS.UPDATE_DOCTOR_PROFILE, payload)
      },
      updateConsultationFees: payload => {
        return api.post(URIS.DOCTOR_UPDATE_FEES, payload)
      },
      getWalletList: payload => {
        return api.post(URIS.GET_WALLET_REQUESTS, payload)
      },
      getTransactions: payload => {
        return api.post(URIS.GET_TRANSACTIONS, payload)
      }
    }
  }
}

export default { createApiClient }
