import React from 'react'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'
import InputTwoToneIcon from '@material-ui/icons/InputTwoTone'
import * as moment from 'moment'

const useStyles = makeStyles({
  table: {
    minWidth: '700px',
    overflow: 'scroll',
    '@media (max-width:767px)': {
      overflow: 'scroll'
    },
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
      background: '#063bc4',
      color: '#fff',
      fontSize: '16px'
    },
    cellHeaders: {
      fontWeight: 600,
      fontSize: '20px'
    }
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.2, 1.2)'
    }
  }
})

export default function PatientAccList(props) {
  const classes = useStyles()
  const patientList = props.patientListFetched.sort(
    (a, b) => a.user_type - b.user_type
  )
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Patient Name</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Gender</strong>
            </TableCell>
            <TableCell align="left">
              <strong>Date Of Birth</strong>
            </TableCell>
            <TableCell align="left" style={{ width: '45%' }}>
              <strong>Address</strong>
            </TableCell>
            <TableCell align="right" className={classes.cellHeaders}>
              View
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {patientList.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                <span style={{ color: '#2d3864', fontWeight: 550 }}>
                  {row.first_name + ' ' + row.last_name}
                </span>
              </TableCell>
              <TableCell align="left">
                {row.gender
                  ? row.gender === 'male'
                    ? 'Male'
                    : row.gender === 'female'
                    ? 'Female'
                    : row.gender
                  : 'N/A'}
              </TableCell>
              <TableCell align="left">
                {row.dob ? moment(row.dob).format('DD-MM-YYYY') : 'N/A'}
              </TableCell>
              <TableCell align="left">
                {row.address ? row.address : 'N/A'}
              </TableCell>
              <TableCell align="right">
                <InputTwoToneIcon
                  onClick={() => props.handleClick(row)}
                  className={classes.icon}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
