import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Snackbar,
  CircularProgress
} from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import MuiAlert from '@material-ui/lab/Alert'
import { useDropzone } from 'react-dropzone'

import SingleFileUpload from './UploadDialogComponents/SingleFileUpload'
import { ClinicStore } from '../../../../stores'
import { NETWORK_STATUS } from '../../../../Constants'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#00e676'
}

const setErrMess = errors => {
  const errs = []

  if (!errors.length) {
    return []
  }

  errors.forEach(({ code }) => {
    if (code === 'file-too-large') {
      errs.push('File size should be less than 4.5 MB')
    }

    if (code === 'file-invalid-type') {
      errs.push('Only jpeg-s, jpg-s, png-s and pdfs are allowed')
    }
  })

  return errs
}

const UploadDialog = ({ open, onClose, appointmentId }) => {
  const {
    uploadPrescription,
    updateNetworkStatus
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  const [files, setFiles] = useState([])
  const [uploadingAll, setUploadingAll] = useState(false)

  useEffect(() => {
    if (!open) {
      setFiles([])
    }
  }, [open])

  const onDrop = useCallback((accFiles, rejFiles) => {
    const mappedAcc = accFiles.map(file => {
      const fileName = file.name.split('.').slice(0, -1).join('.')
      return {
        file,
        errors: [],
        fileName,
        singleFileErrorMessage: settingErrMsgs(fileName),
        uploading: false,
        successful: null,
        serverErrorMessage: null
      }
    })

    const mappedRejFiles = rejFiles.map(file => ({
      ...file,
      fileName: file.file.name.split('.').slice(0, -1).join('.'),
      singleFileErrorMessage: setErrMess(file.errors)
    }))
    setFiles(curr => [...curr, ...mappedAcc, ...mappedRejFiles])
  }, [])

  const settingErrMsgs = value => {
    const errMsgs = []

    var regexForChecking = /^[0-9A-Za-z_.-]+$/
    if (value === '') {
      errMsgs.push("The prescription name shouldn't be empty")
    }
    if (value.length > 50) {
      errMsgs.push('Max. characters 50')
    }
    if (value.search(' ') !== -1) {
      errMsgs.push('Spaces are not allowed')
    }
    if (!value.match(regexForChecking)) {
      errMsgs.push(
        'Only hyphens, underscores, periods, alphabets & numbers are allowed'
      )
    }
    return errMsgs
  }

  const handleInputFieldsChange = (e, file) => {
    const { target } = e
    const { value } = target
    setFiles(curr =>
      curr.map(fw => {
        if (fw.file === file) {
          return {
            ...fw,
            fileName: value,
            singleFileErrorMessage: settingErrMsgs(value)
          }
        }
        return fw
      })
    )
  }

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: ['.jpeg', '.jpg', '.png', '.pdf'],
    maxSize: 4.5 * 1024 * 1024
  })

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const onUpload = async (file, prescriptionName) => {
    let fileResponse = await convertBase64(file)

    setFiles(curr =>
      curr.map(fw => {
        if (fw.file === file) {
          return {
            ...fw,
            uploading: true,
            successful: null,
            serverErrorMessage: null
          }
        }
        return fw
      })
    )

    const response = await uploadPrescription({
      appointment_id: appointmentId,
      prescription_name: prescriptionName,
      prescription_file: fileResponse
    })

    setFiles(curr =>
      curr.map(fw => {
        if (fw.file === file) {
          return {
            ...fw,
            uploading: false,
            showError: !!response ? response?.statusCode !== 200 : true,
            successful: response?.statusCode === 200 ? true : false,
            serverErrorMessage:
              response?.statusCode !== 200 ? response?.message : null
          }
        }
        return fw
      })
    )
  }

  const onUploadAll = async () => {
    try {
      updateNetworkStatus(NETWORK_STATUS.FETCHING)
      setUploadingAll(true)
      for (const fw of files) {
        if (!fw.singleFileErrorMessage.length && !fw.successful) {
          await onUpload(fw.file, fw.fileName)
        }
      }
      setUploadingAll(false)
      updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    } catch (error) {
      updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }

  const handleSnackbarClose = (event, reason, fileWrapper) => {
    if (reason === 'clickaway') {
      return
    }

    setFiles(curr =>
      curr.map(fw => {
        if (fw.file === fileWrapper.file) {
          return {
            ...fw,
            uploading: false,
            showError: false,
            successful: null,
            serverErrorMessage: null
          }
        }
        return fw
      })
    )
  }

  const onDelete = file => {
    setFiles(curr => curr.filter(fw => fw.file !== file))
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const singleFileUploadList = []

  for (let i = 0; i < files.length; i++) {
    singleFileUploadList.push(
      <React.Fragment key={i}>
        <Snackbar
          open={files[i].showError}
          autoHideDuration={2000}
          onClose={(e, r) => handleSnackbarClose(e, r, files[i])}>
          <Alert
            onClose={(e, r) => handleSnackbarClose(e, r, files[i])}
            severity="error">
            {files[i].serverErrorMessage
              ? files[i].serverErrorMessage
              : 'Oops! Something went wrong. Try again later!'}
          </Alert>
        </Snackbar>
        <Grid item container spacing={2}>
          <SingleFileUpload
            onDelete={onDelete}
            onUpload={onUpload}
            onTextInputChange={handleInputFieldsChange}
            file={files[i].file}
            fileName={files[i].fileName}
            uploading={files[i].uploading}
            successful={files[i].successful}
            errors={files[i].errors.length ? files[i].errors : []}
            singleFileErrorMessage={files[i].singleFileErrorMessage}
          />
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Upload Prescription</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item style={{ width: '100%' }}>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />

              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </Grid>
          {singleFileUploadList}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between" style={{ padding: '0 1rem' }}>
          <Grid item>
            {uploadingAll ? (
              <CircularProgress />
            ) : (
              <Button
                startIcon={<CloudUpload />}
                onClick={onUploadAll}
                color="primary"
                disabled={
                  !files.length ||
                  files.filter(
                    f =>
                      !!f.singleFileErrorMessage.length === true ||
                      f.successful === true
                  ).length === files.length
                }>
                Upload All
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button onClick={onClose} color="secondary">
              Exit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default UploadDialog
