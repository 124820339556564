import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Box,
  Paper
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { FaSearch } from 'react-icons/fa'
import Loading from 'react-loading'
import { NETWORK_STATUS } from '../../Constants'
import { DoctorStore } from '../../stores'
import NoRecordImg from '../../images/norecord.png'
import AppointmentListItem from '../../components/CommonComponents/AppointmentListItem'
import useDebounce from '../../customHooks/useDebounce'

export default function PatientsList() {
  const [searchKeyWord, setSearchKeyWord] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const classes = useStyles()
  const debouncedSearchTerm = useDebounce(searchKeyWord, 1000)

  const { patientsData, networkStatus, totalpages } = DoctorStore.useStoreState(
    state => ({
      ...state.patientsList
    })
  )
  const { getPatientsList } = DoctorStore.useStoreActions(actions => ({
    ...actions.patientsList
  }))

  useEffect(() => {
    getPatientsList({
      status: 0,
      pattern: searchKeyWord,
      table_config_rows_per_page: '10',
      table_config_page_no: currentPage
    })
  }, [debouncedSearchTerm, currentPage])

  const onPaginationClick = (event, value) => {
    setCurrentPage(value)
  }

  const onSearch = e => {
    let x = patientsData.filter(item =>
      item.first_name.toLowerCase().includes(searchKeyWord.toLowerCase())
    )
  }

  const onTextChange = e => {
    setSearchKeyWord(e.target.value)
  }

  return (
    <div className={classes.Patientlistcol}>
      <Box className={classes.aside}>
        <Paper elevation={3} className={classes.paper}>
          <Grid container className={classes.topTitle}>
            <Grid item xs={12} sm={6} md={8}>
              <h3
                style={{
                  marginBottom: '0px',
                  fontWeight: '500',
                  fontSize: 20,
                  color: '#000'
                }}>
                My Patients
              </h3>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl component="fieldset" className={classes.searchcol}>
                <TextField
                  className={classes.inputElement}
                  onChange={onTextChange}
                  label="Search"
                  value={searchKeyWord}
                />
                <FormControlLabel
                  className={classes.searchbtn}
                  control={<FaSearch onClick={onSearch} />}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              borderTop: '1px rgba(25,40,82,0.1) solid',
              marginTop: 10,
              padding: 10
            }}>
            <div
              style={{
                width: '20%',
                fontSize: 12,
                fontWeight: 600,
                color: 'rgba(25,40,82,0.6)'
              }}>
              Patient
            </div>
            <div
              style={{
                width: '12%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}>
              Date of Birth
            </div>
            <div
              style={{
                width: '28%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}>
              Date & Time
            </div>
            <div
              style={{
                width: '10%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}></div>
            <div
              style={{
                width: '20%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}></div>
            <div
              style={{
                width: '10%',
                fontSize: 12,
                color: 'rgba(25,40,82,0.6)',
                fontWeight: 600
              }}>
              Action
            </div>
          </div>
          <div>
            {networkStatus === NETWORK_STATUS.FETCHING ? (
              <Loading
                type="bubbles"
                textAlign="center"
                color="#9138aa"
                height={50}
              />
            ) : patientsData.length ? (
              <>
                {patientsData.map((d, index) => {
                  return (
                    <AppointmentListItem
                      className={classes.MyPatient}
                      image={d.photo_uri}
                      first_name={d.first_name}
                      middle_name={d.middle_name}
                      last_name={d.last_name}
                      id={d.id}
                      patientId={d.patient_id}
                      gender={d.gender}
                      dob={d.dob}
                      mobile={d.mobile_no}
                      email={d.email}
                      appointmentDate={d.appointment_date}
                      start_time={d.start_time}
                      end_time={d.end_time}
                      tag={d.tag}
                      initialConsultation={d.initialConsultation}
                      key={index}
                    />
                  )
                })}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <img
                    alt="No data"
                    style={{ display: 'block', width: '200px' }}
                    src={NoRecordImg}
                  />
                </div>
              </>
            )}
            <Pagination
              count={totalpages}
              shape="rounded"
              onChange={onPaginationClick}
            />
          </div>
        </Paper>
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    minHeight: '100vh',
    // padding: '50px 0 !important',
    '& .MuiInput-underline:before': {
      border: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none !important'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    },
    '& .MuiInput-underline:after': {
      border: 'none'
    }
  },
  Patientlistcol: {
    padding: '20px 10px',
    '@media (max-width:767px)': {
      padding: '0 15px 15px',
      marginTop: '45px'
    }
  },
  searchbtn: {
    position: 'absolute',
    top: '25px',
    right: '0',
    '@media (max-width:767px)': {
      position: 'absolute',
      top: '20px',
      right: '0px'
    }
  },
  topTitle: {
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
      padding: '0 15px'
    },
    '& .MuiSelect-select ': {
      color: '#3f4b79'
    },
    '& span': {
      color: '#3f4b79'
    },
    '& .MuiSelect-icon': {
      color: '#3f4b79'
    }
  },
  inputElement: {
    borderBottom: '1px #ccc solid',
    width: '100%',
    paddingRight: '40px'
  },
  searchcol: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  paper: {
    padding: '30px',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    boxShadow: '0 0px 0px 0 rgba(0,0,0,0.1)',
    '@media (max-width:767px)': {
      marginTop: '0px'
    }
  }
}))
