import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'

import Logo from '../../components/Logo'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    padding: '2px 0',
    color: '#111d4e'
  },
  avatar: {
    width: 60,
    height: 60
  },
  buttonRoot: {
    fontFamily: 'inherit',
    fontSize: '1rem',
    transition: 'color 300ms',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    '&:hover, &:focus': {
      backgroundColor: 'initial',
      color: '#9138aa'
    }
  },
  icon: {
    fill: 'currentColor !important',
    color: 'currentColor !important',
    margin: '0.25rem'
  }
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button
              disableRipple
              variant="text"
              size="small"
              classes={{
                root: classes.buttonRoot
              }}
              style={{ marginRight: '0.5rem' }}
              color="inherit">
              <HeadsetMicIcon classes={{ root: classes.icon }} />{' '}
              <span>033-418-16533</span>
            </Button>
            <Button
              disableRipple
              variant="text"
              size="small"
              classes={{
                root: classes.buttonRoot
              }}
              color="inherit"
              style={{ fontSize: '1rem' }}>
              <OndemandVideoIcon classes={{ root: classes.icon }} />{' '}
              <span>Tutorials</span>
            </Button>
          </div>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
}

export default TopBar
