import React from 'react'
import {
    Box,
    Grid,
    makeStyles,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    TextField
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import Navbar from '../../components/CommonComponents/Navbar'
import DashboardLayoutImg from '../../images/bg2.png'
import bannerimg from '../../images/banner-img.jpg'
import uploadimg from '../../images/logo-img.jpg'
import Button from '@material-ui/core/Button';

export default function Updateclinic() {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <div className={classes.root}>
           <Navbar/>
                <Grid container className={classes.dashboardPage} spacing={3}>
                <Box className="bodycontent-col">
              <Grid item xs={12} sm={3} className="LeftSide">
                <h2>Dummy Clinic Name</h2>
                <ul className="Leftnav">
                    <li className="active"><Link to="">Our Doctors</Link></li>
                    <li><Link to="">Update Clinic Profile</Link></li>
                    <li><Link to="">Add Doctors</Link></li>
                    <li><Link to="">Account</Link></li>
                    <li><Link to="">Subscriptions</Link></li>
                    <li><Link to="">Logout</Link></li>
                </ul>
      
              </Grid>
               <Grid item xs={12} sm={6} className="Cardbox">
                <h3>Update Clinic Profile</h3>
                
                            <form className={classes.root} noValidate autoComplete="off" style={{width:'70%'}}>
                                <Box className="inputcol"><TextField className="input" id="clinicname" label="Clinic Name" /></Box>
                                <Box className="inputcol"><TextField className="input" id="clinicemail" label="Clinic Email" /></Box>
                                <Box className="inputcol"><TextField className="input" id="clinicemail" label="Clinic Phone" /></Box>
                                <Box className="inputcol">
                                <Box className="add-address"><button type="button" className="add-btn">+ Add</button></Box>
                                <TextField className="input" id="address" label="Clinic Address" />
                                    <Box className="Address">24DG/5, ABC Road, New Delhi-201891, India <Link to="" className="close-icon">X</Link></Box>
                                </Box>
                                <Box className="inputcol"><TextField className="input" id="about" label="About Us" /></Box>
                                <Box className="inputcol">
                                    <Box className="add-address"><button type="button" className="add-btn">+ Add</button></Box>
                                <TextField className="input" id="services" label="Services" />
                                    <Box className="add-servicve">ECG <Link to="" className="close-icon">X</Link></Box>
                                    <Box className="add-servicve">X-Ray <Link to="" className="close-icon">X</Link></Box>
                                </Box>
                                <Box className="inputcol">
                                    <button type="button" className="update-btn">Update</button>
                                </Box>
                                
                            </form>
                            <Box className="inputcol">
                                <h6>Request Password Change</h6>
                                <p>(Note: We will send you a reset password link to your registered email address)</p>
                            </Box>
                
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box className="uploadimage">
                            <input className="upload-input"
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                            />
                            <label htmlFor="contained-button-file" className="uploadimagecol">
                                <img className={classes.doctor} src={uploadimg} alt="doctor" />
                                <Button className="upload-btn" variant="contained" component="span">
                                    Upload Logo
        </Button>
                            </label>
                </Box>
                <Box className="uploadbanner">
                            <input className="upload-input"
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                            />
                            <label htmlFor="contained-button-file" className="uploadimagecol2">
                                <img className={classes.doctor} src={bannerimg} alt="doctor" />
                                <Button className="upload-btn" variant="contained" component="span">
                                    Upload Banner Image
        </Button>
                            </label>
                </Box>
              </Grid>
              </Box>
            </Grid>
            
        </div>
    )
}
const useStyles = makeStyles(theme => ({
    // root: {
    //   width: "100%",
    //   "& > * + *": {
    //     marginTop: theme.spacing(2),
    //   },
    // },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
            '@media (max-width:767px)': {
                width: '100% !important'
            }
        }
    },
    dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center',
    background: `url(${DashboardLayoutImg})`,
    backgroundAttachment: 'fixed',
    marginTop:'-105px'
  },
    image: {
        width: '100%'
    },
    logo: {
        marginTop: '1rem',
        cursor: 'pointer',
        height: '80px'
    },
    doctorimg: {
        width: '100%'
    },
    Navbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}))
