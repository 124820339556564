import React, { useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Paper,
  CircularProgress,
  Typography,
  Divider,
  Snackbar,
  IconButton,
  makeStyles,
  withStyles,
  colors
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { CloudDownload } from '@material-ui/icons'
import axios from 'axios'
import fileDownload from 'js-file-download'

import { NETWORK_STATUS } from '../../Constants'

import NoRecordImg from '../../images/norecord.png'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Card = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    height: '100%'
  }
}))(props => <Paper {...props} elevation={3} />)

const PrintPrescriptionDialog = ({
  open,
  onClose,
  appointmentId,
  networkStatus,
  manualPrescriptionList,
  fetchManualPrescriptionList,
  updateNetworkStatus
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (open) {
      fetchManualPrescriptionList({
        appointment_id: appointmentId
      })
    }
  }, [open, fetchManualPrescriptionList, appointmentId])

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    updateNetworkStatus(null)
  }

  const onDownloadClick = async (imageSrc, fileName, fileExtention) => {
    try {
      const { data } = await axios.get(imageSrc, { responseType: 'blob' })
      if (!data) {
        updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
      fileDownload(data, `${fileName}.${fileExtention}`)
    } catch (error) {
      updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }

  let contentInsideCard = (
    <div className={classes.containerDiv}>
      <CircularProgress />
    </div>
  )

  if (networkStatus === NETWORK_STATUS.FETCHING) {
    contentInsideCard = (
      <div className={classes.containerDiv}>
        <CircularProgress />
      </div>
    )
  } else if (!manualPrescriptionList || !manualPrescriptionList.length) {
    contentInsideCard = (
      <img
        src={NoRecordImg}
        className={classes.notFoundImage}
        alt="Not found!"
      />
    )
  } else {
    contentInsideCard = (
      <div className={classes.containerDiv}>
        <ul className={classes.listContainer}>
          {manualPrescriptionList.map(
            ({ id, prescription_url, filename, fileType }) => {
              const fileExtention = prescription_url.split('.').pop()
              const fileName = filename

              return (
                <li className={classes.listItem} key={id}>
                  <div>
                    {fileExtention === 'pdf' ? (
                      <div
                        className={[classes.red, classes.avatar].join(
                          ' '
                        )}>{`.${fileExtention}`}</div>
                    ) : fileExtention === 'jpeg' || fileExtention === 'jpg' ? (
                      <div
                        className={[classes.green, classes.avatar].join(
                          ' '
                        )}>{`.${fileExtention}`}</div>
                    ) : fileExtention === 'png' ? (
                      <div
                        className={[classes.orange, classes.avatar].join(
                          ' '
                        )}>{`.${fileExtention}`}</div>
                    ) : (
                      <div
                        className={[classes.purple, classes.avatar].join(' ')}>
                        {fileName.slice(0, 1)}
                      </div>
                    )}
                  </div>
                  <p className={classes.listText}>{fileName}</p>
                  <div className={classes.listTrailingActions}>
                    <IconButton
                      edge="end"
                      aria-label="download"
                      onClick={onDownloadClick.bind(
                        this,
                        prescription_url,
                        filename,
                        fileExtention
                      )}>
                      <CloudDownload color="primary" />
                    </IconButton>
                  </div>
                </li>
              )
            }
          )}
        </ul>
      </div>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Manual Prescriptions</DialogTitle>
      <DialogContent>
        <div className={classes.grid}>
          <div>
            <Card>
              {/* <Typography variant="body1" gutterBottom>
                Manual Prescription
              </Typography>
              <Divider variant="middle" /> */}
              {contentInsideCard}
              <Snackbar
                open={networkStatus === NETWORK_STATUS.FAILED}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="error">
                  Oops! Something went wrong. Try again later!
                </Alert>
              </Snackbar>
            </Card>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end" style={{ padding: '0 1rem' }}>
          <Grid item>
            {/* <Button onClick={onClose} color="secondary" variant="contained">
              Exit
            </Button> */}
            <button className={classes.Reportbtn} onClick={onClose}>
              Exit
            </button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default PrintPrescriptionDialog

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 1fr))',
    justifyContent: 'center',
    gridColumnGap: theme.spacing(3),
    columnGap: theme.spacing(3),
    gridRowGap: theme.spacing(6),
    rowGap: theme.spacing(6),
    marginBottom: theme.spacing(4)
  },
  containerDiv: {
    height: theme.spacing(25),
    padding: `0 -${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
    margin: `0 -${theme.spacing(2)}px`,
    '& *': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0
    }
  },
  notFoundImage: {
    height: theme.spacing(25),
    width: theme.spacing(25),
    display: 'block',
    padding: theme.spacing(2),
    margin: 'auto'
  },
  listContainer: {
    listStyleType: 'none',
    display: 'grid',
    gridAutoRows: 'min-content',
    width: '100%',
    gridRowGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    alignSelf: 'stretch',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  avatar: {
    width: '2.5rem',
    height: '2.5rem',
    padding: '0.3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    borderRadius: '50%'
  },
  red: {
    color: theme.palette.getContrastText(colors.red[900]),
    backgroundColor: colors.red[900]
  },
  green: {
    color: theme.palette.getContrastText(colors.green[900]),
    backgroundColor: colors.green[900]
  },
  orange: {
    color: theme.palette.getContrastText(colors.deepOrange[600]),
    backgroundColor: colors.deepOrange[600]
  },
  purple: {
    color: theme.palette.getContrastText(colors.deepPurple[500]),
    backgroundColor: colors.deepPurple[500]
  },
  listItem: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr max-content',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}px`,
    gridColumnGap: theme.spacing(1),
    columnGap: theme.spacing(1)
  },
  listText: {
    fontSize: '90%',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  listTrailingActions: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: `0.5rem`,
    columnGap: `0.5rem`
  },
  Reportbtn: {
    background: '#111d4e',
    cursor: 'pointer',
    borderRadius: '40px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 30px',
    marginLeft: '15px',
    border: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))
