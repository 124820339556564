import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Box } from '@material-ui/core'

import { Link } from 'react-router-dom'
import {
  BOOKING_STATUS,
  VISIT_TYPE,
  LIST_TYPE,
  NETWORK_STATUS
} from '../../Constants'
import Loading from 'react-loading'
import PatientCards from './PatientCards'
import QuickLinks from './QuickLinks'
import DayStatus from './DayStatus'
import PastAppointmentSummary from './PastAppointmentSummary'
import Card from '../../components/CommonComponents/Card'
import { DoctorStore } from '../../stores'
import localforage from 'localforage'
import moment from 'moment'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'

const DoctorDashboard = () => {
  const classes = useStyles()
  const history = useHistory()
  const { upcomingdata, networkStatus } = DoctorStore.useStoreState(state => ({
    ...state.appointments
  }))

  const { getList } = DoctorStore.useStoreActions(actions => ({
    ...actions.appointments
  }))

  const subscriptionPlanAlert = (daysCount, trial) => {
    console.log(daysCount, trial)
    let AlertMsg =
      trial == 'trial_ended'
        ? `Your Trial period ended ${daysCount} ${
            daysCount === 1 ? 'day' : 'days'
          } before. Subscribe to enjoy our services`
        : trial == 'subscription'
        ? `Reminder! Your subscription will be ending in ${daysCount} ${
            daysCount === 1 ? 'day' : 'days'
          }. Renew your subscriptions to enjoy our services`
        : trial == 'trial'
        ? 'Your Trial period will be ending today. Subscribe soon to enjoy our services!'
        : trial == 'trial_before'
        ? `Reminder! Your Trial Period will be ending in ${daysCount} ${
            daysCount === 1 ? 'day' : 'days'
          }. Subscribe to enjoy our services`
        : trial == 'subscription_before'
        ? 'Your Subscription will be ending today. Subscribe soon to enjoy our services!'
        : `Reminder! Your subscription has already expired. Renew your subscriptions to enjoy our services`
    Swal.fire({
      closeOnClickOutside: false,
      title: AlertMsg,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#111d4e',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Subscribe Now',
      cancelButtonText: 'Continue'
    }).then(result => {
      if (result.isConfirmed) {
        history.push('/doctor/payment')
      } else {
        localStorage.setItem('login_alert_skip', true)
        var nextAlert = new Date()
        nextAlert.setHours(nextAlert.getHours() + 12)
        localStorage.setItem('next_alert_time', nextAlert)
      }
    })
  }

  const subscriptionPlanCheck = () => {
    localforage
      .getItem('doctor_subscription')
      .then(data => {
        let today_date = moment().format('DD/MM/YYYY')

        if (data.billing_permission === '1') {
          if (data.next_due_date == '') {
            if (
              today_date ==
              moment(data.trial_end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            ) {
              if (localStorage.getItem('login_alert_skip') !== 'true') {
                subscriptionPlanAlert('', 'trial')
              } else if (localStorage.getItem('next_alert_time')) {
                var today = new Date()
                let nextAlert = localStorage.getItem('next_alert_time')
                nextAlert = new Date(nextAlert)
                let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
                if (timeDiff < 0) {
                  subscriptionPlanAlert('', 'trial')
                }
              }
            } else if (
              moment(new Date()).isBefore(data.trial_end_date) === true
            ) {
              // Time difference
              let day_diff = Math.abs(
                new Date().getTime() - new Date(data.trial_end_date).getTime()
              )

              // days difference
              let days = Math.ceil(day_diff / (1000 * 3600 * 24))

              if (days <= 3) {
                if (localStorage.getItem('login_alert_skip') !== 'true') {
                  subscriptionPlanAlert(Math.abs(days), 'trial_before')
                } else if (localStorage.getItem('next_alert_time')) {
                  var today = new Date()
                  let nextAlert = localStorage.getItem('next_alert_time')
                  nextAlert = new Date(nextAlert)
                  let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
                  if (timeDiff < 0) {
                    subscriptionPlanAlert(Math.abs(days), 'trial_before')
                  }
                }
              }
            } else if (
              moment(new Date()).isAfter(data.trial_end_date) === true
            ) {
              // Time difference
              let day_diff = Math.abs(
                new Date().getTime() - new Date(data.trial_end_date).getTime()
              )

              // days difference
              let days = Math.ceil(day_diff / (1000 * 3600 * 24))

              if (days <= 3) {
                if (localStorage.getItem('login_alert_skip') !== 'true') {
                  subscriptionPlanAlert(Math.abs(days), 'trial_ended')
                } else if (localStorage.getItem('next_alert_time')) {
                  var today = new Date()
                  let nextAlert = localStorage.getItem('next_alert_time')
                  nextAlert = new Date(nextAlert)
                  let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
                  if (timeDiff < 0) {
                    subscriptionPlanAlert(Math.abs(days), 'trial_ended')
                  }
                }
              }
            }
          } else {
            // let end_date = moment(data.next_due_date, 'YYYY-MM-DD').format(
            //   'DD/MM/YYYY'
            // )

            // let end_date = '2021-02-22'
            let days = moment().diff(moment(data.next_due_date), 'days')
            // console.log(days)

            // // Time difference
            // let timeDiff = Math.abs(
            //   new Date().getTime() - new Date(data.next_due_date).getTime()
            // )

            // // days difference
            // let days = Math.floor(timeDiff / (1000 * 3600 * 24))
            if (
              days === 0 &&
              moment().format('DD/MM/YYYY') !==
                moment(data.next_due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            ) {
              if (localStorage.getItem('login_alert_skip') !== 'true') {
                subscriptionPlanAlert('', 'subscription_before')
              } else if (localStorage.getItem('next_alert_time')) {
                var today = new Date()
                let nextAlert = localStorage.getItem('next_alert_time')
                nextAlert = new Date(nextAlert)
                let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
                if (timeDiff < 0) {
                  subscriptionPlanAlert('', 'subscription_before')
                }
              }
            } else if (days <= -1 && days >= -3) {
              if (localStorage.getItem('login_alert_skip') !== 'true') {
                subscriptionPlanAlert(Math.abs(days), 'subscription')
              } else if (localStorage.getItem('next_alert_time')) {
                var today = new Date()
                let nextAlert = localStorage.getItem('next_alert_time')
                nextAlert = new Date(nextAlert)
                let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
                if (timeDiff < 0) {
                  subscriptionPlanAlert(Math.abs(days), 'subscription')
                }
              }
            } else if (days < -3) {
              //
            } else {
              if (localStorage.getItem('login_alert_skip') !== 'true') {
                subscriptionPlanAlert('', null)
              } else if (localStorage.getItem('next_alert_time')) {
                var today = new Date()
                let nextAlert = localStorage.getItem('next_alert_time')
                nextAlert = new Date(nextAlert)
                let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
                if (timeDiff < 0) {
                  subscriptionPlanAlert('', null)
                }
              }
            }
          }
        }
      })
      .catch(err => {
        // console.log(err)
      })
  }

  useEffect(() => {
    getList({
      list_type: LIST_TYPE.UPCOMING.label,
      status: BOOKING_STATUS.ALL.value,
      visit_type: VISIT_TYPE.ALL.id,
      appointment_type: '0',
      sorting: {
        appointment_date: 'ASC',
        start_time: 'ASC'
      }
    })
    subscriptionPlanCheck()
  }, [])

  const offlineLength = upcomingdata?.filter(function (d) {
    return d.appointment_type === '1'
  })
  const onlineLength = upcomingdata?.filter(function (d) {
    return d.appointment_type === '2'
  })
  const checkedInLength = upcomingdata?.filter(function (d) {
    return d.status === '3'
  })

  return (
    <Grid container spacing={1} className={classes.dashboardRoot}>
      <Grid item sm={12} className={classes.insights}>
        {/* Dashboard */}
        <Grid container className={classes.dashboardcontainer}>
          <Grid
            item
            sm={12}
            className={classes.AppointmentsCard}
            style={{ paddingLeft: 0, paddingRight: 0 }}>
            {/* Day Status */}
            <DayStatus
              totalAppointments={2}
              checkedInPatients={6}
              offlineLength={offlineLength.length}
              onlineLength={onlineLength.length}
              checkedInLength={checkedInLength.length}
            />
          </Grid>
          <Grid item sm={12} className={classes.upcomingAppointmentsRoot}>
            {/* Day Board */}
            <div className={classes.upcomingAppointments}>
              <Box style={{ minHeight: '340px', borderRadius: '20px' }}>
                <h4
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    borderBottom: '1px rgba(25,40,82,0.1) solid',
                    marginBottom: 10,
                    paddingBottom: 15
                  }}>
                  Upcoming Appointments
                </h4>
                {/* <DashboardAppointments /> */}
                <div style={{ display: 'flex', marginBottom: 10, padding: 10 }}>
                  <div
                    style={{
                      width: '28%',
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Patient
                  </div>
                  <div
                    style={{
                      width: '25%',
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Date & Time
                  </div>
                  <div
                    style={{
                      width: '15%',
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Status
                  </div>
                  <div
                    style={{
                      width: '20%',
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Type of Appointment
                  </div>
                  <div
                    style={{
                      width: '12%',
                      fontSize: 12,
                      fontWeight: 600,
                      color: 'rgba(25,40,82,0.6)'
                    }}>
                    Action
                  </div>
                </div>
                {networkStatus === NETWORK_STATUS.FETCHING ? (
                  <Loading
                    type="bubbles"
                    textAlign="center"
                    color="#9138aa"
                    height={50}
                  />
                ) : upcomingdata.length ? (
                  upcomingdata.length > 5 ? (
                    <React.Fragment>
                      <PatientCards appointments={upcomingdata.slice(0, 5)} />

                      <Link
                        to="/doctor/appointments"
                        className={classes.navLink}>
                        View More...
                      </Link>
                    </React.Fragment>
                  ) : (
                    <PatientCards appointments={upcomingdata} />
                  )
                ) : (
                  <p style={{ fontWeight: '300' }}>Nothing Found</p>
                )}
              </Box>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            {/* Day Status */}
            <Box className={classes.AppointmentsCard}>
              <QuickLinks linkType={1} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.AppointmentsCard}>
              {/* Day Status */}
              <QuickLinks linkType={2} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.AppointmentsCard}>
              {/* Day Status */}
              <QuickLinks linkType={3} />
            </Box>
          </Grid>
          <PastAppointmentSummary />
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  dashboardRoot: {
    margin: '0',
    justifyContent: 'flex-start',
    '@media (max-width:991px)': {
      margin: '0 0 10px',
      paddingRight: '0'
    },
    '@media (max-width:767px)': {
      margin: '0px 0 10px'
    },
    '@media (max-width:550px)': {
      margin: '0px 0 10px',
      paddingRight: '0px',
      paddingLeft: '15px'
    }
  },
  dashboardcontainer: {
    padding: '25px',
    '@media (max-width:991px)': {
      padding: '0'
    }
  },
  insights: {
    maxWidth: '100%',
    flexBasis: '100%',
    '@media (max-width:991px)': {
      maxWidth: '98%',
      flexBasis: '98%'
    }
  },
  HospitalList: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:991px)': {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    '& img': {
      width: '100%',
      marginBottom: '15px'
    }
  },
  HospitalImg: {
    '& img': {
      width: '100%'
    },
    '@media (max-width:991px)': {
      width: '25%'
    },
    '@media (max-width:550px)': {
      width: '50%'
    }
  },
  // insights: {
  //   maxWidth: 950
  // },
  // upcomingAppointmentsRoot: {
  //   maxWidth: 750
  // },
  upcomingAppointmentsRoot: {
    '@media (max-width:767px)': {
      maxWidth: '98%',
      flexBasis: '100%',
      marginTop: '20px'
    }
  },
  AppointmentsCard: {
    padding: '0 10px 20px',
    '@media (max-width:767px)': {
      maxWidth: '98%',
      flexBasis: '100%',
      padding: '20px 0 0 0 !important'
    }
  },
  upcomingAppointments: {
    maxWidth: '100%',
    minHeight: 375,
    '@media (max-width:767px)': {
      minHeight: 'initial',
      maxWidth: '100%'
    },
    '& h4': {
      color: '#111d4e',
      fontWeight: '400'
    }
  },
  myHospital: {
    width: '100%',
    marginBottom: 16,
    '& h2': {
      fontSize: '24px',
      fontWeight: '500'
    },
    '& img': {
      width: '100%'
    },
    '&>p': {
      fontSize: '14px',
      fontWeight: '400'
    },
    '&>img': {
      width: '100%'
    }
  },
  news: {
    // maxWidth: 'unset'
    '@media (max-width:991px)': {
      maxWidth: '98%',
      flexBasis: '100%'
    }
  },
  NoticeList: {
    width: '100%',
    borderBottom: '1px rgba(0,0,0,0.1) solid',
    '& h3': {
      fontSize: '18px',
      marginBottom: '0px',
      color: 'rgba(0,0,0,0.8)'
    },
    '& p': {
      fontSize: '14px',
      color: 'rgba(0,0,0,0.6)',
      marginTop: '0px'
    },
    '& h6': {
      fontSize: '11px',
      color: 'rgba(0,0,0,0.5)',
      marginTop: '0px'
    }
  },
  embeddedTwitter: {
    display: 'none'
  },
  show: {
    display: 'block'
  }
}))

export default DoctorDashboard
