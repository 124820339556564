import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Tabs, Tab, Box, makeStyles, useTheme } from '@material-ui/core'
// import IncomeBlance from './IncomeBalance'
// import Bankdetails from './Bankdetails'
// import Transactionhistory from './Transactionhistory'
// import TabBgImg from '../../images/tab-bg.png'
// import MediaBgImg from '../../images/right-top-img.jpg'
import WalletRequests from './WalletRequests'
import RequestList from './RequestList'
import LatestOrders from './LatestOrders'
import DashboardContent from './Dashboard'
import SubscriptionHistory from './SubscriptionHistory'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box style={{ marginTop: '15px' }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

export default function FullWidthTabs() {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.accountcol}>
      <h2>My Account</h2>
      <AppBar
        className={classes.accounttab}
        position="static"
        style={{ boxShadow: 'none' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="white"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          <Tab
            className={classes.Tabbutton}
            // style={{ fontWeight: "bold" }}
            label="Dashboard"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.Tabbutton}
            // style={{ fontWeight: "bold" }}
            label="Wallet Requests"
            {...a11yProps(1)}
          />
          <Tab
            className={classes.Tabbutton}
            // style={{ fontWeight: "bold" }}
            label="Request List"
            {...a11yProps(2)}
          />
          <Tab
            className={classes.Tabbutton}
            // style={{ fontWeight: "bold" }}
            label="Earnings"
            {...a11yProps(3)}
          />
          <Tab
            className={classes.Tabbutton}
            label="Subscription History"
            {...a11yProps(4)}
          />
        </Tabs>
      </AppBar>

      <div
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        // onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          className={classes.tabcontentimg}
          value={value}
          index={0}
          dir={theme.direction}>
          <DashboardContent />
        </TabPanel>
        <TabPanel
          className={classes.tabcontentimg2}
          value={value}
          index={1}
          dir={theme.direction}>
          <WalletRequests />
        </TabPanel>

        <TabPanel
          className={classes.tabcontentimg}
          value={value}
          index={2}
          dir={theme.direction}>
          <RequestList />
        </TabPanel>

        <TabPanel
          className={classes.tabcontentimg}
          value={value}
          index={3}
          dir={theme.direction}>
          <LatestOrders />
        </TabPanel>

        <TabPanel
          className={classes.tabcontentimg}
          value={value}
          index={4}
          dir={theme.direction}>
          <SubscriptionHistory />
        </TabPanel>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  accountcol: {
    padding: '0 1.5rem',
    overflow: 'hidden',
    '& h2': {
      marginTop: '0px',
      fontSize: '20px',
      fontWeight: '500',
      color: '#101d4e',
      '@media (max-width:767px)': {
        fontSize: '18px'
      }
    }
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    '& .MuiTab-root': {
      fontSize: '12px',
      padding: '6px 0px'
    },
    '@media (max-width:767px)': {
      marginTop: '5px'
    }
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '15px',
    marginTop: '20px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0,0,0,0.1)',
    '@media (max-width:767px)': {
      marginTop: '0px'
    }
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%'
  },
  Tabbutton: {
    textTransform: 'capitalize',
    fontFamily: 'Poppins',
    color: '#636469',
    fontWeight: '500',
    fontSize: '15px',
    '& span': {
      fontSize: '15px',
      '@media (max-width:767px)': {
        fontSize: '14px',
        width: '200px',
        flexBasis: '200px'
      }
    }
  },
  plus: {
    color: '#fff',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    fontSize: '25px',
    background: '#3f4b79',
    fontWeight: 'bold',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0',
    marginRight: '7px'
  },
  accounttab: {
    backgroundImage: 'linear-gradient(to right, #f5dbff, #e2e1fd)',
    padding: '0px',
    borderRadius: '5px',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#fff',
      background: '#9138aa !important',
      '@media (max-width:767px)': {
        fontSize: '14px'
      }
    },
    '& .MuiTab-textColorPrimary': {
      color: '#979797',
      fontWeight: 'normal'
    }
  },
  tabcontentimg: {
    padding: '0px',
    overflow: 'hidden'
  },
  tabcontentimg2: {
    backgroundColor: '#fff'
  },
  media: {
    // background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  }
}))
