import React from 'react'
import { makeStyles, Paper, Box } from '@material-ui/core'
import { DashboardLayout } from '../../doctorLayouts'
import TabBgImg from '../../images/tab-bg.png'
import MediaBgImg from '../../images/right-top-img.jpg'
import FullWidthTabs from '../../components/DoctorAccount/Accounts'

export default function Account() {
  const classes = useStyles()

  return (
    <DashboardLayout>
      <Box className={classes.aside}>
        <Paper elevation={3} className={classes.paper}>
          <FullWidthTabs />
        </Paper>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    '& .MuiTab-root': {
      fontSize: '12px',
      padding: '6px 0px'
    },
    '@media (max-width:767px)': {
      marginTop: '5px'
    }
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '0px 30px',
    marginTop: '40px',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    boxShadow: '0 0px 0px 0 rgba(0,0,0,0.1)'
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%'
  },
  Tabbutton: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '16px',
    '& span': {
      fontSize: '16px',
      '@media (max-width:767px)': {
        fontSize: '14px',
        width: '200px',
        flexBasis: '200px'
      }
    }
  },
  plus: {
    color: '#fff',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    fontSize: '25px',
    background: '#3f4b79',
    fontWeight: 'bold',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0',
    marginRight: '7px'
  },
  accounttab: {
    backgroundImage: 'linear-gradient(to right, #828fc3 , #34406d)',
    padding: '15px 20px',
    borderRadius: '5px',
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#fff',
      background: '#374370',
      borderRadius: '40px',
      '@media (max-width:767px)': {
        fontSize: '14px'
      }
    },
    '& .MuiTab-textColorPrimary': {
      color: '#fff'
    }
  },
  tabcontentimg: {
    background: `url(${TabBgImg}) center bottom no-repeat`,
    height: '545px'
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  }
}))
