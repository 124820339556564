import React from 'react'
import { Paper, makeStyles, Box } from '@material-ui/core'
import { DashboardLayout } from '../../doctorLayouts'
import MediaBgImg from '../../images/right-top-img.jpg'
import ListBgImg from '../../images/bg2.png'
import ListBgHoverImg from '../../images/listbghover.png'
import CheckedInTopImg from '../../images/right-top-img2.png'
import ProfileShareCard from '../../components/DoctorProfile/ProfileShareCard'

export default function ProfileCard() {
  const classes = useStyles()
  return (
    <DashboardLayout>
      <Box className={classes.aside}>
        <Paper elevation={3} className={classes.paper}>
          <ProfileShareCard />
        </Paper>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    minHeight: '100vh'
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '10px',
    marginTop: '20px',
    borderRadius: '10px',
    marginBottom: '15px',
    boxShadow: '0 0px 0px 0 rgba(0,0,0,0.1)',
    backgroundColor: 'transparent',
    '@media (max-width:1024px)': {
      marginTop: '10px'
    }
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%'
  },
  plus: {
    color: '#fff',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    fontSize: '25px',
    background: '#3f4b79',
    fontWeight: 'bold',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0',
    marginRight: '7px'
  },
  Profilecard: {
    width: '100%',
    padding: '10px 20px'
  },
  ProfilecolTop: {
    width: '100%',
    height: '0px',
    background: `url(${CheckedInTopImg}) center top no-repeat`,
    backgroundSize: 'cover'
  },
  ProfilecolMiddle: {
    width: '100%',
    padding: '25px 30px',
    background: `url(${ListBgImg}) center top no-repeat`,
    backgroundSize: 'cover'
  },
  ProfileImage: {
    width: '170px',
    height: '170px',
    borderRadius: '50%',
    marginBottom: '20px',
    '&>div': {
      width: '170px',
      height: '170px',
      border: '6px solid #ededed'
    }
  },
  Profilecol: {
    width: '100%',
    border: '1px #ccc solid'
  },
  ProfileLeft: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    '& h3': {
      fontSize: '22px',
      color: '#3f4b79',
      fontWeight: 'bold'
    },
    '& p': {
      fontSize: '16px',
      margin: '0px'
    }
  },
  ProfilecolBottom: {
    width: '100%',
    padding: '20px 30px',
    background: `url(${ListBgHoverImg}) center top no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& img': {
      width: '102px',
      height: '28px'
    },
    '& p': {
      fontSize: '14px',
      color: '#fff',
      marginBottom: '0px'
    }
  },
  Barcode: {
    width: '100%',
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      fontSize: '16px',
      textAlign: 'center',
      marginTop: '30px'
    },
    '& h4': {
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '30px',
      marginBottom: '25px'
    }
  },
  Toplogo: {
    width: '100%',
    marginBottom: '75px'
  },
  Btncol: {
    width: '100%',
    padding: '20px 40px'
  },
  Sharebtn: {
    width: '100%',
    height: '50px',
    background: '#3f4b79',
    fontSize: '18px',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    marginBottom: '20px'
  },
  Downloadbtn: {
    width: '100%',
    height: '50px',
    background: '#3f4b79',
    fontSize: '18px',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none'
  },
  Btnrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  viewModal: {
    padding: '30px 200px',
    '@media (max-width:1420px)': {
      padding: '25px 180px'
    }
  },
  buttons: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: '40px',
    '@media (max-width:1420px)': {
      paddingTop: '0px'
    }
  },
  ModalBox: {
    width: '40%',
    height: '30%',
    background: '#fff',
    padding: '0px',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  AssignButton: {
    marginTop: '20px',
    '@media (max-width:1420px)': {
      position: 'relative',
      left: '210px',
      bottom: '60px'
    }
  }
}))
