import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { ClinicStore } from '../../../../stores'
import Loader from '../../../components/ClinicLoader'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import { NETWORK_STATUS } from '../../../../Constants'
import NoRecordImg from '../../../../images/noRecordFound.png'

import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px',
    marginTop: '70px'
  }
}))

const Services = ({
  className,
  doctors,
  doctorFeeAdd,
  doctorFees,
  appointment,
  serviceAddToInvoice,
  selectedServicesInvoice,
  ...rest
}) => {
  const classes = useStyles()
  const [services, setServices] = useState([])
  const [servicesRecommended, setServicesRecommended] = useState([])
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const { networkStatus, servicesList } = ClinicStore.useStoreState(state => ({
    ...state.appointment
  }))
  const { appointmentServicesList } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  useEffect(() => {
    appointmentServicesList({
      appointment_id: appointment.id,
      list_type: 'taken'
    })
  }, [])

  useEffect(() => {
    if (servicesList.length !== 0) {
      setServices(servicesList.filter(i => i.service_status === '1'))
      setServicesRecommended(servicesList.filter(i => i.service_status === '2'))
    }
  }, [servicesList])

  return (
    <>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={() => setOpen(false)}
      />
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Doctor Fees" />
        <Divider />
        <PerfectScrollbar style={{ overflow: 'auto' }}>
          <Box>
            <Table>
              <TableBody>
                <TableRow hover>
                  <TableCell style={{ wordBreak: 'break-word' }}>
                    Doctor Fees
                  </TableCell>
                  <TableCell style={{ wordBreak: 'break-word' }}>
                    Rs. {appointment.doctor_fee}
                  </TableCell>
                  <TableCell style={{ wordBreak: 'break-word' }}>
                    {appointment.is_payment_done === '2'
                      ? 'Payment Done'
                      : 'Payment Due'}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={doctorFees}
                      color="primary"
                      onChange={doctorFeeAdd}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
        style={{ marginTop: '10px' }}>
        <CardHeader title="Services Taken" />
        <Divider />
        <PerfectScrollbar style={{ overflow: 'auto' }}>
          <Box>
            <Table>
              <TableBody>
                {services
                  .filter(i => i.service_payment_status === '1')
                  .map(item => (
                    <TableRow hover>
                      <TableCell style={{ wordBreak: 'break-word' }}>
                        {item.service_name}
                      </TableCell>
                      <TableCell style={{ wordBreak: 'break-word' }}>
                        {item.service_amount}
                      </TableCell>
                      <TableCell style={{ wordBreak: 'break-word' }}>
                        {item.service_payment_status === '1'
                          ? 'Payment Done'
                          : 'Payment Due'}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={selectedServicesInvoice.find(
                            value => value === item.patient_service_id
                          )}
                          color="primary"
                          onChange={() =>
                            serviceAddToInvoice(
                              item.patient_service_id,
                              servicesList
                            )
                          }
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {services.length === 0 ? (
              <img
                src={NoRecordImg}
                className={classes.notFoundImage}
                alt={'Not found!'}
              />
            ) : (
              ''
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

Services.propTypes = {
  className: PropTypes.string
}

export default Services
