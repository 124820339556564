import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Box } from '@material-ui/core'
import PatientDetailsVitals from './PatientDetailVitals'
import { useParams, Link } from 'react-router-dom'

const PatientDetails = props => {
  const params = useParams()
  const classes = useStyles()

  return (
    <Box className={classes.PatientInfoCol}>
      <Box className={classes.PatientTop}>
        <PatientDetailsVitals />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: '15px',
    float: 'left',
    width: '100%',
    padding: '15px'
  },
  vitals: {
    float: 'left',
    width: '100%',
    fontWeight: 'normal'
  },
  dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  CloseBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: '#3f4b79',
    fontSize: '20px',
    '&:hover': {
      color: '#000'
    }
  },
  JoinBtn: {
    width: '250px',
    height: '50px',
    background: '#3f4b79',
    borderRadius: '40px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    display: 'block',
    margin: '15px auto',
    '@media (max-width:767px)': {
      width: '100%'
    },
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  BtnRow: {
    width: '100%'
  },
  TeleBtn: {
    border: 'none',
    background: '#3f4b79',
    borderRadius: 'none',
    padding: '10px 20px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999',
    '& .makeStyles-modal-141': {
      zIndex: '999999999'
    }
  },
  ModalBody: {
    width: '600px',
    backgroundColor: '#fff',
    padding: '20px',
    textAlign: 'center',
    position: 'relative',
    '@media (max-width:767px)': {
      width: '98%'
    },
    '& h2': {
      fontWeight: 'bold',
      fontSize: '20px',
      '@media (max-width:767px)': {
        fontSize: '16px',
        padding: '0 10%'
      }
    },
    '& h5': {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#000',
      '@media (max-width:767px)': {
        fontSize: '16px'
      }
    },
    '& h6': {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#3f4b79',
      marginBottom: '30px',
      '@media (max-width:767px)': {
        fontSize: '16px'
      }
    },
    '& h4': {
      fontSize: '24px',
      color: '#3f4b79',
      fontWeight: 'bold',
      '@media (max-width:767px)': {
        fontSize: '20px'
      }
    }
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },
  dashboard: {
    maxWidth: '75%',
    flexBasis: '75%',
    '@media (max-width:1024px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '35px'
    }
  },
  AreaChart: {
    '& svg rect': {
      fill: '#fff'
    }
  },
  ContentCol: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '15px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  PatientTop: {
    backgroundColor: '#f7f6f6',
    margin: '15px 0'
  },
  PatientInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '15px'
  },
  PatientData: {
    paddingRight: '20px',
    '& h2': {
      color: '#3f4b79',
      marginBottom: '15px',
      fontSize: '14px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  VisitedBtn: {
    textAlign: 'center',
    backgroundColor: '#373737',
    borderRadius: '40px',
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#3f4b79',
      boxShadow: 'none'
    }
  },
  OnlineBtn: {
    textAlign: 'center',
    backgroundColor: '#98e039',
    borderRadius: '40px',
    color: '#fff',
    boxShadow: 'none',
    padding: '3px 30px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#98e039',
      boxShadow: 'none'
    }
  },
  PatientInfoRight: {
    display: 'flex',
    flexDirection: 'flex-end',
    alignItems: 'center'
  },
  PatientStatus: {
    marginRight: '80px',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      marginBottom: '20px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  PatientProfile: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    padding: '5px',
    overflow: 'hidden',
    '&>div': {
      width: '120px',
      height: '120px'
    },
    '& img': {
      width: '100%'
    }
  },
  PatientProfileWithoutButton: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    padding: '5px',
    '&>div': {
      width: '100px',
      height: '100px'
    },
    '& img': {
      width: '100%'
    }
  },
  TabBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '& button': {
      backgroundColor: '#f7f6f6',
      marginRight: '15px',
      textTransform: 'capitalize',
      color: '#3f4b79'
    }
  },
  PatientChart: {
    display: 'flex',
    marginBottom: '20px',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      marginRight: '30px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  GrapTop: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexWrap: 'wrap'
    },
    '& h4': {
      fontSize: '14px',
      color: '#414141',
      '& span': {
        fontSize: '12px',
        color: '#999'
      }
    }
  },
  BtnCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '75%'
  },
  ToggleBtn: {
    textTransform: 'capitalize',
    '& button': {
      textTransform: 'capitalize',
      padding: '3px 15px'
    }
  },
  ActiveBtn: {
    backgroundColor: '#eff7ff !important',
    borderColor: '#3d8ff7 !important',
    color: '#3d8ff7'
  },
  ChartBox: {
    backgroundColor: '#fff',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    borderRadius: '5px',
    marginBottom: '15px'
  },
  submitButton: {
    color: '#fff',
    borderRadius: '25px'
  }
}))

export default PatientDetails
