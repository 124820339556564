import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import Page from '../../components/Page'
import Footer from '../../../components/CommonComponents/Footer'
import TopBarHome from '../../layouts/MainLayout/TopBar'
import bannerimg from '../../../images/clinicbanner.png'
import { ClinicStore } from '../../../stores'
import {
  NETWORK_STATUS,
  CLINIC_USER_ID,
  PAYMENTSTATUS
} from '../../../Constants'
import Loader from '../../components/ClinicLoader'
import { useHistory, useParams } from 'react-router-dom'
import SnackbarAlert from '../../../components/CommonComponents/SnackbarAlert'
import Swal from 'sweetalert2'
import { RAZOR_KEY } from '../../../Environment'
import ReactGA from 'react-ga'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(3)
  },
  footercol: {
    width: '100%',
    paddingRight: '5%',
    display: 'flex',
    paddingTop: '50px'
  },
  button: {
    padding: '10px 8px',
    fontWeight: '500',
    letterSpacing: '0',
    textTransform: 'none',
    justifyContent: 'flex-start',
    backgroundColor: '#111d4e',
    color: '#aeaeae',
    marginLeft: 'auto',
    display: 'block',
    '&:hover': {
      backgroundColor: '#111d4e !important',
      color: '#aeaeae'
    }
  },
  disabled: {
    pointerEvents: 'none',
    background: '#e3e3e3'
  }
}))

const snackBarInitialState = {
  isOpen: false,
  message: '',
  alertType: '',
  multibutton: false,
  primaryClick: () => {}
}

const PaymentView = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const { clinic_slug } = useParams()
  const [snackbar, setSnackbar] = useState({ ...snackBarInitialState })
  const [transactionPollingTimer, setTransactionPollingTimer] = useState(null)
  const {
    getClinicBill,
    initializePayment,
    clinicVerifyPayment
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const {
    prepaidBillingDetails,
    networkStatus,
    paymentVerificationResponse,
    transactionDetails
  } = ClinicStore.useStoreState(state => ({
    ...state.profile
  }))

  React.useEffect(() => {
    if (clinic_slug) {
      getClinicBill(clinic_slug)
    } else {
      push('/clinic/login')
    }
  }, [])

  React.useEffect(() => {
    if (transactionDetails == null) {
    } else if (transactionDetails?.statusCode == 201) {
      checkout()
    } else if (transactionDetails?.statusCode == 200) {
      setSnackbar({
        isOpen: true,
        message:
          'The amount was successfully deducted from your wallet. Payment successful',
        multibutton: false,
        alertType: 'success',
        primaryClick: () => push('/clinic/payment-status/success')
      })
    } else {
      setSnackbar({
        isOpen: true,
        message: transactionDetails?.message,
        multibutton: false,
        alertType: 'error',
        primaryClick: () => setSnackbar({ ...snackBarInitialState })
      })
    }
  }, [transactionDetails])

  React.useEffect(() => {
    if (prepaidBillingDetails == null) {
    } else if (prepaidBillingDetails.statusCode == 200) {
    } else {
      return Swal.fire(
        'Not Found',
        'This clinic does not exist',
        'info'
      ).then(() => push('/clinic/login'))
    }
  }, [prepaidBillingDetails])

  const confirmPayment = () => {
    clinicVerifyPayment({
      transaction_id_pg: transactionDetails.request_data.txnid_pg,
      transaction_id_wallet: transactionDetails.request_data.txnid_wallet
    })
  }

  React.useEffect(() => {
    if (paymentVerificationResponse !== null) {
      const { message } = paymentVerificationResponse
      if (paymentVerificationResponse.statusCode == 201) {
        clearInterval(transactionPollingTimer)
        setTransactionPollingTimer(null)
        setSnackbar({
          isOpen: true,
          alertType: 'success',
          message: message,
          multibutton: false,
          primaryClick: () => push('/clinic/payment-status/success')
        })
      } else {
        setSnackbar({
          isOpen: true,
          alertType: 'error',
          message: message + ' Retrying....',
          multibutton: false,
          primaryClick: () => setSnackbar({ ...snackBarInitialState })
        })
      }
    }
  }, [paymentVerificationResponse])

  const checkout = async () => {
    // setPaymentDialog(false)
    let razorpayID = RAZOR_KEY
    let options = {
      key: razorpayID, //,
      amount:
        Number(
          prepaidBillingDetails?.subscription_bill_details?.payment_from_pg
        ) * 100, // 2000 paise = INR 20, amount in paisa
      name: 'CareNowDoc',
      description: 'CareNowDoc Clinic Subscription ',
      image: process.env.PUBLIC_URL + '/logo-icon.png',
      order_id: transactionDetails.request_data.order_id,
      handler: function (response) {
        localStorage.setItem('paymentID', response.razorpay_payment_id)
        localStorage.setItem('orderID', response.razorpay_order_id)
        localStorage.setItem('signature', response.razorpay_signature)
        localStorage.setItem('txnId', transactionDetails.request_data.txnid)
        if (response.razorpay_payment_id && response.razorpay_order_id) {
          let gaLabel =
            response.razorpay_payment_id +
            ': ' +
            ' ' +
            'Paid through link - ' +
            'Clinic Slug - ' +
            clinic_slug

          ReactGA.event({
            category: 'razorpay',
            action: response.razorpay_order_id,
            label: gaLabel
          })
          setTransactionPollingTimer(
            setInterval(() => {
              confirmPayment()
            }, 1500)
          )
        } else {
          let gaLabel =
            transactionDetails.txnid +
            ': ' +
            localStorage.getItem('clinic_user_id')
          ReactGA.event({
            category: 'razorpay',
            action: 'failed',
            label: gaLabel
          })
        }
      },
      modal: {
        ondismiss: () => {
          // setPaymentDialog(false)
        }
      },
      prefill: {
        // name: profileDetails.display_name,
        // contact: profileDetails.phone_number,
        // email: profileDetails.email
      },
      theme: {
        color: '#17BAE0'
      }
    }
    let rzp = new window.Razorpay(options)
    rzp.open()

    const modalDocument = document.querySelector('#modal.mchild')

    //console.log(modalDocument);
    if (modalDocument) {
      modalDocument.style.top = '60px'
    }
  }

  return (
    <Page className={classes.root} title="Login">
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <TopBarHome />

      <SnackbarAlert {...snackbar} />

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        style={{ paddingTop: '20px' }}>
        <Container maxWidth="sm">
          <Box className="payment-col">
            <h2>
              {prepaidBillingDetails?.subscription_bill_details?.clinic_name}
            </h2>
            {prepaidBillingDetails?.subscription_bill_details?.status ==
            PAYMENTSTATUS.PAID ? (
              <h3>You have already paid for your subscription</h3>
            ) : (
              <h3>You are about to pay for your subscription</h3>
            )}
            {/* <h4>
              <b>Subscription Amount:</b> INR 8,000
            </h4> */}
            <h4>
              <b>Payable Amount:</b> <i class="fa fa-inr"></i>{' '}
              {prepaidBillingDetails?.subscription_bill_details
                ?.subscription_fee || 0}
            </h4>
            {/* <h4>
              <b>Period:</b>{' '}
              {prepaidBillingDetails?.subscription_bill_details?.clinic_name}
            </h4> */}
            <h4>
              {' '}
              <b>Start Date:</b>{' '}
              {
                prepaidBillingDetails?.subscription_bill_details
                  ?.subscription_start_date
              }
              <span>
                <b>End Date:</b>{' '}
                {
                  prepaidBillingDetails?.subscription_bill_details
                    ?.next_due_date
                }
              </span>
            </h4>
            <Button
              className={`${classes.button} ${
                prepaidBillingDetails?.subscription_bill_details?.status ==
                  PAYMENTSTATUS.PAID && classes.disabled
              }`}
              onClick={() =>
                initializePayment({
                  clinic_id:
                    prepaidBillingDetails?.subscription_bill_details?.clinic_id,
                  subscription_id:
                    prepaidBillingDetails?.subscription_bill_details
                      ?.clinic_subscription_history_id
                })
              }>
              {prepaidBillingDetails?.subscription_bill_details?.status ==
              PAYMENTSTATUS.PAID
                ? 'Bill Paid'
                : 'Go to payment'}
            </Button>
          </Box>
        </Container>
      </Box>
      <div className={classes.footercol}>
        <Footer />
      </div>
    </Page>
  )
}

export default PaymentView
