import React from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core'
import { Route, Switch, Redirect } from 'react-router-dom'

import NotFoundView from '../SuperClinic/views/errors/NotFoundView'
import Login from '../SuperClinic/views/auth/Login'
import MyDocList from '../SuperClinic/views/doctorList'
import Myaccount from '../SuperClinic/views/myaccount/MyaccountView'
import ClinicList from '../SuperClinic/views/clinicList'
import SuperClinicProtectedRoute from '../SuperClinic/routes/SuperClinicProtectedRoute'
import { SUPER_CLINIC_USER_ID } from '../Constants'
import Dashboard from '../SuperClinic/views/dashboard'
import { DashboardLayout } from '../SuperClinic/layouts/ClinicDashboardLayout'
import { SuperClinicStore } from '../stores'

const SuperClinicRoutes = props => {
  localStorage.setItem('routing_mode', 'superclinic')
  console.log(localStorage.getItem('routing_mode'))

  const { location, match } = props

  const pathName = location.pathname
  if (pathName.endsWith('/login')) {
    let editedPathName = pathName.replace('/login', '')

    if (editedPathName === '/superclinic') {
      editedPathName = '/superclinic/dashboard/revenue'
    }

    return (
      <ThemeProvider theme={theme}>
        {localStorage.getItem(SUPER_CLINIC_USER_ID) ? (
          <Redirect to={editedPathName} />
        ) : (
          <SuperClinicStore.Provider>
            <Login />
          </SuperClinicStore.Provider>
        )}
      </ThemeProvider>
    )
  }

  if (match.isExact) {
    return <Redirect to="/superclinic/login" />
  }

  const pageContent = (
    <SuperClinicProtectedRoute>
      <SuperClinicStore.Provider>
        <Switch>
          <Route exact path="/superclinic/doctors" component={MyDocList} />
          <Route path="/superclinic/myclinic" component={ClinicList} />
          <Route path="/superclinic/dashboard" component={Dashboard} />
          <Route path="/superclinic/myaccount" component={Myaccount} />
          <Route path="/superclinic" component={NotFoundView} />
        </Switch>
      </SuperClinicStore.Provider>
    </SuperClinicProtectedRoute>
  )

  return (
    <ThemeProvider theme={theme}>
      <DashboardLayout display={pathName !== '/superclinic/login'}>
        {pageContent}
      </DashboardLayout>
    </ThemeProvider>
  )
}

export default SuperClinicRoutes

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(17, 29, 78)'
    }
  },
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(',')
  }
})
