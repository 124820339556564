import React, { useState, useEffect } from 'react'
import ReactJoyride from 'react-joyride'

const TutorialSteps = ({ open, previousState, steps }) => {
  const initialState = {
    run: false,
    steps: steps
  }
  const [joyride, setJoyride] = useState(initialState)

  useEffect(() => {
    setJoyride({ ...joyride, run: open })
  }, [open, previousState])

  const handleJoyrideCallback = data => {
    if (
      data?.action === 'close' ||
      data.status === 'finished' ||
      data.action === 'skip'
    ) {
      setJoyride({ ...joyride, run: false })
    }
  }

  return (
    <React.Fragment>
      <ReactJoyride
        steps={joyride.steps}
        run={joyride.run}
        continuous
        showProgress
        showSkipButton
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        callback={handleJoyrideCallback}
      />
    </React.Fragment>
  )
}

export default TutorialSteps
