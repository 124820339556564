import React, { useEffect } from 'react'
import { Box, Grid, Link, makeStyles } from '@material-ui/core'
import NoRecordImg from '../../images/norecord.png'
import EditBtnImg from '../../images/edit-btn.jpg'
import DeleteBtnImg from '../../images/delete-btn.jpg'
import { DoctorStore } from '../../stores'
import AddEditDemographicModal from './AddEditDemographicModal'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import { SNACKBAR_ALERT_TYPES } from '../../Constants'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'

const PatientDetaildemographic = props => {
  const { classes, params, disabled } = props

  const [openDemographic, setOpenDemographic] = React.useState(false)
  const [isDemographicAdd, setisDemographicAdd] = React.useState(true)
  const [demographicEditData, setdemographicData] = React.useState({})
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [deleteItem, setDeleteItem] = React.useState({})
  const [alertOpen, setAlertOpen] = React.useState(false)

  const {
    demographicDataCaregiver,
    demographicDataEmergency,
    addDemoGraphicDataResponse,
    editDemoGraphicDataResponse,
    deleteDemoGraphicDataResponse
  } = DoctorStore.useStoreState(state => ({
    ...state.patients
  }))

  const { clearSnack, getDemoGraphicData, deleteDemoGraphicData } =
    DoctorStore.useStoreActions(actions => ({
      ...actions.patients
    }))

  useEffect(() => {
    getDemoGraphicData({
      patient_id: params.patient_id
    })

    return () => {
      clearSnack()
    }
  }, [])

  const onDemographicEdit = item => {
    setisDemographicAdd(false)
    setdemographicData(item)
    setOpenDemographic(true)
  }

  const confirmDemographicDelete = item => {
    setDeleteItem(item)
    setAlertOpen(true)
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  const onDemographicDelete = () => {
    deleteDemoGraphicData({
      demographic_id: deleteItem.id,
      patient_id: params.patient_id
    })
    setAlertOpen(false)
  }

  useEffect(() => {
    if (addDemoGraphicDataResponse === undefined) {
      //
    } else if (addDemoGraphicDataResponse?.statusCode === 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Demographic Data added successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage(JSON.parse(addDemoGraphicDataResponse?.message))
      setmultiButton(false)
      setAlertType('error')
    }
  }, [addDemoGraphicDataResponse])

  useEffect(() => {
    if (editDemoGraphicDataResponse === undefined) {
      //
    } else if (editDemoGraphicDataResponse?.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Demographic Data has been updated successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage(JSON.parse(editDemoGraphicDataResponse?.message))
      setmultiButton(false)
      setAlertType('error')
    }
  }, [editDemoGraphicDataResponse])

  useEffect(() => {
    if (deleteDemoGraphicDataResponse === undefined) {
      //
    } else if (deleteDemoGraphicDataResponse?.statusCode === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Demographic Data has been deleted successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Error while deleting the record!')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [deleteDemoGraphicDataResponse])

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  const handleOpen = () => {
    setisDemographicAdd(true)
    setOpenDemographic(true)
  }

  const handleClose = () => {
    setOpenDemographic(false)
  }
  const valid = true
  return (
    <React.Fragment>
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ color: '#f41e1e' }}>
          <i
            class="fa fa-exclamation-triangle"
            style={{ marginRight: '10px' }}></i>{' '}
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this details?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onDemographicDelete} style={{ color: '#f41e1e' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        secondaryClick={onDemographicDelete}
        autoHideDuration={5000}
      />
      <Box className={classes.InfoCol}>
        <Box className={classes.InfoColTop}>
          <h3 style={{ fontWeight: '500', color: '#000' }}>
            Caregivers Information
          </h3>
          <button
            className={disabled ? classes.DisabledAddBtn : classes.AddBtn}
            onClick={handleOpen}
            disabled={disabled}
            style={{ width: 'auto', padding: '0 20px', fontSize: '12px' }}>
            Add New
          </button>
        </Box>
        {demographicDataCaregiver.length ? (
          demographicDataCaregiver.map(item => (
            <Grid
              container
              spacing={3}
              className={classes.InfoRow}
              style={{ borderBottom: '1px dashed rgba(0,0,0,0.2)' }}>
              <Grid item xs={12} sm={6}>
                <p>
                  <span style={{ fontWeight: '600' }}>Name</span> {item.name}
                </p>
                <p>
                  <span style={{ fontWeight: '600' }}>Contact Number</span>{' '}
                  {item.contact_no}
                </p>
                <p>
                  <span style={{ fontWeight: '600' }}>Email Address</span>{' '}
                  {item.email}
                </p>
                <p>
                  <span style={{ fontWeight: '600' }}>Relation</span>{' '}
                  {item.relationship}
                </p>
              </Grid>
              <Grid item xs={12} sm={3}>
                {!disabled ? (
                  <Link
                    style={{ cursor: 'pointer' }}
                    className={classes.EditBtn}
                    onClick={() => {
                      onDemographicEdit(item)
                    }}>
                    {valid ? (
                      <img src={EditBtnImg} />
                    ) : (
                      <img src={EditBtnImg} />
                    )}
                    Edit
                  </Link>
                ) : null}
                {!disabled ? (
                  <Link
                    style={{ cursor: 'pointer' }}
                    className={classes.DeleteBtn}
                    onClick={() => {
                      confirmDemographicDelete(item)
                    }}>
                    {valid ? (
                      <img src={DeleteBtnImg} />
                    ) : (
                      <img src={DeleteBtnImg} />
                    )}
                    Delete
                  </Link>
                ) : null}
              </Grid>
            </Grid>
          ))
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {valid ? (
                <img
                  alt="No data"
                  style={{ display: 'block', width: '200px' }}
                  src={NoRecordImg}
                />
              ) : (
                <img
                  alt="No data"
                  style={{ display: 'block', width: '200px' }}
                  src={NoRecordImg}
                />
              )}
            </div>
          </>
        )}
      </Box>

      <Box className={classes.InfoCol}>
        <Box className={classes.InfoColTop}>
          <h3 style={{ fontWeight: '500', color: '#000' }}>
            Emergency Contact Information
          </h3>
        </Box>
        {demographicDataEmergency.length ? (
          demographicDataEmergency.map(item => (
            <Grid
              container
              spacing={3}
              className={classes.InfoRow}
              style={{ borderBottom: '1px dashed rgba(0,0,0,0.2)' }}>
              <Grid item xs={12} sm={6}>
                <p>
                  <span style={{ fontWeight: '600' }}>Name</span> {item.name}
                </p>
                <p>
                  <span style={{ fontWeight: '600' }}>Contact Number</span>{' '}
                  {item.contact_no}
                </p>
                <p>
                  <span style={{ fontWeight: '600' }}>Email Address</span>{' '}
                  {item.email}
                </p>
                <p>
                  <span style={{ fontWeight: '600' }}>Relation</span>{' '}
                  {item.relationship}
                </p>
              </Grid>
              <Grid item xs={12} sm={3}>
                {!disabled ? (
                  <Link
                    className={classes.EditBtn}
                    onClick={() => {
                      onDemographicEdit(item)
                    }}>
                    {valid ? (
                      <img src={EditBtnImg} />
                    ) : (
                      <img src={EditBtnImg} />
                    )}
                    Edit
                  </Link>
                ) : null}

                {!disabled ? (
                  <Link
                    className={classes.DeleteBtn}
                    onClick={() => {
                      confirmDemographicDelete(item)
                    }}>
                    {valid ? (
                      <img src={DeleteBtnImg} />
                    ) : (
                      <img src={DeleteBtnImg} />
                    )}
                    Delete
                  </Link>
                ) : null}
              </Grid>
            </Grid>
          ))
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {valid ? (
                <img
                  alt="No data"
                  style={{ display: 'block', width: '200px' }}
                  src={NoRecordImg}
                />
              ) : (
                <img
                  alt="No data"
                  style={{ display: 'block', width: '200px' }}
                  src={NoRecordImg}
                />
              )}
            </div>
          </>
        )}
      </Box>

      {/* Demographic modal */}
      {openDemographic ? (
        <AddEditDemographicModal
          showHideModal={handleClose}
          isShow={openDemographic}
          pateintId={params.patient_id}
          editData={demographicEditData}
          isAdd={isDemographicAdd}></AddEditDemographicModal>
      ) : (
        <></>
      )}
    </React.Fragment>
  )
}

export default PatientDetaildemographic
