import React from 'react'
import { Container } from '@material-ui/core'

import SuperClinicDoctorList from './DoctorList'

const MyDocList = () => {
  return (
    <Container maxWidth={false}>
      <SuperClinicDoctorList />
    </Container>
  )
}

export default MyDocList
