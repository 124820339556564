import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

import PersonIcon from '@material-ui/icons/Person'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow:'0 1px 7px 0 rgba(0,0,0,0.1)',
    borderRadius:'10px'
  },
  avatar: {
    backgroundColor: '#111d4e',
    height: 50,
    width: 50
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}))

const ActiveDoctors = ({ className, activeDoctors, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <h4 style={{fontWeight:'600',color:'rgb(96 101 121)'}}>Active Doctors</h4>
            <h5 style={{color:'#9138aa',fontWeight:'600', fontSize:'20px',margin:'0px'}}>{activeDoctors}</h5>
            {/* <Typography
              color="textSecondary"
              gutterBottom
              style={{ fontSize: '1rem' }}>
              
            </Typography> */}
            {/* <Typography color="textPrimary" variant="h5">
              
            </Typography> */}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PersonIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

ActiveDoctors.propTypes = {
  className: PropTypes.string
}

export default ActiveDoctors
