import React from 'react'
import { makeStyles, Paper, Box } from '@material-ui/core'
import { DashboardLayout } from '../../doctorLayouts'
import MediaBgImg from '../../images/right-top-img.jpg'
import Staffs from '../../components/MyStaffs/StaffsList'

export default function MyStaffs() {
  const classes = useStyles()

  return (
    <DashboardLayout>
      <Box className={classes.aside} style={{ padding: '40px' }}>
        <Paper elevation={3} className={classes.paper}>
          <Staffs />
        </Paper>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  aside: {
    minHeight: '100vh',
    '& .MuiTab-root': {
      fontSize: '12px',
      padding: '6px 0px'
    },
    '@media (max-width:767px)': {
      marginTop: '35px'
    }
    // padding: '50px 0 !important'
  },

  paper: {
    padding: '15px'
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%'
  },
  plus: {
    color: '#fff',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    fontSize: '25px',
    background: '#3f4b79',
    fontWeight: 'bold',
    lineHeight: '25px',
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0',
    marginRight: '7px'
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box style={{ marginTop: '15px' }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}
