import { action, thunk, computed } from 'easy-peasy'

import BaseModel from '../Base'
import { NETWORK_STATUS } from '../../Constants'
import api from '../../api'
import moment from 'moment'
import localforage from 'localforage'
import {
  DOCTOR_USER_ID,
  LOCAL_STORAGE_DOCTOR_AUTH_KEY_NAME,
  API_REQUEST_DATETIME_FORMAT,
  DEMOGRAPHIC_TYPE
} from '../../Constants'
import { replace } from 'formik'
import responsiveObserve from 'antd/lib/_util/responsiveObserve'

export default {
  ...BaseModel(),
  patientsList: [],
  patientView: '',
  patientVitals: [],
  successResponse: false,
  patientVitalsHistory: [],
  patientVitalsGraph: [],
  medications: [],
  medicationsFetchingStatus: false,
  oldMedications: [],
  labs: [],
  // deleteLabDataResponse: false,
  demographicDataCaregiver: [],
  demographicDataEmergency: [],
  diagnosisData: {
    chronic_conditions: [],
    suggestions: '',
    diagnosis: '',
    clinical_notes: '',
    others: ''
  },
  documentList: [],
  getVisitSummariesData: [],
  viewSummary: {},
  diagnosisSearch: [],
  MedicalList: [],
  diagnosisFetchingStatus: false,
  prescription: [],
  prescriptionResult: '',
  lablist: [],
  labsFetchingStatus: false,
  medicationsListStatus: false,
  labRecordsListStatus: false,
  servicesList: [],
  servicesTakenList: [],

  // for patients detail page view section
  getPatientView: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const { doctor_id, patient_id, appointment_id } = payload

      const response = await api.doctor.getPatientView({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        patient_id: patient_id,
        appointment_id: appointment_id
      })
      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setPatientView(response.data.patient)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setPatientView: action((state, payload) => {
    state.patientView = payload
  }),

  // for patients detail page vitals section
  getPatientVitals: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.setPatientVitalsHistory([])
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const {
        doctor_id,
        patient_id,
        start_time,
        end_time,
        list_type,
        table_config_page_no,
        table_config_rows_per_page
      } = payload

      const response = await api.patient.getPatientVitals({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        patient_id: patient_id,
        start_time: start_time,
        end_time: end_time,
        list_type: list_type,
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no
      })

      const latestHealthHubVitals = await new Promise(async resolve => {
        try {
          const resp = await fetch(
            'https://luzvk76flk.execute-api.ap-south-1.amazonaws.com/uat/sync-controller',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                action: 'SCAN_SYNC',
                carenowdoc_id: patient_id,
                table_config_rows_per_page: 1,
                table_config_page_no: 1
              })
            }
          )
          const respJSON = await resp.json()
          if (respJSON.statusCode?.toString().startsWith('2')) {
            const result = respJSON.result?.[0]
            resolve({
              id: 'health-hub-latest-vitals-data',
              patient_id: patient_id,
              temperature: '98.6',
              bmi_index: result?.physiological_scores?.bmi,
              sbp: result?.vitals?.bp_sys,
              dbp: result?.vitals?.bp_dia,
              heart_rate: result?.vitals?.heart_rate,
              respiratory_rate: result?.vitals?.resp_rate,
              oxygen_saturation_rate: result?.vitals?.oxy_sat_prcnt,
              datetime_recorded: result?.created_at,
              last_modified: result?.created_at,
              height: result?.physiological_scores?.height,
              weight: result?.physiological_scores?.weight
            })
          } else throw new Error(respJSON?.message)
        } catch (err) {
          console.error(
            'Failed to fetch latest vitals data from Health Hub',
            err
          )
          resolve(undefined)
        }
      })

      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        if (latestHealthHubVitals)
          response.data.vitals.unshift(latestHealthHubVitals)
        if (list_type == 'chart') {
          actions.setPatientVitals(response.data.vitals)
        }
        if (list_type == 'history') {
          actions.setPatientVitalsHistory(response.data.vitals)
        }
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  getPatientGraphDetails: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.setPatientVitalsGraph([])
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const { patient_id, type } = payload

      const response = await api.patient.getPatientGraphDetails({
        patient_id: patient_id,
        type: type
      })

      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setPatientVitalsGraph(response.data.vitals)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),
  setPatientVitalsGraph: action((state, payload) => {
    state.patientVitalsGraph = payload
  }),

  clearSnack: action((state, payload) => {
    // state.patientVitals = undefined,
    // state.diagnosisData = undefined,
    state.editDiagnosisResponse = undefined
    state.addDemoGraphicDataResponse = undefined
    state.editDemoGraphicDataResponse = undefined
    state.deleteDemoGraphicDataResponse = undefined
    state.addMedicationsResponse = undefined
    state.editMedicationsResponse = undefined
    state.deleteMedicationsResponse = undefined
    state.addLabDataResponse = undefined
    state.editLabDataResponse = undefined
    state.deleteLabDataResponse = undefined
    state.patientVitalsGraph = undefined
  }),

  addPatientVitals: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const dateTime = moment().format(API_REQUEST_DATETIME_FORMAT)
    const endDate = moment().format(API_REQUEST_DATETIME_FORMAT)
    const startDate = moment()
      .subtract(60, 'days')
      .format(API_REQUEST_DATETIME_FORMAT)

    const {
      patient_id,
      temperature,
      bmi_index,
      sbp,
      dbp,
      heart_rate,
      respiratory_rate,
      oxygen_saturation_rate,
      height,
      weight,
      appointment_id
    } = payload

    const response = await api.patient.addPatientVitals({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id,
      datetime_recorded: dateTime,
      temperature,
      bmi_index,
      sbp,
      dbp,
      heart_rate,
      respiratory_rate,
      oxygen_saturation_rate,
      height,
      weight,
      appointment_id
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setVitalsSuccessResponse(response?.data)

      actions.getPatientVitals({
        patient_id,
        start_time: startDate,
        end_time: endDate,
        list_type: 'chart',
        table_config_rows_per_page: 20,
        table_config_page_no: 1
      })
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setVitalsSuccessResponse: action((state, payload) => {
    state.addVitalsResponse = payload
  }),

  setPatientVitals: action((state, payload) => {
    state.patientVitals = payload
  }),

  addDemoGraphicData: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.addDemographicData({
      name: payload.name,
      contact_no: payload.contact_no,
      email: payload.email.toLowerCase(),
      type: payload.type,
      relationship: payload.relationship,
      patient_id: payload.pateintId
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getDemoGraphicData({
        patient_id: payload.pateintId
      })
      actions.setAddDemoGraphicDataResponse(response?.data)
      actions.setSuccessResponse(true)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setAddDemoGraphicDataResponse: action((state, payload) => {
    state.addDemoGraphicDataResponse = payload
  }),

  getDemoGraphicData: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { patient_id } = payload

    const response = await api.patient.getDemographicData({
      patient_id: patient_id,
      table_config_rows_per_page: '25',
      table_config_page_no: '1'
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDemographicData(response.data.response)
      if (response.data.status == '404') {
        actions.setDemographicData([])
      }
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setSuccessResponse: action((state, payload) => {
    state.successResponse = payload
  }),

  setPatientVitalsHistory: action((state, payload) => {
    state.patientVitalsHistory = payload
  }),

  setMedications: action((state, payload) => {
    const medications = []
    const oldMedications = []
    payload.forEach(medication => {
      const momentEndDate = moment(medication.end_date, 'YYYY-MM-DD')

      if (moment().isAfter(momentEndDate)) oldMedications.push(medication)
      else medications.push(medication)
    })
    state.medications = medications
    state.oldMedications = oldMedications
  }),

  getPatientMedications: thunk(async (actions, payload) => {
    const { patientId } = payload
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    actions.setMedicationsListStatus(true)
    const { data } = await api.patient.getPatientMedications({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id: patientId,
      active: true,
      table_config_page_no: 1,
      table_config_rows_per_page: 10
    })
    actions.setMedicationsListStatus(false)
    actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    if (data?.medications) {
      actions.setMedications(data.medications)
    }
  }),

  setMedicationsListStatus: action((state, payload) => {
    state.medicationsListStatus = payload
  }),

  setDemographicData: action((state, payload) => {
    if (payload) {
      state.demographicDataCaregiver = payload.filter(
        item => item.type == DEMOGRAPHIC_TYPE.CAREGIVER.id
      )
      state.demographicDataEmergency = payload.filter(
        item => item.type == DEMOGRAPHIC_TYPE.EMERGENCY.id
      )
    }
  }),

  editDemoGraphicData: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.editDemographicData({
      name: payload.name,
      contact_no: payload.contact_no,
      email: payload.email.toLowerCase(),
      type: payload.type,
      relationship: payload.relationship,
      demographic_id: payload.demographic_id
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getDemoGraphicData({
        patient_id: payload.patient_id
      })
      actions.setEditDemoGraphicDataResponse(response?.data)
      actions.setSuccessResponse(true)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setEditDemoGraphicDataResponse: action((state, payload) => {
    state.editDemoGraphicDataResponse = payload
  }),

  deleteDemoGraphicData: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { demographic_id, patient_id } = payload

    const response = await api.patient.deleteDemographicData({
      demographic_id
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getDemoGraphicData({
        patient_id: patient_id
      })
      actions.setDeleteDemoGraphicDataResponse(response?.data)
      actions.setSuccessResponse(true)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setDeleteDemoGraphicDataResponse: action((state, payload) => {
    state.deleteDemoGraphicDataResponse = payload
  }),

  getDiagnosisData: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const { appointment_id } = payload
    const response = await api.patient.getDiagnosisData({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      appointment_id: appointment_id
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDiagnosisData(
        response && response.data && response.data.diagnosis
      )
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDiagnosisData: action((state, payload) => {
    state.diagnosisData = payload
  }),
  editDiagnosisData: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.patient.editDiagnosisData({
      appointment_id: payload && payload.appointment_id,
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id: payload && payload.patient_id,
      observations:
        payload && payload?.values?.diagnosis ? payload?.values?.diagnosis : '',
      clinical_notes:
        payload && payload?.values?.clinical_notes
          ? payload?.values?.clinical_notes
          : '',
      diagnosis:
        payload && payload?.values?.observations
          ? payload?.values?.observations
          : [],
      suggestions:
        payload && payload?.values?.suggestions
          ? payload?.values?.suggestions
          : '',
      others: payload && payload?.values?.others ? payload?.values?.others : ''
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setEditDiagnosisData(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setEditDiagnosisData: action((state, payload) => {
    state.editDiagnosisResponse = payload
  }),
  addMedications: thunk(async (actions, payload) => {
    const {
      patientId,
      appointmentId,
      frequency,
      duration,
      instructions,
      startDate,
      medication_id,
      others,
      durationString,
      medication_name_type
    } = payload

    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.addMedications({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id: patientId,
      appointment_id: appointmentId,
      medication_id: medication_id ? medication_id.toString() : '',
      others: payload.others ? others.toString() : '',
      start_date: startDate,
      frequency,
      duration: duration * durationString,
      instructions,
      medication_name_type: medication_name_type
    })
    if (response.ok) {
      actions.setAddMedicationResponse(response?.data)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getPatientMedications({ patientId })
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setAddMedicationResponse: action((state, payload) => {
    state.addMedicationsResponse = payload
  }),
  editMedication: thunk(async (actions, payload) => {
    const {
      patientId,
      appointmentId,
      frequency,
      duration,
      instructions,
      startDate,
      medication_id,
      others,
      id,
      durationString,
      medication_name_type
    } = payload

    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.updateMedication({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id: patientId,
      appointment_id: appointmentId,
      medication_id: medication_id ? medication_id.toString() : '',
      others: payload.others ? others.toString() : '',
      start_date: startDate,
      id: id,
      frequency,
      duration: duration * durationString,
      instructions,
      medication_name_type: medication_name_type
    })
    if (response.ok) {
      actions.setEditMedicationResponse(response?.data)
      actions.getPatientMedications({ patientId })
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setEditMedicationResponse: action((state, payload) => {
    state.editMedicationsResponse = payload
  }),
  deleteMedication: thunk(async (actions, payload) => {
    const { medicationId, patientId } = payload

    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.deleteMedication({
      medication_id: medicationId,
      doctor_id: localStorage.getItem(DOCTOR_USER_ID)
    })
    if (response.ok) {
      actions.setDeletedMedicationResponse(response?.data)
      actions.getPatientMedications({ patientId })
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDeletedMedicationResponse: action((state, payload) => {
    state.deleteMedicationsResponse = payload
  }),
  getLab: thunk(async (actions, payload) => {
    const { patientId } = payload
    actions.setLabListStatus(true)
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.getLab({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id: patientId,
      table_config_rows_per_page: '25',
      table_config_page_no: '1',
      type_of_document: 'lab',
      list_type: 'labs'
    })
    if (response.ok) {
      actions.setLabListStatus(false)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setLabs(response.data)
    } else {
      actions.setLabListStatus(false)
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setLabListStatus: action((state, payload) => {
    state.labRecordsListStatus = payload
  }),

  getDocuments: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { patientId } = payload

    const { data } = await api.patient.getLab({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id: patientId,
      table_config_rows_per_page: '25',
      table_config_page_no: '1',
      type_of_document: 'all',
      list_type: 'documents'
    })

    actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)

    actions.setDocuments(data.response || [])
  }),
  setLabs: action((state, payload) => {
    state.labs = payload
  }),
  setDocuments: action((state, payload) => {
    state.documentList = payload
  }),
  addLab: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const {
      patientId,
      report,
      reportName,
      prescribedOn,
      type,
      testName,
      instructions,
      appointmentId
    } = payload

    const response = await api.patient.addLab({
      patient_id: patientId,
      report: report || '',
      report_name: reportName || '',
      report_status: reportName ? '2' : '1',
      prescribed_on: prescribedOn,
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      others: testName ? testName : '',
      lab_master_id: type !== 'others' ? type : '',
      instructions: instructions,
      type_of_document: 'lab',
      appointment_id: appointmentId
    })
    if (response.ok) {
      actions.getLab({ patientId })
      actions.setLabResponse(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setLabResponse: action((state, payload) => {
    state.addLabDataResponse = payload
  }),

  editLab: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const {
      patientId,
      report,
      reportName,
      prescribedOn,
      testName,
      type,
      instructions,
      labId,
      documentId
    } = payload

    const response = await api.patient.editLab({
      patient_id: patientId,
      report: report || '',
      report_name: reportName || '',
      report_status: reportName ? '2' : '1',
      prescribed_on: prescribedOn,
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      others: testName ? testName : '',
      lab_master_id: type !== 'others' && type !== 'initial' ? type : '',
      lab_id: labId,
      instructions: instructions,
      document_id: documentId,
      type_of_document: 'lab'
    })

    if (response.ok) {
      actions.getLab({ patientId })
      actions.setEditLabResponse(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setEditLabResponse: action((state, payload) => {
    state.editLabDataResponse = payload
  }),

  deleteLab: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const { patientId, labId, doctor_id } = payload

    const response = await api.patient.deleteLab({
      lab_id: labId,
      doctor_id: doctor_id,
      patient_id: patientId ? patientId : ''
    })

    if (response.ok) {
      actions.getLab({ patientId })
      actions.setDeleteLabResponse(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDeleteLabResponse: action((state, payload) => {
    state.deleteLabDataResponse = payload
  }),

  getVisitSummaries: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const {
      patient_id,
      table_config_rows_per_page,
      table_config_page_no,
      user_id,
      view_type
    } = payload
    const response = await api.patient.getVisitSummaries({
      patient_id: patient_id ? patient_id : '',
      table_config_rows_per_page: table_config_rows_per_page
        ? table_config_rows_per_page
        : '',
      table_config_page_no: table_config_page_no ? table_config_page_no : '',
      user_id: user_id ? user_id : '',
      view_type: view_type
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setGetVisitSummariesData(
        response && response.data && response.data.response
      )
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setGetVisitSummariesData: action((state, payload) => {
    state.getVisitSummariesData = payload
  }),
  viewVisitSummary: thunk(async (actions, payload) => {
    const response = await api.patient.getVisitSummaries({
      appointment_id: payload.appointment_id,
      patient_id: payload.patient_id,
      user_id: payload.user_id,
      view_type: payload.view_type
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setViewSummaries(
        response && response.data && response.data.response
      )
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setViewSummaries: action((state, payload) => {
    state.viewSummary = payload
  }),
  getDiagnosisSearch: thunk(async (actions, payload) => {
    actions.setDiagnosisFetchingStatus(true)
    const response = await api.patient.getDiagnosisSearchData({
      pattern: payload && payload.eventData,
      table_config_page_no: payload && payload.table_config_page_no,
      table_config_rows_per_page: payload && payload.table_config_rows_per_page
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDiagnosisSearch(
        response && response.data && response.data.response
      )
      actions.setDiagnosisFetchingStatus(false)
    } else {
      actions.setDiagnosisFetchingStatus(false)
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDiagnosisFetchingStatus: action((state, payload) => {
    state.diagnosisFetchingStatus = payload
  }),
  setDiagnosisSearch: action((state, payload) => {
    state.diagnosisSearch = payload ? payload : []
  }),

  getMedicalSearch: thunk(async (actions, payload) => {
    actions.setMedicationsFetchingStatus(true)
    actions.setMedicalSearch([])
    if (payload.searchString !== '') {
      const response = await api.patient.getMedicalSearchData({
        pattern: payload && payload.searchString,
        table_config_page_no: payload && payload.table_config_page_no,
        table_config_rows_per_page:
          payload && payload.table_config_rows_per_page,
        medication_name_type: payload?.medication_name_type
      })
      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setMedicalSearch(response?.data?.response)
        actions.setMedicationsFetchingStatus(false)
      } else {
        actions.setMedicationsFetchingStatus(false)
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    } else {
      actions.setMedicationsFetchingStatus(false)
    }
  }),
  setMedicalSearch: action((state, payload) => {
    if (payload == undefined) {
      //
    } else {
      let sortedPayload = payload.sort((a, b) => a.type.localeCompare(b.type))
      // console.log(sortedPayload, 'SORTED PAYLOAD')
      state.MedicalList = sortedPayload
    }
  }),
  setMedicationsFetchingStatus: action((state, payload) => {
    state.medicationsFetchingStatus = payload
  }),

  generatePrescriptionPdf: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const { appointment_id } = payload

      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const response = await api.patient.generatePrescription({
        appointment_id: appointment_id,
        view_type: 'pdf'
      })
      if (response?.data?.statusCode == 200) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return response?.data?.pdf_blob
      } else {
        actions.setPrescriptionStatus(response?.data)
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),
  setPrescriptionPdf: action((state, payload) => {
    state.prescription = payload
  }),
  setPrescriptionStatus: action((state, payload) => {
    state.prescriptionResult = payload
  }),
  getLabRecordsNames: thunk(
    async (actions, payload, { dispatch, getStoreState }) => {
      actions.setLabListFetching(true)
      const response = await api.patient.getLabTypes({
        table_config_rows_per_page: '10',
        table_config_page_no: '1',
        table_config_search_string: payload?.table_config_search_string
          ? payload?.table_config_search_string
          : ''
      })
      if (response?.data?.statusCode === 201) {
        actions.setLabList(response?.data)
        actions.setLabListFetching(false)
      } else {
        actions.setLabListFetching(false)
      }
    }
  ),
  setLabList: action((state, payload) => {
    state.lablist = payload?.data
  }),
  setLabListFetching: action((state, payload) => {
    state.labsFetchingStatus = payload
  }),

  getClinicServices: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const response = await api.clinic.fetchServicesList({
        doctor_id: localStorage.getItem(DOCTOR_USER_ID),
        table_config_rows_per_page: 10,
        table_config_page_no: payload?.current_page ? payload?.current_page : 1,
        pattern: ''
      })
      if (response.data.status == '200') {
        actions.fetchedServices(response.data.response)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  fetchedServices: action((state, payload) => {
    if (payload) {
      state.servicesList = payload
    }
  }),

  listPatientServices: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const response = await api.patient.serviceList({
        action: 'list',
        appointment_id: payload?.appointment_id,
        list_type: 'all',
        table_config_rows_per_page: 10,
        table_config_page_no: 1
      })
      if (response.data.status == '200') {
        actions.fetchedTakenServices(response.data.response)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  fetchedTakenServices: action((state, payload) => {
    if (payload) {
      state.servicesTakenList = payload
    }
  }),

  addPatientServices: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const response = await api.patient.serviceAdd({
        service_status: payload?.service_status,
        service_master_id: payload?.service_master_id,
        appointment_id: payload?.appointment_id,
        patient_id: payload?.patient_id
      })
      if (response.data.statusCode == '200') {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return response.data
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        return false
      }
    }
  ),

  editPatientServices: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const response = await api.patient.serviceEdit({
        action: 'edit',
        patient_service_id: 'f99dde59-d1c5-43f6-981a-61b1985845cd',
        service_status: '1',
        service_master_id: 'af700ecc-3c67-4b24-9d0a-04a479af3be2',
        appointment_id: 'dc29d34c-9e68-4fcd-be39-6c4172eaae33'
      })
      if (response.data.statusCode == '200') {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return response.data
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  deletePatientServices: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('doctor_auth_key')
      })
      const response = await api.patient.serviceDelete({
        action: 'delete',
        patient_service_id: payload?.patient_service_id
      })
      if (response.data.statusCode == '200') {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        return response.data
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        return false
      }
    }
  )
}
