import React from 'react'
import { makeStyles, Grid, TextField, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import Loading from 'react-loading'
import { useForm } from 'react-hook-form'
import { useFormik } from 'formik'
import { DoctorStore } from '../../stores'
import * as yup from 'yup'
import {
  NETWORK_STATUS,
  VALIDATE_WHITESPACES,
  DOCTOR_USER_ID,
  SPECIAL_CHAR
} from '../../Constants'
import VisibilityIcon from '@material-ui/icons/Visibility'
import InputAdornment from '@material-ui/core/InputAdornment'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(() => ({
  userForm: {
    width: '100%',
    padding: '0px'
  },
  requestbtn: {
    width: '220px',
    cursor: 'pointer',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#5a5f95',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none'
    // '&:hover': {
    //   backgroundColor: '#000'
    // }
  },

  blancebox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    width: '40%'
  },
  btncol: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px'
  },
  input: {
    width: '60%'
  },
  input2: {
    width: '100%'
  },
  topheading: {
    '& h2': {
      fontSize: '20px',
      color: '#374370',
      marginTop: '20px',
      marginBottom: '0px'
    }
  },
  accountbox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    '& p': {
      fontSize: '20px',
      color: '#a2a2a2',
      margin: '0px'
    },

    '& h3': {
      fontSize: '40px',
      color: '#374370',
      margin: '0px',
      lineHeight: '40px'
    }
  },
  errMsg: {
    color: 'red',
    fontSize: '12px',
    fontFamily: 'san-serif',
    fontWeight: 'bold'
  },
  viewIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))
export default function Bankdetails() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  // const account_no = React.useRef({})
  const [bankdetails, setBankDetails] = React.useState(false)
  const user_master_id = localStorage.getItem(DOCTOR_USER_ID)
  const [accVisibility, setAccVisibility] = React.useState(false)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  // account_no.current = watch('account_no', '')

  // Store Action
  const {
    doctorBankDetails,
    getBankDetails,
    snackClear
  } = DoctorStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  // Store State
  const {
    docbankdetailsAddedStatus,
    getBankList,
    networkStatus
  } = DoctorStore.useStoreState(state => ({
    ...state.profile
  }))

  React.useEffect(() => {
    if (user_master_id !== undefined) {
      getBankDetails({ user_master_id: user_master_id })
    }

    return () => {
      snackClear()
    }
  }, [])

  React.useEffect(() => {
    if (getBankList && getBankList.message) {
      setBankDetails(getBankList.message)
    }
  }, [getBankList])

  React.useEffect(() => {
    if (docbankdetailsAddedStatus == undefined) {
      //
    } else if (
      docbankdetailsAddedStatus.statusCode === 400 ||
      docbankdetailsAddedStatus.statusCode === 533
    ) {
      setOpenSnackBar(true)
      setSnackBarMessage(
        docbankdetailsAddedStatus.message
      )
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Bank Details Added Successfully...')
      setmultiButton(false)
      setAlertType('success')
    }
  }, [docbankdetailsAddedStatus])

  //Submission of the form
  const submit = async values => {
    doctorBankDetails({
      ...values
    })
  }

  // To handle the Snackbar
  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  const formik = useFormik({
    initialValues: {
      account_no: bankdetails.primary_account_no
        ? bankdetails.primary_account_no
        : '',
      cnfrmaccount_no: bankdetails.primary_account_no
        ? bankdetails.primary_account_no
        : '',
      bank_name: bankdetails.primary_bank_name
        ? bankdetails.primary_bank_name
        : '',
      branch_name: bankdetails.primary_branch_name
        ? bankdetails.primary_branch_name
        : '',
      ifsc_code: bankdetails.primary_ifsc_code
        ? bankdetails.primary_ifsc_code
        : '',
      beneficiary_name: bankdetails.primary_beneficiary_name
        ? bankdetails.primary_beneficiary_name
        : ''
    },

    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const Input = e => {
    e.preventDefault()
    setOpenSnackBar(true)
    setSnackBarMessage('Paste option disabled...')
    setmultiButton(false)
    setAlertType('info')
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate={true}
      className={classes.root}>
      {/* {docbankdetailsAddedStatus ===
        undefined ? null : docbankdetailsAddedStatus.statusCode === 400 ||
          docbankdetailsAddedStatus.statusCode === 533 ? (
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'bottom' }}>
              <Alert onClose={handleClose} severity="error">
                {docbankdetailsAddedStatus.message &&
                  docbankdetailsAddedStatus.message.includes('primary_ifsc_code')
                  ? docbankdetailsAddedStatus.message.replace(
                    'primary_ifsc_code',
                    'Primary IFSC Code'
                  )
                  : null}
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'bottom' }}>
              <Alert onClose={handleClose} severity="success">
                Bank Details Added Successfully
          </Alert>
            </Snackbar>
          )} */}
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        autoHideDuration={5000}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} className={classes.topheading}>
          <h2>Update Bank Details</h2>
        </Grid>
        {networkStatus === NETWORK_STATUS.FETCHING ? (
          <Loading type="cylon" textAlign="center" color="grey" height={40} />
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={8}>
              <TextField
                id="bank_name"
                style={{ width: '100%' }}
                type="text"
                placeholder="Bank Name"
                label="Bank Name *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik && formik.values && formik.values.bank_name}
                name="bank_name"
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.bank_name && formik.errors.bank_name
                      ? formik.errors.bank_name
                      : null}
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="account_no"
                style={{ width: '100%' }}
                // type="password"
                type={accVisibility == true ? 'text' : 'password'}
                autocomplete="off"
                placeholder="Account No"
                onChange={formik.handleChange}
                value={formik && formik.values && formik.values.account_no}
                onBlur={formik.handleBlur}
                name="account_no"
                label="Account No *"
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.account_no && formik.errors.account_no
                      ? formik.errors.account_no
                      : null}
                  </div>
                }
                InputProps={{
                  endAdornment: (
                    <VisibilityIcon
                      onClick={() => setAccVisibility(!accVisibility)}
                      className={classes.viewIcon}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="cnfrmaccount_no"
                style={{ width: '100%' }}
                placeholder="Confirm Account No"
                name="cnfrmaccount_no"
                label="Confirm Account No *"
                onPaste={Input}
                // type="password"
                type="password"
                autocomplete="off"
                value={formik && formik.values && formik.values.cnfrmaccount_no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.cnfrmaccount_no &&
                    formik.errors.cnfrmaccount_no
                      ? formik.errors.cnfrmaccount_no
                      : null}
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="ifsc_code"
                style={{ width: '95%' }}
                type="text"
                placeholder="IFSC Code"
                value={formik && formik.values && formik.values.ifsc_code}
                name="ifsc_code"
                label="IFSC Code *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // disabled={true}
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.ifsc_code && formik.errors.ifsc_code
                      ? formik.errors.ifsc_code
                      : null}
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="branch_name"
                style={{ width: '100%' }}
                type="text"
                placeholder="Branch Name "
                label="Branch Name  *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik && formik.values && formik.values.branch_name}
                name="branch_name"
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.branch_name && formik.errors.branch_name
                      ? formik.errors.branch_name
                      : null}
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="beneficiary_name"
                style={{ width: '95%' }}
                placeholder="Beneficiary Name"
                name="beneficiary_name"
                label="Beneficiary Name *"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                  formik && formik.values && formik.values.beneficiary_name
                }
                helperText={
                  <div className={classes.errMsg}>
                    {formik.touched.beneficiary_name &&
                    formik.errors.beneficiary_name
                      ? formik.errors.beneficiary_name
                      : null}
                  </div>
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <button className={classes.requestbtn} type="submit">
                Update Details
              </button>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </form>
  )
}

const validationSchema = yup.object().shape({
  account_no: yup
    .string()
    .required('Account No is required')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .min(9 ,'Account number should be between 9 to 18 digits')
    .max(18, 'Account number should be between 9 to 18 digits')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining and end'),

  cnfrmaccount_no: yup
    .string()
    .required('Confirm account No is required')
    .oneOf([yup.ref('account_no'), null], 'Confirm Account No must match')
    .matches(/^[0-9]*$/, 'Only numbers are allowed')
    .min(9 ,'Account number should be between 9 to 18 digits')
    .max(18, 'Account number should be between 9 to 18 digits')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining and end'),

  bank_name: yup
    .string()
    .max(50, 'Character exceeded the limit!')
    .required('Bank Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(SPECIAL_CHAR, 'Special Characters are not allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  branch_name: yup
    .string()
    .max(50, 'Character exceeded the limit!')
    .required('Branch Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),
  ifsc_code: yup
    .string()
    .max(11, 'IFSC_Code must be 11 digit code!')
    .required('IFSC Code is required')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),
  beneficiary_name: yup
    .string()
    .max(50, 'Character exceeded the limit!')
    .required('Beneficiary Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    )
})
