var __extends =
  (this && this.__extends) ||
  (function () {
    var extendStatics = function (d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function (d, b) {
            d.__proto__ = b;
          }) ||
        function (d, b) {
          for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
        };
      return extendStatics(d, b);
    };
    return function (d, b) {
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype =
        b === null
          ? Object.create(b)
          : ((__.prototype = b.prototype), new __());
    };
  })();
var __decorate =
  (this && this.__decorate) ||
  function (decorators, target, key, desc) {
    var c = arguments.length,
      r =
        c < 3
          ? target
          : desc === null
          ? (desc = Object.getOwnPropertyDescriptor(target, key))
          : desc,
      d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
      r = Reflect.decorate(decorators, target, key, desc);
    else
      for (var i = decorators.length - 1; i >= 0; i--)
        if ((d = decorators[i]))
          r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
  };
import {
  Component,
  Property,
  NotifyPropertyChanges,
  Event,
  Browser,
  isBlazor,
} from "cn_scheduler/base";
import {
  getDefaultDateObject,
  getValue,
  cldrData,
  L10n,
  isNullOrUndefined,
  removeClass,
  addClass,
} from "cn_scheduler/base";
import { DropDownList } from "cn_scheduler/dropdowns";
import { NumericTextBox } from "cn_scheduler/inputs";
import { DatePicker } from "cn_scheduler/calendars";
import { Button, RadioButton } from "cn_scheduler/buttons";
import { EventHandler, classList } from "cn_scheduler/base";
import {
  extractObjectFromRule,
  generate,
  generateSummary,
  getRecurrenceStringFromDate,
  getCalendarUtil,
} from "./date-generator";
import { capitalizeFirstWord } from "../schedule/base/util";
var HEADER = "e-editor";
var INPUTWARAPPER = "e-input-wrapper";
var INPUTWARAPPERSIDE = "e-input-wrapper-side";
var REPEATELEMENT = "e-repeat-element";
var REPEATINTERVAL = "e-repeat-interval";
var INTERVALCLASS = "e-interval";
var DAYWRAPPER = "e-days";
var WEEKWRAPPER = "e-non-week";
var WEEKPOSITION = "e-week-position";
var YEAREXPANDERWRAPPER = "e-year-expander";
var YEAREXPANDERELEMENT = "e-year-expander-element";
var MONETHEXPANDERWRAPPER = "e-month-expander";
var MONETHEXPANDWRAPPER = "e-month-expand-wrapper";
var MONTHEXPANDERELEMENT = "e-month-expander-element";
var MONTHEXPANDERCHECKBOXWRAPPER = "e-month-expander-checkbox-wrapper";
var FORMLEFT = "e-form-left";
var FORMRIGHT = "e-form-right";
var MONTHDAYWRAPPER = "e-month-day";
var MONTHEXPANNDERELEM = "e-month-expander-wrapper";
var MONTHPOS = "e-month-pos";
var MONTHWEEK = "e-month-week";
var ENDON = "e-end-on";
var MONTHEXPANDERLABEL = "e-month-expander-label";
var WEEKEXPANDERLABEL = "e-week-expander-label";
var ENDONLABEL = "e-end-on-label";
var ENDONLEFT = "e-end-on-left";
var MONTHDAYELEMENT = "e-monthday-element";
var ENDONELEMENT = "e-end-on-element";
var ENDONDATE = "e-end-on-date";
var UNTILDATE = "e-until-date";
var ENDONCOUNTWRAPPER = "e-end-on-count";
var ENDONCOUNT = "e-recurrence-count";
var HIDEWRAPPER = "e-hide-recurrence-element";
var RTLCLASS = "e-rtl";
var PRIMARY = "e-primary";
var ACTIVE = "e-active";
var RECURRENCETABLE = "e-recurrence-table";
var REPEATCONTENT = "e-repeat-content";
var REPEATCONTENTWRAPPER = "e-repeat-content-wrapper";
var NONE = "none";
var DAILY = "daily";
var WEEKLY = "weekly";
var MONTHLY = "monthly";
var YEARLY = "yearly";
var NEVER = "never";
var UNTIL = "until";
var COUNT = "count";
var TEXTFIELD = "text";
var VALUEFIELD = "value";
var LAST = "last";
var REPEAT = "repeat";
var REPEATEVERY = "repeatEvery";
var ON = "on";
var END = "end";
var RADIOLABEL = "onDay";
var RULEUNTIL = "UNTIL";
var RULEBYDAY = "BYDAY";
var RULEBYMONTHDAY = "BYMONTHDAY";
var RULEBYMONTH = "BYMONTH";
var RULEINTERVAL = "INTERVAL";
var RULECOUNT = "COUNT";
var RULESETPOS = "BYSETPOS";
var RULEFREQ = "FREQ";
var RULEDAILY = "DAILY";
var RULEWEEKLY = "WEEKLY";
var RULEMONTHLY = "MONTHLY";
var RULEYEARLY = "YEARLY";
var RULESUNDAY = "SU";
var RULEMONDAY = "MO";
var RULETUESDAY = "TU";
var RULEWEDNESDAY = "WE";
var RULETHURSDAY = "TH";
var RULEFRIDAY = "FR";
var RULESATURDAY = "SA";
var KEYSUNDAY = "sun";
var KEYMONDAY = "mon";
var KEYTUESDAY = "tue";
var KEYWEDNESDAY = "wed";
var KEYTHURSDAY = "thu";
var KEYFRIDAY = "fri";
var KEYSATURDAY = "sat";
var EQUAL = "=";
var SEMICOLON = ";";
var COMMA = ",";
var FIRST = "first";
var SECOND = "second";
var THIRD = "third";
var FOURTH = "fourth";
var contentType = {
  none: "",
  daily: "days",
  weekly: "weeks",
  monthly: "months",
  yearly: "years",
};
var valueData = {
  sun: RULESUNDAY,
  mon: RULEMONDAY,
  tue: RULETUESDAY,
  wed: RULEWEDNESDAY,
  thu: RULETHURSDAY,
  fri: RULEFRIDAY,
  sat: RULESATURDAY,
};
var neverClassList = [
  DAYWRAPPER,
  WEEKWRAPPER,
  ENDON,
  INTERVALCLASS,
  YEAREXPANDERWRAPPER,
  MONETHEXPANDERWRAPPER,
];
var weekClassList = [WEEKWRAPPER];
var monthClassList = [DAYWRAPPER, YEAREXPANDERWRAPPER];
var yearClassList = [DAYWRAPPER];
var dailyClassList = [
  DAYWRAPPER,
  WEEKWRAPPER,
  YEAREXPANDERWRAPPER,
  MONETHEXPANDERWRAPPER,
];
var noEndClassList = [ENDONDATE, ENDONCOUNTWRAPPER];
var endOnCountClassList = [ENDONDATE];
var endOnDateClassList = [ENDONCOUNTWRAPPER];
/**
 * Represents the RecurrenceEditor component.
 * ```html
 * <div id="recurrence"></div>
 * ```
 * ```typescript
 * <script>
 *   var recObj = new RecurrenceEditor();
 *   recObj.appendTo("#recurrence");
 * </script>
 * ```
 */
var RecurrenceEditor = /** @class */ (function (_super) {
  __extends(RecurrenceEditor, _super);
  /**
   * Constructor for creating the widget
   * @param  {object} options?
   */
  function RecurrenceEditor(options, element) {
    var _this = _super.call(this, options, element) || this;
    _this.defaultLocale = {
      none: "None",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      month: "Month",
      yearly: "Yearly",
      never: "Never",
      until: "Until",
      count: "Count",
      first: "First",
      second: "Second",
      third: "Third",
      fourth: "Fourth",
      last: "Last",
      repeat: "Repeat",
      repeatEvery: "Repeat every",
      on: "Repeat On",
      end: "End",
      onDay: "Day",
      days: "Day(s)",
      weeks: "Week(s)",
      months: "Month(s)",
      years: "Year(s)",
      every: "every",
      summaryTimes: "time(s)",
      summaryOn: "on",
      summaryUntil: "until",
      summaryRepeat: "Repeats",
      summaryDay: "day(s)",
      summaryWeek: "week(s)",
      summaryMonth: "month(s)",
      summaryYear: "year(s)",
      monthWeek: "Month Week",
      monthPosition: "Month Position",
      monthExpander: "Month Expander",
      yearExpander: "Year Expander",
      repeatInterval: "Repeat Interval",
    };
    _this.renderStatus = false;
    _this.dayButtons = [];
    _this.monthButtons = [];
    _this.calendarUtil = getCalendarUtil(_this.calendarMode);
    return _this;
  }
  RecurrenceEditor.prototype.startState = function (freq, endOn, startDate) {
    this.showFormElement();
    this.updateForm(freq);
    this.freshOnEndForm();
    this.updateEndOnForm(endOn);
    this.selectMonthDay(startDate);
    this.updateUntilDate(startDate);
    this.onMonthDay.setProperties({ checked: true });
  };
  RecurrenceEditor.prototype.preRender = function () {
    this.localeObj = new L10n(
      this.getModuleName(),
      this.defaultLocale,
      this.locale
    );
    // pre render code snippets
  };
  RecurrenceEditor.prototype.applyCustomClass = function (cssClass) {
    if (cssClass) {
      addClass([this.element], cssClass);
    }
  };
  RecurrenceEditor.prototype.initialize = function () {
    addClass([this.element], "e-" + this.getModuleName());
    this.renderComponent();
    if (!isNullOrUndefined(this.value) && this.value !== "") {
      this.setRecurrenceRule(this.value);
    } else {
      this.startState(
        this.repeatType.value.toString().toUpperCase(),
        NEVER,
        this.startDate
      );
      this.updateForm(this.repeatType.value.toString());
      if (this.selectedType > 0) {
        this.setProperties({ value: this.getRecurrenceRule() }, false);
      }
    }
    this.applyCustomClass(this.cssClass);
  };
  RecurrenceEditor.prototype.triggerChangeEvent = function () {
    var _this = this;
    if (this.renderStatus) {
      var value = this.getRecurrenceRule();
      this.trigger("change", { value: value }, function (args) {
        return _this.setProperties({ value: args.value }, false);
      });
    }
  };
  RecurrenceEditor.prototype.resetDayButton = function () {
    var elements = [].slice.call(
      this.element.querySelectorAll("." + DAYWRAPPER + " button")
    );
    elements.forEach(function (element) {
      return removeClass([element], [ACTIVE, PRIMARY]);
    });
  };
  RecurrenceEditor.prototype.daySelection = function (dayIndex) {
    this.resetDayButton();
    var days = [0, 1, 2, 3, 4, 5, 6];
    this.rotateArray(days, this.firstDayOfWeek);
    var element = this.element.querySelector(
      "." + DAYWRAPPER + ' button[data-index="' + days.indexOf(dayIndex) + '"]'
    );
    if (element) {
      addClass([element], [ACTIVE, PRIMARY]);
    }
  };
  RecurrenceEditor.prototype.rtlClass = function (status) {
    if (status) {
      addClass([this.element], RTLCLASS);
    } else {
      removeClass([this.element], RTLCLASS);
    }
  };
  RecurrenceEditor.prototype.updateUntilDate = function (date) {
    var tempDate = new Date(date.getTime());
    tempDate.setDate(tempDate.getDate() + 60);
    this.untilDateObj.setProperties({ value: tempDate });
  };
  RecurrenceEditor.prototype.selectMonthDay = function (date) {
    var weekday = [
      KEYSUNDAY,
      KEYMONDAY,
      KEYTUESDAY,
      KEYWEDNESDAY,
      KEYTHURSDAY,
      KEYFRIDAY,
      KEYSATURDAY,
    ];
    this.monthDate.setProperties({ value: this.calendarUtil.getDate(date) });
    this.monthWeekDays.setProperties({
      value: valueData[weekday[date.getDay()]],
    });
    this.monthValue.setProperties({
      value: "" + this.calendarUtil.getMonth(date),
    });
    this.monthWeekPos.setProperties({ value: this.getDayPosition(date) });
    this.daySelection(date.getDay());
  };
  RecurrenceEditor.prototype.updateForm = function (state) {
    var _this = this;
    this.repeatType.setProperties({ value: state });
    var end = this.element.querySelector("." + ENDON);
    if (state === DAILY) {
      classList(end, [FORMLEFT], [FORMRIGHT]);
    } else {
      classList(end, [FORMRIGHT], [FORMLEFT]);
    }
    switch (state) {
      case NONE:
        neverClassList.forEach(function (className) {
          return addClass(
            [_this.element.querySelector("." + className)],
            HIDEWRAPPER
          );
        });
        break;
      case WEEKLY:
        weekClassList.forEach(function (className) {
          return addClass(
            [_this.element.querySelector("." + className)],
            HIDEWRAPPER
          );
        });
        break;
      case MONTHLY:
        monthClassList.forEach(function (className) {
          return addClass(
            [_this.element.querySelector("." + className)],
            HIDEWRAPPER
          );
        });
        break;
      case YEARLY:
        yearClassList.forEach(function (className) {
          return addClass(
            [_this.element.querySelector("." + className)],
            HIDEWRAPPER
          );
        });
        break;
      case DAILY:
        dailyClassList.forEach(function (className) {
          return addClass(
            [_this.element.querySelector("." + className)],
            HIDEWRAPPER
          );
        });
        break;
    }
  };
  RecurrenceEditor.prototype.updateEndOnForm = function (state) {
    var _this = this;
    this.endType.setProperties({ value: state });
    switch (state) {
      case NEVER:
        noEndClassList.forEach(function (className) {
          return addClass(
            [_this.element.querySelector("." + className)],
            HIDEWRAPPER
          );
        });
        break;
      case UNTIL:
        endOnDateClassList.forEach(function (className) {
          return addClass(
            [_this.element.querySelector("." + className)],
            HIDEWRAPPER
          );
        });
        break;
      case COUNT:
        endOnCountClassList.forEach(function (className) {
          return addClass(
            [_this.element.querySelector("." + className)],
            HIDEWRAPPER
          );
        });
        break;
    }
  };
  RecurrenceEditor.prototype.freshOnEndForm = function () {
    var _this = this;
    noEndClassList.forEach(function (className) {
      var element = _this.element.querySelector("." + className);
      if (element) {
        removeClass([element], HIDEWRAPPER);
      }
    });
  };
  RecurrenceEditor.prototype.showFormElement = function () {
    var _this = this;
    neverClassList.forEach(function (className) {
      var hideElement = _this.element.querySelector("." + className);
      if (hideElement) {
        removeClass([hideElement], HIDEWRAPPER);
      }
    });
  };
  RecurrenceEditor.prototype.renderDropdowns = function () {
    var _this = this;
    var self = this;
    this.repeatType = new DropDownList({
      //set the data to dataSource property
      dataSource: this.getRepeatData(),
      floatLabelType: "Always",
      enableRtl: this.enableRtl,
      index: this.selectedType,
      fields: {
        text: TEXTFIELD,
        value: VALUEFIELD,
      },
      placeholder: this.localeObj.getConstant(REPEAT),
      htmlAttributes: { title: this.localeObj.getConstant(REPEAT) },
      change: function (args) {
        self.setProperties(
          { selectedType: _this.frequencies.indexOf(args.value) },
          false
        );
        self.element.querySelector(
          "." + REPEATCONTENT
        ).innerHTML = self.localeObj.getConstant(contentType[args.value]);
        self.showFormElement();
        self.updateForm(args.value);
        self.resetFormValues();
        self.triggerChangeEvent();
      },
    });
    // set placeholder to DropDownList input element
    this.repeatType.appendTo(this.element.querySelector("." + REPEATELEMENT));
    this.endType = new DropDownList({
      dataSource: this.getEndData(),
      popupWidth: this.getPopupWidth(),
      enableRtl: this.enableRtl,
      index: 1,
      fields: {
        text: TEXTFIELD,
        value: VALUEFIELD,
      },
      change: function (args) {
        self.freshOnEndForm();
        self.updateEndOnForm(args.value);
        self.resetFormValues();
        self.triggerChangeEvent();
      },
    });
    this.endType.appendTo(this.element.querySelector("." + ENDONELEMENT));
    var renderDropDownList = function (dropDownData) {
      return new DropDownList({
        dataSource: dropDownData,
        popupWidth: _this.getPopupWidth(),
        enableRtl: _this.enableRtl,
        fields: {
          text: TEXTFIELD,
          value: VALUEFIELD,
        },
        index: 1,
        change: function (args) {
          self.onWeekDay.setProperties({ checked: true });
          self.resetFormValues();
          self.triggerChangeEvent();
        },
      });
    };
    this.monthWeekPos = renderDropDownList(this.getMonthPosData());
    this.monthWeekPos.appendTo(this.element.querySelector("." + MONTHPOS));
    this.monthWeekDays = renderDropDownList(this.getDayData("wide"));
    this.monthWeekDays.appendTo(this.element.querySelector("." + MONTHWEEK));
    this.monthValue = new DropDownList({
      dataSource: this.getMonthData(),
      fields: {
        text: TEXTFIELD,
        value: VALUEFIELD,
      },
      floatLabelType: "Always",
      enableRtl: this.enableRtl,
      index: 7,
      change: function (args) {
        self.resetFormValues();
        self.triggerChangeEvent();
      },
    });
    this.monthValue.appendTo(
      this.element.querySelector("." + YEAREXPANDERELEMENT)
    );
  };
  RecurrenceEditor.prototype.setDefaultValue = function () {
    var formelement = [].slice.call(
      this.element.querySelectorAll(".e-control .e-numerictextbox")
    );
    for (
      var _i = 0, formelement_1 = formelement;
      _i < formelement_1.length;
      _i++
    ) {
      var element = formelement_1[_i];
      var instance = element.cn_instances[0];
      if (instance.element.classList.contains(REPEATINTERVAL)) {
        instance.value = 1;
        instance.dataBind();
      } else if (instance.element.classList.contains(ENDONCOUNT)) {
        instance.value = 10;
        instance.dataBind();
      }
    }
  };
  RecurrenceEditor.prototype.resetFormValues = function () {
    var recurreneElement = [].slice.call(
      this.element.querySelectorAll('.e-control [type="text"]')
    );
    for (
      var _i = 0, recurreneElement_1 = recurreneElement;
      _i < recurreneElement_1.length;
      _i++
    ) {
      var element = recurreneElement_1[_i];
      var instance = void 0;
      if (element.classList.contains("e-datepicker")) {
        instance = element.cn_instances[0];
        if (instance.value) {
          instance.value = instance.value;
          instance.dataBind();
        } else {
          this.updateUntilDate(this.startDate);
        }
      } else if (element.classList.contains("e-dropdownlist")) {
        instance = element.cn_instances[0];
        instance.index = instance.index || 0;
        instance.dataBind();
      } else if (element.classList.contains("e-numerictextbox")) {
        instance = element.cn_instances[0];
        var value = void 0;
        if (instance.element.classList.contains(REPEATINTERVAL)) {
          value = 1;
        } else if (instance.element.classList.contains(ENDONCOUNT)) {
          value = 10;
        } else {
          value = this.startDate.getDate();
        }
        instance.value = instance.value || value;
        instance.dataBind();
      }
    }
  };
  RecurrenceEditor.prototype.getPopupWidth = function () {
    return Browser.isDevice ? "100%" : "auto";
  };
  RecurrenceEditor.prototype.renderDatePickers = function () {
    var self = this;
    this.untilDateObj = new DatePicker({
      firstDayOfWeek: this.firstDayOfWeek,
      enableRtl: this.enableRtl,
      locale: this.locale,
      min: this.minDate,
      max: this.maxDate,
      change: function (args) {
        if (args.value) {
          self.triggerChangeEvent();
        }
      },
    });
    this.untilDateObj.appendTo(this.element.querySelector("." + UNTILDATE));
  };
  RecurrenceEditor.prototype.dayButtonRender = function () {
    var _this = this;
    var btns = [].slice.call(
      this.element.querySelectorAll("." + DAYWRAPPER + " button")
    );
    var self = this;
    for (var _i = 0, btns_1 = btns; _i < btns_1.length; _i++) {
      var btn = btns_1[_i];
      var button = new Button(
        { isToggle: true, enableRtl: this.enableRtl },
        btn
      );
      this.dayButtons.push(button);
      EventHandler.add(btn, "click", function (args) {
        var btns = [].slice.call(
          _this.element.querySelectorAll(
            "." + DAYWRAPPER + " button." + PRIMARY
          )
        );
        var element = args.target;
        if (!element.classList.contains(PRIMARY)) {
          addClass([element], PRIMARY);
          self.triggerChangeEvent();
        } else if (btns.length > 1) {
          removeClass([element], PRIMARY);
          self.triggerChangeEvent();
        }
      });
    }
  };
  RecurrenceEditor.prototype.radioButtonRender = function () {
    var self = this;
    this.onMonthDay = new RadioButton({
      label: this.localeObj.getConstant(RADIOLABEL),
      enableRtl: this.enableRtl,
      name: "monthType",
      value: "day",
      change: function (args) {
        self.resetFormValues();
        self.triggerChangeEvent();
      },
    });
    this.onMonthDay.appendTo(
      this.element.querySelector("." + MONTHEXPANDERELEMENT)
    );
    this.monthButtons.push(this.onMonthDay);
    this.onWeekDay = new RadioButton({
      label: "",
      name: "monthType",
      enableRtl: this.enableRtl,
      value: "daypos",
      change: function (args) {
        self.resetFormValues();
        self.triggerChangeEvent();
      },
    });
    this.onWeekDay.appendTo(
      this.element.querySelector("." + MONTHEXPANNDERELEM)
    );
    this.monthButtons.push(this.onWeekDay);
  };
  RecurrenceEditor.prototype.numericTextboxRender = function () {
    var self = this;
    this.recurrenceCount = new NumericTextBox({
      value: 10,
      format: "#",
      enableRtl: this.enableRtl,
      floatLabelType: "Always",
      min: 1,
      max: 999,
      change: function (args) {
        self.triggerChangeEvent();
      },
    });
    this.recurrenceCount.appendTo(this.element.querySelector("." + ENDONCOUNT));
    this.monthDate = new NumericTextBox({
      value: 1,
      format: "#",
      enableRtl: this.enableRtl,
      min: 1,
      max: 31,
      change: function (args) {
        self.onMonthDay.setProperties({ checked: true });
        self.triggerChangeEvent();
      },
    });
    this.monthDate.appendTo(this.element.querySelector("." + MONTHDAYWRAPPER));
    this.repeatInterval = new NumericTextBox({
      value: 1,
      format: "#",
      min: 1,
      max: 999,
      enableRtl: this.enableRtl,
      floatLabelType: "Always",
      placeholder: this.localeObj.getConstant(REPEATEVERY),
      change: function (args) {
        self.triggerChangeEvent();
      },
    });
    this.repeatInterval.appendTo(
      this.element.querySelector("." + REPEATINTERVAL)
    );
  };
  RecurrenceEditor.prototype.renderComponent = function () {
    this.setTemplate();
    this.renderDropdowns();
    this.renderDatePickers();
    this.dayButtonRender();
    this.radioButtonRender();
    this.numericTextboxRender();
  };
  RecurrenceEditor.prototype.rotateArray = function (data, count) {
    var temp;
    for (var index = 0; index < count; index++) {
      temp = data.shift();
      data.push(temp);
    }
  };
  RecurrenceEditor.prototype.getEndData = function () {
    var endData = [NEVER, UNTIL, COUNT];
    var self = this;
    var dataSource = [];
    endData.forEach(function (data) {
      dataSource.push({ text: self.localeObj.getConstant(data), value: data });
    });
    return dataSource;
  };
  RecurrenceEditor.prototype.getDayPosition = function (date) {
    var temp = new Date(date.getTime());
    var endDate = new Date(date.getTime());
    var day = date.getDay();
    var positionCollection = [];
    temp = this.calendarUtil.getMonthStartDate(temp);
    endDate = this.calendarUtil.getMonthEndDate(endDate);
    while (temp < endDate) {
      if (temp.getDay() === day) {
        positionCollection.push(temp.getTime());
      }
      temp.setDate(temp.getDate() + 1);
    }
    if (
      positionCollection.indexOf(date.getTime()) ===
      positionCollection.length - 1
    ) {
      return -1;
    }
    return positionCollection.indexOf(date.getTime()) + 1;
  };
  RecurrenceEditor.prototype.getRepeatData = function () {
    var data = [];
    var self = this;
    this.frequencies.forEach(function (element) {
      var textValue = element === NONE ? NEVER : element;
      data.push({
        text: self.localeObj.getConstant(textValue),
        value: element,
      });
    });
    return data;
  };
  RecurrenceEditor.prototype.getMonthPosData = function () {
    var monthpos = [FIRST, SECOND, THIRD, FOURTH, LAST];
    var monthposValue = {
      first: 1,
      second: 2,
      third: 3,
      fourth: 4,
      last: -1,
    };
    var self = this;
    var dataSource = [];
    monthpos.forEach(function (data) {
      dataSource.push({
        text: self.localeObj.getConstant(data),
        value: monthposValue[data],
      });
    });
    return dataSource;
  };
  RecurrenceEditor.prototype.getDayData = function (format) {
    var weekday = [
      KEYSUNDAY,
      KEYMONDAY,
      KEYTUESDAY,
      KEYWEDNESDAY,
      KEYTHURSDAY,
      KEYFRIDAY,
      KEYSATURDAY,
    ];
    var dayData = [];
    var cldrObj;
    this.rotateArray(weekday, this.firstDayOfWeek);
    if (this.locale === "en" || this.locale === "en-US") {
      cldrObj = getValue(
        "days.stand-alone." + format,
        getDefaultDateObject(this.getCalendarMode())
      );
    } else {
      cldrObj = getValue(
        "main." +
          "" +
          this.locale +
          ".dates.calendars." +
          this.getCalendarMode() +
          ".days.stand-alone." +
          format,
        cldrData
      );
    }
    for (var _i = 0, weekday_1 = weekday; _i < weekday_1.length; _i++) {
      var obj = weekday_1[_i];
      var day = getValue(obj, cldrObj);
      dayData.push({
        text: format === "narrow" ? day : capitalizeFirstWord(day, "single"),
        value: valueData[obj],
      });
    }
    return dayData;
  };
  RecurrenceEditor.prototype.getMonthData = function () {
    var monthData = [];
    var cldrObj;
    if (this.locale === "en" || this.locale === "en-US") {
      cldrObj = getValue(
        "months.stand-alone.wide",
        getDefaultDateObject(this.getCalendarMode())
      );
    } else {
      cldrObj = getValue(
        "main." +
          "" +
          this.locale +
          ".dates.calendars." +
          this.getCalendarMode() +
          ".months.stand-alone.wide",
        cldrData
      );
    }
    for (var _i = 0, _a = Object.keys(cldrObj); _i < _a.length; _i++) {
      var obj = _a[_i];
      monthData.push({
        text: capitalizeFirstWord(getValue(obj, cldrObj), "single"),
        value: obj,
      });
    }
    return monthData;
  };
  RecurrenceEditor.prototype.setTemplate = function () {
    var dayData = this.getDayData("narrow");
    var fullDay = this.getDayData("wide");
    this.element.innerHTML =
      '<div class="' +
      HEADER +
      '">' +
      '<div class="' +
      INPUTWARAPPER +
      " " +
      FORMLEFT +
      '">' +
      '<input type="text" tabindex="0" class="' +
      REPEATELEMENT +
      '"label="' +
      REPEATELEMENT.substr(2) +
      '" />' +
      '</div><div class="' +
      INPUTWARAPPER +
      " " +
      INTERVALCLASS +
      " " +
      FORMRIGHT +
      '"><table  class="' +
      RECURRENCETABLE +
      " " +
      REPEATCONTENTWRAPPER +
      '"><tr>' +
      '<td><input type="text" tabindex="0" class="' +
      REPEATINTERVAL +
      '"title="' +
      this.localeObj.getConstant("repeatInterval") +
      '" /></td>' +
      '<td><span class="' +
      REPEATCONTENT +
      '"></span></td>' +
      '</tr></table></div><div class="' +
      INPUTWARAPPERSIDE +
      " " +
      DAYWRAPPER +
      " " +
      FORMLEFT +
      '">' +
      "<div class=" +
      WEEKEXPANDERLABEL +
      ">" +
      this.localeObj.getConstant(ON) +
      "</div>" +
      '<button type="button" class="e-round" data-index="0" title="' +
      fullDay[0].text +
      '">' +
      dayData[0].text +
      "</button>" +
      '<button type="button" class="e-round" data-index="1" title="' +
      fullDay[1].text +
      '">' +
      dayData[1].text +
      "</button>" +
      '<button type="button" class="e-round" data-index="2" title="' +
      fullDay[2].text +
      '">' +
      dayData[2].text +
      "</button>" +
      '<button type="button" class="e-round" data-index="3" title="' +
      fullDay[3].text +
      '">' +
      dayData[3].text +
      "</button>" +
      '<button type="button" class="e-round" data-index="4" title="' +
      fullDay[4].text +
      '">' +
      dayData[4].text +
      "</button>" +
      '<button type="button" class="e-round" data-index="5" title="' +
      fullDay[5].text +
      '">' +
      dayData[5].text +
      "</button>" +
      '<button type="button" class="e-round" data-index="6" title="' +
      fullDay[6].text +
      '">' +
      dayData[6].text +
      "</button></div>" +
      '<div class="' +
      INPUTWARAPPERSIDE +
      " " +
      WEEKWRAPPER +
      " " +
      FORMLEFT +
      '">' +
      "<div class=" +
      MONTHEXPANDERLABEL +
      ">" +
      this.localeObj.getConstant(ON) +
      "</div>" +
      '<div class="' +
      YEAREXPANDERWRAPPER +
      '">' +
      '<input class="' +
      YEAREXPANDERELEMENT +
      '" type="text" tabindex="0" title="' +
      this.localeObj.getConstant("yearExpander") +
      '"/>' +
      "</div>" +
      '<div class="' +
      MONETHEXPANDERWRAPPER +
      '">' +
      '<table class="' +
      RECURRENCETABLE +
      " " +
      MONETHEXPANDWRAPPER +
      '"><tr><td>' +
      '<div class="' +
      INPUTWARAPPER +
      " " +
      MONTHEXPANDERCHECKBOXWRAPPER +
      '">' +
      '<input class="' +
      MONTHEXPANDERELEMENT +
      '"title="' +
      this.localeObj.getConstant("monthExpander") +
      '" type="radio">' +
      "</div></td>" +
      '<td colspan="2"><div class="' +
      INPUTWARAPPER +
      " " +
      MONTHDAYELEMENT +
      '">' +
      '<input type="text" tabindex="0" class="' +
      MONTHDAYWRAPPER +
      '"title="' +
      this.localeObj.getConstant("monthExpander") +
      '" />' +
      "</div></td></tr>" +
      "<tr><td>" +
      '<div class="' +
      INPUTWARAPPER +
      " " +
      MONTHEXPANDERCHECKBOXWRAPPER +
      '" style="min-width: 30px;margin-bottom:18px;">' +
      '<input class="' +
      MONTHEXPANNDERELEM +
      '"title="' +
      this.localeObj.getConstant("monthExpander") +
      '" type="radio">' +
      "</div></td>" +
      '<td><div class="' +
      INPUTWARAPPER +
      " " +
      WEEKPOSITION +
      '" >' +
      '<input type="text" tabindex="0" class="' +
      MONTHPOS +
      '"title="' +
      this.localeObj.getConstant("monthPosition") +
      '" />' +
      "</div></td>" +
      '<td><div class="' +
      INPUTWARAPPER +
      '" >' +
      '<input type="text" tabindex="0" class="' +
      MONTHWEEK +
      '"title="' +
      this.localeObj.getConstant("monthWeek") +
      '" />' +
      "</div></td></tr></table>" +
      "</div></div>" +
      '<div class="' +
      INPUTWARAPPERSIDE +
      " " +
      ENDON +
      " " +
      FORMRIGHT +
      '">' +
      "<div class=" +
      ENDONLABEL +
      ">" +
      this.localeObj.getConstant(END) +
      "</div>" +
      '<div class="' +
      INPUTWARAPPER +
      " " +
      ENDONLEFT +
      '">' +
      '<input type="text" tabindex="0" class="' +
      ENDONELEMENT +
      '"title="' +
      this.localeObj.getConstant(END) +
      '" />' +
      "</div>" +
      '<div class="' +
      INPUTWARAPPER +
      " " +
      ENDONDATE +
      '" >' +
      '<input type="text" tabindex="0" class="' +
      UNTILDATE +
      '"title="' +
      this.localeObj.getConstant(UNTIL) +
      '" />' +
      "</div>" +
      '<div class="' +
      INPUTWARAPPER +
      " " +
      ENDONCOUNTWRAPPER +
      '">' +
      '<input type="text" tabindex="0" class="' +
      ENDONCOUNT +
      '"title="' +
      this.localeObj.getConstant(COUNT) +
      '" />' +
      "</div></div>" +
      "</div></div>";
  };
  RecurrenceEditor.prototype.getSelectedDaysData = function () {
    var ruleData = RULEBYDAY + EQUAL;
    var elements = [].slice.call(
      this.element.querySelectorAll("." + DAYWRAPPER + " button." + PRIMARY)
    );
    var weekday = [
      RULESUNDAY,
      RULEMONDAY,
      RULETUESDAY,
      RULEWEDNESDAY,
      RULETHURSDAY,
      RULEFRIDAY,
      RULESATURDAY,
    ];
    this.rotateArray(weekday, this.firstDayOfWeek);
    for (var index = 0; index < elements.length; index++) {
      ruleData +=
        weekday[parseInt(elements[index].getAttribute("data-index"), 10)] +
        (index === elements.length - 1 ? "" : COMMA);
    }
    return ruleData + SEMICOLON;
  };
  RecurrenceEditor.prototype.getSelectedMonthData = function () {
    var ruleData;
    if (this.onWeekDay.checked) {
      ruleData =
        RULEBYDAY +
        EQUAL +
        this.monthWeekDays.value +
        SEMICOLON +
        RULESETPOS +
        EQUAL +
        this.monthWeekPos.value +
        SEMICOLON;
    } else {
      ruleData = RULEBYMONTHDAY + EQUAL + this.monthDate.value + SEMICOLON;
    }
    return ruleData;
  };
  RecurrenceEditor.prototype.getIntervalData = function () {
    return RULEINTERVAL + EQUAL + this.repeatInterval.value + SEMICOLON;
  };
  RecurrenceEditor.prototype.getEndOnCount = function () {
    return RULECOUNT + EQUAL + this.recurrenceCount.value + SEMICOLON;
  };
  RecurrenceEditor.prototype.getYearMonthRuleData = function () {
    return RULEBYMONTH + EQUAL + this.monthValue.value + SEMICOLON;
  };
  RecurrenceEditor.prototype.updateWeekButton = function (keys) {
    var weekday = [
      RULESUNDAY,
      RULEMONDAY,
      RULETUESDAY,
      RULEWEDNESDAY,
      RULETHURSDAY,
      RULEFRIDAY,
      RULESATURDAY,
    ];
    this.rotateArray(weekday, this.firstDayOfWeek);
    for (var _i = 0, _a = this.dayButtons; _i < _a.length; _i++) {
      var obj = _a[_i];
      var index = parseInt(obj.element.getAttribute("data-index"), 10);
      if (keys.indexOf(weekday[index]) !== -1) {
        obj.setProperties({ isPrimary: true });
      } else {
        obj.setProperties({ isPrimary: false });
      }
    }
  };
  RecurrenceEditor.prototype.updateMonthUI = function () {
    if (this.ruleObject.monthDay.length) {
      this.monthDate.setProperties({ value: this.ruleObject.monthDay[0] });
      this.onMonthDay.setProperties({ checked: true });
    } else {
      this.onWeekDay.setProperties({ checked: true });
      this.monthWeekPos.setProperties({ value: this.ruleObject.setPosition });
      for (var _i = 0, _a = Object.keys(valueData); _i < _a.length; _i++) {
        var key = _a[_i];
        if (valueData[key] === this.ruleObject.day[0]) {
          this.monthWeekDays.setProperties({ value: this.ruleObject.day[0] });
          break;
        }
      }
    }
  };
  RecurrenceEditor.prototype.updateUI = function (repeat, state) {
    this.repeatInterval.setProperties({ value: this.ruleObject.interval });
    switch (state) {
      case UNTIL:
        this.untilDateObj.setProperties({ value: this.ruleObject.until });
        break;
      case COUNT:
        this.recurrenceCount.setProperties({ value: this.ruleObject.count });
        break;
    }
    switch (repeat) {
      case WEEKLY:
        this.updateWeekButton(this.ruleObject.day);
        break;
      case YEARLY:
        this.monthValue.setProperties({ index: this.ruleObject.month[0] - 1 });
        this.updateMonthUI();
        break;
      case MONTHLY:
        this.updateMonthUI();
        break;
    }
  };
  RecurrenceEditor.prototype.getUntilData = function () {
    if (!this.untilDateObj.value) {
      return "";
    }
    var tempStr = getRecurrenceStringFromDate(this.untilDateObj.value);
    return RULEUNTIL + EQUAL + tempStr + SEMICOLON;
  };
  RecurrenceEditor.prototype.destroyComponents = function () {
    if (!this.recurrenceCount.isDestroyed) {
      this.recurrenceCount.destroy();
    }
    if (!this.monthDate.isDestroyed) {
      this.monthDate.destroy();
    }
    if (!this.repeatInterval.isDestroyed) {
      this.repeatInterval.destroy();
    }
    if (!this.untilDateObj.isDestroyed) {
      this.untilDateObj.destroy();
    }
    if (!this.repeatType.isDestroyed) {
      this.repeatType.destroy();
    }
    if (!this.endType.isDestroyed) {
      this.endType.destroy();
    }
    if (!this.monthWeekPos.isDestroyed) {
      this.monthWeekPos.destroy();
    }
    if (!this.monthWeekDays.isDestroyed) {
      this.monthWeekDays.destroy();
    }
    if (!this.monthValue.isDestroyed) {
      this.monthValue.destroy();
    }
    this.dayButtons.forEach(function (element) {
      if (!element.isDestroyed) {
        element.destroy();
      }
    });
    this.dayButtons = [];
    this.monthButtons.forEach(function (element) {
      if (!element.isDestroyed) {
        element.destroy();
      }
    });
    this.monthButtons = [];
  };
  /** @hidden */
  RecurrenceEditor.prototype.resetFields = function () {
    this.startState(NONE, NEVER, this.startDate);
    this.setDefaultValue();
  };
  RecurrenceEditor.prototype.getCalendarMode = function () {
    return this.calendarMode.toLowerCase();
  };
  RecurrenceEditor.prototype.getRuleSummary = function (rule) {
    if (rule === void 0) {
      rule = this.getRecurrenceRule();
    }
    return generateSummary(
      rule,
      this.localeObj,
      this.locale,
      this.calendarMode
    );
  };
  RecurrenceEditor.prototype.getRecurrenceDates = function (
    startDate,
    rule,
    excludeDate,
    maximumCount,
    viewDate
  ) {
    if (isBlazor()) {
      startDate = new Date("" + startDate);
      if (viewDate) {
        viewDate = new Date("" + viewDate);
      }
    }
    viewDate = isNullOrUndefined(viewDate) ? this.startDate : viewDate;
    return generate(
      startDate,
      rule,
      excludeDate,
      this.firstDayOfWeek,
      maximumCount,
      viewDate,
      this.calendarMode
    );
  };
  RecurrenceEditor.prototype.getRecurrenceRule = function () {
    var ruleData = RULEFREQ + EQUAL;
    switch (this.repeatType.value) {
      case DAILY:
        ruleData += RULEDAILY + SEMICOLON;
        break;
      case WEEKLY:
        ruleData += RULEWEEKLY + SEMICOLON + this.getSelectedDaysData();
        break;
      case MONTHLY:
        ruleData += RULEMONTHLY + SEMICOLON + this.getSelectedMonthData();
        break;
      case YEARLY:
        ruleData +=
          RULEYEARLY +
          SEMICOLON +
          this.getSelectedMonthData() +
          this.getYearMonthRuleData();
        break;
      case NONE:
        return "";
    }
    ruleData += this.getIntervalData();
    switch (this.endType.value) {
      case UNTIL:
        ruleData += this.getUntilData();
        break;
      case COUNT:
        ruleData += this.getEndOnCount();
        break;
    }
    return ruleData;
  };
  RecurrenceEditor.prototype.setRecurrenceRule = function (rule, startDate) {
    if (startDate === void 0) {
      startDate = this.startDate;
    }
    if (isBlazor()) {
      startDate = new Date("" + startDate);
    }
    if (!rule) {
      this.repeatType.setProperties({ value: NONE });
      return;
    }
    this.renderStatus = false;
    this.ruleObject = extractObjectFromRule(rule);
    var endon = this.ruleObject.count
      ? COUNT
      : this.ruleObject.until
      ? UNTIL
      : NEVER;
    switch (this.ruleObject.freq) {
      case RULEDAILY:
        this.startState(DAILY, endon, startDate);
        this.updateUI(DAILY, endon);
        break;
      case RULEWEEKLY:
        this.startState(WEEKLY, endon, startDate);
        this.updateUI(WEEKLY, endon);
        break;
      case RULEMONTHLY:
        this.startState(MONTHLY, endon, startDate);
        this.updateUI(MONTHLY, endon);
        break;
      case RULEYEARLY:
        this.startState(YEARLY, endon, startDate);
        this.updateUI(YEARLY, endon);
        break;
    }
    this.renderStatus = true;
    this.triggerChangeEvent();
  };
  /**
   * Destroys the widget.
   * @returns void
   */
  RecurrenceEditor.prototype.destroy = function () {
    this.destroyComponents();
    _super.prototype.destroy.call(this);
    var removeClasses = ["e-" + this.getModuleName()];
    if (this.cssClass) {
      removeClasses = removeClasses.concat(this.cssClass.split(" "));
    }
    removeClass([this.element], removeClasses);
    while (this.element.firstElementChild) {
      this.element.removeChild(this.element.firstElementChild);
    }
  };
  /**
   * Get component name.
   * @returns string
   * @private
   */
  RecurrenceEditor.prototype.getModuleName = function () {
    return "recurrenceeditor";
  };
  /**
   * Get the properties to be maintained in the persisted state.
   * @returns string
   */
  RecurrenceEditor.prototype.getPersistData = function () {
    return this.addOnPersist([]);
  };
  /**
   * Initialize the control rendering
   * @returns void
   * @private
   */
  RecurrenceEditor.prototype.render = function () {
    this.initialize();
    this.rtlClass(this.enableRtl);
    this.renderStatus = true;
    this.renderComplete();
  };
  /**
   * Called internally, if any of the property value changed.
   * @private
   */
  RecurrenceEditor.prototype.onPropertyChanged = function (newProp, oldProp) {
    for (var _i = 0, _a = Object.keys(newProp); _i < _a.length; _i++) {
      var prop = _a[_i];
      switch (prop) {
        case "startDate":
          this.selectMonthDay(newProp.startDate);
          this.updateUntilDate(newProp.startDate);
          this.endType.setProperties({ index: 0 });
          break;
        case "enableRtl":
          this.rtlClass(newProp.enableRtl);
          break;
        case "cssClass":
          this.applyCustomClass(newProp.cssClass);
          break;
        case "selectedType":
          this.repeatType.setProperties({ index: this.selectedType });
          break;
        case "minDate":
          this.untilDateObj.setProperties({ minDate: this.minDate });
          break;
        case "maxDate":
          this.untilDateObj.setProperties({ maxDate: this.maxDate });
          break;
        case "value":
          if (this.getRecurrenceRule() !== this.value) {
            this.setRecurrenceRule(this.value);
          }
          break;
        case "calendarMode":
          this.calendarMode = newProp.calendarMode;
          this.calendarUtil = getCalendarUtil(newProp.calendarMode);
          break;
        case "locale":
        case "frequencies":
        case "firstDayOfWeek":
          this.refresh();
          break;
      }
    }
  };
  __decorate(
    [Property(["none", "daily", "weekly", "monthly", "yearly"])],
    RecurrenceEditor.prototype,
    "frequencies",
    void 0
  );
  __decorate(
    [Property(0)],
    RecurrenceEditor.prototype,
    "firstDayOfWeek",
    void 0
  );
  __decorate(
    [Property(new Date())],
    RecurrenceEditor.prototype,
    "startDate",
    void 0
  );
  __decorate([Property()], RecurrenceEditor.prototype, "dateFormat", void 0);
  __decorate(
    [Property("Gregorian")],
    RecurrenceEditor.prototype,
    "calendarMode",
    void 0
  );
  __decorate([Property()], RecurrenceEditor.prototype, "cssClass", void 0);
  __decorate([Property()], RecurrenceEditor.prototype, "value", void 0);
  __decorate(
    [Property(new Date(1900, 0, 1))],
    RecurrenceEditor.prototype,
    "minDate",
    void 0
  );
  __decorate(
    [Property(new Date(2099, 11, 31))],
    RecurrenceEditor.prototype,
    "maxDate",
    void 0
  );
  __decorate([Property(0)], RecurrenceEditor.prototype, "selectedType", void 0);
  __decorate([Event()], RecurrenceEditor.prototype, "change", void 0);
  RecurrenceEditor = __decorate([NotifyPropertyChanges], RecurrenceEditor);
  return RecurrenceEditor;
})(Component);
export { RecurrenceEditor };
