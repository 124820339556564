import React, { useEffect, useState } from 'react'
import {
  TextField,
  Button,
  makeStyles,
  Box,
  Modal,
  Backdrop,
  Select,
  Fade,
  MenuItem,
  Typography,
  InputLabel,
  InputAdornment
} from '@material-ui/core'
import { DoctorStore } from '../../stores'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  VALIDATE_PHONE,
  VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE
} from '../../Constants'
import { useFormik } from 'formik'
import * as yup from 'yup'

import indianFlag from '../../images/flag.png'

const AddEditDemographicModal = ({
  showHideModal,
  isShow,
  pateintId,
  editData,
  isAdd
}) => {
  const classes = useStyles()
  const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  // const VALIDATE_PHONE = /^((\+){1}91){1}[1-9]{1}[0-9]{9}$/

  const {
    addDemoGraphicData,
    editDemoGraphicData
  } = DoctorStore.useStoreActions(actions => ({
    ...actions.patients
  }))

  const submit = async values => {
    if (isAdd) {
      addDemoGraphicData({
        ...values,
        pateintId,
        contact_no: `+91${values.contact_no}`
      })
      showHideModal()
    } else {
      editDemoGraphicData({
        ...values,
        demographic_id: editData.id,
        patient_id: pateintId,
        contact_no: `+91${values.contact_no}`
      })
      showHideModal()
    }
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required('Name is required')
      .matches(/^([^0-9]*)$/, 'Digits are not allowed')
      .max(30, 'Max 30 characters allowed'),
    email: yup
      .string()
      .trim()
      .max(50, 'Max 50 characters allowed')
      .email('Invalid email address')
      .required('Email is required'),
    contact_no: yup
      .string()
      .trim()
      .max(14, 'You may enter up to 14 digits')
      .matches(
        VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
        'Please enter a valid mobile number'
      )
      // .matches(VALIDATE_PHONE, 'Please enter a valid mobile number with +91')
      .required('Mobile number is required'),
    // type: isAdd ? 1 : editData.type ? editData.type : 1,
    relationship: yup
      .string()
      .trim()
      .required('Relationship is required')
      .max(50, 'Max 50 characters allowed'),
    type: yup.string().required('Care Type is required')
  })

  const formik = useFormik({
    initialValues: {
      name: isAdd ? '' : editData.name ? editData.name : '',
      contact_no: isAdd
        ? ''
        : editData.contact_no
        ? editData.contact_no.replace('+91', '')
        : '',
      email: isAdd ? '' : editData.email ? editData.email : '',
      type: isAdd ? '' : editData.type ? editData.type : '',
      relationship: isAdd
        ? ''
        : editData.relationship
        ? editData.relationship
        : ''
    },

    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  return (
    <Dialog
      open={isShow}
      onClose={showHideModal}
      aria-labelledby="form-dialog-title"
      className={classes.ModalHead}>
      <h3> Contact Details</h3>
      {/* <DialogTitle id="form-dialog-title" >
        Contact Details
      </DialogTitle> */}
      <DialogContent>
        <Box className={classes.ModalForm}>
          <Box className={classes.InputCol}>
            <p style={{ marginTop: '25px' }}>Name: *</p>
            <TextField
              id="name"
              type="text"
              className={classes.Input}
              placeholder="Name *"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              name="name"
              helperText={
                <div className={classes.errMsg}>
                  {formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : null}
                </div>
              }
            />
          </Box>
          <Box className={classes.InputCol}>
            <p style={{ marginTop: '25px' }}>Mobile: *</p>
            <TextField
              id="contact"
              name="contact_no"
              placeholder="9870568432"
              className={classes.Input}
              value={formik.values.contact_no}
              // inputProps={{ maxlength: 10 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={indianFlag}
                      alt=""
                      width="25"
                      height="25"
                      style={{ marginRight: '0.4rem' }}
                    />
                    +91
                  </InputAdornment>
                )
              }}
              helperText={
                <div className={classes.errMsg}>
                  {formik.touched.contact_no && formik.errors.contact_no
                    ? formik.errors.contact_no
                    : null}
                </div>
              }
            />
          </Box>
          <Box className={classes.InputCol}>
            <p style={{ marginTop: '25px' }}>Email: *</p>
            <TextField
              id="email"
              name="email"
              placeholder="Email *"
              inputProps={{ maxlength: 255 }}
              className={classes.Input}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                <div className={classes.errMsg}>
                  {formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null}
                </div>
              }
            />
          </Box>
          <Box className={classes.InputCol}>
            <p style={{ marginTop: '25px' }}>Relation: *</p>
            <TextField
              id="relation"
              name="relationship"
              placeholder="Relationship *"
              className={classes.Input}
              inputProps={{ maxlength: 30 }}
              value={formik.values.relationship}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                <div className={classes.errMsg}>
                  {formik.touched.relationship && formik.errors.relationship
                    ? formik.errors.relationship
                    : null}
                </div>
              }
            />
          </Box>
          {/* <Box className={classes.InputCol}>
            <p style={{marginTop:'30px', marginRight:'28px'}}>Type:</p>
          <Box className={classes.InputCol}>
            <p style={{ marginTop: '30px', marginRight: '28px' }}>Type: *</p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom'
                }
              }}
              id="type"
              className={classes.Input1}
              value={formik.values.type}
              name="type"
              placeholder="Type *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                <div className={classes.errMsg}>
                  {formik.touched.type && formik.errors.type
                    ? formik.errors.type
                    : null}
                </div>
                
              }>
                
              <MenuItem value={1}>caregiver</MenuItem>
              <MenuItem value={2}>emergency</MenuItem>
            </Select>
          </Box> */}
          <Box className={classes.InputCol}>
            <p style={{ width: '125px' }}>Type *</p>
            <Select
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom'
                }
              }}
              id="type"
              className={classes.Input1}
              value={formik.values.type}
              name="type"
              placeholder="Type *"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                <div className={classes.errMsg}>
                  {formik.touched.type && formik.errors.type
                    ? formik.errors.type
                    : null}
                </div>
              }>
              <MenuItem value={1}>Caregiver</MenuItem>
              <MenuItem value={2}>Emergency</MenuItem>
            </Select>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: '20px 20px' }}>
        <Button
          onClick={showHideModal}
          className={classes.modalSecondaryButton}>
          Cancel
        </Button>
        <Button
          onClick={formik.handleSubmit}
          className={classes.modalPrimaryButton}>
          {isAdd ? 'Add Details' : 'Update Details'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  InputCol: {
    width: '100%',
    display: 'flex',
    marginBottom: '0',
    '@media (max-width:767px)': {
      flexWrap: 'wrap',
      marginBottom: '10px',
      '& .MuiFormControl-root': {
        width: '100%'
      }
    },
    '& button': {
      margin: '0px auto 20px'
    },
    // justifyContent: 'center',
    '& p': {
      fontSize: '14px',
      fontWeight: '400',
      // lineHeight: '40px',
      marginRight: '10px',
      width: '100px',
      '@media (max-width:767px)': {
        marginBottom: '0px !important',
        display: 'none'
      }
    }
  },
  Input: {
    width: '100%',
    paddingTop: '20px',
    paddingLeft: '15px',
    '@media (max-width:767px)': {
      paddingLeft: '0px'
    }
  },
  Input1: {
    margin: theme.spacing(0),
    width: '100%',
    paddingTop: '10px',
    '@media (max-width:767px)': {
      paddingLeft: '0px',
      margin: theme.spacing(0),
      paddingTop: '20px'
    }
  },
  ModalBox: {
    width: '450px',
    background: '#fff',
    padding: '0px',
    marginTop: 0,
    '@media (max-width:767px)': {
      width: '100%'
    },
    // height:'400px',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      color: '#fff',
      background: '#111d4e',
      padding: '10px 20px',
      marginTop: 0,
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  ModalForm: {
    width: '100%',
    padding: '20px',
    '@media (max-width:991px)': {
      padding: '20px 0',
      height: '330px',
      overflowY: 'auto'
    }
  },
  DownloadBtn: {
    width: '120px',
    background: '#111d4e',
    height: '40px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '40px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '50px',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#fff',
    cursor: 'pointer',
    height: '40px',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: '#111d4e'
    }
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    cursor: 'pointer',
    height: '40px',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: '#e4e4e4'
    }
  },
  ModalHead: {
    width:'500px',
    margin:'0px auto',
    fontFamily:'Poppins',
    '@media (max-width:991px)': {
      width:'96%',
    },
    '& h3':{
      padding:'15px 24px 10px',
      fontSize:'18px',
      marginBottom:'0px',
      borderBottom:'1px #ccc solid',
      fontWeight:'600'
    }
  },
  errMsg: {
    color: 'red',
    fontSize: '12px',
    fontFamily: 'san-serif',
    fontWeight: 'bold',
    width: 'max-content',
    position: 'absolute'
  }
}))

export default AddEditDemographicModal
