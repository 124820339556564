import React from 'react'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  contained: {
    background: 'rgb(17, 29, 78)',
    borderRadius: '40px',
    color: '#fff',
    transition: 'background 0.5s',
    letterSpacing: '0.1rem',
    textTransform: 'none',
    '&:hover, &:focus': {
      background: '#1b2d75'
    }
  },
  text: {
    transition: 'color 0.5s',
    '&:hover, &:focus': {
      color: '#98348e'
    }
  }
})

const ContainedButton = props => {
  const classes = useStyles()

  let style = props.style

  if (props.backgroundTransparent) {
    style = {
      ...style,
      backgroundColor: 'transparent'
    }
  }

  return (
    <Button
      {...props}
      classes={{
        contained: classes.contained,
        text: classes.text
      }}
      style={style}
    />
  )
}

export default ContainedButton
