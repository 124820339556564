import React, { useRef } from 'react'
import {
  makeStyles,
  TextField,
  Radio,
  Button,
  Avatar,
  Grid,
  FormLabel,
  RadioGroup,
  FormControl,
  MenuItem,
  FormControlLabel,
  Select,
  Box,
  InputLabel
} from '@material-ui/core'
import { AiFillCamera } from 'react-icons/ai'
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useDropzone } from "react-dropzone";
import NoRecordImg from '../../images/norecord.png'
import { useFormik } from "formik";
import * as yup from "yup"
import MediaBgImg from '../../images/right-top-img.jpg'
import StaffListItem from './StaffListItem'
import {
  VALIDATE_PHONE,
  NETWORK_STATUS,
  VALIDATE_WHITESPACES,
} from "../../Constants";
import { DoctorStore } from '../../stores'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'



export default function StaffListDoctors() {
  const classes = useStyles()
  const [status2, setAge] = React.useState('')
  const [Image, previewImage] = React.useState(null)
  const [file, setFile] = React.useState('')
  const [name, setName] = React.useState(undefined);
  const [docfile, setDocFile] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  const [addNewActive, setAddNewState] = React.useState(false)
  const Addnew = () => setAddNewState(o => !o)

  const data = [
    {
      id: 1,
      name: 'Bruce Wayne',
      degree: 'MBBS',
      designation: 'General Physician',
      mobile: '9239510870',
      email: 'wayne@mail.com',
      doctorId: 'DC1',
      image:
        'https://images.unsplash.com/photo-1542410613-d073472c3135?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
      status: 'Active'
    },
    {
      id: 2,
      name: 'John Wick',
      degree: 'MBBS',
      designation: 'Neuro Surgeon',
      mobile: '9999999990',
      email: 'wick@mail.com',
      doctorId: 'D99900',
      image:
        'https://images.unsplash.com/photo-1560354508-468e7201bbc2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1494&q=80',
      status: 'Inactive'
    }
  ]

  const {
    medicalAsstDetails
  } = DoctorStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const hiddenFileInput = useRef()

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const genderChange = (e) => {
    formik.setFieldValue("gender", e.target.value);
  };

  const statusChange = (e) => {
    formik.setFieldValue("status", e.target.value)
  }

  const handleChange = (event) => {
    event.preventDefault();
    formik.setFieldValue("file", event.currentTarget.files[0]);

    let reader = new FileReader();
    let fileimage = event.target.files[0];

    reader.onloadend = () => {
      previewImage(reader.result);
    };
    reader.readAsDataURL(fileimage);
  };

  const submit = async (formData) => {
    const file = formData.file;
    if (file) {
      let base64 = await convertBase64(file);
      let format = base64.split(",");
      medicalAsstDetails({
        ...formData,
        format
      })
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage("Wrong!", "Insert a Profile Pic!")
      setmultiButton(false)
      setAlertType('error')
    }
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // const maxSize = 1048576;

  // const {
  //   acceptedFiles,
  //   fileRejections,
  //   isDragActive,
  //   isDragReject,
  //   getRootProps,
  //   getInputProps,
  // } = useDropzone({
  //   accept: "image/jpeg, image/png , .pdf",
  //   minSize: 0,
  //   maxSize,
  // });

  // React.useEffect(() => {
  //   if (acceptedFiles) {
  //     acceptedFiles.map((file) => {
  //       setName(file.name);
  //       setDocFile(file);
  //     });
  //   }
  // }, [acceptedFiles]);

  const formik = useFormik({
    initialValues: {
      username: "",
      first_name: "",
      last_name: "",
      mobile_no: "",
      email: "",
      gender: "",
      dob: "",
      address: "",
      status: ""

    },
    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit,
  });

  const customStyles = {
    browse_button: {
      height: '100%',
      position: 'absolute',
      top: '0',
      right: '0',
      zIndex: '99',
      cursor: 'pointer',
      display: 'none',
      /*This makes the button huge. If you want a bigger button, increase the font size*/
      fontSize: '50px',
      /*Opacity settings for all browsers*/
      opacity: '0',
      mozOpacity: '0',
      msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
    }
  }

  const phoneChange = value => {
    setInputValue(value)
    formik.setFieldValue("mobile_no", value ? value : "");
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }


  return (
    <div style={{ width: '100%' }}>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      // secondaryClick={validationCheck}
      />
      {addNewActive ? (
        <>
          {/* ADD NEW DOCTOR FORM GOES HERRE */}
          <div
            style={{ width: '100%', padding: '20px 40px' }}
            className={classes.MainContent}>
            <form onSubmit={formik.handleSubmit}
              noValidate={true}
              className={classes.root}>

              <Grid container spacing={3}>
                <Grid item xs={12} md={8} className={classes.updateprofileform}>
                  <Grid container spacing={3} style={{ marginTop: "30px" }}>

                    <Grid item xs={12} md={5}>
                      <TextField
                        id="username"
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="User_Name"
                        onChange={formik.handleChange}
                        value={formik && formik.values && formik.values.username}
                        onBlur={formik.handleBlur}
                        name="username"
                        label="User Name *"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.username && formik.errors.username
                              ? formik.errors.username
                              : null}
                          </div>
                        }
                      />

                      <TextField
                        id="first_name"
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="First Name"
                        onChange={formik.handleChange}
                        value={formik && formik.values && formik.values.first_name}
                        onBlur={formik.handleBlur}
                        name="first_name"
                        label="First Name *"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.first_name && formik.errors.first_name
                              ? formik.errors.first_name
                              : null}
                          </div>
                        }
                      />

                      <TextField
                        id="email"
                        style={{ width: "100%" }}
                        placeholder="jane@gmail.com"
                        name="email"
                        label="Email *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik && formik.values && formik.values.email}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : null}
                          </div>
                        }
                      />

                      <PhoneInput
                        placeholder="Enter phone number"
                        international
                        defaultCountry="IN"
                        id="mobile_no"
                        value={inputValue}
                        style={{ width: "100%", paddingLeft: "10px", paddingTop: "35px" }}
                        name="mobile_no"
                        onChange={phoneChange}
                        error={inputValue ? (isValidPhoneNumber(inputValue) ? undefined : 'Invalid phone number') : 'Phone number required'}

                      />
                      {
                        <div className={classes.errMsg2}>
                          {formik.touched.mobile_no && formik.errors.mobile_no
                            ? formik.errors.mobile_no
                            : null}
                        </div>
                      }
                    </Grid>
                    <Grid item xs={12} md={1} />
                    <Grid item xs={12} md={5}>
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-placeholder-label-label"
                        >
                          Status
                  </InputLabel>
                        <Select
                          id="status"
                          value={formik.values.status}
                          name="status"
                          label="Status *"
                          onChange={statusChange}
                          style={{ width: "100%" }}
                          onBlur={formik.handleBlur}
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.status && formik.errors.status
                                ? formik.errors.gender
                                : null}
                            </div>
                          }
                        >
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="inactive">In-Active</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        id="last_name"
                        style={{ width: "100%" }}
                        placeholder="Last name"
                        name="last_name"
                        label="Last name*"
                        value={formik && formik.values && formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.last_name && formik.errors.last_name
                              ? formik.errors.last_name
                              : null}
                          </div>
                        }
                      />
                      <TextField
                        style={{ width: "100%" }}
                        id="dob"
                        label="Date of birth"
                        type="date"
                        name="dob"
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.dob && formik.errors.dob
                              ? formik.errors.dob
                              : null}
                          </div>
                        }
                        value={formik && formik.values && formik.values.dob}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-placeholder-label-label"
                        >
                          Gender
                  </InputLabel>
                        <Select
                          id="gender"
                          value={formik.values.gender}
                          name="gender"
                          label="Gender *"
                          onChange={genderChange}
                          style={{ width: "100%" }}
                          onBlur={formik.handleBlur}
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.gender && formik.errors.gender
                                ? formik.errors.gender
                                : null}
                            </div>
                          }
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="address"
                      style={{ width: "100%" }}
                      placeholder="Address"
                      name="address"
                      label="Address *"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik && formik.values && formik.values.address}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.address && formik.errors.address
                            ? formik.errors.address
                            : null}
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.updateprofilecol}>
                  <Box
                    style={{
                      width: "140px",
                      height: "140px",
                      background: "white",
                      borderRadius: "50%",
                      boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
                      0 2px 2px rgba(0,0,0,0.12), 
                      0 4px 4px rgba(0,0,0,0.12), 
                      0 8px 8px rgba(0,0,0,0.12),
                      0 16px 16px rgba(0,0,0,0.12)`,
                      margin: "25px auto",
                      textAlign: "right",
                      padding: "10px",
                      display: "flex",
                    }}
                  >
                    {Image ? (
                      <Avatar className={classes.AvatarCol} src={Image} />
                    ) : null}
                    {/* {Image ? <p>Loading...</p> : <div>First Name</div>} */}
                  </Box>
                  <label
                    style={{
                      textAlign: "center",
                      margin: "20px auto",
                      display: "block",
                      fontWeight: "bold",
                      width:'150px'
                    }}
                  >
                    <div>
                      <Button
                        onClick={handleClick}
                        style={{
                          width: "140px",
                          borderRadius: "50px",
                          background: "#3f4b79",
                          border: "none",
                          height: "40px",
                          textAlign: "center",
                          color: "#fff",
                          // lineHeight: '40px',
                          cursor: "pointer",
                        }}
                      >
                        Edit Picture
                </Button>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        accept=" image/jpeg, image/png"
                        ref={hiddenFileInput}
                        style={customStyles.browse_button}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </label>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.updateprofilebutton}>
                  <button
                    type={"submit"}
                    style={{
                      width: "140px",
                      borderRadius: "50px",
                      background: "#3f4b79",
                      border: "none",
                      height: "40px",
                      textAlign: "center",
                      color: "#fff",
                      lineHeight: "40px",
                      cursor: "pointer",
                    }}
                  >
                    Create Account
            </button>

                  <button
                    className={classes.CancelBtn}
                    onClick={() => setAddNewState(false)}>
                    Cancel
                      </button>
                </Grid>
              </Grid>

            </form>
          </div>
        </>
      ) : (
          <>
            <Grid container>
              <Grid item xs={12} md={9}>
                {data.length ? (
                  <>
                    {data.map((d, index) => {
                      return (
                        <StaffListItem
                          image={d.image}
                          name={d.name}
                          degree={d.degree}
                          designation={d.designation}
                          mobile={d.mobile}
                          email={d.email}
                          doctorId={d.doctorId}
                          status={d.status}
                          key={index}
                        />
                      )
                    })}
                  </>
                ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        <img
                          alt="No data"
                          style={{ display: 'block', width: '200px' }}
                          src={NoRecordImg}
                        />
                      </div>
                    </>
                  )}
              </Grid>
              <Grid item xs={12} md={3}>
                <div className={classes.addNewContainer}>
                  <Box boxShadow={4} className={classes.addNew} onClick={Addnew}>
                    <b className={classes.plus}>
                      <span>+</span>Add New Doctor
                  </b>
                  </Box>
                  <div className={classes.media}>
                    <h1 style={{ color: '#fff' }}>{data.length}</h1>
                    <p>Total Active Doctors</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        )}
    </div>
  )
}

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .required("User Name is mandatory")
    .matches(VALIDATE_WHITESPACES, "Space should not come at begining"),
  first_name: yup
    .string()
    .required("Name is required")
    .matches(VALIDATE_WHITESPACES, "Space should not come at begining"),
  address: yup
    .string()
    .required("Address is Required")
    .matches(VALIDATE_WHITESPACES, "Space should not come at begining"),

  email: yup
    .string()
    .email("Invalid email")
    .required("Email is Required")
    .matches(VALIDATE_WHITESPACES, "Space should not come at begining"),
  mobile_no: yup
    .string()
    // .matches(VALIDATE_PHONE, " Check with +91")
    .required("Mobile number is Required"),
  gender: yup.string().required("Gender is required"),
  status: yup.string().required("Status is required"),
  dob: yup
    .string()
    // .matches(/\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])/, "incorrect date format" , { excludeEmptyString: true })
    .required("Dob is required")
});

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  addNew: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  UserPicture: {
    width: '200px',
    height: '200px',
    background: 'rgba(0,0,0,0.1)',
    borderRadius: '50%',
    position: 'relative',
    margin: '20px auto',
    textAlign: 'center',
    '& img': {
      width: '92%',
      padding: '20px'
    }
  },
  errMsg: {
    color: "red",
  },
  errMsg2: {
    color: "red",
    fontSize: "12px",
    paddingLeft: "5px",
    paddingTop: "4px"
  },
  InputFile2: {
    position: 'absolute',
    bottom: '20px',
    left: '45%',
    width: '50px',
    opacity: '0',
    zIndex: '99'
  },
  CameraIcon: {
    position: 'absolute',
    bottom: '20px',
    left: '45%',
    fontSize: '30px',
    color: '#fff'
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  BtnCol: {
    marginTop: '40px'
  },
  UserId: {
    width: '32%'
  },
  MainContent: {
    '@media (max-width:767px)': {
      padding: '20px'
    },
    '& .MuiInputBase-root': {
      width: '100%'
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  AddIcon: {
    marginRight: '10px'
  },
  FileUpload: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer'
  },
  InputFile: {
    opacity: '0',
    position: 'absolute'
  },
  input: {
    width: '100%'
  },
  UploadBtn: {
    width: '130px',
    background: '#3f4b79',
    textAlign: 'center',
    lineHeight: '40px',
    borderRadius: '50px',
    color: '#fff',
    border: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 15px',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  CancelBtn: {
    width: '150px',
    background: '#ccc',
    textAlign: 'center',
    lineHeight: '40px',
    borderRadius: '50px',
    color: '#3f4b79',
    border: 'none',
    marginRight: '15px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  AccountBtn: {
    width: '150px',
    background: '#3f4b79',
    textAlign: 'center',
    lineHeight: '40px',
    borderRadius: '50px',
    color: '#fff',
    border: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  plus: {
    display: 'flex',
    '& span': {
      color: '#fff',
      width: '25px',
      height: '25px',
      display: 'flex',
      fontSize: '25px',
      background: '#3f4b79',
      fontWeight: 'bold',
      justifyContent: 'center',
      borderRadius: '50%',
      alignItems: 'center',
      padding: '0',
      marginRight: '7px'
    }
  },
  media: {
    background: `url(${MediaBgImg}) center top no-repeat`,
    marginTop: '25px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 20px',
    backgroundSize: 'cover',
    color: '#fff',
    '& h1': {
      margin: '0'
    },
    '& p': {
      margin: '0'
    }
  },
  addNewContainer: {
    display: 'block',
    margin: '0 auto',
    width: '85%',
    '@media (max-width:767px)': {
      marginTop: '20px'
    }
  },
  formControl: {
    width: "100%",
    margin: "12px 8px 8px",
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  CancelBtn: {
    width: "140px",
    borderRadius: "50px",
    background: "#3f4b79",
    border: "none",
    height: "40px",
    textAlign: "center",
    color: "#fff",
    lineHeight: "40px",
    cursor: "pointer",
  },
}))
