import React from 'react'
import {
  Box,
  DialogActions,
  Grid,
  Slide,
  Dialog,
  DialogContent,
  DialogContentText,
  Tooltip,
  withStyles
} from '@material-ui/core'

import Button from '../../components/Button'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

const viewDialog = ({ open, handleClose, doctorDetail }) => (
  <>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent
        className="edit-modal"
        style={{
          height: 'auto',
          padding: '30px 40px 0 !important'
        }}>
        <DialogContentText id="alert-dialog-slide-description">
          <Box
            className="modal-top"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <h3>Doctor Infomation</h3>
          </Box>
        </DialogContentText>
        <Box className="clinic-profile">
          <Box className="clinic-avatar">
            <img
              src={doctorDetail?.profile_picture}
              alt={`${doctorDetail?.doctor_name}`}
            />
          </Box>
        </Box>
        <Box
          className="clinic-details"
          style={{
            paddingLeft: '0px',
            width: '100%',
            marginBottom: '5px'
          }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className="form-group">
                <p>Name</p>
                <div>{doctorDetail?.doctor_name}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="form-group">
                <p>Address</p>
                <div>{doctorDetail?.clinic_address} </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="form-group">
                <p>Phone No</p>
                <div>{doctorDetail?.mobile_no}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className="form-group">
                <p>Email ID</p>
                <LightTooltip title={doctorDetail?.email} placement="top-start">
                  <div
                    style={{
                      width: '70%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}>
                    {doctorDetail?.email}
                  </div>
                </LightTooltip>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                width: '420px',
                background: '#fff',
                display: 'flex',
                justifyContent: 'flex-end'
              }}></Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            padding: '0 1rem 0.8rem 0',
            display: 'grid',
            gridAutoFlow: 'column',
            gridColumnGap: '0.8rem',
            justifyContent: 'flex-end'
          }}>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{
              padding: '0 1.5rem',
              height: '2.5rem',
              color: '#cd4b4b',
              background: '#ccc'
            }}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  </>
)

export default viewDialog
