import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { ClinicStore } from '../../../../stores'
import Loader from '../../../components/ClinicLoader'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import { NETWORK_STATUS } from '../../../../Constants'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import NoRecordImg from '../../../../images/noRecordFound.png'

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px',
    marginTop: '70px'
  }
}))

const Payment = ({
  className,
  doctors,
  appointment,
  setPaymentWindow,
  fetchList,
  ...rest
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [services, setServices] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [paymentMode, setPaymentMode] = useState('CASH')

  const { networkStatus, servicesList } = ClinicStore.useStoreState(state => ({
    ...state.appointment
  }))
  const {
    appointmentServicesList,
    appointmentPaymentConfirm,
    appointmentServicesPayment
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  useEffect(() => {
    appointmentServicesList({
      appointment_id: appointment.id
    })
  }, [])

  useEffect(() => {
    if (servicesList.length !== 0) {
      setServices(servicesList.filter(i => i.service_status === '1'))
    }
  }, [servicesList])

  const handleChange = (event, item) => {
    let value = event.target.checked
    if (value) {
      appointmentServicesPayment({
        patient_service_id: item.patient_service_id
      })
    } else {
      setOpen(true)
      setSnackBarMessage('Cannot mark the payment as unpaid')
      setmultiButton(false)
      setAlertType('info')
    }
  }

  const handleSubmit = () => {
    appointmentPaymentConfirm({
      appointment_id: appointment.id,
      offline_payment_mode: paymentMode
    }).then(data => {
      if (data) {
        fetchList()
        setOpen(true)
        setSnackBarMessage(data.message)
        setmultiButton(false)
        setAlertType('success')
        setDialogOpen(false)
        setPaymentWindow(false)
      } else {
        setOpen(true)
        setSnackBarMessage('Payment could not be completed')
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const handlePaymentModeChange = event => {
    setPaymentMode(event.target.value)
  }

  return (
    <>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Dialog
        style={{ maxWidth: '100% !important' }}
        open={dialogOpen}
        classes={{ paper: classes.modalView }}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          Payment mode
        </DialogTitle>
        <DialogContent>
          Please select the payment mode
          <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={paymentMode}
            style={{ width: '80%' }}
            onChange={handlePaymentModeChange}
            label="Select Doctor">
            <MenuItem value={'CREDIT-CARD'}>CREDIT-CARD</MenuItem>
            <MenuItem value={'DEBIT-CARD'}>DEBIT-CARD</MenuItem>
            <MenuItem value={'UPI'}>UPI</MenuItem>
            <MenuItem value={'CASH'}>CASH</MenuItem>
            <MenuItem value={'NET-BANKING'}>NET-BANKING</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions style={{ padding: '20px 40px' }}>
          <button
            onClick={() => setDialogOpen(false)}
            className={classes.modalSecondaryButton}
            style={{ backgroundColor: '#aeaeae' }}>
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className={classes.modalPrimaryButton}
            style={{ backgroundColor: '#111d4e' }}>
            Confirm
          </button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={() => setOpen(false)}
      />
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Doctor Fees" />
        <Divider />
        <PerfectScrollbar style={{ overflow: 'auto' }}>
          <Box>
            <Table>
              <TableBody>
                <TableRow hover>
                  <TableCell style={{ wordBreak: 'break-word' }}>
                    Doctor Fees
                  </TableCell>
                  <TableCell style={{ wordBreak: 'break-word' }}>
                    Rs. {appointment.doctor_fee}
                  </TableCell>
                  <TableCell style={{ wordBreak: 'break-word' }}>
                    {appointment.is_payment_done === '2'
                      ? 'Payment Done'
                      : 'Payment Due'}
                  </TableCell>
                  <TableCell style={{ wordBreak: 'break-word' }}>
                    Mark As Paid
                    <Checkbox
                      checked={appointment.is_payment_done === '2'}
                      disabled={appointment.is_payment_done === '2'}
                      onChange={() => setDialogOpen(true)}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>

      <Card
        className={clsx(classes.root, className)}
        {...rest}
        style={{ marginTop: '10px' }}>
        <CardHeader title="Service Fees" />
        <Divider />
        <PerfectScrollbar style={{ overflow: 'auto' }}>
          <Box>
            <Table>
              <TableBody>
                {services.map(item => (
                  <TableRow hover>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.service_name}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.service_amount}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      {item.service_payment_status === '1'
                        ? 'Payment Done'
                        : 'Payment Due'}
                    </TableCell>
                    <TableCell style={{ wordBreak: 'break-word' }}>
                      Mark As Paid
                      <Checkbox
                        onChange={e => handleChange(e, item)}
                        checked={item.service_payment_status === '1'}
                        disabled={item.service_payment_status === '1'}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {services.length == 0 ? (
              <img
                src={NoRecordImg}
                className={classes.notFoundImage}
                alt={'Not found!'}
              />
            ) : (
              ''
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

Payment.propTypes = {
  className: PropTypes.string
}

export default Payment
