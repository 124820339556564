import React from 'react'
import { makeStyles, Avatar } from '@material-ui/core'
import { DoctorPublicStore } from '../../stores'
import DefaultImage from '../../images/profile_pic.png'

const ProfilePic = ({ name, children }) => {
  const classes = useStyles()

  const { image } = DoctorPublicStore.useStoreState(state => ({
    ...state.doctorProfile
  }))

  return (
    <div className={classes.root}>
      <Avatar
        className={classes.AvatarCol}
        alt={name}
        src={image || DefaultImage}
      />
      {children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0)
    }
  },

  // Causing css flex effect.
  // avatar: {
  //   width: '140px',
  //   height: '117px',
  //   background: 'white',
  //   borderRadius: '50%',
  //   boxShadow: `0 1px 1px rgba(0,0,0,0.12),
  //     0 2px 2px rgba(0,0,0,0.12),
  //     0 4px 4px rgba(0,0,0,0.12),
  //     0 8px 8px rgba(0,0,0,0.12),
  //     0 16px 16px rgba(0,0,0,0.12)`,
  //   margin: '0px auto',
  //   textAlign: 'center',
  //   padding: '0px auto',
  //   display: 'flex',
  //   '& > *': {
  //     margin: theme.spacing(0)
  //   }
  // },
  AvatarCol: {
    width: '100%',
    height: '100%'
  }
}))

export default ProfilePic
