import React from 'react'

import { DashboardLayout } from '../../patientLayouts'
import PatientDocument from '../../components/MyDocuments/patientDocument'

const PatientDocumentPage = () => {
  return (
    <DashboardLayout>
      <PatientDocument />
    </DashboardLayout>
  )
}

export default PatientDocumentPage
