import React from 'react'
import DoctorScheduler from '../../components/Scheduler/MyScheduler'
import { DashboardLayout } from '../../doctorLayouts'

const MySchedule = () => {
  return (
    <DashboardLayout>
      <DoctorScheduler />
    </DashboardLayout>
  )
}

export default MySchedule
