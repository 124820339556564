import React from 'react'
import { Box, Grid, Link, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {
  IoIosArrowForward,
  IoIosArrowRoundUp,
  IoIosArrowRoundDown
} from 'react-icons/io'
import moment from 'moment'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { Chart } from 'react-google-charts'
import Card from '../CommonComponents/Card'
import AddVitalsModal from './AddVitalsModal'
import ViewVitalsHistoryModal from './ViewVitalsHistoryModal'
import NoRecordImg from '../../images/noRecordFound.png'

const PatientDetailchart = props => {
  const {
    classes,
    showVitalsModal,
    hideVitalsModal,
    VitalsHistoryModal,
    VitalsData,
    AddVitals,
    showVitalsHistory,
    hideVitalsHistoryModal,
    handleChange,
    value,
    params,
    alignment,
    TabPanel,
    chartData
  } = props

  return (
    <React.Fragment>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box>
              <h3 style={{ textAlign: 'left' }}>Vitals Record</h3>
              <Card className={classes.vitalsCard}>
                <h5 style={{ margin: '10px 0', color: '#000' }}>
                  Add New Record
                </h5>
                <IconButton className={classes.button}>
                  <Link style={{ display: 'flex', alignItems: 'center' }}>
                    <IoIosArrowForward
                      className={classes.icon}
                      size={32}
                      color="#fff"
                      onClick={showVitalsModal}
                    />
                  </Link>
                </IconButton>
              </Card>
              <Card className={classes.vitalsCard} mt={2}>
                <h5 style={{ margin: '10px 0', color: '#000' }}>
                  View Vitals History
                </h5>
                <IconButton className={classes.button}>
                  <Link style={{ display: 'flex', alignItems: 'center' }}>
                    <IoIosArrowForward
                      className={classes.icon}
                      size={32}
                      color="#fff"
                      onClick={VitalsHistoryModal}
                    />
                  </Link>
                </IconButton>
              </Card>
            </Box>
          </Grid>
          {VitalsData.length ? (
            <Grid item xs={12} md={7}>
              <h4>
                Latest Record :{' '}
                <small
                  className={classes.vitalName}
                  style={{ fontSize: '13px' }}>
                  {VitalsData[0] && VitalsData[0].datetime_recorded
                    ? moment(VitalsData[0].datetime_recorded).format(
                        'DD/MM/YYYY, h:mm:ss a'
                      )
                    : 'NA'}
                </small>
              </h4>
              <Grid container spacing={2}>
                <Grid item sm={4} style={{ width: '100%' }}>
                  <Card style={{ background: '#fff' }}>
                    {VitalsData[0].sbp > 124 && VitalsData[0].sbp <= 140 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].sbp > 140 || VitalsData[0].dbp > 90 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].sbp > 104 && VitalsData[0].sbp <= 115 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].sbp <= 104 && VitalsData[0].dbp <= 64 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && ~~VitalsData[0].sbp} /{' '}
                      {VitalsData[0] && ~~VitalsData[0].dbp}
                    </h3>

                    <p className={classes.vitalName}>Blood Pressure (mmHg)</p>
                  </Card>
                </Grid>
                <Grid item sm={4} style={{ width: '100%' }}>
                  <Card style={{ background: '#fff' }}>
                    {VitalsData[0].temperature < 97 &&
                    VitalsData[0].temperature > 95.5 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].temperature <= 95.5 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].temperature > 98.6 &&
                    VitalsData[0].temperature < 100.4 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].temperature >= 100.4 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].temperature
                        ? VitalsData[0].temperature
                        : 'NA'}
                    </h3>
                    <p className={classes.vitalName}>
                      Temperature <br />
                      (F)
                    </p>
                  </Card>
                </Grid>
                <Grid item sm={4} style={{ width: '100%' }}>
                  <Card style={{ background: '#fff' }}>
                    {VitalsData[0].oxygen_saturation_rate > 92 &&
                    VitalsData[0].oxygen_saturation_rate <= 97 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].oxygen_saturation_rate <= 92 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].oxygen_saturation_rate
                        ? Math.floor(VitalsData[0].oxygen_saturation_rate)
                        : 'NA'}
                    </h3>
                    <p className={classes.vitalName}>Oxygen Sat.(spO2%)</p>
                  </Card>
                </Grid>
                <Grid item sm={4} style={{ width: '100%' }}>
                  <Card style={{ background: '#fff' }}>
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].height
                        ? Math.floor(VitalsData[0].height)
                        : 'NA'}
                    </h3>
                    <p className={classes.vitalName}>Height (cm)</p>
                  </Card>
                </Grid>
                <Grid item sm={4} style={{ width: '100%' }}>
                  <Card style={{ background: '#fff' }}>
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].weight
                        ? VitalsData[0].weight
                        : 'NA'}
                    </h3>
                    <p className={classes.vitalName}>Weight (kg)</p>
                  </Card>
                </Grid>
                <Grid item sm={4} style={{ width: '100%' }}>
                  <Card style={{ background: '#fff' }}>
                    {VitalsData[0].heart_rate > 94 &&
                    VitalsData[0].heart_rate <= 115 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].heart_rate > 115 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].heart_rate >= 50 &&
                    VitalsData[0].heart_rate < 60 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="#f2a208"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    {VitalsData[0].heart_rate < 50 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}
                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].heart_rate
                        ? Math.floor(VitalsData[0].heart_rate)
                        : 'NA'}
                    </h3>
                    <p className={classes.vitalName}>Heart Rate (bpm)</p>
                  </Card>
                </Grid>
                <Grid item sm={4} style={{ width: '100%' }}>
                  <Card style={{ background: '#fff' }}>
                    {VitalsData[0].respiratory_rate > 16 ? (
                      <IoIosArrowRoundUp
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}

                    {VitalsData[0].respiratory_rate < 12 ? (
                      <IoIosArrowRoundDown
                        size={32}
                        color="red"
                        style={{ float: 'right' }}
                      />
                    ) : null}

                    <h3 className={classes.patientVital}>
                      {VitalsData[0] && VitalsData[0].respiratory_rate
                        ? Math.floor(VitalsData[0].respiratory_rate)
                        : 'N/A'}
                    </h3>
                    <p className={classes.vitalName}>Respiratory Rate (bpm)</p>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <img
                  alt="No data"
                  style={{ display: 'block', width: '250px' }}
                  src={NoRecordImg}
                />
              </div>
            </>
          )}

          {AddVitals ? (
            <AddVitalsModal
              showHideModal={hideVitalsModal}
              isShow={AddVitals}
              pateintId={params.patient_id}></AddVitalsModal>
          ) : (
            <></>
          )}

          {showVitalsHistory ? (
            <ViewVitalsHistoryModal
              showHideModal={hideVitalsHistoryModal}
              isShow={showVitalsHistory}
              pateintId={params.patient_id}></ViewVitalsHistoryModal>
          ) : (
            <></>
          )}

          {chartData ? (
            <Grid item xs={12} md={12}>
              <Box className={classes.GrapTop}>
                <Typography component="h4">
                  Vitals <span>Average for month</span>
                </Typography>
                <Box className={classes.BtnCol}>
                  <ToggleButtonGroup
                    size="small"
                    className={classes.ToggleBtn}
                    value={alignment}
                    exclusive
                    onChange={handleChange}>
                    <ToggleButton
                      value="weekly"
                      className={
                        alignment === 'weekly' ? classes.ActiveBtn : ''
                      }>
                      Latest
                    </ToggleButton>
                    <ToggleButton
                      value="monthly"
                      className={
                        alignment === 'monthly' ? classes.ActiveBtn : ''
                      }>
                      Month
                    </ToggleButton>
                    <ToggleButton
                      value="yearly"
                      className={
                        alignment === 'yearly' ? classes.ActiveBtn : ''
                      }>
                      Year
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.bp}
                      options={{
                        title: 'Blood Pressure',
                        isStacked: true,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      className={classes.Areachart}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.temp}
                      options={{
                        title: 'Temperature',
                        isStacked: true,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      style={{ background: '#c3dcfd' }}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.hrate}
                      options={{
                        title: 'Heart Rate',
                        isStacked: false,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      style={{ background: '#c3dcfd' }}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.bmi}
                      options={{
                        title: 'BMI',
                        isStacked: false,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      style={{ background: '#c3dcfd' }}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.oxy}
                      options={{
                        title: 'Oxygen saturation',
                        isStacked: false,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.ChartBox}>
                    <Chart
                      style={{ background: '#c3dcfd' }}
                      width="100%"
                      height="250px"
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={chartData?.resp}
                      options={{
                        title: 'Respiration Rate',
                        isStacked: false,
                        height: 250,
                        legend: { position: 'top', maxLines: 5 },
                        vAxis: { minValue: 0 },
                        series: {
                          0: { color: '#3f4b79' },
                          1: { color: '#6bdf0e' }
                        }
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </TabPanel>
    </React.Fragment>
  )
}

export default PatientDetailchart
