import React from 'react'
import { DashboardLayout } from '../../doctorLayouts'
import SMSList from '../../components/Communication/SMSList'

export default function Communication() {
  return (
    <DashboardLayout>
      <SMSList />
    </DashboardLayout>
  )
}
