import React from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import Page from '../../../components/Page'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DoctorSchedule from '../../../../components/Scheduler/MyScheduler'
import DoctorPage from '../../../../pages/DoctorProfile/DoctorPage'
import ServiceList from './ServiceList'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const ServicesContent = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="services">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <ServiceList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default ServicesContent
