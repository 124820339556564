import React, { useState, useLayoutEffect, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import { ClinicStore } from '../../../../stores'
// import { NETWORK_STATUS } from '../../../../Constants'

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AppointmentStatusChangeDialog = ({
  previousState,
  nextState,
  openConfirm,
  handleClose = () => {},
  appointmentData,
  refresh
}) => {
  const appointmentStatus = [
    null,
    'Booked',
    'Confirmed',
    'Checked-In',
    'In-Progress',
    'Cancelled',
    'No-show',
    'Completed'
  ]

  const paymentModes = useRef([
    'credit-card',
    'debit-card',
    'upi',
    'cash',
    'net-banking'
  ])

  const { editAppointmentStatusInClinic } = ClinicStore.useStoreActions(
    actions => ({
      ...actions.appointment
    })
  )

  const [switchToPaymentModal, setSwitchToPaymentModal] = useState(false)
  const [offlinePaymentMode, setOfflinePaymentMode] = useState(null)
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: null,
    error: null
  })

  useLayoutEffect(() => {
    if (
      openConfirm &&
      nextState === 7 &&
      appointmentData?.appointment_type === '1' &&
      appointmentData?.is_payment_done === '1'
    ) {
      setSwitchToPaymentModal(true)
      setOfflinePaymentMode(paymentModes.current[0].toUpperCase())
    }
    if (!openConfirm) {
      setOfflinePaymentMode(null)
    }
  }, [openConfirm, nextState, appointmentData, paymentModes])

  const handleSubmit = async () => {
    handleClose()
    try {
      const responseData = await editAppointmentStatusInClinic({
        appointment_id: appointmentData?.id,
        doctor_id: appointmentData?.doctor_id,
        status: nextState, // -- 1 = booked, 2 = confirmed (unused), 3 = checked-in, 4 = in-progress, 5 = cancelled, 6 = no-show, 7 = completed , 8 = rescheduled
        patient_id: appointmentData?.patient_id,
        offline_payment_mode: offlinePaymentMode // --- credit-card, debit-card, upi, cash, net-banking
      })

      if (
        !!responseData &&
        !!responseData.statusCode &&
        responseData.statusCode === 200
      ) {
        setSnackBarMessage({
          message: `Successfully updated appointment from ${appointmentStatus[previousState]} to ${appointmentStatus[nextState]}`,
          error: false
        })
        refresh()
      } else {
        setSnackBarMessage({
          message:
            !!responseData && !!responseData.message
              ? responseData.message
              : 'Oops! Something went wrong. Try again later!',
          error: true
        })
      }
    } catch (error) {
      setSnackBarMessage({
        message: 'Oops! Something went wrong. Try again later!',
        error: true
      })
    }
    setOfflinePaymentMode(null)
  }

  const handlePaymentSubmit = () => {
    setSwitchToPaymentModal(false)
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarMessage({
      message: null,
      error: null
    })
  }

  return (
    <>
      <Snackbar
        open={!!snackBarMessage.message}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'bottom' }}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={!!snackBarMessage.error ? 'error' : 'success'}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openConfirm}
        onClose={handleClose}
        fullWidth
        maxWidth='sm'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {!switchToPaymentModal ? 'Are you sure?' : 'Choose payment mode'}
        </DialogTitle>
        <DialogContent>
          {!switchToPaymentModal ? (
            <DialogContentText id="alert-dialog-description">
              By clicking 'Yes', you are going to change the status of the
              appointment from '{appointmentStatus[previousState]}' to '
              {appointmentStatus[nextState]}'!
            </DialogContentText>
          ) : (
            <FormControl
              style={{ display: 'block', width: '70%', margin: 'auto' }}>
              <InputLabel htmlFor="max-width">Payment Mode</InputLabel>
              <Select
                autoFocus
                style={{ display: 'inline-block', width: '90%' }}
                value={offlinePaymentMode}
                onChange={e => setOfflinePaymentMode(e.target.value)}>
                {paymentModes.current.map(i => (
                  <MenuItem key={i} value={i.toUpperCase()}>
                    {i
                      .split('-')
                      .map(word => word[0].toUpperCase() + word.substring(1))
                      .join(' ')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          {!switchToPaymentModal ? (
            <Button onClick={handleSubmit} color="primary">
              Yes
            </Button>
          ) : (
            <Button onClick={handlePaymentSubmit} color="primary">
              Proceed
            </Button>
          )}
          <Button onClick={handleClose} color="secondary" autoFocus>
            {!switchToPaymentModal ? 'No' : 'Exit'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AppointmentStatusChangeDialog
