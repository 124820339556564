import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Footer from '../../../components/CommonComponents/Footer'
import logo from '../../../images/logo-iconWhite.png'
import Page from '../../components/Page'
import TopBarHome from '../../layouts/MainLayout/TopBar'
import { SuperClinicStore } from '../../../stores'
import Loader from '../../components/ClinicLoader'
import { NETWORK_STATUS, SUPER_CLINIC_USER_ID } from '../../../Constants'
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0)
  },
  footercol: {
    width: '100%',
    paddingRight: '5%',
    display: 'flex',
    paddingTop: '50px'
  }
}))

const SuperClinicLoginView = props => {
  const classes = useStyles()

  const {
    history: { replace },
    location: { pathname }
  } = props

  const { login } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.auth
  }))
  const { networkStatus } = SuperClinicStore.useStoreState(state => ({
    ...state.auth
  }))

  const submit = (values, { resetForm }) => {
    login(values).then(data => {
      if (data.statusCode === 200) {
        localStorage.setItem(SUPER_CLINIC_USER_ID, data.super_clinic_details.id)
        replace(pathname.replace('/login', ''))
      } else {
        resetForm()
      }
    })
  }

  return (
    <Page className={classes.root} title="Login">
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      {/* <TopBarHome /> */}
      {/* <Grid container spacing={3}>
        <Grid item xs={12}>
          <img
            src={bannerimg}
            style={{ width: '100%', marginTop: '4rem' }}
            alt="bannerImg"
          />
        </Grid>
      </Grid> */}
      <Box className="">
        <Box className="col-left"></Box>
        <Box className="col-bg"></Box>
      </Box>
      <Box className="top-header">
        <img src={logo} alt="logo" style={{ width: '70px' }} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="flex-start"
        className="login-box">
        <Box className="login-coll">
          <Formik
            onSubmit={submit}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                submit()
              }
            }}
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3} className="Logincol">
                  <h3> Sign in </h3>
                  <p>Sign in on the super clinic platform</p>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Enter email address"
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="email"
                  type="email"
                  value={values.email}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                />
                <Box my={2}>
                  <Button
                    style={{
                      backgroundColor: '#063bc4',
                      color: '#fff',
                      fontSize: '14px',
                      height: '42px',
                      fontSize: '14px',
                      borderRadius: '50px',
                      margin: '30px 0 10px'
                    }}
                    fullWidth
                    size="large"
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained">
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      {/* <div className={classes.footercol}>
        <Footer />
      </div> */}
    </Page>
  )
}

export default withRouter(SuperClinicLoginView)
