import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Loader from '../CommonComponents/Loader'
import { makeStyles, Box, Grid, Typography } from '@material-ui/core'
import { PatientStore } from '../../stores'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import SnackbarAlert from '../../components/CommonComponents/SnackbarAlert'
import ManualPrescriptionDialog from '../CommonComponents/ManualPrescriptionDialog'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const PatientVisitSummary = props => {
  const params = useParams()
  const disabled = props.completed
  const appointmentId = props.appointmentId
  const visitSummary = props.visitSummary
  const [open, setOpen] = React.useState(false)
  const [values, setValues] = useState({
    diagnosis: '',
    observations: '',
    suggestions: '',
    clinical_notes: ''
  })
  const [value, setValue] = React.useState(0)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  const [showManualPrescriptionDialog, setShowManualPrescriptionDialog] =
    useState(false)

  const classes = useStyles()

  const { prescription, networkStatus, manualPrescriptionList } =
    PatientStore.useStoreState(state => ({
      ...state.appointments
    }))
  const {
    generatePrescriptionPdf,
    fetchManualPrescriptionList,
    updateNetworkStatus
  } = PatientStore.useStoreActions(actions => ({
    ...actions.appointments
  }))

  React.useEffect(() => {
    if (prescription?.pdf_blob) {
      let presDate = moment(props.appointmentDate).format('DD-MM-YYYY')
      const linkSource = `data:application/pdf;base64,${prescription?.pdf_blob}`
      const downloadLink = document.createElement('a')
      const fileName = `Prescription_${presDate}.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    } else if (prescription?.message) {
      setOpenSnackBar(true)
      setSnackBarMessage(prescription.message)
      setmultiButton(false)
      setAlertType('info')
    }
  }, [prescription])

  const generatePdf = () => {
    generatePrescriptionPdf({
      appointment_id: props?.appointmentId //appointmentId to be replaced
    })
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <div>
      <ManualPrescriptionDialog
        open={showManualPrescriptionDialog}
        onClose={() => setShowManualPrescriptionDialog(false)}
        appointmentId={appointmentId}
        networkStatus={networkStatus}
        manualPrescriptionList={manualPrescriptionList}
        fetchManualPrescriptionList={fetchManualPrescriptionList}
        updateNetworkStatus={updateNetworkStatus}
      />
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        // secondaryClick={validationCheck}
      />
      {networkStatus === 'FETCHING' ? (
        <div>
          <Loader open={true} />
        </div>
      ) : (
        <div>
          <Loader open={false} />
        </div>
      )}
      <Paper elevation={3} className={classes.paper}>
        {visitSummary?.length !== 0 ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Box className={classes.SummaryText}>
                <h2>
                  Visit Summary
                  {props.status === '7' && (
                    <>
                      <button className={classes.PdfBtn} onClick={generatePdf}>
                        Generate Prescription
                      </button>
                      <button
                        className={classes.PdfBtn}
                        onClick={() => setShowManualPrescriptionDialog(true)}>
                        Print Manual Prescriptions
                      </button>
                    </>
                  )}
                </h2>
                {visitSummary.vitals[0].sbp ? (
                  <p>
                    <span>Body Vitals:</span>
                    <b>BP : </b> &nbsp;{Math.round(visitSummary.vitals[0].sbp)}/
                    {Math.round(visitSummary.vitals[0].dbp)} , &nbsp;
                    <b>HR : </b>&nbsp;
                    {Math.round(visitSummary.vitals[0].heart_rate)} , &nbsp;
                    <b>Resp Rate : </b>&nbsp;
                    {Math.round(visitSummary.vitals[0].respiratory_rate)}{' '}
                    ,&nbsp;
                    <b>BMI : </b>&nbsp;
                    {visitSummary.vitals[0].bmi_index} , &nbsp;<b>Temp: </b>
                    &nbsp;
                    {visitSummary.vitals[0].temperature}
                  </p>
                ) : (
                  ''
                )}
                <p>
                  <span>Purpose of Consultation:</span>{' '}
                  {visitSummary.purpose ? visitSummary.purpose : 'NA'}
                </p>
                <p>
                  <span>Chief Complaints / Observations :</span>{' '}
                  {visitSummary &&
                  visitSummary.diagnosis &&
                  visitSummary.diagnosis.observations
                    ? visitSummary.diagnosis.observations
                    : 'NA'}
                </p>
                <p>
                  <span>Diagnosis :</span>{' '}
                  {visitSummary &&
                  visitSummary.diagnosis &&
                  visitSummary.diagnosis.diagnosis_comment
                    ? visitSummary.diagnosis.diagnosis_comment
                    : 'NA'}
                </p>
                <p>
                  <span>Clinical Notes :</span>{' '}
                  {visitSummary &&
                  visitSummary.diagnosis &&
                  visitSummary.diagnosis.clinical_notes
                    ? visitSummary.diagnosis.clinical_notes
                    : 'NA'}
                </p>
                <p>
                  <span>Suggestion/Instructions:</span>{' '}
                  {visitSummary &&
                  visitSummary.diagnosis &&
                  visitSummary.diagnosis.suggestions
                    ? visitSummary.diagnosis.suggestions
                    : 'NA'}
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.SummaryText}>
                <h3>Medication:</h3>
                {visitSummary &&
                visitSummary.medications &&
                visitSummary.medications.length !== 0
                  ? visitSummary.medications.map((item, index) => (
                      <div className={classes.indentText}>
                        <p>
                          {visitSummary.medications.length > 1
                            ? index + 1 + '. '
                            : ''}
                          Drugname : {item.drug_name ? item.drug_name : 'NA'}
                        </p>
                        <p>
                          Frequency: {item.frequency ? item.frequency : 'NA'}
                        </p>
                        <p>
                          Duration:{' '}
                          {item.duration ? item.duration + ' Days' : 'NA'}{' '}
                        </p>
                        <p>
                          Instructions:{' '}
                          {item.instructions ? item.instructions : 'NA'}
                        </p>
                      </div>
                    ))
                  : 'N/A'}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.SummaryText}>
                <h3>Lab Orders:</h3>

                {visitSummary &&
                visitSummary.labs &&
                visitSummary.labs.length !== 0
                  ? visitSummary.labs.map((item, index) => (
                      <div className={classes.indentText}>
                        <p>
                          {visitSummary.labs.length > 1 ? index + 1 + '. ' : ''}
                          Test name: {item.testname ? item.testname : 'NA'}
                        </p>
                        <p>
                          Instructions:{' '}
                          {item.lab_instructions ? item.lab_instructions : 'NA'}
                        </p>
                      </div>
                    ))
                  : 'N/A'}
              </Box>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Paper>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '15px',
    float: 'left',
    width: '100%',
    padding: '15px'
  },
  // LabReport: {
  //   marginTop: '40px'
  // },
  patientVital: {
    fontWeight: '500',
    fontSize: 26
  },
  SummaryText: {
    width: '100%',
    padding: '0 20px',
    '& p': {
      fontSize: '16px',
      display: 'flow-root',
      '& span': {
        width: '220px',
        fontWeight: 'bold'
      }
    },
    '& h3': {
      fontSize: '20px'
    },
    '& h2': {
      fontSize: '24px',
      marginTop: '0px'
    }
  },
  vitalName: {
    fontWeight: '400'
  },
  MedicationCol: {
    '& h3': {
      color: '#3f4b79',
      marginBottom: '0px'
    }
  },
  InfoRow: {
    marginBottom: '20px'
  },
  TableRow: {
    background: '#fff !important',
    borderBottom: '0px !important'
  },
  // TableRow2: {
  //   borderBottom: '0px !important',
  //   padding: '2px 0 !important'
  // },
  BtnRow: {
    display: 'flex'
  },
  InfoCol: {
    '& p': {
      fontSize: '14px',
      color: '#787878',
      marginBottom: '7px',
      '& span': {
        width: '200px',
        float: 'left'
      }
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: '600',
      color: '#414141',
      marginBottom: '20px'
    }
  },
  InfoColTop: {
    width: '100%',
    marginTop: '50px',
    display: 'flex',
    '@media (max-width:767px)': {
      marginTop: '20px'
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: '500',
      color: '#3f4b79',
      flexGrow: '10'
    }
  },
  EditBtn: {
    float: 'left',
    color: '#3f4b79',
    marginRight: '20px',
    '&:hover': {
      textDecoration: 'none',
      color: '#3f4b79'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  DeleteBtn: {
    float: 'left',
    color: '#c10505',
    marginRight: '20px',
    '&:hover': {
      textDecoration: 'none',
      color: '#c10505'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  AddBtn: {
    background: '#3f4b79',
    borderRadius: '50px',
    border: 'none',
    width: '115px',
    height: '30px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  DisabledAddBtn: {
    background: '#ADADAD',
    borderRadius: '50px',
    border: 'none',
    width: '115px',
    height: '30px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  customized: {
    padding: '0px'
  },
  TabContent: {
    '& .MuiTable-root': {
      width: '100%',
      overflowX: 'auto'
    },
    '@media (max-width:767px)': {
      width: '100%',
      overflowX: 'auto'
    }
  },
  table: {
    minWidth: '100%'
  },
  vitals: {
    float: 'left',
    width: '100%',
    fontWeight: 'normal'
  },
  dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },
  dashboard: {
    maxWidth: '75%',
    flexBasis: '75%',
    '@media (max-width:1024px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '35px'
    }
  },
  AreaChart: {
    '& svg rect': {
      fill: '#fff'
    }
  },
  ContentCol: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '15px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  PatientTop: {
    backgroundColor: '#f7f6f6',
    margin: '15px 0'
  },
  PatientInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '15px'
  },
  PatientData: {
    paddingRight: '20px',
    '& h2': {
      color: '#3f4b79',
      marginBottom: '15px',
      fontSize: '14px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  VisitedBtn: {
    textAlign: 'center',
    backgroundColor: '#3f4b79',
    borderRadius: '40px',
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#3f4b79',
      boxShadow: 'none'
    }
  },
  PatientInfoRight: {
    display: 'flex',
    flexDirection: 'flex-end',
    alignItems: 'center'
  },
  PatientStatus: {
    marginRight: '80px',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      marginBottom: '20px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  PatientProfile: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    padding: '5px',
    overflow: 'hidden',
    '& img': {
      width: '100%'
    }
  },
  TabBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '& button': {
      backgroundColor: '#f7f6f6',
      marginRight: '15px',
      textTransform: 'capitalize',
      color: '#3f4b79'
    }
  },
  PatientChart: {
    display: 'flex',
    marginBottom: '20px',
    width: '100%',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      width: '100%',
      marginRight: '30px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  GrapTop: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexWrap: 'wrap'
    },
    '& h4': {
      fontSize: '14px',
      color: '#414141',
      '& span': {
        fontSize: '12px',
        color: '#999'
      }
    }
  },
  BtnCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '75%'
  },
  ToggleBtn: {
    textTransform: 'capitalize',
    '& button': {
      textTransform: 'capitalize',
      padding: '3px 15px'
    }
  },
  ActiveBtn: {
    backgroundColor: '#eff7ff !important',
    borderColor: '#3d8ff7 !important',
    color: '#3d8ff7'
  },
  ChartBox: {
    backgroundColor: '#fff',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    borderRadius: '5px',
    marginBottom: '15px'
  },
  submitButton: {
    color: '#fff',
    borderRadius: '25px'
  },
  Input: {
    width: '100%'
  },
  Input1: {
    margin: theme.spacing(1),
    width: '70%'
  },

  InputCol: {
    width: '100%',
    display: 'flex',
    marginBottom: '0',
    justifyContent: 'center',
    '& p': {
      fontSize: '18px',
      lineHeight: '55px',
      // lineHeight: '40px',
      marginRight: '10px',
      width: '90px'
    }
  },
  ModalForm: {
    width: '100%',
    padding: '40px;'
  },
  ModalBox: {
    width: '500px',
    background: '#fff',
    padding: '0px',
    height: '600px',
    '& h2': {
      textAlign: 'center',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  DownloadBtn: {
    width: '120px',
    background: '#53caf1',
    height: '35px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '35px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '50px',
    border: 'none',
    cursor: 'pointer'
  },
  button: {
    height: 40,
    width: 40,
    padding: '8px',
    background: '#3f4b79',
    marginLeft: 'auto',
    alignSelf: 'right',
    float: 'right',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: '#3f4b79'
    }
  },
  vitalsCard: {
    marginTop: '10px',
    height: '60px',
    padding: '10px 15px',
    '& h5': {
      float: 'left'
    }
  },
  disabledIcon: {
    height: 40,
    width: 40,
    padding: '8px',
    background: '#ADADAD',
    marginLeft: 'auto',
    alignSelf: 'right',
    float: 'right',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: '#ADADAD'
    }
  },
  PdfBtn: {
    border: 'none',
    borderRadius: '40px',
    padding: '10px 20px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
    marginLeft: '10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  indentText: {
    padding: '5px 5px'
  }
}))

export default PatientVisitSummary
