import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Avatar,
  Fade,
  Backdrop,
  Modal,
  Paper,
  Box,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import * as moment from 'moment'
import ProfilePicImg from '../../images/profile_pic.png'
import VideoCall from '../../images/video-call2.svg'
import { CONSULTATION_TYPE } from '../../Constants'
import { DoctorStore, DoctorPublicStore } from '../../stores'
import PatientDetailsVitals from './PatientDetailVitals'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { filter } from 'lodash'

const PatientDetails = props => {
  const params = useParams()
  const classes = useStyles()
  const [meetingEnabled, setMeetingEnabled] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [paymentMode, setPaymentMode] = useState('CASH')
  const [statusType, setStatusType] = React.useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [statusText, setStatusText] = useState('')

  const { getPatientView } = DoctorStore.useStoreActions(actions => ({
    ...actions.patients
  }))

  const { updateAppointmentStatus } = DoctorPublicStore.useStoreActions(
    actions => ({
      ...actions.appointment
    })
  )

  const { updateApptStatus } = DoctorPublicStore.useStoreState(state => ({
    ...state.appointment
  }))

  const { patientView, patientVitals } = DoctorStore.useStoreState(state => ({
    ...state.patients
  }))

  const statusList = [
    {
      id: 0,
      value: '1',
      label: 'Booked',
      group_status: ['0']
    },
    {
      id: 1,
      value: '2',
      label: 'Confirmed',
      group_status: ['0']
    },
    {
      id: 2,
      value: '3',
      label: 'Checked-in',
      group_status: ['2']
    },
    {
      id: 3,
      value: '4',
      label: 'In-progress',
      group_status: ['3']
    },
    {
      id: 4,
      value: '5',
      label: 'Cancelled',
      group_status: ['0']
    },
    {
      id: 5,
      value: '6',
      label: 'No Show',
      group_status: ['0'] //['0', '1', '2']
    },
    {
      id: 6,
      value: '7',
      label: 'Completed',
      group_status: ['4']
    },
    {
      id: 7,
      value: '8',
      label: 'Rescheduled',
      group_status: ['0'] //['0', '1', '2']
    }
  ]

  useEffect(() => {
    getPatientView({
      patient_id: params.patient_id,
      appointment_id: params.appointment_id
    })
  }, [])

  useEffect(() => {
    setInterval(() => {
      const momentStartTime = moment(
        patientView.start_time + ' ' + patientView.appointment_date
      )
      const momentEndTime = moment(
        patientView.end_time + ' ' + patientView.appointment_date
      )
      if (patientView.appointment_date) {
        if (moment().isBetween(momentStartTime, momentEndTime)) {
          setMeetingEnabled(true)
        } else {
          setMeetingEnabled(false)
        }
      }
    }, 1000)
  }, [patientView])

  useEffect(() => {
    if (updateApptStatus === undefined) {
      //
    } else if (updateApptStatus?.statusCode === 500) {
      setOpenSnackBar(true)
      setSnackBarMessage(updateApptStatus?.message)
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage(updateApptStatus?.message)
      setmultiButton(false)
      setAlertType('success')
    }
  }, [updateApptStatus])

  useEffect(() => {
    if (patientView.status === '3' || patientView.status === '4') {
      setCompleted(false)
    } else {
      setCompleted(true)
    }
    setStatus(patientView.status)
  }, [patientView?.status])

  const [status, setStatus] = React.useState('')

  const filteredSortList = statusList.filter(function (item, i) {
    let sortedOptions =
      item.group_status.find(item => item === status) || item.value === status
    return sortedOptions
  })

  const [open, setOpen] = React.useState(false)
  const [completed, setCompleted] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const joinConsult = isAudioCall => {
    let audioStatus = isAudioCall ? isAudioCall : false
    localStorage.setItem('audio_call', audioStatus)
  }

  const handlePaymentModeChange = (event, value) => {
    event.preventDefault()
    console.log(value.props.value)
    setPaymentMode(event.target.value)
  }

  // const handlePaymentModeChange = React.useCallback((event)=>{
  //   console.log(event.target.value)
  //   setPaymentMode(event.target.value)
  // },[paymentMode])

  const statusChange = event => {
    let statusType = event.target.value
    setStatusType(statusType)
    setAlertOpen(true)
    if (statusType === '7' && patientView.is_payment_done === '1') {
      setStatusText(
        <>
          Please select the payment mode
          <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            style={{ width: '80%' }}
            defaultValue={paymentMode}
            onChange={handlePaymentModeChange}
            label="Select Doctor">
            <MenuItem value={'CREDIT-CARD'}>CREDIT-CARD</MenuItem>
            <MenuItem value={'DEBIT-CARD'}>DEBIT-CARD</MenuItem>
            <MenuItem value={'UPI'}>UPI</MenuItem>
            <MenuItem value={'CASH'}>CASH</MenuItem>
            <MenuItem value={'NET-BANKING'}>NET-BANKING</MenuItem>
          </Select>
        </>
      )
    } else if (statusType === '7') {
      setStatusText('Are you sure you want to mark the status as Completed?')
    } else {
      let selected = statusList.find(item => item.value === statusType)
      let statusString =
        'Are you sure you want to mark the status as ' + selected.label + '?'
      setStatusText(statusString)
    }
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }
  const validationCheck = e => {
    setAlertOpen(false)
    if (statusType === '7') {
      updateAppointmentStatus({
        appointment_id: params.appointment_id,
        status: statusType,
        patient_id: params.patient_id,
        followup_recommendation: '2',
        offline_payment_mode: paymentMode,
        value: 1,
        timeline_type: 'day'
      })
      setCompleted(true)
      setStatus(statusType)
    } else if (statusType === '1' || statusType === '2') {
      updateAppointmentStatus({
        appointment_id: params.appointment_id,
        status: statusType,
        patient_id: params.patient_id,
        followup_recommendation: '2',
        value: 1,
        timeline_type: 'day'
      })
      setCompleted(true)
      setStatus(statusType)
    } else if (statusType === '3' && patientView.is_payment_done === '1') {
      updateAppointmentStatus({
        appointment_id: params.appointment_id,
        status: statusType,
        patient_id: params.patient_id,
        followup_recommendation: '2',
        value: 1,
        timeline_type: 'day'
      })
      setCompleted(false)
      setStatus(statusType)
    } else {
      updateAppointmentStatus({
        appointment_id: params.appointment_id,
        status: statusType,
        patient_id: params.patient_id,
        followup_recommendation: '2',
        value: 1,
        timeline_type: 'day'
      })
      setCompleted(false)
      setStatus(statusType)
    }
  }

  return (
    <Box className={classes.ContentCol}>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">Confirm Status</DialogTitle>
        <DialogContent>
          <DialogContentText>{statusText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAlertClose} color="primary">
            Cancel
          </Button>
          <Button onClick={validationCheck} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.PatientInfoCol}>
        <Box className={classes.PatientTop}>
          <Paper elevation={3} className={classes.paper}>
            <Grid
              container
              spacing={3}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%'
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '30%'
                  }}>
                  <Box
                    className={
                      patientView.appointment_type ===
                      CONSULTATION_TYPE.TELE.value
                        ? classes.PatientProfile
                        : classes.PatientProfileWithoutButton
                    }>
                    <Avatar
                      className={classes.AvatarCol}
                      src={
                        patientView && patientView.photo_uri
                          ? patientView.photo_uri
                          : ProfilePicImg
                      }
                    />
                  </Box>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    <Typography
                      component="h2"
                      style={{
                        color: '#000',
                        marginBottom: 5,
                        fontWeight: '500',
                        fontSize: '16px',
                        fontFamily: 'Poppins',
                        marginTop: 10
                      }}>
                      <span>
                        {patientView && patientView.first_name ? (
                          <>{patientView.first_name}</>
                        ) : (
                          <>---</>
                        )}{' '}
                        {patientView && patientView.middle_name}{' '}
                        {patientView && patientView.last_name}
                      </span>
                    </Typography>
                    <Typography component="h2" style={{ fontSize: 13 }}>
                      <span>
                        {patientView && patientView.gender ? (
                          <>
                            {patientView.gender === 'male'
                              ? 'Male'
                              : patientView.gender === 'female'
                              ? 'Female'
                              : patientView?.gender}
                          </>
                        ) : (
                          <>---</>
                        )}
                      </span>
                    </Typography>
                  </div>
                </div>
                <div style={{ width: '26.5%' }}>
                  <p
                    style={{
                      marginRight: '10px',
                      marginTop: 0,
                      marginBottom: 5,
                      color: 'rgba(0,0,0,0.6)',
                      fontSize: 14,
                      fontWeight: 400
                    }}>
                    Status
                  </p>
                  <Select
                    disabled={
                      patientView.appointment_type ===
                        CONSULTATION_TYPE.TELE.value && status !== '4'
                    }
                    defaultValue={patientView.status}
                    onChange={statusChange}
                    value={status}
                    style={{
                      width: '90%',
                      height: 40,
                      background: '#fff',
                      borderRadius: 7,
                      padding: '0 15px',
                      border: '1px #3267F1 solid'
                    }}>
                    {filteredSortList.map(({ id, value, label }) => (
                      <MenuItem key={id} value={value}>
                        <span style={{ color: '#98bb36' }}>
                          {label}{' '}
                          {value !== '7' && patientView?.is_payment_done === '1'
                            ? '(Not Paid)'
                            : ''}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  <Button variant="contained" className={classes.OnlineBtn}>
                    {patientView &&
                    patientView.appointment_type ===
                      CONSULTATION_TYPE.IN_PERSON.value
                      ? CONSULTATION_TYPE.IN_PERSON.label
                      : CONSULTATION_TYPE.TELE.label}
                  </Button>
                  <Box>
                    {patientView &&
                    patientView.appointment_type ===
                      CONSULTATION_TYPE.TELE.value ? (
                      <Box className={classes.BtnRow}>
                        <button
                          className={
                            (status === '2' ||
                              status === '3' ||
                              status === '4') &&
                            meetingEnabled
                              ? classes.TeleBtn
                              : classes.TeleBtnDisabled
                          }
                          onClick={handleOpen}
                          disabled={
                            (status !== '2' &&
                              status !== '3' &&
                              status !== '4') ||
                            !meetingEnabled
                          }>
                          <img
                            src={VideoCall}
                            alt=""
                            style={{ marginRight: 5 }}
                          />{' '}
                          Start Tele-Consult
                        </button>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  paddingLeft: 80,
                  marginTop: 20
                }}>
                <Box className={classes.PatientData} style={{ width: '44%' }}>
                  <p>Appointment Number:</p>
                  <Typography component="h2">
                    <span>
                      {patientView && patientView.appointment_number ? (
                        <>{patientView.appointment_number}</>
                      ) : (
                        <>---</>
                      )}
                    </span>
                  </Typography>
                </Box>

                <Box className={classes.PatientData} style={{ width: '55%' }}>
                  <p>Consultation Date & Time:</p>
                  <Box style={{ display: 'flex' }}>
                    <Typography
                      component="h2"
                      style={{
                        marginBottom: '0px',
                        marginRight: '10px',
                        textAlign: 'left'
                      }}>
                      {' '}
                      {patientView && patientView.appointment_date ? (
                        <span>
                          {moment(patientView.appointment_date).format(
                            'DD/MM/YYYY'
                          )}
                        </span>
                      ) : (
                        <>---</>
                      )}
                    </Typography>
                    <Typography component="h2" style={{ marginBottom: '0px' }}>
                      {' '}
                      {patientView.start_time && patientView.end_time ? (
                        <span>
                          {moment(patientView.start_time, 'hh:mm:ss').format(
                            'hh:mm A'
                          )}{' '}
                          -{' '}
                          {moment(patientView.end_time, 'hh:mm:ss').format(
                            'hh:mm A'
                          )}
                        </span>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.PatientData} style={{ width: '90%' }}>
                  <p>Purpose</p>
                  <Typography
                    component="h2"
                    style={{ fontFamily: 'Poppins', marginBottom: '0px' }}>
                    <span
                      style={{
                        fontFamily: 'Poppins',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {patientView && patientView.purpose ? (
                        <>{patientView.purpose}</>
                      ) : (
                        <>---</>
                      )}
                    </span>
                  </Typography>
                </Box>
              </div>
              {/* <Grid item xs={12} md={5} className={classes.PatientInforight}>
                <Box className={classes.PatientInfoRight}>
                  <Box>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '5px'
                      }}>
                      
                    </Box>
                    <Box
                      className={classes.PatientStatus}
                      style={{ display: 'flex' }}>
                      <p style={{ marginRight: '10px', marginTop: '5px' }}>
                        Status
                      </p>
                      <Box className={classes.CountryCol}>
                       
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid> */}
              {/* <Grid item xs={12} md={7} className={classes.PatientInfoleft}>
                <Box className={classes.PatientInfo}> */}
              {/* <Box className={classes.PatientData}>
                    <Typography component="h2">
                      Appointment Number:
                      <span>
                        {patientView && patientView.appointment_number ? (
                          <>{patientView.appointment_number}</>
                        ) : (
                          <>---</>
                        )}
                      </span>
                    </Typography>
                  </Box> */}
              {/* <Box className={classes.PatientData}>
                   
                  </Box> */}
              {/* <Box className={classes.PatientData}>
                    <Typography component="h2">
                      DOB
                      <span>
                        {patientView && patientView.dob ? (
                          <>{moment(patientView.dob).format('DD-MM-YYYY')}</>
                        ) : (
                          <>---</>
                        )}
                      </span>
                    </Typography>
                  </Box> */}

              {/* </Box>
              </Grid> */}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title">
                <DialogTitle
                  id="responsive-dialog-title"
                  style={{ fontSize: 14 }}>
                  You're about to join a tele-consult with patient
                </DialogTitle>
                <DialogContent>
                  <div className={classes.ModalBody}>
                    <h4>
                      <span>
                        {patientView && patientView.first_name ? (
                          <>{patientView.first_name}</>
                        ) : (
                          <>---</>
                        )}{' '}
                        {patientView && patientView.middle_name}{' '}
                        {patientView && patientView.last_name}
                      </span>
                    </h4>
                    <h5>Scheduled on</h5>
                    <h6>
                      <p style={{ fontSize: 15 }}>
                        Date:{' '}
                        {patientView && patientView.appointment_date
                          ? moment(patientView.appointment_date).format(
                              'DD-MM-YYYY'
                            )
                          : ''}
                      </p>
                      <p style={{ fontSize: 15 }}>
                        Time:{' '}
                        {moment(patientView.start_time, 'hh:mm:ss').format(
                          'hh:mm A'
                        )}{' '}
                        -{' '}
                        {moment(patientView.end_time, 'hh:mm:ss').format(
                          'hh:mm A'
                        )}
                      </p>
                    </h6>
                    <Link
                      to={{
                        pathname:
                          '/doctor/patient/teleconsult' +
                          `/${params.appointment_id}` +
                          `/${params.patient_id}`
                      }}>
                      <button
                        className={classes.JoinBtn}
                        onClick={() => joinConsult(false)}>
                        Join with Video
                      </button>
                    </Link>
                    <Link
                      to={{
                        pathname:
                          '/doctor/patient/teleconsult' +
                          `/${params.appointment_id}` +
                          `/${params.patient_id}`
                      }}>
                      <button
                        className={classes.JoinBtn}
                        onClick={() => joinConsult(true)}>
                        Join with Audio
                      </button>
                    </Link>
                  </div>
                </DialogContent>
              </Dialog>
            </Grid>
          </Paper>
          <PatientDetailsVitals
            completed={completed}
            patientView={patientView}
          />
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: '15px',
    float: 'left',
    width: '100%',
    padding: '30px',
    borderRadius: '15px',
    fontFamily: 'Poppins',
    backgroundColor: '#DDE8FF',
    backgroundSize: 'cover',
    boxShadow: 'none'
  },
  PatientInfoleft: {
    '@media (max-width:767px)': {
      order: '2'
    }
  },
  vitals: {
    float: 'left',
    width: '100%',
    fontWeight: 'normal'
  },
  dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  AvatarCol: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  CloseBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: '#3f4b79',
    fontSize: '20px',
    fontFamily: 'Poppins',
    '&:hover': {
      color: '#000'
    }
  },
  JoinBtn: {
    width: '250px',
    height: '50px',
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
    borderRadius: '8px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 600,
    border: 'none',
    display: 'block',
    margin: '15px auto',
    fontFamily: 'Poppins',
    '@media (max-width:767px)': {
      width: '100%'
    },
    '&:hover': {
      backgroundColor: '#000',
      cursor: 'pointer'
    }
  },
  BtnRow: {
    width: '100%'
  },
  TeleBtn: {
    border: '1px #92D487 solid',
    cursor: 'pointer',
    background: '#9FF092',
    borderRadius: '7px',
    padding: '0px 40px',
    color: '#000',
    fontSize: '14px',
    fontWeight: 500,
    marginTop: '10px',
    fontFamily: 'Poppins',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  TeleBtnDisabled: {
    border: 'none',
    background: '#cdd1d9',
    borderRadius: '7px',
    padding: '0 40px',
    color: '#000',
    fontSize: '14px',
    fontWeight: '500',
    height: '40px',
    marginTop: '0',
    fontFamily: 'Poppins',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      filter: 'grayscale(1)',
      marginRight: 10
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999',
    fontFamily: 'Poppins',
    '& .makeStyles-modal-141': {
      zIndex: '999999999'
    }
  },
  ModalBody: {
    backgroundColor: '#fff',
    padding: '20px',
    textAlign: 'center',
    position: 'relative',
    '& p': {
      '@media (max-width:767px)': {
        fontSize: '14px'
      }
    },
    '@media (max-width:767px)': {
      width: '98%'
    },
    '& h2': {
      fontWeight: 'bold',
      fontSize: '20px',
      fontFamily: 'Poppins',
      '@media (max-width:767px)': {
        fontSize: '16px',
        padding: '0 5%'
      }
    },
    '& h5': {
      fontSize: '20px',
      fontWeight: 'bold',
      fontFamily: 'Poppins',
      color: '#000',
      '@media (max-width:767px)': {
        fontSize: '16px'
      }
    },
    '& h6': {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#3f4b79',
      marginBottom: '30px',
      fontFamily: 'Poppins',
      '@media (max-width:767px)': {
        fontSize: '16px'
      }
    },
    '& h4': {
      fontSize: '24px',
      color: '#3f4b79',
      fontWeight: 'bold',
      '@media (max-width:767px)': {
        fontSize: '18px'
      }
    }
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },
  dashboard: {
    maxWidth: '75%',
    flexBasis: '75%',
    '@media (max-width:1024px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '15px'
    }
  },
  AreaChart: {
    '& svg rect': {
      fill: '#fff'
    }
  },
  ContentCol: {
    width: '96%',
    padding: '10px 5px',
    margin: '0px auto',
    display: 'flex',
    fontFamily: 'Poppins',
    flexDirection: 'column',
    '@media (max-width:767px)': {
      marginTop: '0px',
      paddingTop: '0px',
      fontFamily: 'Poppins'
    }
  },
  PatientTop: {
    backgroundColor: '#f7f6f6',
    margin: '15px 0',
    fontFamily: 'Poppins'
  },
  PatientInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '15px',
    fontFamily: 'Poppins'
  },
  PatientData: {
    paddingRight: 0,
    '& p': {
      color: 'rgba(0,0,0,0.6)',
      margin: '0 0 5px',
      fontSize: '14px',
      fontWeight: '400'
    },
    '& h2': {
      color: '#000',
      marginBottom: '0px',
      fontSize: '13px',
      fontFamily: 'Poppins',
      '& span': {
        color: '#000',
        display: 'block',
        fontFamily: 'Poppins'
      }
    }
  },
  VisitedBtn: {
    textAlign: 'center',
    backgroundColor: '#373737',
    borderRadius: '40px',
    fontFamily: 'Poppins',
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#3f4b79',
      boxShadow: 'none'
    }
  },
  OnlineBtn: {
    borderRadius: '0px',
    color: 'rgba(0,0,0,0.6)',
    marginBottom: '5px',
    boxShadow: 'none',
    padding: '0px',
    textTransform: 'capitalize',
    pointerEvents: 'none',
    backgroundColor: 'transparent',
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400
  },
  PatientInfoRight: {
    display: 'flex',
    flexDirection: 'flex-end',
    alignItems: 'center',
    borderRight: '2px dashed rgba(255,255,255,0.5)',
    paddingBottom: '0px',
    fontFamily: 'Poppins',
    '@media (max-width:767px)': {
      borderRight: '0px'
    }
  },
  PatientStatus: {
    marginRight: '10px',
    fontFamily: 'Poppins',
    '& .MuiSelect-icon path': {
      fill: '#ccc'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px rgba(255,255,255,0.5) solid'
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px rgba(255,255,255,0.5) solid'
    },
    '& p': {
      color: '#b6b5b9',
      margin: '0px',
      fontSize: '14px',
      fontWeight: '600'
    },
    '@media (max-width:1199px)': {
      marginRight: '20px'
    },
    '@media (max-width:767px)': {
      order: '2',
      marginRight: '0px',
      marginLeft: '0'
    },
    '& h3': {
      fontSize: '14px',
      color: '#a5a5a5',
      marginBottom: '20px',
      fontFamily: 'Poppins',
      '& span': {
        color: '#ffffff',
        display: 'block',
        fontFamily: 'Poppins'
      }
    }
  },
  PatientProfile: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: '#fff0',
    marginRight: '20px',
    padding: '5px',
    '&>div': {
      width: '60px',
      height: '60px',
      border: '4px solid #f8f8f8',
      '@media (max-width:767px)': {
        width: '60px',
        height: '60px'
      }
    },
    '& img': {
      width: '100%'
    }
  },
  PatientProfileWithoutButton: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    marginRight: '20px',
    padding: '5px',

    '& img': {
      width: '100%'
    }
  },
  TabBar: {
    backgroundColor: '#fff',
    fontFamily: 'Poppins',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '& button': {
      backgroundColor: '#f7f6f6',
      marginRight: '15px',
      textTransform: 'capitalize',
      color: '#3f4b79'
    }
  },
  PatientChart: {
    display: 'flex',
    marginBottom: '20px',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      marginRight: '30px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  GrapTop: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexWrap: 'wrap',
      flexDirection: 'column'
    },
    '& h4': {
      fontSize: '14px',
      color: '#414141',
      '& span': {
        fontSize: '12px',
        color: '#999'
      }
    }
  },
  icon: {
    cursor: 'pointer'
  },
  BtnCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '75%'
  },
  ToggleBtn: {
    textTransform: 'capitalize',
    '& button': {
      textTransform: 'capitalize',
      padding: '3px 15px'
    }
  },
  ActiveBtn: {
    backgroundColor: '#eff7ff !important',
    borderColor: '#3d8ff7 !important',
    color: '#3d8ff7'
  },
  ChartBox: {
    backgroundColor: '#fff',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    borderRadius: '5px',
    marginBottom: '15px'
  },
  submitButton: {
    color: '#fff',
    borderRadius: '25px'
  }
}))

export default PatientDetails
