import React from 'react'
import Chart from 'react-apexcharts'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  barChart: {
    minWidth: '-webkit-fill-available'
  }
}))

const BarChartTwo = ({ doctors, earnings }) => {
  const classes = useStyles()
  const options = {
    chart: {
      height: 250,
      width: '100%',
      type: 'bar',
      toolbar:{
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    redrawOnParentResize: true,
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    // title: {
    //   text: 'Top 5 Revenue Generators',
    //   align: 'left'
    // },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep'
      ],
      labels: {
        minWidth: 400
      }
    }
  }

  const series = [
    {
      name: 'Desktops',
      data: earnings
    }
  ]

  return (
    <Chart
      className={classes.barChart}
      options={options}
      series={series}
      type="bar"
      height={250}
    />
  )
}

export default BarChartTwo
