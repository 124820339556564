import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { Message, PhoneCallback } from '@material-ui/icons'

const ResendOTPDialogBox = ({
  isOpen = false,
  handleClose = () => {},
  resendOTPViaValue = 'text',
  updateResendOTPViaValue = () => {},
  onClickResendNow = () => {}
}) => {
  const classes = useStyles()

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle style={{ paddingTop: '1rem', paddingBottom: '0' }}>
        Resend OTP
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          value={resendOTPViaValue}
          onChange={event => updateResendOTPViaValue(event.target.value)}
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gridColumnGap: '2rem',
            columnGap: '2rem'
          }}>
          <FormControlLabel
            value="text"
            control={<Radio color="primary" />}
            label={
              <div
                style={{
                  display: 'inline-grid',
                  gridAutoFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gridColumnGap: '0.5rem',
                  columnGap: '0.5rem'
                }}>
                <span>Text</span>
                <Message style={{ color: 'currentcolor' }} />
              </div>
            }
            classes={{
              label: resendOTPViaValue === 'text' ? classes.labelColor : ''
            }}
          />
          <FormControlLabel
            value="voice"
            control={<Radio color="primary" />}
            label={
              <div
                style={{
                  display: 'inline-grid',
                  gridAutoFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gridColumnGap: '0.5rem',
                  columnGap: '0.5rem'
                }}>
                <span>Voice</span>
                <PhoneCallback
                  style={{ marginLeft: '-0.3rem', color: 'currentcolor' }}
                />
              </div>
            }
            classes={{
              label: resendOTPViaValue === 'voice' ? classes.labelColor : ''
            }}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justify="flex-end"
          style={{ padding: '0 1rem 0.5rem 1rem' }}>
          <Grid item>
            <Button
              onClick={() => {
                handleClose()
                onClickResendNow()
              }}
              style={{
                height: '1rem',
                fontSize: 'inherit',
                color: '#091c2e',
                lineHeight: 'inherit',
                fontFamily: 'inherit'
              }}
              color="secondary">
              Resend now
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ResendOTPDialogBox

const useStyles = makeStyles(theme => ({
  labelColor: {
    color: `rgb(17, 29, 78) !important`
  }
}))
