import { Layout } from 'antd'
import React from 'react'
import PublicNavbar from '../components/CommonComponents/PublicNavbar'
import { makeStyles, Grid, Box } from '@material-ui/core'
import DoctorProfile1 from '../images/doctor1.png'
import DoctorProfile2 from '../images/doctor2.png'
import DoctorProfile3 from '../images/doctor3.png'
import { Link } from 'react-router-dom'

const DoctorList = props => {
  const classes = useStyles()

  return (
    <div>
      <Layout>
        <PublicNavbar />
        <Grid container spacing={0} className={classes.Heademain}>
          <Box className="doctor-col">
            <div className="doctorlist-top">
              <img src={DoctorProfile1} alt="" />
              <div>
                <h6>Dr. Ankit Saha</h6>
                <p>MBBS, MD, Cardiologist</p>
                <p>English & Hindi</p>
              </div>
            </div>
            <div className="doctorlist-bottom">
              <div>
                <p className="fees">₹0</p>
                <p className="consult-text">Consultation Fees</p>
              </div>

              <Link to="/ankit-saha" className="Consult-btn">
                Book Consult
              </Link>
            </div>
          </Box>
          <Box className="doctor-col">
            <div className="doctorlist-top">
              <img src={DoctorProfile2} alt="" />
              <div>
                <h6>Dr. Zahid Hussain</h6>
                <p>MBBS, FRCS (UK), General Medicine</p>
                <p>English</p>
              </div>
            </div>
            <div className="doctorlist-bottom">
              <div>
                <p className="fees">₹0</p>
                <p className="consult-text">Consultation Fees</p>
              </div>

              <Link to="/zahid-hussain" className="Consult-btn">
                Book Consult
              </Link>
            </div>
          </Box>
          <Box className="doctor-col">
            <div className="doctorlist-top">
              <img src={DoctorProfile3} alt="" />
              <div>
                <h6>Dr. Subhabrata Paul</h6>
                <p>MBBS, FRCS (UK), Cardiologist</p>
                <p>English, Hindi & Bengali</p>
              </div>
            </div>
            <div className="doctorlist-bottom">
              <div>
                <p className="fees">₹0</p>
                <p className="consult-text">Consultation Fees</p>
              </div>

              <Link to="/dr-subhabrata" className="Consult-btn">
                Book Consult
              </Link>
            </div>
          </Box>
        </Grid>
      </Layout>
    </div>
  )
}
const useStyles = makeStyles(() => ({
  Heademain: {
    display: 'flex',
    position: 'relative',
    marginTop: 120,
    flexDirection: 'column',

    '@media (max-width:767px)': {
      marginTop: 90,
      flexDirection: 'column',
      padding: '0px 15px'
    }
  }
}))
export default DoctorList
