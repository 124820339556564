import React from 'react'
import VisitSummaries from './VisitSummaries'

const PatientVisitSummaries = props => {
  const { TabPanel, value } = props
  return (
    <React.Fragment>
      <TabPanel
        value={value}
        index={2}
        style={{
          background: '#fff',

          borderRadius: 15
        }}>
        <VisitSummaries />
      </TabPanel>
    </React.Fragment>
  )
}

export default PatientVisitSummaries
