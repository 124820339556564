import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import DoctorProtectedRoute from '../components/CommonComponents/DoctorProtectedRoute'
import {
  DoctorLogin,
  DoctorAppointmentsPage,
  DoctorDashboardPage,
  MySchedulePage,
  MyStaffsPage,
  NotFoundPage,
  OfflineDoctorProfileLayout,
  PatientDetailsPage,
  PatientsPage,
  CommunicationPage,
  TeleConsultPage,
  VirtualMRPage,
  CarenowSketch,
  Pricing,
  UpdateProfile,
  Account,
  DoctorInfo,
  Updateclinic,
  AddDoctor
} from '../pages'
import PaymentStepLayout from '../Clinic/views/PaymentStep/PaymentStepLayout'

import Subscription from '../pages/Doctor/Subscription'
import { DOCTOR_USER_ID } from '../Constants'

export default function DoctorRoutes() {
  localStorage.setItem('routing_mode', 'doctor')
  console.log(localStorage.getItem('routing_mode'))
  return (
    <Switch>
      <Route
        exact
        path="/doctor/login"
        render={() => {
          return localStorage.getItem(DOCTOR_USER_ID) ? (
            <Redirect to="/doctor/dashboard" />
          ) : (
            <DoctorLogin />
          )
        }}
      />
      <DoctorProtectedRoute>
        <Route exact path="/doctor/dashboard" component={DoctorDashboardPage} />
        <Route exact path="/doctor/sketch" component={CarenowSketch} />
        <Route
          exact
          path="/doctor/appointments"
          component={DoctorAppointmentsPage}
        />
        <Route exact path="/doctor/scheduler" component={MySchedulePage} />
        <Route
          exact
          path="/doctor/clinicalinsights"
          component={VirtualMRPage}
        />
        <Route exact path="/doctor/payment" component={PaymentStepLayout} />
        <Route exact path="/doctor/patients" component={PatientsPage} />
        <Route
          exact
          path="/doctor/communication"
          component={CommunicationPage}
        />
        <Route exact path="/doctor/staffs" component={MyStaffsPage} />
        <Route exact path="/doctor/updateprofile" component={UpdateProfile} />
        <Route
          exact
          path="/doctor/patient-details/:patient_id/:appointment_id"
          component={PatientDetailsPage}
        />
        <Route
          exact
          path="/doctor/patient/teleconsult/:appointment_id/:patient_id"
          component={TeleConsultPage}
        />
        <Route
          exact
          path="/doctor/profileCard"
          component={OfflineDoctorProfileLayout}
        />
        <Route exact path="/doctor/subscription" component={Subscription} />
        <Route exact path="/doctor/updateclinic" component={Updateclinic} />
        <Route exact path="/doctor/adddoctor" component={AddDoctor} />
        <Route exact path="/doctor/doctorinfo" component={DoctorInfo} />
        <Route exact path="/doctor/account" component={Account} />
        <Route exact path="/doctor/pricing" component={Pricing} />
        <Route exact path="/404/" component={NotFoundPage} />
      </DoctorProtectedRoute>
    </Switch>
  )
}
