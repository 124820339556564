var __extends =
  (this && this.__extends) ||
  (function () {
    var extendStatics = function (d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function (d, b) {
            d.__proto__ = b;
          }) ||
        function (d, b) {
          for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
        };
      return extendStatics(d, b);
    };
    return function (d, b) {
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype =
        b === null
          ? Object.create(b)
          : ((__.prototype = b.prototype), new __());
    };
  })();
var __decorate =
  (this && this.__decorate) ||
  function (decorators, target, key, desc) {
    var c = arguments.length,
      r =
        c < 3
          ? target
          : desc === null
          ? (desc = Object.getOwnPropertyDescriptor(target, key))
          : desc,
      d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
      r = Reflect.decorate(decorators, target, key, desc);
    else
      for (var i = decorators.length - 1; i >= 0; i--)
        if ((d = decorators[i]))
          r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
  };
import { Property, ChildProperty, Complex } from "cn_scheduler/base";
import { Field } from "./fields";
/**
 * Holds the configuration of event related options and dataSource binding to Schedule.
 */
var EventSettings = /** @class */ (function (_super) {
  __extends(EventSettings, _super);
  function EventSettings() {
    return (_super !== null && _super.apply(this, arguments)) || this;
  }
  __decorate([Property()], EventSettings.prototype, "template", void 0);
  __decorate([Property([])], EventSettings.prototype, "dataSource", void 0);
  __decorate([Property()], EventSettings.prototype, "query", void 0);
  __decorate([Complex({}, Field)], EventSettings.prototype, "fields", void 0);
  __decorate(
    [Property(false)],
    EventSettings.prototype,
    "enableTooltip",
    void 0
  );
  __decorate([Property()], EventSettings.prototype, "tooltipTemplate", void 0);
  __decorate(
    [Property()],
    EventSettings.prototype,
    "resourceColorField",
    void 0
  );
  __decorate(
    [Property(false)],
    EventSettings.prototype,
    "editFollowingEvents",
    void 0
  );
  __decorate([Property(true)], EventSettings.prototype, "allowAdding", void 0);
  __decorate([Property(true)], EventSettings.prototype, "allowEditing", void 0);
  __decorate(
    [Property(true)],
    EventSettings.prototype,
    "allowDeleting",
    void 0
  );
  __decorate(
    [Property(false)],
    EventSettings.prototype,
    "enableMaxHeight",
    void 0
  );
  __decorate(
    [Property(false)],
    EventSettings.prototype,
    "enableIndicator",
    void 0
  );
  __decorate(
    [Property(false)],
    EventSettings.prototype,
    "ignoreWhitespace",
    void 0
  );
  return EventSettings;
})(ChildProperty);
export { EventSettings };
