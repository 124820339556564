import React from 'react'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TableContainer,
  Box,
  Typography
} from '@material-ui/core'
import { NETWORK_STATUS } from '../../Constants'
import { DoctorStore } from '../../stores'
import Loading from 'react-loading'
import Pagination from '@material-ui/lab/Pagination'
import NoRecordImg from '../../images/norecord.png'
import Swal from 'sweetalert2'
import moment from 'moment'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles(() => ({
  userForm: {
    width: '100%',
    padding: '0px'
  },
  updatebtn: {
    width: '7rem',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#101c48',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '@media (max-width:767px)': {
      width: '90%',
      marginBottom: '15px'
    },
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  cancelbtn: {
    width: '7rem',
    height: '40px',
    marginTop: '5px',
    borderRadius: '40px',
    backgroundColor: '#9b0843',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '@media (max-width:767px)': {
      width: '90%'
    },
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  requestbtn: {
    width: '200px',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#5a5f95',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  confirmbtn: {
    width: '48%',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#101c48',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  blancebox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    width: '40%'
  },
  btncol: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px'
  },
  tablecontent: {
    '& h6': {
      fontSize: '16px',
      padding: '15px 10px'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      margin: '0px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '0px',
      fontWeight: '400'
    },
    '& .MuiTableCell-head': {
      color: '#fff'
    },
    '& .MuiTableCell-root': {
      padding: '20px 10px'
    },
    '& .MuiPaper-rounded': {
      boxShadow: 'none !important',
      borderRadius: '0px !important'
    }
  },
  accountbox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    '& p': {
      fontSize: '20px',
      color: '#a2a2a2',
      margin: '0px'
    },

    '& h3': {
      fontSize: '40px',
      color: '#374370',
      margin: '0px',
      lineHeight: '40px'
    }
  },
  Searchcol: {
    '@media (max-width:767px)': {
      // marginBottom: '15px',
      position: 'relative',
      left: '0px'
    },
    display: 'flex',
    top: '-10px',
    left: '14px',
    position: 'relative'
  },
  container: {
    maxHeight: 440,
    display: 'block',
    // overflowY: "clip",
    marginTop: '5px',
    '@media (max-width:767px)': {
      marginTop: '-20px !important'
    }
  },
  formcol: {
    '@media (max-width:767px)': {
      margin: '0px !important'
    }
  },
  Rightcheckbox: {
    '@media (max-width:767px)': {
      textAlign: 'left !important',
      marginTop: '-25px',
      marginLeft: '-8px'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '18px',
    right: '0px'
  },

  inputElement: {
    width: '100%',
    borderWidth: 1,
    fontSize: '14px',
    '&>div': {
      '&::before': {
        borderWidth: '1px'
      }
    }
  },
  Input: {
    width: '100%'
  },
  modalPrimaryButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#fff',
    cursor: 'pointer',
    border: '1px solid #111d4e'
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#111d4e',
    cursor: 'pointer',
    border: '1px solid #e4e4e4'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  ModalForm: {
    width: '100%',
    padding: '40px',
    '@media (max-height:450px)': {
      padding: '20px',
      height: '250px',
      overflowY: 'auto'
    },
    '@media (max-width:767px)': {
      padding: '20px',
      height: '400px',
      overflowY: 'auto'
    }
  }
}))

export default function WalletRequests() {
  const classes = useStyles()
  const [contentPerPage, setContentPerPage] = React.useState(10)
  const [initialContent, setInitialContent] = React.useState(0)
  const [finalContent, setFinalContent] = React.useState(contentPerPage)
  const [requestsList, setRequestsList] = React.useState([])
  const [pageCount, setPageCount] = React.useState(1)
  const [currentPage, setCurrentPageNo] = React.useState(1)
  const [amount, setAmount] = React.useState('')
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [id, setId] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState('')

  // let amountSchema = yup.string().matches(/^[0-9]+$/, 'Must be only digits')

   let amountSchema = yup.number().typeError("Must be in digits")

  const {
    doctorWalletRequestList,
    doctorWalletRequestUpdate,
    doctorWalletRequestCancel,
    snackClear
  } = DoctorStore.useStoreActions(actions => ({
    ...actions.profile
  }))
  // Store State
  const {
    walletRequestList,
    networkStatus,
    walletUpdate,
    walletCancel
  } = DoctorStore.useStoreState(state => ({
    ...state.profile
  }))

  React.useEffect(() => {
    doctorWalletRequestList() //card or cash
    return () => {
      snackClear()
    }
  }, [])

  React.useEffect(() => {
    if (walletRequestList.length !== 0) {
      setRequestsList(walletRequestList?.doctor_payout_requests)
      setPageCount(walletRequestList?.total_pages_count)
    }
  }, [walletRequestList])

  React.useEffect(() => {
    if (walletUpdate === undefined) {
      //
    } else if (walletUpdate && walletUpdate.statusCode !== 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Error Occured')
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Wallet Request updated Successfully!')
      setmultiButton(false)
      setAlertType('success')
      doctorWalletRequestList()
    }
  }, [walletUpdate])

  React.useEffect(() => {
    if (walletCancel === undefined) {
      //
    } else if (walletCancel && walletCancel.statusCode !== 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Error Occured')
      setmultiButton(false)
      setAlertType('error')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Your Wallet Request has been canceled!')
      setmultiButton(false)
      setAlertType('success')
      doctorWalletRequestList()
    }
  }, [walletCancel])

  const onPaginationClick = (event, value) => {
    setCurrentPageNo(value)
    doctorWalletRequestList({ currentPage: value })
  }

  const Update = async value => {
    // const amount_id = await id
    if (value !== 'amount_id') {
      setId(await value)
      setOpen(true)
    } else {
      try {
        amountSchema.validateSync(amount)
        setError('')
        doctorWalletRequestUpdate({ id: id, amount: amount })
        setAmount("")
        setOpen(false)
      } catch (err) {
        setError(err.message)
      }
    }

    // const { value: text } = await Swal.fire({
    //   // input: 'textarea',
    //   // inputLabel: 'Amount',
    //   // inputPlaceholder: 'Enter the amount',
    //   // showCancelButton: true
    //   title: 'Enter the Amount',
    //   input: 'text',
    //   inputPlaceholder: 'Amount',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Confirm',
    //   showLoaderOnConfirm: true,
    // })
    // if (text) {
    //   doctorWalletRequestUpdate({ id: id, amount: text })
    // }
  }

  const Cancel = id => {
    setId(id)
    setOpenSnackBar(true)
    setSnackBarMessage("You won't be able to revert this!")
    setmultiButton(true)
    setAlertType('info')
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  const validationCheck = () => {
    setOpenSnackBar(false)
    doctorWalletRequestCancel({ id: id })
    setAmount('')
  }

  const amountChange = e => {
    setAmount(e.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Grid container spacing={3}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description">
        <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          Enter the amount
        </DialogTitle>
        <DialogContent>
          <Box className={classes.ModalForm}>
            <TextField
              type={'text'}
              className={classes.Input}
              label="Enter Amount"
              name="amount"
              placeholder="Enter Amount"
              value={amount}
              onChange={amountChange}
            />

            {error ? <Typography color={'error'}>{error}</Typography> : null}
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: '20px 40px' }}>
        <button
            onClick={() => Update('amount_id')}
            className={classes.modalPrimaryButton}>
            Add
          </button>
          <button
            onClick={handleClose}
            className={classes.modalSecondaryButton}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
        secondaryClick={validationCheck}
      />
      <Grid item xs={12} sm={12} md={12} className={classes.tablecontent}>
        <TableContainer className={classes.container}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow style={{ background: 'rgb(16 29 71)', color: '#fff' }}>
                <TableCell>
                  <h5>Amount Requested</h5>
                </TableCell>
                <TableCell>
                  <h5>Primary Bank Name</h5>
                </TableCell>
                <TableCell>
                  <h5>Status</h5>
                </TableCell>
                <TableCell>
                  <h5>Created At</h5>
                </TableCell>
                <TableCell>
                  <h5>Action</h5>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {networkStatus === NETWORK_STATUS.FETCHING ? (
                <Loading
                  type="cylon"
                  textAlign="center"
                  color="grey"
                  height={40}
                />
              ) : requestsList.length ? (
                <>
                  {requestsList
                    .slice(initialContent, finalContent)
                    .map((d, index) => {
                      return (
                        <TableRow key={index} className={classes.tablerow}>
                          <TableCell>{d.amount_requested}</TableCell>
                          <TableCell>{d.primary_bank_name}</TableCell>
                          <TableCell>
                            {d.status === '1'
                              ? 'Requested'
                              : d.status === '2'
                              ? 'Completed'
                              : 'Cancelled'}
                          </TableCell>
                          <TableCell>
                            {moment(d.created_at.split(' ')[0]).format(
                              'DD-MM-YYYY'
                            )}
                            <br />
                            {moment(
                              d.created_at.split(' ')[1],
                              'hh:mm:ss'
                            ).format('hh:mm A')}
                          </TableCell>
                          <TableCell>
                            {d.status === '1' ? (
                              <>
                                <button
                                  className={classes.updatebtn}
                                  onClick={() => Update(d.id)}
                                  style={{ cursor: 'pointer' }}>
                                  Update
                                </button>
                                <button
                                  className={classes.cancelbtn}
                                  onClick={() => Cancel(d.id)}
                                  style={{ cursor: 'pointer' }}>
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>NA</>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {networkStatus !== NETWORK_STATUS.FETCHING &&
        requestsList.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <img
              alt="No data"
              style={{ display: 'block', width: '200px' }}
              src={NoRecordImg}
            />
          </div>
        ) : (
          ''
        )}
        <Pagination
          count={pageCount}
          shape="rounded"
          onChange={onPaginationClick}
        />
      </Grid>
    </Grid>
  )
}
