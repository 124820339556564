import React, { useEffect, useState } from 'react'
import AppointmentListItem from './AppointmentListItem'
import { PatientStore } from '../../stores'
import moment from 'moment'
import {
  TextField,
  makeStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Grid
} from '@material-ui/core'
import {
  APPOINTMENT_TYPE,
  BOOKING_STATUS,
  VISIT_TYPE,
  LIST_TYPE,
  NETWORK_STATUS
} from '../../Constants'
import Pagination from '@material-ui/lab/Pagination'
import { FaSearch } from 'react-icons/fa'
import Loading from 'react-loading'
import NoRecordImg from '../../images/noRecordFound.png'
import useDebounce from '../../customHooks/useDebounce'

export default function FollowupAppointments() {
  const [searchKeyWord, setSearchKeyWord] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageCount, setPageCount] = React.useState(1)
  const debouncedSearchTerm = useDebounce(searchKeyWord, 1000)

  const { upcomingdata, networkStatus, totalPagesCount } =
    PatientStore.useStoreState(state => ({
      ...state.appointments
    }))
  const { getList } = PatientStore.useStoreActions(actions => ({
    ...actions.appointments
  }))

  //start filter code
  const [filter, setFilter] = React.useState({
    all: true,
    Tele: false,
    In_Person: false
  })
  const [filter2, setFilter2] = React.useState({
    upcoming: false,
    past: false
  })

  const classess = useStyless()

  useEffect(() => {
    getAppointmentList()
  }, [filter, filter2, debouncedSearchTerm, currentPage])

  useEffect(() => {
    setPageCount(totalPagesCount)
  }, [totalPagesCount])

  const onPaginationClick = (event, value) => {
    setCurrentPage(value)
  }

  const handleChange = e => {
    let value = e.target.checked
    if (!value && e.target.name === 'all') {
      return
    } else if (!value) {
      setFilter({
        all: true,
        Tele: false,
        In_Person: false
      })
    } else {
      onPaginationClick('', 1)
      let newFilter = filter
      Object.keys(filter).map(key => {
        newFilter[key] = false
      })
      setFilter({ ...newFilter, [e.target.name]: e.target.checked })
    }
  }

  const handleChange2 = e => {
    let value = e.target.checked
    if (!value) {
      setFilter2({
        upcoming: false,
        past: false
      })
    } else {
      onPaginationClick('', 1)
      let newFilter = filter2
      Object.keys(filter2).map(key => {
        newFilter[key] = false
      })
      setFilter2({ ...newFilter, [e.target.name]: e.target.checked })
    }
  }

  const getAppointmentList = () => {
    let filterResult = Object.keys(filter).find(key => filter[key] === true)
    let filter2Result = Object.keys(filter2).find(key => filter2[key] === true)
    let filterValue = Object.keys(APPOINTMENT_TYPE).find(
      key => APPOINTMENT_TYPE[key].label === filterResult
    )
    let filter2Value = Object.keys(LIST_TYPE).find(
      key => LIST_TYPE[key].label === filter2Result
    )
    if (filterValue || filter2Value) {
      let list_type = filter2Value ? LIST_TYPE[filter2Value].label : 'all'
      let appointment_type = filterValue
        ? APPOINTMENT_TYPE[filterValue].id
        : '0'
      getList({
        list_type: list_type,
        status: BOOKING_STATUS.ALL.value,
        visit_type: VISIT_TYPE.FOLLOW_UP.id,
        currentPage: currentPage,
        searchString: searchKeyWord,
        appointment_type: appointment_type,
        sorting: {
          appointment_date: 'DESC',
          start_time: 'DESC'
        }
      })
    }
  }

  const onTextChange = e => {
    setSearchKeyWord(e.target.value)
  }

  return (
    <div>
      <div className={classess.checkboxes}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  className={classess.formcol}
                  value="all"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleChange}
                      name="all"
                    />
                  }
                  label="All"
                  labelPlacement="end"
                  checked={filter.all}
                />
                <FormControlLabel
                  className={classess.formcol}
                  value="In_Person"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleChange}
                      name="In_Person"
                    />
                  }
                  label="In-Person"
                  labelPlacement="end"
                  checked={filter.In_Person}
                />
                <FormControlLabel
                  className={classess.formcol}
                  value="Tele"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleChange}
                      name="Tele"
                    />
                  }
                  label="Tele"
                  labelPlacement="end"
                  checked={filter.Tele}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            className={classess.Searchcol}
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <FormGroup aria-label="position" row style={{ width: '100%' }}>
                <FormControlLabel
                  className={classess.formcol}
                  style={{ width: '100%' }}
                  control={
                    <TextField
                      className={classess.inputElement}
                      onChange={onTextChange}
                      label="Search"
                      value={searchKeyWord}
                    />
                  }
                />
                <FormControlLabel
                  className={classess.SearchBtn}
                  control={<FaSearch onClick={onTextChange} />}
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  className={classess.formcol}
                  value="upcoming"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleChange2}
                      name="upcoming"
                    />
                  }
                  label="Upcoming"
                  labelPlacement="end"
                  checked={filter2.upcoming}
                />
                <FormControlLabel
                  className={classess.formcol}
                  value="past"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleChange2}
                      name="past"
                    />
                  }
                  label="Past"
                  labelPlacement="end"
                  checked={filter2.past}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loading
          type="bubbles"
          textAlign="center"
          color="#9138aa"
          height={50}
        />
      ) : upcomingdata.length ? (
        <>
          {upcomingdata.map((appointment, index) => {
            return (
              <AppointmentListItem
                appointment={appointment}
                isForFollowupBooking={false}
              />
            )
          })}
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <img
              alt="No data"
              style={{ display: 'block', width: '200px' }}
              src={NoRecordImg}
            />
          </div>
        </>
      )}
      <Pagination
        count={pageCount}
        shape="rounded"
        onChange={onPaginationClick}
      />
    </div>
  )
}
const useStyless = makeStyles(() => ({
  checkboxes: {
    marginTop: '15px',
    '& label > span': {
      fontSize: '14px'
    },
    '& span > svg': {
      width: '0.8em',
      height: '0.8em'
    }
  },
  SearchRow2: {
    width: '100%'
  },
  formcol: {
    '@media (max-width:767px)': {
      margin: '0px !important'
    }
  },
  SearchBtn: {
    position: 'absolute',
    top: '18px',
    right: '0px'
  },
  Searchcol: {
    width: '100% !important',
    '@media (max-width:767px)': {
      marginBottom: '15px'
    }
  },
  SearchRow: {
    width: '100%'
  },
  Rightcheckbox: {
    '@media (max-width:767px)': {
      textAlign: 'left !important'
    }
  },
  inputElement: {
    width: '100%',
    borderWidth: 1,
    fontSize: '14px',
    '& .MuiInput-input': {
      paddingRight: '30px'
    },
    '&>div': {
      '&::before': {
        borderWidth: '1px'
      }
    }
  }
}))
