import React from 'react'
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Checkbox
} from '@material-ui/core'
import { NETWORK_STATUS } from '../../Constants'
import { DoctorStore } from '../../stores'
import { initial } from 'lodash'
import { TextField, InputAdornment } from '@material-ui/core'
import Loading from 'react-loading'
import Pagination from '@material-ui/lab/Pagination'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import NoRecordImg from '../../images/norecord.png'
import { FaSearch } from 'react-icons/fa'
import { debounce } from 'lodash'
import useDebounce from '../../customHooks/useDebounce'
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(() => ({
  userForm: {
    width: '100%',
    padding: '0px'
  },
  requestbtn: {
    width: '200px',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#5a5f95',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  tablerow: {
    fontSize: 13,
    '& td': {
      border: 0,
      fontFamily: 'Poppins',
      fontSize: 13,
      padding: '10px 5px !important'
    },
    '&:nth-child(even)': {
      backgroundColor: '#F5F5F5'
    }
  },
  confirmbtn: {
    width: '48%',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#5a5f95',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  cancelbtn: {
    width: '48%',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#9b0843',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  blancebox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    width: '40%'
  },
  btncol: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px'
  },
  tablecontent: {
    background: '#fff',
    borderRadius: 15,
    '& h6': {
      fontSize: '16px',
      padding: '15px 10px'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      margin: '0px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '0px',
      fontWeight: '400'
    },
    '& .MuiTableCell-head': {
      color: '#fff'
    },
    '& .MuiTableCell-root': {
      padding: '20px 10px'
    },
    '& .MuiPaper-rounded': {
      boxShadow: 'none !important',
      borderRadius: '0px !important'
    }
  },
  accountbox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    '& p': {
      fontSize: '20px',
      color: '#a2a2a2',
      margin: '0px'
    },

    '& h3': {
      fontSize: '40px',
      color: '#374370',
      margin: '0px',
      lineHeight: '40px'
    }
  },
  Searchcol: {
    '@media (max-width:767px)': {
      // marginBottom: '15px',
      position: 'relative',
      left: '0px'
    },
    display: 'flex',
    top: '-10px',
    left: '14px',
    position: 'relative'
  },
  container: {
    maxHeight: 440,
    display: 'block',
    // overflowY: "clip",
    marginTop: '0px',
    '@media (max-width:767px)': {
      marginTop: '0px !important',
      maxHeight: 390
    }
  },
  formcol: {
    '@media (max-width:767px)': {
      margin: '0px !important'
    }
  },
  Rightcheckbox: {
    '@media (max-width:767px)': {
      textAlign: 'left !important',
      marginTop: '-25px',
      marginLeft: '-8px'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '18px',
    right: '0px'
  },
  inputElement: {
    width: '100%',
    borderWidth: 1,
    fontSize: '14px',
    '&>div': {
      '&::before': {
        borderWidth: '1px'
      }
    }
  },
  searchBar: {
    '& .MuiInputBase-input:focus': {
      border: 'none'
    }
  }
}))

export default function Transactionhistory() {
  const classes = useStyles()

  const [transactionList, setTransactionList] = React.useState([])
  const [pageCount, setPageCount] = React.useState(1)
  const [page, setPageNo] = React.useState(1)
  const [contentPerPage, setContentPerPage] = React.useState(10)
  const [initialContent, setInitialContent] = React.useState(0)
  const [finalContent, setFinalContent] = React.useState(contentPerPage)
  const [searchKeyWord, setSearchKeyWord] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchKeyWord, 1000)

  const [filter, setFilter] = React.useState({
    All: true,
    Card: false,
    Cash: false
  })

  const { getDoctorAppointmentTransactions } = DoctorStore.useStoreActions(
    actions => ({
      ...actions.profile
    })
  )
  let type = Object.keys(filter).toString().toLowerCase()
  // Store State
  const { doctorAppointmentTransactions, networkStatus } =
    DoctorStore.useStoreState(state => ({
      ...state.profile
    }))

  const handleChange = e => {
    setSearchKeyWord('')
    onPaginationClick('', 1)
    setFilter({ [e.target.name]: e.target.checked })
  }

  React.useEffect(() => {
    let filterType = filter.Card === true || filter.Cash === true ? type : 'All'
    getDoctorAppointmentTransactions({
      type: filterType,
      count: page,
      pattern: searchKeyWord
    })
  }, [filter, page, debouncedSearchTerm])

  React.useEffect(() => {
    if (doctorAppointmentTransactions?.appointments_details) {
      setTransactionList(doctorAppointmentTransactions?.appointments_details)
      setPageCount(doctorAppointmentTransactions?.total_pages_count)
    }
  }, [doctorAppointmentTransactions])

  const onPaginationClick = (event, value) => {
    setPageNo(value)
  }

  // search for transaction
  const onTextChange = e => {
    let eventData = e.target.value
    setSearchKeyWord(eventData)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3} className={classes.Searchcol}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormGroup aria-label="position" row style={{ width: '100%' }}>
            <FormControlLabel
              className={classes.formcol}
              style={{ width: '100%' }}
              control={
                <TextField
                  className={classes.inputElement}
                  onChange={onTextChange}
                  label="Search"
                  value={searchKeyWord}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              }
            />
            {/* <FormControlLabel
              className={classes.searchicon}
              control={<FaSearch />}
            /> */}
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        md={9}
        className={classes.Rightcheckbox}
        style={{ textAlign: 'right' }}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className={classes.formcol}
              value="All"
              control={
                <Checkbox color="primary" onChange={handleChange} name="All" />
              }
              label="All"
              labelPlacement="end"
              checked={filter.All}
            />
            <FormControlLabel
              className={classes.formcol}
              value="Card"
              control={
                <Checkbox color="primary" onChange={handleChange} name="Card" />
              }
              label="Online"
              labelPlacement="end"
              checked={filter.Card}
            />
            <FormControlLabel
              className={classes.formcol}
              value="Cash"
              control={
                <Checkbox color="primary" onChange={handleChange} name="Cash" />
              }
              label="Offline"
              labelPlacement="end"
              checked={filter.Cash}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        className={classes.tablecontent}
        style={{
          background: '#fff',
          borderRadius: 15,
          padding: '25px 20px 20px',
          boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)'
        }}>
        {/* <h6>Filter: All/Card/Cash</h6> */}

        <TableContainer className={classes.container}>
          <Table className={classes.table}>
            <TableHead stickyHeader aria-label="sticky table">
              <TableRow
                style={{
                  color: 'rgb(0,0,0,0.7)',
                  fontFamily: 'Poppins',
                  background: '#F5F5F5'
                }}>
                <TableCell>
                  <h5
                    style={{
                      color: 'rgb(0,0,0,0.7)',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      fontWeight: '500'
                    }}>
                    Appointment Number
                  </h5>
                </TableCell>
                <TableCell>
                  <h5
                    style={{
                      color: 'rgb(0,0,0,0.7)',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      fontWeight: '500'
                    }}>
                    Patient Name
                  </h5>
                </TableCell>
                <TableCell>
                  <h5
                    style={{
                      color: 'rgb(0,0,0,0.7)',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      fontWeight: '500'
                    }}>
                    Type
                  </h5>
                </TableCell>
                <TableCell>
                  <h5
                    style={{
                      color: 'rgb(0,0,0,0.7)',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      fontWeight: '500'
                    }}>
                    Date
                  </h5>
                </TableCell>
                <TableCell>
                  <h5
                    style={{
                      color: 'rgb(0,0,0,0.7)',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      fontWeight: '500'
                    }}>
                    Time
                  </h5>
                </TableCell>
                <TableCell>
                  <h5
                    style={{
                      color: 'rgb(0,0,0,0.7)',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      fontWeight: '500'
                    }}>
                    Amount Paid
                  </h5>
                </TableCell>
                <TableCell>
                  <h5
                    style={{
                      color: 'rgb(0,0,0,0.7)',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      fontWeight: '500'
                    }}>
                    GST
                  </h5>
                </TableCell>
                <TableCell>
                  <h5
                    style={{
                      color: 'rgb(0,0,0,0.7)',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      fontWeight: '500'
                    }}>
                    Amount Received
                  </h5>
                </TableCell>
                <TableCell>
                  <h5
                    style={{
                      color: 'rgb(0,0,0,0.7)',
                      fontSize: '13px',
                      fontFamily: 'Poppins',
                      fontWeight: '500'
                    }}>
                    Payment Mode
                  </h5>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {networkStatus === NETWORK_STATUS.FETCHING ? (
                <Loading
                  type="cylon"
                  textAlign="center"
                  color="grey"
                  height={40}
                />
              ) : transactionList.length ? (
                <>
                  {transactionList
                    .slice(initialContent, finalContent)
                    // .sort(function (a, b) {
                    //   if (a.date > b.date == true || a.date < b.date == true) {
                    //   } else {
                    //     if (
                    //       moment(a.time, 'HH:mm A ').format('HH:mm A') <
                    //       moment(b.time, 'HH:mm A').format('HH:mm A')
                    //     )
                    //       return 1
                    //     if (
                    //       moment(a.time, 'HH:mm A').format('HH:mm A') >
                    //       moment(b.time, 'HH:mm A').format('HH:mm A')
                    //     )
                    //       return -1
                    //     return 0
                    //   }
                    // })
                    .map((d, index) => {
                      return (
                        <TableRow key={index} className={classes.tablerow}>
                          <TableCell>{d.appointment_no}</TableCell>
                          <TableCell>{d.patient_name}</TableCell>
                          <TableCell>
                            {d.appointment_type === '1'
                              ? 'In-person Consult'
                              : 'Tele-Consult'}
                          </TableCell>
                          <TableCell>{d.date}</TableCell>
                          <TableCell>{d.time}</TableCell>
                          <TableCell>{d.amount_paid}</TableCell>
                          <TableCell>{d.gst}</TableCell>
                          <TableCell>{d.amount_recived}</TableCell>
                          <TableCell>
                            {d.payment_mode === '1'
                              ? 'Online Payment'
                              : 'Offline Payment'}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {networkStatus !== NETWORK_STATUS.FETCHING &&
        transactionList.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <img
              alt="No data"
              style={{ display: 'block', width: '200px' }}
              src={NoRecordImg}
            />
          </div>
        ) : (
          ''
        )}
        <Pagination
          style={{ marginTop: '15px' }}
          count={pageCount}
          shape="rounded"
          onChange={onPaginationClick}
        />
      </Grid>
    </Grid>
  )
}
