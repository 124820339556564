import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logoWhite from '../../images/logoWhite.png'
import compliance from '../../images/compliance.png'
import Grid from '@material-ui/core/Grid'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import { Link } from 'react-router-dom'
import { ADMIN_URL, HOME_URL } from '../../Environment'

const useStyles = makeStyles(() => ({
  Footer: {
    width: '100%',
    background: '#192852',
    padding: '20px 3%',
    color: '#fff'
  },
  logo: {
    maxWidth: '25%',
    marginTop: '5%'
  },
  compliance: {
    width: '100%'
  }
}))
export default function Footer() {
  const classes = useStyles()
  return (
    <div className={classes.Footer}>
      <Grid container>
        <Grid item md={3}>
          <img className={classes.logo} src={logoWhite} alt="logo" />
          <br />
          <p style={{ fontSize: '12px' }}>
            © All Rights Reserved | CareNow Healthcare
          </p>
        </Grid>
        <Grid item md={3}>
          <h4 style={{ color: '#841bb1', fontWeight: '500' }}>
            About CareNow HealthCare
          </h4>
          <p style={{ fontSize: '12px', width: '80%', textAlign: 'justify' }}>
            At CareNow Healthcare, we are transforming how people access
            healthcare around the globe—creating a new kind of healthcare
            experience—one with greater convenience, and valued outcome.
          </p>
          <small>www.carenow.healthcare</small>

          <h4
            style={{ color: '#841bb1', fontWeight: '500', marginTop: '30px' }}>
            Contact Us
          </h4>
          <p style={{ fontSize: '12px' }}>
            Tel: <a href="tel:+913341816533">033-418-16533</a>
            <br />
            Email : help@carenow.healthcare
          </p>
        </Grid>

        <Grid item md={2}>
          <h4 style={{ color: '#841bb1', fontWeight: '500' }}>Quick Links</h4>
          <Link to="/doctor/login">
            <p style={{ fontSize: '12px' }}>Doctor Login</p>
          </Link>
          <Link to="/patient/login">
            <p style={{ fontSize: '12px' }}>Patient Login</p>
          </Link>

          {/* <Link>
            <p style={{ fontSize: '12px' }}>Clinic Login</p>
          </Link> */}

          <h4
            style={{ color: '#841bb1', fontWeight: '500', marginTop: '30px' }}>
            Social Links
          </h4>
          <p style={{ fontSize: '12px' }}>
            {' '}
            <a target="_blank" href="https://www.facebook.com/carenowdoc/">
              <FacebookIcon />
            </a>{' '}
            <a
              target="_blank"
              href="https://www.instagram.com/carenow.healthcare/">
              <InstagramIcon />
            </a>{' '}
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCqJSrY_NEnauSr5Cc66G1AA">
              <YouTubeIcon />
            </a>{' '}
          </p>
        </Grid>

        <Grid item md={4}>
          <h4 style={{ color: '#841bb1', fontWeight: '500' }}>Compliances</h4>
          <img className={classes.compliance} src={compliance} alt="logo" />
          <br />

          <h4
            style={{ color: '#841bb1', fontWeight: '500', marginTop: '30px' }}>
            Helpful Links
          </h4>
          <p style={{ fontSize: '12px' }}>
            <a href="/termsofuse">Terms & Conditions</a>
            <br />
            <br />
            <a href={HOME_URL + 'privacy'}>Privacy Policy</a>
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
