import React from 'react'
import {
    Box,
    Grid,
    makeStyles,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    TextField
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import Navbar from '../../components/CommonComponents/Navbar'
import DashboardLayoutImg from '../../images/bg2.png'
import bannerimg from '../../images/banner-img.jpg'
import uploadimg from '../../images/logo-img.jpg'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Modal from '@material-ui/core/Modal'
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Dr. John Doe', <p>12/11/2020</p>, <p>12/11/2020</p>, <p>Micro Tier (Annual)</p>, <p><Link className="renew-btn" to="">Renew</Link><Link className="cancel-btn" to="">Cancel</Link></p>),
    createData('Dr. John Doe', <p>12/11/2020</p>, <p>12/11/2020</p>, <p>Micro Tier (Annual)</p>, <p><Link className="renew-btn" to="">Renew</Link><Link className="cancel-btn" to="">Cancel</Link></p>),
    createData('Dr. John Doe', <p>12/11/2020</p>, <p>12/11/2020</p>, <p>Micro Tier (Annual)</p>, <p><Link className="renew-btn" to="">Renew</Link><Link className="cancel-btn" to="">Cancel</Link></p>),
    createData('Dr. John Doe', <p>12/11/2020</p>, <p>12/11/2020</p>, <p>Micro Tier (Annual)</p>, <p><Link className="renew-btn" to="">Renew</Link><Link className="cancel-btn" to="">Cancel</Link></p>),
    createData('Dr. John Doe', <p>12/11/2020</p>, <p>12/11/2020</p>, <p>Micro Tier (Annual)</p>, <p><Link className="renew-btn" to="">Renew</Link><Link className="cancel-btn" to="">Cancel</Link></p>),
];
function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
    };
}
export default function AddDoctor() {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.Modalbody}>
          <Box className={classes.Modalcontent}>
              <Link className="modal-close" onClick={handleClose}>x</Link>
              <h2 id="simple-modal-title">Are you sure you want to add a new doctor?</h2>
      <p id="simple-modal-description">
        If yes, confirm to send request to our onboarding team. Once a request is sent, our team will reach out to you within 24-48 hrs for doctor verification process and to confirm the subscription plan.
      </p>
              <Box className="modal-btn"><Link className="cancel-btn" to="">Cancel</Link><Link className="request-btn" to="">Confirm</Link></Box>
          </Box>
    </div>
  );
    return (
        <div className={classes.root}>
           <Navbar/>
                <Grid container className={classes.dashboardPage} spacing={3}>
                <Box className="bodycontent-col">
              <Grid item xs={12} sm={3} className="LeftSide">
                <h2>Dummy Clinic Name</h2>
                <ul className="Leftnav">
                    <li className="active"><Link to="">Our Doctors</Link></li>
                    <li><Link to="">Update Clinic Profile</Link></li>
                    <li><Link to="">Add Doctors</Link></li>
                    <li><Link to="">Account</Link></li>
                    <li><Link to="">Subscriptions</Link></li>
                    <li><Link to="">Logout</Link></li>
                </ul>
      
              </Grid>
               <Grid item xs={12} sm={9} className="Cardbox">
                <h3>Add Doctors</h3>
                <Box className="active-col">
                    <h3>Active Doctors <span>1</span></h3>
                    <h3>Total Doctors <span>5</span></h3>
                </Box>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Doctor Name</TableCell>
                                    <TableCell align="right" style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Added on</TableCell>
                                    <TableCell align="right" style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Subscriptions Ends</TableCell>
                                    <TableCell align="right" style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Plan</TableCell>
                                    <TableCell align="right" style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row" style={{fontFamily:'Poppins'}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right" style={{fontFamily:'Poppins'}}>{row.calories}</TableCell>
                                        <TableCell align="right" style={{fontFamily:'Poppins'}}>{row.fat}</TableCell>
                                        <TableCell align="right" style={{fontFamily:'Poppins'}}>{row.carbs}</TableCell>
                                        <TableCell align="right" style={{fontFamily:'Poppins'}}>{row.protein}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <button type="button" className="request-btn" onClick={handleOpen}>Request to add new doctor</button>
              </Grid>
              </Box>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}
const useStyles = makeStyles(theme => ({
    // root: {
    //   width: "100%",
    //   "& > * + *": {
    //     marginTop: theme.spacing(2),
    //   },
    // },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
            '@media (max-width:767px)': {
                width: '100% !important'
            }
        }
    },
    dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center',
    background: `url(${DashboardLayoutImg})`,
    backgroundAttachment: 'fixed',
    marginTop:'-105px'
  },
    Modalcontent:{
        width:'600px',
        background:'#fff',
        borderRadius:'10px',
        padding:'30px',
        '& p':{
            fontSize:'16px',
            color:'#333',
            fontWeight:'normal',
            lineHeight:'30px',
            marginBottom:'30px'
        },
        '& h2':{
            color:'#121d48',
            marginBottom:'20px'
        }
    },
    Modalbody:{
     display:'flex',
        flexDirection:'column',
        height:'100vh',
        justifyContent:'center',
        alignItems:'center'
    },
    table:{
        '& tr':{
            background:'transparent !important'
        },
        '& td':{
            borderBottom:'0px !important',
            color:'#7b7b7b !important',
            padding:'5px',
            '& p':{
                marginBottom:'0px'
            }
        },
        '& th': {
            borderBottom: '0px !important',
            color:'#121d48',
            padding:'5px'
        }
    },
    image: {
        width: '100%'
    },
    logo: {
        marginTop: '1rem',
        cursor: 'pointer',
        height: '80px'
    },
    doctorimg: {
        width: '100%'
    },
    Navbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}))
