import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../doctorLayouts'
import PatientsList from '../../components/MyPatients/PatientsList'

export default function Patients() {
  return (
    <DashboardLayout>
      <PatientsList />
    </DashboardLayout>
  )
}
