import React, { useEffect } from 'react'
import Loading from 'react-loading'
import AddIcon from '@material-ui/icons/Add'
import MuiAlert from '@material-ui/lab/Alert'
import { PATIENT_ID } from '../../Constants'
import Autocomplete from '@material-ui/lab/Autocomplete'
import localforage from 'localforage'
import { PatientStore } from '../../stores'
import { PublicPatientStore } from '../../stores'
import Loader from '../../components/CommonComponents/Loader'
import {
  Snackbar,
  Box,
  Typography,
  makeStyles,
  Chip,
  Paper,
  CircularProgress,
  Grid,
  Modal,
  Backdrop,
  Fade,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  FormLabel
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { VALIDATE_PHONE, VALIDATE_WHITESPACES } from '../../Constants'
import { useFormik } from 'formik'
import * as yup from 'yup'
import PatientAccList from './PatientAccList'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const SelectPatientComponent = props => {
  const classes = useStyles()

  const { getAccountsList, addPatientDetails } = PatientStore.useStoreActions(
    actions => ({
      ...actions.profile
    })
  )

  const { fetchCountryList, fetchStateList, fetchCityList, clearState } =
    PublicPatientStore.useStoreActions(actions => ({
      ...actions.appointment
    }))

  const { patientAccountsList, patientAddedStatus, networkStatus } =
    PatientStore.useStoreState(state => ({
      ...state.profile
    }))

  const {
    countryList,
    stateList,
    cityList,
    fetchingCountry,
    fetchingState,
    fetchingCity
  } = PublicPatientStore.useStoreState(state => ({
    ...state.appointment
  }))

  const patientListFetched = patientAccountsList

  useEffect(() => {}, [patientListFetched])

  useEffect(() => {
    getAccountsList({})
    fetchCountryList({})
  }, [])

  useEffect(() => {
    formik.resetForm()
  }, [patientAddedStatus])

  const handleClick = patient => {
    localStorage.setItem(PATIENT_ID, patient.id)
    localforage.setItem('selected_patient', patient)
    window.location.href = '/patient/dashboard'
  }
  const addPatient = () => {
    window.location.href = '/addPatient'
  }

  const logout = () => {
    localforage.clear().then(() => {
      localStorage.clear()
    })
    localStorage.clear()
    window.location.href = '/patient/login'
  }
  const [isShow, setShow] = React.useState(false)
  const [Country, setCountry] = React.useState({})
  const [State, setState] = React.useState({})
  const [city, setCity] = React.useState({})
  const [color, setColor] = React.useState('')
  const [snack, setSnack] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [inputDate, setDate] = React.useState('')

  const styles = { float: 'right', cursor: 'pointer', color: color }

  const datehandleChange = e => {
    setDate(e)
    formik.setFieldValue('dob', e)
  }

  const phoneChange = value => {
    setInputValue(value)
    formik.setFieldValue('mobile_no', value ? value : '')
  }

  const countryChange = (event, value) => {
    if (value == null) {
      setState('')
      setCountry('')
      setCity('')
      // clearState()
    } else {
      fetchStateList(value?.id)
      setCountry(value)
      // setCountrySelect(true);
      formik.setFieldValue('country_id', value?.id)
    }
  }

  const stateChange = (event, value) => {
    // setStateSelect(true);
    if (value == null) {
      setCity('')
      setState('')
      // clearState()
    } else {
      setState(value)
      setCity('')
      fetchCityList(value?.id)
      formik.setFieldValue('state_id', value?.id)
    }
  }

  const cityChange = (event, value) => {
    // setCitySelect(true);
    setCity(value)
    formik.setFieldValue('city_id', value?.id)
  }

  // useEffect(() => {
  //   if (Country?.id) {
  //     fetchStateList(Country?.id)
  //   }
  // }, [Country])

  // useEffect(() => {
  //   if (State?.id) {
  //     fetchCityList(State)
  //   }
  // }, [State])

  useEffect(() => {
    if (patientAddedStatus) {
      setSnack(true)
      setState('')
      setCountry('')
      setCity('')
    }
  }, [patientAddedStatus])

  const submit = async (values, { resetForm }) => {
    // event.preventDefault();
    addPatientDetails({
      ...values
    })
    formik.resetForm()
    setShow(false)
  }

  const handleOpen = () => {
    setShow(true)
  }

  const handleClose = ({ resetForm }) => {
    formik.resetForm()
    setShow(false)
  }

  const snackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnack(false)
  }

  const formik = useFormik({
    initialValues: {
      first_name: '',
      dob: null,
      address: '',
      last_name: '',
      state_id: '',
      gender: '',
      pincode: '',
      mobile_no: '',
      country_id: '',
      city_id: '',
      email: ''
    },
    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: false,
    validationSchema,
    onSubmit: submit
  })

  return (
    <Box className={classes.PatientInfoCol}>
      <Box className={classes.PatientTop}>
        <Paper elevation={3} className={classes.paper}>
          <Grid container spacing={2} style={{ width: '100% !important' }}>
            <Grid
              item
              sm={12}
              className="welcome-col"
              style={{ width: '100% !important' }}>
              <Box className={classes.Topbar}>
                <h2 className={classes.welcomeText}>Welcome</h2>
                <Box className={classes.AddPatientBtn}>
                  <Chip
                    style={{ borderRadius: 8 }}
                    label="Add New Patient"
                    // onClick={addPatient}
                    onClick={handleOpen}
                    icon={<AddIcon />}
                    variant="outlined"
                  />
                </Box>
              </Box>
            </Grid>
            {patientListFetched.length === 0 ? (
              <Loading
                type="cylon"
                textAlign="center"
                color="grey"
                height={40}
              />
            ) : (
              <Grid item sm={12} className={classes.patientsCol}>
                <Typography variant="h6" className={classes.listHeading}>
                  Select An Account
                </Typography>{' '}
                <br />
                <div className={classes.patientsList}>
                  <PatientAccList
                    patientListFetched={patientListFetched}
                    handleClick={handleClick}
                  />
                </div>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              style={{ justifyContent: 'center', display: 'flex' }}>
              <button
                onClick={logout}
                style={{
                  width: '140px',
                  borderRadius: 8,
                  background:
                    'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
                  border: 'none',
                  height: '40px',
                  textAlign: 'center',
                  color: '#fff',
                  lineHeight: '40px',
                  cursor: 'pointer'
                }}>
                Logout
              </button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {patientAddedStatus.statusCode === 400 ? (
        <Snackbar
          open={snack}
          autoHideDuration={6000}
          onClose={snackClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'bottom' }}>
          <Alert onClose={snackClose} severity="error">
            {patientAddedStatus.message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={snack}
          autoHideDuration={6000}
          onClose={snackClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'bottom' }}>
          <Alert onClose={snackClose} severity="success">
            Patient added successfully...
          </Alert>
        </Snackbar>
      )}

      {networkStatus === 'FETCHING' ? (
        <div>
          <Loader open={true} />
        </div>
      ) : (
        <div>
          <Loader open={false} />
        </div>
      )}
      <Dialog
        open={isShow}
        classes={{ paper: classes.modalView }}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
          Add Patient
          <span
            style={styles}
            onMouseEnter={() => setColor('red')}
            onMouseLeave={() => setColor('')}
            onClick={handleClose}>
            X
          </span>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {/* Restructure start */}

          <Box>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box className={classes.InputRow}>
                    <TextField
                      id="first_name"
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      label="First Name*"
                      value={
                        formik && formik.values && formik.values.first_name
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.first_name && formik.errors.first_name
                            ? formik.errors.first_name
                            : null}
                        </div>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={classes.InputRow}>
                    <TextField
                      id="last_name"
                      type="text"
                      placeholder="Last Name"
                      label="Last Name*"
                      name="last_name"
                      value={formik && formik.values && formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.last_name && formik.errors.last_name
                            ? formik.errors.last_name
                            : null}
                        </div>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={classes.InputRow}>
                    <FormControl>
                      <FormLabel
                        style={{
                          fontWeight: 'bold',
                          color: '#979797',
                          fontSize: '14px'
                        }}>
                        Gender *
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="gender"
                        // label="Gender*"
                        name="gender"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // helperText={
                        //   <div className={classes.errMsg}>
                        //     {formik.touched.gender && formik.errors.gender
                        //       ? formik.errors.gender
                        //       : null}
                        //   </div>
                        // }
                        className={classes.radioInput}>
                        <FormControlLabel
                          value="male"
                          control={<Radio color="primary" />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio color="primary" />}
                          label="Female"
                        />
                      </RadioGroup>
                      <div
                        className={[
                          classes.errMsg,
                          classes.genderErrorMessage
                        ].join(' ')}>
                        {formik.touched.gender && formik.errors.gender
                          ? formik.errors.gender
                          : null}
                      </div>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    style={{ width: '100%' }}
                    variant="inline"
                    format="DD-MM-YYYY"
                    margin="normal"
                    name="dob"
                    maxDate={new Date()}
                    label="Date of birth*"
                    value={formik.values.dob}
                    onChange={value => formik.setFieldValue('dob', value)}
                    onBlur={formik.handleBlur}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.dob && formik.errors.dob
                          ? formik.errors.dob
                          : null}
                      </div>
                    }
                    autoOk={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.InputRow}>
                    <TextField
                      id="address"
                      placeholder="Address"
                      label="Address *"
                      name="address"
                      className={classes.InputCol}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik && formik.values && formik.values.address}
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.address && formik.errors.address
                            ? formik.errors.address
                            : null}
                        </div>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={classes.InputRow}>
                    <TextField
                      id="pincode"
                      type="text"
                      placeholder="Pincode"
                      label="Pincode *"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik && formik.values && formik.values.pincode}
                      name="pincode"
                      helperText={
                        <div className={classes.errMsg}>
                          {formik.touched.pincode && formik.errors.pincode
                            ? formik.errors.pincode
                            : null}
                        </div>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>

                <Grid item xs>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="size-small-outlined"
                      name="country_id"
                      size="small"
                      style={{ width: '100%' }}
                      options={countryList}
                      getOptionLabel={option => option.name}
                      onChange={countryChange}
                      value={Country}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Country *"
                          loading={fetchingCountry}
                          style={{ width: '100%' }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingCountry ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    <div className={classes.errMsg2}>
                      {formik.touched.country_id && formik.errors.country_id
                        ? formik.errors.country_id
                        : null}
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      name="state_id"
                      style={{ width: '100%' }}
                      options={Country ? stateList : []}
                      getOptionLabel={option => option.name}
                      onChange={stateChange}
                      value={State}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="State *"
                          loading={fetchingState}
                          style={{ width: '100%' }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingState ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    <div className={classes.errMsg2}>
                      {formik.touched.state_id && formik.errors.state_id
                        ? formik.errors.state_id
                        : null}
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      name="city_id"
                      style={{ width: '100%' }}
                      options={Country && State ? cityList : []}
                      getOptionLabel={option => option.name}
                      onChange={cityChange}
                      value={city}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="City *"
                          loading={fetchingCity}
                          style={{ width: '100%' }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {fetchingCity ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    <div className={classes.errMsg2}>
                      {formik.touched.city_id && formik.errors.city_id
                        ? formik.errors.city_id
                        : null}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: '20px 20px' }}>
          <Button
            onClick={handleClose}
            className={classes.modalSecondaryButton}>
            Cancel
          </Button>
          <Button
            onClick={formik.handleSubmit}
            className={classes.modalPrimaryButton}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    // .trim()
    .required('First Name is required')
    .max(35, 'You may enter up to 35 characters')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  // .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),
  last_name: yup
    .string()
    // .trim()
    .required('Last Name is required')
    .max(70, 'You may enter up to 70 characters')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  // mobile_no: yup
  //   .string()
  //   .matches(VALIDATE_PHONE, ' Check with +91 and numbered entered')
  //   .required('Mobile number is required')
  //   .max(14, 'You may enter up to 14 digits')
  //   .typeError('Phone number cant contain characters'),
  // email: yup
  //   .string()
  //   .email('Invalid email')
  //   .required('Email is required')
  //   .max(254, 'You have exceeded the character limit')
  //   .matches(
  //     VALIDATE_WHITESPACES,
  //     'Space should not come before and after text!'
  //   ),

  pincode: yup
    .string()
    .trim()
    .required('Pincode is required')
    .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Incorrect pincode format'),

  dob: yup
    .date()
    .typeError('Please enter your date in the correct format')
    .max(new Date(), `Date should be earlier than today's date`)
    .required('Dob is required'),

  country_id: yup.string().required('Country is required'),

  address: yup
    .string()
    // .trim()
    .required('Address is required')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),

  gender: yup.string().required('Gender is required'),
  state_id: yup.string().required('State is required'),
  city_id: yup.string().required('City is required')
})

const useStyles = makeStyles(theme => ({
  // root: {
  //   width: "100%",
  //   "& > * + *": {
  //     marginTop: theme.spacing(2),
  //   },
  // },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    },
    '& .MuiDialogContent-root': {
      padding: '50ps 24px'
    }
  },
  PatientTop: {
    backgroundColor: '#f7f6f6',
    margin: '15px 0'
  },

  PatientModal: {
    width: '800px',
    background: '#fff',
    padding: '10px 40px'
  },
  InputRow: {
    width: '100%',
    '& .MuiTextField-root': {
      width: '100%'
    }
  },
  InputCol: {
    width: '100%'
  },
  // InputCol1: {
  //   // float: 'left',
  //   // width: '50%'
  //   // paddingBottom: '10px'
  // },
  // InputCol2: {
  //   // float: 'right',
  //   // width: '50%'
  //   // paddingBottom: '10px'
  // },
  formControl: {
    width: '100%',
    paddingLeft: '5px'
  },
  BottomTent: {
    width: '100%',
    marginTop: '60px',
    textAlign: 'right',
    '@media (max-width:767px)': {
      textAlign: 'center'
    },
    '& p': {
      color: '#5c637e'
    }
  },
  InfoText: {
    width: '100%',
    '& h2': {
      fontSize: '20px'
    }
  },
  modalView: {
    width: '40rem !important',
    height: '45rem'
  },
  BtnCol: {
    width: '100%',
    padding: '30px 0',
    display: 'flex',
    justifyContent: 'center',
    '& h4': {
      fontSize: '20px',
      color: '#3f4c79',
      marginBottom: '20px'
    }
  },
  ProceedBtn: {
    width: '120px',
    height: '40px',
    background: '#3f4c79',
    borderRadius: '40px',
    textAlign: 'center',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '40px',
    border: 'none',
    cursor: 'pointer'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  FormElement: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '5px',
    '& p': {
      marginBottom: '0px',
      fontSize: '14px',
      marginRight: '15px',
      marginTop: '0px'
    }
  },

  PatientInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '15px'
  },
  Topbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  errMsg: {
    color: 'red',
    float: 'inherit'
  },
  genderErrorMessage: {
    fontSize: '75%',
    letterSpacing: '0.05rem'
  },
  errMsg2: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingTop: '4px',
    fontWeight: 'bolder'
  },
  paper: {
    marginBottom: '15px',
    float: 'left',
    width: '100%',
    padding: '15px',
    minHeight: '400px',
    boxShadow: 'none',
    background: '#fafafa'
  },
  PatientNameList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  p: {
    color: '#bf1650',
    '&::before': {
      display: 'inline',
      content: '⚠ '
    }
  },
  TabBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '& button': {
      backgroundColor: '#f7f6f6',
      marginRight: '15px',
      textTransform: 'capitalize',
      color: '#3f4b79'
    }
  },
  patientName: {
    width: 'fit-content',
    display: 'flex',
    padding: '10px 10px 10px 0',
    margin: '10px',
    fontSize: '18px',
    fontWeight: '300'
  },

  upcomingAppointmentsRoot: {
    '@media (max-width:767px)': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  upcomingAppointments: {
    maxWidth: '100%',
    minHeight: '20vh'
  },
  AddPatientBtn: {
    marginBottom: '0',
    fontSize: '16px',
    fontWeight: '300',
    '@media (max-width:780px)': {
      padding: '5px 0 0 70px'
    }
  },
  genderInput: {
    paddingLeft: '10px'
  },
  dobMain: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  modalPrimaryButton: {
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
    padding: '5px 20px',
    height: 40,
    borderRadius: '8px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111d4e',
      color: '#fff'
    }
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '8px',
    height: 40,
    color: '#111d4e',
    '&:hover': {
      backgroundColor: '#e4e4e4',
      color: '#111d4e'
    }
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },

  inputChange: {
    width: '100%',
    paddingLeft: '8px',
    // paddingTop: '22px',
    '& .PhoneInputInput': {
      border: 'none',
      borderBottom: '1px solid #9E9E9E',
      margin: '10px'
    }
  },
  patientsCol: {
    '@media (max-width:780px)': {
      overflowX: 'auto'
    }
  },
  patientsList: {
    '@media (max-width:780px)': {
      width: '100%',
      paddingLeft: '0px'
    },
    '@media (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait)':
      {
        width: '100%'
      }
  },
  listHeading: {
    color: '#000',
    '@media (max-width:780px)': {
      paddingLeft: '0px'
    }
  },
  welcomeText: {
    margin: 0,
    '@media (max-width:780px)': {
      paddingLeft: '0px',
      paddingRight: '0',
      marginRight: '0'
    }
  },
  content: {
    padding: '25px 20px'
  }
}))

export default SelectPatientComponent
