import React from 'react'
import { makeStyles, Box, Typography, Link } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import PrimaryButton from '../../components/CommonComponents/PrimaryButton'
import PrimaryInputField from '../../components/CommonComponents/PrimaryInputField'
import { DoctorPublicStore, DoctorStore } from '../../stores'
import { USER_TYPE, NETWORK_STATUS } from '../../Constants'
import OtpForm from './OtpForm'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Height } from '@material-ui/icons'

const LoginForm = ({
  onSuccess = () => {},
  expire,
  forgotPasswordClicked = () => {}
}) => {
  const classes = useStyles()

  const { control, handleSubmit, errors } = useForm()

  const { login } = DoctorPublicStore.useStoreActions(actions => ({
    ...actions.auth
  }))
  const setDoctorProfileData = DoctorStore.useStoreActions(
    actions => actions.profile.setData
  )

  const { networkStatus } = DoctorPublicStore.useStoreState(state => ({
    ...state.auth
  }))

  const usernameInput = React.useRef()
  const passwordInput = React.useRef()

  React.useEffect(() => {
    usernameInput.current.focus()
  }, [])

  const onSubmit = formData => {
    login(formData).then(data => {
      if (formData.userType === USER_TYPE.DOCTOR.value) {
        setDoctorProfileData(data[0])
      }
      if (onSuccess) {
        onSuccess({ request: formData, response: data })
      }
    })
  }
  const [loginLoader, setLoginLoader] = React.useState(false)
  const setLoader = React.useCallback(
    value => {
      setLoginLoader(value)
    },
    [loginLoader, setLoginLoader]
  )

  const keyPressed = e => {
    if (e.keyCode === 13) {
      passwordInput.current.focus()
    }
  }
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Box display="flex" alignItems="center" mb={4}>
          <Typography variant="h5" style={{ color: '#3f4b79' }}>
            Welcome Back, Doctor!
          </Typography>
        </Box> */}

        <Box mb={4}>
          <Controller
            as={PrimaryInputField}
            name="email"
            control={control}
            defaultValue=""
            placeholder="Username"
            rules={{ required: 'Username is required' }}
            inputRef={usernameInput}
            onKeyDown={e => keyPressed(e)}
          />
          {!loginLoader ? (
            errors.email ? (
              <Typography color={'error'}>{errors.email?.message}</Typography>
            ) : null
          ) : null}
        </Box>
        <Box mb={4}>
          <Controller
            as={PrimaryInputField}
            name="password"
            type="password"
            control={control}
            defaultValue=""
            placeholder="Password"
            rules={{ required: 'Password is required' }}
            inputRef={passwordInput}
          />
          {!loginLoader ? (
            errors.password ? (
              <Typography color={'error'}>
                {errors.password?.message}
              </Typography>
            ) : null
          ) : null}
        </Box>
        <PrimaryButton
          rounded
          type="submit"
          disabled={loginLoader}
          stopLoading={loginLoader}
          isLoading={networkStatus === NETWORK_STATUS.FETCHING}
          style={{
            background: 'linear-gradient(to right bottom, #3267F1, #3E3BE8)',
            width: '100%',
            borderRadius: 8,
            textTransform: 'capitalize',
            Height: 40
          }}>
          Login
        </PrimaryButton>
        {/* <Link className="forgotext" to="" onClick={handleOpen}>
          Forgot Password
        </Link> */}
      </form>
      <Box mb={4} mt={3}>
        <p style={{ fontSize: 14, textAlign: 'center' }}>Or, Request OTP</p>
      </Box>
      <OtpForm expire={expire} onSuccess={onSuccess} setLoader={setLoader} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Forgot your password?
          <span className={classes.cross} onClick={handleClose}>
            X
          </span>
        </DialogTitle>
        <DialogContent>
          <p
            id="transition-modal-description"
            style={{ color: '#9138aa', fontWeight: 600 }}>
            No need to worry, use your registered number to login and update
            your password.
          </p>
          <form>
            <input
              type="tel"
              className="numberinput"
              placeholder="+91xxxxxxxxxx"
            />
            <button
              className={classes.sendOtpButton}
              onClick={() => alert('Send otp button clicked!')}>
              Send OTP
            </button>
          </form>
        </DialogContent>
      </Dialog>
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className={classes.ForgotModal}>
            <span className="closemodal" onClick={handleClose}>
              X
            </span>
            <h2 id="transition-modal-title">Forgot your password ?</h2>
            <p id="transition-modal-description" style={{ color: '#9138aa' }}>
              No need to worry, use your registered number to login and update
              your password.
            </p>
            <form>
              <input
                type="tel"
                className="numberinput"
                placeholder="+91xxxxxxxxxx"
              />
              <button type="button" className="otpbtn">
                Send OTP
              </button>
            </form>
          </div>
        </Fade>
      </Modal> */}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
    maxWidth: '100%',
    width: '100%'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  selectEmpty: {
    marginTop: theme.spacing(0)
  },
  selectElement: {
    width: '90px',
    borderWidth: 0,
    fontSize: '14px',
    '&>div': {
      '&::before': {
        borderWidth: '0px',
        '&::after': {
          borderWidth: '0px'
        }
      }
    }
  },
  ForgotModal: {
    width: '600px',
    padding: '25px',
    background: '#fff',
    borderRadius: '10px',
    position: 'relative',
    '& p': {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    '& h2': {
      marginTop: '0px'
    }
  },
  labelText: {
    fontSize: '12px',
    float: 'left',
    marginRight: '10px',
    lineHeight: '29px'
  },
  selectGroup: {
    display: 'flex',
    marginBottom: '30px'
  },
  inputField: {
    marginBottom: 40,
    display: 'flex',
    flexFlow: 'column'
  },
  forgotPassword: {
    fontSize: 12,
    cursor: 'pointer',
    width: 'fit-content',
    alignSelf: 'flex-end',
    marginTop: 5,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  submitButton: {
    width: 150
  },
  cross: {
    cursor: 'pointer',
    float: 'right',
    color: '#f76f6f'
  },
  sendOtpButton: {
    backgroundColor: '#111d4e',
    padding: '5px 20px',
    borderRadius: '40px',
    color: '#fff',
    cursor: 'pointer',
    border: '1px solid #111d4e'
  }
}))

export default LoginForm
