import { action, thunk, computed } from 'easy-peasy'

import BaseModel from '../Base'
import { NETWORK_STATUS, CONSULTATION_TYPE } from '../../Constants'
import api from '../../api'
import { PATIENT_USER_ID, PATIENT_ID } from '../../Constants'
import localforage from 'localforage'

const INITIAL_SCHEDULE = {
  id: null,
  doctorId: null,
  date: null,
  startTime: null,
  endTime: null,
  scheduleType: null,
  consultationFees: null
}

export default {
  ...BaseModel(),

  schedule: INITIAL_SCHEDULE,
  patientId: null,
  doctorId: null,
  consultationType: CONSULTATION_TYPE.IN_PERSON,
  countryList: [],
  stateList: [],
  cityList: [],
  successMessage: [],
  fetchingCountry: false,
  fetchingState: false,
  fetchingCity: false,
  transactionDetails: [],
  paymenVerificationResonse: [],

  create: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const {
        schedule: { id, date, startTime, endTime },
        doctorId,
        patientId,
        consultationType
      } = getState()
      const { purpose, booked_by, Caregiversf, Charegiversl } = payload

      api.setAuthHeaders({
        authorization: localStorage.getItem('patient_auth_key')
      })
      if (payload?.is_profile_completed === '0') {
        const loginCreds = JSON.parse(
          localStorage.getItem('patient_register_details')
        )

        const patientProfileresponse = await api.patient.initialAccountRegistration(
          {
            ...payload,
            primary_doctor_id: '',
            patient_id: localStorage.getItem(PATIENT_ID),
            user_id: localStorage.getItem(PATIENT_USER_ID),
            username: loginCreds?.email,
            password: loginCreds?.password,
            email: loginCreds?.username
          }
        )

        if (patientProfileresponse.ok) {
          const response = await api.createAppointment({
            ...payload,
            patient_id: localStorage.getItem(PATIENT_ID),
            appointment_date: payload.start_time_slot.split(' ')[0],
            appointment_start_time: payload.start_time_slot.split(' ')[1],
            appointment_end_time: payload.end_time_slot.split(' ')[1],
            status: '1',
            appointment_type: payload.appointment_type,
            booked_by_id: localStorage.getItem(PATIENT_ID),
            booked_by_first_name: Caregiversf ? Caregiversf : '',
            booked_by_last_name: Charegiversl ? Charegiversl : '',
            primary_doctor_id: '',
            clinic_doctor_association_id:JSON.parse(localStorage.getItem('clinic_association_id')) ? JSON.parse(localStorage.getItem('clinic_association_id')) : localStorage.getItem("clinic_doctor_association_id") ,
          })
          if (response.ok) {
            actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
            actions.updateResponse(response.data)
            if (localStorage.getItem('new_patient')) {
              localStorage.removeItem('new_patient')
              localStorage.removeItem('account-data')
              localStorage.removeItem('patient_register_details')
            }
            let patientDetails = payload
            localStorage.setItem(
              'selected_patient_initial',
              JSON.stringify(patientDetails)
            )
            localforage.setItem('selected_patient', patientDetails)
          } else {
            actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
          }
        } else {
          // console.log('problem in updating profile')
        }
      } else {
        const response = await api.createAppointment({
          ...payload,
          patient_id: localStorage.getItem(PATIENT_ID),
          appointment_date: payload.start_time_slot.split(' ')[0],
          appointment_start_time: payload.start_time_slot.split(' ')[1],
          appointment_end_time: payload.end_time_slot.split(' ')[1],
          status: '1',
          appointment_type: payload.appointment_type,
          booked_by_id: localStorage.getItem(PATIENT_ID),
          booked_by_first_name: Caregiversf ? Caregiversf : '',
          booked_by_last_name: Charegiversl ? Charegiversl : '',
          primary_doctor_id: '',
          clinic_doctor_association_id:JSON.parse(localStorage.getItem('clinic_association_id')) ? JSON.parse(localStorage.getItem('clinic_association_id')) : localStorage.getItem("clinic_doctor_association_id") ,
        })
        if (response.ok) {
          actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
          actions.updateResponse(response.data)
          if (localStorage.getItem('new_patient')) {
            localStorage.removeItem('new_patient')
            localStorage.removeItem('account-data')
          }
        } else {
          actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        }
      }
    }
  ),

  updateResponse: action((state, payload) => {
    if (payload) {
      state.transactionDetails = payload
    }
  }),

  setConsultationType: action((state, payload) => {
    state.consultationType = payload
  }),

  setPatientId: action((state, payload) => {
    state.patientId = payload
  }),

  setSchedule: action((state, payload) => {
    state.schedule = payload
    state.doctorId = state.schedule.doctorId
  }),

  fetchCountryList: thunk(async (actions, payload) => {
    actions.fetchingCountryStatus(true)
    const response = await api.getCountryStateCity({})
    if (response.ok) {
      actions.fetchedCountryList(response.data.data)
      actions.fetchedStateList([])
      actions.fetchingCountryStatus(false)
    }
  }),

  fetchingCountryStatus: action((state, payload) => {
    state.fetchingCountry = payload
  }),

  fetchedCountryList: action((state, payload) => {
    if (payload) {
      state.countryList = payload
    }
  }),

  fetchStateList: thunk(async (actions, payload) => {
    if (payload) {
      actions.fetchingStateStatus(true)
      const response = await api.getCountryStateCity({
        country_id: payload
      })
      if (response.ok) {
        actions.fetchingStateStatus(false)
        actions.fetchedStateList(response.data.data)
        actions.fetchedCityList([])
      }
    }
  }),

  fetchingStateStatus: action((state, payload) => {
    state.fetchingState = payload
  }),

  fetchedStateList: action((state, payload) => {
    if (payload) {
      state.stateList = payload
    }
  }),

  fetchCityList: thunk(async (actions, payload) => {
    if (payload) {
      actions.fetchingCityStatus(true)

      const response = await api.getCountryStateCity({ state_id: payload })
      if (response.ok) {
        actions.fetchingCityStatus(false)
        actions.fetchedCityList(response.data.data)
      }
    }
  }),

  fetchingCityStatus: action((state, payload) => {
    state.fetchingCity = payload
  }),

  fetchedCityList: action((state, payload) => {
    if (payload) {
      state.cityList = payload
    }
  }),

  clearState: action((state, payload) => {
    state.stateList = []
    state.cityList = []
  }),

  reset: action(state => {
    state.schedule = INITIAL_SCHEDULE
  }),

  verifyPayment: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const { transaction_id } = payload

      const response = await api.payment.verifyPayment({
        transaction_id: transaction_id,
        patient_id: localStorage.getItem(PATIENT_ID)
      })
      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setPaymenVerification(response?.data)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),
  setPaymenVerification: action((state, payload) => {
    state.paymenVerificationResonse = payload
  })
}
