import { action, thunk } from 'easy-peasy'
import BaseModel from '../Base'
import { NETWORK_STATUS, SUPER_CLINIC_USER_ID } from '../../Constants'
import api from '../../api'

const NO_CONNECTION_MESSAGE =
  'Oops! Something went wrong. Please try again later...'

export default {
  ...BaseModel(),

  loadMessage: null,
  errorMessageFromBackend: null,
  successMessageFromBackend: null,

  loadDocList: true,
  fetchDocListNetworkStatus: NETWORK_STATUS.FETCHING,
  superClinicDocList: [],
  docListPaginationInfo: {},

  setMessageFromBackend: action((state, payload) => {
    if (!!payload.success) {
      state.successMessageFromBackend = payload.message
    } else {
      state.errorMessageFromBackend = payload.message
    }
  }),

  setLoadMessage: action((state, payload) => {
    state.loadMessage = payload
  }),

  setLoadDocList: action((state, payload) => {
    state.loadDocList = payload
  }),

  getMyDoctorList: thunk(async (actions, payload) => {
    actions.setDocListNetworkStatus(NETWORK_STATUS.FETCHING)

    let data = null

    try {
      const response = await api.super_clinic.getmyDoctorList({
        sub_clinic_id: !!payload.sub_clinic_id ? payload.sub_clinic_id : '', // (optional)
        super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID),
        table_config_page_no: `${payload.table_config_page_no}`,
        table_config_rows_per_page: `${payload.table_config_rows_per_page}`,
        pattern: !!payload.pattern ? payload.pattern : '' // (put searchable content)
      })

      data = response.data

      if (
        !!data &&
        !!data.statusCode &&
        (data.statusCode === 200 || data.statusCode === 201)
      ) {
        actions.setDocListNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setSuperClinicDocList(data.data ? data.data : [])

        const {
          current_page,
          rows_per_page,
          total_pages_count,
          total_rows_count
        } = data

        actions.setDocListPaginationInfo({
          current_page,
          rows_per_page,
          total_pages_count,
          total_rows_count
        })

        return data
      } else {
        throw Error('error')
      }
    } catch (error) {
      actions.setDocListNetworkStatus(NETWORK_STATUS.FAILED)
      actions.setMessageFromBackend({
        message: !!data && !!data.message ? data.message : NO_CONNECTION_MESSAGE
      })
    }

    return null
  }),

  setSuperClinicDocList: action((state, payload) => {
    state.superClinicDocList = payload
  }),

  setDocListPaginationInfo: action((state, payload) => {
    state.docListPaginationInfo = payload
  }),

  setDocListNetworkStatus: action((state, payload) => {
    state.fetchDocListNetworkStatus = payload
  }),

  getClinicList: thunk(async (actions, payload) => {
    actions.setClinicListNetworkStatus(NETWORK_STATUS.FETCHING)

    try {
      const response = await api.super_clinic.getClinicList({
        pattern: !!payload.pattern ? payload.pattern : '', // 'mahesh', // (optional)
        super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35',
        table_config_page_no: `${payload.table_config_page_no}`, // '1',
        doctor_id: !!payload.doctor_id ? payload.doctor_id : '', // (optional)
        start_date: !!payload.start_date ? payload.start_date : '', // '2021-03-03', // (optional)
        end_date: !!payload.end_date ? payload.end_date : '', // (optional)
        table_config_rows_per_page: `${payload.table_config_rows_per_page}`
      })

      const { data = null } = response

      if (!!data && !!data.statusCode && data.statusCode === 200) {
        actions.setClinicListNetworkStatus(NETWORK_STATUS.SUCCESS)

        actions.setClinicList(data.data)

        const {
          total_rows_count,
          current_page,
          rows_per_page,
          total_pages_count
        } = data

        actions.setClinicPaginationInfo({
          total_rows_count,
          current_page,
          rows_per_page,
          total_pages_count
        })
      } else {
        actions.setClinicListNetworkStatus(NETWORK_STATUS.FAILED)
        actions.setMessageFromBackend({
          message:
            !!data && !!data.message ? data.message : NO_CONNECTION_MESSAGE
        })
      }
      return data
    } catch (error) {
      actions.setClinicListNetworkStatus(NETWORK_STATUS.FAILED)
      actions.setMessageFromBackend({ message: NO_CONNECTION_MESSAGE })
    }
    return null
  })
}
