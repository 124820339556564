import React, { useState, useLayoutEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { Collapse, makeStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import localforage from 'localforage'

const handleLogout = () => {
  localforage.clear().then(() => {
    localStorage.removeItem('super_clinic_user_id')
    localStorage.removeItem('superclinic')
    localStorage.removeItem('routing_mode')
  })
}

const NavItem = ({
  sub = null,
  href = '',
  listTitle = '',
  baseUrl = '',
  title = '',
  listIcon: ListIcon = <></>,
  icon: Icon = <></>,
  ...props
}) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)
  const [active, setActive] = useState(false)

  const { location } = props

  useLayoutEffect(() => {
    if (location.pathname.startsWith(baseUrl)) {
      setExpanded(true)
      setActive(true)
    } else {
      setActive(false)
    }
  }, [location, baseUrl])

  if (sub) {
    return (
      <div className={classes.justToOverrideIconStyles}>
        <div
          className={
            active ? classes.activeSubLinkContainer : classes.subLinkContainer
          }
          style={{ cursor: 'pointer' }}>
          <button
            onClick={e => {
              setExpanded(cur => !cur)
            }}
            style={{
              fontFamily: 'inherit',
              appearance: 'none',
              '-webkit-appearance': 'none',
              '-moz-appearance': 'none',
              border: '0',
              outline: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer',
              padding: '10px 15px',
              margin: '0',
              borderRadius: '0',
              color: '#fff',
              height: 'initial',
              fontSize: 'inherit',
              fontWeight: 'inherit',
              display: 'grid',
              width: '100%',
              gridAutoFlow: 'column',
              gridTemplateColumns: 'min-content 1fr max-content',
              alignItems: 'center',
              textAlign: 'initial'
            }}>
            <div>
              <ListIcon style={{ margin: '0 10px -5px 0' }} />
            </div>
            <div>{listTitle}</div>
            <div style={{ marginLeft: 'auto' }}>
              {expanded ? (
                <ExpandMoreIcon
                  style={{
                    lineHeight: 1,
                    fontSize: 'inherit',
                    marginBottom: '-4px'
                  }}
                />
              ) : (
                <ChevronRightIcon
                  style={{
                    lineHeight: 1,
                    fontSize: 'inherit',
                    marginBottom: '-4px'
                  }}
                />
              )}
            </div>
          </button>
        </div>
        <Collapse in={expanded}>
          <ul className={classes.subListGroup}>
            {sub.map((item, index) => (
              <li key={index} className={classes.subListItem}>
                <NavLink
                  exact
                  to={`${baseUrl}${item.href}`}
                  className={classes.subLink}
                  activeClassName={classes.subActiveLink}>
                  <div
                    style={{
                      display: 'inline-grid',
                      gridTemplateColumns: 'min-content 1fr',
                      alignItems: 'center',
                      lineHeight: 1,
                      gridColumnGap: '8px'
                    }}>
                    <div
                      style={{
                        display: 'inline-block'
                      }}>
                      <item.icon className={classes.icon} />
                    </div>
                    <div
                      style={{
                        display: 'inline-block'
                      }}>
                      {item.title}
                    </div>
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>
        </Collapse>
      </div>
    )
  }

  return (
    <NavLink
      exact
      to={href}
      className={classes.link}
      onClick={title === 'Logout' ? handleLogout : ''}
      activeClassName={classes.activeLink}>
      <div style={{ display: 'grid', gridTemplateColumns: 'min-content 1fr' }}>
        <div>
          <Icon style={{ margin: '0 10px -5px 0' }} />
        </div>
        <div>{title}</div>
      </div>
    </NavLink>
  )
}

export default withRouter(NavItem)

const useStyles = makeStyles(theme => ({
  link: {
    width: '100%',
    display: 'flex',
    fontFamily: 'Poppins'
  },
  activeLink: {
    width: '100%',
    display: 'flex',
    background: '#9138aa',
    fontFamily: 'Poppins'
  },
  subLinkContainer: {
    fontFamily: 'Poppins'
  },
  activeSubLinkContainer: {
    background: '#9138aa',
    fontFamily: 'Poppins'
  },
  // subListGroup: {
  //   listStyleType: 'none',
  //   fontFamily: 'inherit',
  //   padding: 0,
  //   margin: 0
  // },
  // subListItem: {
  //   display: 'block',
  //   fontFamily: 'inherit',
  //   padding: 0,
  //   margin: 0
  // },
  subListGroup: {
    listStyleType: 'none',
    margin: '10px 0 10px',
    fontFamily: 'Poppins',
    '& li a': {
      padding: '0 15px'
    },
    '& li:hover svg path': {
      fill: '#fff'
    },
    '& li': {
      borderBottom: '0px !important',
      fontSize: '13px',
      padding: '7px 15px'
    }
  },
  subListItem: {
    display: 'block',
    margin: 0,
    width: '100%',
    fontFamily: 'Poppins'
  },
  icon: {
    fill: 'currentColor !important',
    fontSize: '22px'
  },
  justToOverrideIconStyles: {
    '& ul li .MuiSvgIcon-root': {
      fill: 'currentColor !important'
    }
  },
  subLink: {},
  subActiveLink: {
    color: '#e17cfd'
  }
}))
