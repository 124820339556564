import React from 'react'
import {
  Chip,
  Tooltip,
  CircularProgress,
  makeStyles,
  colors
} from '@material-ui/core'
import { AddCircleOutline, Delete, Error } from '@material-ui/icons'

import { ClinicStore } from '../../../../../stores'
import { NETWORK_STATUS } from '../../../../../Constants'

const AddAsTaken = ({
  onClickMoveToTakenButton,
  loadStatusAll,
  onDeleteButtonClicked
}) => {
  const classes = useStyles()

  const { recommendedServiceList } = ClinicStore.useStoreState(state => ({
    ...state.appointment
  }))

  if (loadStatusAll === NETWORK_STATUS.FETCHING) {
    return (
      <div className={classes.addAsTaken}>
        <div className={classes.container}>
          <div className={classes.emptyContainerText}>
            <CircularProgress />
          </div>
        </div>
      </div>
    )
  } else if (loadStatusAll === NETWORK_STATUS.FAILED) {
    return (
      <div className={classes.addAsTaken}>
        <div className={classes.container}>
          <div className={classes.emptyContainerText}>
            <div className={classes.hintText}>
              <Error />
              Oops! Something went wrong. Try again later!
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.addAsTaken}>
      {/* <h3>Add as Taken</h3> */}
      <div className={classes.container}>
        {!!recommendedServiceList.length ? (
          <ul className={classes.listsContainer}>
            {recommendedServiceList.map(rs => {
              const {
                patient_service_id: patientServiceId,
                service_name: serviceName
              } = rs
              return (
                <li className={classes.listItem} key={patientServiceId}>
                  <Chip
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      maxWidth: '100%'
                    }}
                    label={
                      <Chip
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginLeft: '-1.4rem',
                          width: '100%'
                        }}
                        label={
                          <span
                            style={{
                              display: 'inline-block',
                              width: '100%',
                              margin: '0 1.4rem 0 0'
                            }}>
                            {serviceName}
                          </span>
                        }
                        onDelete={() =>
                          onClickMoveToTakenButton(patientServiceId)
                        }
                        deleteIcon={
                          <Tooltip
                            title="Add to 'Services Taken'"
                            aria-label="add"
                            placement="top">
                            <AddCircleOutline style={{ marginLeft: 'auto' }} />
                          </Tooltip>
                        }
                        color="primary"
                      />
                    }
                    onDelete={() => onDeleteButtonClicked(patientServiceId)}
                    deleteIcon={
                      <Tooltip
                        title="Delete this service"
                        aria-label="add"
                        placement="top">
                        <Delete
                          color="error"
                          style={{ marginLeft: '-1.4rem' }}
                        />
                      </Tooltip>
                    }
                    color="primary"
                  />
                </li>
              )
            })}
          </ul>
        ) : (
          <div className={classes.emptyContainerText}>
            Please 'Add' some services from 'Select from services'
          </div>
        )}
      </div>
    </div>
  )
}

export default AddAsTaken

const useStyles = makeStyles(theme => ({
  addAsTaken: {
    display: 'grid',
    gridAutoFlow: 'rows',
    gridTemplateRows: 'min-content 1fr'
  },
  listsContainer: {
    margin: '0',
    padding: '0.5rem',
    listStyleType: 'none',
    textAlign: 'center'
  },
  listItem: {
    display: 'inline-block',
    margin: '-0.3rem -0.3rem',
    maxWidth: '80%'
  },
  container: {
    height: '20vh',
    overflowY: 'scroll',
    padding: '0 !important',
    border: `1px solid ${colors.grey[500]}`,
    borderRadius: theme.spacing(1)
  },
  emptyContainerText: {
    margin: '2.5rem 0 0 0',
    color: colors.grey[300],
    textAlign: 'center',
    padding: '0 1rem'
  },
  hintText: {
    display: 'grid',
    gridColumnGap: '0.5rem',
    columnGap: '0.5rem',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    lineHeight: '1',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.error.main
  },
  // container: {
  //   width: '50%',
  //   margin: 'auto'
  // },
  // listsContainer: {
  //   margin: '0',
  //   padding: '0.5rem 1rem',
  //   listStyleType: 'none',
  //   display: 'grid',
  //   gridAutoFlow: 'row',
  //   gridAutoRows: 'min-content',
  //   gridTemplateColumns: '1fr',
  //   gridRowGap: '0.5rem',
  //   rowGap: '0.5rem'
  // },
  // listItem: {
  //   margin: '0',
  //   padding: '0',
  //   display: 'grid',
  //   gridAutoFlow: 'column',
  //   gridTemplateColumns: '1fr min-content',
  //   alignItems: 'center',
  //   gridColumnGap: '0.5rem',
  //   columnGap: '0.5rem'
  // },
  iconButton: {
    '& *': {
      height: '100% !important'
    }
  }
}))
