import React from 'react'
import DoctorDashboard from '../../components/DoctorDashboard/Dashboard'
import { DashboardLayout } from '../../doctorLayouts'

const DoctorDashboardPage = () => {
  return (
    <DashboardLayout>
      <DoctorDashboard />
    </DashboardLayout>
  )
}

export default DoctorDashboardPage
