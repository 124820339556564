import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Modal, Backdrop, Fade } from '@material-ui/core'
import { Layout } from '../../patientLayouts'
import DoctorProfileNew from './DoctorPageNew'
import ScheduleSlots from '../../components/SlotBooking/Slots'
import Loader from '../../components/CommonComponents/Loader'
import { withRouter } from 'react-router'
import UserBrowser from '../../BrowserSupport'
import ProfileBg from '../../images/bg2.png'

const DoctorNew = () => {
  const classes = useStyles()
  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.classList.add('doctor-profile')
    return () => {
      document.body.classList.remove('doctor-profile')
    }
  }, [])
  return (
    <Layout navBar="hideNavbar">
      <Loader />
      <div className={classes.DoctorSearch}>
        <Box className={classes.calendar}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Box className={classes.DoctorProfile}>
                <DoctorProfileNew />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} id="book">
              <Box className={classes.PatientSchdule}>
                <h2
                  style={{
                    color: '#000',
                    fontWeight: '500',
                    margin: '0 0 30px',
                    fontSize: '20px'
                  }}>
                  My Availability
                </h2>
                <ScheduleSlots />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Layout>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  PatientSchdule: {
    background: '#fff',
    borderRadius: 20,
    padding: 30,
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
  },
  calendar: {
    padding: '20px 30px',
    '@media (max-width:767px)': {
      padding: '20px 0px'
    }
  },
  DoctorProfile: {
    background: '#fff',
    padding: '30px 30px 0',
    borderRadius: 20,
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    marginBottom: 10
  },
  DoctorSearch: {
    background: `#F8F8FB`,
    backgroundRepeat: 'no-repeat',
    padding: '30px',
    width: '100%',
    overflowX: 'hidden',
    justifyContent: 'center',
    marginTop: '90px',
    '@media (max-width:780px)': {
      padding: '20px;'
    }
  },
  SearchTop: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    width: '40%'
  },
  SearchCol: {
    width: '100%',
    position: 'relative',
    display: 'flex'
  },
  SearchButton: {
    padding: '0px',
    border: 'none',
    background: 'none',
    position: 'absolute',
    right: '5px',
    top: '20px',
    cursor: 'pointer'
  },
  SearchIcon: {
    color: '#21caf1',
    fontSize: '20px'
  },
  DoctorInfo: {
    width: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    background: '#fff',
    marginBottom: '10px',
    '& h4': {
      fontSize: '18px',
      color: '#979797',
      margin: '0',
      fontWeight: 'normal'
    },
    '& h5': {
      fontSize: '18px',
      color: '#21caf1',
      margin: '0px',
      fontWeight: 'normal'
    },
    '& p': {
      fontSize: '16px',
      color: '#979797',
      marginTop: '0px',
      marginBottom: '5px',
      '& span': {
        color: '#21caf1'
      }
    },
    '& h3': {
      fontSize: '18px',
      margin: '0'
    }
  },
  ProfileImg: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    border: '2px #21caf1 solid',
    margin: '0px auto 15px',
    minWidth: '120px'
  },
  ProfileImgLeft: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    border: '2px #21caf1 solid',
    marginRight: '20px',
    minWidth: '120px'
  },
  ViewText: {
    color: '#21caf1',
    cursor: 'pointer',
    '&:hover': {
      color: '#333',
      textDecoration: 'none'
    }
  },
  ProfileCol: {
    width: '100%',
    display: 'flex',
    marginTop: '30px',
    '& p': {
      fontSize: '18px',
      color: '#979797',
      marginTop: '0px'
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: '400',
      marginBottom: '0px',
      marginTop: '20px'
    }
  },
  ContentText: {
    fontSize: '16px',
    color: '#979797'
  },
  TopCol: {
    display: 'flex',
    marginBottom: '35px',
    marginTop: '30px',
    color: '#979797',
    '& h3': {
      fontSize: '20px',
      fontWeight: '500',
      margin: '0px',
      '& span': {
        fontWeight: '700',
        color: '#414141'
      }
    }
  },
  Review: {
    '& p': {
      fontSize: '16px',
      margin: '0px',
      color: '#21caf1'
    },
    '& h6': {
      fontSize: '78px',
      color: '#21caf1',
      margin: '0px',

      '& span': {
        fontSize: '32px',
        color: '#414141'
      }
    }
  },
  ReviewCol: {
    display: 'flex',
    '& h5': {
      margin: 'auto'
    }
  },
  selectEmpty: {
    marginLeft: '20px'
  },
  TabCol: {
    width: '99%',
    backgroundColor: '#efefef',
    borderRadius: '5px',
    color: '#414141',
    boxShadow: 'none',
    overflow: 'hidden'
  },
  TabList: {
    '& span': {
      top: '0px',
      height: '0px',
      zIndex: '0'
    },
    '& button': {
      maxWidth: '108px',
      minWidth: '108px',
      padding: '5px 0',
      zIndex: '99',
      position: 'relative'
    }
  },
  TimeSelected: {
    backgroundColor: '#414141',
    color: '#fff',
    borderRadius: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#414141'
    }
  },
  TimeButton: {
    backgroundColor: '#efefef',
    color: '#21caf1',
    borderRadius: '10px',
    boxShadow: 'none',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#414141'
    }
  },
  TimeSchdule: {
    marginBottom: '30px'
  },
  AppointmentBook: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '5px',
    marginTop: '50px',
    '& h2': {
      fontSize: '20px',
      color: '#414141',
      marginRight: '80px',
      '& span': {
        color: '#21caf1'
      }
    }
  },
  BookBtn: {
    width: '140px',
    textAlign: 'center',
    backgroundColor: '#21caf1',
    borderRadius: '40px',
    boxShadow: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#414141',
      boxShadow: 'none'
    }
  },
  BackBtn: {
    color: '#21caf1',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: '5px'
    }
  },
  TopHeading: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '20px',
    alignItems: 'center',
    fontSize: '18px',
    color: '#979797',
    '& img': {
      marginRight: '10px'
    }
  },
  ToggleBtn: {
    backgroundColor: '#efefef',
    color: '#21caf1',
    borderRadius: '0px',
    border: 'none',
    '& button': {
      borderRadius: '0px',
      border: 'none',
      textTransform: 'capitalize',
      fontSize: '16px',
      paddingLeft: '20px',
      paddingRight: '20px'
    }
  },
  ActiveToggle: {
    backgroundColor: '#21caf1',
    color: '#fff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: '#fff',
    border: '0px solid #000',
    padding: '20px 30px',
    width: '60%',
    position: 'relative',
    '@media (max-width:991px)': {
      width: '85%'
    },
    '@media (max-width:767px)': {
      width: '98%',
      padding: '20px'
    }
  },
  CloseBtn: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    background: '#414141',
    color: '#fff',
    textAlign: 'center',
    lineHeight: '35px',
    position: 'absolute',
    minWidth: '35px',
    boxShadow: 'none',
    right: '20px',
    top: '15px',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#21caf1'
    }
  }
}))

export default withRouter(UserBrowser(DoctorNew))
