import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Backdrop,
  Button,
  Grid,
  makeStyles,
  CircularProgress,
  Fade,
  TextField,
  Select,
  MenuItem
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { PatientStore } from '../../stores'
import { NETWORK_STATUS, PATIENT_ID } from '../../Constants'
import { useParams } from 'react-router'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Autocomplete } from '@material-ui/lab'
import useDebounce from '../../customHooks/useDebounce'

const AddLabOrders = ({ open, handleClose }) => {
  const classes = useStyles()
  const params = useParams()
  const [report, setReport] = useState([])
  const [searchString, setSearchString] = useState('')
  const debouncedSearchTerm = useDebounce(searchString, 300)
  const [fileNotFound, setFileNotFound] = useState(false)

  const { addLab, getLabRecordsNames } = PatientStore.useStoreActions(
    actions => ({
      ...actions.patients
    })
  )

  const { networkStatus, lablist, labsFetchingStatus } =
    PatientStore.useStoreState(state => ({
      ...state.patients
    }))

  const submit = async values => {
    if (report.length) {
      const file = report[0]
      let fileResponse = await convertBase64(file)
      addLab({
        ...values,
        patientId: localStorage.getItem(PATIENT_ID),
        appointmentId: '',
        reportName: file.name,
        report: fileResponse
      })

      setTimeout(() => {
        handleClose()
      }, 500)
      return
    } else {
      setFileNotFound(true)
    }
  }

  const handlelabsChange = (event, value) => {
    if (value?.type === 'others') {
      setFieldValue('type', value?.type)
    } else {
      setFieldValue('type', value?.lab_id)
    }
    setFieldValue('testName', value?.testname)
  }

  const { values, handleChange, handleSubmit, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        type: '',
        instructions: '',
        testName: ''
      },
      onSubmit: submit,
      validationSchema
    })

  useEffect(() => {
    getLabRecordsNames({
      table_config_search_string: searchString
    })
  }, [debouncedSearchTerm])

  const handleSearchChange = event => {
    setSearchString(event.target.value)
  }

  const handleFileChange = event => {
    setFileNotFound(true)
    setReport(event.target.files)
  }

  const isError = (error, touched) => error && touched

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
        Add New Lab Reports
      </DialogTitle>
      <DialogContent>
        <Box className={classes.ModalForm}>
          <form noValidate={true}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  // multiple
                  options={lablist}
                  style={{ width: '100%' }}
                  getOptionLabel={option => {
                    if (option.type === 'others') {
                      let added = values.testName === option?.testname
                      if (added) {
                        return option?.testname
                      } else {
                        return option.title
                      }
                    }
                    if (option.inputValue) {
                      return option.title
                    }
                    return option?.testname
                  }}
                  loadingText="Loading..."
                  noOptionsText={
                    labsFetchingStatus ? 'Loading...' : 'No Options'
                  }
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  onChange={handlelabsChange}
                  filterOptions={(options, params) => {
                    const filtered = options
                    if (
                      options.length === 0 &&
                      !labsFetchingStatus &&
                      params.inputValue !== ''
                    ) {
                      filtered.push({
                        type: 'others',
                        title: `Add "${params.inputValue}"`,
                        testname: params.inputValue
                      })
                    }
                    return filtered
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className={classes.Input}
                      label="Lab Record Name *"
                      name={'testName'}
                      value={values.testName}
                      // variant="outlined"
                      error={isError(errors.type, touched.type)}
                      helperText={
                        isError(errors.type, touched.type) ? errors.type : null
                      }
                      loading={labsFetchingStatus}
                      onChange={handleSearchChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {labsFetchingStatus ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ padding: '8px 0' }}>
                <Box className={classes.InputCol}>
                  <TextField
                    multiline={true}
                    className={classes.textArea}
                    variant={'outlined'}
                    label="Instructions *"
                    name={'instructions'}
                    error={isError(errors.instructions, touched.instructions)}
                    helperText={
                      isError(errors.instructions, touched.instructions)
                        ? errors.instructions
                        : null
                    }
                    value={values.instructions}
                    onChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} style={{ padding: '8px 0' }}>
                <input
                  accept="image/*,.pdf,.doc*"
                  className={classes.uploadInput}
                  id="file-input"
                  name="file"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="file-input">
                  <Button
                    variant="contained"
                    color="#111d4e"
                    size="large"
                    className={classes.button}
                    component="span"
                    startIcon={<CloudUploadIcon />}>
                    Upload Report *
                  </Button>
                </label>
                {fileNotFound == false ? (
                  <Typography className={classes.error}>
                    File is mandatory
                  </Typography>
                ) : (
                  ''
                )}
                {report.length ? (
                  <Typography className={classes.fileUploaded}>
                    File Uploaded
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: '20px 40px' }}>
        <button onClick={handleClose} className={classes.modalSecondaryButton}>
          Cancel
        </button>
        <button onClick={handleSubmit} className={classes.modalPrimaryButton}>
          Add
        </button>
      </DialogActions>
    </Dialog>
  )
}

const validationSchema = yup.object().shape({
  testName: yup.string().trim().required('Test name is required'),
  type: yup.string().trim().required('Test name is required'),
  instructions: yup
    .string()
    .trim()
    .required('Instructions are required')
    .max(250, 'You have exceeded the character limit')
})

const convertBase64 = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = error => {
      reject(error)
    }
  })
}

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main
  },
  fileUploaded: {
    color: theme.palette.success.main,
    margin: '8px 0'
  },
  uploadInput: {
    display: 'none'
  },
  textArea: {
    width: '100%'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formControl: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  select: {
    width: '100%'
  },
  InputCol: {
    '& .MuiInput-root': {
      width: '100%'
    }
  },
  Textara: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    padding: '10px'
  },
  ModalBox: {
    width: '650px',
    background: '#fff',
    padding: '0px',
    '@media (max-width:767px)': {
      width: '100%'
    },
    // height:'400px',
    '& h2': {
      textAlign: 'left',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  ModalForm: {
    width: '100%',
    padding: '40px',
    '@media (max-width:767px)': {
      padding: '10px',
      height: '400px',
      overflowY: 'auto'
    }
  },
  DownloadBtn: {
    width: '120px',
    background: '#3f4b79',
    height: '40px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '40px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    margin: '20px auto 0',
    display: 'block'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ModalHead: {
    backgroundColor: '#111d4e',
    color: '#fff'
  },
  modalPrimaryButton: {
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
    padding: '5px 20px',
    borderRadius: '8px',
    color: '#fff',
    cursor: 'pointer',
    border: '1px solid #111d4e',
    height: 40
  },
  modalSecondaryButton: {
    backgroundColor: '#e4e4e4',
    padding: '5px 20px',
    borderRadius: '8px',
    color: '#111d4e',
    cursor: 'pointer',
    border: '1px solid #e4e4e4',
    height: 40
  }
}))

export default AddLabOrders
