import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import RuppeeIcon from '../../../../images/rupee.png'
import Earning from '../../../../images/earning.svg'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow:'0 1px 8px 0 rgba(0,0,0,0.1)',
    borderRadius:'10px',
    '& .MuiCardContent-root:last-child':{
      paddingBottom:'0px !important'
    }
  },
  avatar: {
    backgroundColor: '#9138aa',
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}))

const TotalCustomers = ({ className, earnings, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
          <Box style={{ display:'flex', alignItems:'center', marginBottom:'10px'}}>
             <img src={Earning} alt="Earning" style={{marginRight:'15px', width:'20px'}} />
             <h4 style={{margin:'0px', fontWeight:'600'}}>Total Earnings</h4>
            </Box>
            <Typography color="textPrimary" variant="h5">
            <i class="fa fa-inr"></i>{' '} {earnings.total_earnings ? earnings.total_earnings : '0'}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={1} display="flex" alignItems="center">
          
          <Typography className={classes.differenceValue} variant="body2">
            ₹ {earnings.fee_earnings ? earnings.fee_earnings : '0'}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Fees based earnings
          </Typography>
        </Box>
        <Box mt={0} display="flex" alignItems="center">
          <Typography className={classes.differenceValue} variant="body2">
            ₹ {earnings.service_earnings ? earnings.service_earnings : '0'}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Services based earnings
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

TotalCustomers.propTypes = {
  className: PropTypes.string
}

export default TotalCustomers
