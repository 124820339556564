import React, { useRef, useEffect } from 'react'
import {
  makeStyles,
  Avatar,
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from '@material-ui/core'
import { useFormik } from 'formik'
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber
} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useDropzone } from 'react-dropzone'
import * as yup from 'yup'
import {
  VALIDATE_PHONE,
  NETWORK_STATUS,
  VALIDATE_WHITESPACES
} from '../../Constants'
import { DoctorStore } from '../../stores'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'

const UpdateModal = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const [Image, previewImage] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [file, setFile] = React.useState('')
  const [name, setName] = React.useState(undefined)
  const [docfile, setDocFile] = React.useState('')
  const [inputValue, setInputValue] = React.useState('')
  const [color, setColor] = React.useState('')
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')

  const { medicalUpdateDetails } = DoctorStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const styles = { float: 'right', cursor: 'pointer', color: color }

  const hiddenFileInput = useRef()

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const genderChange = e => {
    formik.setFieldValue('gender', e.target.value)
  }

  const statusChange = e => {
    formik.setFieldValue('status', e.target.value)
  }

  const handleChange = event => {
    event.preventDefault()
    formik.setFieldValue('file', event.currentTarget.files[0])

    let reader = new FileReader()
    let fileimage = event.target.files[0]

    reader.onloadend = () => {
      previewImage(reader.result)
    }
    reader.readAsDataURL(fileimage)
  }

  const submit = async formData => {
    const file = formData.file
    if (file) {
      let base64 = await convertBase64(file)
      let format = base64.split(',')
      medicalUpdateDetails({
        ...formData,
        format
      })
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage("Wrong!", "Insert a Profile Pic!")
      setmultiButton(false)
      setAlertType('error')
    }
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const deleteProfile = () => { }

  //   const maxSize = 1048576

  //   const {
  //     acceptedFiles,
  //     fileRejections,
  //     isDragActive,
  //     isDragReject,
  //     getRootProps,
  //     getInputProps
  //   } = useDropzone({
  //     accept: 'image/jpeg, image/png , .pdf',
  //     minSize: 0,
  //     maxSize
  //   })

  //   React.useEffect(() => {
  //     if (acceptedFiles) {
  //       acceptedFiles.map(file => {
  //         setName(file.name)
  //         setDocFile(file)
  //       })
  //     }
  //   }, [acceptedFiles])

  const formik = useFormik({
    initialValues: {
      username: '',
      first_name: '',
      last_name: '',
      mobile_no: '',
      email: '',
      gender: '',
      dob: '',
      address: '',
      status: ''
    },
    //Control whether Formik should reset the form if initialValues changes (enableReinitialize)
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const phoneChange = value => {
    setInputValue(value)
    formik.setFieldValue('mobile_no', value ? value : '')
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <div>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      // secondaryClick={validationCheck}
      />
      <button
        type="button"
        ref={ref}
        className={classes.browse_button}
        onClick={handleOpen}>
        react-transition-group
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={open}>
          <div className={classes.PatientModal}>
            <Box className={classes.InfoText}>
              <h2 id="transition-modal-title">
                Update Details{' '}
                <span
                  style={styles}
                  onMouseEnter={() => setColor('red')}
                  onMouseLeave={() => setColor('')}
                  onClick={handleClose}>
                  X
                </span>
              </h2>

              <form
                onSubmit={formik.handleSubmit}
                noValidate={true}
                className={classes.root}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    className={classes.updateprofileform}>
                    <Grid container spacing={3} style={{ marginTop: '30px' }}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          id="username"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="User_Name"
                          onChange={formik.handleChange}
                          value={
                            formik && formik.values && formik.values.username
                          }
                          onBlur={formik.handleBlur}
                          name="username"
                          label="User Name *"
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.username && formik.errors.username
                                ? formik.errors.username
                                : null}
                            </div>
                          }
                        />

                        <TextField
                          id="first_name"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="First Name"
                          onChange={formik.handleChange}
                          value={
                            formik && formik.values && formik.values.first_name
                          }
                          onBlur={formik.handleBlur}
                          name="first_name"
                          label="First Name *"
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.first_name &&
                                formik.errors.first_name
                                ? formik.errors.first_name
                                : null}
                            </div>
                          }
                        />

                        <TextField
                          id="email"
                          style={{ width: '100%' }}
                          placeholder="jane@gmail.com"
                          name="email"
                          label="Email *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik && formik.values && formik.values.email}
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.email && formik.errors.email
                                ? formik.errors.email
                                : null}
                            </div>
                          }
                        />

                        <PhoneInput
                          placeholder="Enter phone number"
                          international
                          defaultCountry="IN"
                          id="mobile_no"
                          value={inputValue}
                          style={{
                            width: '100%',
                            paddingLeft: '10px',
                            paddingTop: '35px'
                          }}
                          name="mobile_no"
                          onChange={phoneChange}
                          error={
                            inputValue
                              ? isValidPhoneNumber(inputValue)
                                ? undefined
                                : 'Invalid phone number'
                              : 'Phone number required'
                          }
                        />
                        {
                          <div className={classes.errMsg2}>
                            {formik.touched.mobile_no && formik.errors.mobile_no
                              ? formik.errors.mobile_no
                              : null}
                          </div>
                        }
                      </Grid>
                      <Grid item xs={12} md={1} />
                      <Grid item xs={12} md={5}>
                        <FormControl className={classes.formControl}>
                          <InputLabel
                            shrink
                            id="demo-simple-select-placeholder-label-label">
                            Status
                          </InputLabel>
                          <Select
                            id="status"
                            value={formik.values.status}
                            name="status"
                            label="Status *"
                            onChange={statusChange}
                            style={{ width: '100%' }}
                            onBlur={formik.handleBlur}
                            helperText={
                              <div className={classes.errMsg}>
                                {formik.touched.status && formik.errors.status
                                  ? formik.errors.status
                                  : null}
                              </div>
                            }>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">In-Active</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          id="last_name"
                          style={{ width: '100%' }}
                          placeholder="Last name"
                          name="last_name"
                          label="Last name*"
                          value={
                            formik && formik.values && formik.values.last_name
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.last_name &&
                                formik.errors.last_name
                                ? formik.errors.last_name
                                : null}
                            </div>
                          }
                        />
                        <TextField
                          style={{ width: '100%' }}
                          id="dob"
                          label="Date of birth"
                          type="date"
                          name="dob"
                          helperText={
                            <div className={classes.errMsg}>
                              {formik.touched.dob && formik.errors.dob
                                ? formik.errors.dob
                                : null}
                            </div>
                          }
                          value={formik && formik.values && formik.values.dob}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />

                        <FormControl className={classes.formControl}>
                          <InputLabel
                            shrink
                            id="demo-simple-select-placeholder-label-label">
                            Gender
                          </InputLabel>
                          <Select
                            id="gender"
                            value={formik.values.gender}
                            name="gender"
                            label="Gender *"
                            onChange={genderChange}
                            style={{ width: '100%' }}
                            onBlur={formik.handleBlur}
                            helperText={
                              <div className={classes.errMsg}>
                                {formik.touched.gender && formik.errors.gender
                                  ? formik.errors.gender
                                  : null}
                              </div>
                            }>
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="address"
                        style={{ width: '100%' }}
                        placeholder="Address"
                        name="address"
                        label="Address *"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik && formik.values && formik.values.address}
                        helperText={
                          <div className={classes.errMsg}>
                            {formik.touched.address && formik.errors.address
                              ? formik.errors.address
                              : null}
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className={classes.updateprofilecol}>
                    <Box
                      style={{
                        width: '140px',
                        height: '140px',
                        background: 'white',
                        borderRadius: '50%',
                        boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
                0 2px 2px rgba(0,0,0,0.12), 
                0 4px 4px rgba(0,0,0,0.12), 
                0 8px 8px rgba(0,0,0,0.12),
                0 16px 16px rgba(0,0,0,0.12)`,
                        margin: '25px auto',
                        textAlign: 'right',
                        padding: '10px',
                        display: 'flex'
                      }}>
                      {Image ? (
                        <Avatar className={classes.AvatarCol} src={Image} />
                      ) : null}
                      {/* {Image ? <p>Loading...</p> : <div>First Name</div>} */}
                    </Box>
                    <label
                      style={{
                        textAlign: 'center',
                        margin: '20px auto',
                        display: 'block',
                        fontWeight: 'bold',
                        width:'150px'
                      }}>
                      <div>
                        <Button
                          onClick={handleClick}
                          style={{
                            width: '140px',
                            borderRadius: '50px',
                            background: '#3f4b79',
                            border: 'none',
                            height: '40px',
                            textAlign: 'center',
                            color: '#fff',
                            // lineHeight: '40px',
                            cursor: 'pointer'
                          }}>
                          Edit Picture
                        </Button>
                        <input
                          id="file"
                          name="file"
                          type="file"
                          accept=" image/jpeg, image/png"
                          ref={hiddenFileInput}
                          className={classes.browse_button}
                          onChange={handleChange}
                        // className="form-control"
                        />
                      </div>
                    </label>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.updateprofilebutton}>
                    <button
                      type={'submit'}
                      style={{
                        width: '140px',
                        borderRadius: '50px',
                        background: '#3f4b79',
                        border: 'none',
                        height: '40px',
                        textAlign: 'center',
                        color: '#fff',
                        lineHeight: '40px',
                        cursor: 'pointer'
                      }}>
                      Save
                    </button>

                    <button
                      className={classes.CancelBtn}
                      onClick={deleteProfile}>
                      Delete
                    </button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  )
})
const validationSchema = yup.object().shape({
  username: yup
    .string()
    .required('User Name is mandatory')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining'),
  first_name: yup
    .string()
    .required('Name is required')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining'),
  address: yup
    .string()
    .required('Address is Required')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining'),

  email: yup
    .string()
    .email('Invalid email')
    .required('Email is Required')
    .matches(VALIDATE_WHITESPACES, 'Space should not come at begining'),
  mobile_no: yup
    .string()
    // .matches(VALIDATE_PHONE, " Check with +91")
    .required('Mobile number is Required'),
  gender: yup.string().required('Gender is required'),
  status: yup.string().required('Status is required'),
  dob: yup
    .string()
    // .matches(/\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])/, "incorrect date format" , { excludeEmptyString: true })
    .required('Dob is required')
})

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0)
    }
  },
  browse_button: {
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '99',
    cursor: 'pointer',
    display: 'none',
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    fontSize: '50px',
    /*Opacity settings for all browsers*/
    opacity: '0',
    mozOpacity: '0',
    msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
  },
  formControl: {
    width: '100%',
    margin: '12px 8px 8px'
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  CancelBtn: {
    width: '140px',
    borderRadius: '50px',
    background: '#3f4b79',
    border: 'none',
    height: '40px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '40px',
    cursor: 'pointer'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  PatientModal: {
    width: '60%',
    background: '#fff',
    padding: '10px 40px'
    // height:"60%"
  },
  InfoText: {
    width: '100%',
    '& h2': {
      fontSize: '20px'
    }
  },
  CancelBtn: {
    width: '140px',
    borderRadius: '50px',
    background: 'darkred',
    border: 'none',
    height: '40px',
    textAlign: 'center',
    color: 'white',
    lineHeight: '40px',
    cursor: 'pointer'
  },
  errMsg: {
    color: 'red'
  },
  errMsg2: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingTop: '4px'
  }
}))

export default UpdateModal
