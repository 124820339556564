import { action, thunk, computed } from 'easy-peasy'

import BaseModel from '../Base'
import { NETWORK_STATUS, CONSULTATION_TYPE } from '../../Constants'
import api from '../../api'

export default {
  ...BaseModel(),

  countryList: [],
  stateList: [],
  cityList: [],
  profileDetails: [],
  fetchingCountry: false,
  fetchingState: false,
  fetchingCity: false,
  dashboardData: [],
  clinicSubscription: null,
  getSubscriptionList: null,
  activationResponse: null,
  transactionDetails: null,
  paymentVerificationResponse: null,
  prepaidBillingDetails: null,

  getClinicProfileDetails: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.setVerificationResponse(null)
      actions.setActivationResponse(null)
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })
      if (
        payload?.cachedValues &&
        localStorage.getItem('clinic_profile_details')
      ) {
        let profileDetails = JSON.parse(
          localStorage.getItem('clinic_profile_details')
        )
        actions.fetchedProfileDetails(profileDetails)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        const response = await api.clinic.getClinicProfileDetails({})
        if (response.data.statusCode == '200') {
          actions.fetchedProfileDetails(response.data.data)
          localStorage.setItem(
            'clinic_profile_details',
            JSON.stringify(response.data.data)
          )
          actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        } else {
          actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        }
      }
      return
    }
  ),

  fetchedProfileDetails: action((state, payload) => {
    if (payload) {
      state.profileDetails = payload
    }
  }),

  updatedClinicProfile: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })
      const response = await api.clinic.updateClinicProfileDetails({
        name: payload?.clinicName,
        email: payload?.email,
        phone: payload?.phone,
        address: payload?.address,
        pincode: payload?.pincode,
        city: payload?.city_id,
        state: payload?.state_id,
        country: payload?.country_id,
        about: payload?.about,
        gst_no: payload?.gst,
        reg_no: payload?.regno,
        bank_acc_no: payload?.account_no,
        bank_ifsc: payload?.ifsc_code,
        bank_name: payload?.bank_name,
        bank_benificiary_name: payload?.beneficiary_name,
        bank_branch_name: payload?.branch_name,
        logo_image_name:
          payload && payload?.file && payload?.file?.name
            ? payload?.file?.name
            : '',
        logo_image:
          payload && payload.format && payload.format[1]
            ? payload.format[1]
            : ''
      })
      if (response.ok) {
        actions.updateResponse(response.data)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        localStorage.removeItem('clinic_profile_details')
        // window.location.reload()
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  updateResponse: action((state, payload) => {
    if (payload) {
      state.updatedClinicSuccessMessage = payload
    }
  }),

  fetchCountryList: thunk(async (actions, payload) => {
    actions.fetchingCountryStatus(true)
    const response = await api.getCountryStateCity({})
    if (response.ok) {
      actions.fetchedCountryList(response.data.data)
      actions.fetchedStateList([])
      actions.fetchingCountryStatus(false)
    }
  }),

  fetchingCountryStatus: action((state, payload) => {
    state.fetchingCountry = payload
  }),

  fetchedCountryList: action((state, payload) => {
    if (payload) {
      state.countryList = payload
    }
  }),

  fetchStateList: thunk(async (actions, payload) => {
    if (payload) {
      actions.fetchingStateStatus(true)
      const response = await api.getCountryStateCity({
        country_id: payload
      })
      if (response.ok) {
        actions.fetchingStateStatus(false)
        actions.fetchedStateList(response.data.data)
        actions.fetchedCityList([])
      }
    }
  }),

  fetchingStateStatus: action((state, payload) => {
    state.fetchingState = payload
  }),

  fetchedStateList: action((state, payload) => {
    if (payload) {
      state.stateList = payload
    }
  }),

  fetchCityList: thunk(async (actions, payload) => {
    if (payload) {
      actions.fetchingCityStatus(true)

      const response = await api.getCountryStateCity({ state_id: payload })
      if (response.ok) {
        actions.fetchingCityStatus(false)
        actions.fetchedCityList(response.data.data)
      }
    }
  }),

  fetchingCityStatus: action((state, payload) => {
    state.fetchingCity = payload
  }),

  fetchedCityList: action((state, payload) => {
    if (payload) {
      state.cityList = payload
    }
  }),

  clearState: action((state, payload) => {
    state.stateList = []
    state.cityList = []
    state.docdetailAddedStatus = undefined
    state.docbankdetailsAddedStatus = undefined
    state.subscriptionChoiceStatus = undefined
  }),

  clearCity: action((state, payload) => {
    state.cityList = []
  }),

  getClinicDashboardData: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.clinic.clinicDashboardData({
      start_date: payload?.start_date,
      end_date: payload?.end_date
    })
    if (response.data.statusCode == '200') {
      actions.setDashboardData(response.data)
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setDashboardData: action((state, payload) => {
    state.dashboardData = payload
  }),

  // Add Doctor
  doctorDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    // return
    api.setAuthHeaders({
      Authorization: localStorage.getItem('clinic_auth_key')
    })
    const response = await api.hospital.createDoctorDetails({
      name: payload?.name,
      user_name: payload?.username,
      email: payload?.email.toLowerCase(),
      user_short_code: payload?.user_short_code,
      picture_name: payload.fileName,
      bio: payload?.bio,
      experience: payload?.experience,
      picture:
        payload && payload.format && payload.format[1] ? payload.format[1] : '',
      specialization: payload?.specialization,
      qualification: payload?.qualification,
      mobile_no: `${payload?.mobile_no}`,
      is_new_user: 'true',
      department_id: '2af4713f-1678-4a40-98aa-3f2d19ff0c88',
      role_id: '52c81c76-5236-4085-adfb-e8b4c7e4ba86',
      registration_no: payload?.registration_no,
      document:
        payload && payload.format && payload.format[1] ? payload.format[1] : '',
      document_name: payload && payload?.docName ? payload?.docName : '',
      country_code: payload?.country_id,
      state_code: payload?.state_id,
      city_code: payload?.city_id,
      pincode: payload?.pincode,
      associated_with: payload?.associated_with
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDocdetailsAddedStatus(response.data)
      localStorage.setItem('doctor_id', response.data.doctor_id)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDocdetailsAddedStatus: action((state, payload) => {
    state.docdetailAddedStatus = payload
  }),

  doctorBankDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    api.setAuthHeaders({
      Authorization: localStorage.getItem('clinic_auth_key')
    })
    const response = await api.hospital.addDoctorBankDetails({
      // doctor_id: localStorage.getItem('doctor_id') == null
      //     ? payload?.doctor_id
      //     : localStorage.getItem('doctor_id'),
      doctor_id: localStorage.getItem('doctor_id'),
      user_id: localStorage.getItem('user_id'),
      primary_account_no: payload?.account_no,
      primary_branch_name: payload?.branch_name,
      primary_bank_name: payload?.bank_name,
      primary_ifsc_code: payload?.ifsc_code,
      primary_beneficiary_name: payload?.beneficiary_name
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setDocbankdetailsAddedStatus(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  setDocbankdetailsAddedStatus: action((state, payload) => {
    state.docbankdetailsAddedStatus = payload
  }),

  subscriptionPlanList: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    let clinic_id = localStorage.getItem('clinic_user_id')
    const response = await api.hospital.getSubscriptionList({
      clinic_id: clinic_id ? clinic_id : localStorage.getItem('clinic_id')
      // clinic_id: 'f1e86a6b-d80d-48e8-addf-fca38da22da5'
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.getSubscriptionPlanList(response.data.response)
    } else {
      actions.getSubscriptionPlanList(null)
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getSubscriptionPlanList: action((state, payload) => {
    state.getSubscriptionList = payload
  }),

  addSubscriptionChoice: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.hospital.subscriptionChoice({
      doctor_id: localStorage.getItem('doctor_id'),
      subscription_id: payload?.subscription_id
    })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.subscriptionChoiceAdded(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  subscriptionChoiceAdded: action((state, payload) => {
    state.subscriptionChoiceStatus = payload
  }),

  getClinicSubscriptionDetails: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })

      const response = await api.clinic.clinicSubscriptionView({
        clinic_id: localStorage.getItem('clinic_user_id')
      })
      if (response.ok) {
        actions.fetchedClinicSubscription(response.data)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.fetchedClinicSubscription(null)
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  fetchedClinicSubscription: action((state, payload) => {
    state.clinicSubscription = payload
  }),

  activateClinicSubscription: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      api.setAuthHeaders({
        Authorization: localStorage.getItem('clinic_auth_key')
      })

      const response = await api.clinic.clinicActivateSubscription({
        clinic_id: localStorage.getItem('clinic_user_id'),
        subscription_id: payload.subscription_id
      })
      if (
        Number(response.data.statusCode) >= 200 &&
        Number(response.data.statusCode) < 300
      ) {
        actions.setActivationResponse(response.data)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.setActivationResponse({ ...response.data, err: true })
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setActivationResponse: action((state, payload) => {
    state.activationResponse = payload
  }),

  initializePayment: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      // api.setAuthHeaders({
      //   Authorization: localStorage.getItem('clinic_auth_key')
      // })
      const response = await api.clinic.clinicInitializePayment({
        clinic_id: payload?.clinic_id || localStorage.getItem('clinic_user_id'),
        subscription_history_id: payload.subscription_id
      })
      if (
        Number(response.data.statusCode) >= 200 &&
        Number(response.data.statusCode) < 300
      ) {
        actions.setTransactionDetails(response.data)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.setTransactionDetails({ ...response.data, err: true })
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setTransactionDetails: action((state, payload) => {
    state.transactionDetails = payload
  }),

  clinicVerifyPayment: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      let clinic_id = localStorage.getItem('clinic_user_id')
      const response = await api.clinic.clinicVerifyPayment({
        transaction_id_pg: payload.transaction_id_pg,
        transaction_id_wallet: payload.transaction_id_wallet,
        clinic_id: clinic_id ? clinic_id : localStorage.getItem('clinic_id')
      })
      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setVerificationResponse(response?.data)
      } else {
        actions.setVerificationResponse({ ...response?.data, err: true })
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  setVerificationResponse: action((state, payload) => {
    state.paymentVerificationResponse = payload
  }),

  getClinicBill: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
      const response = await api.clinic.clinicGetBill({
        clinic_slug: payload
      })
      if (
        Number(response?.data?.statusCode) >= 200 &&
        Number(response?.data?.statusCode) < 300
      ) {
        response.data.subscription_bill_details.billing_start_date_formatted = response.data.subscription_bill_details?.billing_start_date
          .split('-')
          .reverse()
          .join('/')
        response.data.subscription_bill_details.billing_end_date_formatted = response.data.subscription_bill_details?.billing_end_date
          .split('-')
          .reverse()
          .join('/')
        actions.setBillingDetails(response.data)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      } else {
        actions.setBillingDetails({ ...response.data, err: true })
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  paymentInitalize: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    let clinic_id = localStorage.getItem('clinic_user_id')

    const response = await api.clinic.paymentInitalize({
      subscription_history_id: payload?.subscription_history_id,
      subscription_id: payload?.subscription_id,
      clinic_id: clinic_id ? clinic_id : localStorage.getItem('clinic_id')
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setPaymentTransactionDetails(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setPaymentTransactionDetails: action((state, payload) => {
    state.transactionDetails = payload
  }),

  paymentCalculate: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    let clinic_id = localStorage.getItem('clinic_user_id')
    let payloadData = !payload.activePlan
      ? {
          new_plan_details: {
            slug: payload?.planType,
            plan_name: payload?.planName
          },
          clinic_id: clinic_id ? clinic_id : localStorage.getItem('clinic_id')
          //  subscription_history_id:uuid
        }
      : {
          clinic_id: clinic_id ? clinic_id : localStorage.getItem('clinic_id')
        }

    const response = await api.clinic.billingDetails(payloadData)

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setBillingDetails({
        ...response?.data?.response,
        err: true
      })
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setBillingDetails: action((state, payload) => {
    state.prepaidBillingDetails = payload
  })
}
