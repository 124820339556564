import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Paper,
  makeStyles,
  withStyles
} from '@material-ui/core'

import ManualPrescriptionList from './PrintPrescriptionDialogComponents/ManualPrescriptionList'
import DefaultPrescriptionList from './PrintPrescriptionDialogComponents/DefaultPrescriptionList'

const Card = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    height: '100%'
  }
}))(props => <Paper {...props} elevation={3} />)

const PrintPrescriptionDialog = ({ open, onClose, appointmentData }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Print Prescription</DialogTitle>
      <DialogContent>
        <div className={classes.grid}>
          <div>
            <Card>
              <ManualPrescriptionList
                open={open}
                appointmentId={appointmentData.id}
              />
            </Card>
          </div>
          <div>
            <Card>
              <DefaultPrescriptionList
                open={open}
                appointmentData={appointmentData}
              />
            </Card>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end" style={{ padding: '0 1rem' }}>
          <Grid item>
            <Button onClick={onClose} color="secondary">
              Exit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default PrintPrescriptionDialog

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 1fr))',
    justifyContent: 'center',
    gridColumnGap: theme.spacing(3),
    columnGap: theme.spacing(3),
    gridRowGap: theme.spacing(6),
    rowGap: theme.spacing(6),
    marginBottom: theme.spacing(4)
  }
}))
