import React, { useEffect, useState } from 'react'
import { Avatar, Box, Grid, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { IoIosArrowForward } from 'react-icons/io'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import { PatientStore } from '../../stores'
import { PATIENT_ID, PATIENT_USER_ID } from '../../Constants'
import { CONSULTATION_TYPE } from '../../Constants'
import * as moment from 'moment'
import DoctorDefaultImg from '../../images/doctor-default-profile-image.png'
import ListBgImg from '../../images/listbg.png'
import ListBgHoverImg from '../../images/listbghover.png'

const PatientCards = ({ appointments }) => {
  const classes = useStyles()

  const [selectedAppointment, setSelectedAppointment] = useState([])
  const { viewVisitSummary } = PatientStore.useStoreActions(actions => ({
    ...actions.patients
  }))
  const { viewSummary } = PatientStore.useStoreState(state => ({
    ...state.patients
  }))

  useEffect(() => {
    viewVisitSummary({
      user_id: localStorage.getItem(PATIENT_USER_ID), //'2a717c90-44d2-4fb4-b17e-a3ca0b21931f',
      patient_id: localStorage.getItem(PATIENT_ID), //'eef21d7d-c0e8-4ab0-ad6d-dd9f528af102',
      view_type: 'view',
      appointment_id: 'f59b1443-8597-4ab3-8ddf-dd701cb4cf06'
    })
  }, [])

  useEffect(() => {
    setSelectedAppointment(viewSummary)
  }, [viewSummary])

  const visit_type_list = [
    { id: '1', label: 'Initial consult' },
    { id: '2', label: 'Follow-up' }
  ]
  const booking_status_list = [
    {
      id: '1',
      label: 'Booked'
    },
    {
      id: '2',
      label: 'Confirmed'
    },
    {
      id: '3',
      label: 'Checked in'
    },
    {
      id: '4',
      label: 'In-Progress'
    },
    {
      id: '5',
      label: 'Cancelled'
    },
    {
      id: '6',
      label: 'No Show'
    },
    {
      id: '7',
      label: 'Completed'
    },
    {
      id: '8',
      label: 'Rescheduled'
    }
  ]

  return (
    <>
      {appointments.map(appointment => {
        const {
          id,
          name,
          image,
          appointment_date,
          appointment_type,
          doctor_name,
          status,
          patient_id,
          start_time,
          end_time,
          visit_type,
          doctor_pic_uri,
          purpose_of_consultation
        } = appointment
        return (
          <div className={classes.patientCard}>
            <Avatar
              src={doctor_pic_uri ? doctor_pic_uri : DoctorDefaultImg}
              alt={name}
              className={classes.image}
            />
            <div className={classes.patientCardLayout}>
              <Grid item sm={12}>
                <Typography className={classes.name}>{doctor_name}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography className={classes.appointmentDetails}>
                  <Box className={classes.Startdate}>
                    <div className={classes.iconContainer}>
                      <i>
                        <svg
                          className={classes.icon}
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24">
                          <path
                            d="M7 12h2v2H7v-2m14-6v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1V2h2v2h8V2h2v2h1a2 2 0 0 1 2 2M5 8h14V6H5v2m14 12V10H5v10h14m-4-6v-2h2v2h-2m-4 0v-2h2v2h-2m-4 2h2v2H7v-2m8 2v-2h2v2h-2m-4 0v-2h2v2h-2z"
                            fill="#3f4b79"
                          />
                          <rect
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                            fill="rgba(0, 0, 0, 0)"
                          />
                        </svg>
                      </i>
                    </div>
                    <span>
                      {moment(appointment_date).format('DD-MM-YYYY')}{' '}
                    </span>
                  </Box>
                  <Box className={classes.Startdate}>
                    <div className={classes.iconContainer}>
                      <i>
                        <svg
                          className={classes.icon}
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24">
                          <path
                            d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7h1.5z"
                            fill="#3f4b79"
                          />
                          <rect
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                            fill="rgba(0, 0, 0, 0)"
                          />
                        </svg>
                      </i>
                    </div>
                    <span>
                      {moment(start_time, 'hh:mm:ss').format('hh:mm A')}-
                      {moment(end_time, 'hh:mm:ss').format('hh:mm A')}
                    </span>
                  </Box>
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography className={classes.appointmentDetails}>
                  {'Status: '}
                  <span>
                    {
                      booking_status_list.find(option => option.id === status)
                        ?.label
                    }
                  </span>
                </Typography>
                <Typography className={classes.appointmentDetails}>
                  {'Visit Type: '}
                  <span>
                    {
                      visit_type_list.find(option => option.id === visit_type)
                        .label
                    }
                  </span>
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography className={classes.appointmentDetails}>
                  {'Appointment Mode : '}
                  <div>
                    <Button variant="contained" className={classes.OnlineBtn}>
                      {appointment_type == CONSULTATION_TYPE.IN_PERSON.value
                        ? CONSULTATION_TYPE.IN_PERSON.label
                        : CONSULTATION_TYPE.TELE.label}
                    </Button>
                  </div>
                </Typography>
              </Grid>
            </div>
            <IconButton className={classes.button}>
              <Link
                style={{ position: 'absolute', right: '0', left: '0' }}
                to={{
                  pathname: '/patient/details' + '/' + id
                }}>
                <IoIosArrowForward
                  className={classes.icon}
                  size={32}
                  color="#fff"
                />
              </Link>
            </IconButton>
          </div>
        )
      })}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  patientCardLayout: {
    display: 'flex',
    width: '85%',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexDirection:'column',
      width:'100%'
    }
  },
  patientCard: {
    width: '100%',
    background: `url(${ListBgImg}) center top no-repeat`,
    padding: '10px',
    borderRadius: '20px',
    backgroundSize: 'cover',
    display: 'flex',
    marginBottom: '15px',
    flexWrap: 'wrap',
    // '&:hover p': {
    //   color: '#fff'
    // },
    // '&:hover p span': {
    //   color: '#fff'
    // },
    '@media (max-width:767px)': {
      flexDirection: 'column',
      alignItems: 'center'
    },
    '&:hover': {
      background: `url(${ListBgHoverImg}) center top no-repeat`,
      backgroundSize: 'cover',
      color: '#fff'
    }
  },
  image: {
    width: '60px',
    height: '60px',
    marginRight: '15px',
    marginTop: '3px',
    flex: '0 1 60px',
    order: '0'
  },
  Startdate: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: '5px'
    }
  },
  name: {
    fontSize: '16px',
    color: theme.palette.primary.dark,
    fontWeight: '600',
    marginTop: '0px',
    display: 'flex',
    marginBottom: '10px',
    paddingRight: 8
  },
  OnlineBtn: {
    textAlign: 'center',
    cursor: 'default',
    backgroundColor: '#98e039',
    borderRadius: '40px',
    color: 'black',
    boxShadow: 'none',
    padding: '3px 30px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#98e039',
      boxShadow: 'none'
    }
  },
  appointmentDetails: {
    color: theme.palette.primary.dark,
    fontWeight: '600',
    fontSize: '12px',
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '5px',
    '@media (max-width:767px)': {
      marginBottom: '5px',
      marginTop: '5px',
      fontSize:'14px'
    },
    '& span': {
      color: theme.palette.primary.main
    }
  },
  button: {
    height: 40,
    width: 40,
    padding: '8px',
    background: theme.palette.primary.main,
    alignSelf: 'center',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  icon: {
    width: '1.5em',
    height: '1.5em',
    position: 'relative',
    top: '1px'
  },
  item: {
    alignItems: 'center'
  },
  iconContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '18% 70%'
  }
}))
export default PatientCards
