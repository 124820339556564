import React from 'react'
import {
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { DoctorStore } from '../../stores'

const useStyles = makeStyles(() => ({
  userForm: {
    width: '100%',
    padding: '0px'
  },
  requestbtn: {
    width: '200px',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#5a5f95',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    marginBottom: '30px',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  confirmbtn: {
    width: '48%',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#5a5f95',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  cancelbtn: {
    width: '48%',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: '#9b0843',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '16px',
    justifyContent: 'center',
    border: 'none',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  blancebox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    width: '40%'
  },
  btncol: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px'
  },
  tablecontent: {
    fontWeight: '400',
    '& h6': {
      fontSize: '16px',
      padding: '15px 10px'
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      margin: '0px'
    },
    '& p': {
      fontSize: '16px',
      marginBottom: '0px',
      fontWeight: '400'
    },
    '& .MuiTableCell-head': {
      color: '#fff'
    },
    '& .MuiTableCell-root': {
      padding: '20px 10px'
    },
    '& .MuiPaper-rounded': {
      boxShadow: 'none !important',
      borderRadius: '0px !important'
    }
  },
  bottomcol: {
    '& h3': {
      fontSize: '18px',
      color: '#5a5f95',
      fontWeight: 'bold'
    }
  },
  accountbox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 3px 10px 2px rgba(91, 104, 152, 0.25);',
    padding: '20px',
    marginTop: '15px',
    '& p': {
      fontSize: '20px',
      color: '#a2a2a2',
      margin: '0px'
    },

    '& h3': {
      fontSize: '40px',
      color: '#374370',
      margin: '0px',
      lineHeight: '40px'
    }
  }
}))
function createData(name, Startdate, Validtill, Carddetails, Status) {
  return { name, Startdate, Validtill, Carddetails, Status }
}

const rows = [
  createData(
    'Monthly Basic Plan 799',
    <p>04-10-2020</p>,
    <p>04-11-2020</p>,
    <p>xxxxx1990</p>,
    <p>Cancelled</p>
  ),
  createData(
    'Monthly Basic Plan 799',
    <p>04-10-2020</p>,
    <p>04-11-2020</p>,
    <p>xxxxx1990</p>,
    <p>Cancelled</p>
  ),
  createData(
    'Monthly Basic Plan 799',
    <p>04-10-2020</p>,
    <p>04-11-2020</p>,
    <p>xxxxx1990</p>,
    <p>Cancelled</p>
  )
]
export default function Subscriptionplan() {
  const classes = useStyles()
  const [age, setAge] = React.useState('')

  const handleChange = event => {
    setAge(event.target.value)
  }

  const { getDoctorSubscriptionHistory } = DoctorStore.useStoreActions(
    actions => ({
      ...actions.profile
    })
  )

  const {
    doctorSubscriptionHistory,
    networkStatus
  } = DoctorStore.useStoreState(state => ({
    ...state.profile
  }))

  React.useEffect(() => {
    getDoctorSubscriptionHistory()
  }, [])

  React.useEffect(() => {
    if (doctorSubscriptionHistory.length !== 0) {
    }
  }, [doctorSubscriptionHistory])
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} className={classes.tablecontent}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow style={{ background: '#596390', color: '#fff' }}>
                <TableCell>
                  <h5>Subscription Plan</h5>
                </TableCell>
                <TableCell align="right">
                  <h5>Start Date</h5>
                </TableCell>
                <TableCell align="right">
                  <h5>Valid Till</h5>
                </TableCell>
                <TableCell align="right">
                  <h5>Card Details</h5>
                </TableCell>
                <TableCell align="right">
                  <h5>Status</h5>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name} className={classes.tablerow}>
                  <TableCell component="th" scope="row">
                    <p>{row.name}</p>
                  </TableCell>
                  <TableCell align="right">{row.Startdate}</TableCell>
                  <TableCell align="right">{row.Validtill}</TableCell>
                  <TableCell align="right">{row.Carddetails}</TableCell>
                  <TableCell align="right">{row.Status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.bottomcol}>
        <button className={classes.requestbtn}>Activate Plan</button>
        <h3>
          <Link to="/doctor/pricing">Checkout other plans & pricings </Link>
        </h3>
      </Grid>
    </Grid>
  )
}
