import { action, thunk } from 'easy-peasy'
import localforage from 'localforage'
import BaseModel from '../Base'
import { NETWORK_STATUS, PATIENT_USER_ID } from '../../Constants'
import api from '../../api'
import moment from 'moment'

export default {
  ...BaseModel(),
  userId: null,
  username: null,
  patientProfile: [],
  idToken: '',
  patientAccountsList: [],
  selectedPatient: [],
  profileUpdateStatus: '',
  patientAddedStatus: '',
  message: null,
  userName: '',

  setData: action((state, payload) => {
    if (payload) {
      state.userId = payload && payload.user_id
      state.username = payload && payload.user_name
      state.patientProfile = payload && payload.self_patient_data
      state.idToken = payload && payload.id_token
    }
  }),

  getAccountsList: thunk(async (actions, payload, { getStoreState }) => {
    actions.setPatientAccountsList([])
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.getPatientAccountsList({
      user_id: localStorage.getItem(PATIENT_USER_ID),
      table_config_rows_per_page: '25',
      table_config_page_no: '1'
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      if (response.data.response) {
        actions.setPatientAccountsList(response.data.response)
      }
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  updatedPatientProfile: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    let event = new Date(payload.DOB)
    // let date = JSON.stringify(event)
    let date = moment(event).format('YYYY-MM-DD')

    const response = await api.patient.updatedPatientProfile({
      patient_id: payload?.patient_id,
      first_name: payload?.first_name,
      // middle_name: payload?.middle_name,
      last_name: payload?.last_name,
      mobile_no: payload?.mobile_no,
      email: payload?.email.toLowerCase(),
      address: payload?.address,
      country_id: payload?.country_id,
      state_id: payload?.state_id,
      city_id: payload?.city_id,
      pincode: payload?.pincode,
      dob: date,
      gender: payload?.gender,
      username: payload?.username,
      password: payload?.password,
      user_id: payload?.user_id,
      picture_name:
        payload && payload?.file && payload?.file?.name
          ? payload?.file?.name
          : '',
      // picture_name:
      //   payload && payload.format && payload.format[0] ? payload.format[0] : '',
      picture:
        payload && payload.format && payload.format[1] ? payload.format[1] : '',
      user_type: payload.user_type
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setProfileUpdateStatus(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setProfileUpdateStatus: action((state, payload) => {
    state.profileUpdateStatus = payload
  }),

  snackClear: action((state, payload) => {
    state.profileUpdateStatus = undefined
  }),

  getPatientProfile: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    const response = await api.patient.getPatientProfile({
      patient_id: payload?.patient_id,
      user_type: payload?.user_type
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setUserName(response?.data?.user_details?.username)

      localStorage.setItem("email" , response.data.user_details.email)
      localforage.setItem('selected_patient', response.data.user_details)

      localStorage.setItem('Photo_uri', response.data.user_details.photo_uri)
    }
  }),

  setUserName: action((state, payload) => {
    state.userName = payload
  }),

  setPatientAccountsList: action((state, payload) => {
    state.patientAccountsList = payload
  }),
  setSelectedPatient: action((state, payload) => {
    state.selectedPatient = payload
  }),

  addPatientDetails: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    let event = new Date(payload.dob)
    // let date = JSON.stringify(event)
    let date = moment(event).format('YYYY-MM-DD')
    // date = date.slice(1, 11)

    const response = await api.patient.setPatientinfoDetails({
      user_id: localStorage.getItem(PATIENT_USER_ID),
      first_name: payload && payload.first_name,
      middle_name: '',
      last_name: payload && payload.last_name,
      mobile_no: payload && payload.mobile_no,
      email: payload && payload.email.toLowerCase(),
      address: payload && payload.address,
      pincode: payload && payload.pincode,
      country_id: payload && payload.country_id,
      state_id: payload && payload.state_id,
      city_id: payload && payload.city_id,
      dob: date,
      gender: payload.gender,
      primary_doctor_id: ''
    })
    if (response.data.statusCode === 200) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setPatientAddedStatus(response.data)
      actions.getAccountsList({})
      actions.getpatientDetailResponse(response.data.response)
    } else {
      actions.setPatientAddedStatus(response.data)
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setPatientAddedStatus: action((state, payload) => {
    state.patientAddedStatus = payload
  }),

  getpatientDetailResponse: action((state, payload) => {
    state.patientresponseData = payload
  }),

  doPatientRegistration: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.patient.doPatientRegistration({ ...payload })
    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.updateResponse(response.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  updateResponse: action((state, payload) => {
    if (payload) {
      state.message = payload
    }
  })
}
