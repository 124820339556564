import { Box, Grid, MenuItem } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import NativeSelect from '@material-ui/core/NativeSelect'
import { makeStyles } from '@material-ui/core/styles'
import doctor from '../../../../../src/images/doctor.svg'
import clinic from '../../../../../src/images/hospital.svg'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Donut from './semiDonut'
import { SuperClinicStore } from '../../../../stores'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))
const ClinicsAndDoctors = () => {
  const classes = useStyles()
  const {
    getClinicDoctorFiveRevenue,
    getClinicDoctorFiveServices,
    getMostClinicConsultations,
    getMostDoctorConsultations,
    getClinicList
  } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.dashboard
  }))

  const { clinicLists } = SuperClinicStore.useStoreState(state => ({
    ...state.dashboard
  }))
  const [clinic, setClinic] = useState('')
  const periodLists = [
    { id: 'Last_week', label: 'Last week' },
    { id: 'Last_month', label: 'Last month' },
    { id: 'Last_3_month', label: 'Last 3 month' },
    { id: 'Last_6_month', label: 'Last 6 month' },
    { id: 'Last_9_month', label: 'Last 9 month' },
    { id: 'This_Quarter', label: 'This Quarter' },
    { id: 'Last_Quarter', label: 'Last Quarter' }
  ]

  const handleRevenueGenerate = e => {
    getClinicDoctorFiveRevenue({
      data_period: e.target.value
    })
  }
  const handleClinicConsultation = e => {
    getMostClinicConsultations({
      data_period: e.target.value
    })
  }
  const handleClinicChange = e => {
    setClinic(e.target.value)
  }

  useEffect(() => {
    getClinicList({})
  }, [])

  return (
    <Box className="page-content">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} style={{ paddingTop: '0px' }}></Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-col2">
            <Box className="table-top">
              <h4>Top 5 Revenue Generated</h4>
              <FormControl
                className={classes.formControl}
                style={{ minWidth: '45%', marginTop: '0px' }}>
                <InputLabel htmlFor="age-native-simple">
                  Select Data Period
                </InputLabel>
                <Select
                  value={periodLists.id}
                  onChange={handleRevenueGenerate}
                  name="data"
                  id="data-list"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    anchorReference: 'anchorEl',
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    },
                    getContentAnchorEl: null
                  }}>
                  {periodLists.map(({ label, id }) => (
                    <MenuItem key={id} value={id}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Table
              className={classes.table}
              aria-label="simple table"
              style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: 'rgba(17,29,78,0.7)' }}>
                    Name of Services
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: 'rgba(17,29,78,0.7)' }}>
                    Services Count
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: 'rgba(17,29,78,0.7)' }}>
                    Revenue generated
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>X-Ray</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Blood Test</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sugar Test</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ECG Test</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>X-Ray</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-col2">
            <Box className="table-top">
              <h4>Top 5 Clinics Services</h4>
              <FormControl
                className={classes.formControl}
                style={{ minWidth: '45%', marginTop: '0px' }}>
                <InputLabel htmlFor="age-native-simple">
                  Select Sub clinic
                </InputLabel>
                <Select
                  value={clinicLists.display_name}
                  onChange={handleClinicChange}
                  name="clinic"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    anchorReference: 'anchorEl',
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    },
                    getContentAnchorEl: null
                  }}>
                  {clinicLists.length > 0 &&
                    clinicLists.map(({ clinic_id, display_name }) => (
                      <MenuItem key={clinic_id} value={display_name}>
                        {display_name}
                      </MenuItem>
                    ))}
                </Select>
                {/* <NativeSelect
                  style={{ marginTop: '0px' }}
                  // value={state.age}
                  // onChange={handleChange}
                  name="age"
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'age' }}>
                  <option value="">Select Sub clinic</option>
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </NativeSelect> */}
              </FormControl>
            </Box>

            <Table
              className={classes.table}
              aria-label="simple table"
              style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: 'rgba(17,29,78,0.7)' }}>
                    Name of Services
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: 'rgba(17,29,78,0.7)' }}>
                    Services Count
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: 'rgba(17,29,78,0.7)' }}>
                    Revenue generated
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>X-Ray</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Blood Test</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sugar Test</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ECG Test</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>X-Ray</TableCell>
                  <TableCell align="center">5</TableCell>
                  <TableCell align="center">2000</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-col2">
            <Donut />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="box-col2">
            <Box className="table-top">
              <h4 style={{ flex: '0 0 100%', marginBottom: '10px' }}>
                Doctors with most Consultations
              </h4>
            </Box>
            <Box className="table-top">
              <FormControl
                className={classes.formControl}
                style={{ minWidth: '45%', marginTop: '0px' }}>
                <InputLabel htmlFor="age-native-simple">
                  Select Sub clinic
                </InputLabel>
                <Select
                  value={clinicLists.display_name}
                  onChange={handleClinicChange}
                  name="clinic"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    anchorReference: 'anchorEl',
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    },
                    getContentAnchorEl: null
                  }}>
                  {clinicLists.length > 0 &&
                    clinicLists.map(({ clinic_id, display_name }) => (
                      <MenuItem key={clinic_id} value={display_name}>
                        {display_name}
                      </MenuItem>
                    ))}
                </Select>
                {/* <NativeSelect
                  style={{ marginTop: '0px' }}
                  // value={state.age}
                  // onChange={handleChange}
                  name="age"
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'age' }}>
                  <option value="">Select Sub clinic</option>
                  <option value={10}>Ten</option>
                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                </NativeSelect> */}
              </FormControl>
              <FormControl
                className={classes.formControl}
                style={{ minWidth: '45%', marginTop: '0px' }}>
                <InputLabel htmlFor="age-native-simple">
                  Select Data Period
                </InputLabel>
                <Select
                  value={periodLists.id}
                  onChange={handleClinicConsultation}
                  name="data"
                  id="data-list"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    anchorReference: 'anchorEl',
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    },
                    getContentAnchorEl: null
                  }}>
                  {periodLists.map(({ label, id }) => (
                    <MenuItem key={id} value={id}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Table
              className={classes.table}
              aria-label="simple table"
              style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: 'rgba(17,29,78,0.7)' }}>
                    Doctor Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: 'rgba(17,29,78,0.7)' }}>
                    Consultation Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Dr. Manoj Kumar</TableCell>
                  <TableCell align="center">500</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dr. Subhabrata Paul</TableCell>
                  <TableCell align="center">500</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dr. Monosij Senguptha</TableCell>
                  <TableCell align="center">900</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dr. Chironjit Roy</TableCell>
                  <TableCell align="center">600</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dr. Sathadrue Bose</TableCell>
                  <TableCell align="center">700</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClinicsAndDoctors
