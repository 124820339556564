import React, { useEffect, useState, useRef } from 'react'
import {
  MenuItem,
  Select,
  Box,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddAlarmIcon from '@material-ui/icons/AddAlarm'
// import Swal from 'sweetalert2'
import {
  ScheduleComponent,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Resize,
  DragAndDrop
} from 'cn_scheduler/main-scheduler'
import { NETWORK_STATUS } from '../../Constants'
import Loader from '../../components/CommonComponents/Loader'
import moment from 'moment'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import { DoctorStore } from '../../stores'
import sch1 from '../../images/sch1.png'

const MyScheduler = () => {
  let selectedArgs = null

  var inputEl = useRef(null)
  const classes = useStyles()
  const onDragStart = args => {
    selectedArgs = args.data
    args.navigation.enable = true
  }
  const [resourceData, setResourceData] = useState([])
  const [schedules, setSchedules] = useState([])
  const [appointmentData, setAppointmentData] = useState([])

  const [schedulerView, setSchedulerView] = useState('true')
  const [initialLoad, setInitialLoadComplete] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [rescheduledArgs, setRescheduledArgs] = useState('')
  const [rescheduleDialog, setRescheduleDialog] = useState(false)
  const {
    fetchSchedule,
    createSchedule,
    updateSchedule,
    deleteSchedule,
    getConsultationSettings,
    setInpersonConsultationSettings,
    setTeleConsultationSettings,
    updateConsultationSettings,
    rescheduleAppointment,
    setRescheduleResponse
  } = DoctorStore.useStoreActions(actions => ({
    ...actions.my_scheduler
  }))
  const {
    data,
    events,
    appointment,
    scheduleUpdateFailed,
    scheduleCreateFailed,
    networkStatus,
    inpersonconsultationSettings,
    teleconsultationSettings,
    rescheduleAppointmentStatus,
    consultSettingUpdateStatus
  } = DoctorStore.useStoreState(state => ({
    ...state.my_scheduler
  }))

  useEffect(() => {
    if (consultSettingUpdateStatus === 0) {
    } else if (consultSettingUpdateStatus === 200) {
      setOpenSnackBar(true)
      setSnackBarMessage('Consultation settings updated successfully...')
      setmultiButton(false)
      setAlertType('success')
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage('Oops! Consultation settings could not be updated...')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [consultSettingUpdateStatus])

  useEffect(() => {
    if (data) {
      setResourceData(data)
    }
  }, [data])

  useEffect(() => {
    if (events) {
      setSchedules(events)
    }
  }, [events])

  useEffect(() => {
    if (appointment) {
      setAppointmentData(appointment)
    }
  }, [appointment])

  useEffect(() => {
    if (inputEl?.showHeaderBar) {
      //   inputEl.showHeaderBar = false
    }
  }, [inputEl])

  useEffect(() => {
    if (scheduleUpdateFailed) {
      setOpenSnackBar(true)
      setSnackBarMessage('Sorry there was a problem in updating your schedules')
      setmultiButton(false)
      setAlertType('error')
      fetchSchedule({ schedulerView })
    }
  }, [scheduleUpdateFailed])

  useEffect(() => {
    if (scheduleCreateFailed) {
      setOpenSnackBar(true)
      setSnackBarMessage('Sorry there was a problem in creating your schedules')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [scheduleCreateFailed])

  useEffect(() => {
    if (rescheduleAppointmentStatus == undefined) {
      //
    } else if (rescheduleAppointmentStatus.statusCode == 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Appointment rescheduled successfully')
      setmultiButton(false)
      setAlertType('info')
      fetchSchedule({ schedulerView })
    } else {
      setOpenSnackBar(true)
      setSnackBarMessage(rescheduleAppointmentStatus?.message)
      setmultiButton(false)
      setAlertType('error')
    }
  }, [rescheduleAppointmentStatus])

  useEffect(() => {
    fetchSchedule({ schedulerView })
    getConsultationSettings({
      visitType: 1,
      appointmentType: '1'
    })
    getConsultationSettings({
      visitType: 1,
      appointmentType: '2'
    })
    setInitialLoadComplete(true)
  }, [])

  const onChangeView = (event, value) => {
    setSchedulerView(value)
    fetchSchedule({ schedulerView: value })
  }

  const onActionComplete = args => {
    if (schedulerView !== 'true') {
      //appointment
    } else {
      //scheduler
      if (args.requestType === 'eventCreated') {
        createSchedule(args.addedRecords)
      } else if (args.requestType === 'eventChanged') {
        if (args.changedRecords?.length !== 0) {
          updateSchedule(args.changedRecords).then(data => {
            if (args.addedRecords?.length !== 0 && data) {
              let newValue = args.addedRecords[0]
              Object.keys(args.addedRecords[0]).map(key => {
                if (
                  key === 'recurrence_rule' ||
                  key === 'recurrence_exception'
                ) {
                  newValue[key] = null
                }
              })
              createSchedule([newValue])
            }
          })
        } else if (args.addedRecords?.length !== 0) {
          createSchedule(args.addedRecords)
        }
      } else if (args.requestType === 'eventRemoved') {
        if (args.changedRecords?.length !== 0) {
          updateSchedule(args.changedRecords).then(() => {
            if (args.deletedRecords?.length !== 0) {
              deleteSchedule(args.deletedRecords)
            }
          })
        } else if (args.deletedRecords?.length !== 0) {
          deleteSchedule(args.deletedRecords)
        }
      }
    }
  }

  // const onPopupOpen = args => {
  //   if (
  //     (!args.target.classList.contains('e-appointment') &&
  //       args.type === 'QuickInfo') ||
  //     args.type === 'Editor'
  //   ) {
  //     args.cancel = schedulerView === 'true' ? false : true
  //   }
  // }
  const onActionBegin = args => {
    if (schedulerView !== 'true') {
      args.cancel = false
    } else if (
      (args.requestType === 'eventCreate' ||
        args.requestType === 'eventChange') &&
      args.data.length > 0
    ) {
      let eventData = args.data[0]
      let eventField = inputEl?.eventFields
      let startDate = eventData[eventField.startTime]
      let endDate = eventData[eventField.endTime]
      let eventId = eventData[eventField.id]
      let eventType = eventData.category_id
      let conflictingSlot = conflictCheck(startDate, endDate, eventId)
      if (eventType === conflictingSlot.category_id) {
        if (conflictingSlot.length !== 0) {
          args.cancel = true
          setOpenSnackBar(true)
          setSnackBarMessage(
            'Sorry you cannot add or modify this slot since you have a recurring or a conflicting slot!'
          )
          setmultiButton(false)
          setAlertType('info')
        }
      }
    } else if (args.requestType === 'eventChange') {
      let eventData = args.changedRecords[0]
      let eventField = inputEl.eventFields
      let startDate = eventData[eventField.startTime]
      let endDate = eventData[eventField.endTime]
      let eventId = eventData[eventField.id]
      let eventType = eventData.category_id
      let conflictingSlot = conflictCheck(startDate, endDate, eventId)
      if (eventType === conflictingSlot.category_id) {
        if (
          conflictingSlot.length !== 0 &&
          conflictingSlot.id !== eventData.id
        ) {
          args.cancel = true
          setOpenSnackBar(true)
          setSnackBarMessage(
            'Sorry you cannot add or modify this slot since you have a recurring or a conflicting slot!'
          )
          setmultiButton(false)
          setAlertType('info')
        }
      }
    }
  }

  const resheduleAppointment = () => {
    let appointment_id = rescheduledArgs.appointment_id
    let appointment_date = moment(rescheduledArgs.start_time).format(
      'YYYY-MM-DD'
    )
    let appointment_start_time = moment(rescheduledArgs.start_time).format(
      'HH:mm:SS'
    )
    let appointment_end_time = moment(rescheduledArgs.end_time).format(
      'HH:mm:SS'
    )

    rescheduleAppointment({
      ...rescheduledArgs,
      appointment_id,
      appointment_date,
      appointment_start_time,
      appointment_end_time
    })
    setRescheduleDialog(false)
  }

  const handleCloseRescheduleDialog = () => {
    setRescheduleDialog(false)
  }

  const onDragStop = args => {
    // console.log(args)
    // console.log(schedulerView)
    if (schedulerView !== 'true') {
      // console.log(selectedArgs)
      // console.log(new Date(selectedArgs.start_time), new Date(args.data.start_time), new Date(selectedArgs.start_time).setSeconds(0,0) == new Date(args.data.start_time).setSeconds(0,0))
      if (
        args.data !== null &&
        selectedArgs !== null &&
        new Date(args.data.start_time).setSeconds(0, 0) ==
          new Date(selectedArgs.start_time).setSeconds(0, 0) &&
        new Date(args.data.end_time).setSeconds(0) ==
          new Date(selectedArgs.end_time).setSeconds(0)
      )
        return
      let eventData = args.data
      let eventField = inputEl.eventFields
      let startDate = eventData[eventField.startTime]
      let endDate = eventData[eventField.endTime]
      let eventId = eventData[eventField.id]
      let eventType = eventData.category_id
      let conflictingSlot = conflictCheck(startDate, endDate, eventId)
      if (eventType === conflictingSlot.category_id) {
        if (conflictingSlot.length !== 0) {
          args.cancel = true
          setOpenSnackBar(true)
          setSnackBarMessage(
            'Sorry you cannot modify this slot since you have a recurring or a conflicting slot!'
          )
          setmultiButton(false)
          setAlertType('info')
        }
      } else {
        args.cancel = true
        setRescheduleDialog(true)
        setRescheduledArgs(args.data)
      }
    } else if (args.data) {
      let eventData = args.data
      let eventField = inputEl.eventFields
      let startDate = eventData[eventField.startTime]
      let endDate = eventData[eventField.endTime]
      let eventId = eventData[eventField.id]
      let eventType = eventData.category_id
      let conflictingSlot = conflictCheck(startDate, endDate, eventId)
      if (eventType === conflictingSlot.category_id) {
        if (conflictingSlot.length !== 0) {
          args.cancel = true
          setOpenSnackBar(true)
          setSnackBarMessage(
            'Sorry you cannot modify this slot since you have a recurring or a conflicting slot!'
          )
          setmultiButton(false)
          setAlertType('info')
        }
      }
    }
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
    setRescheduleResponse(null)
  }
  const validationCheck = () => {
    resheduleAppointment(rescheduledArgs)
  }

  const onResizeStop = args => {
    if (schedulerView !== 'true') {
      args.cancel = true
    } else if (args.data) {
      let eventData = args.data
      let eventField = inputEl.eventFields
      let startDate = eventData[eventField.startTime]
      let endDate = eventData[eventField.endTime]
      let eventId = eventData[eventField.id]
      let eventType = eventData.category_id
      let conflictingSlot = conflictCheck(startDate, endDate, eventId)
      if (eventType === conflictingSlot.category_id) {
        if (conflictingSlot.length !== 0) {
          args.cancel = true
          setOpenSnackBar(true)
          setSnackBarMessage(
            'Sorry you cannot modify this slot since you have a recurring or a conflicting slot!'
          )
          setmultiButton(false)
          setAlertType('error')
        }
      }
    }
  }

  const conflictCheck = (startDate, endDate, id) => {
    let allEvents = inputEl.getEvents(startDate, endDate, true)
    var beginningTime = moment(startDate, 'YYYY-MM-DD h:mm a')
    var endingTime = moment(endDate, 'YYYY-MM-DD h:mm a')
    let recurrenceConflict = false
    let conflictSlot = []
    allEvents.map(item => {
      if (item.calendar_specific_event_id !== id) {
        let firstOccurance = moment(item.StartTime, 'YYYY-MM-DD h:mma')
        let secondOccurance = moment(item.EndTime, 'YYYY-MM-DD h:mma')
        recurrenceConflict =
          firstOccurance.isBetween(beginningTime, endingTime) ||
          beginningTime.isBetween(firstOccurance, secondOccurance) ||
          endingTime.isBetween(firstOccurance, secondOccurance)
        conflictSlot = item
      }
    })
    return conflictSlot
  }

  const changeInpersonDuration = event => {
    setInpersonConsultationSettings({
      duration: event.target.value,
      noOfPerson: 60 / event.target.value
    })
  }

  const changeTeleDuration = event => {
    setTeleConsultationSettings({
      duration: event.target.value,
      noOfPerson: 60 / event.target.value
    })
  }

  const updateInpersonConsultSetting = event => {
    updateConsultationSettings({
      visitType: 1,
      appointmentType: '1',
      visitLength: inpersonconsultationSettings.duration,
      consultPerHour: inpersonconsultationSettings.noOfPerson
    })
    updateConsultationSettings({
      visitType: 1,
      appointmentType: '2',
      visitLength: teleconsultationSettings.duration,
      consultPerHour: teleconsultationSettings.noOfPerson
    })
  }

  return (
    <>
      <div style={{ padding: '20px 15px' }}>
        {networkStatus === NETWORK_STATUS.FETCHING ? (
          <Loader open={true} />
        ) : (
          <Loader />
        )}
        <Box
          mt={3}
          display="flex"
          justifyContent="space-between"
          style={{ marginLeft: '15px' }}>
          <ToggleButtonGroup
            value={schedulerView}
            exclusive
            onChange={onChangeView}
            className={classes.toogles}>
            <ToggleButton value="true">Scheduler View</ToggleButton>
            <ToggleButton value="false">Appointment View</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <SnackbarAlert
          isOpen={isSnackBarOpen}
          message={snackBarMessage}
          alertType={alertType}
          multibutton={multiButton}
          primaryClick={hideSnackBar}
          secondaryClick={validationCheck}
        />

        {
          <Box className={classes.SchdulContent}>
            <Accordion
              style={{
                boxShadow: 0,
                border: 0,
                borderRadius: 10,
                background: '#fff !important'
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <AddAlarmIcon style={{ marginRight: '10px' }} />
                <Typography gutterBottom variant="h4">
                  Consultation settings
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5} className={classes.ContentLeft}>
                    <Box display="flex" alignItems="center" marginBottom="10px">
                      <p style={{ marginTop: '0px' }}>
                        In-person consult duration (mins)
                      </p>
                      <Select
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom'
                          }
                        }}
                        className={classes.InputCol}
                        value={inpersonconsultationSettings.duration}
                        onChange={changeInpersonDuration}>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                      </Select>
                    </Box>

                    <Box display="flex" alignItems="center" marginBottom="10px">
                      <p style={{ marginTop: '0px' }}>
                        Number of consultations per hour
                      </p>
                      <label
                        className={classes.InputCol}
                        style={{ width: '100px', height: '25px' }}>
                        {inpersonconsultationSettings.noOfPerson}
                      </label>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={5} className={classes.ContentLeft}>
                    <Box display="flex" alignItems="center" marginBottom="10px">
                      <p style={{ marginTop: '0px' }}>
                        Teleconsult duration (mins)
                      </p>
                      <Select
                        className={classes.InputCol}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom'
                          }
                        }}
                        value={teleconsultationSettings.duration}
                        onChange={changeTeleDuration}>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                      </Select>
                    </Box>

                    <Box display="flex" alignItems="center" marginBottom="10px">
                      <p style={{ marginTop: '0px' }}>
                        Number of consultations per hour
                      </p>
                      <label
                        className={classes.InputCol}
                        style={{ width: '100px', height: '25px' }}>
                        {teleconsultationSettings.noOfPerson}
                      </label>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} className={classes.ContentRight}>
                    <p>
                      Note: Time range for in-person consult will be divided by
                      the in-person consult duration. If number of consult is
                      provided, each hour will be divided by number of consult
                      to get consult duration.
                    </p>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                      }}>
                      <button
                        className={classes.UpdateBtn}
                        onClick={updateInpersonConsultSetting}>
                        Update
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        }
        {
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={rescheduleDialog}>
            <DialogTitle id="draggable-dialog-title">
              Reschedule Appointment
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Do you want to reschedule this appointment to{' '}
                {moment(rescheduledArgs.start_time).format('DD-MM-YYYY')} from{' '}
                {moment(rescheduledArgs.start_time).format('hh:mm A')} to{' '}
                {moment(rescheduledArgs.end_time).format('hh:mm A')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={handleCloseRescheduleDialog}
                color="primary">
                Cancel
              </Button>
              <Button onClick={resheduleAppointment} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        }
        {initialLoad ? (
          <div style={{ padding: '5px 10px' }}>
            <ScheduleComponent
              height="650px"
              ref={t => (inputEl = t)}
              //   selectedDate={new Date()}
              eventSettings={{
                dataSource:
                  schedulerView === 'true' ? schedules : appointmentData,
                enableTooltip: true,
                fields: {
                  id: 'calendar_specific_event_id',
                  subject: { name: 'subject' },
                  isAllDay: { name: 'is_all_day' },
                  startTime: { name: 'start_time' },
                  endTime: { name: 'end_time' },
                  startTimezone: { name: 'start_timezone' },
                  endTimezone: { name: 'end_timezone' },
                  recurrenceRule: { name: 'recurrence_rule' },
                  recurrenceID: { name: 'recurrence_id' },
                  recurrenceException: { name: 'recurrence_exception' }
                }
              }}
              disabledView={schedulerView === 'true' ? false : true}
              jumpToBooking={true}
              workHours={{ highlight: true, start: '00:00', end: '24:00' }}
              workDays={[0, 1, 2, 3, 4, 5, 6]}
              timeScale={{ enable: true, interval: 60, slotCount: 4 }}
              actionComplete={onActionComplete}
              dragStart={onDragStart}
              actionBegin={onActionBegin}
              dragStop={onDragStop}
              resizeStop={onResizeStop}>
              <ViewsDirective>
                <ViewDirective option="WorkWeek"></ViewDirective>
              </ViewsDirective>
              <ResourcesDirective>
                <ResourceDirective
                  dataSource={resourceData}
                  field="category_id"
                  title="Category"
                  name="Category"
                  textField="category_display_text"
                  idField="id"
                  colorField="category_color"></ResourceDirective>
              </ResourcesDirective>
              <Inject
                services={[
                  Day,
                  Week,
                  WorkWeek,
                  Month,
                  Agenda,
                  Resize,
                  DragAndDrop
                ]}
              />
            </ScheduleComponent>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  nav: {
    marginRight: 2
  },

  dashboard: {
    '& .scheduler-view': {
      width: '700px !important'
    }
  },
  consultOptionsTexts: {
    fontSize: 14
  },
  checkbox: {
    '&>span': {
      fontSize: 14
    }
  },
  amount: {
    fontWeight: 'bold',
    margin: '0 8px',
    color: theme.palette.primary.dark
  },
  amountField: {
    width: 80
  },
  InputCol: {
    width: '100px',
    background: '#efefef',
    height: '36px',
    padding: '5px 10px',
    '& .MuiInput-underline::after': {
      borderBottom: '0px'
    },
    '& .MuiInput-underline::before': {
      borderBottom: '0px',
      display: 'none'
    }
  },
  ContentLeft: {
    '& p': {
      color: '#1d1c1c',
      fontSize: '15px',
      fontWeight: '400',
      width: '75%',
      marginRight: '10px',
      marginBottom: '0px'
    }
  },
  submitButton: {
    maxWidth: 'fit-content',
    textTransform: 'capitalize',
    fontSize: '1.2rem',
    color: '#fff',
    borderRadius: '25px'
  },
  SchdulContent: {
    borderRadius: '5px',
    padding: '15px',
    marginTop: '15px',
    '& h4': {
      fontSize: '20px',
      color: '#111e48',
      fontWeight: '500',
      marginBottom: '0px'
    }
  },
  UpdateBtn: {
    width: '170px',
    background: '#3E3BE8',
    color: '#fff',
    textAlign: 'center',
    height: '45px',
    lineHeight: '45px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '8px',
    marginRight: '20px',
    cursor: 'pointer',
    '&:hover': {
      background: '#111c47',
      color: '#fff'
    }
  },
  ResetBtn: {
    width: '170px',
    background: 'transparent',
    color: '#6d79a4',
    textAlign: 'left',
    height: '45px',
    lineHeight: '45px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '0px',
    marginRight: '20px',
    textDecoration: 'underline',
    '&:hover': {
      color: '#fff',
      cursor: 'pointer'
    }
  },
  ContentRight: {
    '& p': {
      fontSize: '14px',
      fontWeight: '400',
      color: '#1d1c1c'
    }
  },
  toogles: {
    height: '45px',
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '0px 1px 15px -3px rgba(0,0,0,0.1)',
    '& button': {
      background: '#fff',
      color: '#000',
      textTransform: 'capitalize',
      fontWeight: 500,
      padding: '0 25px',
      border: 'none',
      fontFamily: 'Poppins',

      height: 45
    },
    '& button:hover': {
      background: '#0E42B1',
      color: '#fff'
    },
    '& .Mui-selected': {
      color: '#fff',
      background: '#0E42B1',
      '&:hover': {
        background: '#0E42B1'
      }
    },
    '& .Mui-selected:nth-child(2)': {
      background: '#9abd0f !important',
      '&:hover': {
        background: '#9abd0f !important'
      }
    }
  },

  colorIndicator: {
    '& b': { position: 'relative', color: '#000', fontWeight: 'normal' },
    '&  p span': {
      height: '10px',
      width: '10px',
      display: 'inline-block',
      marginRight: '6px'
    }
  },
  inPersonindi: {
    background: '#3f4b79'
  },
  teleind: {
    background: '#9abd0f'
  },
  takenindi: {
    background: '#535353'
  }
}))
export default MyScheduler
