import React from 'react'
import { Button, makeStyles, CircularProgress } from '@material-ui/core'
import classNames from 'classnames'

const PrimaryButton = ({
  children,
  rounded,
  type,
  circle,
  isLoading,
  stopLoading,
  classVariation,
  disabled,
  ...rest
}) => {
  const classes = useStyles()
  var stopLoadingAction = stopLoading ? true : false
  return (
    <Button
      variant="contained"
      color="primary"
      className={classNames(classes.primaryButton, {
        [classes.rounded]: rounded,
        [classes.circle]: circle,
        [classes.variant]: classVariation
      })}
      type={type}
      disabled={disabled}
      disableElevation
      {...rest}
      style={{
        height: 40,
        width: '100%'
      }}>
      {isLoading && !stopLoadingAction && (
        <CircularProgress size={24} color="#fff" />
      )}
      {(!isLoading || stopLoadingAction) && children}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  primaryButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontFamily: 'inherit',
    // width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  rounded: {
    borderRadius: '8px',
    backgroundColor: '#111d4e'
  },
  circle: {
    borderRadius: '50%',
    minWidth: 'unset',
    backgroundColor: '#111d4e'
  },
  variant: {
    width: '135px',
    height: '40px',
    backgroundColor: '#111d4e',
    borderRadius: '8px',
    textAlign: 'center',
    fontSize: '16px',
    color: '#fff',
    lineHeight: '40px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#111d4e'
    }
  }
}))

export default PrimaryButton
