import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
  Box,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  InputLabel,
  Select,
  CircularProgress
} from '@material-ui/core'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'

import Page from '../../components/Page'
import Loader from '../../components/ClinicLoader'
import { SuperClinicStore } from '../../../stores'
import { NETWORK_STATUS } from '../../../Constants'
import SnackBarAlert from '../../components/SnackBarAlert'
import DoctorListItem from './DoctorListItem'
import ViewDialog from './ViewDialog'
import SearchField from './SearchField'

import noRecordFound from '../../../images/noRecordFound.png'

const SuperClinicDoctorList = ({ className, ...rest }) => {
  const classes = useStyles()

  const {
    getMyDoctorList,
    setLoadDocList,
    setMessageFromBackend
  } = SuperClinicStore.useStoreActions(actions => ({
    ...actions.doctorDetails
  }))

  const {
    networkStatus,
    loadMessage,
    errorMessageFromBackend,
    successMessageFromBackend,
    fetchDocListNetworkStatus,
    superClinicDocList,
    loadDocList,
    docListPaginationInfo
  } = SuperClinicStore.useStoreState(state => ({
    ...state.doctorDetails
  }))

  const docListDirty = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [viewDialogOpen, setViewDialogOpen] = useState(false)

  const {
    total_rows_count: totalRowsCount,
    current_page: currentPage,
    rows_per_page: currentRowsPerPage
  } = docListPaginationInfo

  useEffect(() => {
    if (loadDocList) {
      getMyDoctorList({
        table_config_page_no: page + 1,
        table_config_rows_per_page: rowsPerPage,
        pattern: searchValue
      }).then(data => {
        if (data) {
          setLoadDocList(false)
        }
      })
    }
  }, [
    getMyDoctorList,
    page,
    rowsPerPage,
    loadDocList,
    setLoadDocList,
    searchValue
  ])

  useEffect(() => {
    if (!currentPage || !currentRowsPerPage) {
      setPage(0)
      setRowsPerPage(10)
    } else {
      setPage(+currentPage - 1)
      setRowsPerPage(+currentRowsPerPage)
    }
  }, [currentPage, currentRowsPerPage])

  useEffect(() => {
    return () => {
      if (docListDirty.current) {
        setLoadDocList(true)
      }
    }
  }, [setLoadDocList])

  const fetchUpdatedDocList = useCallback(
    ({ searchString = '', subClinicId = '' }) => {
      if (searchString === '' && subClinicId === '') {
        docListDirty.current = false
      } else {
        docListDirty.current = true
      }

      return getMyDoctorList({
        table_config_page_no: 1,
        table_config_rows_per_page: rowsPerPage,
        sub_clinic_id: subClinicId,
        pattern: searchString
      })
    },
    [getMyDoctorList, rowsPerPage]
  )

  const onClickVisibilityIcon = doctorId => {
    setSelectedDoctor(
      superClinicDocList.find(item => item?.doctor_id === doctorId)
    )
  }

  const [state, setState] = React.useState({
    age: '',
    name: 'hai'
  })

  const handleChange = event => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value
    })
  }

  return (
    <Page title="My Doctors">
      <Loader
        label={loadMessage}
        open={networkStatus === NETWORK_STATUS.FETCHING}
      />
      <SnackBarAlert
        message={errorMessageFromBackend || successMessageFromBackend}
        error={!successMessageFromBackend}
        closeSnackBar={() =>
          !!errorMessageFromBackend
            ? setMessageFromBackend({ message: null })
            : setMessageFromBackend({ message: null, success: true })
        }
      />
      <ViewDialog
        open={viewDialogOpen}
        handleClose={setViewDialogOpen.bind(this, false)}
        doctorDetail={selectedDoctor}
      />
      <Box className="main-content-coll">
        <Box className={clsx(classes.root, className)} {...rest}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <h3 style={{ marginBottom: '10px' }}>All Doctors List</h3>
              <p
                style={{
                  marginTop: '0px',
                  color: '#a3a3a3',
                  fontSize: '14px',
                  marginBottom: '0'
                }}>
                More than 400 + new Doctors
              </p>
            </Grid>
            <Grid item xs={12} md={12} style={{ marginBottom: '30px' }}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  md={5}
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <p
                    style={{
                      marginBottom: '0px',
                      marginRight: '15px',
                      fontWeight: '400'
                    }}>
                    Filter By
                  </p>
                  <FormControl
                    className={classes.formControl}
                    style={{ width: '75%' }}>
                    <InputLabel htmlFor="clinicname">
                      Type to search the clinic
                    </InputLabel>
                    <Select
                      native
                      value={state.age}
                      onChange={handleChange}
                      inputProps={{
                        name: 'clinicname',
                        id: 'clinicname'
                      }}>
                      <option aria-label="None" value="" />
                      <option value={10}>Ten</option>
                      <option value={20}>Twenty</option>
                      <option value={30}>Thirty</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={5}>
                  <SearchField
                    value={searchValue}
                    setValue={setSearchValue}
                    disabled={
                      fetchDocListNetworkStatus !== NETWORK_STATUS.SUCCESS
                    }
                    apiCall={fetchUpdatedDocList}
                    initNetworkStatus={fetchDocListNetworkStatus}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {fetchDocListNetworkStatus === NETWORK_STATUS.FETCHING ? (
            <div
              style={{
                width: '100%',
                borderTop: '1px rgba(224, 224, 224, 1) solid',
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CircularProgress />
            </div>
          ) : superClinicDocList.length <= 0 ? (
            <div
              style={{
                width: '100%',
                borderTop: '1px rgba(224, 224, 224, 1) solid',
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <img
                src={noRecordFound}
                alt="No Clinics Found"
                style={{ display: 'block', height: '15rem', width: '15rem' }}
              />
            </div>
          ) : (
            <>
              <Divider />
              <PerfectScrollbar style={{ overflow: 'auto' }}>
                <Box minWidth={800}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: 100, color: '#a3a3a3' }}>
                          Doctor Name
                        </TableCell>
                        <TableCell style={{ minWidth: 100, color: '#a3a3a3' }}>
                          Specialization
                        </TableCell>
                        <TableCell style={{ minWidth: 100, color: '#a3a3a3' }}>
                          Mobile number
                        </TableCell>
                        <TableCell style={{ width: '150px', color: '#a3a3a3' }}>
                          Email
                        </TableCell>
                        <TableCell style={{ minWidth: 100, color: '#a3a3a3' }}>
                          Clinic Name
                        </TableCell>
                        <TableCell style={{ width: '20%', color: '#a3a3a3' }}>
                          Clinic Address
                        </TableCell>
                        <TableCell style={{ color: '#a3a3a3' }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {superClinicDocList.map(item => (
                        <DoctorListItem
                          doctorName={item?.doctor_name}
                          specialization={item?.specialization}
                          mobileNo={item?.mobile_no}
                          email={item?.email}
                          clinicName={item?.clinic_name}
                          clinicAddress={item?.clinic_address}
                          handleViewDoctorModalOpen={() => {
                            onClickVisibilityIcon(item?.doctor_id)
                            setViewDialogOpen(true)
                          }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <Box display="flex" justifyContent="flex-end" p={2}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={totalRowsCount ? +totalRowsCount : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={(event, newPage) => {
                    setPage(newPage)
                    setLoadDocList(true)
                  }}
                  onChangeRowsPerPage={event => {
                    setRowsPerPage(+event.target.value)
                    setPage(0)
                    setLoadDocList(true)
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Page>
  )
}

export default SuperClinicDoctorList

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  }
}))
