import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  APPOINTMENT_TYPE,
  BOOKING_STATUS,
  VISIT_TYPE,
  LIST_TYPE,
  NETWORK_STATUS
} from '../../Constants'
import Pagination from '@material-ui/lab/Pagination'
import {
  Grid,
  Box,
  Checkbox,
  FormGroup,
  TextField,
  makeStyles,
  FormControl,
  FormControlLabel
} from '@material-ui/core'
import { FaSearch } from 'react-icons/fa'
import Loading from 'react-loading'
import { DoctorStore } from '../../stores'
import AppointmentListItem from '../CommonComponents/AppointmentListItem'
import NoRecordImg from '../../images/norecord.png'
import useDebounce from '../../customHooks/useDebounce'

export default function UpcomingAppointments({ upcomingType }) {
  const [searchKeyWord, setSearchKeyWord] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageCount, setPageCount] = React.useState(1)
  const debouncedSearchTerm = useDebounce(searchKeyWord, 1000)

  const {
    upcomingdata,
    networkStatus,
    totalPagesCount
    // upcomingType
  } = DoctorStore.useStoreState(state => ({
    ...state.appointments
  }))
  const { getList } = DoctorStore.useStoreActions(actions => ({
    ...actions.appointments
  }))

  //start filter code
  const [filter, setFilter] = React.useState({
    all: true,
    Tele: false,
    In_Person: false
  })
  const [filter2, setFilter2] = React.useState({
    confirmed: false,
    checked_in: false
  })

  //check upcoming filter presence
  useEffect(() => {
    if (upcomingType === 'tele') {
      setFilter({
        all: false,
        Tele: true,
        In_Person: false
      })
    } else if (upcomingType === 'inPerson') {
      setFilter({
        all: false,
        Tele: false,
        In_Person: true
      })
    } else {
      setFilter({
        all: true,
        Tele: false,
        In_Person: false
      })
    }
  }, [upcomingType])

  const classess = useStyless()

  useEffect(() => {
    getAppointmentList()
  }, [filter, filter2, debouncedSearchTerm, currentPage])

  useEffect(() => {
    setPageCount(totalPagesCount)
  }, [totalPagesCount])

  const onPaginationClick = (event, value) => {
    setCurrentPage(value)
  }

  const handleChange = e => {
    let value = e.target.checked
    if (!value && e.target.name === 'all') {
      return
    } else if (!value) {
      setFilter({
        all: true,
        Tele: false,
        In_Person: false
      })
    } else {
      onPaginationClick('', 1)
      let newFilter = filter
      Object.keys(filter).map(key => {
        newFilter[key] = false
      })
      setFilter({ ...newFilter, [e.target.name]: e.target.checked })
    }
  }

  const handleChange2 = e => {
    let value = e.target.checked
    if (!value) {
      setFilter2({
        confirmed: false,
        checked_in: false
      })
    } else {
      onPaginationClick('', 1)
      let newFilter = filter2
      Object.keys(filter2).map(key => {
        newFilter[key] = false
      })
      setFilter2({ ...newFilter, [e.target.name]: e.target.checked })
    }
  }

  const getAppointmentList = () => {
    let filterResult = Object.keys(filter).find(key => filter[key] === true)
    let filter2Result = Object.keys(filter2).find(key => filter2[key] === true)
    let filterValue = Object.keys(APPOINTMENT_TYPE).find(
      key => APPOINTMENT_TYPE[key].label === filterResult
    )
    let filter2Value = Object.keys(BOOKING_STATUS).find(
      key => BOOKING_STATUS[key].label === filter2Result
    )
    if (filterValue || filter2Value) {
      let status = filter2Value ? BOOKING_STATUS[filter2Value].value : '0'
      let appointment_type = filterValue
        ? APPOINTMENT_TYPE[filterValue].id
        : '0'
      getList({
        list_type: LIST_TYPE.UPCOMING.label,
        status: status,
        visit_type: VISIT_TYPE.ALL.id,
        appointment_type: appointment_type,
        currentPage: currentPage,
        searchString: searchKeyWord,
        sorting: {
          appointment_date: 'ASC',
          start_time: 'ASC'
        }
      })
    }
  }

  const onTextChange = e => {
    setSearchKeyWord(e.target.value)
  }

  return (
    <div>
      <div className={classess.checkboxes}>
        <Box className={classess.Topfiltercol}>
          <FormControl component="fieldset" style={{ marginTop: '10px' }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="all"
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleChange}
                    name="all"
                  />
                }
                label="All"
                labelPlacement="end"
                checked={filter.all}
              />
              <FormControlLabel
                value="In_Person"
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleChange}
                    name="In_Person"
                  />
                }
                label="In-Person"
                labelPlacement="end"
                checked={filter.In_Person}
              />
              <FormControlLabel
                value="Tele"
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleChange}
                    name="Tele"
                  />
                }
                label="Tele"
                labelPlacement="end"
                checked={filter.Tele}
              />
            </FormGroup>
          </FormControl>

          <Box
            className={classess.SearchFilter}
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControl component="fieldset" className={classess.SearchCol}>
              <FormGroup
                aria-label="position"
                row
                className={classess.SearchRow}>
                <TextField
                  className={classess.inputElement}
                  onChange={onTextChange}
                  label="Search"
                  value={searchKeyWord}
                />
                {/* <FormControlLabel
                  className={classess.SearchRow2}
                  control={
                    <TextField
                      className={classess.inputElement}
                      onChange={onTextChange}
                      label="Search"
                      value={searchKeyWord}
                    />
                  }
                /> */}
                <FormControlLabel
                  className={classess.SearchBtn}
                  control={<FaSearch />}
                />
              </FormGroup>
            </FormControl>
            <Box style={{ marginTop: '10px' }}>
              <FormControl
                component="fieldset"
                style={{ fontSize: 14, fontWeight: 500 }}>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="confirmed"
                    control={
                      <Checkbox
                        style={{ fontSize: 14, fontWeight: 500 }}
                        color="primary"
                        onChange={handleChange2}
                        name="confirmed"
                      />
                    }
                    label="Confirmed"
                    labelPlacement="end"
                    checked={filter2.confirmed}
                  />
                  <FormControlLabel
                    value="checked_in"
                    control={
                      <Checkbox
                        color="primary"
                        onChange={handleChange2}
                        name="checked_in"
                      />
                    }
                    label="Checked In"
                    labelPlacement="end"
                    checked={filter2.checked_in}
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          borderTop: '1px rgba(25,40,82,0.1) solid',
          marginTop: 10,
          padding: 10
        }}>
        <div
          style={{
            width: '20%',
            fontSize: 12,
            fontWeight: 600,
            color: 'rgba(25,40,82,0.6)'
          }}>
          Patient
        </div>
        <div
          style={{
            width: '12%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Date of Birth
        </div>
        <div
          style={{
            width: '28%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Date & Time
        </div>
        <div
          style={{
            width: '10%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Status
        </div>
        <div
          style={{
            width: '20%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Type of Appointment
        </div>
        <div
          style={{
            width: '10%',
            fontSize: 12,
            color: 'rgba(25,40,82,0.6)',
            fontWeight: 600
          }}>
          Action
        </div>
      </div>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loading
          type="bubbles"
          textAlign="center"
          color="#0D46C1"
          height={50}
        />
      ) : upcomingdata.length !== 0 ? (
        <>
          {upcomingdata.map((d, index) => {
            return (
              <AppointmentListItem
                image={d.photo_uri}
                first_name={d.patient_name}
                middle_name={d.middle_name}
                last_name={d.last_name}
                id={d.id}
                patientId={d.patient_id}
                gender={d.gender}
                dob={d.dob}
                mobile={d.mobile_no}
                email={d.email}
                appointmentDate={d.appointment_date}
                start_time={d.start_time}
                end_time={d.end_time}
                tag={d.tag}
                initialConsultation={d.initialConsultation}
                status={d.status}
                key={index}
                appointment_type={d.appointment_type}
              />
            )
          })}
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <img
              alt="No data"
              style={{ display: 'block', width: '200px' }}
              src={NoRecordImg}
            />
          </div>
        </>
      )}
      <Pagination
        count={pageCount}
        shape="rounded"
        onChange={onPaginationClick}
      />
    </div>
  )
}
const useStyless = makeStyles(() => ({
  checkboxes: {
    marginTop: '15px',
    '& label > span': {
      fontSize: '14px'
    },
    '& span > svg': {
      width: '0.8em',
      height: '0.8em'
    }
  },
  SearchRow2: {
    width: '100%'
  },
  SearchBtn: {
    position: 'absolute',
    right: '0px',
    top: '20px'
  },
  Searchcol: {
    width: '58% !important',
    '@media (max-width:767px)': {
      marginBottom: '15px'
    }
  },
  SearchRow: {
    width: '100%'
  },
  Topfiltercol: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:767px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column'
    }
  },
  SearchCol: {
    '@media (max-width:767px)': {
      width: '98% !important',
      marginLeft: '12px'
    }
  },
  SearchFilter: {
    flex: '0 0 48%',
    '@media (max-width:767px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      flex: '0 0 100%'
    }
  },
  formcol: {
    '@media (max-width:767px)': {
      margin: '0px !important'
    }
  },
  Rightcheckbox: {
    '@media (max-width:767px)': {
      textAlign: 'left !important'
    }
  },
  searchicon: {
    position: 'absolute',
    top: '18px',
    right: '0px'
  },
  inputElement: {
    width: '100%',
    borderWidth: 1,
    fontSize: '14px',
    '& .MuiInput-input': {
      paddingRight: '30px'
    },
    '&>div': {
      '&::before': {
        borderWidth: '1px'
      }
    }
  }
}))
