import React from 'react'
import PatientDocument from './PatientDocument'

const PatientDocuments = props => {
  const { classes, TabPanel, value } = props
  return (
    <React.Fragment>
      <TabPanel
        value={value}
        index={4}
        style={{
          background: '#fff',
          borderRadius: 15
        }}>
        <PatientDocument classes={classes} />
      </TabPanel>
    </React.Fragment>
  )
}

export default PatientDocuments
