import { createContextStore } from 'easy-peasy'
import localforage from 'localforage'
import { LOCAL_STORAGE_NAME } from '../Constants'
import PublicDoctor from './PublicDoctor'
import Doctor from './Doctor'
import PublicPatient from './PublicPatient'
import Patient from './Patient'
import Clinic from './Clinic'
import SuperClinic from "./SuperClinic"

localforage.config({
  name: LOCAL_STORAGE_NAME
})

const DoctorPublicStore = createContextStore(PublicDoctor)

const DoctorStore = createContextStore(Doctor)

const PublicPatientStore = createContextStore(PublicPatient)

const PatientStore = createContextStore(Patient)

const ClinicStore = createContextStore(Clinic)

const SuperClinicStore = createContextStore(SuperClinic)

export {
  DoctorPublicStore,
  DoctorStore,
  PublicPatientStore,
  PatientStore,
  ClinicStore,
  SuperClinicStore
}
