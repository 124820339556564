import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow: '0 1px 7px 0 rgba(0,0,0,0.1)'
  },
  avatar: {
    background: 'linear-gradient(45deg, #063bc4, #680fad)',
    height: 45,
    width: 45
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}))

const TotalCustomers = ({ className, data, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              style={{ fontSize: '1rem', width: '9rem', textAlign: 'left' }}>
              Total Patient
            </Typography>
            <Typography color="textPrimary" variant="h5">
              {data?.total_patient ? data?.total_patient : '0'}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

TotalCustomers.propTypes = {
  className: PropTypes.string
}

export default TotalCustomers
