import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
// import { Layout as DashboardLayout } from '../../patientLayouts'
import { DashboardLayout } from '../../patientLayouts'
import UpdateProfile from '../../components/PatientProfile/UpdateProfile'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'

export default function Appointments() {
  const classes = useStyles()
  return (
    <DashboardLayout>
      <Link to="/patient/selectPatient" className={classes.navLink}>
        <button className={classes.Backbtn}>
          <IoIosArrowBack className={classes.Backicon} />
          Select Patient Account
        </button>
      </Link>
      <Box className={classes.aside}>
        <Paper elevation={3} className={classes.paper}>
          <UpdateProfile />
        </Paper>
      </Box>
    </DashboardLayout>
  )
}

const useStyles = makeStyles(theme => ({
  Backbtn: {
    marginRight: '20px',
    background: 'none',
    border: '0px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    cursor: 'pointer',
    marginBottom: '15px',
    '@media (max-width:550px)': {
      marginBottom: '0px'
    }
  },
  aside: {
    padding: '10px 20px 20px 10px',
    '@media (max-width:767px)': {
      padding: '25px 10px 20px 10px'
    }
  },
  Backicon: {
    marginRight: '5px'
  },
  paper: {
    padding: '15px'
  }
}))
