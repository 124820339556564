import { action, thunk } from 'easy-peasy'

import BaseModel from '../Base'
import api from '../../api'
import { NETWORK_STATUS } from '../../Constants'
import {
  SUPER_CLINIC_USER_ID,
  CLINIC_USER_ID,
  DOCTOR_ID
} from '../../Constants'
export default {
  ...BaseModel(),

  successMessageFromBackend: null,
  errorMessageFromBackend: null,
  revenue: null,
  doctorEarnings: null,
  lastMonthsEarnings: null,
  topFiveEarnings: null,
  doctorRevenue: null,
  doctorServices: null,
  clinicConsultations: null,
  doctorConsultations: null,
  clinicLists: [],
  rowsList: 0,
  pageList: 0,
  doctorList: [],
  earnings: 0,

  setMessageFromBackend: action((state, payload) => {
    if (!!payload.success) {
      state.successMessageFromBackend = payload.message
    } else {
      state.errorMessageFromBackend = payload.message
    }
  }),
  getClinicList: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.super_clinic.getClinicList({
      pattern: !!payload.pattern ? payload.pattern : '', // 'mahesh', // (optional)
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35',
      doctor_id: '', // (optional)
      start_date: payload?.start_date ? payload?.start_date : '', // '2021-03-03', // (optional)
      end_date: payload?.end_date ? payload?.end_date : '', // (optional)
      table_config_page_no: JSON.stringify(payload?.table_config_page_no)
        ? JSON.stringify(payload?.table_config_page_no)
        : '1',
      table_config_rows_per_page: JSON.stringify(
        payload?.table_config_rows_per_page
          ? payload?.table_config_rows_per_page
          : 200
      )
    })
    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.updateClinicList(response?.data?.data)
      action.updatePageNo(response.data.total_pages_count)
      action.updateRowNo(response.data.total_rows_count)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  updateClinicList: action((state, payload) => {
    state.clinicLists = payload
  }),
  updatePageNo: action((state, payload) => {
    state.pageList = payload
  }),
  updateRowNo: action((state, payload) => {
    state.rowsList = payload
  }),

  getmyDoctorList: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.super_clinic.getmyDoctorList({
      sub_clinic_id: !!payload.sub_clinic_id ? payload.sub_clinic_id : '', // (optional)
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35',
      table_config_page_no: JSON.stringify(payload?.table_config_page_no)
        ? JSON.stringify(payload?.table_config_rows_per_page)
        : '1', // '1',
      table_config_rows_per_page: JSON.stringify(
        payload?.table_config_rows_per_page
      )
        ? JSON.stringify(payload?.table_config_rows_per_page)
        : '200'
    })
    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.updateDoctorList(response?.data?.data)
      action.setPageNo(response.data.total_pages_count)
      action.setRowNo(response.data.total_rows_count)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  updateDoctorList: action((state, payload) => {
    state.doctorList = payload
  }),
  setPageNo: action((state, payload) => {
    state.pageList = payload
  }),
  setRowNo: action((state, payload) => {
    state.rowsList = payload
  }),

  getRevenue: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = api.super_clinic.getRevenue({
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID),
      clinic_id: localStorage.getItem(CLINIC_USER_ID),
      data_period: !!payload.data_period ? payload.data_period : '' //'Last_Quarter'
    })

    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.revenueAmount(response?.data)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  revenueAmount: action((state, payload) => {
    state.revenue = payload
  }),

  getDoctorEarnings: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = api.super_clinic.getDoctorEarnings({
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), //f897f35e-8fbb-474f-9ab2-fed6f43bbd35
      // doctor_id: payload.doctor_id ? payload.doctor_id : '', //13e736b2-6f65-4aaa-90e8-13c820bd65f7
      doctor_id: '13e736b2-6f65-4aaa-90e8-13c820bd65f7', //13e736b2-6f65-4aaa-90e8-13c820bd65f7
      data_period: !!payload.data_period ? payload.data_period : '', //Last_week
      authorizer_principal_id: {
        user_type: 7
      }
    })

    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.totalDoctorEarnings(response?.data)
      action.getEarnings(response.data.data.DAY_WISE_DOCTOR_EARNINGS)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  totalDoctorEarnings: action((state, payload) => {
    state.doctorEarnings = payload
  }),
  getEarnings: action((state, payload) => {
    state.earnings = payload
  }),

  getLastMonthsRevenue: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = api.super_clinic_id.getLastMonthsRevenue({
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35'
      data_period: !!payload.data_period ? payload.data_period : '' //'Last_Quarter'
    })

    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.lastMonthRevenue(response?.data)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  lastMonthRevenue: action((state, payload) => {
    state.lastMonthsEarnings = payload
  }),

  getTopFiveRevenue: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = api.super_clinic.getTopFiveRevenue({
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35'
      data_period: !!payload.data_period ? payload.data_period : '' //'Last_Quarter'
    })
    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.topFiveRevenue(response?.data)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  topFiveRevenue: action((state, payload) => {
    state.topFiveEarnings = payload
  }),

  getClinicDoctorFiveRevenue: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = api.super_clinic.getClinicDoctorFiveRevenue({
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35'
      action: 'top_5_revenue_generated',
      data_period: !!payload.data_period ? payload.data_period : '' //'Last_6_month'
    })
    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.getDoctorRevenue(response?.data)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getDoctorRevenue: action((state, payload) => {
    state.doctorRevenue = payload
  }),

  getClinicDoctorFiveServices: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = api.super_clinic.getClinicDoctorFiveServices({
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35'
      clinic_id: localStorage.getItem(CLINIC_USER_ID), // 'a698b98c-c3c9-4856-a081-037ebd4d2248'
      action: 'top_5_clinics_services',
      data_period: !!payload.data_period ? payload.data_period : '' //'Last_6_month'
    })
    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.getDoctorServices(response?.data)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getDoctorServices: action((state, payload) => {
    state.doctorServices = payload
  }),

  getMostClinicConsultations: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = api.super_clinic.getMostClinicConsultations({
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35'
      action: 'clinics_with_most_consultations',
      data_period: !!payload.data_period ? payload.data_period : '' //'Last_6_month'
    })
    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.getClinicConsultations(response?.data)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getClinicConsultations: action((state, payload) => {
    state.clinicConsultations = payload
  }),

  getMostDoctorConsultations: thunk(async (action, payload) => {
    action.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = api.super_clinic.getMostDoctorConsultations({
      super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35'
      clinic_id: localStorage.getItem(CLINIC_USER_ID), // 'a698b98c-c3c9-4856-a081-037ebd4d2248'
      action: 'doctors_with_most_consultations',
      data_period: !!payload.data_period ? payload.data_period : '' //'Last_6_month'
    })
    if (response.ok) {
      action.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      action.getDoctorConsultations(response?.data)
    } else {
      action.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  getDoctorConsultations: action((state, payload) => {
    state.doctorConsultations = payload
  })
}
