import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Footer from '../../../components/CommonComponents/Footer'
import TopBarHome from '../../layouts/MainLayout/TopBar'
import { Box } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { RAZOR_KEY } from '../../../Environment'
import { ClinicStore } from '../../../stores'
import ReactGA from 'react-ga'
import swal from 'sweetalert'
import { useHistory } from 'react-router-dom'
import SnackbarAlert from '../../../components/CommonComponents/SnackbarAlert'
import Swal from 'sweetalert2'
import PaymentPlans from './PaymentPlans'
import Loader from '../../components/ClinicLoader'
import { NETWORK_STATUS } from '../../../Constants'
import localforage from 'localforage'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    border: '1px #3f51b5 solid'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  resetContainer: {
    textAlign: 'center',
    padding: '20px'
  },
  Resetbutton: {
    width: '100px',
    backgroundColor: '#0d1843',
    color: '#fff'
  },
  paymentProcessing: {
    textAlign: 'center',
    margin: '20% 25%'
  },
  ContentPic: {
    width: '94%',
    minHeight: '500px',
    padding: '0 3%',
    textAlign: 'center',
    '@media (max-width:767px)': {
      width: '100%'
    }
  },
  subtitleText: {
    color: '#9a9a9a'
  }
}))

export default function PaymentStepView() {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()
  const stepperRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [transactionPollingTimer, setTransactionPollingTimer] = useState(null)
  const [planName, setPlanName] = useState(null)
  const [planType, setPlanType] = useState(null)
  const [billDetails, setBillDetails] = useState(null)
  const [paymentNotDone, setPaymentNotDone] = useState(true)
  const [disableBack, setDisableBack] = useState(false)
  const history = useHistory()

  const {
    paymentInitalize,
    paymentCalculate,
    clinicVerifyPayment
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const {
    networkStatus,
    transactionDetails,
    profileDetails,
    prepaidBillingDetails,
    paymentVerificationResponse
  } = ClinicStore.useStoreState(state => ({
    ...state.profile
  }))

  function getSteps() {
    return [
      <h2>Choose Your Plan</h2>,
      <h2>Plan Details</h2>,
      <h2>Review Order</h2>
    ]
  }

  // const setPlan = React.useCallback(
  //   value => {
  //     setPlanName(value)
  //   },
  //   [planName, setPlanName]
  // )

  const setPlanDuration = React.useCallback(
    (planType, planName) => {
      setPlanType(planType)
      setDisableBack(true)
      setActiveStep(1)
      paymentCalculate({
        planType: planType,
        planName: planName,
        activePlan: true
      })
    },
    [planType, planName, setPlanType, setDisableBack, setActiveStep]
  )

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PaymentPlans
            setPlan={setPlanName}
            planName={planName}
            setPlanDuration={setPlanDuration}
          />
        )
      case 1:
        return (
          <Box className="plan-details">
            <h2>
              <span>Plan Name: </span>
              <span>
                {planName == 'Micro Tier'
                  ? 'Micro Plan'
                  : planName == 'Mini Tier'
                  ? 'Mini Plan'
                  : 'Pay As You Go'}
              </span>
            </h2>
            <Box className="payment-frequency responsive-payment-frequency">
              <h4>Payment Frequency</h4>
              <Box className="frequency-right">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  value={planType}
                  onChange={e => setPlanType(e.target.value)}
                  defaultValue="top">
                  {planName === 'Pay as you go' || (
                    <FormControlLabel
                      value="yearly"
                      control={<Radio color="primary" />}
                      label="Yearly"
                    />
                  )}
                  <FormControlLabel
                    value="monthly"
                    control={<Radio color="primary" />}
                    label="Monthly"
                  />
                  <p style={{ color: '#3de012' }}>
                    Get 20% discount on yearly subscription
                  </p>
                </RadioGroup>
              </Box>
            </Box>

            {/* <Box className="payment-frequency amount-row">
              <h4>Amount to be paid per year</h4>
              <Box className="total-amount">INR 65000</Box>
            </Box> */}
          </Box>
        )
      case 2:
        return (
          <Box className="plan-details">
            <h3>Order Summary</h3>
            <div className="responsive-table">
              <table className="table table-striped" style={{ border: '0px' }}>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Unit Price</th>
                    <th>No. of Units</th>
                    <th className="text-right">Total/year</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {planName == 'Micro Tier'
                        ? 'Micro Plan'
                        : planName == 'Mini Tier'
                        ? 'Mini Plan'
                        : 'Pay As You Go'}
                    </td>
                    <td>{billDetails?.amount}</td>
                    <td>1</td>
                    <td className="text-right">{billDetails?.amount}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="tax-price">
                      <h4>Tax.</h4> {billDetails?.gst}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Box
              className="payment-frequency amount-row"
              style={{ marginBottom: '0px' }}>
              <h4>Amount to be paid</h4>
              <Box className="total-amount">
                <i class="fa fa-inr"></i>{' '}
                {billDetails?.amount + billDetails?.gst}
              </Box>
            </Box>
            <p style={{ color: '#ccc', fontSize: '12px' }}>
              * Tax mentioned may vary. The final tax amount will be reflected
              in your invoice.
            </p>
            <h3>
              <span>Your current wallet balance: </span>
              <span>{billDetails?.wallet_balance}</span>
            </h3>
            <span className={classes.subtitleText}>
              * Amount will be charged from your wallet. If you have
              insufficient balance then the remaining amount will be charged via
              the payment gateway!
            </span>
            {/* <span>{billDetails?.amount_going_to_be_deducted_from_pg}</span> */}
          </Box>
        )
      default:
        return 'Unknown step'
    }
  }

  React.useEffect(() => {
    if (transactionDetails == null) {
    } else if (transactionDetails?.statusCode == 201) {
      paymentHandler()
    } else if (transactionDetails?.statusCode == 200) {
      swal({
        title: 'Success',
        closeOnClickOutside: false,
        text: transactionDetails.message,
        type: 'Success'
      }).then(confirm => {
        if (confirm) {
          localforage.removeItem('subscription_details')
          localforage.removeItem('doctor_subscription')
          if (window.location.pathname == '/clinic/payment') {
            window.location.href = '/clinic/dashboard'
          } else {
            window.location.href = '/doctor/dashboard'
          }
        }
      })
    } else {
      setOpen(true)
      setSnackBarMessage(transactionDetails.message)
      setmultiButton(false)
      setAlertType('error')
    }
  }, [transactionDetails])

  React.useEffect(() => {
    if (
      paymentVerificationResponse !== undefined &&
      paymentVerificationResponse !== null
    ) {
      if (paymentVerificationResponse.statusCode == 201) {
        clearInterval(transactionPollingTimer)
        setTransactionPollingTimer(null)
        paymentSuccessful(true)
        localforage.removeItem('subscription_details')
        localforage.removeItem('doctor_subscription')
      } else if (
        paymentVerificationResponse.statusCode == 540 ||
        paymentVerificationResponse.statusCode == 542
      ) {
        clearInterval(transactionPollingTimer)
        setTransactionPollingTimer(null)
        swal({
          title: 'Payment Failed',
          closeOnClickOutside: false,
          text: paymentVerificationResponse.message,
          type: 'Failure'
        }).then(confirm => {
          if (confirm) {
            if (window.location.pathname == '/clinic/payment') {
              window.location.href = '/clinic/dashboard'
            } else {
              window.location.href = '/doctor/dashboard'
            }
          }
        })
      }
    }
  }, [paymentVerificationResponse])

  React.useEffect(() => {
    if (prepaidBillingDetails) {
      setBillDetails(prepaidBillingDetails)
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }, [prepaidBillingDetails])

  const paymentSuccessful = noredirect => {
    swal({
      title: 'Success',
      closeOnClickOutside: false,
      text: 'Payment Done Successfully',
      type: 'Success'
    }).then(confirm => {
      if (confirm) {
        if (window.location.pathname == '/clinic/payment') {
          window.location.href = '/clinic/dashboard'
        } else {
          window.location.href = '/doctor/dashboard'
        }
      }
    })
  }

  const paymentHandler = async () => {
    let razorpayID = RAZOR_KEY
    const options = {
      key: razorpayID,
      name: 'CareNowClinic',
      description: 'CareNowDoc Clinic Subscription ',
      order_id: transactionDetails.request_data.order_id,
      handler: async response => {
        try {
          const data = {
            // orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature
          }
          if (response.razorpay_payment_id && response.razorpay_order_id) {
            setPaymentNotDone(false)
            setTransactionPollingTimer(
              setInterval(() => {
                confirmPayment()
              }, 5000)
            )
            let gaLabel =
              response.razorpay_payment_id +
              ':' +
              localStorage.getItem('clinic_user_id')
            ReactGA.event({
              category: 'razorpay',
              action: response.razorpay_order_id,
              label: gaLabel
            })
          } else {
            let gaLabel =
              transactionDetails.txnid +
              ': ' +
              localStorage.getItem('clinic_user_id')
            ReactGA.event({
              category: 'razorpay',
              action: 'failed',
              label: gaLabel
            })
          }
        } catch (err) {
          console.log(err)
        }
      },
      prefill: {
        name: profileDetails.display_name,
        contact: profileDetails.phone_number,
        email: profileDetails.email
      },
      theme: {
        color: '#686CFD'
      }
    }
    const rzp1 = new window.Razorpay(options)
    rzp1.open()
  }

  const confirmPayment = () => {
    clinicVerifyPayment({
      transaction_id_pg: transactionDetails.request_data.txnid_pg,
      transaction_id_wallet: transactionDetails.request_data.txnid_wallet
    })
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      paymentInitalize({
        subscription_history_id: prepaidBillingDetails?.subscription_history_id,
        subscription_id: prepaidBillingDetails?.subscription_id
      })
    } else {
      if (activeStep == 0 && planName === null) {
        Swal.fire({
          title: '',
          text: 'Select the plan',
          icon: 'info',
          timer: 3000
        })
      } else if (activeStep == 1 && planType === null) {
        Swal.fire({
          title: '',
          text: 'Select the plan duration',
          icon: 'info'
        })
      } else if (activeStep == 1) {
        paymentCalculate({
          planType: planType,
          planName: planName
        })
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    const elList = stepperRef.current.querySelectorAll('.MuiStep-completed')
    let scrollHeight = 0
    for (let i = 0; i < elList.length - 1; i++) {
      scrollHeight += elList[i].scrollHeight
    }
    window.scroll({
      top: scrollHeight,
      behavior: 'smooth'
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <div className={classes.root}>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={() => setOpen(false)}
      />
      <TopBarHome />
      {paymentNotDone ? (
        <Box className="container2">
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ background: '#f8f8ff' }}
            ref={stepperRef}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {getStepContent(index)}
                  <div className={classes.actionsContainer}>
                    <div>
                      {activeStep !== 0 && (
                        <Button
                          onClick={handleBack}
                          disabled={disableBack}
                          className={classes.button}>
                          Back
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}>
                        {activeStep === steps.length - 1
                          ? 'Make Payment'
                          : 'Next'}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography style={{ color: '#87d91c' }}>
                Your payment successfully
              </Typography>
              <Button onClick={handleReset} className={classes.ss}>
                Reset
              </Button>
            </Paper>
          )}
        </Box>
      ) : (
        <Box className={classes.ContentPic}>
          <p className={classes.paymentProcessing}>
            Please wait...we are processing your payment
          </p>
        </Box>
      )}
      <div className={classes.footercol}>
        <Footer />
      </div>
    </div>
  )
}
