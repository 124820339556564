import { action, thunk } from 'easy-peasy'
import BaseModel from '../Base'
import { NETWORK_STATUS, CONSULTATION_TYPE } from '../../Constants'
import api from '../../api'
import { DOCTOR_USER_ID } from '../../Constants'

const INITIAL_SCHEDULE = {
  id: null,
  doctorId: null,
  date: null,
  startTime: null,
  endTime: null,
  scheduleType: null,
  consultationFees: null
}

export default {
  ...BaseModel(),

  schedule: INITIAL_SCHEDULE,
  patientId: null,
  doctorId: null,
  consultationType: CONSULTATION_TYPE.IN_PERSON,
  countryList: [],
  stateList: [],
  cityList: [],
  message: null,

  create: thunk(
    async (actions, payload, { dispatch, getState, getStoreState }) => {
      actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

      const {
        schedule: { id, date, startTime, endTime },
        doctorId,
        patientId,
        consultationType
      } = getState()

      const response = await api.createAppointment({
        ...payload
      })
      if (response.ok) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.created(response.data)
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      }
    }
  ),

  created: action((state, payload) => {
    if (payload) {
      state.message = payload.message
    }
  }),

  setConsultationType: action((state, payload) => {
    state.consultationType = payload
  }),

  setPatientId: action((state, payload) => {
    state.patientId = payload
  }),

  setSchedule: action((state, payload) => {
    state.schedule = payload
    state.doctorId = state.schedule.doctorId
  }),

  fetchCountryList: thunk(async (actions, payload) => {
    const response = await api.getCountryStateCity({})

    if (response.ok) {
      actions.fetchedCountryList(response.data.data)
    }
  }),

  fetchedCountryList: action((state, payload) => {
    if (payload) {
      state.countryList = payload
    }
  }),

  fetchStateList: thunk(async (actions, payload) => {
    const response = await api.getCountryStateCity({ country_id: payload })

    if (response.ok) {
      actions.fetchedStateList(response.data.data)
    }
  }),

  fetchedStateList: action((state, payload) => {
    if (payload) {
      state.stateList = payload
    }
  }),

  fetchCityList: thunk(async (actions, payload) => {
    const response = await api.getCountryStateCity({ state_id: payload })

    if (response.ok) {
      actions.fetchedCityList(response.data.data)
    }
  }),

  fetchedCityList: action((state, payload) => {
    if (payload) {
      state.cityList = payload
    }
  }),

  reset: action(state => {
    state.schedule = INITIAL_SCHEDULE
  }),

  updateAppointmentStatus: thunk(async (actions, payload) => {
    const {
      appointment_id,
      status,
      patient_id,
      value,
      timeline_type,
      offline_payment_mode,
      followup_recommendation
    } = payload
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const response = await api.updateAppointmentStatus({
      appointment_id: appointment_id,
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      patient_id: patient_id,
      followup_recommendation: followup_recommendation,
      status: status,
      offline_payment_mode: offline_payment_mode ? offline_payment_mode : '',
      value: value,
      timeline_type: timeline_type
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.updateApptStatus(response?.data)
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),
  updateApptStatus: action((state, payload) => {
    state.updateApptStatus = payload
  })
}
