import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TableSortLabel,
  Tooltip,
  Menu,
  makeStyles
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ListIcon from '@material-ui/icons/List'
import MenuItem from '@material-ui/core/MenuItem'
import { ClinicStore } from '../../../../stores'
import SnackbarAlert from '../../../../components/CommonComponents/SnackbarAlert'
import { NETWORK_STATUS } from '../../../../Constants'
import Loader from '../../../components/ClinicLoader'
import NoRecordImg from '../../../../images/noRecordFound.png'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Payment from './Payment'
import Invoice from './Invoice'
import Services from './Services'
import UploadDialog from '../AppointmentModals/UploadDialog'
import PrintPrescriptionDialog from '../AppointmentModals/PrintPrescriptionDialog'
import BillDeskDialog from '../AppointmentModals/BillDeskDialog'
import AppointmentStatusChangeDialog from '../AppointmentModals/AppointmentStatusChangeDialog'
import Swal from 'sweetalert2'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: '0 1px 7px 0 rgba(0,0,0,0.1)',
    marginTop:'10px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  notFoundImage: {
    margin: '0 auto',
    display: 'flex',
    height: '200px',
    marginTop: '70px'
  },
  loadingAndErrorState: {
    margin: '0 auto',
    display: 'block',
    height: '200px',
    width: '200px',
    marginTop: '8rem',
    textAlign: 'center'
  },
  modalView: {
    minWidth: '80%'
  }
}))

const AppointmentList = ({
  className,
  doctorId,
  docAssociationId,
  apptType,
  startDate,
  endDate,
  searchString,
  ...rest
}) => {
  const classes = useStyles()
  const [currentPage, setCurrentPage] = React.useState(1) // for showing the current page
  const [pageCount, setPageCount] = React.useState(1) // for showing total no. of pages
  const [open, setOpen] = useState(false) // for showing snackbar
  const [multiButton, setmultiButton] = useState(false) // for showing CheckIcon in the snackbar
  const [alertType, setAlertType] = useState('') // for showing the type of alert displayed
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [appointmentData, setAppointmentData] = React.useState(null)
  const [dialogType, setDialogType] = React.useState(null)
  const [doctorFees, setDoctorFees] = useState(false)
  const [selectedServicesInvoice, setSelectedServicesInvoice] = useState([])

  const [listNetworkStatus, setListNetworkStatus] = useState(null)
  const [showStatusDialog, setShowStatusDialog] = useState(false)
  const [selectedAppointmentStatus, setSelectedAppointmentStatus] = useState(
    null
  )

  const menuList = [
    {
      id: 0,
      label: 'Reschedule',
      action: 'reschedule'
    },
    {
      id: 1,
      label: 'Cancel',
      action: 'cancel'
    },
    {
      id: 2,
      label: 'Payment',
      action: 'payment'
    },
    {
      id: 3,
      label: 'BillDesk',
      action: 'services'
    },
    {
      id: 4,
      label: 'Generate Invoice',
      action: 'invoice'
    },
    {
      id: 5,
      label: 'Upload Prescription',
      action: 'uploadPrescription'
    },
    {
      id: 6,
      label: 'Print Prescription',
      action: 'printPrescription'
    }
  ]

  const appointmentStatus = [
    'Booked',
    'Confirmed',
    'Checked-In',
    'In-Progress',
    'Cancelled',
    'No-show',
    'Completed'
  ]
  // const menuList = [
  //   {
  //     id: 0,
  //     label: 'Reschedule',
  //     action: 'reschedule'
  //   },
  //   {
  //     id: 1,
  //     label: 'Cancel',
  //     action: 'cancel'
  //   },
  //   {
  //     id: 2,
  //     label: 'Payment',
  //     action: 'payment'
  //   },
  //   {
  //     id: 3,
  //     label: 'Services',
  //     action: 'services'
  //   },
  //   {
  //     id: 4,
  //     label: 'Generate Invoice',
  //     action: 'invoice'
  //   },
  //   {
  //     id: 5,
  //     label: 'Print Prescription',
  //     action: 'prescription'
  //   }
  // ]

  const {
    upcomingdata,
    networkStatus,
    totalPagesCount
  } = ClinicStore.useStoreState(state => ({
    ...state.appointment
  }))
  const {
    getList,
    cancelClinicAppointment,
    generatePrescriptionPdf,
    generateInvoice
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.appointment
  }))

  const fetchList = useCallback(
    async currentPage => {
      let appointmentType = apptType
      let listType = 'all'
      let visitType = 0
      let status = '0'
      let sortingType = {
        appointment_date: 'DESC',
        start_time: 'DESC'
      }
      if (appointmentType === 'upcoming') {
        listType = 'upcoming'
        sortingType = {
          appointment_date: 'ASC',
          start_time: 'ASC'
        }
      } else if (appointmentType === 'past') {
        listType = 'past'
      } else if (appointmentType === 'rescheduled') {
        status = '8'
      } else if (appointmentType === 'followup') {
        visitType = 2
      }
      if (doctorId) {
        setListNetworkStatus(NETWORK_STATUS.FETCHING)
        try {
          const responseData = await getList({
            doctor_id: doctorId,
            clinic_doc_assoc_id: docAssociationId,
            list_type: listType,
            status: status,
            visit_type: visitType,
            appointment_type: '0', //all tele and inpersion
            currentPage: currentPage ? currentPage : 1,
            searchString,
            startDate,
            endDate,
            sorting: sortingType
          })
          if (
            !!responseData &&
            !!responseData.statusCode &&
            responseData.statusCode === 201 &&
            !!responseData.appointments &&
            Array.isArray(responseData.appointments)
          ) {
            setListNetworkStatus(NETWORK_STATUS.SUCCESS)
            return
          }
          setListNetworkStatus(NETWORK_STATUS.FAILED)
        } catch (error) {
          setListNetworkStatus(NETWORK_STATUS.FAILED)
          Swal.fire(
            'Oops! Something went wrong. Please try again later...',
            '',
            'error'
          )
        }
      }
    },
    [getList, apptType, doctorId, searchString, startDate, endDate]
  )

  useEffect(() => {
    fetchList()
  }, [doctorId, apptType, fetchList])

  const handleClick = (event, appointment) => {
    setAppointmentData(appointment)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    setAnchorEl(null)
  }

  const selectOption = (e, type) => {
    setAnchorEl(null)
    if (type === 'reschedule') {
      window.location.href = '/clinic/schedule/' + appointmentData.doctor_id
    } else {
      setDialogOpen(true)
      setDialogType(type)
    }
  }

  const doctorFeeAdd = React.useCallback(
    event => {
      setDoctorFees(event.target.checked)
    },
    [doctorFees]
  )

  const serviceAddToInvoice = React.useCallback(
    (id, servicesList) => {
      let listItem = selectedServicesInvoice.find(item => item === id)
      let list = selectedServicesInvoice
      if (listItem) {
        list = selectedServicesInvoice.filter(item => item !== id)
      } else {
        list.push(id)
      }
      setSelectedServicesInvoice(list)
    },
    [selectedServicesInvoice]
  )

  const cancelAppointment = () => {
    setDialogOpen(false)
    cancelClinicAppointment({
      appointment_id: appointmentData.id,
      appointment_type: appointmentData.appointment_type
    }).then(data => {
      if (data?.statusCode === 200) {
        setOpen(true)
        setSnackBarMessage(data?.message)
        setmultiButton(false)
        setAlertType('success')
        fetchList()
      } else {
        setOpen(true)
        setSnackBarMessage(data?.message)
        setmultiButton(false)
        setAlertType('error')
      }
    })
  }

  const onPaginationClick = (event, value) => {
    setCurrentPage(value)
  }
  useEffect(() => {
    fetchList(currentPage)
  }, [currentPage])

  useEffect(() => {
    setPageCount(totalPagesCount)
  }, [totalPagesCount])

  const handleSubmit = () => {
    if (dialogType === 'cancel') {
      cancelAppointment()
    }
    // else if (dialogType === 'printPrescription') {
    //   generatePrescriptionPdf({
    //     appointment_id: appointmentData.id
    //   }).then(data => {
    //     setDialogOpen(false)
    //     if (data.pdf_blob) {
    //       downloadPdf(data.pdf_blob)
    //       setOpen(true)
    //       setSnackBarMessage('Prescription Generateed successfully')
    //       setmultiButton(false)
    //       setAlertType('success')
    //     } else {
    //       setOpen(true)
    //       setSnackBarMessage(data?.message)
    //       setmultiButton(false)
    //       setAlertType('error')
    //     }
    //   })
    // }
    else if (dialogType === 'invoice') {
      generateInvoice({
        appointment_id: appointmentData.id,
        doctor_fee: doctorFees,
        service_id_list: selectedServicesInvoice
      }).then(data => {
        if (data) {
          if (data.files) {
            setDialogOpen(false)
            setOpen(true)
            setSnackBarMessage('Invoice Generated successfully')
            setmultiButton(false)
            setAlertType('success')
            window.location.href = data.invoice_url
          } else {
            setOpen(true)
            setSnackBarMessage(data?.message)
            setmultiButton(false)
            setAlertType('info')
          }
        } else {
          setOpen(true)
          setSnackBarMessage(data?.message)
          setmultiButton(false)
          setAlertType('error')
        }
      })
    }
  }

  // const downloadPdf = pdf_blob => {
  //   if (pdf_blob) {
  //     let presDate = moment(appointmentData.appointment_date).format(
  //       'DD-MM-YYYY'
  //     )
  //     const linkSource = `data:application/pdf;base64,${pdf_blob}`
  //     const downloadLink = document.createElement('a')
  //     const fileName = `Prescription_${presDate}.pdf`
  //     downloadLink.href = linkSource
  //     downloadLink.download = fileName
  //     downloadLink.click()
  //   }
  // }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <SnackbarAlert
        isOpen={open}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={() => setOpen(false)}
      />
      <AppointmentStatusChangeDialog
        openConfirm={showStatusDialog}
        handleClose={setShowStatusDialog.bind(this, false)}
        previousState={!!appointmentData ? +appointmentData.status : null}
        nextState={selectedAppointmentStatus}
        appointmentData={appointmentData}
        refresh={fetchList.bind(this, currentPage)}
      />
      {dialogType === 'uploadPrescription' ? (
        <UploadDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          appointmentId={appointmentData.id}
        />
      ) : dialogType === 'printPrescription' ? (
        <PrintPrescriptionDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          appointmentData={appointmentData}
        />
      ) : dialogType === 'services' ? (
        <BillDeskDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          appointmentData={appointmentData}
          refreshAppointmentList={fetchList.bind(this, currentPage)}
        />
      ) : (
        <Dialog
          style={{ maxWidth: '100% !important' }}
          open={dialogOpen}
          classes={{ paper: classes.modalView }}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.ModalHead}>
            {dialogType === 'cancel'
              ? 'Cancel Appointment'
              : dialogType === 'payment'
              ? 'Payment'
              : dialogType === 'printPrescription'
              ? 'Generate Prescription'
              : dialogType === 'services'
              ? 'Services'
              : dialogType === 'invoice'
              ? 'Invoice'
              : ''}
          </DialogTitle>
          <DialogContent>
            {dialogType === 'payment' ? (
              <Payment
                appointment={appointmentData}
                setPaymentWindow={setDialogOpen}
                fetchList={fetchList}
              />
            ) : dialogType === 'cancel' ? (
              'Are you sure you want to cancel this appointment ?'
            ) : dialogType === 'printPrescription' ? (
              'Are you sure you want to generate prescription for this appointment ?'
            ) : dialogType === 'services' ? (
              <Services appointmentId={appointmentData.id} />
            ) : dialogType === 'invoice' ? (
              <Invoice
                appointment={appointmentData}
                doctorFeeAdd={doctorFeeAdd}
                doctorFees={doctorFees}
                selectedServicesInvoice={selectedServicesInvoice}
                serviceAddToInvoice={serviceAddToInvoice}
              />
            ) : (
              ''
            )}
          </DialogContent>
          <DialogActions style={{ padding: '20px 40px' }}>
            <button
              onClick={() => setDialogOpen(false)}
              className={classes.modalSecondaryButton}
              style={{ backgroundColor: '#aeaeae' }}>
              Cancel
            </button>
            {dialogType === 'services' || dialogType === 'payment' ? null : (
              <button
                onClick={handleSubmit}
                className={classes.modalPrimaryButton}
                style={{ backgroundColor: '#111d4e' }}>
                {dialogType === 'invoice' ? 'Generate' : 'Confirm'}
              </button>
            )}
          </DialogActions>
        </Dialog>
      )}
      <PerfectScrollbar>
        <Box minWidth={800} style={{ padding: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins', fontFamily:'Poppins' }}>
                  Appointment ID
                </TableCell>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Doctor</TableCell>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Patient</TableCell>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>
                  Patient Contact
                </TableCell>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Date</TableCell>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Time</TableCell>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Mode</TableCell>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Status</TableCell>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Payment</TableCell>
                <TableCell style={{ color: 'rgba(17,29,78,0.5)', fontWeight:'600', fontFamily:'Poppins' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {upcomingdata.map(item => (
                <TableRow hover>
                  <TableCell>{item.appointment_number}</TableCell>
                  <TableCell>{item.doctor_name}</TableCell>
                  <TableCell>{item.patient_name}</TableCell>
                  <TableCell>{item.mobile_no}</TableCell>
                  <TableCell>
                    {moment(item.appointment_date).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(item.start_time, 'hh:mm:ss').format('hh:mm A')}
                  </TableCell>
                  <TableCell>
                    {item.appointment_type === '1'
                      ? 'In-Person'
                      : 'Tele-Consult'}
                  </TableCell>
                  {/* <TableCell>
                    {item.status === '1'
                      ? 'Booked'
                      : item.status === '2'
                      ? 'Confirmed'
                      : item.status === '3'
                      ? 'Checked-In'
                      : item.status === '4'
                      ? 'In-Progress'
                      : item.status === '5'
                      ? 'Cancelled'
                      : item.status === '6'
                      ? 'No-show'
                      : item.status === '7'
                      ? 'Completed'
                      : item.status === '8'
                      ? 'Rescheduled'
                      : 'NA'}
                  </TableCell> */}

                  <TableCell>
                    {item.status === '8' ? (
                      'Rescheduled'
                    ) : (
                      <FormControl className={classes.formControl}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={+item.status}
                          onChange={e => {
                            setAppointmentData(item)
                            setShowStatusDialog(true)
                            setSelectedAppointmentStatus(e.target.value)
                          }}
                          style={{
                            fontSize: '0.9rem'
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'center'
                            },
                            anchorReference: 'anchorEl',
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'center'
                            },
                            getContentAnchorEl: null
                          }}>
                          {parseInt(item?.status) === 5 ||
                          parseInt(item?.status) === 6 ? (
                            <MenuItem
                              disabled={true}
                              value={parseInt(item?.status)}>
                              {appointmentStatus[parseInt(item?.status) - 1]}
                            </MenuItem>
                          ) : (
                            appointmentStatus
                              .slice(+item.status - 1)
                              .map((i, index) => {
                                const statusOfItem =
                                  parseInt(item?.status) + index

                                if (statusOfItem === 5 || statusOfItem === 6) {
                                  return null
                                }

                                return (
                                  <MenuItem
                                    key={index}
                                    disabled={index === 0}
                                    value={!statusOfItem ? 0 : statusOfItem}>
                                    {i}
                                  </MenuItem>
                                )
                              })
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </TableCell>
                  <TableCell>
                    {item?.is_payment_done === '1' ? 'Not Paid' : 'Paid'}
                  </TableCell>
                  <TableCell>
                    <ListIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={e => handleClick(e, item)}
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}>
                      {menuList.map(menu => {
                        if (
                          (menu.action === 'printPrescription' ||
                            menu.action === 'uploadPrescription') &&
                          appointmentData?.status !== '7'
                        ) {
                          return null
                        } else if (
                          menu.action === 'payment' ||
                          menu.action === 'invoice'
                          // && appointmentData?.status === '7'
                        ) {
                          return null
                        } else if (
                          (menu.action === 'reschedule' ||
                            menu.action === 'cancel') &&
                          apptType !== 'upcoming'
                        ) {
                          return null
                        } else {
                          return (
                            <MenuItem
                              onClick={e => selectOption(e, menu.action)}>
                              {menu.label}
                            </MenuItem>
                          )
                        }
                      })}
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {listNetworkStatus === NETWORK_STATUS.FETCHING ? (
            <div className={classes.loadingAndErrorState}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {upcomingdata.length == 0 ? (
                <img
                  src={NoRecordImg}
                  className={classes.notFoundImage}
                  alt={'Not found!'}
                />
              ) : (
                ''
              )}
            </>
          )}
        </Box>
      </PerfectScrollbar>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Pagination
          count={pageCount}
          shape="rounded"
          onChange={onPaginationClick}
        />
      </Box>
    </Card>
  )
}

AppointmentList.propTypes = {
  className: PropTypes.string
}

export default AppointmentList
