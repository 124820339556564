import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { DoctorStore, PatientStore, PublicPatientStore } from '../../stores'
import { NETWORK_STATUS } from '../../Constants'

const Loader = props => {
  const classes = useStyles()

  return (
    <>
      <Backdrop className={classes.backdrop} open={props.open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 99999999,
    color: '#fff'
  }
}))

export default Loader
