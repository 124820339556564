import React from 'react'
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Breadcrumbs,
  Link
} from '@material-ui/core'
import Page from '../../../components/Page'
import Budget from './Budget'
import LatestOrders from './LatestOrders'
import LatestProducts from './LatestProducts'
import Sales from './Sales'
import TasksProgress from './TasksProgress'
import Subscription from './Subscription'
import TotalCustomers from './TotalCustomers'
import TotalProfit from './TotalProfit'
import TrafficByDevice from './TrafficByDevice'
import SubscriptionHistory from './SubscriptionHistory'
import { ClinicStore } from '../../../../stores'
import { NETWORK_STATUS } from '../../../../Constants'
import Loader from '../../../components/ClinicLoader'
import localforage from 'localforage'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  navLink: {
    cursor: 'pointer'
  }
}))

const DashboardContent = () => {
  const classes = useStyles()
  const [topEarners, setTopEarners] = React.useState([])
  const [revenue, setRevenue] = React.useState([])
  const [cardsData, setCardsData] = React.useState([])
  const [planData, setPlanData] = React.useState(null)
  const [AlertMsg, setAlert] = React.useState('')

  const history = useHistory()
  const [view, setView] = React.useState('monthly')

  const onChangeView = (event, value) => {
    setView(event.target.name)
  }

  const {
    getClinicDashboardData,
    getClinicSubscriptionDetails,
    subscriptionPlanList,
    activateClinicSubscription,
    initializePayment,
    clinicVerifyPayment,
    getClinicProfileDetails
  } = ClinicStore.useStoreActions(actions => ({
    ...actions.profile
  }))

  const {
    dashboardData,
    networkStatus,
    clinicSubscription,
    profileDetails,
    getSubscriptionList,
    activationResponse,
    transactionDetails,
    paymentVerificationResponse
  } = ClinicStore.useStoreState(state => ({
    ...state.profile
  }))

  const subscriptionPlanAlert = (daysCount, trial) => {
    let AlertMsg =
      trial == 'trial_ended'
        ? `Your Trial period ended ${daysCount} ${
            daysCount === 1 ? 'day' : 'days'
          } before. Subscribe to enjoy our services`
        : trial == 'subscription'
        ? `Reminder! Your subscription will be ending in ${daysCount} ${
            daysCount === 1 ? 'day' : 'days'
          }. Renew your subscriptions to enjoy our services`
        : trial == 'trial'
        ? 'Your Trial period will be ending today. Subscribe soon to enjoy our services!'
        : trial == 'trial_before'
        ? `Reminder! Your Trial Period will be ending in ${daysCount} ${
            daysCount === 1 ? 'day' : 'days'
          }. Subscribe to enjoy our services`
        : trial == 'subscription_before'
        ? 'Your Subscription will be ending today. Subscribe soon to enjoy our services!'
        : `Reminder! Your subscription has already expired. Renew your subscriptions to enjoy our services`
    Swal.fire({
      closeOnClickOutside: false,
      title: AlertMsg,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#111d4e',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Subscribe Now',
      cancelButtonText: 'Continue'
    }).then(result => {
      if (result.isConfirmed) {
        history.push('/clinic/payment')
      } else {
        localStorage.setItem('login_alert_skip', true)
        var nextAlert = new Date()
        nextAlert.setHours(nextAlert.getHours() + 12)
        localStorage.setItem('next_alert_time', nextAlert)
      }
    })
  }

  const subscriptionPlanCheck = () => {
    localforage
      .getItem('subscription_details')
      .then(data => {
        let today_date = moment().format('DD/MM/YYYY')

        if (data.next_due_date == '') {
          if (
            today_date ==
            moment(data.trial_end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          ) {
            if (localStorage.getItem('login_alert_skip') !== 'true') {
              subscriptionPlanAlert('', 'trial')
            } else if (localStorage.getItem('next_alert_time')) {
              var today = new Date()
              let nextAlert = localStorage.getItem('next_alert_time')
              nextAlert = new Date(nextAlert)
              let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
              if (timeDiff < 0) {
                subscriptionPlanAlert('', 'trial')
              }
            }
          } else if (
            moment(new Date()).isBefore(data.trial_end_date) === true
          ) {
            // Time difference
            let day_diff = Math.abs(
              new Date().getTime() - new Date(data.trial_end_date).getTime()
            )

            // days difference
            let days = Math.ceil(day_diff / (1000 * 3600 * 24))

            if (days <= 3) {
              if (localStorage.getItem('login_alert_skip') !== 'true') {
                subscriptionPlanAlert(Math.abs(days), 'trial_before')
              } else if (localStorage.getItem('next_alert_time')) {
                var today = new Date()
                let nextAlert = localStorage.getItem('next_alert_time')
                nextAlert = new Date(nextAlert)
                let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
                if (timeDiff < 0) {
                  subscriptionPlanAlert(Math.abs(days), 'trial_before')
                }
              }
            }
          } else if (moment(new Date()).isAfter(data.trial_end_date) === true) {
            // Time difference
            let day_diff = Math.abs(
              new Date().getTime() - new Date(data.trial_end_date).getTime()
            )

            // days difference
            let days = Math.ceil(day_diff / (1000 * 3600 * 24))

            if (days <= 3) {
              if (localStorage.getItem('login_alert_skip') !== 'true') {
                subscriptionPlanAlert(Math.abs(days), 'trial_ended')
              } else if (localStorage.getItem('next_alert_time')) {
                var today = new Date()
                let nextAlert = localStorage.getItem('next_alert_time')
                nextAlert = new Date(nextAlert)
                let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
                if (timeDiff < 0) {
                  subscriptionPlanAlert(Math.abs(days), 'trial_ended')
                }
              }
            }
          }
        } else {
          // let end_date = moment(data.next_due_date, 'YYYY-MM-DD').format(
          //   'DD/MM/YYYY'
          // )

          // let end_date = '2021-02-22'
          let days = moment().diff(moment(data.next_due_date), 'days')

          // // Time difference
          // let timeDiff = Math.abs(
          //   new Date().getTime() - new Date(data.next_due_date).getTime()
          // )

          // // days difference
          // let days = Math.floor(timeDiff / (1000 * 3600 * 24))
          if (
            days === 0 &&
            moment().format('DD/MM/YYYY') !==
              moment(data.next_due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          ) {
            if (localStorage.getItem('login_alert_skip') !== 'true') {
              subscriptionPlanAlert('', 'subscription_before')
            } else if (localStorage.getItem('next_alert_time')) {
              var today = new Date()
              let nextAlert = localStorage.getItem('next_alert_time')
              nextAlert = new Date(nextAlert)
              let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
              if (timeDiff < 0) {
                subscriptionPlanAlert('', 'subscription_before')
              }
            }
          } else if (days <= -1 && days >= -3) {
            if (localStorage.getItem('login_alert_skip') !== 'true') {
              subscriptionPlanAlert(Math.abs(days), 'subscription')
            } else if (localStorage.getItem('next_alert_time')) {
              var today = new Date()
              let nextAlert = localStorage.getItem('next_alert_time')
              nextAlert = new Date(nextAlert)
              let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
              if (timeDiff < 0) {
                subscriptionPlanAlert(Math.abs(days), 'subscription')
              }
            }
          } else if (days < -3) {
            //
          } else {
            if (localStorage.getItem('login_alert_skip') !== 'true') {
              subscriptionPlanAlert('', null)
            } else if (localStorage.getItem('next_alert_time')) {
              var today = new Date()
              let nextAlert = localStorage.getItem('next_alert_time')
              nextAlert = new Date(nextAlert)
              let timeDiff = (nextAlert - today) / (60 * 60 * 1000)
              if (timeDiff < 0) {
                subscriptionPlanAlert('', null)
              }
            }
          }
        }
      })
      .catch(err => {
        // console.log(err)
      })
  }

  React.useEffect(() => {
    var date = new Date()
    var firstDay = new Date()
    var lastDay = new Date()

    if (view == 'monthly') {
      firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    } else {
      firstDay = new Date(new Date().getFullYear(), 0, 1)
      lastDay = new Date(new Date().getFullYear(), 11, 31)
    }
    getClinicDashboardData({
      start_date: moment(firstDay).format('DD-MM-YYYY'),
      end_date: moment(lastDay).format('DD-MM-YYYY')
    })
  }, [view])

  React.useEffect(() => {
    if (dashboardData.length !== 0) {
      setCardsData(dashboardData?.stat?.card_details)
      setRevenue(dashboardData?.stat?.revenue)
      setTopEarners(dashboardData?.stat?.top_earner)
      subscriptionPlanCheck()
    }
  }, [dashboardData])

  React.useEffect(() => {
    if (clinicSubscription) {
      setPlanData(clinicSubscription)
    }
  }, [clinicSubscription])

  return (
    <Page className={classes.root} title="Dashboard">
      {networkStatus === NETWORK_STATUS.FETCHING ? (
        <Loader open={true} />
      ) : (
        <Loader />
      )}
      <Container maxWidth={false}>
        <Breadcrumbs maxItems={2} aria-label="breadcrumb">
          <Link
            className={classes.navLink}
            color={view === 'monthly' ? 'textPrimary' : 'inherit'}
            name="monthly"
            underline="none"
            onClick={onChangeView}>
            Monthly
          </Link>
          <Link
            className={classes.navLink}
            color={view === 'yearly' ? 'textPrimary' : 'inherit'}
            name="yearly"
            underline="none"
            onClick={onChangeView}>
            Annually
          </Link>
        </Breadcrumbs>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget data={cardsData} view={view} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers data={cardsData} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress data={cardsData} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit data={cardsData} />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Subscription
            // data={revenue}
            // planData={planData}
            // profileDetails={profileDetails}
            // getSubscriptions={subscriptionPlanList}
            // plans={getSubscriptionList}
            // activateSubscription={id =>
            //   activateClinicSubscription({ subscription_id: id })
            // }
            // activationResponse={activationResponse}
            // initializePayment={() =>
            //   initializePayment({
            //     subscription_id: planData?.clinic_wallet_Data?.subscription_id
            //   })
            // }
            // transactionDetails={transactionDetails}
            // clinicVerifyPayment={clinicVerifyPayment}
            // paymentVerificationResponse={paymentVerificationResponse}
            // getClinicProfileDetails={getClinicProfileDetails}
            />
          </Grid>

          <Grid item lg={8} md={12} xl={9} xs={12}>
            <Sales data={revenue} />
          </Grid>

          <Grid item lg={4} md={6} xl={3} xs={12}>
            <TrafficByDevice data={topEarners} />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <LatestOrders />
          </Grid>

          {/* <Grid item lg={12} md={12} xl={12} xs={12}>
            <SubscriptionHistory />
          </Grid> */}

          {/* <Grid item lg={4} md={6} xl={3} xs={12}>
            <LatestProducts />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  )
}

export default DashboardContent
