import React from 'react'
import {
  TableCell,
  TableRow,
  Tooltip,
  withStyles,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import RemoveIcon from '@material-ui/icons/Remove'

import ViewModalLink from '../../components/ViewModalLink'
import { SuperClinicStore } from '../../../stores'

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

const ClinicListItem = ({
  clinicId,
  clinicName,
  address,
  phoneNumber,
  onButtonsClicked = () => {},
  index = 0
}) => {
  const classes = useStyles()

  const { refreshClinic } = SuperClinicStore.useStoreState(state => ({
    ...state.clinicListDetails
  }))

  return (
    <React.Fragment>
      {refreshClinic.includes(clinicId) && (
        <div className={classes.flowOverText} style={{ '--index': index }}>
          <CircularProgress size="1.5rem" />
          <span>Updating the Clinic</span>
        </div>
      )}
      <TableRow>
        <TableCell component="td" scope="row">
          <p>{clinicName}</p>
        </TableCell>
        <TableCell align="right" style={{ width: '40%' }}>
          <LightTooltip title={address} placement="bottom-start">
            <p
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '250px',
                whiteSpace: 'nowrap'
              }}>
              {address}
            </p>
          </LightTooltip>
        </TableCell>
        <TableCell align="right">{phoneNumber}</TableCell>
        <TableCell align="right">
          <div className="action-col">
            <ViewModalLink
              className="view action-btn"
              onClick={onButtonsClicked.bind(this, 'view', clinicId)}>
              <VisibilityIcon fontSize="small" className={classes.icon} />
              {/* <i class="fa fa-eye"></i> */}
            </ViewModalLink>
            <ViewModalLink
              className="action-btn delete"
              onClick={onButtonsClicked.bind(this, 'delete', clinicId)}>
              <RemoveIcon fontSize="small" className={classes.icon} />
              {/* <i class="fa fa-minus"></i> */}
            </ViewModalLink>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default ClinicListItem

const useStyles = makeStyles(() => ({
  icon: {
    color: 'currentColor !important',
    fill: 'currentColor !important'
  },
  flowOverText: {
    position: 'absolute',
    fontSize: '80%',
    color: '#efefef',
    height: 'calc(2.8125rem + 1px)',
    width: '100%',
    top: 'calc(34px + var(--index) * 2.8125rem)',
    left: '0',
    right: '0',
    zIndex: '10',
    display: 'inline-grid',
    alignItems: 'center',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(2, max-content)',
    gridColumnGap: '1.5rem',
    columnGap: '1.5rem',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '@media all and (max-width: 767px)': {
      height: 'calc(2.625rem + 1px)',
      top: 'calc(34px + var(--index) * 2.625rem)'
    }
  }
}))
