import React from 'react'
import PatientDetailInfoPage from './PatientDetailInfo'
import { PublicPatientStore, PatientStore } from '../../stores'

const DoctorDashboardPage = () => {
  return (
    <PublicPatientStore.Provider>
      <PatientStore.Provider>
        <PatientDetailInfoPage />
      </PatientStore.Provider>
    </PublicPatientStore.Provider>
  )
}

export default DoctorDashboardPage
