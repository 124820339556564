import React from 'react'
import { ClinicStore } from '../../../stores'
import ClinicLoginView from './ClinicLoginView'

const Login = () => {
  return (
    <ClinicStore.Provider>
      <ClinicLoginView />
    </ClinicStore.Provider>
  )
}

export default Login
