import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Dialog from '@material-ui/core/Dialog'
import Stepper from '@material-ui/core/Stepper'
import IconButton from '@material-ui/core/IconButton'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Check from '@material-ui/icons/Check'
import SettingsIcon from '@material-ui/icons/Settings'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import StepConnector from '@material-ui/core/StepConnector'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Personaldetails from './Personaldetails'
import Bankdetails from './Bankdetails'
import Plandetails from './Plandetails'
import Slide from '@material-ui/core/Slide'
import CloseIcon from '@material-ui/icons/Close'
import PrimaryButton from '../../../../components/CommonComponents/PrimaryButton'
import { NETWORK_STATUS } from '../../../../Constants'
import { ClinicStore } from '../../../../stores'


const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: '#784af4'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#784af4'
    }
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  },
})(StepConnector)

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: '#784af4'
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18
  }
})

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}>
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  appBar: {
    position: 'relative',
    backgroundColor: 'rgb(16 29 71)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  button: {
    backgroundColor: '#111d4e',
    marginRight: theme.spacing(1),
    left: '40px'
  },
  instructions: {
    marginTop: "-20px",
    // marginBottom: theme.spacing(1)
  },
  Modal_popup: {
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '99',
    cursor: 'pointer',
    display: 'none',
    /*This makes the button huge. If you want a bigger button, increase the font size*/
    fontSize: '50px',
    /*Opacity settings for all browsers*/
    opacity: '0',
    mozOpacity: '0',
    msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
  },
//   stepper:{
//       position:"relative",
//       top:"7%"
//   }
}))

function getSteps() {
  return ['Personal Details', 'Bank Details', 'Select Plan']
}

const CustomizedSteppers = React.forwardRef((props , ref) => {
  const {setactiveTab } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [triggersave, setTriggersave] = React.useState(false)
  const [docdetails, setDocDetails] = React.useState(undefined)
  const steps = getSteps()
  const submitForm = null
  const trigger = React.useRef()

  const save = React.useRef()

  const {
    docdetailAddedStatus,
    docbankdetailsAddedStatus,
    subscriptionChoiceStatus,
    networkStatus
  } = ClinicStore.useStoreState(state => ({
    ...state.profile
  }))

  const { clearState } = ClinicStore.useStoreActions(action => ({
    ...action.profile
  }))

  //on mount will always render 1st step
  React.useEffect(() => {
    setActiveStep(0)
  }, [])

  const handleNext = event => {
    if (
      subscriptionChoiceStatus !== undefined &&
      subscriptionChoiceStatus.statusCode == 201
    ) {
      setactiveTab()
      clearState()
    } else if (
      docdetails === undefined ||
      docbankdetailsAddedStatus === undefined ||
      subscriptionChoiceStatus === undefined
    ) {
      // When component loads at first
      trigger.current.click()
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  React.useEffect(() => {
    setDocDetails(docdetailAddedStatus)
  }, [docdetailAddedStatus])

  React.useEffect(() => {
    if (docdetails !== undefined && docdetails.statusCode == 200) {
      setTimeout(() => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }, 1000)
    }
  }, [docdetails])

  React.useEffect(() => {
    if (
      docbankdetailsAddedStatus !== undefined &&
      docbankdetailsAddedStatus.statusCode == 200
    ) {
      setTimeout(() => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }, 1000)
    }
  }, [docbankdetailsAddedStatus])

  React.useEffect(() => {
    if (
      subscriptionChoiceStatus !== undefined &&
      subscriptionChoiceStatus.statusCode === 201
    ) {
      setactiveTab()
      clearState()
      setOpen(false)
      setDocDetails(undefined)
    }
  }, [subscriptionChoiceStatus])

  // React.useEffect(()=>{
  // //   if(docdetailAddedStatus !== undefined){
  // // //  Setting the component to initial when renders
  // // setDocDetails(undefined)
  // //   }
  // console.log("yes")
  // },[])

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Personaldetails ref={trigger} />
      case 1:
        return <Bankdetails ref={trigger} back={handleBack} />
      case 2:
        return <Plandetails />
      default:
        return 'Unknown step'
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickClose = () => {
    setOpen(false)
  }

//   const handleSave = () => {
//     // setOpen(false);
//     save.current.click()
//   }

  return (
    <>
    <Button
    ref={ref}
    className={classes.Modal_popup}
    onClick={handleClickOpen}>
    Open full-screen dialog
  </Button>

  <Dialog
    fullScreen
    open={open}
    onClose={handleClickClose}
    TransitionComponent={Transition}>
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClickClose}
          aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Add New Doctor
        </Typography>
        {/* <Button
          autoFocus
          color="inherit"
          onClick={handleSave}
          style={{
            border: '1px rgba(255,255,255, 0.5) solid',
            borderRadius: '40px',
            padding: '5px 20px'
          }}>
          <b>save</b>
        </Button> */}
      </Toolbar>
    </AppBar>
    <div className={classes.root}>
      <Stepper alternativeLabel className={classes.stepper}activeStep={activeStep}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <div>
              {activeStep === steps.length - 1 ? null : (
                <PrimaryButton
                  rounded
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  isLoading={networkStatus === NETWORK_STATUS.FETCHING}>
                  Next
                </PrimaryButton>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
    </Dialog>
    </>
  )
})

export default CustomizedSteppers
