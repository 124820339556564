import React from 'react'
import { DashboardLayout } from '../../patientLayouts'
import SelectBookingPatientComponent from '../../components/PatientDetailsInfo/BookingSelectPatient'

const SelectPatient = () => {
  return (
    <DashboardLayout noNavbar={'true'}>
      <SelectBookingPatientComponent />
    </DashboardLayout>
  )
}

export default SelectPatient
