import React from 'react'
import {
  // IconButton,
  Avatar,
  // Box,
  Typography,
  // Grid,
  makeStyles
} from '@material-ui/core'
// import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'
import moment from 'moment'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ProfilePicImg from '../../images/profile_pic.png'
import VideoIcon from '../../images/video-call.svg'
import ListBgHoverImg from '../../images/listbghover.png'

const PatientCard = ({
  id,
  patient_id,
  name,
  image,
  appointmentDate,
  appointmentMode,
  checked,
  first_name,
  middle_name,
  start_time,
  last_name
}) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.patientCard}>
        <div style={{ width: '28%', display: 'flex', alignItems: 'center' }}>
          {' '}
          <Avatar
            src={image ? image : ProfilePicImg}
            alt={name}
            className={classes.image}
          />
          <Typography className={classes.name} syle={{ marginBottom: '0px' }}>
            {name}
          </Typography>
        </div>
        <div style={{ width: '25%' }}>
          <Typography
            className={classes.appointmentDetails}
            style={{
              fontFamily: 'Poppins',
              fontWeight: '400',
              color: '#101d4e',
              fontSize: 14
            }}>
            <i
              class="fa fa-calendar"
              style={{
                marginRight: '10px',
                color: 'rgba(25,40,82, 0.7)'
              }}></i>{' '}
            <span>
              {' '}
              {moment(appointmentDate).format('DD-MM-YYYY') +
                ' - ' +
                moment(start_time, 'hh:mm:ss').format('hh:mm A')}
            </span>
          </Typography>
        </div>
        <div style={{ width: '15%' }}>
          <span
            style={{
              color: '#15A560',
              paddingTop: '0px',
              display: 'flex',
              fontSize: '14px',
              fontWeight: '400',
              alignItems: 'center',
              marginRight: '10px'
            }}>
            <span
              className={
                checked === '1' ? classes.bookedIcon : classes.checkinIcon
              }>
              <CheckCircleIcon
                style={{ fontSize: '20px', marginRight: '5px' }}
              />
            </span>
            <span>
              {checked === '1'
                ? 'Booked'
                : checked === '2'
                ? 'Confirmed'
                : checked === '3'
                ? 'Checked-In'
                : checked === '4'
                ? 'In-Progress'
                : checked === '5'
                ? 'Cancelled'
                : checked === '6'
                ? 'No-show'
                : checked === '7'
                ? 'Completed'
                : checked === '8'
                ? 'Rescheduled'
                : 'NA'}
            </span>
          </span>
        </div>
        <div style={{ width: '20%', display: 'flex', alignItems: 'center' }}>
          <img src={VideoIcon} alt="" />
          <Typography
            className={classes.appointmentDetails}
            syle={{ marginBottom: '0px' }}>
            <span
              style={{
                padding: '2px 0',
                fontFamily: 'Poppins',
                fontWeight: '400',
                marginBottom: '0px',
                fontSize: '14px',
                color: '#0D46C1',
                border: '0px solid #aeaeae',
                marginLeft: 10
              }}>
              {appointmentMode === '1' ? <>In-Person</> : <>Tele</>}
              {' Consult'}
            </span>
          </Typography>
        </div>
        <div style={{ width: '12%' }}>
          {' '}
          <Link
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              width: 70,
              height: 30,
              borderRadius: 5,
              fontSize: 12,
              background: '#0D46C1'
            }}
            to={{
              pathname: '/doctor/patient-details' + `/${patient_id}` + '/' + id
            }}>
            View
          </Link>
        </div>

        {/* <Grid
        item
        xs={12}
        md={4}
        className={classes.info + ' ' + classes.item}
        style={{
          display: 'flex'
        }}>
        
      </Grid> */}
      </div>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  patientCard: {
    width: '100%',
    // background: `url(${ListBgImg}) center top no-repeat`,
    padding: '10px',
    background: '#fff',
    borderRadius: '10px',
    backgroundSize: 'cover',
    // border: '1px rgba(0,0,0,0.1) solid',
    boxShadow: '0 0px 10px 0 rgba(0,0,0,0.05)',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    flexWrap: 'wrap',
    '&:hover p': {
      color: '#202020'
    },
    '&:hover p span': {
      color: '#202020'
    },
    '@media (max-width:767px)': {
      flexDirection: 'column',
      alignItems: 'initial'
    },
    '&:hover': {
      background: `url(${ListBgHoverImg}) center top no-repeat`,
      backgroundSize: 'cover',
      color: '#202020'
    }
  },
  userinfoCol: {
    borderRight: '1px dashed rgba(0,0,0,0.2)',
    width: '72%',
    '@media (max-width:767px)': {
      borderRight: '0px dashed rgba(0,0,0,0.2)'
    }
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    '&:hover p span': {
      color: '#202020'
    },
    '@media (max-width:767px)': {
      alignItems: 'initial',
      marginTop: '10px'
    }
  },
  image: {
    width: '50px',
    height: '50px',
    marginRight: '15px',
    marginTop: '3px',
    flex: '0 0 60px',
    order: '0'
  },
  name: {
    fontSize: '15px',
    color: '#000',
    fontWeight: '600',
    marginTop: '0px',
    display: 'flex',
    marginBottom: '0px',
    paddingRight: '8px',
    '&:hover': {
      color: '#fff'
    }
  },
  appointmentDetails: {
    color: '#313131',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '0px',
    marginBottom: '0px',
    '@media (max-width:767px)': {
      marginLeft: '5px'
    },
    '& span': {
      color: '#313131'
    }
  },
  button: {
    height: 40,
    width: 40,
    position: 'absolute',
    right: '8px',
    padding: '8px',
    background: theme.palette.primary.main,
    marginLeft: 'auto',
    alignSelf: 'center',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  checkinIcon: {
    marginRight: '5px',
    '@media (max-width:767px)': {
      marginLeft: '3px'
    },
    '& svg': {
      verticalAlign: 'bottom'
    }
  },
  bookedIcon: {
    marginRight: '5px',
    color: '#39b71b !important',
    '@media (max-width:767px)': {
      marginLeft: '3px'
    },
    '& span': {
      color: '#39b71b !important'
    },
    '& svg': { fill: '#39b71b' }
  }
}))

export default PatientCard
