import { action, thunk } from 'easy-peasy'
import BaseModel from '../Base'
import { NETWORK_STATUS, DOCTOR_USER_ID } from '../../Constants'
import api from '../../api'

export default {
  ...BaseModel(),
  patientsData: [],
  totalpages: 0,

  getPatientsList: thunk(async (actions, payload) => {
    actions.setPatientsData([])
    actions.setTotalCount(0)
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)

    const {
      table_config_rows_per_page,
      table_config_page_no,
      pattern
    } = payload

    const response = await api.doctor.getPatientsList({
      doctor_id: localStorage.getItem(DOCTOR_USER_ID),
      status: '0',
      sort_by: 'latest',
      table_config_rows_per_page: table_config_rows_per_page,
      table_config_page_no: table_config_page_no,
      pattern: pattern ? pattern : ''
    })

    if (response.ok) {
      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      if (response.data.my_patients) {
        actions.setPatientsData(response.data.my_patients)
        actions.setTotalCount(response.data.total_pages_count)
      }
    } else {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
    }
  }),

  setPatientsData: action((state, payload) => {
    state.patientsData = payload
  }),
  setTotalCount: action((state, payload) => {
    state.totalpages = payload
  })
}
