import React, { useEffect, useState, useMemo, useRef } from 'react'
import Profile from '../../components/CommonComponents/PatientProfile'
import Loader from '../../components/CommonComponents/Loader'
import {
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  Grid,
  Box,
  TextField,
  Avatar,
  CircularProgress,
  InputAdornment,
  Snackbar,
  makeStyles
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { PatientStore, PublicPatientStore } from '../../stores'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  PATIENT_USER_ID,
  VALIDATE_PHONE,
  VALIDATE_WHITESPACES,
  PATIENT_ID,
  LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME,
  VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE
} from '../../Constants'
import localforage from 'localforage'
import MuiAlert from '@material-ui/lab/Alert'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import SnackbarAlert from '../../components/CommonComponents/SnackbarAlert'
import { setDate } from 'date-fns'
import * as moment from 'moment'

import indianFlag from '../../images/flag.png'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function UpdateProfile() {
  const [open, setOpen] = useState(false)
  const [countrySelect, setCountrySelect] = useState(false)
  const [stateSelect, setStateSelect] = useState(false)
  const [citySelect, setCitySelect] = useState(false)
  const [multiButton, setmultiButton] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [Image, previewImage] = useState(null)
  const [file, setFile] = useState('')
  const classes = useStyles()
  const [selectedPatient, setSelectedPatient] = useState({})
  const [inputValue, setInputValue] = useState('')
  const [username, setUsername] = useState('')
  const [usertype, setUsertype] = useState('1')
  const [countryNameValue, setCountryNameValue] = useState({})
  const [stateNameValue, setStateNameValue] = useState({})
  const [cityNameValue, setCityNameValue] = useState({})
  const [inputDate, setDate] = useState('')
  const [showLoader, setShowLoader] = useState(false)

  const { profileUpdateStatus, userName, networkStatus } =
    PatientStore.useStoreState(state => ({
      ...state.profile
    }))
  const { updatedPatientProfile, getPatientProfile, snackClear } =
    PatientStore.useStoreActions(actions => ({
      ...actions.profile
    }))

  const { fetchCountryList, fetchStateList, fetchCityList, clearState } =
    PublicPatientStore.useStoreActions(actions => ({
      ...actions.appointment
    }))

  const {
    countryList,
    stateList,
    cityList,
    fetchingCountry,
    fetchingState,
    fetchingCity
  } = PublicPatientStore.useStoreState(state => ({
    ...state.appointment
  }))

  // const date =
  //   new Date().getFullYear() +
  //   '-' +
  //   (new Date().getMonth() + 1) +
  //   '-' +
  //   new Date().getDate()

  const date = new Date()

  const fetchSelectedPatient = async () => {
    setShowLoader(true)
    const data = await localforage.getItem('selected_patient')

    fetchCountryList({})
    fetchStateList(data.country_id)
    fetchCityList(data.state_id)
    patientData(data)

    if (data?.email === '') {
      setShowLoader(false)
      return
    }

    if (!data || !data.email) {
      fetchSelectedPatient()
    } else {
      setShowLoader(false)
    }
    return
  }

  useEffect(() => {
    // const region = new Promise((resolve, reject) => {
    //   if (resolve) {
    //     resolve(localforage.getItem('selected_patient'))
    //     // resolve(localforage.getItem(LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME))
    //   } else {
    //     reject('error')
    //   }
    // })

    // region.then(region => {
    //   fetchCountryList({})
    //   fetchStateList(region.country_id)
    //   fetchCityList(region.state_id)
    //   patientData(region)
    // })

    let details = new Promise((resolve, reject) => {
      if (resolve) {
        resolve(JSON.parse(localStorage.getItem('selected_patient_initial')))
      } else {
        reject('error')
      }
    })

    details
      .then(details => {
        fetchCountryList({})
        fetchStateList(details.country_id)
        fetchCityList(details.state_id)
        patientData(details)
      })
      .catch(data => {
        localforage.getItem('selected_patient').then(data => {
          fetchCountryList({})
          fetchStateList(data.country_id)
          fetchCityList(data.state_id)
          patientData(data)
        })
      })
    //getting username from lf
    localforage.getItem(LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME).then(data => {
      console.log(data)
      // console.log("the data is", data);
      // console.log(data.user.user_name);
      setUsername(data.user.user_name)
    })

    //Retrieving the photo from the localStorage

    localforage.getItem('selected_patient').then(data => {
      previewImage(data.photo_uri)
    })
    return () => {
      snackClear()
    }
  }, [])

  useEffect(() => {
    if (profileUpdateStatus === undefined || profileUpdateStatus === '') {
      //
    } else if (profileUpdateStatus?.statusCode == 201) {
      setOpenSnackBar(true)
      setSnackBarMessage('Patient profile updated successfully...')
      setmultiButton(false)
      setAlertType('success')
      getPatientProfile({
        patient_id: localStorage.getItem(PATIENT_ID),
        user_type: usertype
      }).then(data => {
        if (data == undefined) {
          window.location.reload()
        }
      })
    } else {
      setOpenSnackBar(true)
      // setSnackBarMessage(profileUpdateStatus.message.replace(/['"]+/g, ''))
      setSnackBarMessage('Patient profile not updated successfully')
      setmultiButton(false)
      setAlertType('error')
    }
  }, [profileUpdateStatus])

  useEffect(() => {
    if (usertype) {
      getPatientProfile({
        patient_id: localStorage.getItem(PATIENT_ID),
        user_type: usertype
      })
    }
  }, [usertype])

  useEffect(() => {
    if (userName) {
      formik.setFieldValue('username', userName)
    }
  }, [userName])

  useEffect(() => {
    if (countrySelect) {
      // when country select event triggered means it will change state value to true then it will not affected anything,
      // IMP : without countrySelect(true) state value it will always go to whatever code written in else statement it will run,
      // so we can't see updated country there
    } else {
      countryName =
        countryList &&
        countryList.filter(country => {
          return country.id === selectedPatient.country_id
        })
      if (countryName.length > 0) {
        setCountryNameValue(countryName[0])
        formik.setFieldValue('country_id', countryName[0].id)
      }
    }
    if (stateSelect) {
    } else {
      stateName =
        stateList &&
        stateList.filter(state => {
          return state.id === selectedPatient.state_id
        })
      if (stateName.length > 0) {
        setStateNameValue(stateName[0])
        formik.setFieldValue('state_id', stateName[0].id)
      }
    }
    if (citySelect) {
    } else {
      cityName =
        cityList &&
        cityList.filter(city => {
          return city.id === selectedPatient.city_id
        })
      if (cityName.length > 0) {
        setCityNameValue(cityName[0])
        formik.setFieldValue('city_id', cityName[0].id)
      }
    }
  }, [countryList, stateList, cityList])

  const patientData = data => {
    setSelectedPatient(data)
    setInputValue(data.mobile_no)
    setDate(data.dob)
  }

  // let user_type = localforage.getItem("selected_patient.user_type")
  // let pass = localStorage.getItem('pass')

  const user_Details = new Promise((resolve, reject) => {
    if (resolve) {
      resolve(localforage.getItem(LOCAL_STORAGE_PATIENT_AUTH_KEY_NAME))
    } else {
      reject('error')
    }
  })

  const user_type = Promise.resolve(localforage.getItem('selected_patient'))

  Promise.all([user_Details, user_type]).then(values => {
    // console.log(values[0].user.self_patient_data.user_type)
    setUsertype(values[1].user_type)
  })

  const submit = async values => {
    const file = values.file
    if (file) {
      if (
        file.name.split('.').pop().search('pdf') === 0 ||
        file.name.split('.').pop().search('docx') === 0
      ) {
        setOpenSnackBar(true)

        setSnackBarMessage(
          'Pdf and Docx file are not supported for profile picture!'
        )
        setmultiButton(false)
        setAlertType('info')
      } else if (
        file.name.split('.').pop().search('pdf') !== 0 ||
        file.name.split('.').pop().search('docx') !== 0
      ) {
        let base64 = await convertBase64(file)
        let format = base64.split(',')

        updatedPatientProfile({
          ...values,
          patient_id: localStorage.getItem(PATIENT_ID),
          user_id: localStorage.getItem(PATIENT_USER_ID),
          user_type: usertype,
          // username: userName,
          // password: localStorage.getItem("pass")
          //   ? localStorage.getItem("pass")
          //   : "",
          format,
          mobile_no: `+91${values.mobile_no}`
        })
      }
    } else {
      updatedPatientProfile({
        ...values,
        // doctor_id: localStorage.getItem(PATIENT_USER_ID),
        patient_id: localStorage.getItem(PATIENT_ID),
        user_id: localStorage.getItem(PATIENT_USER_ID),
        // user_type: localforage.getItem("selected_patient"),
        user_type: usertype,
        mobile_no: `+91${values.mobile_no}`
        // username: username,
        // password: localStorage.getItem("pass"),
      })
    }

    if (values.mobile_no && isValidPhoneNumber(values.mobile_no)) {
    }
  }

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  let countryName = []
  let stateName
  let cityName
  const formik = useFormik({
    initialValues: {
      username: username,
      password: '',
      confirmpassword: '',
      first_name: selectedPatient.first_name ? selectedPatient.first_name : '',
      DOB: selectedPatient.dob
        ? selectedPatient.dob
        : new Date().toLocaleDateString(),
      address: selectedPatient.address ? selectedPatient.address : '',
      last_name: selectedPatient.last_name ? selectedPatient.last_name : '',
      gender: selectedPatient.gender ? selectedPatient.gender : 'select',
      pincode: selectedPatient.pincode ? selectedPatient.pincode : '',
      mobile_no: selectedPatient.mobile_no
        ? selectedPatient.mobile_no.replace('+91', '')
        : '',
      country_id: selectedPatient.country_id ? selectedPatient.country_id : '',
      city_id: selectedPatient.city_id ? selectedPatient.city_id : '',
      state_id: selectedPatient.state_id ? selectedPatient.state_id : '',
      email: localStorage.getItem('email') ? localStorage.getItem('email') : ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: submit
  })

  const datehandleChange = (e, value) => {
    setDate(e)
    formik.setFieldValue('DOB', e)
  }

  const countryChange = (e, value) => {
    if (value == null) {
      setStateNameValue({})
      setCityNameValue({})
      // setCountryNameValue({})
      // clearState()
      formik.setFieldValue('country_id', '')
    }
    fetchStateList(value?.id)
    setCountrySelect(true)
    setCountryNameValue(value)
    setStateNameValue({})
    setCityNameValue({})
    formik.setFieldValue('country_id', value?.id)
    formik.setFieldValue('state_id', '')
    formik.setFieldValue('city_id', '')
  }

  const stateChange = (e, value) => {
    if (value == null) {
      setStateNameValue({})
      setCityNameValue({})
      fetchCityList('')
      // clearState()
      formik.setFieldValue('state_id', '')
      formik.setFieldValue('city_id', '')
    } else {
      setStateSelect(true)
      fetchCityList(value?.id)
      setStateNameValue(value)
      setCityNameValue({})
      formik.setFieldValue('state_id', value?.id)
      formik.setFieldValue('city_id', '')
    }
  }

  const cityChange = (e, value) => {
    setCitySelect(true)
    setCityNameValue(value)
    formik.setFieldValue('city_id', value?.id)
  }

  const genderChange = e => {
    formik.setFieldValue('gender', e.target.value)
  }

  const hiddenFileInput = useRef()

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  const handleChange = event => {
    formik.setFieldValue('file', event.currentTarget.files[0])
    event.preventDefault()
    // console.log(event.currentTarget.files[0]);

    let reader = new FileReader()
    let fileimage = event.target.files[0]

    reader.onloadend = () => {
      setFile(fileimage)
      previewImage(reader.result)
    }
    reader.readAsDataURL(fileimage)
  }

  const phoneChange = value => {
    setInputValue(value)
    formik.setFieldValue('mobile_no', value ? value : '')
  }

  const customStyles = {
    browse_button: {
      height: '100%',
      position: 'absolute',
      top: '0',
      right: '0',
      zIndex: '99',
      cursor: 'pointer',
      display: 'none',
      /*This makes the button huge. If you want a bigger button, increase the font size*/
      fontSize: '50px',
      /*Opacity settings for all browsers*/
      opacity: '0',
      mozOpacity: '0',
      msFilter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=0)'
    }
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }

  return (
    <div className={classes.Editprofilecol}>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />
      {networkStatus === 'FETCHING' || showLoader ? (
        <div>
          <Loader open={true} />
        </div>
      ) : (
        <div>
          <Loader open={false} />
        </div>
      )}

      <form
        onSubmit={formik.handleSubmit}
        noValidate={true}
        className={classes.root}>
        <div className={classes.formHeader}>
          <span>Update Profile</span>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} className={classes.updateprofileform}>
            <Grid container spacing={3} style={{ marginTop: '30px' }}>
              <Grid item xs={12} md={5}>
                {usertype === '1' ? (
                  <TextField
                    id="username"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="User_Name"
                    onChange={formik.handleChange}
                    value={formik && formik.values && formik.values.username}
                    onBlur={formik.handleBlur}
                    name="username"
                    label="User Name *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.username && formik.errors.username
                          ? formik.errors.username
                          : null}
                      </div>
                    }
                  />
                ) : (
                  <TextField
                    id="username"
                    style={{ width: '100%' }}
                    type="text"
                    placeholder="User_Name"
                    disabled={true}
                    onChange={formik.handleChange}
                    value={formik && formik.values && formik.values.username}
                    onBlur={formik.handleBlur}
                    name="username"
                    label="User Name *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.username && formik.errors.username
                          ? formik.errors.username
                          : null}
                      </div>
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={5}>
                {usertype === '1' ? (
                  <TextField
                    id="email"
                    style={{ width: '100%' }}
                    placeholder="jane@gmail.com"
                    name="email"
                    label="Email *"
                    // disabled={true }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.email}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : null}
                      </div>
                    }
                  />
                ) : (
                  <TextField
                    id="email"
                    style={{ width: '100%' }}
                    placeholder="jane@gmail.com"
                    name="email"
                    label="Email *"
                    disabled={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik && formik.values && formik.values.email}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : null}
                      </div>
                    }
                  />
                )}
              </Grid>

              <Grid item xs={12} md={5}>
                <TextField
                  id="first_name"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="First Name"
                  onChange={formik.handleChange}
                  value={formik && formik.values && formik.values.first_name}
                  onBlur={formik.handleBlur}
                  name="first_name"
                  label="First Name *"
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.first_name && formik.errors.first_name
                        ? formik.errors.first_name
                        : null}
                    </div>
                  }
                />
                {usertype === '1' ? (
                  <TextField
                    id="password"
                    style={{ width: '100%' }}
                    autoComplete="new-password"
                    type="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik && formik.values && formik.values.password}
                    onBlur={formik.handleBlur}
                    name="password"
                    label="Password *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.password && formik.errors.password
                          ? formik.errors.password
                          : null}
                      </div>
                    }
                  />
                ) : null}

                <FormControl className={classes.formControl}>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label">
                    Gender *
                  </InputLabel>
                  <Select
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom'
                      }
                    }}
                    className={classes.Input}
                    id="gender"
                    value={formik.values.gender}
                    name="gender"
                    label="Gender *"
                    placeholder="Select your gender"
                    onChange={genderChange}
                    style={{ width: '100%' }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.gender && formik.errors.gender
                          ? formik.errors.gender
                          : null}
                      </div>
                    }>
                    <MenuItem value="select" style={{ display: 'none' }}>
                      Select your gender
                    </MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </FormControl>

                {/* {usertype === '1' ? (
                  <PhoneInput
                    placeholder="Enter phone number"
                    international
                    id="mobile_no"
                    label="Mobile No*"
                    // disabled={true}
                    value={inputValue}
                    // style={{
                    //   width: '100%',
                    //   paddingLeft: '8px',
                    //   paddingTop: '30px',
                    //   // border:"none",
                    //   // borderBottom:"1px solid #9E9E9E"
                    // }}
                    className={classes.inputChange}
                    name="mobile_no"
                    onChange={phoneChange}
                    // onChange={inputValue => console.log(inputValue)}
                    error={
                      formik.values.mobile_no
                        ? isValidPhoneNumber(formik.values.mobile_no)
                          ? undefined
                          : 'Invalid phone number'
                        : 'Phone number required'
                    }
                  />
                ) : (
                  <PhoneInput
                    placeholder="Enter phone number"
                    international
                    id="mobile_no"
                    label="Mobile No*"
                    disabled={true}
                    value={inputValue}
                    // style={{
                    //   width: '100%',
                    //   paddingLeft: '8px',
                    //   paddingTop: '30px',
                    //   // border:"none",
                    //   // borderBottom:"1px solid #9E9E9E"
                    // }}
                    className={classes.inputChange}
                    name="mobile_no"
                    onChange={phoneChange}
                    // onChange={inputValue => console.log(inputValue)}
                    error={
                      formik.values.mobile_no
                        ? isValidPhoneNumber(formik.values.mobile_no)
                          ? undefined
                          : 'Invalid phone number'
                        : 'Phone number required'
                    }
                  />
                )} */}

                <TextField
                  id="contact"
                  name="mobile_no"
                  label="Enter phone number *"
                  placeholder="9870568432"
                  style={{ width: '100%' }}
                  // className={classes.Input}
                  value={formik.values.mobile_no}
                  // inputProps={{ maxlength: 10 }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={indianFlag}
                          alt=""
                          width="25"
                          height="25"
                          style={{ marginRight: '0.4rem' }}
                        />
                        +91
                      </InputAdornment>
                    )
                  }}
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.mobile_no && formik.errors.mobile_no
                        ? formik.errors.mobile_no
                        : null}
                    </div>
                  }
                />

                {/* {
                  <div className={classes.errMsg2}>
                    {formik.touched.mobile_no && formik.errors.mobile_no
                      ? formik.errors.mobile_no
                      : null}
                  </div>
                } */}

                <TextField
                  id="pincode"
                  style={{ width: '100%' }}
                  type="text"
                  placeholder="Pincode"
                  label="Pincode *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik && formik.values && formik.values.pincode}
                  name="pincode"
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.pincode && formik.errors.pincode
                        ? formik.errors.pincode
                        : null}
                    </div>
                  }
                />

                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id="size-small-outlined"
                    size="small"
                    name="state_id"
                    style={{ width: '100%' }}
                    options={stateList}
                    getOptionLabel={option => option.name}
                    onChange={stateChange}
                    value={stateNameValue}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="State *"
                        loading={fetchingState}
                        style={{ width: '100%' }}
                        // error={
                        //   (formik.errors.state_id, formik.touched.state_id)
                        // }
                        // helperText={
                        //   (formik.errors.state_id,
                        //   formik.touched.state_id
                        //     ? formik.errors.state_id
                        //     : null)
                        // }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {fetchingState ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                  <div className={classes.errMsg2}>
                    {formik.touched.state_id && formik.errors.state_id
                      ? formik.errors.state_id
                      : null}
                  </div>
                  {/* <div className={classes.errMsg2}>
                      {formik?.values?.state_id === undefined ? (
                        <Typography>{"State is required"}</Typography>
                      ) : null}
                    </div> */}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={5}>
                <TextField
                  id="last_name"
                  style={{ width: '100%' }}
                  placeholder="Last Name"
                  name="last_name"
                  label="Last Name*"
                  value={formik && formik.values && formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.last_name && formik.errors.last_name
                        ? formik.errors.last_name
                        : null}
                    </div>
                  }
                />
                {usertype === '1' ? (
                  <TextField
                    id="confirmpassword"
                    style={{ width: '100%' }}
                    type="password"
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    value={
                      formik && formik.values && formik.values.confirmpassword
                    }
                    onBlur={formik.handleBlur}
                    name="confirmpassword"
                    label="Confirm Password *"
                    helperText={
                      <div className={classes.errMsg}>
                        {formik.touched.confirmpassword &&
                        formik.errors.confirmpassword
                          ? formik.errors.confirmpassword
                          : null}
                      </div>
                    }
                  />
                ) : null}
                <KeyboardDatePicker
                  variant="inline"
                  format="DD-MM-YYYY"
                  margin="normal"
                  maxDate={new Date()}
                  name="DOB"
                  label="Date of birth *"
                  value={formik?.values?.DOB}
                  onChange={value => formik.setFieldValue('DOB', value)}
                  onBlur={formik.handleBlur}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.DOB && formik.errors.DOB
                        ? formik.errors.DOB
                        : null}
                    </div>
                  }
                  autoOk={true}
                />
                <TextField
                  id="address"
                  style={{ width: '100%' }}
                  placeholder="Address"
                  name="address"
                  label="Address *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik && formik.values && formik.values.address}
                  helperText={
                    <div className={classes.errMsg}>
                      {formik.touched.address && formik.errors.address
                        ? formik.errors.address
                        : null}
                    </div>
                  }
                />
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id="size-small-outlined"
                    name="country_id"
                    size="small"
                    style={{ width: '100%' }}
                    options={countryList}
                    getOptionLabel={option => option.name}
                    onChange={countryChange}
                    value={countryNameValue}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Country *"
                        loading={fetchingCountry}
                        style={{ width: '100%' }}
                        // error={
                        //   (formik.errors.country_id, formik.touched.country_id)
                        // }
                        // helperText={
                        //   (formik.errors.country_id,
                        //   formik.touched.country_id
                        //     ? formik.errors.country_id
                        //     : null)
                        // }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {fetchingCountry ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                  <div className={classes.errMsg2}>
                    {formik.touched.country_id && formik.errors.country_id
                      ? formik.errors.country_id
                      : null}
                  </div>

                  {/* <div className={classes.errMsg2}>
                      {formik?.values?.country_id === undefined ? (
                        <Typography>{"Country is required"}</Typography>
                      ) : null}
                    </div> */}
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id="size-small-outlined"
                    size="small"
                    name="city_id"
                    style={{ width: '100%' }}
                    options={cityList}
                    getOptionLabel={option => option.name}
                    onChange={cityChange}
                    value={cityNameValue}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="City *"
                        loading={fetchingCity}
                        style={{ width: '100%' }}
                        // error={(formik.errors.city_id, formik.touched.city_id)}
                        // helperText={
                        //   (formik.errors.city_id,
                        //   formik.touched.city_id ? formik.errors.city_id : null)
                        // }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {fetchingCity ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                  <div className={classes.errMsg2}>
                    {formik.touched.city_id && formik.errors.city_id
                      ? formik.errors.city_id
                      : null}
                  </div>
                  {/* <div className={classes.errMsg2}>
                      {formik?.values?.city_id === undefined ? (
                        <Typography>{"City is required"}</Typography>
                      ) : null}
                    </div> */}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12}></Grid>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.updateprofilecol}>
            <Box
              style={{
                width: '140px',
                height: '140px',
                background: 'white',
                borderRadius: '50%',
                margin: '25px auto',
                textAlign: 'right',
                padding: '10px 10px',
                display: 'flex'
              }}>
              {Image ? (
                <Avatar className={classes.AvatarCol} src={Image} />
              ) : (
                <Profile profileImage={true} />
              )}
            </Box>
            <label
              style={{
                textAlign: 'center',
                margin: '20px auto',
                display: 'block',
                fontWeight: 'bold',
                width: '150px'
              }}>
              <div>
                <Button
                  onClick={handleClick}
                  style={{
                    width: '140px',
                    borderRadius: '50px',
                    background: '#111d4e',
                    border: 'none',
                    height: '40px',
                    textAlign: 'center',
                    color: '#fff',
                    // lineHeight: '40px',
                    cursor: 'pointer',
                    left: '10px'
                  }}>
                  Edit Picture
                </Button>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept=" image/jpeg, image/png"
                  ref={hiddenFileInput}
                  style={customStyles.browse_button}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </label>
          </Grid>

          <Grid item xs={12} sm={6} className={classes.updateprofilebutton}>
            <button
              className={classes.Savebutton}
              type={'submit'}
              style={{
                width: '140px',
                borderRadius: '50px',
                background: '#111d4e',
                border: 'none',
                height: '40px',
                textAlign: 'center',
                color: '#fff',
                lineHeight: '40px',
                cursor: 'pointer',
                float: 'left'
              }}>
              Save
            </button>
          </Grid>
        </Grid>
      </form>

      <Loader />
    </div>
  )
}

const validationSchema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .required('Username is mandatory')
    .max(100, 'You may enter up to 100 characters'),

  first_name: yup
    .string()
    .trim()
    .required('First Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(35, 'You may enter up to 35 characters'),
  // .matches(SINGLE_WHITESPACE, 'Remove extra spaces'),
  address: yup
    .string()
    .trim()
    .required('Address is required')
    .max(250, 'Maximum character limit exceeded'),

  email: yup
    .string()
    .trim()
    .email('Invalid email')
    .required('Email is required'),

  mobile_no: yup
    .string()
    .matches(
      VALIDATE_PHONE_WITHOUT_PLUS_NINE_ONE,
      'Please enter a valid mobile number'
    )
    // .matches(VALIDATE_PHONE, ' Check with +91 and numbered entered')
    .required('Mobile number is required'),
  pincode: yup
    .string()
    .trim()
    .required('Pincode is required')
    .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Incorrect pincode format'),

  password: yup
    .string()
    // .trim()

    .min(6, 'Password cannot be less than 6 characters')
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),
  confirmpassword: yup
    .string()
    // .trim()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    })
    .matches(
      VALIDATE_WHITESPACES,
      'Space should not come before and after text!'
    ),
  last_name: yup
    .string()
    .trim()
    .required('Last Name is required')
    .matches(/^([^0-9]*)$/, 'Digits are not allowed')
    .max(35, 'You may enter upto 35 characters'),
  gender: yup.string().required('Gender is required'),
  country_id: yup.string().required('Country is required'),
  state_id: yup.string().required('State is required'),
  city_id: yup.string().required('City is required'),
  DOB: yup
    .date()
    .typeError('Please enter your date in the correct format')
    .max(new Date(), `Date should be earlier than today's date`)
    .required('Dob is required')
})

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  aside: {
    // padding: '25px 0 !important'
  },
  updateprofilecol: {
    '@media (max-width:767px)': {
      order: '1'
    }
  },
  updateprofilebutton: {
    '@media (max-width:767px)': {
      order: '3'
    }
  },
  updateprofileform: {
    '@media (max-width:767px)': {
      order: '2'
    }
  },
  Savebutton: {
    margin: '0px auto',
    display: 'block'
  },
  Editprofilecol: {
    padding: '20px',
    '@media (max-width:767px)': {
      padding: '0 15px 15px 0'
    }
  },
  inputChange: {
    width: '100%',
    paddingLeft: '8px',
    paddingTop: '22px',
    '& .PhoneInputInput': {
      border: 'none',
      borderBottom: '1px solid #9E9E9E',
      margin: '10px'
    }
  },
  AvatarCol: {
    width: '100%',
    height: '100%'
  },
  AvatarProfileCol: {
    padding: '0px !important'
  },
  appointmentPage: {
    background: '#F7F5F6',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  formControl: {
    width: '100%',
    margin: '12px 8px 8px'
  },
  errMsg: {
    color: 'red',
    fontSize: '12px',
    position: 'absolute'
  },
  errMsg2: {
    color: 'red',
    fontSize: '12px',
    paddingLeft: '5px',
    paddingTop: '4px',
    fontWeight: 'bolder',
    position: 'absolute'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 300
  },
  avatar: {
    width: '140px',
    height: '140px',
    background: 'white',
    borderRadius: '50%',
    boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
        0 2px 2px rgba(0,0,0,0.12), 
        0 4px 4px rgba(0,0,0,0.12), 
        0 8px 8px rgba(0,0,0,0.12),
        0 16px 16px rgba(0,0,0,0.12)`,
    margin: '0px auto',
    textAlign: 'center',
    padding: '10px',
    display: 'flex'
  },
  image: {
    width: '100%',
    height: '100%'
  },
  AppBar: {
    '& .MuiBox-root': {
      padding: '0'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      display: 'none'
    },
    checkboxes: {
      marginTop: '15px'
    }
  },
  transform: {
    textTransform: 'capitalize'
  },
  avatarName: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#000',
    textAlign: 'center'
  },
  formHeader: {
    paddingLeft: '10px',
    '& span': {
      fontWeight: 600,
      color: '#3f4b79',
      fontSize: '18px',
      margin: 0
    }
  }
}))
