import React from 'react'
import Medication from './Medication'

const PatientMedication = props => {
  const { TabPanel, value, classes, disabled } = props
  return (
    <React.Fragment>
      <TabPanel
        value={value}
        index={3}
        className={classes.MedicationCol}
        style={{
          background: '#fff',
          borderRadius: 15
        }}>
        <Medication disabled={disabled} />
      </TabPanel>
    </React.Fragment>
  )
}

export default PatientMedication
