import React, { useState, useEffect } from 'react'
import { Box, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import localforage from 'localforage'
import Logo from '../../images/logo.png'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import MenuIcon from '../../images/menu.png'
import WhiteLogo from '../../images/white-logo.png'
import { DOCTOR_USER_ID, PATIENT_USER_ID } from '../../Constants'

const PublicNavbar = props => {
  const [scrolltopdata, setscrolltopdata] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 15) {
        setscrolltopdata('')
      } else {
        setscrolltopdata('scrolled')
      }
    })
  }, [])
  const classes = useStyles()
  const [loggedIn, setLoggedIn] = React.useState(false)
  const [patientInfo, setPatientInfo] = React.useState(null)
  const [doctorInfo, setDoctorInfo] = React.useState(null)

  React.useEffect(() => {
    if (localStorage.getItem(PATIENT_USER_ID)) {
      setLoggedIn(true)
      localforage.getItem('selected_patient').then(data => {
        if (data) {
          setPatientInfo(data)
        } else {
          setLoggedIn(false)
          localStorage.removeItem(PATIENT_USER_ID)
          localforage.removeItem('selected_patient')
        }
      })
    } else if (localStorage.getItem(DOCTOR_USER_ID)) {
      setLoggedIn(true)
      let doctorData = localStorage.getItem('doctor_details')
      if (doctorData) {
        setDoctorInfo(JSON.parse(doctorData))
      } else {
        setLoggedIn(false)
        localStorage.removeItem(DOCTOR_USER_ID)
        localStorage.removeItem('doctor_details')
      }
    } else {
      setLoggedIn(false)
    }
  }, [])

  const logout = () => {
    localforage.clear().then(() => {
      localStorage.removeItem('patient_user_id')
      localStorage.removeItem('patient_id')
    })
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List className={classes.Listitem}>
        {loggedIn ? (
          <>
            <RouterLink to="/patient/dashboard">
              <Button className={classes.loginButton}>
                <b>Hi</b> {'   '}{' '}
                {patientInfo?.first_name || `Dr.${doctorInfo?.name}`}{' '}
                {patientInfo?.last_name}
              </Button>
              {!props.showLogout && (
                <Button onClick={logout} className={classes.logoutButton}>
                  Logout
                </Button>
              )}
            </RouterLink>
          </>
        ) : (
          ''
        )}
        {loggedIn ? (
          ''
        ) : (
          <>
            <a className={classes.loginButton} href="/#features">
              Features
            </a>
            <a className={classes.loginButton} href="/#pricing">
              Pricing
            </a>
            <RouterLink className={classes.loginButton} to="/patient/login">
              Patient Login
            </RouterLink>
            <RouterLink className={classes.loginButton} to="/doctor/login">
              Doctor Login
            </RouterLink>
            {/* <a className={classes.contactButton} href="#contactus">
              Business Contact
            </a> */}
          </>
        )}
      </List>
    </div>
  )

  const businessButtonClicked = () => {}
  return (
    <Box className={classes.Publicnav}>
      <div className={`header-wrapper ${scrolltopdata}`}>
        <Box
          w="100%"
          h="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          {...props}
          className={classes.headerSection}>
          <Box className={classes.responsivenav}>
            {['left'].map(anchor => (
              <React.Fragment key={anchor}>
                <Button
                  className={classes.responsivebtn}
                  onClick={toggleDrawer(anchor, true)}>
                  <img src={MenuIcon} alt="nav" />
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}>
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>
          <RouterLink to="/">
            <Box className={classes.Logo}>
              <img className="Logoimg" src={Logo} alt="Logo" />
              <img className="WhiteLog" src={WhiteLogo} alt="Logo" />
            </Box>
          </RouterLink>

          <Box justifyContent="right" className={classes.Headerright}>
            {loggedIn ? (
              <>
                <RouterLink
                  to={
                    doctorInfo == null
                      ? '/patient/dashboard'
                      : '/doctor/dashboard'
                  }
                  className="topheader-right">
                  <Button className="user-name">
                    Hi {'  '}{' '}
                    {patientInfo?.first_name || `Dr.${doctorInfo?.name}`}{' '}
                    {patientInfo?.last_name}
                  </Button>
                  <Button onClick={logout} className={classes.logoutButton}>
                    Logout
                  </Button>
                </RouterLink>
              </>
            ) : (
              ''
            )}
            {loggedIn ? (
              ''
            ) : (
              <>
                <RouterLink className="loginButton active" to="/">
                  Home
                </RouterLink>
                <a className="loginButton" href="/#features">
                  Features
                </a>
                <a className="loginButton" href="/#pricing">
                  Pricing
                </a>
                <RouterLink className="loginButton" to="/patient/login">
                  Patient Login
                </RouterLink>
                <RouterLink className="loginButton" to="/doctor/login">
                  Doctor Login
                </RouterLink>
                <a className={classes.contactButton} href="/#contactus">
                  Business Contact
                </a>
              </>
            )}
          </Box>
        </Box>
      </div>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  headerSection: {
    padding: '4px 20px 4px 0',
    width: '100%',
    display: 'flex',

    '@media (max-width:991px)': {
      padding: '10px 20px 20px',
      flexWrap: 'wrap'
    }
  },

  list: {
    width: 300,
    padding: '20px'
  },
  fullList: {
    width: 'auto'
  },
  Listitem: {
    display: 'flex',
    flexDirection: 'column'
  },
  loginButton: {
    color: '#212121',
    marginRight: '4rem',
    fontWeight: '400',
    cursor: 'pointer',
    '@media (max-width:1024px)': {
      marginRight: '1.5rem',
      marginBottom: '15px'
    },
    '&:hover': {
      color: '#111d4e',
      textDecoration: 'underline',
      paddingBottom: '8px'
    }
  },
  responsivebtn: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent'
    }
  },
  contactButton: {
    borderRadius: '8px',
    background: 'linear-gradient(to right bottom, #3E3BE8, #3267F1)',
    color: '#fff',
    padding: '10px 20px',
    cursor: 'pointer',
    textTransform: 'initial'
  },
  logoutButton: {
    borderRadius: '8px',
    marginRight: '20px',
    backgroundColor: '#063bc4',
    color: '#fff',
    padding: '10px 20px',
    textTransform: 'initial',
    height: '40px',
    '@media (max-width:767px)': {
      marginRight: 0
    },
    '&:hover': {
      backgroundColor: '#680fad',
      color: '#fff'
    }
  },
  Logoimg: {
    height: '90px',
    padding: '0 30%',
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape)':
      {
        height: '60px'
      },
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait)':
      {
        height: '60px'
      },
    '@media (max-width:767px)': {
      height: '60px'
    }
  },
  WhiteLog: {
    height: '90px',
    padding: '0 30%',
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape)':
      {
        height: '60px'
      },
    '@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait)':
      {
        height: '60px'
      },
    '@media (max-width:767px)': {
      height: '60px'
    }
  },
  responsivenav: {
    display: 'none',
    position: 'absolute',
    left: '0px',
    top: '18px',
    // background: `url(${CardBgImg}) center top no-repeat`,
    '@media (max-width:767px)': {
      display: 'block',
      top: '18px'
    }
  },
  Logo: {
    '@media (max-width:767px)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      marginBottom: '0px',
      marginLeft: '30px'
    }
  },
  Headerright: {
    '@media (max-width:767px)': {
      display: 'none',
      alignItems: 'end',
      justifyContent: 'end',
      width: '58%'
    }
  }
}))

export default PublicNavbar
