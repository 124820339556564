import React from 'react'
import {
    Box,
    Grid,
    makeStyles,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    TextField
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import ClinicNavbar from '../../components/CommonComponents/ClinicNavbar'
import DashboardLayoutImg from '../../images/bg2.png'
import Doctorimg from '../../images/doctor-img3.png'
import Doctorimg2 from '../../images/doctor-img4.png'
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '../../images/menu.png'

export default function DoctorInfo() {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
 
    return (
        <div className={classes.root}>
           <ClinicNavbar/>
                <Grid container className={classes.dashboardPage} spacing={3}>
                <Box className="bodycontent-col">
                    <Grid item xs={12} sm={4} md={3} className="LeftSide">
                <h2>Dummy Clinic Name</h2>
                <ul className="Leftnav">
                    <li className="active"><Link to="">Our Doctors</Link></li>
                    <li><Link to="">Update Clinic Profile</Link></li>
                    <li><Link to="">Add Doctors</Link></li>
                    <li><Link to="">Account</Link></li>
                    <li><Link to="">Subscriptions</Link></li>
                    <li><Link to="">Logout</Link></li>
                </ul>
      
              </Grid>
                    <Grid item xs={12} sm={8} md={6} className="Cardbox">
                <h3>Our Doctos</h3>
                <Box className="cardrow">
                    <Box className="doctorcard">
                        <img className={classes.doctor} src={Doctorimg} alt="doctor" />
                        <h5>Dr. John Doe</h5>
                        <p>MBBS, FRCS (UK)</p>
                    </Box>
                    <Box className="doctorcard">
                        <img className={classes.doctor} src={Doctorimg2} alt="doctor" />
                        <h5>Dr. Neha M.</h5>
                        <p>MBBS, FRCS</p>
                    </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Box className="appointment">
                    <p>Upcoming Appointments</p>
                    <h6 style={{color:'#fff'}}>Tele</h6>
                    <h5>0</h5>
                    <h6 style={{ color: '#fff' }}>In-Person</h6>
                    <h5 style={{marginBottom:'0px'}}>6</h5>
                </Box>
                <Box className="checked-patient">
                    <h2>3</h2>
                            <h3 style={{ color: '#fff' }}>Checked-In Patients</h3>
                </Box>
              </Grid>
              </Box>
            </Grid>
            
        </div>
    )
}
const useStyles = makeStyles(theme => ({
    // root: {
    //   width: "100%",
    //   "& > * + *": {
    //     marginTop: theme.spacing(2),
    //   },
    // },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
            '@media (max-width:767px)': {
                width: '100% !important'
            }
        }
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center',
    background: `url(${DashboardLayoutImg})`,
    backgroundAttachment: 'fixed',
    marginTop:'0px'
  },
    image: {
        width: '100%'
    },
    logo: {
        marginTop: '1rem',
        cursor: 'pointer',
        height: '80px'
    },
    doctorimg: {
        width: '100%'
    },
    Navbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}))
