import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { ErrorMessage, Field } from 'formik'

import TextField from '../../../components/TextField'
import PhoneNumberField from '../../../components/PhoneNumberField'

const EditFidel = ({ name, lable, info, phone }) => {
  const classes = useStyles()

  const [toggleEdit, setToggleEdit] = useState(false)

  const textFieldOnBlur = (e, field) => {
    setToggleEdit(false)
  }

  return (
    <div className={classes.wrapper}>
      <p
        style={toggleEdit ? { lineHeight: 2, verticalAlign: 'bottom' } : {}}
        onClick={() => setToggleEdit(true)}>
        {lable}
      </p>
      <Field name={name}>
        {({ field, meta: { touched, error } }) =>
          toggleEdit ? (
            !phone ? (
              <TextField
                focusNow
                {...field}
                onChange={e => {
                  field.onChange(e)
                }}
                onBlur={e => {
                  field.onBlur(e)
                  if (!error) {
                    textFieldOnBlur(e, field)
                  }
                }}
                error={touched && error}
                helperText={<ErrorMessage name={name} />}
              />
            ) : (
              <PhoneNumberField
                focusNow
                {...field}
                onChange={e => {
                  field.onChange(e)
                }}
                onBlur={e => {
                  field.onBlur(e)
                  if (!error) {
                    textFieldOnBlur(e, field)
                  }
                }}
                error={touched && error}
                helperText={<ErrorMessage name={name} />}
              />
            )
          ) : (
            <p onClick={() => setToggleEdit(true)}>
              {phone && `${field.value}` != 'undefined'
                ? `+91${field.value}`
                : field.value}
            </p>
          )
        }
      </Field>
    </div>
  )
}

export default EditFidel

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(max-content, 6rem) 12.5rem',
    gridColumnGap: '1rem',
    columnGap: '1rem',
    alignItems: 'flex-start'
  }
}))
