import { action, thunk } from 'easy-peasy'

import BaseModel from '../Base'
import api from '../../api'
import { NETWORK_STATUS } from '../../Constants'

const NO_CONNECTION_MESSAGE =
  'Oops! Something went wrong. Please try again later...'

export default {
  ...BaseModel(),

  bankinfo: '',
  reload: true,
  messageToggle: false,
  message: "",

  //set reload to ture/false
  setReload: action((state, payload) => {
    console.log("pyload form setreload")
    console.log(payload )
    state.reload = payload
  }),

  //set message
  setMessage: action((state, payload) => {
    state.message = payload.message
    state.messageToggle = payload.messageToggle
  }),

  //get bank details
  getBankInfo: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    
    try {
      const resp = await api.super_clinic.getBankDetails({
        super_clinic_id: payload.super_clinic_id
      })

      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
    
      if (resp.data.statusCode && resp.data.statusCode === 200) {
        actions.setBankInfo(resp.data.bank_details)
      }
      else{
        actions.setMessage({
          message: resp.data.message,
          messageToggle: true
        })
      }

    } catch {}

  }),

  //edit bank details
  editBankInfo: thunk(async (actions, payload) => {
    
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    console.log(payload)

    try {
      const resp = await api.super_clinic.editBankDetails({
        // primary_account_no: payload.primary_account_no,
        primary_ifsc_code: payload.primary_ifsc_code,
        primary_bank_name: payload.primary_bank_name,
        primary_beneficiary_name: payload.primary_beneficiary_name,
        primary_branch_name: payload.primary_branch_name,
      })

      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      console.log(resp)

      if (resp.data.statusCode && resp.data.statusCode === 201) {
        actions.setReload(true)
      }
      else{
        actions.setMessage({
          message: resp.data.message,
          messageToggle: true
        })
      }
    } catch {}
  }),

  setBankInfo: action((state, payload) => {
    console.log("paylaod of action")
    console.log(payload)
    state.bankinfo = payload
  })
}
