import { action, thunk } from 'easy-peasy'

import BaseModel from '../Base'
import api from '../../api'
import { NETWORK_STATUS, SUPER_CLINIC_USER_ID } from '../../Constants'

const NO_CONNECTION_MESSAGE =
  'Oops! Something went wrong. Please try again later...'

export default {
  ...BaseModel(),

  loadMessage: null,

  initClinicList: [],

  loadList: true,
  clinicList: [],
  clinicPaginationInfo: {},
  clinicListNetworkStatus: NETWORK_STATUS.FETCHING,
  errorMessageFromBackend: null,
  successMessageFromBackend: null,
  fetchingCountryListNetworkStatus: NETWORK_STATUS.FETCHING,
  fetchingStateListNetworkStatus: NETWORK_STATUS.NOT_STARTED,
  fetchingCityListNetworkStatus: NETWORK_STATUS.NOT_STARTED,
  countryList: [],
  stateList: [],
  cityList: [],

  refreshClinic: [],

  formSubmitNetworkStatus: NETWORK_STATUS.NOT_STARTED,

  setMessageFromBackend: action((state, payload) => {
    if (!!payload.success) {
      state.successMessageFromBackend = payload.message
    } else {
      state.errorMessageFromBackend = payload.message
    }
  }),

  setLoadMessage: action((state, payload) => {
    state.loadMessage = payload
  }),

  //del clinic lists
  delClinic: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    actions.setLoadMessage('Please Wait! Removing the clinic...')

    try {
      const resp = await api.super_clinic.removeClinicList({
        clinic_id: payload
      })

      actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
      actions.setLoadMessage(null)

      if (resp.data.statusCode && resp.data.statusCode === 201) {
        actions.setLoadList(true)
        actions.setMessageFromBackend({
          message: 'Removed the Clinic successfully!',
          success: true
        })
      } else {
        actions.setMessageFromBackend({
          message:
            !!resp.data && !!resp.data.message
              ? resp.data.message
              : NO_CONNECTION_MESSAGE
        })
      }
    } catch (e) {
      actions.setMessageFromBackend({ message: NO_CONNECTION_MESSAGE })
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      actions.setLoadMessage(null)
    }
  }),

  setInitClinicList: action((state, payload) => {
    state.initClinicList = payload
  }),

  getClinicList: thunk(async (actions, payload, { getState }) => {
    actions.setClinicListNetworkStatus(NETWORK_STATUS.FETCHING)

    try {
      const response = await api.super_clinic.getClinicList({
        pattern: !!payload.pattern ? payload.pattern : '', // 'mahesh', // (optional)
        super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID), // 'f897f35e-8fbb-474f-9ab2-fed6f43bbd35',
        table_config_page_no: `${payload.table_config_page_no}`, // '1',
        doctor_id: !!payload.doctor_id ? payload.doctor_id : '', // (optional)
        start_date: !!payload.start_date ? payload.start_date : '', // '2021-03-03', // (optional)
        end_date: !!payload.end_date ? payload.end_date : '', // (optional)
        table_config_rows_per_page: `${payload.table_config_rows_per_page}`
      })

      const { data = null } = response

      if (
        !!data &&
        !!data.statusCode &&
        (data.statusCode === 200 || data.statusCode === 201)
      ) {
        actions.setClinicListNetworkStatus(NETWORK_STATUS.SUCCESS)

        actions.setClinicList(data.data ? data.data : [])

        const { initClinicList } = getState()

        if (!initClinicList || initClinicList.length <= 0) {
          actions.setInitClinicList(data.data ? data.data.slice(0, 10) : [])
        }

        const {
          total_rows_count,
          current_page,
          rows_per_page,
          total_pages_count
        } = data

        actions.setClinicPaginationInfo({
          total_rows_count,
          current_page,
          rows_per_page,
          total_pages_count
        })
      } else {
        actions.setClinicListNetworkStatus(NETWORK_STATUS.FAILED)
        actions.setMessageFromBackend({
          message:
            !!data && !!data.message ? data.message : NO_CONNECTION_MESSAGE
        })
      }
      return data
    } catch (error) {
      actions.setClinicListNetworkStatus(NETWORK_STATUS.FAILED)
      actions.setMessageFromBackend({ message: NO_CONNECTION_MESSAGE })
    }
    return null
  }),

  setClinicList: action((state, payload) => {
    state.clinicList = payload
  }),

  setClinicPaginationInfo: action((state, payload) => {
    state.clinicPaginationInfo = payload
  }),

  setClinicListNetworkStatus: action((state, payload) => {
    state.clinicListNetworkStatus = payload
  }),

  fetchCountryStateCityList: thunk(async (actions, payload = {}) => {
    const { country_id = null, state_id = null } = payload

    actions.setCountryStateCityListNetworkStatus({
      ids: payload,
      networkStatus: NETWORK_STATUS.FETCHING
    })

    try {
      const response = await api.getCountryStateCity(
        !!state_id ? { state_id } : !!country_id ? { country_id } : {}
      )
      const { data = null } = response

      if (!!data && !!data.statusCode && data.statusCode === 200) {
        actions.setCountryStateCityListNetworkStatus({
          ids: payload,
          networkStatus: NETWORK_STATUS.SUCCESS
        })
        actions.setCountryStateCityLists({ ids: payload, list: data.data })
      } else {
        actions.setCountryStateCityListNetworkStatus({
          ids: payload,
          networkStatus: NETWORK_STATUS.FAILED
        })
        actions.setMessageFromBackend({
          message:
            !!data && !!data.message ? data.message : NO_CONNECTION_MESSAGE
        })
      }
    } catch (error) {
      actions.setCountryStateCityListNetworkStatus({
        ids: payload,
        networkStatus: NETWORK_STATUS.FAILED
      })
      actions.setMessageFromBackend({ message: NO_CONNECTION_MESSAGE })
    }
  }),

  setCountryStateCityListNetworkStatus: action((state, payload) => {
    const {
      ids: { country_id = null, state_id = null },
      networkStatus
    } = payload

    if (!country_id && !state_id) {
      state.fetchingCountryListNetworkStatus = networkStatus
    } else if (!!state_id) {
      state.fetchingCityListNetworkStatus = networkStatus
    } else if (!!country_id) {
      state.fetchingStateListNetworkStatus = networkStatus
    }
  }),

  setCountryStateCityLists: action((state, payload) => {
    const {
      ids: { country_id = null, state_id = null },
      list = []
    } = payload

    if (!country_id && !state_id) {
      state.countryList = list
    } else if (!!state_id) {
      state.cityList = list
    } else if (!!country_id) {
      state.stateList = list
    }
  }),

  clearStateAndCityList: action((state, payload) => {
    const { country_id = null, state_id = null } = payload

    if (state_id === '') {
      state.cityList = []
    } else if (country_id === '') {
      state.cityList = []
      state.stateList = []
    }
  }),

  addClinicProfile: thunk(async (actions, payload) => {
    actions.setFormSubmitNetworkStatus(NETWORK_STATUS.FETCHING)
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    actions.setLoadMessage('Please Wait! Adding the Clinic...')

    try {
      const { data } = await api.super_clinic.getClinicProfile({
        ...payload,
        trial_end_date: '',
        super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID)
      })

      if (!!data && !!data.statusCode && data.statusCode === 200) {
        actions.setFormSubmitNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setLoadMessage(null)
        actions.setLoadList(true)
        actions.setMessageFromBackend({
          message: 'Successfully added the Clinic!',
          success: true
        })
        return data
      } else {
        actions.setFormSubmitNetworkStatus(NETWORK_STATUS.FAILED)
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        actions.setLoadMessage(null)
        actions.setMessageFromBackend({
          message:
            !!data && !!data.message ? data.message : NO_CONNECTION_MESSAGE
        })
      }
    } catch (error) {
      actions.setFormSubmitNetworkStatus(NETWORK_STATUS.FAILED)
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      actions.setLoadMessage(null)
      actions.setMessageFromBackend({ message: NO_CONNECTION_MESSAGE })
    }

    return null
  }),

  setFormSubmitNetworkStatus: action((state, payload) => {
    state.formSubmitNetworkStatus = payload
  }),

  setLoadList: action((state, payload) => {
    state.loadList = payload
  }),

  editClinicProfile: thunk(async (actions, payload) => {
    actions.updateNetworkStatus(NETWORK_STATUS.FETCHING)
    actions.setLoadMessage('Please Wait! Saving the updated clinic data...')

    try {
      const { data } = await api.super_clinic.editSubClinicInSuperClinic({
        ...payload
      })

      if (
        !!data &&
        !!data.statusCode &&
        (data.statusCode === 200 || data.statusCode === 201)
      ) {
        actions.updateNetworkStatus(NETWORK_STATUS.SUCCESS)
        actions.setLoadMessage(null)
        actions.setMessageFromBackend({
          message: 'Successfully saved the updated Clinic Data!',
          success: true
        })
        return data
      } else {
        actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
        actions.setLoadMessage(null)
        actions.setMessageFromBackend({
          message:
            !!data && !!data.message ? data.message : NO_CONNECTION_MESSAGE
        })
      }
    } catch (error) {
      actions.updateNetworkStatus(NETWORK_STATUS.FAILED)
      actions.setLoadMessage(null)
      actions.setMessageFromBackend({ message: NO_CONNECTION_MESSAGE })
    }

    return null
  }),

  viewClinicInfo: thunk(async (actions, payload, { getState }) => {
    actions.setRefreshClinic(payload.clinic_id)

    try {
      const { data } = await api.super_clinic.viewSubClinicOrSuperclinicDetails(
        {
          super_clinic_id: localStorage.getItem(SUPER_CLINIC_USER_ID),
          clinic_id: payload.clinic_id
        }
      )

      actions.deleteRefreshClinic(payload.clinic_id)

      if (
        !!data &&
        !!data.statusCode &&
        (data.statusCode === 200 || data.statusCode === 201)
      ) {
        const { clinicList } = getState()
        const { Profile_data: profileData = {} } = data

        const newClinicList = clinicList.map(item =>
          item.clinic_id === payload.clinic_id
            ? {
                clinic_id: item.clinic_id,
                logo: profileData.logo,
                display_name: profileData.name,
                point_of_contact_name: item.point_of_contact_name,
                poc_phone_no: item.poc_phone_no,
                phone_number: profileData.phone_number,
                email: profileData.email,
                poc_email_id: item.poc_email_id,
                onboarded_on: item.onboarded_on,
                address: profileData.address,
                address_line2: item.address_line2,
                commission_value: profileData.commission_value
              }
            : { ...item }
        )

        actions.setClinicList(newClinicList)

        actions.setMessageFromBackend({
          message: 'Successfully Updated the Clinic Data!',
          success: true
        })
        return data
      } else {
        actions.setMessageFromBackend({
          message:
            !!data && !!data.message ? data.message : NO_CONNECTION_MESSAGE
        })
      }
    } catch (error) {
      actions.deleteRefreshClinic(payload.clinic_id)
      actions.setMessageFromBackend({ message: NO_CONNECTION_MESSAGE })
    }

    return null
  }),

  setRefreshClinic: action((state, payload) => {
    state.refreshClinic = [...state.refreshClinic, payload]
  }),

  deleteRefreshClinic: action((state, payload) => {
    state.refreshClinic = state.refreshClinic.filter(item => item !== payload)
  })
}
