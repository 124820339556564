import React from 'react'
import { DashboardLayout } from '../../doctorLayouts'
import PatientDetails from '../../components/PatientDetails/PatientDetails'

const PatientDetailsPage = () => {
  return (
    <DashboardLayout>
      <PatientDetails />
    </DashboardLayout>
  )
}

export default PatientDetailsPage
