import React, { useEffect, useState } from 'react'
import {
  Box,
  TableCell,
  makeStyles,
  withStyles,
  TableBody,
  Link,
  TableRow,
  Table,
  TableHead,
  Button
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import EditIcon from '@material-ui/icons/Edit'
import { HighlightOff } from '@material-ui/icons'
import localforage from 'localforage'
import moment from 'moment'
import { useParams } from 'react-router'
import NoRecordImg from '../../images/norecord.png'
import { DoctorStore } from '../../stores'
import { NETWORK_STATUS, DOCTOR_USER_ID } from '../../Constants'
import AddMedicationModal from './AddMedicationModal'
import EditMedicationModal from './EditMedicationModal'
import AddLabOrders from './AddLabOrders'
import EditLabOrders from './EditLabOrders'
import ViewLabRecordModal from './ViewLabRecordModal'
import SnackbarAlert from '../CommonComponents/SnackbarAlert'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#fff',
    color: 'rgba(55,55,55,0.8)',
    fontFamily: 'Poppins',
    fontSize: '14px',
    padding: '7px 10px !important'
  },
  tbody: {
    color: '#3f4b79',
    fontFamily: 'Poppins',
    '& td': {
      color: '#111d4e'
    },
    '& th': {
      fontSize: '0.8rem',
      fontFamily: 'Poppins',
      fontWeight: '500'
    }
  }
}))(TableCell)

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      fontFamily: 'Poppins',
      backgroundColor: '#F5F5F5',
      color: '#000'
    },
    '& th': {
      fontSize: '14px',
      border: 0
    },
    '& td': {
      fontSize: '14px',
      border: 0
    }
  }
}))(TableRow)

//? Main Function
const Medication = props => {
  const classes = useStyles()
  const params = useParams()
  const disabled = props.disabled
  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [addLab, setAddLab] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmLabDeleteModal, setConfirmLabDeleteModal] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState({})
  const [selectedLab, setSelectedLab] = useState({})
  const [showEditLabModal, setShowEditLabModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState('')
  const [viewRecordModal, setViewRecordModal] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [isSnackBarOpen, setOpenSnackBar] = React.useState(false)
  const [multiButton, setmultiButton] = React.useState(false)
  const [alertType, setAlertType] = React.useState('success')
  const [deleteMed, setDeleteMed] = useState('')
  const [labRecords, setLabRecords] = useState([])
  const [statusText, setStatusText] = useState('')
  const [statusTitle, setStatusTitle] = useState('')
  const {
    medications,
    oldMedications,
    labs,
    networkStatus,
    medicationsListStatus,
    labRecordsListStatus
  } = DoctorStore.useStoreState(state => ({
    ...state.patients
  }))
  const { getPatientMedications, getLab, deleteLab, deleteMedication } =
    DoctorStore.useStoreActions(actions => ({
      ...actions.patients
    }))

  const addMedicationClicked = () => {
    setAddModal(true)
  }
  const addLabOrdersClicked = () => {
    setAddLab(true)
  }

  useEffect(() => {
    getPatientMedications({ patientId: params.patient_id })
    getLab({ patientId: params.patient_id })
  }, [])

  const deleteClicked = medication => {
    setSelectedMedication(medication)
    setAlertOpen(true)
    setStatusTitle('Delete Medication')
    setStatusText(
      `Do you want to delete the Drug Name : ${medication?.proprietaryname} ?`
    )
    setDeleteMed(true)
  }

  const editClicked = medication => {
    setSelectedMedication(medication)
    setEditModal(true)
  }

  const labEditClicked = lab => {
    setSelectedLab(lab)
    setShowEditLabModal(true)
  }

  useEffect(() => {
    if (labs.response) {
      setLabRecords(labs.response)
    }
  }, [labs])

  const labDeleteClicked = lab => {
    setSelectedLab(lab)
    setAlertOpen(true)
    setStatusTitle('Delete Lab Record')
    setStatusText(`Do you want to delete the Test name : ${lab?.testname}?`)
    setDeleteMed(null)
  }

  const viewLabClicked = record => {
    setSelectedRecord(record)
    setViewRecordModal(true)
  }

  const showSnackBar = () => {
    setOpenSnackBar(true)
  }

  const hideSnackBar = () => {
    setOpenSnackBar(false)
  }
  const handleAlertClose = () => {
    setAlertOpen(false)
  }
  const validationCheck = () => {
    setAlertOpen(false)
    if (deleteMed) {
      deleteMedication({
        medicationId: selectedMedication.id,
        patientId: params.patient_id
      })
    } else {
      deleteLab({
        labId: selectedLab.labs_id,
        patientId: params.patient_id,
        doctor_id: localStorage.getItem(DOCTOR_USER_ID)
      })
      setLabRecords([])
    }
    setOpenSnackBar(false)
  }

  let Lab_data = labRecords.sort((a, b) => {
    return new Date(b.prescribed_on) - new Date(a.prescribed_on)
  })

  return (
    <>
      <Box className={classes.InfoColTop}>
        <h3
          style={{
            marginTop: '30px',
            fontSize: 20,
            fontWeight: 500,
            color: '#000'
          }}>
          Current Medications
        </h3>
        <button
          disabled={disabled}
          className={disabled ? classes.DisabledAddBtn : classes.AddBtn}
          onClick={addMedicationClicked}>
          Add New
        </button>
      </Box>
      <SnackbarAlert
        isOpen={isSnackBarOpen}
        message={snackBarMessage}
        alertType={alertType}
        multibutton={multiButton}
        primaryClick={hideSnackBar}
      />
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{statusTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{statusText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleAlertClose} color="primary">
            Cancel
          </Button>
          <Button onClick={validationCheck} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{
                width: '22%',
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Drug Name
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Frequency
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Duration
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                width: '28%',
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Instructions
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Prescribed by
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              End Date
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Action
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!medicationsListStatus
            ? medications?.map(row => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.proprietaryname}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.frequency}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.duration} days
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.instructions}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.prescribed_by}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(row.end_date).format('DD-MM-YYYY')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Link
                      className={
                        disabled || row.prescribed_by == ' '
                          ? classes.DisabledEditBtn
                          : classes.EditBtn
                      }
                      onClick={() => editClicked(row)}>
                      {disabled || row.prescribed_by == ' ' ? null : (
                        <EditIcon />
                      )}
                    </Link>
                    <Link
                      className={
                        disabled || row.prescribed_by == ' '
                          ? classes.DisabledDeleteBtn
                          : classes.DeleteBtn
                      }
                      onClick={() => deleteClicked(row)}>
                      {disabled || row.prescribed_by == ' ' ? null : (
                        <HighlightOff />
                      )}
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            : null}
        </TableBody>
      </Table>
      {medicationsListStatus ? (
        <Skeleton variant={'rect'} height={40} width={'100%'} />
      ) : null}
      {!medicationsListStatus && medications.length === 0 ? (
        <img
          src={NoRecordImg}
          className={classes.notFoundImage}
          alt={'Not found!'}
        />
      ) : null}

      <Box className={classes.InfoColTop}>
        <h3
          style={{
            marginTop: '30px',
            fontSize: 20,
            fontWeight: 500,
            color: '#000'
          }}>
          Medications History
        </h3>
      </Box>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{
                width: '25%',
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Drug Name
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Frequency
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Duration
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Instructions
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                backgroundColor: '#F5F5F5',
                fontSize: 13,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)'
              }}>
              Prescribed on
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!medicationsListStatus
            ? oldMedications?.map(row => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.proprietaryname}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.frequency}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.duration}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.instructions}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.start_date}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            : null}
        </TableBody>
      </Table>
      {medicationsListStatus ? (
        <Skeleton variant={'rect'} height={40} width={'100%'} />
      ) : null}
      {!medicationsListStatus && oldMedications.length === 0 ? (
        <img
          src={NoRecordImg}
          className={classes.notFoundImage}
          alt={'Not found!'}
        />
      ) : null}

      <Box className={classes.LabReport}>
        <Box display={'flex'} justifyContent={'space-between'} mb={2}>
          <h3
            style={{
              marginTop: '30px',
              fontSize: 20,
              fontWeight: 500,
              color: '#000'
            }}>
            Labs
          </h3>
          <button
            disabled={disabled}
            className={disabled ? classes.DisabledAddBtn : classes.AddBtn}
            onClick={addLabOrdersClicked}>
            Add New
          </button>
        </Box>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                className={classes.TableRow2}
                style={{
                  backgroundColor: '#F5F5F5',
                  fontSize: 13,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)'
                }}>
                Test Name
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.TableRow2}
                style={{
                  width: '25%',
                  backgroundColor: '#F5F5F5',
                  fontSize: 13,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)'
                }}>
                Instructions
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.TableRow2}
                style={{
                  backgroundColor: '#F5F5F5',
                  fontSize: 13,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)'
                }}>
                Prescribed by
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.TableRow2}
                style={{
                  backgroundColor: '#F5F5F5',
                  fontSize: 13,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)'
                }}>
                Prescribed on
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.TableRow2}
                style={{
                  backgroundColor: '#F5F5F5',
                  fontSize: 13,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)'
                }}>
                Document
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.TableRow2}
                style={{
                  backgroundColor: '#F5F5F5',
                  fontSize: 13,
                  fontWeight: 500,
                  color: 'rgba(0,0,0,0.7)'
                }}>
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!labRecordsListStatus
              ? Lab_data?.map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      // className={classes.TableRow2}
                    >
                      {row.testname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.instructions}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(row.prescribed_on).format('DD-MM-YYYY')}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Button
                        style={{
                          width: '70px',
                          height: '35px',
                          fontSize: '12px'
                        }}
                        // className={classes.AddBtn}
                        onClick={() => viewLabClicked(row.document_uri)}
                        disabled={row.document_uri == ''}
                        className={
                          row.document_uri == ''
                            ? classes.DisabledAddBtn
                            : classes.AddBtn
                        }>
                        View
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Link
                        className={
                          disabled || row.name == ''
                            ? classes.DisabledEditBtn
                            : classes.EditBtn
                        }
                        onClick={() => labEditClicked(row)}>
                        {disabled || row.name == '' ? null : <EditIcon />}
                      </Link>
                      <Link
                        className={
                          disabled || row.name == ''
                            ? classes.DisabledDeleteBtn
                            : classes.DeleteBtn
                        }
                        onClick={() => labDeleteClicked(row)}>
                        {disabled || row.name == '' ? null : <HighlightOff />}
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {labRecordsListStatus ? (
          <Skeleton variant={'rect'} height={40} width={'100%'} />
        ) : null}
        {!labRecordsListStatus && labRecords.length === 0 ? (
          <img
            src={NoRecordImg}
            className={classes.notFoundImage}
            alt={'Not found!'}
          />
        ) : null}
      </Box>
      <AddMedicationModal
        open={addModal}
        handleClose={() => setAddModal(false)}
      />
      {editModal ? (
        <EditMedicationModal
          open={editModal}
          handleClose={() => setEditModal(false)}
          defaultValues={selectedMedication}
        />
      ) : null}

      {addLab ? (
        <AddLabOrders
          open={addLab}
          handleClose={() => {
            setAddLab(false)
          }}
        />
      ) : null}

      {showEditLabModal ? (
        <EditLabOrders
          open={showEditLabModal}
          handleClose={() => setShowEditLabModal(false)}
          defaultValues={selectedLab}
        />
      ) : null}

      {viewRecordModal ? (
        <ViewLabRecordModal
          open={viewRecordModal}
          handleClose={() => setViewRecordModal(false)}
          record={selectedRecord}
        />
      ) : null}
    </>
  )
}

const useStyles = makeStyles(() => ({
  notFoundImage: {
    margin: '25px auto 0',
    display: 'flex',
    height: '200px',
    borderRadius: 20
  },
  InfoColTop: {
    width: '100%',
    marginTop: '0',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    '& h3': {
      fontSize: '18px',
      fontWeight: '500',
      color: '#3f4b79',
      flexGrow: '10',
      marginBottom: '0px'
    }
  },
  AddBtn: {
    background: '#0D46C1',
    borderRadius: '8px',
    border: 'none',
    width: '100px',
    height: '35px',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#fff',
    fontWeight: '500',
    cursor: 'pointer',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#39415f !important'
    }
  },
  DisabledAddBtn: {
    background: '#A3A9B7',
    borderRadius: '8px',
    border: 'none',
    width: '115px',
    fontFamily: 'Poppins',
    height: '35px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    fontWeight: '500',
    cursor: 'pointer'
  },
  table: {
    minWidth: '100%',
    '@media (max-width:767px)': {
      width: '800px',
      overflowX: 'auto'
    }
  },
  EditBtn: {
    float: 'left',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    color: '#3f4b79',
    marginRight: '5px',
    '&:hover': {
      textDecoration: 'none',
      color: '#3f4b79',
      cursor: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  DeleteBtn: {
    float: 'left',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    color: '#c10505',
    marginRight: '0',
    '&:hover': {
      textDecoration: 'none',
      color: '#c10505',
      cursor: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  DisabledEditBtn: {
    float: 'left',
    color: 'grey',
    marginRight: '5px',
    '&:hover': {
      textDecoration: 'none',
      color: 'grey',
      cursor: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  DisabledDeleteBtn: {
    float: 'left',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    color: 'grey',
    marginRight: '20px',
    '&:hover': {
      textDecoration: 'none',
      color: 'grey',
      curosr: 'pointer'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  LabReport: {
    marginTop: '40px'
  },
  TableRow2: {
    borderBottom: '0px !important',
    padding: '7px 5px !important',
    fontFamily: 'Poppins'
  },
  TableRow: {
    background: '#fff !important',
    borderBottom: '0px !important',
    fontFamily: 'Poppins'
  }
}))

export default Medication
