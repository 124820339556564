import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import RuppeeIcon from '../../../../images/rupee.png'
import Earning from '../../../../images/earning.svg'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    boxShadow:'0 1px 8px 0 rgba(0,0,0,0.1)',
    borderRadius:'10px',
    '& .MuiCardContent-root:last-child':{
      paddingBottom:'0px !important'
    }
  },
  avatar: {
    backgroundColor: '#9138aa',
    height: 56,
    width: 56
  }
}))

const TotalProfit = ({ className, offline_earning, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
          <Box style={{ display:'flex', alignItems:'center', marginBottom:'10px'}}>
          <img src={Earning} alt="Earning" style={{marginRight:'15px', width:'20px'}} />
          <h4 style={{margin:'0px', fontWeight:'600'}}>Total Offline Earnings</h4>
            </Box>
            <Typography color="textPrimary" variant="h5">
            <i class="fa fa-inr"></i>{' '} {offline_earning ? offline_earning : '0'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

TotalProfit.propTypes = {
  className: PropTypes.string
}

export default TotalProfit
