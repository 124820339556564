import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { API_REQUEST_DATETIME_FORMAT } from '../../Constants'
import Loader from '../CommonComponents/Loader'
import { Box, Grid, Link, Typography } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { PatientStore } from '../../stores'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import PatientDetailchart from './PatientDetailchart'
import { PATIENT_ID } from '../../Constants'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const PatientDetailsVitals = props => {
  const params = useParams()
  const disabled = props.completed
  const [open, setOpen] = React.useState(false)
  const [values, setValues] = useState({
    diagnosis: '',
    observations: '',
    suggestions: '',
    clinical_notes: ''
  })

  const [alignment, setAlignment] = React.useState('weekly')
  const [showVitalsHistory, setShowVitalsHistory] = useState(false)
  const [showAddVitals, setShowAddVitals] = useState(false)
  const [deleteItem, setDeleteItem] = React.useState({})
  const [value, setValue] = React.useState(0)
  const [snack, setSnack] = React.useState()
  const [chartData, setChartData] = React.useState(null)

  const { getPatientVitals, getPatientGraphDetails } =
    PatientStore.useStoreActions(actions => ({
      ...actions.patients
    }))

  const { patientVitals, patientVitalsGraph, updateVitals } =
    PatientStore.useStoreState(state => ({
      ...state.patients
    }))

  // const { successResponse } = PatientStore.useStoreState((state) => ({
  //   ...state.patients,
  // }));

  // console.log(patientVitals)

  useEffect(() => {
    if (updateVitals && updateVitals.statusCode === 201) {
      setSnack(true)
    }
  }, [updateVitals])

  const snackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnack(false)
  }

  useEffect(() => {
    getPatientVitals({
      doctor_id: '',
      patient_id: localStorage.getItem(PATIENT_ID),
      start_time: startDate,
      end_time: endDate,
      list_type: 'chart',
      table_config_rows_per_page: 20,
      table_config_page_no: 1
    })
  }, [])

  const endDate = moment().format(API_REQUEST_DATETIME_FORMAT)
  const startDate = moment()
    .subtract(60, 'days')
    .format(API_REQUEST_DATETIME_FORMAT)

  const classes = useStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    getPatientGraphDetails({
      patient_id: localStorage.getItem(PATIENT_ID),
      type: alignment
    })
  }, [alignment])

  useEffect(() => {
    if (patientVitalsGraph?.length > 0) {
      let vitals = patientVitalsGraph.reduce(
        (accumulator, currentValue, index) => {
          accumulator.bmi.push([currentValue.desc, parseInt(currentValue.bmi)])
          accumulator.bp.push([
            currentValue.desc,
            parseInt(currentValue.dbp),
            parseInt(currentValue.sbp)
          ])
          accumulator.hrate.push([
            currentValue.desc,
            parseInt(currentValue.hrate)
          ])
          accumulator.oxy.push([currentValue.desc, parseInt(currentValue.oxy)])
          accumulator.resp.push([
            currentValue.desc,
            parseInt(currentValue.resp)
          ])
          accumulator.temp.push([
            currentValue.desc,
            parseInt(currentValue.temp)
          ])
          return accumulator
        },
        {
          bmi: [['', '']],
          bp: [['', '', '']],
          hrate: [['', '']],
          oxy: [['', '']],
          resp: [['', '']],
          temp: [['', '']]
        }
      )
      setChartData(vitals)
    }
  }, [patientVitalsGraph])

  const buttonhandleChange = (event, newAlignment) => {
    setAlignment(newAlignment)
  }

  let patientVitalsData =
    patientVitals && patientVitals.length ? patientVitals : []

  const showVitalsModal = () => {
    setShowAddVitals(true)
  }

  const hideVitalsModal = () => {
    setShowAddVitals(false)
  }

  const showVitalsHistoryModal = () => {
    setShowVitalsHistory(true)
  }

  const hideVitalsHistoryModal = () => {
    setShowVitalsHistory(false)
  }
  // console.log(document.cookie, '---------', localStorage, 'cookie')

  return (
    <div className="vital-col">
      <Loader />
      {updateVitals?.statusCode === 201 ? (
        <Snackbar
          open={snack}
          autoHideDuration={6000}
          onClose={snackClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'bottom' }}>
          <Alert onClose={snackClose} severity="success">
            Vitals added successfully...
          </Alert>
        </Snackbar>
      ) : null}

      <Paper elevation={3} className={classes.paper}>
        <Grid item xs={12} sm={12} style={{ paddingTop: '15px' }}>
          <h2
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: '#000',
              marginBottom: 20
            }}>
            My Vitals
          </h2>
          <Box
            style={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: 20,
              padding: 20,
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
            }}>
            {/* PatientDetailchart */}
            <PatientDetailchart
              classes={classes}
              showVitalsModal={showVitalsModal}
              hideVitalsModal={hideVitalsModal}
              VitalsHistoryModal={showVitalsHistoryModal}
              VitalsData={patientVitalsData}
              AddVitals={showAddVitals}
              value={value}
              showVitalsHistory={showVitalsHistory}
              hideVitalsHistoryModal={hideVitalsHistoryModal}
              handleChange={buttonhandleChange}
              params={params}
              alignment={alignment}
              chartData={chartData}
              TabPanel={TabPanel}
              disabled={disabled}
            />
          </Box>
        </Grid>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: '15px',
    float: 'left',
    width: '100%',
    boxShadow: '0px 0px 0px #000',
    padding: '15px',
    backgroundColor: 'transparent',
    '& h2': {
      margin: '0px'
    }
  },
  // LabReport: {
  //   marginTop: '40px'
  // },
  patientVital: {
    fontWeight: '500',
    fontSize: 26
  },
  vitalName: {
    fontWeight: '400'
  },
  MedicationCol: {
    '& h3': {
      color: '#3f4b79',
      marginBottom: '0px'
    }
  },
  InfoRow: {
    marginBottom: '20px'
  },
  TableRow: {
    background: '#fff !important',
    borderBottom: '0px !important'
  },
  // TableRow2: {
  //   borderBottom: '0px !important',
  //   padding: '2px 0 !important'
  // },
  BtnRow: {
    display: 'flex'
  },
  InfoCol: {
    '& p': {
      fontSize: '14px',
      color: '#787878',
      marginBottom: '7px',
      '& span': {
        width: '200px',
        float: 'left'
      }
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: '600',
      color: '#414141',
      marginBottom: '20px'
    }
  },
  InfoColTop: {
    width: '100%',
    marginTop: '50px',
    '@media (max-width:767px)': {
      marginTop: '20px'
    },
    display: 'flex',
    '& h3': {
      fontSize: '18px',
      fontWeight: '500',
      color: '#3f4b79',
      flexGrow: '10'
    }
  },
  EditBtn: {
    float: 'left',
    color: '#3f4b79',
    marginRight: '20px',
    '&:hover': {
      textDecoration: 'none',
      color: '#3f4b79'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  DeleteBtn: {
    float: 'left',
    color: '#c10505',
    marginRight: '20px',
    '&:hover': {
      textDecoration: 'none',
      color: '#c10505'
    },
    '& img': {
      marginRight: '8px',
      float: 'left'
    }
  },
  Backbtn: {
    marginRight: '20px',
    background: 'none',
    border: '0px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    cursor: 'pointer',
    marginBottom: '15px',
    '@media (max-width:550px)': {
      marginBottom: '0px'
    }
  },
  Backicon: {
    marginRight: '5px'
  },
  AddBtn: {
    background: '#3f4b79',
    borderRadius: '50px',
    border: 'none',
    width: '115px',
    height: '30px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  DisabledAddBtn: {
    background: '#ADADAD',
    borderRadius: '50px',
    border: 'none',
    width: '115px',
    height: '30px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#fff',
    lineHeight: '30px',
    fontWeight: '600',
    cursor: 'pointer'
  },
  customized: {
    padding: '0px'
  },
  TabContent: {
    '& .MuiTable-root': {
      width: '100%',
      overflowX: 'auto'
    },
    '@media (max-width:767px)': {
      width: '100%',
      overflowX: 'auto'
    }
  },
  table: {
    minWidth: '100%'
  },
  vitals: {
    float: 'left',
    width: '100%',
    fontWeight: 'normal'
  },
  dashboardPage: {
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  drawer: {
    width: '20%',
    flexBasis: '20%',
    maxWidth: '260px',
    '@media (max-width:1024px)': {
      display: 'none'
    }
  },
  dashboard: {
    maxWidth: '75%',
    flexBasis: '75%',
    '@media (max-width:1024px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      marginTop: '35px'
    }
  },
  AreaChart: {
    '& svg rect': {
      fill: '#fff'
    }
  },
  ContentCol: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '15px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  PatientTop: {
    backgroundColor: '#f7f6f6',
    margin: '15px 0',
    '@media (max-width:767px)': {
      margin: '0'
    }
  },
  PatientInfo: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '15px'
  },
  PatientData: {
    paddingRight: '20px',
    '& h2': {
      color: '#3f4b79',
      marginBottom: '15px',
      fontSize: '14px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  VisitedBtn: {
    textAlign: 'center',
    backgroundColor: '#3f4b79',
    borderRadius: '40px',
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#3f4b79',
      boxShadow: 'none'
    }
  },
  PatientInfoRight: {
    display: 'flex',
    flexDirection: 'flex-end',
    alignItems: 'center'
  },
  PatientStatus: {
    marginRight: '80px',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      marginBottom: '20px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  PatientProfile: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    overflow: 'hidden',
    padding: '5px',
    '& img': {
      width: '100%'
    }
  },
  TabBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '& button': {
      backgroundColor: '#f7f6f6',
      marginRight: '15px',
      textTransform: 'capitalize',
      color: '#3f4b79'
    }
  },
  PatientChart: {
    display: 'flex',
    marginBottom: '20px',
    width: '100%',
    '& h3': {
      fontSize: '14px',
      color: '#3f4b79',
      width: '100%',
      marginRight: '30px',
      '& span': {
        color: '#414141',
        display: 'block'
      }
    }
  },
  GrapTop: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    '& h4': {
      fontSize: '14px',
      color: '#414141',
      '& span': {
        fontSize: '12px',
        color: '#999'
      }
    }
  },
  BtnCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '75%',
    '@media (max-width:767px)': {
      width: '100%',
      justifyContent: 'center'
    }
  },
  ToggleBtn: {
    textTransform: 'capitalize',
    '& button': {
      textTransform: 'capitalize',
      padding: '3px 15px'
    }
  },
  ActiveBtn: {
    backgroundColor: '#eff7ff !important',
    borderColor: '#3d8ff7 !important',
    color: '#3d8ff7'
  },
  ChartBox: {
    backgroundColor: '#fff',
    boxShadow: '1px 1px 8px 0px rgba(50, 50, 50, 0.15);',
    borderRadius: '5px',
    marginBottom: '15px'
  },
  submitButton: {
    color: '#fff',
    borderRadius: '25px'
  },
  Input: {
    width: '100%'
  },
  Input1: {
    margin: theme.spacing(1),
    width: '70%'
  },

  InputCol: {
    width: '100%',
    display: 'flex',
    marginBottom: '0',
    justifyContent: 'center',
    '& p': {
      fontSize: '18px',
      lineHeight: '55px',
      // lineHeight: '40px',
      marginRight: '10px',
      width: '90px'
    }
  },
  ModalForm: {
    width: '100%',
    padding: '40px;'
  },
  ModalBox: {
    width: '500px',
    background: '#fff',
    padding: '0px',
    height: '600px',
    '& h2': {
      textAlign: 'center',
      fontSize: '20px',
      color: '#fff',
      background: '#3f4b79',
      padding: '10px 20px',
      '& span': {
        float: 'right',
        color: '#fff',
        cursor: 'pointer'
      }
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  DownloadBtn: {
    width: '120px',
    background: '#53caf1',
    height: '35px',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '35px',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '50px',
    border: 'none',
    cursor: 'pointer'
  },
  button: {
    height: 40,
    width: 40,
    padding: '8px',
    background: '#3f4b79',
    marginLeft: 'auto',
    alignSelf: 'right',
    float: 'right',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: '#3f4b79'
    }
  },
  vitalsCard: {
    marginTop: '10px',
    height: '60px',
    padding: '10px 15px',
    '& h5': {
      float: 'left'
    }
  },
  disabledIcon: {
    height: 40,
    width: 40,
    padding: '8px',
    background: '#ADADAD',
    marginLeft: 'auto',
    alignSelf: 'right',
    float: 'right',
    '& svg': {
      height: '20px'
    },
    '&:hover': {
      background: '#ADADAD'
    }
  }
}))

export default PatientDetailsVitals
