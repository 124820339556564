import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Container } from '@material-ui/core'

import CL from './ClinicList'
import AddNewClinic from './AddNewClinic'

const clinicList = ({
  history: { push, goBack, replace },
  location: { state }
}) => (
  <Switch>
    <Route
      exact
      path="/superclinic/myclinic"
      render={() => (
        <Container maxWidth={false}>
          <CL
            onClickAddButton={() => {
              push({
                pathname: '/superclinic/myclinic/addclinic',
                state: 'goBack'
              })
            }}
          />
        </Container>
      )}
    />
    <Route
      exact
      path="/superclinic/myclinic/addclinic"
      render={() => (
        <Container maxWidth={false}>
          <AddNewClinic
            onClickBackToClinicListButton={() =>
              state === 'goBack' ? goBack() : replace('/superclinic/myclinic')
            }
          />
        </Container>
      )}
    />
  </Switch>
)

export default clinicList
