import React from 'react'
import DashboardContent from './Dashboard'
import Account from "./Account"
import { DashboardLayout } from '../../../layouts/ClinicDashboardLayout'

const Dashboard = () => {
  return (
    <DashboardLayout>
      <Account/>
    </DashboardLayout>
  )
}

export default Dashboard
